import Vue from 'vue'
import moment from 'moment'
import momentTZ from 'moment-timezone'
import i18n from '@/lang'

export function formatNumber(value, decimals) {
  if (value >= 0 && typeof value == 'number') {
    value = decimals ? value.toFixed(decimals) : value.toFixed(2)
    var parts = value.toString().split('.')
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return parts.join('.')
  }
  return value
}

//filer currency symbols
Vue.filter('currencyToSymbol', currency => {
  switch (currency) {
    case 'USD':
      return '$'
    case 'AUD':
      return 'A$'
    case 'CAD':
      return 'C$'
    case 'EUR':
      return '€'
    case 'GBP':
      return '￡'
    case 'NZD':
      return 'NZ$'
    case 'SGD':
      return 'S$'
    case 'JPY':
      return '￥'
    case 'HKD':
      return 'HK$'
    case 'BRL':
      return 'R$'
    default:
      return ''
  }
})

// replace Anonymous Function for test
Vue.filter('formatNumber', formatNumber)

Vue.filter('dateFormat', function (dataStr, pattern = 'DD/MM/YYYY HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

Vue.filter('date', function (dataStr, pattern = 'DD/MM/YYYY') {
  if (!dataStr) {
    return '-'
  } else {
    return moment(dataStr).format(pattern)
  }
})

Vue.filter('dateYearLast', function (dataStr, pattern = 'DD/MM/YYYY') {
  return moment(dataStr).format(pattern)
})

Vue.filter('dateFormatWithTimeZone', function (dataStr, pattern = 'DD/MM/YYYY HH:mm:ss zz') {
  let timezone = momentTZ.tz.guess()
  let EDTTime = momentTZ.tz(dataStr, 'YYYY-MM-DD HH:mm zz', 'America/New_York')
  return EDTTime.clone().tz(timezone).format(pattern)
})

// for PAYMENT DETAILS
Vue.filter('paymentType', paymentType => {
  switch (paymentType) {
    case 1:
      return i18n.t('common.withdrawChannel.creditcard')
    case 4:
      return i18n.t('common.withdrawChannel.unionpay')
    default:
      return ''
  }
})

Vue.filter('transactionStatus', status => {
  let temp = status.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, '').toLowerCase()
  temp = 'common.field.' + temp
  return i18n.t(temp)
})

Vue.filter('individualUserName', individualUserName => {
  return individualUserName.split('&')[0].trim()
})

Vue.filter('status', status => {
  switch (status) {
    case 'Pending':
      return 'Processing'
    default:
      return status
  }
})

// for deposit history
Vue.filter('depositMethods', method => {
  var key = method.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, '').toLowerCase()
  if (i18n.te('common.withdrawChannel.' + key) || i18n.te('common.withdrawChannel.' + key, 'en_US'))
    return i18n.t('common.withdrawChannel.' + key)
  return method
})

// for withdrawl history
Vue.filter('withdrawMethods', method => {
  var key = method.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, '').toLowerCase()
  if (i18n.te('common.withdrawChannel.' + key) || i18n.te('common.withdrawChannel.' + key, 'en_US'))
    return i18n.t('common.withdrawChannel.' + key)
  return method
})

Vue.filter('payStatus', status => {
  var key = status.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, '').toLowerCase()
  return i18n.t('transferHistory.payStatus.' + key)
})

Vue.filter('accStatus', status => {
  var key = status.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/\s]/g, '').toLowerCase()
  return i18n.t('home.accStatus.' + key)
})

Vue.filter('appendzero', status => {
  return status < 10 ? `0${status}` : status
})

//https://cloud.google.com/translate/docs/languages
Vue.filter('googleLangSwitch', lang => {
  switch (lang) {
    case 'en_US':
      return 'en'
    case 'zh_CN':
      return 'zh-CN'
    case 'fr_FR':
      return 'fr'
    case 'ja':
      return 'ja'
    case 'ko':
      return 'ko'
    case 'th':
      return 'th'
    case 'vi':
      return 'vi'
    case 'es':
      return 'es'
    case 'pt':
      return 'pt'
    case 'de':
      return 'de'
    case 'id':
      return 'id'
    case 'ar':
      return 'ar'
    case 'ru':
      return 'ru'
    case 'mn':
      return 'mn'
    default:
      return 'en'
  }
})

Vue.filter('tradingViewLangSwitch', lang => {
  switch (lang) {
    case 'en_US':
      return 'en-US'
    case 'zh_CN':
      return 'ZH'
    case 'fr_FR':
      return 'fr-FR'
    case 'ja':
      return 'ja-JP'
    case 'ko':
      return 'ko-KR'
    case 'th':
      return 'th-TH'
    case 'vi':
      return 'vi-VN'
    case 'es':
      return 'es-ES'
    case 'pt':
      return 'pt-PT'
    case 'de':
      return 'de-DE'
    case 'id':
      return 'id-ID'
    case 'ar':
      return 'ar'
    case 'ru':
      return 'ru-RU'
    case 'mn':
      return 'mn-MN'
    default:
      return 'en-US'
  }
})
