export default {
  computed: {
    lang() {
      return this.$store.state.common.lang
    },
  },
  methods: {
    goToTradingView() {
      let langSuffix = this.$options.filters.tradingViewLangSwitch(this.lang)
      let globalProtraderLink = this.GLOBAL_PROTRADER_LINK + '?lang='
      let loginUrl = globalProtraderLink + langSuffix

      window.open(loginUrl, '_blank')
    },
  },
}
