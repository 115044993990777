export default {
  data() {
    return {
      form: {
        idDocFilePathList: [],
        poaDocFilePathList: [],
        step: 5,
      },
      showIdDialog: false,
      showPoaDialog: false,
    }
  },
  inject: ['onIdPlayerReady', 'onPoaPlayerReady'],
  computed: {
    checkIdDocFile() {
      return this.form.idDocFilePathList.length > 0
    },
    checkPocDocFile() {
      return this.form.poaDocFilePathList.length > 0
    },
    showYoutube() {
      return !location.hostname.includes('.hk')
    },
    lang() {
      return this.$store.state.common.lang
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      await this.$store.dispatch('common/actionRegisterStep')
      if (this.$store.state.common.registerStep >= 6) {
        this.$router.push('/registerFinish')
      }
    },
    updateIDInfo(fileInfo) {
      this.form.idDocFilePathList = fileInfo.fileList
    },
    updatePOAInfo(fileInfo) {
      this.form.poaDocFilePathList = fileInfo.fileList
    },
    onIdPlayerClick() {
      this.showIdDialog = true
      this.onIdPlayerReady()
    },
    onPoaPlayerClick() {
      this.showPoaDialog = true
      this.onPoaPlayerReady()
    },
    closeID() {
      this.showIdDialog = false
    },
    closePOA() {
      this.showPoaDialog = false
    },
  },
}
