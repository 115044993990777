const country = require('@/constants/country.js')

module.exports = {
  info: {
    url: 'www.monetamarkets.com',
    protraderURL: 'https://protrader.monetamarkets.com/',
    map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.638612031567!2d-81.38410268509537!3d19.29807578696095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8f25865f22a0eebd%3A0xcd06467def56ae29!2sThe Harbour Centre%2C 42 N Church St%2C George Town%2C Cayman Islands!5e0!3m2!1sen!2sau!4v1571113930600!5m2!1sen!2sau',
    address: `First Floor, First St Vincent Bank Ltd Building, James Street, Kingstown, St. Vincent and the Grenadines.`,
    phone: '+44 (113) 3204819',
    email: 'support@monetamarkets.com',
    accountEmail: 'support@monetamarkets.com',
    depositEmail: 'support@monetamarkets.com',
    name: 'VIG Holdings Limited',
    license: 'VFSC: 700271',
  },
  menus: [
    {
      name: 'menu.home',
      path: '/home',
      icon: require('@/assets/images/menu/menu_icon_home.png'),
    },
    {
      name: 'menu.accManagement',
      path: '/accountManagement',
      icon: require('@/assets/images/menu/menu_icon_account.png'),
      children: [
        {
          name: 'menu.liveAcc',
          path: '/liveAccount',
        },
        {
          optional: true,
          name: 'menu.openAdditAcc',
          path: '/openLiveAccount',
        },
        {
          name: 'menu.homeDemo',
          path: '/homeDemo',
        },
      ],
    },
    {
      name: 'menu.funds',
      path: '/funds',
      icon: require('@/assets/images/menu/menu_icon_funds.png'),
      children: [
        {
          checkBlacklist: true,
          name: 'menu.depositFund',
          path: '/depositFunds',
        },
        {
          name: 'menu.withdrawFunds',
          path: '/withdrawFunds',
          checkBlacklist: true,
        },
        {
          checkBlacklist: true,
          name: 'menu.transferBetweenAccs',
          path: '/transferFunds',
        },
        {
          name: 'menu.payDetails',
          path: '/paymentDetails',
        },
        {
          name: 'menu.transactionHis',
          path: '/transactionHistory',
        },
      ],
    },
    {
      name: 'menu.download',
      path: '/downloads',
      icon: require('@/assets/images/menu/menu_icon_down.png'),
    },
    {
      name: 'menu.profile',
      path: '/profile',
      icon: require('@/assets/images/menu/menu_icon_my.png'),
      children: [
        {
          name: 'menu.myProfile',
          path: '/myProfile',
        },
        // {
        //   name: 'menu.cpPassword', // securityManagement页面替换changePassword页面
        //   path: '/changePassword',
        // },
        {
          name: 'menu.securityManagement', // 安全中心综合了changePassword功能 替换changePassword页面
          path: '/securityManagement',
        },
        // {
        //   name: 'menu.twoFactorAuthentication',
        //   path: '/twoFactorAuthentication',
        // },
      ],
    },
    {
      name: 'menu.protraderPlatform',
      path: '/ProtraderPlatform',
      icon: require('@/assets/images/menu/menu_icon_tradingView.png'),
      iconSuffix: require('@/assets/images/menu/menu_popular.png'),
    },
    {
      name: 'menu.tradingTools',
      path: '/tradingTool/MARKET_BUZZ',
      icon: require('@/assets/images/menu/menu_icon_tool.png'),
      children: [
        {
          name: 'menu.MARKET_BUZZ',
          path: '/tradingTool/MARKET_BUZZ',
        },
        {
          name: 'menu.FEAT_FX',
          path: '/tradingTool/FEAT_FX',
        },
        {
          name: 'menu.ECON_CALENDAR',
          path: '/tradingTool/ECON_CALENDAR',
        },
        {
          name: 'menu.FX_IDEAS',
          path: '/tradingTool/FX_IDEAS',
        },
        {
          name: 'menu.ALPHA_GENERATION',
          path: '/tradingTool/ALPHA_GENERATION',
        },
        {
          name: 'menu.MARKET_MASTERS',
          path: '/tradingTool/MARKET_MASTERS',
        },
      ],
    },
    // {
    //   name: 'menu.hmcTrader',
    //   path: '/hmcTraderPlatform',
    //   icon: require('@/assets/images/menu/menu_icon_chart_bar.png'),
    // },
    {
      name: 'menu.marketWidgets',
      path: '/marketWidgets/technicalAnalysis',
      icon: require('@/assets/images/menu/menu_icon_marketWidgets.png'),
      children: [
        {
          name: 'menu.technicalAnalysis',
          path: '/marketWidgets/technicalAnalysis',
        },
      ],
    },
    {
      name: 'menu.promotion',
      path: '/depositBonus',
      icon: require('@/assets/images/menu/menu_icon_cashback.png'),
      children: [
        // {
        //   name: 'menu.referAFriend',
        //   path: '/referAFriend',
        //   iconSuffix: require('@/assets/images/menu/menu_popular2.png'),
        // },
        // {
        //   name: 'menu.depositBonus',
        //   path: '/depositBonus',
        //   iconSuffix: require('@/assets/images/menu/menu_popular2.png'),
        // },
        {
          name: 'menu.cashback',
          path: '/cashback',
          iconSuffix: require('@/assets/images/menu/menu_popular2.png'),
        },
      ],
    },
    {
      name: 'menu.support',
      path: '',
      icon: require('@/assets/images/menu/menu_icon_contact.png'),
      children: [
        // {
        //   name: 'menu.myTickets',
        //   path: '/myTickets',
        //   icon: '',
        // },
        {
          name: 'menu.contactUs',
          path: '/contactUs',
          icon: '',
        },
      ],
    },
    {
      name: 'menu.register',
      path: '/register',
      icon: require('@/assets/images/menu/menu_icon_register.png'),
    },
  ],
  getPayments(vm) {
    return [
      // {
      //   title: 'deposit.default.channels.malayInstant',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.mysCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.zotaClick,
      //   dataTestid: 'malayInstant',
      // },
      // {
      //   title: 'deposit.default.channels.philippineInstant',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.phpCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   clickFunction: vm.zotaClick,
      //   dataTestid: 'philippineInstant',
      // },
      // {
      //   title: 'deposit.malaysia.header',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.mysCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.eeziePayClick,
      //   dataTestid: 'malayEeziePay',
      // },
      // {
      //   title: 'deposit.default.channels.thaiInstant',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.thaCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.zotaClick,
      //   dataTestid: 'thaiInstant',
      // },
      // {
      //   title: 'deposit.vietnam.header',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.vnmCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.eeziePayClick,
      //   dataTestid: 'vietEeziePay',
      // },
      // {
      //   title: 'deposit.indonesia.header',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.idnCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.eeziePayClick,
      //   dataTestid: 'indonesiaEeziePay',
      // },
      // {
      //   title: 'common.withdrawChannel.hongkongbanktransfer',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.hkCountryCode,
      //   feeNumber: '$0*',
      //   time: '1',
      //   hour: true,
      //   preferred: true,
      //   clickFunction: vm.hongkongClick,
      //   dataTestid: 'hongkong',
      // },
      // {
      //   title: 'common.withdrawChannel.dollarSmartQR',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.hkCountryCode,
      //   feeNumber: '$0*',
      //   time: '1',
      //   hour: true,
      //   preferred: true,
      //   promotion: true,
      //   clickFunction: vm.hongkongQRClick,
      //   dataTestid: 'hongkong',
      // },
      // {
      //   title: 'common.withdrawChannel.indiabanktransfer',
      //   logo: require(`@/assets/images/channel/bank_transfer.png`),
      //   limitCountry: true,
      //   checkIdPass: vm.idPass,
      //   onlyThisCountry: vm.inCountryCode,
      //   feeNumber: '$0',
      //   time: '0-6',
      //   hours: true,
      //   preferred: false,
      //   clickFunction: vm.indiaBankTransferClick,
      //   dataTestid: 'indiaBankTransfer',
      // },
      // {
      //   title: 'deposit.ida.header',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.inCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   clickFunction: vm.eeziePayClick,
      //   dataTestid: 'indiaEeziePay',
      // },
      // {
      //   title: 'deposit.default.channels.pagsmileBrazil',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.brlCountryCode,
      //   feeNumber: '$0*',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.pagsmileClick,
      //   dataTestid: 'pagsmile',
      // },
      // {
      //   title: 'deposit.default.channels.pagsmileMexico',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.mexicoCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.pagsmileClick,
      //   dataTestid: 'pagsmile',
      // },
      // {
      //   title: 'deposit.default.channels.pagsmileColombia',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.colombiaCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.pagsmileClick,
      //   dataTestid: 'pagsmile',
      // },
      // {
      //   title: 'deposit.default.channels.pagsmileChile',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.chileCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.pagsmileClick,
      //   dataTestid: 'pagsmile',
      // },
      // {
      //   title: 'deposit.default.channels.pagsmilePeru',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.peruCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.pagsmileClick,
      //   dataTestid: 'pagsmile',
      // },
      {
        title: 'deposit.default.channels.creditOrDebit',
        logo: require(`@/assets/images/channel/visa.png`),
        limitCountry: true,
        showPayment: vm.excludeChina && vm.excludeIndia && vm.excludeIran,
        feeNumber: '$0',
        time: '24/7',
        instant: true,
        clickFunction: vm.bridgePaymentClick,
        dataTestid: 'creditOrDebit',
      },
      {
        title: 'deposit.default.channels.i12BankTransfer',
        logo: require(`@/assets/images/channel/swift.png`),
        limitCountry: true,
        showPayment: vm.notRestricted(2) && vm.showIBT,
        feeNumber: '$0',
        time: '2-5',
        businessDays: true,
        clickFunction: vm.internationalClick,
        dataTestid: 'i12BankTransfer',
      },
      // {
      //   title: 'deposit.default.channels.fasa',
      //   logo: require(`@/assets/images/channel/fasapay.png`),
      //   feeNumber: '$0*',
      //   time: '24/7',
      //   instant: true,
      //   clickFunction: vm.fasapayClick,
      //   dataTestid: 'fasapay',
      // },
      // {
      //   title: 'deposit.default.channels.crypto',
      //   logo: require(`@/assets/images/channel/crypto.png`),
      //   time: '1',
      //   hour: true,
      //   clickFunction: vm.cryptoClick,
      //   dataTestid: 'crypto',
      // },
      // {
      //   title: 'deposit.default.channels.unionPay',
      //   logo: require(`@/assets/images/channel/unionpay.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.chCountryCode,
      //   feeNumber: '$0*',
      //   time: '24/7',
      //   instant: true,
      //   clickFunction: vm.unionClick,
      //   dataTestid: 'unionPay',
      // },
      // {
      //   title: 'deposit.default.channels.nigeriaInstant',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.ngaCountryCode,
      //   feeNumber: '$0',
      //   time: '1-2',
      //   businessDays: true,
      //   preferred: true,
      //   clickFunction: vm.zotaClick,
      //   dataTestid: 'nigeriaInstant',
      // },
      // {
      //   title: 'deposit.default.channels.vietInstant',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   showPayment: vm.showVietnam,
      //   feeNumber: '$0',
      //   time: '1-2',
      //   businessDays: true,
      //   preferred: true,
      //   clickFunction: vm.vietnamClick,
      //   dataTestid: 'vietInstant',
      // },
      // {
      //   title: 'deposit.default.channels.indonesiaInstant',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.idnCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.zotaClick,
      //   dataTestid: 'indonesiaInstant',
      // },
      // {
      //   title: 'deposit.default.channels.indiaInstant',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.inCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.indiaClick,
      //   dataTestid: 'indiaInstant',
      // },
      // {
      //   title: 'deposit.uk.header',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.ukCountryCode,
      //   feeNumber: '$0*',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.ukClick,
      //   dataTestid: 'uk',
      // },
      // {
      //   title: 'deposit.southAfrica.header',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.zarCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.southAfricaClick,
      //   dataTestid: 'southAfrica',
      // },
      // {
      //   title: 'deposit.default.channels.laosInstant',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.laosCountryCode,
      //   feeNumber: '$0*',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.laosClick,
      //   dataTestid: 'laos',
      // },
      // {
      //   title: 'common.withdrawChannel.ugandabanktransfer',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.ugandaCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.africaClick,
      //   dataTestid: 'uganda',
      // },
      // {
      //   title: 'common.withdrawChannel.rwandabanktransfer',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.rwandaCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.africaClick,
      //   dataTestid: 'rwanda',
      // },
      // {
      //   title: 'common.withdrawChannel.zambiabanktransfer',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.zambiaCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.africaClick,
      //   dataTestid: 'zambia',
      // },
      // {
      //   title: 'common.withdrawChannel.congobanktransfer',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.congoCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.africaClick,
      //   dataTestid: 'congo',
      // },
      // {
      //   title: 'common.withdrawChannel.cameroonbanktransfer',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.cameroonCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.africaClick,
      //   dataTestid: 'cameroon',
      // },
      // {
      //   title: 'common.withdrawChannel.burundibanktransfer',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.burundiCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.africaClick,
      //   dataTestid: 'burundi',
      // },
      // {
      //   title: 'common.withdrawChannel.kenyabanktransfer',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.kenyaCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.africaClick,
      //   dataTestid: 'kenya',
      // },
      // {
      //   title: 'common.withdrawChannel.ghanabanktransfer',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.ghanaCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.africaClick,
      //   dataTestid: 'ghana',
      // },
      // {
      //   title: 'common.withdrawChannel.tanzaniabanktransfer',
      //   logo: require(`@/assets/images/channel/local_transfer.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.tanzaniaCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   preferred: true,
      //   clickFunction: vm.africaClick,
      //   dataTestid: 'tanzania',
      // },
      // {
      //   title: 'deposit.default.channels.skrill',
      //   logo: require(`@/assets/images/channel/skrill.png`),
      //   limitCountry: true,
      //   showPayment: vm.excludeEEACountries,
      //   feeNumber: '$0*',
      //   time: '24/7',
      //   instant: true,
      //   clickFunction: vm.skrillClick,
      //   dataTestid: 'skrill',
      // },
      // {
      //   title: 'deposit.default.channels.sticPay',
      //   logo: require(`@/assets/images/channel/sticPay.png`),
      //   feeNumber: '$0*',
      //   time: '24/7',
      //   instant: true,
      //   clickFunction: vm.sticPayClick,
      //   dataTestid: 'sticPay',
      // },
      // {
      //   title: 'deposit.default.channels.neteller',
      //   logo: require(`@/assets/images/channel/neteller.png`),
      //   limitCountry: true,
      //   showPayment: vm.excludeNetellerCountries,
      //   feeNumber: '$0*',
      //   time: '24/7',
      //   instant: true,
      //   clickFunction: vm.netellerClick,
      //   dataTestid: 'neteller',
      // },
      // {
      //   title: 'deposit.default.channels.b2b',
      //   logo: require(`@/assets/images/channel/broker.png`),
      //   feeNumber: '$0*',
      //   time: '3-5',
      //   businessDays: true,
      //   clickFunction: vm.brokerClick,
      //   dataTestid: 'b2b',
      // },
      // {
      //   title: 'deposit.default.channels.bitwallet',
      //   logo: require(`@/assets/images/channel/bitwallet.png`),
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   clickFunction: vm.bitwalletClick,
      //   dataTestid: 'bitwallet',
      // },
      // {
      //   title: 'deposit.default.channels.astropay',
      //   logo: require(`@/assets/images/channel/astropay.png`),
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   clickFunction: vm.astropayClick,
      //   dataTestid: 'astropay',
      // },
      // {
      //   title: 'deposit.default.channels.webMoney',
      //   logo: require(`@/assets/images/channel/webmoney.png`),
      //   limitCountry: true,
      //   showPayment: vm.showWebMoney,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   clickFunction: vm.webMoneyClick,
      //   dataTestid: 'webmoney',
      // },
      // {
      //   title: 'deposit.default.channels.vload',
      //   logo: require(`@/assets/images/channel/vload.png`),
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   clickFunction: vm.vloadClick,
      //   dataTestid: 'vload',
      // },
      // {
      //   title: 'deposit.default.channels.dasshpeUPI',
      //   logo: require(`@/assets/images/channel/dasshpe_upi.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.inCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   clickFunction: vm.dasshpeUPIClick,
      //   dataTestid: 'dasshpeUPI',
      // },
      // {
      //   title: 'deposit.default.channels.dasshpeWallet',
      //   logo: require(`@/assets/images/channel/dasshpe_wallet.png`),
      //   limitCountry: true,
      //   onlyThisCountry: vm.inCountryCode,
      //   feeNumber: '$0',
      //   time: '24/7',
      //   instant: true,
      //   clickFunction: vm.dasshpeWalletClick,
      //   dataTestid: 'dasshpeWallet',
      // },
    ]
  },
  accountTypeMaps: {
    0: 'ALL',
    1: 'Direct STP',
    2: 'PRIME ECN',
    3: 'Direct STP & Binary Options',
    4: 'PRIME ECN & Binary Options',
    5: 'Binary Options Only',
    6: 'Swap free STP',
    7: 'Swap free ECN',
    8: 'PRO ECN',
    10: 'PAMM',
    101: 'Direct STP', // MT5 Standard STP
    11: 'Net STP',
    12: 'Net ECN',
    13: 'Direct STP', // Hedge STP
    14: 'PRIME ECN', // Hedge ECN
    15: 'Swap free STP', // MT5 Swap free STP
    16: 'Swap free ECN', // MT5 Swap free ECN
    17: 'PRO ECN', //MT5 PRO ECN
    35: 'Ultra ECN', //MT4 ULTRA_ECN
    36: 'Hedge Ultra ECN', //MT5 HEDGE_ULTRA_ECN
    45: '10 Days Swap Free STP',
    46: '10 Days Swap Free ECN',
    47: 'MT5 10 Days Swap Free STP',
    48: 'MT5 10 Days Swap Free ECN',
  },
  tradingPlatform: {
    mt4: true,
    mt5: true,
    appTrader: true,
  },
  restrictDepositFundsByCountries: [],
  restrictOpenAdditionAccountCountries: [
    // 5860, //Japan
    6645, //Singapore
  ],
  filterLiveType: ['swapFreeStandardSTP', 'swapFreeRawECN'],
  filterMenaLiveType: ['swapFree10DaysStandardSTP', 'swapFree10DaysRawECN'],
  filterSwapCurrencyList: ['USD'],
  swapFreeCountries: [
    country.countryCodeEnum.INDIA,
    country.countryCodeEnum.MALAYSIA,
    country.countryCodeEnum.INDONESIA,
    country.countryCodeEnum.TURKEY,
  ],
  directStpCountries: [
    country.countryCodeEnum.CHINA,
    country.countryCodeEnum.SOUTH_KOREA,
    country.countryCodeEnum.BRUNEI_DARUSSALAM,
    country.countryCodeEnum.MYANMAR,
    country.countryCodeEnum.CAMBODIA,
    country.countryCodeEnum.TIMOR_LESTE,
    country.countryCodeEnum.LAO_PEOPLE_REPUBLIC,
    country.countryCodeEnum.PHILIPPINES,
    country.countryCodeEnum.SINGAPORE,
    country.countryCodeEnum.THAILAND,
    country.countryCodeEnum.VIETNAM,
    country.countryCodeEnum.MALAYSIA,
    country.countryCodeEnum.INDONESIA,
    country.countryCodeEnum.INDIA,
    country.countryCodeEnum.TURKEY,
  ],
  swapFreeStpCountries: [
    country.countryCodeEnum.MALAYSIA,
    country.countryCodeEnum.INDONESIA,
    country.countryCodeEnum.INDIA,
    country.countryCodeEnum.TURKEY,
  ],
  swapFreeMenaCountries: [
    country.countryCodeEnum.ALGERIA,
    country.countryCodeEnum.BAHRAIN,
    country.countryCodeEnum.EGYPT,
    country.countryCodeEnum.IRAN,
    country.countryCodeEnum.IRAQ,
    country.countryCodeEnum.ISRAEL,
    country.countryCodeEnum.JORDAN,
    country.countryCodeEnum.KUWAIT,
    country.countryCodeEnum.LEBANON,
    country.countryCodeEnum.LIBYA,
    country.countryCodeEnum.MOROCCO,
    country.countryCodeEnum.OMAN,
    country.countryCodeEnum.QATAR,
    country.countryCodeEnum.SAUDI_ARABIA,
    country.countryCodeEnum.SYRIA,
    country.countryCodeEnum.TUNISIA,
    country.countryCodeEnum.UNITED_ARAB_EMIRATES,
    country.countryCodeEnum.PALESTINIAN,
    country.countryCodeEnum.YEMEN,
    country.countryCodeEnum.SUDAN,
  ],
  openLiveAccount: {
    choosePlatform: [
      {
        img: require('@/assets/images/openAccount/choose_platform_img1.png'),
        value: 'mt4',
      },
      {
        img: require('@/assets/images/openAccount/choose_platform_img2.png'),
        popular_img: require('@/assets/images/register/popular.png'),
        value: 'mt5',
      },
    ],
    chooseLiveType: [
      {
        img: require('@/assets/images/openAccount/demo_stp.png'),
        value: 'standardSTP',
      },
      {
        img: require('@/assets/images/openAccount/demo_ecn.png'),
        value: 'rawECN',
      },
      {
        img: require('@/assets/images/openAccount/choose_type_img5.png'),
        value: 'PAMM',
      },
      {
        img: require('@/assets/images/openAccount/choose_type_img3.png'),
        value: 'swapFreeStandardSTP',
      },
      {
        img: require('@/assets/images/openAccount/choose_type_img4.png'),
        value: 'swapFreeRawECN',
      },
      {
        img: require('@/assets/images/openAccount/swapFreeDirectStp.png'),
        value: 'swapFree10DaysStandardSTP',
      },
      {
        img: require('@/assets/images/openAccount/swapFreePrimeEcn.png'),
        value: 'swapFree10DaysRawECN',
      },
    ],
    mt4RegisterChooseType: [
      {
        img: require('@/assets/images/openAccount/demo_stp.png'),
        value: 'standardSTP',
      },
      {
        img: require('@/assets/images/openAccount/demo_ecn.png'),
        value: 'rawECN',
      },
      {
        img: require('@/assets/images/openAccount/choose_type_img5.png'),
        value: 'PAMM',
      },
      {
        img: require('@/assets/images/openAccount/choose_type_img3.png'),
        value: 'swapFreeStandardSTP',
      },
      {
        img: require('@/assets/images/openAccount/choose_type_img4.png'),
        value: 'swapFreeRawECN',
      },
      {
        img: require('@/assets/images/openAccount/swapFreeDirectStp.png'),
        value: 'swapFree10DaysStandardSTP',
      },
      {
        img: require('@/assets/images/openAccount/swapFreePrimeEcn.png'),
        value: 'swapFree10DaysRawECN',
      },
    ],
    mt4DemoChooseType: [
      {
        img: require('@/assets/images/openAccount/demo_stp.png'),
        value: 'standardSTP',
      },
      {
        img: require('@/assets/images/openAccount/demo_ecn.png'),
        value: 'rawECN',
      },
    ],
    mt5ChooseType: [
      {
        img: require('@/assets/images/openAccount/demo_stp.png'),
        value: 'standardSTP',
      },
      {
        img: require('@/assets/images/openAccount/demo_ecn.png'),
        value: 'rawECN',
      },
      {
        img: require('@/assets/images/openAccount/choose_type_img3.png'),
        value: 'swapFreeStandardSTP',
      },
      {
        img: require('@/assets/images/openAccount/choose_type_img4.png'),
        value: 'swapFreeRawECN',
      },
      {
        img: require('@/assets/images/openAccount/swapFreeDirectStp.png'),
        value: 'swapFree10DaysStandardSTP',
      },
      {
        img: require('@/assets/images/openAccount/swapFreePrimeEcn.png'),
        value: 'swapFree10DaysRawECN',
      },
    ],
    mt5RegisterChooseType: [
      {
        img: require('@/assets/images/openAccount/demo_stp.png'),
        value: 'standardSTP',
      },
      {
        img: require('@/assets/images/openAccount/demo_ecn.png'),
        value: 'rawECN',
      },
      {
        img: require('@/assets/images/openAccount/choose_type_img5.png'),
        value: 'PAMM',
      },
      {
        img: require('@/assets/images/openAccount/choose_type_img3.png'),
        value: 'swapFreeStandardSTP',
      },
      {
        img: require('@/assets/images/openAccount/choose_type_img4.png'),
        value: 'swapFreeRawECN',
      },
      {
        img: require('@/assets/images/openAccount/swapFreeDirectStp.png'),
        value: 'swapFree10DaysStandardSTP',
      },
      {
        img: require('@/assets/images/openAccount/swapFreePrimeEcn.png'),
        value: 'swapFree10DaysRawECN',
      },
    ],
    mt5DemoChooseType: [
      {
        img: require('@/assets/images/openAccount/demo_stp.png'),
        value: 'standardSTP',
      },
      {
        img: require('@/assets/images/openAccount/demo_ecn.png'),
        value: 'rawECN',
      },
    ],
    chooseCurrency: [
      {
        img: require('@/assets/images/flag/us_flag.png'),
        value: '$ USD',
        currency: 'USD',
      },
      {
        img: require('@/assets/images/flag/uk_flag.png'),
        value: '£ GBP',
        currency: 'GBP',
      },
      {
        img: require('@/assets/images/flag/ca_flag.png'),
        value: '$ CAD',
        currency: 'CAD',
      },
      {
        img: require('@/assets/images/flag/eu_flag.png'),
        value: '€ EUR',
        currency: 'EUR',
      },
      {
        img: require('@/assets/images/flag/sg_flag.png'),
        value: '$ SGD',
        currency: 'SGD',
      },
      {
        img: require('@/assets/images/flag/nz_flag.png'),
        value: '$ NZD',
        currency: 'NZD',
      },
      {
        img: require('@/assets/images/flag/hk_flag.png'),
        value: '$ HKD',
        currency: 'HKD',
      },
      {
        img: require('@/assets/images/flag/jp_flag.png'),
        value: '￥ JPY',
        currency: 'JPY',
      },
      {
        img: require('@/assets/images/flag/brl_flag.png'),
        value: 'R$ BRL',
        currency: 'BRL',
        hiddenPAMM: true,
      },
    ],
    chooseLeverage: [100, 200, 300, 400, 500],
    chooseBalance: [
      { value: 1000, name: '$1000' },
      { value: 2500, name: '$2,500' },
      { value: 5000, name: '$5k' },
      { value: 10000, name: '$10k' },
      { value: 25000, name: '$25k' },
    ],
    getAgreeCondition: 'openAdditAcc.vfsc.disclaimer',
  },
  metaTraderLinks(url) {
    return {
      mt4: {
        METATRADER4_windows: `https://download.mql5.com/cdn/web/17777/mt4/monetamarkets4setup.exe`,
        METATRADER4_iOS: `https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.dmg`,
        METATRADER4_iPhone: `https://download.mql5.com/cdn/mobile/mt4/ios?server=MonetaMarkets-Demo,MonetaMarkets-Demo%202,MonetaMarkets-Live%203,MonetaMarkets-Live%206,MonetaMarkets-Live01`,
        METATRADER4_Android:
          'https://download.mql5.com/cdn/mobile/mt4/android?server=MonetaMarkets-Demo,MonetaMarkets-Demo%202,MonetaMarkets-Live%203,MonetaMarkets-Live%206,MonetaMarkets-Live01',
        METATRADER4_WebTrader: `https://webtrader.monetamarkets.com/`,
      },
      mt5: {
        METATRADER5_windows: `https://download.mql5.com/cdn/web/moneta.markets.pty/mt5/monetamarkets5setup.exe`,
        METATRADER5_iOS: `https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg`,
        METATRADER5_iPhone: `https://download.mql5.com/cdn/mobile/mt5/ios?server=MonetaMarkets-Demo,MonetaMarkets-Live`,
        METATRADER5_Android: `https://download.mql5.com/cdn/mobile/mt5/android?server=MonetaMarkets-Demo,MonetaMarkets-Live`,
        METATRADER5_Android_China: `https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/metatrader5.apk?utm_source=www.metatrader5.com&utm_campaign=install.metaquotes`,
        METATRADER5_WebTrader: `https://webtrader.monetamarkets.com/`,
      },
      appTrader: {
        APPTRADER_iOS: `https://moneta-website.onelink.me/5hS8/ukk3phxn  `,
        APPTRADER_Android: `https://moneta-website.onelink.me/5hS8/qblueav1  `,
        PROTRADER: `https://protrader.monetamarkets.com/`,
      },
    }
  },
  bankInfoDetails: {
    bank_name: { show: true, value: 'National Australia Bank' },
    branch_address: { show: true, value: '292 Pitt St, Sydney, 2000, Australia' },
    branch_name(currency) {
      return { show: false, value: '' }
    },
    account_name(currency) {
      return { show: true, value: `VIG CLIENT SEGREGATED ACCOUNT ${currency}` }
    },
    account_number(currency) {
      const value = currency == 'AUD' ? '511544202' : `VIGHO${currency}01`
      return { show: true, value: value }
    },
    beneficiary_address(currency) {
      return { show: false, value: '' }
    },
    bsb_number(currency) {
      return { show: false, value: '082-080' }
    },
    swift_code: { show: true, value: 'NATAAU3303M' },
    sort_code: { show: false, value: '' },
    IBAN: { show: false, value: '' },
  },
  withdrawSetup: {
    checkMethodMinAmount: true,
    cancelWithdraw: true,
  },
  withdrawMethods: {
    1: 'Credit/Debit Card',
    2: {
      1: 'Bank Transfer (Australia)',
      2: 'Bank Transfer (International)',
      3: 'Bank Transfer (China)',
    },
    3: 'Skrill / Neteller',
    4: 'Union Pay',
    5: 'Internet Banking (Thailand)',
    6: 'Internet Banking (Malaysia)',
    7: 'FasaPay',
    8: 'Internet Banking (Vietnam)',
    9: 'Internet Banking (Nigeria)',
    24: 'Internet Banking (India)',
    31: 'Skrill',
    32: 'Neteller',
    33: 'Paypal',
    34: 'Cryptocurrency-Bitcoin',
    35: 'Internet Banking (Korea)',
    36: 'Cryptocurrency-USDT',
    37: 'Bitwallet',
    38: 'SticPay',
    39: 'Internet Banking (Indonesia)',
    40: 'Internet Banking (Philippine)',
    41: 'astropay',
    42: 'Internet Banking (South Africa)',
    43: 'WebMoney',
    44: 'Vload',
    47: 'Internet Banking (Laos)',
    48: 'DasshpeUPI',
    49: 'Internet Banking (HK)',
    50: 'Internet Banking (Uganda)',
    51: 'Internet Banking (Rwanda)',
    52: 'Internet Banking (Zambia)',
    53: 'Internet Banking (Congo)',
    54: 'Internet Banking (Cameroon)',
    55: 'Internet Banking (Burundi)',
    56: 'Internet Banking (Kenya)',
    57: 'Internet Banking (Ghana)',
    58: 'Internet Banking (Tanzania)',
    62: 'Internet Banking (Japan)',
    63: 'Internet Banking (Mexico)',
    64: 'Internet Banking (Brazil)',
    69: 'ADVcash',
    80: 'Cryptocurrency-ETH',
    81: 'Cryptocurrency-USDC(ERC20)',
    88: 'Internet Banking (Chile)',
    89: 'Internet Banking (Colombia)',
    90: 'Internet Banking (Peru)',
    97: 'Ewallet',
    100: 'PIX',
    101: 'Internet Banking (USD)',
  },
  banner(url) {
    return [
      {
        img: require('@/assets/images/banner/banner_2.png'),
        divInfo: `<p class='p3'>Unlock <span>Market Masters</span> and access <br></p><p class="p2"><span>130+ </span>video trading tutorials!</p>`,
        specificButton: 'FIND OUT MORE',
        url: `https://www.monetamarkets.com/clients/tools/market-masters/`,
      },
      {
        img: require('@/assets/images/banner/banner_3.png'),
        divInfo: `<p class='p1'>Get <span>free forex signals,</span> <br></p><p class="p3">AI market buzz and <span>much more!</span></p>`,
        specificButton: 'FIND OUT MORE',
        url: `https://www.monetamarkets.com/clients/tools/trading-tools/`,
      },
      {
        img: require('@/assets/images/banner/banner_5.png'),
        rightInfo: `<p class='p2'>Enjoy 100% MT4 and MT5<br></p><p class="p2">uptime with our <span>FREE VPS!</span></p>`,
        rightButton: 'FIND OUT MORE',
        url: `https://www.monetamarkets.com/clients/tools/forex-vps/`,
      },
    ]
  },
  isPageVisibleByIdPoa: {
    MARKET_BUZZ: false,
    ECON_CALENDAR: false,
    FEAT_FX: false,
    FX_IDEAS: false,
    technicalAnalysis: false,
    TradingVideoTutorials: false,
  },
  isPageVisibleByTradableAccounts: {
    MARKET_BUZZ: false,
    ECON_CALENDAR: false,
    FEAT_FX: false,
    FX_IDEAS: false,
    TradingVideoTutorials: false,
  },
  i18n: {
    authority: { dialog2: 'register.authority.dialog2' },
  },
  depositBankTransferSelection: {
    6845: [
      //India
      { label: 'Primary', value: 5, name: 'Dasshpe', default: true },
      { label: 'Secondary', value: 1, name: 'Paygate', default: false },
    ],
    4131: [
      //Philippines
      { label: 'Primary', value: 1, name: 'Zotapay', default: true },
      // { label: 'Secondary', value: 2, name: 'PaymentAsia', default: false },
    ],
  },
}
