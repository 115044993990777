<template>
  <TradingViewColumnDefault :mtServer="mtServer" :isHiddenTable="isHiddenTable"></TradingViewColumnDefault>
</template>

<script>
import TradingViewColumnDefault from '@/components/template/home/TradingViewColumnDefault';

export default {
  props: { mtServer: Number, isHiddenTable: Boolean },
  components: { TradingViewColumnDefault }
};
</script>
