export default {
  setAsicUpgradeStep(state, value) {
    state.asicUpgradeStep = value
  },
  setFcaUpgradeStep(state, value) {
    state.fcaUpgradeStep = value
  },
  setProClientType(state, value) {
    state.proClientType = value
  },
  setFcaSelectedCategorization(state, value) {
    state.fcaSelectedCategorisation = value
  },
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      if (key == 'asicUpgradeStep') state[key] = ''
      else state[key] = []
    })
  },
  setWealthQuestion(state, value) {
    state.wealthQuestion = value
  },
  setExtraQuestion(state, value) {
    state.extraQuestion = value
  },
}
