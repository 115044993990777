export default {
  setEligibleCampaigns(state, value) {
    state.eligibleCampaigns = value
  },

  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      state[key] = []
    })
  },
}
