<template>
  <Default ref="regulator" :countries="countries"></Default>
</template>

<script>
import Default from '@/components/template/register/userAddress/Default';

export default {
  data() {
    return {
      excludeCountries: [
        3512, // Austria
        6645, //Singapore
        6907 //UNITED KINGDOM
      ]
    };
  },
  components: { Default },
  computed: {
    countries() {
      return this.$store.state.register.countries.filter(f => !this.excludeCountries.includes(f.id));
    }
  }
};
</script>
