export default {
  common: {
    liveChat: { desc: '<li><p>도움이 필요하신가요?</p><span>실시간 채팅</span></li>' },
    country: { international: '국제은행송금', australia: '호주', uk: '영국' },
    verification: { toSlide: '퍼즐을 완료하려면 미세요.' },
    hover: {
      BankName: '자금을 수령하는 은행 또는 금융 기관의 이름.',
      BankBeneficiaryName: '자금을 수령하는 은행 계좌를 소유한 개인 또는 회사의 이름.',
      AccountHoldersAddress: '은행 계좌를 소유한 개인 또는 회사의 실제 주소.',
      BankAddress: '자금을 수령하는 은행 또는 금융 기관의 실제 주소.',
      BankAccountNumber:
        '은행 계좌 번호 또는 IBAN(국제 은행 계좌 번호)은 은행 계좌를 식별하는 데 사용되는 번호입니다.',
      SwiftCode:
        '은행 식별 코드(BIC)라고도 하는 스위프트(SWIFT) 코드는 국가, 은행 및 지점을 식별하는 데 사용되는 국제 코드로, 해외 송금 시 필요합니다.',
    },
    field: {
      nameOnCard: '카드소유자영문명',
      first4Digits: '카드 앞 4자리 숫자',
      last3Digits: '카드 끝 3자리 숫자',
      first6Digits: '카드 앞 6자리 숫자',
      last4Digits: '카드 끝 4자리 숫자',
      bankName: '은행 이름',
      bankAddress: '은행주소',
      branchAddress: '지점주소',
      bankBranch: '은행지점',
      bankCity: '시/군/구',
      bankProvince: '시/도',
      bankCode: '은행 코드',
      bankBranchCode: '지점 코드',
      bankBeneficiaryName: '수취인명',
      bankBeneAccName: '수취인명',
      bankBeneAccNum: '수취인계좌번호',
      bankBeneAddress: '수취인주소',
      bankAccNum: '은행계좌번호',
      bankAccName: '은행계좌이름',
      bankHolderAddress: '예금주주소',
      address: '상세주소',
      accName: '예금주',
      accNum: '계좌번호',
      accNumTo: '계좌번호',
      bsbNum: 'BSB 번호',
      swift: '스위프트코드 (SWIFT code)',
      region: '지역',
      sort: '식별 코드',
      ifscCode: 'IFSC 코드',
      swiftNonAUD: '스위프트코드 (AUD 계좌 외)',
      amt: '금액',
      amtUSD: '금액 (USD)',
      imptNotes: '비고',
      yes: '예',
      no: '아니오',
      payMethod: '지불방식 :',
      expDate: '유효기간',
      bsb: 'BSB',
      abaSortCode: 'ABA/식별 코드:',
      country_region: '국가/지역',
      countryCode: '국가',
      currLeverage: '현재 레버리지',
      accEquity: '계좌 잔액',
      newLeverage: '신청 레버리지',
      currPw: '현재 비밀번호',
      newPw: '새 비밀번호 입력',
      confirmPw: '새 비밀번호 확인',
      loginEmail: '이메일 로그인',
      email: '이메일',
      pw: '비밀번호',
      accType: '계좌유형',
      name: '성명',
      emailAdd: '이메일',
      phone: '연락처',
      dob: '생년월일',
      nat: '국가',
      country: '거주지',
      street: '상세주소',
      suburbOrCity: '시/도',
      provinceOrState: '시/군/구',
      postcode: '우편번호',
      empStat: '고용상태',
      occupation: '직업',
      annIncome: '연수입',
      save: '저축/투자',
      source: '소득근원',
      industry: '직업',
      statusDef: '처리현황설명',
      month: '월',
      year: '년도',
      select: '선택',
      completed: '승인',
      incomplete: '미완료',
      submitted: '제출됨',
      successful: '성공',
      processing: '처리중',
      cancelled: '취소됨',
      failed: '실패',
      rejected: '거절',
      upload: '업로드',
      ccNum: '신용카드번호',
      bankCardNumber: '은행카드번호',
      cardHolderName: '카드소유자영문명',
      reservedMobileNumber: '은행에 등록된 핸드폰 번호',
      nationalId: '주민등록증',
      cardPhoto: '카드 앞면 사진',
      notes: '비고',
      bankAccOrIBAN: '은행계좌번호/IBAN',
      selectWithdrawCard: '출금하고자 하는 카드를 선택 하십시오.',
      updateCardPhoto: '카드 앞면 사진을 업로드 하십시오.',
      transitNumber: '은행 등록 번호',
      institutionNumber: '기관 번호',
      wallet: '지갑',
      resetBalTo: '다음 금액으로 잔액 재설정',
      confirmation: '계속 진행하시겠습니까?',
      removeNote: '이 기록을 정말 삭제하시겠습니까? 이 과정은 취소할 수 없습니다.',
      depositCurrency: '예금 통화',
      bankAccountCurrency: '은행 계좌 통화',
      ipChangeWarning: '다른 위치에서 계정에 로그인되면 알림 이메일을 받게 됩니다',
      documentType: '문서 유형',
      documentDetails: '문서 세부정보',
      pixKeyType: 'PIX 키 유형',
      pixKey: 'PIX 키',
      enterVerificationCode: '확인 코드 입력',
      accDigit: '계정 숫자',
      docID: '문서 ID',
      paymentAccName: '{name} 예금주',
      paymentAccNum: '{name} 계좌번호',
      beneficiaryIBANNumber: '수취인의 IBAN 번호',
      beneficiaryBankSwift: '수취인 은행 SWIFT 코드',
      beneficiaryBankName: '수취인 은행명',
      beneficiaryBankAddress: '수취인 은행 주소',
      beneficiaryBankSortCode: '수취인 은행 소트 코드',
      beneAccNum: '수취인 계좌 번호',
      reversedfailed: '실패된 역',
      cardNumber: '카드 번호',
      walletNumber: '{name} 지갑 번호',
      beneficiaryName: '수취인 이름',
    },
    column: {
      date: '일시',
      type: '유형',
      server: '서버',
      status: '처리현황',
      comment: '비고',
      accNum: '계좌번호',
      currency: '통화',
      equity: '잔액',
      credits: '크레딧',
      balance: '균형',
      leverage: '레버리지',
      resetMT4PW: 'MT4 비밀번호 재설정',
      resetMT5PW: 'MT5 비밀번호 재설정',
      tradingAcc: '거래 계좌',
      method: '방식',
      amt: '금액',
      procNote: '처리정보',
      number: '카드번호',
      resetBal: '잔액 재설정',
      remove: '삭제',
    },
    key: { from: '보내는계좌', to: '받는계좌' },
    fund: {
      must: '필수사항',
      infoHover: '카드 영문명은 {must} 당사의 계좌 개설시의 영문명과 동일해야 합니다.',
      ccUploadReminder:
        '최초 인증을 위해 카드 앞 면의 사진을 업로드 하십시오. (사진 촬영시 카드 중간 6자리를 가려주시길 바랍니다.)',
    },
    button: {
      bkToHm: '처음으로 돌아가기',
      smsLogin: '모바일 로그인',
      userLogin: '이메일 로그인',
      goToIB: '파트너포털접속',
      logout: '로그아웃',
      submit: '제출하기',
      selectFile: '찾아보기',
      depositFunds: '자금예치',
      withdrawFunds: '출금신청',
      ibPortal: '파트너포털',
      download: '다운로드',
      changeLeverage: '레버리지변경',
      cancel: '취소',
      changePw: '비밀번호변경',
      forgetPw: '비밀번호찾기',
      confirm: '확인',
      login: '로그인',
      forgotPw: '비밀번호찾기',
      completeQuestionnaire: '설문지작성',
      identityProof: '지원서작성',
      upload: '업로드',
      continue: '계속하기',
      enable: '활성화',
      disable: '비활성화',
      export: '내보내기',
      ok: '확인',
      hide: '숨기기',
      unhide: '숨기기 해제',
      showAll: '모두 보기',
      beginner: '초급',
      intermediate: '중급',
      advanced: '고급',
      sendCode: '코드 발송',
      resendCode: '코드 재전송',
    },
    formValidation: {
      common: '이 입력란은 필수입니다.',
      accReq: '계좌번호를 입력하십시오.',
      amtReq: '금액을 입력하십시오.',
      ccReq: '신용카드번호를 입력하십시오.',
      cardReq: '카드번호를 입력하십시오.',
      cardOnNameReq: '카드상 영문명을 입력하십시오.',
      digits16: '카드 16자리 숫자를 입력하십시오.',
      digits3or4: '숫자 3자리 혹은 4자리를 입력하십시오.',
      digitsFirst6: '첫 6자리를 입력하십시오.',
      digitsLast4: '뒷 4자리를 입력하십시오.',
      digitsLast3: '뒷 3자리를 입력하십시오.',
      mid6Digits: '중간 {number}자리를 입력하십시오.',
      expDateReq: '유효기간을 입력하십시오.',
      cvvReq: 'CVV보안코드를 입력하십시오.',
      file: '파일을 업로드 하십시오.',
      filePhoto: '신용카드 사진을 업로드 하십시오.',
      fileReceipt: '영수증을 업로드 하십시오.',
      filePhotoReq: '카드 사진을 업로드 하십시오.',
      amtLarger:
        '금액은 빈칸으로 제출할 수 없으며 ${minLimit} {currency}보다 크거나 같아야 합니다.',
      withdrawAmtLarger: '최소 출금 금액은 ${minLimit} {currency} 또는 그에 상응하는 금액입니다.',
      amt0: '0 이상의 금액을 입력하십시오.',
      amtLess: '입금액은 ${maxLimit} {currency}를 초과할 수 없습니다',
      note256: '글자수는 최대 256자 입니다.',
      noteMoreThan: '글자수는 최대 {limit}자 입니다.',
      noteLessThan: '글자수는 최대 {limit}자 입니다.',
      emailFormat: '올바른 이메일을 입력해 주십시오.',
      payMethodReq: '올바른 지불방식을 선택해 주십시오.',
      expReq: '유효기간을 입력하십시오.',
      bankNameReq: '은행명을 입력하십시오.',
      bankCityReq: '은행주소(City시/구/군)를 입력하십시오.',
      bankProvinceReq: '은행주소(Province 시/도)를 입력하십시오.',
      bankAddressReq: '은행주소(상세주소)를 입력하십시오.',
      bankBranchReq: '은행지점 (Bank Branch)을 입력하십시오.',
      bankAccNumReq: '은행계좌번호를 입력하십시오.',
      ifscCodeReq: 'IFSC 코드는 필수 항목입니다',
      bankBeneficiaryNameReq: '계좌예금주를 입력하십시오.',
      bankHolderAddressReq: '계좌예금주의 주소를 입력하십시오.',
      swiftReq: '스위프트코드를 입력하십시오.',
      accHolderAddress: '계좌예금주의 주소를 입력하십시오.',
      bsbReq: 'BSB를 입력하십시오.',
      accNumIBANReq: '은행계좌번호/IBAN 번호를 입력하십시오.',
      accNameReq: '은행계좌번호를 입력하십시오.',
      withdrawMethodReq: '출금방식을 선택하십시오.',
      tnc: '약관을 읽고 동의하시길 바랍니다.',
      currPwReq: '기존 비밀번호를 입력하십시오.',
      currPwWrong: '기존 비밀번호가 올바르지 않습니다.',
      newPwReq: '새 비밀번호를 입력해주십시오.',
      newPwFormat:
        '암호는 8-16자 사이여야 하며 문자(a~z 및 A~Z), 숫자(0~9) 및 !@#$%^&*.() 와 같은 특수 문자가 혼합되어 있어야 합니다.',
      newPwAccFormat:
        '암호는 8-16자 사이여야 하며 문자(a~z 및 A~Z), 숫자(0~9) 및 !@#$%^&* 와 같은 특수 문자가 혼합되어 있어야 합니다.',
      IdenficalPw: '새 비밀번호는 기존 비밀번호와 다르게 설정하십시오.',
      confirmPwReq: '새 비밀번호를 다시 입력해주십시오.',
      confirmNotMatch: '두 입력이 일치하지 않습니다.',
      pwReq: '비밀번호를 입력하십시오.',
      accCurrReq: 'MT4 계좌 통화를 선택하십시오.',
      tradAccReq: '거래 계좌를 선택하십시오.',
      answerReq: '선택하기',
      bankCardReq: '카드를 선택하십시오.',
      phoneNumberReq: '은행에 등록된 핸드폰 번호를 입력해 주십시오.',
      nationaIdReq: '유효한 신분증번호를 입력해 주십시오.',
      numberReq: '번호를 입력해주십시오.',
      alphanumericReq: '영숫자를 입력해주십시오.',
      idTypeReq: '신분증명유형을 선택해주십시오.',
      dobReq: '생년월일을 선택해주십시오.',
      pobReq: '출생지를 선택해주십시오.',
      nationalityReq: '국적을 선택해주십시오.',
      titleReq: '호칭을 선택해주십시오.',
      firstNameReq: '이름은 필수입력 사항입니다.',
      lastNameReq: '성은 필수입력 사항입니다.',
      emailReq: '이메일을 입력해주십시오.',
      addressReq: '상세 주소를 입력해주십시오.',
      suburbReq: '시/군/구를 입력해주십시오.',
      stateReq: '시/도를 입력해주십시오.',
      postcodeReq: '우편번호를 입력해주십시오.',
      countryReq: '거주 국가를 입력해주십시오.',
      NIReq: '국민 보험 (NI) 번호를 입력하세요',
      taxResidencyReq: '세금 거주 국가를 선택하십시오',
      yearsAtAddressReq: '주소에 연수를 입력하십시오',
      bankCodeReq: '카드발급은행을 입력해주십시오.',
      bankBranchCodeReq: '지점 코드는 필수 항목입니다.',
      dynamicReq: '{dynamic} 은 필수입력 사항입니다.',
      mobReq: '전화 번호 입력해주십시오.',
      confirmed: '확인해주세요',
      resetBalAmt: '0에서 1,000,000 사이의 금액을 설정하세요',
      verCodeReq: '확인 코드가 필요합니다',
      noEligibleAccounts: '자격 있는 계정 없음',
      AlphanumericOnlyNotSpace: '영문자와 숫자만 허용됩니다. 공백은 허용되지 않습니다.',
      AlphanumericOnly: '영문자 및 숫자만 허용됩니다.',
      NumbersSymbol: '숫자 및 기호만 허용됩니다.',
      NumbersOnlyNotSpace: '숫자만 허용됩니다. 공백은 허용되지 않습니다.',
      NumbersOnly: '숫자만 허용됩니다.',
      LettersSymbol: '문자와 기호만 허용됩니다.',
      LettersSymbolNotSpace: '문자와 기호만 허용됩니다. 공백은 허용되지 않습니다.',
      LettersOnlyNotSpace: '문자만 허용됩니다. 공백은 허용되지 않습니다.',
      NotSpace: '공백은 허용되지 않습니다.',
      beneficiaryNameReq: '수혜자 이름이 필요합니다',
    },
    withdrawChannel: {
      banktransferbpaypolipay: '은행송금/Bpay/PoliPay',
      banktransfer: '해외송금',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: '유니온페이',
      unionpaytransfer: '유니온페이이체',
      fasapay: 'FasaPay',
      creditcard: '신용카드 ',
      archiveThisCard: '이 카드가 더 이상 유효하지 않거나 사용 중인 경우 보관하십시오.',
      update: '업데이트',
      archive: '보관',
      updateExpiryMsg: '인출 시 카드를 사용하려면 카드의 만료 날짜를 업데이트하십시오.',
      available: '사용 가능 ',
      pending: '보류 ',
      unavailable: '사용 불가능 ',
      fullyRefunded: '전액 환불 ',
      requiredDocument: '카드 보관에 필요한 문서 ',
      officialLetter: '카드를 더 이상 사용할 수 없다는 은행 편지/이메일 명시',
      bankStatement: '이 카드로 이루어진 거래가 있는 은행 명세서',
      expiredCard: '만료/사용할 수 없는 카드 ',
      confirmArchive: '이 카드를 보관하시겠습니까?',
      confirmUpdate:
        '이 카드의 만료 날짜를업데이트하시겠습니까?참고:제공하신 만료 날짜가 잘못되어 인출에 실패할 경우 Moneta는 책임을 지지 않습니다.',
      bpay: 'Bpay',
      creditdebitcard: '신용/체크 카드',
      bankwiretransferinternational: '해외송금(국제)',
      banktransferaustralia: '은행송금(오스트레일리아)',
      banktransferinternational: '해외송금',
      banktransferchina: '은행송금(국제)',
      banktransferuk: '은행송금(영국)',
      mobilepay: '모바일결제',
      brokertobrokertransfer: '브로커 간 이체',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: '인터넷뱅킹 (태국)',
      internetbankingnigeria: '인터넷뱅킹 (나이지리아)',
      internetbankingvietnam: '인터넷뱅킹 (베트남)',
      internetbankingmalaysia: '인터넷뱅킹 (말레이시아)',
      internetbankingindonesia: '인터넷뱅킹 (인도네시아)',
      internetbankingkorea: '인터넷뱅킹 (한국)',
      internetbankingsouthkorea: '인터넷뱅킹 (대한민국)',
      internetbankingphilippine: '인터넷뱅킹 (필리핀 제도)',
      internetbankingsouthafrica: '인터넷뱅킹 (남아프리카)',
      internetbankinguk: '인터넷뱅킹 (영국)',
      internetbankinglaos: '인터넷뱅킹 (라오스)',
      internetbankingindia: '인터넷 뱅킹(인도)',
      internetbankinghk: '인터넷 뱅킹(홍콩)',
      internetbankinguganda: '인터넷뱅킹 (우간다)',
      internetbankingrwanda: '인터넷뱅킹 (르완다)',
      internetbankingzambia: '인터넷뱅킹 (잠비아)',
      internetbankingcongo: '인터넷뱅킹 (콩고)',
      internetbankingcameroon: '인터넷뱅킹 (카메룬)',
      internetbankingburundi: '인터넷뱅킹 (부룬디)',
      internetbankingkenya: '인터넷뱅킹 (케냐)',
      internetbankingghana: '인터넷뱅킹 (가나)',
      internetbankingtanzania: '인터넷뱅킹 (탄자니아)',
      internetbankingbrazil: '인터넷뱅킹 (브라질)',
      internetbankingmexico: '인터넷 뱅킹(멕시코)',
      internetbankingcolombia: '인터넷 뱅킹(콜롬비아)',
      internetbankingchile: '인터넷 뱅킹(칠레)',
      internetbankingperu: '인터넷 뱅킹(페루)',
      internetbankingusd: '인터넷 뱅킹(USD)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: '베트남 은행송금이체',
      nigeriabanktransfer: '나이지리아 은행송금이체',
      polipayment: 'Poli 결제',
      bankwiretransferaustralia: '은행송금 (오스트레일리아)',
      thailandinstantbankwiretransfer: '태국 은행 계좌이체',
      nigeriainstantbankwiretransfer: '나이지리아 은행계좌이체',
      vietnaminstantbankwiretransfer: '베트남 은행계좌이체',
      laosinstantbankwiretransfer: '라오스 즉시 은행 송금',
      malaysiainstantbankwiretransfer: '말레이시아 은행계좌이체',
      indonesiainstantbankwiretransfer: '인도네시아 은행계좌이체',
      indiainstantbanktransfer: '인도 신속 은행 송금 ',
      southafricainstantbankwiretransfer: '남아프리카 은행계좌이체',
      philippinesinstantbankwiretransfer: '필리핀 제도 은행계좌이체',
      indialocalbankwiretransfer: '인도 현지 은행 송금',
      hongkongbanktransfer: '홍콩 지역 은행 송금',
      ugandabanktransfer: '우간다 국내 은행이체',
      rwandabanktransfer: '르완다 국내 은행 이체',
      zambiabanktransfer: '잠비아 국내 은행 이체',
      congobanktransfer: '콩고 국내 은행 이체',
      cameroonbanktransfer: '카메룬 국내 은행 이체',
      burundibanktransfer: '부룬디 국내 은행 이체',
      kenyabanktransfer: '케냐 국내 은행 이체',
      ghanabanktransfer: '가나 국내 은행 이체',
      tanzaniabanktransfer: '탄자니아 국내 은행 이체',
      brazilbanktransfer: '브라질 은행이체',
      mexicobanktransfer: '멕시코 지역 은행 송금',
      pagsmileBrazil: '브라질 은행계좌이체',
      pagsmileMexico: '멕시코 즉시 은행 송금',
      pagsmileColombia: '콜롬비아 즉시 은행 송금',
      pagsmileChile: '칠레 즉시 은행 송금',
      pagsmilePeru: '페루에서 즉시 은행 송금',
      banktransferbpaypolipayaustralia: '은행송금/BPay/PoliPay (오스트레일리아)',
      banktransferbpaypolipayinternational: '은행송금/BPay/PoliPay (국제)',
      paypal: '페이팔',
      canadianlocalbanktransfer: '캐나다 로컬 은행 계좌 이체',
      cryptobitcoin: '암호 화폐-Bitcoin',
      cryptotetheromni: '암호 화폐-USDT(OMNI)',
      cryptotethererc20: '암호 화폐-USDT(ERC20)',
      cryptotethertrc20: '암호 화폐-USDT(TRC20)',
      cryptocurrencybitcoin: '암호 화폐-Bitcoin',
      cryptocurrencyusdt: '암호 화폐-USDT',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      trustly: 'Trustly',
      astropay: 'Astropay',
      webmoney: 'WebMoney',
      vload: 'Vload',
      dasshpewallet: '인도 지갑',
      cryptocurrencyethcps: '암호 화폐-ETH',
      cryptocurrencyeth: '암호 화폐-ETH',
      cryptocurrencyusdc: '암호 화폐-USDC(ERC-20)',
      cryptocurrencyusdcerc20: '암호 화폐-USDC(ERC-20)',
      cryptocurrencyusdccps: '암호 화폐-USDC(ERC-20)',
      chileBankTransfer: '칠레 은행 송금',
      colombiaBankTransfer: '콜롬비아 은행 송금',
      perubanktransfer: '페루 은행 송금',
      alipay: '알리페이',
    },
  },
  home: {
    pendingTooltip:
      '실 계좌가 승인되면 거래를 시작할 수 있도록 MT{number} 로그인 세부 정보가 포함된 이메일이 전송됩니다! ',
    leverage: {
      tncTitle: '<span>거래 약관</span>',
      tnc: '<p>본인은 이 거래 약관에 동의함으로써 증거금을 동반한 거래 상품의 운용은 고 위험 투자 방식이며, 높은 위험성을 수반하고, 손실 규모는 예치한 자금에 국한되지 않고 더 큰 손실을 입힐 수 있다는 것을 알고 있음을 인정합니다.<br><br>또한 상품 발행자는 사전 경고나 알림 없이 제 거래 활동을 검토하고 거래 계좌의 레버리지 수준을 재량에 따라 조정할 수 있다는 데에 동의합니다.<br><br>높은 레버리지를 선택함으로써 적은 증거금을 활용할 수 있으나 잠재적으로 상당한 손실을 초래할 수 있다는 것을 이해합니다.</p>',
      tncConfirm: '본인은 이 약관을 읽고 동의합니다.',
      changeLeverageConfirm:
        '귀하의 요청은 정상적으로 제출되었으며 곧 담당부서팀에서 처리할 예정입니다.',
      changeLeverageAutoComplete: '레버리지 변경 요청이 완료되었습니다.',
      changeLeverageReject: '레버리지 변경 요청이 거부되었습니다.',
      changeLeverageFail: '레버리지 변경 요청이 실패했습니다. 나중에 시도하십시오.',
      changeLeverageDefault:
        '현재 오류가 발생했습니다. 귀하의 신청을 완료하려면 {supportEmail}에 문의하십시오.',
      alreadyApplied:
        '귀하의 계좌에 대한 레버리지 변경을 이미 요청했으며 현재 {platform} 담당 부서에서 검토 중입니다. 다른 요청을 제출하기 전에 이 요청이 완료될 때까지 기다려 주십시오.',
      reduceLeverageWarn:
        '참고: 계정에 충분한 증거금이 없는 경우, 레버리지를 줄이면 현재 포지션이 강제로 닫힐 수 있습니다.',
      reduceLeverageConfirm: '계속 하시겠습니까?',
      autoRestore:
        '<p>회원님의 자산이 20,000 이상이므로, 사용 가능하신 레버리지는 500:1입니다.</p><br /><p>회원님의 자본이 20,000 미만으로 내려가면 다시 1000:1로 조정할 수 있습니다.</p>',
      oneThousandRatio:
        '<p>1,000:1 레버리지로 전환하려면 아래를 참조하십시오:<br><br>1. 크레딧 관련 프로모션을 신청하실 수 없습니다.<br><br>2. 계정 자본은 15,000 달러 혹은 그에 상당하는 금액을 초과할 수 없습니다.<br><br>3. 계정 자본이 20,000 달러 혹은 그에 상당하는 금액을 초과할 경우 계정 레버리지가 500:1로 전환됩니다.<br><br>4. 레버리지 한도는 해당 규정에 따라 달라질 수 있습니다. <br><br>5. 레버리지 변경은 레버리지가 고정된 제품에는 적용되지 않습니다. <br><br> 6. 고객 계약에 따라 채무 불이행 사유 및/또는 의심스러운 거래 활동에 대한 조항을 위반할 경우 해지 또는 차감도 가능하며, 이에 국한되지 않는 더한 조치가 취해질 수 있습니다.<br><br></p>',
    },
    pw: {
      error: '비밀번호를 재설정하지 못했습니다. 나중에 다시 시도해 주세요.',
      confirm:
        '귀하의 비밀번호가 재설정되었습니다.<br>이제 새 비밀번호를 사용하여 MT4/MT5 거래 계좌에 접속 할 수 있습니다.',
      email:
        '<p class=first_info">귀하의 이메일로 비밀번호 재설정 링크가 포함된 이메일이 발송됬습니다. </p><p>이메일의 스팸 폴더를 확인하세요.</p>',
    },
    activate: {
      question: '실 계좌를 다시 활성화 하시겠습니까?',
      confirm: '귀하의 활성화 요청을 받았습니다.<br>당신의 계좌는 곧 활성화될 것입니다.',
    },
    appNote: { desc02: '자세히 알아보려면 여기를 클릭하세요.' },
    accStatus: {
      active: '활성화',
      rejected: '거절',
      inactive: '비활성화',
      processing: '처리중',
      activate: '활성화됨',
      expired: '만료됨',
    },
    reset: {
      success: '초기화에 성공했습니다.',
      creditTip:
        '계정 잔액이 0 미만입니다. 계정 잔액을 0으로 재설정하면 크레딧도 0으로 재설정됩니다. 계속하시겠습니까?',
      resetBtn: '초기화',
      tableHeader: '마이너스 잔액',
    },
  },
  oneThousandRatioError: {
    error1: '이 계정에는 보너스가 있으며 1000:1 레버리지로 조정할 수 없습니다.',
    error4:
      '계정 자본이 $15,000(또는 동등한 통화)보다 크므로 1000:1 레버리지로 조정할 수 없습니다.',
    error5: 'The ID audit is not pass, cannot be adjusted to 1000:1 leverage',
    error6: '계정으로 보너스 활동에 참여했으므로 1000:1 레버리지로 조정할 수 없습니다.',
  },
  app: {
    title: '운영 방법',
    desc01: '{platform} 다운로드',
    desc02: '기존 이메일로 로그인',
    desc03: '전화번호를 인증해주세요',
    desc04: '설정이 완료되었습니다. 강력한 거래를 즐기세요',
  },
  paymentDetails: {
    subHeader: '지불방식추가',
    payMethod: '지불방식 ',
    tip: '<p>아래 메뉴에서 선호하시는 입출금 방식을 선택하십시오.</p><br />\n    <p><b>주의: </b> 귀하의 입출금 요청이 신속하게 처리될 수 있도록 하기 위해 AML/CTF 규정상 선택한 지불 방법에 표시된 이름이 <b> {individualUserName}</b> 로 되어 있어야 합니다.</p>',
    result: {
      defaultSuccessMessage:
        '거래 계좌에 대한 입 출금 방법을 제출해 주셔서 감사합니다. 현재 귀하의 요청을 처리 중이며 곧 연락드리겠습니다.',
      unionPayCardSuccessMessage:
        '유니온 페이 카드 세부 정보가 성공적으로 제출되었습니다. 담당부서에서 새로운 은행 세부 정보를 승인하면 바로 사용 가능합니다.',
    },
    history: { header: '지불방식' },
    cc: {
      field: {
        ccNum: '신용카드번호',
        first6: '첫번째 여섯번째',
        last4: '마지막 네번째',
        withrawlable: '이 카드의 최대 인출 가능 금액은 {amount}입니다.',
      },
      formValidation: { upload: '신용카드 사진을 업로드 하십시오.' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: '예치 상황을 가져오는 중입니다... {countDown}초 남았습니다.',
      successMsg:
        '입금해 주셔서 감사합니다. 귀하의 입금 금액은 곧 계좌에 반영될 예정입니다. 잠시만 기다려주십시오.',
      rejectMsg: '거절',
      failureMsg: '귀하의 입금이 성공하지 못했습니다. 다시 시도해 주십시오.',
      message:
        '입금해 주셔서 감사합니다. 곧 {platform}담당부서에서 귀하의 예치금을 수동으로 승인할 것입니다. 자금이 승인되면 고객님의 계좌로 반영될 것입니다.',
      defaultException:
        '{platform}의 귀하의 예치금을 확인이 필요하시면, 고객지원팀 {email}이메일로 연락하시기 바랍니다.',
    },
  },
  deposit: {
    default: {
      deposit: '자금예치',
      reference: '참고 사항',
      sendReceipt: '송금 내역서',
      sendReceiptTip:
        '해외 송금 완료 후 아래 양식을 작성하시고 귀하의 계좌 번호와 입금 금액이 명확히 표시된 은행 송금 내역서의 사진 혹은 스캔본을 첨부하여 담당 부서가 신속하게 처리 할 수 있도록 해 주시기 바랍니다.',
      reminder: '귀하의 입금 반영 처리는 영업일 1~2일 정도 소요될 예정입니다. 양해 부탁드립니다.',
      successMsg:
        '귀하가 송금내역서를 업로드 후 당사의 담당부서에서 자금을 확인하면 귀하의 계좌에 반영 처리하게 되며 계좌상에 해당 예치금액이 나타납니다.',
      alt: '또는 {mailTo}로 송금 내역서를 첨부하여 이메일로 보내셔도 됩니다.',
      reminderFundAppear:
        '이전 브로커로부터 자금을 받는 즉시 귀하의 계좌상에 해당 자금이 표시됩니다.',
      failed: '귀하의 입금 신청이 실패했습니다. 잠시 후 다시 시도해 주십시오.',
      fee: '수수료',
      instant: '즉시',
      hour: '소요시간',
      hours: '소요시간',
      businessDays: '영업일 일',
      disclaimer:
        '<li>1. {platform}은 제 3자의 지불을 엄격하게 수락하지 않습니다. 거래 계좌에 입금되는 모든 자금은 {platform} 거래 계좌와 일치하는 본인 명의의 계좌로 진행 되야만 합니다.</li>\n      <li></br>2. 고객의 입금 요청 처리 시 자금 출처를 식별하기 위해 필요하다고 판단되는 경우, 고객의 재산 및 기타 정보 {platform}에 대해 요청 할 수 있음을 인지하고 {platform}에서 요구하는 모든 정보를 제공하기로 동의합니다. 당사에서 요청한 정보를 제공하지 않으면 입금 요청 절차가 지연될 수 있습니다. {platform}은 해당 지연으로 인해 발생한 지연 또는 손실에 대해 책임을 지지 않습니다.</li>\n      <li></br>3. 입금 신청을 제출하는 것이 {platform}에  자금 수령을 구성하는 것은 아닙니다. 이 점을 귀하는 숙지 하고 있어야만 합니다. 만약 귀하의 계좌가 마진콜에 가깝거나 마진콜에 있다면, 다른 준비가 되어 있는지 확인하셔야 합니다.</li>\n      <li></br>4. {platform}은 결제 시스템으로 인한 지연 및 에러로 인한 어떠한 책임을 지지 않습니다.</li>\n      <li></br>5. {platform}은 고객 입출금에 대해 별도의 수수료를 부과하지 않습니다. 단, 금융기관 혹은 페이먼트사측에서 귀하에게 환전 수수료 및 중개은행간 서비스 수수료가 부과될 수는 있으며, 이러한 이유로 발생하는 비용에 대하여 당사는 어떠한 책임도 지지 않습니다.</li>\n      <li> </br>6. Moneta Market의 결제 시스템 설명은 <a href="https://www.monetamarkets.com/" target="_blank">www.monetamarkets.com</a> - Gladstonos & Filokyprou, 120, House Office E2로 표시될 것입니다.<li>',
      channels: {
        creditOrDebit: '신용/체크 카드',
        i12BankTransfer: '해외송금',
        auBankTransfer: '호주은행 계좌이체',
        bPay: 'Bpay 계좌이체',
        skrill: 'Skrill / Moneybookers',
        b2b: '브로커 간 이체',
        unionPay: '유니온페이',
        unionPayCN: '유니온페이계좌이체',
        vietInstant: '베트남 은행 즉시이체',
        thaiInstant: '태국 은행 계좌이체',
        nigeriaInstant: '나이지리아 은행 즉시이체',
        laosInstant: '라오스 즉시 은행 송금',
        malayInstant: '말레이시아 은행 즉시이체',
        indonesiaInstant: '인도네시아 은행 즉시이체',
        philippineInstant: '필리핀 제도 은행 즉시이체',
        mobilePay: '모바일결제',
        poli: 'POLi 입금',
        paypal: 'Paypal 입금',
        mijipay: 'Mijipay 입금',
        crypto: '암호 화폐',
        canada: 'Interac E-Transfer',
        bitwallet: 'bitwallet 입금',
        indiaInstant: '인도 신속 은행 송금 ',
        pagsmileBrazil: '브라질 은행 즉시이체',
        pagsmileMexico: 'Mexico Instant Bank Transfer',
        pagsmileColombia: 'Colombia Instant Bank Transfer',
        pagsmileChile: 'Chile Instant Bank Transfer',
        pagsmilePeru: 'Peru Instant Bank Transfer',
        astropay: 'Astropay 입금',
        dasshpeWallet: '인도 지갑',
        southkoreabanktransfer: '한국 은행 송금',
        alipay: '알리페이',
        indonesiainstantbanktransfer: '인도네시아 은행 즉시이체',
        thailandinstantbanktransfer: '태국 즉시 은행 송금',
      },
      rate: {
        rate: '환율({oldCurrency}/{newCurrency}): ',
        result: {
          rateError: '{oldCurrency}를 {newCurrency} 로 전송이 실패했습니다.',
          channelError: '결제 채널을 가져오지 못했습니다.',
        },
      },
      cn: { rate: '환율(USD/RMB):', amt: 'RMB:' },
      viet: {
        result: { rateError: 'USD를 VND로 전송이 실패했습니다.' },
        rate: '환율(USD/VND):',
        amt: 'VND:',
      },
    },
    method: {
      desc: '{method} 계좌에 입금하는 방법',
      inst1: "{number}.   아래의 양식을 작성한 후 '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2:
        '{number}.   은행 선택 페이지에서 귀하의 {country} 은행을 선택한 후 해당 은행의 온라인뱅킹에 로그인 합니다.',
      inst3:
        '{number}.   지시에 따라 온라인/모바일 뱅크를 통해 다음단계로 넘어가 결제를 진행합니다.',
      inst4: '{number}.   고객 포털 사이트로 이동 후 거래가 완료됩니다',
      inst5: '{number}.   귀하의 {method}계정에 로그인 하십시오.',
      inst6: '{number}.   {method} 계정의 메시지에 따라 결제를 완료합니다.',
      inst7:
        '참고: 수동 입금 처리를 선택하면, 자금이 지연될 수 있으며 거래 계정에 입금되는 데 많은 시간이 소요될 수 있습니다',
      inst8: '{number}.    를 사용하는 경우 입금액은 거래당 {currency} {amount}로 제한됩니다',
      form: { header: '{method} 신청양식' },
      inst12: "{number}.   아래 양식을 작성하고 '제출'을 선택하십시오",
      inst13: '{number}.   모바일 뱅킹 애플리케이션을 열려면 QR 코드를 스캔하십시오',
      inst14: '{number}.   모바일 장치의 프롬프트를 따라 예금을 완료하십시오',
      inst15: '{number}.   아래 양식을 작성하고 ‘제출’ 버튼을 누르세요',
      inst16: "{number}.   카드 정보를 입력한 후 '제출'을 누르십시오",
    },
    cpsInfo: {
      thispaymentchannelistemporaryunavailable: '이 결제 채널은 일시적으로 사용할 수 없습니다.',
    },
    uk: { header: '영국 은행 즉시이체', country: '영국' },
    southAfrica: { header: '남아프리카 은행 즉시이체', country: '남아프리카' },
    idn: { header: '인도네시아 은행 즉시이체', country: '인도네시아' },
    ida: { header: '인도 은행 즉시이체', country: '인도' },
    brazil: { header: '브라질 은행 즉시이체', country: '브라질' },
    nigeria: { header: '나이지리아 은행 즉시이체', country: '나이지리아' },
    thailand: { header: '태국 은행 계좌이체', country: '태국' },
    vietnam: { header: '베트남 은행 즉시이체', country: '베트남' },
    laos: {
      header: '라오스 즉시 은행 송금',
      country: '라오스',
      inst: '2.   OnePay 앱으로 QR 코드 스캔',
    },
    hongkong: {
      country: '홍콩',
      inst1: '2.   안내에 따라 결제를 진행합니다',
      inst2: '3.   거래가 완료됩니다',
      inst3: '결제를 하시려면 아래 안내에 따르세요.',
      inst4: '1.   귀하의 은행 계좌에 로그인하세요.',
      inst5: '2.   다음과 같이 자금을 이체하세요:',
      inst6: '3.   결제 완료 후 완료 버튼을 눌러 진행해주세요.',
      inst7: '계정은 일회성 예금에만 유효합니다.',
      inst8: '알림: ',
      inst9:
        '생성된 이 계좌 번호는 1회성 입금에만 유효하며 자금 입금이 지연되는 것을 방지하기 위해 향후 거래에 사용해서는 안됩니다.',
      inst10: '이체된 결제 금액은 입금 요청한 금액과 일치해야 합니다.',
      inst11:
        '입금 실패를 방지하기 위해 입금 요청을 한 후 1시간 이내에 은행 송금을 해주시기 바랍니다.',
    },
    malay: { header: '말레이시아 은행 즉시이체', country: '말레이시아' },
    php: { header: '필리핀 제도 은행 즉시이체', country: '필리핀 제도' },
    uganda: { header: '우간다 국내 은행이체', country: '우간다' },
    rwanda: { header: '르완다 국내 은행 이체', country: '르완다' },
    zambia: { header: '잠비아 국내 은행 이체', country: '잠비아' },
    congo: { header: '콩고 국내 은행 이체', country: '콩고' },
    cameroon: { header: '카메룬 국내 은행 이체', country: '카메룬' },
    burundi: { header: '부룬디 국내 은행 이체', country: '부룬디' },
    kenya: { header: '케냐 국내 은행 이체', country: '케냐' },
    ghana: { header: '가나 국내 은행 이체', country: '가나' },
    tanzania: { header: '탄자니아 국내 은행 이체', country: '탄자니아' },
    bankWireAu: {
      header: '은행계좌이체: 오스트레일리아',
      instruction:
        '해외송금은 귀하의 명의로 개설된 은행 온라인 뱅킹을 통해 {platform}에 이체를 진행하거나 혹은 직접 은행창구로 방문하여 {platform}에 전신환 송금을 통해 이체를 진행하면 됩니다.',
      tip: '입금하고자 하는 계좌통화를 선택하세요. 거래 계좌의 통화와 동일한 통화를 선택하십시오.',
      form: {
        bankDetails: '은행 세부 정보(AUD 예치만 해당)',
        refTip: '여기에 귀하의 로그인 계좌를 남겨주시길 바랍니다.',
        afp: '호주내 입금방식(호주 국내 AUD 예치만 가능)',
        payIdOrABNNum: 'PayID/ABN 번호',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip: '은행 송금 참조 필드에 {platform} 거래 계좌 번호를 포함하세요.',
      },
    },
    bPay: {
      header: 'Bpay 입금',
      option: 'BPay로 입금 하는 방법 (호주만 해당)',
      inst1: '1. MT4/MT5 계좌를 선택하여 BPay 청구 업체 코드 및 참조 번호를 확인합니다.',
      inst2:
        '2. 결제 세부 정보, BPay 청구 업체 코드 및 참조 번호를 인터넷 뱅킹에서 입력하고 결제 정보를 제출합니다.',
      inst3:
        '3. 이 페이지로 돌아가서 아래 BPay 신청양식을 작성하십시오. 담당부서에서 귀하의 요청을 신속하게 처리할 수 있도록 할 것 입니다.',
      form: {
        header: 'BPay 신청양식',
        billerCode: 'BPay 청구 업체 코드',
        refNum: 'BPay 참조 번호',
        receipt: 'Bpay 이체 영수증',
        reminderNotUploaded:
          '귀하는 이체 영수증을 업로드하지 않았습니다. 신속한 입금 처리를 위해 가능한 한 빨리 영수증 사본을 {mailTo}로 첨부하여 보내주시기 바랍니다.',
        reminderProc: '참고 : 이체 영수증을 받기 전까지는 요청을 처리할 수 없습니다.',
      },
    },
    b2b: {
      header: '브로커 간 이체',
      option: '타사 브로커로부터 {platform}로 자금을 이체 신청 하는 방법',
      inst1: '1. {download} 다운로드하여 설치를 완료합니다.',
      inst2:
        '2. 아래의 온라인 신청 양식을 작성하고 브로커 간 이체 양식을 해당 브로커에 업로드합니다.',
      inst3:
        '3. 브로커 간 이체가 정상적으로 진행될 수 있도록, 작성 완료된 이체 양식 사본을 기존 브로커에게 보내주십시오.',
      b2bForm: '브로커 간 이체 신청양식',
      formValidation: { upload: '브로커 간 이체 신청양식을 업로드 하십시오.' },
    },
    cc: {
      header: '신용 체크카드 입금',
      remind:
        '기본 방법을 사용하여 계정에 자금을 조달하는 데 어려움이있는 경우 보조 신용 / 직불 카드 방법을 사용하십시오',
      field: {
        fundNow: '신용/체크 카드로 자금을 예치합니다.',
        newCard: '새로운 카드',
        cardNum: '신용카드번호',
        nameOnCard: '카드소유자영문명',
        expDate: '유효기간(월/년도)',
        cvv: 'CVV 보안코드',
        bankCode: '카드 발행 은행',
      },
      placeholder: { bankCode: '카드발행은행을 입력해주십시오.' },
      cvvTip: '카드 뒷면에 표시된 3자리 <br />',
      threeDomainSecQ: '귀하의 카드가 3D-Secure를 지원합니까?',
      threeDomainSec:
        '3D Secure 인증이란? <br /><strong>3D Secure 해외 안심클릭 인증방식은 비자 및 마스터카드가 인정한 보안 방지 방법입니다.<br/> 만약 당신의 카드가 3D Secure를 지원한다면, 귀하의 자금은 즉시 처리될 것입니다.',
      cardUpload:
        '귀하가 자금을 예치하는 데 사용하는 카드가 현 거래 계좌에 연동되지 않았습니다. 최초 인증을 위해 카드 앞면 사진을 올려 주십시오. (카드 중간 6자리는 가리고 업로드 하십시오.)',
      result: {
        success:
          '감사합니다. 담당부서에서 현재 귀하의 자금과 인증 자료를 검토중입니다. 인증이 통과되면 24시간 내로 귀하의 거래 계좌에 예치하신 자금이 반영될 것입니다.',
        depositSuccess: '예치해 주셔서 감사합니다. 귀하의 자금은 곧 거래 계좌에 반영될 예정입니다.',
        error:
          '현재 입금 시도 횟수 초과로 인하여,<br> 귀하의 계좌를 보호하기 위한 조치로 일시적으로 예치가 정지되었습니다.<br> {email}로 문의해 주십시오.',
      },
      mastercardonly: 'Mastercard만 사용하여 이 결제 채널을 계속 이용해 주세요.',
    },
    fasa: {
      header: 'FASAPAY 입금',
      description: 'FasaPay로 입금하는 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 귀하의 FasaPay계정에 로그인 하십시오.',
      inst3: '3. FasaPay 계정의 메시지에 따라 결제를 완료합니다.',
      inst4: '4. Fasapay 예금은 거래 당 미화 25,000 달러, 일일 최대 미화 75,000 달러로 제한됩니다',
      form: { header: 'FASAPAY 신청양식' },
    },
    miji: {
      header: '태국 은행 계좌이체',
      description: '태국은행 즉시이체 입금방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 결제 페이지로 넘어갑니다.',
      inst3: '3. 모바일 뱅킹 앱에 로그인하여 결제 페이지에서 QR 코드를 스캔합니다.',
      inst4: '참고: <b>시스템상 요청하신 금액보다 몇 센트가 추가될 수 있습니다</b>',
      form: { header: '태국은행 즉시이체 신청양식' },
    },
    intSwift: {
      header: '해외 전신환 송금 : 은행 스위프트 정보',
      desc: '해외송금은 귀하의 명의로 개설된 은행 온라인 뱅킹을 통해 {platform}에 이체를 진행하거나 혹은 직접 은행창구로 방문하여 {platform}에 전신환 송금을 통해 이체를 진행하면 됩니다.',
      desc2:
        '입금 후 아래 양식을 작성하시고, 고객님의 성함, 계좌번호, 입금 금액이 명확하게 표시된 이체 내역서 혹은 영수증 사본을 사진 촬영 후 첨부하여 업로드를 진행하십시오.',
      options: '예치를 진행할 실계좌를 선택하세요.',
      refInfo: '은행 송금 비고란에 {platform} 실계좌번호를 입력해주세요.',
      bussDayInfo:
        '해외 전신환 송금은 당사에 도착까지 평균 영업입 2-5일이 소요되며, 제출해주신 이체 영수증을 기반으로 담당 부서에서 귀하의 입금을 확인하여 신속하게 반영드리겠습니다.',
      tip: '송금 내역서에 <b>이름</b>, <b>계좌 번호</b>,<b>입금 금액</b>이 명확하게 표시되어 있는지 확인하세요.',
    },
    mobile: {
      header: '모바일결제',
      desc: '모바일결제로 자금을 예치하는 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 지원하는 모바일 어플리케이션을 열어 QR코드를 스캔합니다.',
      inst3: '3. 모바일 장치의 안내에 따라 예치금 결제를 완료합니다.',
      form: { payMethod: '지불방식:', rate: '환율(USD/CNY)', amt: 'CNY:' },
      formValidation: { cnyAmt: 'CNY 금액은 ${limit} 이하여야 합니다.' },
    },
    net: {
      header: 'Neteller',
      inst2: '2.   귀하의 Neteller계정에 로그인 하십시오.',
      inst3: '3.   Neteller 계정의 메시지에 따라 결제를 완료합니다.',
      inst4: {
        fca: '4.   당사는 USD, EUR, GBP로 결제 가능합니다.',
        default: '4.   당사는 AUD, USD, EUR, GBP, SGD로 결제 가능합니다.',
      },
    },
    canada: {
      header: 'Interac E-Transfer',
      desc: 'Interac E-Transfer입금 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 은행 선택 페이지에서 은행을 선택하고 온라인 뱅킹에 로그인합니다.',
      inst3: '3. 지시에 따라 온라인/모바일 뱅크를 통해 다음단계로 넘어가 결제를 진행합니다.',
      inst4: '4. 당사는 CAD 결제를 받을 수 있습니다.예치 금액은 1회 최대 $9,999 CAD 입니다.',
      form: { header: 'Interac E-Transfer 신청양식' },
    },
    bitwallet: {
      header: 'BITWALLET 입금',
      desc: 'BITWALLET 입금방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 귀하의 BITWALLET 로그인 하십시오.',
      inst3: '3. BITWALLET 계정의 메시지에 따라 결제를 완료합니다.',
      inst4: '4. 당사는 USD, EUR, JPY로 결제 가능합니다.',
      form: { header: 'BITWALLET 신청양식' },
    },
    astropay: {
      header: 'ASTROPAY 입금',
      desc: 'ASTROPAY 입금방법',
      inst2: '2. 귀하의 ASTROPAY 로그인 하십시오.',
      inst3: '3. ASTROPAY 계정의 메시지에 따라 결제를 완료합니다.',
      inst4: '4. 모든 통화 지불이 가능합니다.',
      form: { header: 'ASTROPAY 신청양식' },
    },
    crypto: {
      choose: '선호하는 암호 화폐를 선택하십시오',
      proceedQuestion: '<b>{method}</b> 입금을 진행 하시겠습니까?',
      remark:
        'BUSDT를 통해 입금 또는 출금할 수 없습니다. 주소와 암호화폐가 허용되는 체인 및 통화와 일치하는지 확인하세요. 그렇지 않으면 자금을 잃을 수 있습니다.',
      inst2: '2.   비트코인 지갑 주소가 표시된 새 창이 나타납니다.',
      inst3:
        '3.   비트코인 지갑 주소를 복사한 다음 개인 비트코인 지갑에 로그인하여 원하는 금액을 복사한 비트코인 주소로 전송합니다.',
      inst4: '주의 : 비트코인 예치는 B2BinPay의 당시 환율에 의해 적용되어 처리됩니다.',
    },
    poli: {
      header: 'POLI 입금',
      desc: 'POLI 입금방법',
      reminder:
        '주의 : 자금 예치는 {platform} 실계좌와 동일한 영문명이어야합니다.  * 예치는 호주 달러(AUD) 만 가능합니다.',
      form: { header: 'POLI 양식' },
    },
    skrill: {
      header: 'SKRILL/MONEYBOOKERS 입금 방법',
      desc: 'Skrill 입금 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 귀하의 SKRILL계정에 로그인 하십시오.',
      inst3: '3. SKRILL 계정의 메시지에 따라 결제를 완료합니다.',
      inst4: '4. 당사는 USD, EUR, GBP, CAD로 결제 가능합니다.',
      inst4FCA: '4. 당사는 USD, EUR, GBP 결제 가능합니다.',
      form: { header: '스크릴 신청양식', email: '스크릴 계정 이메일' },
      reminder:
        '<li> 참고: </ li>\n      <li> * 계정은 MT4 거래 계정과 동일한 통화로만 자금을 조달 할 수 있습니다. 잘못된 이메일을 사용하는 경우 Skrill은 자동으로 자금을 계정의 기본 통화로 변환합니다. 요금이 부과 될 수 있습니다. </ li>\n      <li> * 예금은 {platform} 계정과 동일한 이름이어야합니다. </ li>',
    },
    sticPay: {
      inst2: '2.   귀하의 SticPay계정에 로그인 하십시오.',
      inst3: '3.   SticPay 계정의 메시지에 따라 결제를 완료합니다.',
    },
    webMoney: {
      header: 'WEBMONEY',
      inst4: '4.   당사는 USD, EUR로 결제 가능합니다.',
      inst5: '5.   WebMoney 예금은 거래 당 미화 1300 USD/1100 EUR',
    },
    vload: {
      header: 'VLOAD',
      inst1: '2.   USD, EUR, JPY로 결제 가능합니다.',
      inst2:
        '<li>3.   Vload 상품권 구매, 클릭하십시오 <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">여기를</a>.</li>',
      form: { voucherpin: '바우처 핀', voucherpinReq: '바우처 PIN이 필요합니다' },
    },
    dasshpe: {
      headerWallet: '인도 지갑',
      inst1: '2.   UPI 앱에서 거래를 승인해주세요',
      inst2: '2.   안내에 따라 지갑 앱을 통해 결제하세요',
    },
    thaiPaytoday: {
      header: '태국 은행 계좌이체',
      desc: '태국은행 즉시이체 입금방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 안내에 따라 지불을 완료하십시오',
      form: { header: '태국은행 즉시이체 신청양식' },
      scan: '스캔하여 입금 진행',
      exp: '{expireTime}에 만료됩니다',
      reminder:
        "자금은 1 시간 이내에 계정에 나타납니다.<br/><br/>\n      '거래 내역'에서 예금의 현재 상태를 볼 수 있습니다.<br/>\n      입금 관련 문제가 발생하면 계정 관리자에게 문의하십시오.<br/>",
    },
    unionPay: {
      header: '유니온페이결제',
      desc: '유니온페이 자금 예치 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 은행 선택 페이지에서 은행을 선택하고 온라인 뱅킹에 로그인합니다.',
      inst3: '3. 지시에 따라 온라인/모바일 뱅크를 통해 다음단계로 넘어가 결제를 진행합니다.',
      form: { header: '중국 유니온페이 신청양식' },
    },
    unionPayCN: {
      header: '유니온페이계좌이체',
      desc: '중국 유니온 페이 이체 계좌 자금 예치 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 결제 세부 정보 페이지가 팝업창으로 나타납니다.',
      inst3: '3. 지시에 따라 온라인/모바일 뱅크를 통해 다음단계로 넘어가 결제를 진행합니다.',
      inst4:
        '참고: 입금을 진행하는 동안 멤버십 번호를 묻는 메시지가 나타날 수 있습니다. 당사의 MT4 실계좌 번호를 멤버십 번호에 입력하십시오.',
      form: {
        header: '중국 유니온 페이 이체 신청서',
        holderName: '유니온페이 카드 소유자 이름:',
        holderNameInfo: '위에 입력한 영문명과 유니온페이 카드 소유자 이름이 동일해야 합니다.',
      },
    },
    vietZota: {
      header: '베트남 은행 즉시이체',
      desc: '베트남 은행 즉시이체 입금방법',
      inst: "1. 아래 양식을 작성 후 '제출' 버튼을 클릭해주십시오 / 2. 은행 선택 페이지에서 은행을 선택 후 베트남 온라인 뱅킹에 로그인하십시오 / 3. 안내에 따라 온라인 / 모바일 은행을 통해 결제를 진행하십시오 / 4. 고객 포털 사이트로 이동 후 거래가 완료됩니다",
      form: { header: '베트남 은행 즉시이체 신청양식' },
    },
    paypal: {
      header: 'PayPal',
      desc: 'PAyPal 입금 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. PayPal 계정으로 로그인해주십시오.',
      inst3: '3. 다음의 안내에 따라 페이팔 계정에서 결제를 완료해주십시오.',
      inst4: '주의 : 제3자의 임의적인 지불은 허용되지 않습니다.',
      form: { header: 'PayPal 양식' },
    },
    cardpay: {
      desc: '신용카드 입금 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 다음의 양식으로 이동하여 카드 세부정보를 입력해주십시오',
      inst3: '3. 신용카드 정보를 입력 후 "제출"버튼을 클릭해주십시오',
      note: "카드 명세서에 표시되는 거래 설명은 사용되는 금융 게이트웨이에 따라 다릅니다. 거래 설명에 대해 우려사항이 있는 경우 <a href='mailto:{mail}'>{mail}</a> 으로 문의해주세요.",
      form: { header: '신용카드 신청양식 ' },
    },
    tinkbit: { inst5: '5.   증거금당 최대 $1500 USD' },
    trustly: {
      desc: 'Trustly 입금 방법',
      inst1: "1. 아래의 양식을 작성한 후  '제출하기' 버튼을 눌러 예치를 진행하세요.",
      inst2: '2. 귀하의 Trustly 계정에 로그인 하십시오.',
      inst3: '3. Trustly 계정의 메시지에 따라 결제를 완료합니다.',
      inst4: '4. 당사는 EUR, GBP로 결제 가능합니다.',
      form: { header: 'TRUSTLY 양식' },
      reminder:
        '<li> 참고: </ li>\n      <li> * 계정은 MT4 거래 계정과 동일한 통화로만 자금을 조달 할 수 있습니다. 잘못된 이메일을 사용하는 경우 Trustly은 자동으로 자금을 계정의 기본 통화로 변환합니다. 요금이 부과 될 수 있습니다. </ li>\n      <li> * 예금은 {platform} 계정과 동일한 이름이어야합니다. </ li>',
    },
    fxir: {
      desc: "{number}.    아래 양식을 작성하고 '제출’을 선택하세요.",
      desc2:
        '{number}.    성공적이고 신속한 입금을 위해 거래 계정과 동일한 통화로 자금을 이체하고 이체 영수증을 업로드한 후 입금 요청을 생성하십시오.',
      desc1: "{number}.    아래 양식을 작성하고 '제출’을 선택하세요.",
      desc3:
        '송금을 완료한 후 아래 양식을 작성하고 은행 송금 영수증 사본을 첨부하세요. 이 영수증은 귀하의 전체 이름, FX-IR ID 및 입금 금액이 명확히 표시되어야 하며, 이를 통해 귀하의 거래를 처리할 수 있습니다.',
      desc4: '중요! 송금 참조 필드에 (귀하의 거래 계좌 번호)를 포함하세요.',
      field: {
        id: '발신자 FX-IR ID',
        accName: 'FX-IR 계정 이름',
        walletNumber: 'FX-IR 지갑 번호',
        transactionID: '거래 ID',
        senderFxirID: '발신자 FX-IR ID',
      },
    },
  },
  restrictDeposit: {
    header: '중요 알림',
    info: '안타깝게도 당사에서는 더 이상 캐나다에 거주하는 고객의 입금을 받지 않습니다.',
  },
  withdraw: {
    default: {
      selectCC: '신용카드 선택',
      selectBA: '은행 계좌 선택',
      anotherCC: '신용카드를 수동으로 입력하세요.',
      anotherBA: '은행 계좌 추가',
      remember: '내 계정 기억',
      success: '귀하의 출금 신청이 정상적으로 제출되었으며, 곧 처리될 예정입니다. ',
      ccFail:
        '현재 입금 시도 횟수 초과로 인하여, 귀하의 계좌를 보호하기 위한 일시적 조치로 입출금이 정지되었습니다. {email}로 문의해 주십시오.',
      fetchTradeAccFailed: '거래 계좌를 불러오는데 실패했습니다.',
      failed: '출금에 실패하였습니다. 잠시 후 다시 시도해주십시오.',
      notEnoughFunds: '입력한 금액이 해당 카드 한도를 초과합니다.',
      cardExpird: '이 카드는 이미 만료되었습니다. 다른 카드 또는 다른 결제 방법을 선택하세요.',
      minAmountWarn:
        '적용된 출금 금액이 적용된 결제 게이트웨이의 최소 금액보다 적습니다. 출금 요청을 제출하기 전에 계정 잔액을 최소 금액 이상으로 유지해주세요. 자세한 내용은 <a href="https://{regulatorUrl}/clients/accounts/withdraw/">여기에서</a> 확인할 수 있습니다.',
      noCardWarn:
        '출금 가능한 카드가 없습니다. 먼저 출금 세부 사항에서 카드를 등록하십시기 바랍니다.',
      equityStop:
        '귀하의 계좌에 사용 가능한 마진이 강제청산 레벨인 100% 아래로 떨어져 출금 처리를 진행할 수 없습니다.',
      equityMargin: '죄송합니다. 고객님의 계정이 완전히 헤지되어 현재 인출을 처리할 수 없습니다.',
      equityContinue:
        '현재 보유중인 포지션이 존재합니다. 출금신청을 할 경우 포지션이 청산될 수 있습니다. ',
      YourPaymentGreaterThanAvailableBalance: '입력하신 지불 금액이 잔액을 초과합니다',
      blackList:
        '죄송합니다. 현재 인출 요청을 제출할 수 없습니다. 자세한 내용은 {supportEmail}에 문의하십시오.',
      NetellerChargeAlert:
        'Neteller 인출은 거래 당 $ 500,000로 제한되며 2 % 수수료 ($ 30로 제한)가 적용됩니다.',
      FasaPayChargeAlert: 'FasaPay는 모든 출금에 대해 0.5 %의 거래 수수료를 부과합니다.',
      SkrillChargeAlert: 'Skrill은 모든 출금에 대해 1 %의 거래 수수료를 부과합니다.',
      localBankTransfer: 'Local Bank Transfer',
    },
    fasa: {
      field: { accName: 'FasaPay 계정이름', accNum: 'FasaPay 계정번호' },
      formValidation: {
        accNameReq: 'FasaPay 계정이름을 입력해주십시오.',
        accNumReq: 'FasaPay 계정번호를 입력해주십시오.',
      },
    },
    neteller: {
      field: { email: 'Neteller 이메일' },
      formValidation: { emailReq: 'Neteller 이메일을 입력해주십시오' },
    },
    bitcoin: {
      field: { address: 'Bitcoin 지갑 주소' },
      formValidation: { addressReq: 'Bitcoin 지갑 주소를 입력해주십시오' },
    },
    eth: {
      field: { address: 'ETH 지갑 주소' },
      formValidation: { addressReq: 'ETH 지갑 주소를 입력해주십시오' },
    },
    usdc: {
      field: { address: 'USDC 지갑 주소' },
      formValidation: { addressReq: 'USDC 지갑 주소를 입력해주십시오' },
    },
    usdt: {
      field: { address: 'USDT 지갑 주소' },
      formValidation: { addressReq: 'USDT 지갑 주소를 입력해주십시오' },
    },
    paypal: {
      field: { email: 'Paypal 이메일' },
      formValidation: { emailReq: 'Paypal 이메일을 입력해주십시오' },
    },
    skrill: {
      field: { email: 'Skrill 이메일' },
      formValidation: { emailReq: 'Skrill 이메일을 입력해주십시오 ' },
    },
    webMoney: {
      field: { email: 'WebMoney 이메일' },
      formValidation: { emailReq: 'WebMoney 이메일을 입력해주십시오 ' },
    },
    vload: {
      field: { email: 'Vload 가입 이메일' },
      formValidation: { emailReq: 'Vload 등록 이메일이 필요합니다' },
    },
    sticpay: {
      field: { email: 'SticPay 이메일' },
      formValidation: { emailReq: 'SticPay 이메일을 입력해주십시오 ' },
    },
    bitwallet: {
      field: { email: 'Bitwallet 이메일' },
      formValidation: { emailReq: 'Bitwallet 이메일을 입력해주십시오 ' },
    },
    astropay: {
      field: { accountNumber: 'Astropay 계정' },
      formValidation: { accountNumberReq: 'Astropay 계정이 필요합니다' },
    },
    transfer: {
      info: '{platform}은 매 월 1회 은행 송금 출금 수수료를 무료로 면제합니다. 한 달 안에 추가로 인출할 경우 인출 통화의 20불의 은행 송금 수수료가 발생합니다.',
      label: {
        upload: '은행 명세서를 업로드해주십시오. (최근 3개월 거래내역 포함)',
        upload2:
          '거래 명세서의 날짜 또는 최종 거래 날짜가 현재 월부터 지난 3개월 사이에 있는지 확인하십시오. 그 기간을 초과하는 경우 명세서를 다시 업로드하십시오.',
      },
      formValidation: { upload: '이체내역서를 업로드해주십시오.' },
    },
    ccMethod: {
      desc1: 'AML/CTF 규정 준수로 인해 초기 입금 시 사용한 카드로 자금이 이체되어야 합니다',
      desc2: '계정 입금에 사용된 카드를 사용할 수 없는 경우 {mailto}으로 문의해주세요',
    },
    otherMethod: {
      otherType: '기타 유형',
      withdrawalMethod: '인출 방법',
      desc1:
        '계좌이체 시 수수료가 발생할 수 있으니 참고하시기 바랍니다. 귀하의 인출 금액이 발생할 수 있는 모든 수수료를 부담하기에 충분한지 확인하십시오.',
      desc2:
        'AML/CTF 준수로 인해 모든 인출은 계정에 자금을 입금하는 데 사용된 것과 동일한 방법을 사용하여 이체되어야 합니다.',
      note: "최근 변경 사항으로 인해 일부 국가에서 가입된 고객의 국제 은행 송금을 처리하지 못할 수 있습니다.<br/>\n      국제 은행 송금을 통해 인출해야 하는 경우 +44 2080 363 883 또는 <a href='mailto:{mail}'>{mail}</a>으로 고객 지원에 문의해주세요.<br/>\n      불편을 드려 죄송합니다.",
      verificationCode:
        '{email}에 확인 코드가 전송되었습니다. 추가로 고객 지원이 필요한 경우 {supportEmail}에 문의하십시오',
      note2:
        '한국의 Travel Rule 정책의 영향으로 한국 거래소 주소에서의 출금 중에 단일 거래 금액이 735 USDT (약 1,000,000 KRW)을 초과하는 경우 또는 해당 주소가 KYC 인증을 통과하지 않은 고객의 경우 출금을 받지 않습니다.',
    },
  },
  transfer: {
    creditWarn: '프로모션 자금이 포함되어 있는 계좌는 이체 신청을 진행할 수 없습니다. ',
    blackListWarn:
      'MAM 계좌에서 자금이체를 신청할 경우 유지중인 포지션이 청산될 수 있습니다. 자금이체를 희망하실 경우 {mailTo} 로 문의하시기 바랍니다. ',
  },
  changePw: {
    header: '비밀번호변경',
    field: { currPw: '현재 비밀번호', newPw: '새 비밀번호 입력', confirmPw: '새 비밀번호 확인' },
    succ: '비밀번호 재설정이 완료되었습니다. 변경된 비밀번호로 고객포털 사이트에 로그인해주십시오.',
  },
  security: {
    confirm: '확인',
    cancel: '취소',
    understand: '이해합니다',
    passwordRenewalReminder: {
      header: '로그인 비밀번호 변경',
      desc: '계정 자금의 안전을 위해 로그인 비밀번호를 변경한 후 T+{days} 일 동안 출금이 지원되지 않습니다.',
    },
    withdrawalFreeze: {
      header: '출금이 일시적으로 지원되지 않습니다',
      userLoginDesc:
        '로그인 이메일/전화번호 변경으로 인해 T+{days}일 동안 출금이 일시적으로 지원되지 않습니다. 이 제한은 {hours}시간 후에 해제될 예정입니다.',
      passDesc:
        '로그인 비밀번호 변경으로 인해 계좌 자금의 안전을 위해 T+{days}일 동안 출금이 일시적으로 지원되지 않습니다. 이 제한은 {hours}시간 후에 해제될 예정입니다.',
      limitSecurityAuthenticator:
        '보안 인증기 앱을 수정했기 때문에 계정 보안을 보장하기 위해 T+{days} 일 동안 출금이 일시적으로 지원되지 않습니다. 이 제한은 {hours} 시간 후에 해제될 예정입니다.',
    },
    securityManagement: '보안 관리',
    securityAuthentication: '보안 인증',
    authenticationMethod: '인증 방법',
    changeAuthenticatorTitle: '\t보안 인증기 변경',
    isVerified: '확인 됨',
    isNotVerified: '확인되지 않음',
    password: '비밀번호',
    securityAuthenticatorApp: '보안 인증 앱',
    enableLoginAuthentication: '로그인 인증 활성화',
    loginLocationChange: '로그인 위치 변경 이메일 알림',
    lastUpdate: '최근 업데이트',
    modify: '수정',
    verify: '확인',
    enable: '활성화',
    resend: '재전송',
    enterVerificationCode: '인증 코드 입력',
    verifyPreEmailNote: '코드가 {email}로 전송됩니다',
    verifyPostEmailNote: '코드가 {email}로 전송되었습니다',
    preEmailCode: '코드가 새 이메일 주소로 전송될 것입니다',
    authenticatorTooltip: 'Authenticator APP 검증 코드는 30초마다 새로 고침됩니다',
    contactSupport: '인증 방법에 방문할 수 없습니까? {contact_email} 또는 Livechat에 문의하세요',
    changeEmail: '이메일 주소 변경',
    emailTitle: '새 이메일 주소 입력',
    editAuthenticator: '인증기기 편집',
    addAuthenticator: '인증 설비 추가',
    scanWithApp: 'Authenticator APP로 스캔',
    scanAppTooltip:
      'Google Authenticator 또는 Microsoft Authenticator를 휴대폰 애플리케이션 스토어에서 다운로드할 수 있습니다. 그리고 앱 카메라를 사용하여 제공된 QR 코드를 스캔하세요.',
    scanAppTooltipForChina:
      '스마트폰 애플리케이션 스토어에서 Google Authenticator 또는 Microsoft Authenticator를 다운로드하거나 WeChat 미니 프로그램에서 Google Authenticator를 검색하고 첫 번째 결과를 선택합니다. 그런 다음 앱 내 카메라를 사용하여 제공된 QR 코드를 스캔하십시오.',
    scanAuthenticatorAppTips: {
      tips1:
        '휴대 전화 앱 스토어에서 Google Authenticator 또는 Microsoft Authenticator를 다운로드할 수 있습니다',
      tips2:
        '또는 WeChat 미니 프로그램에서 Google Authenticator를 검색하고 첫 번째 결과를 선택할 수도 있습니다.',
      tips3: '그런 다음, 제공된 QR 코드를 앱 내 카메라를 사용하여 스캔하십시오',
    },
    userGuide: '사용자 가이드',
    setupKey: '키 설정',
    tnc: '인증 앱을 연결함으로써 {link}을(를) 읽고 승인했다는 것을 확인합니다.',
    disclaimer: '면책 조항',
    changePasswordSuccess: '비밀번호가 업데이트됨',
    changeEmailSuccess: '이메일 주소가 업데이트됨',
    enableSecuritySuccess: '보안 인증기가 활성화됨',
    updateSecuritySuccess: '보안 인증기가 업데이트됨',
    changePasswordSuccessDesc:
      '비밀번호가 업데이트되었습니다. <br/> 이제 새로운 비밀번호로 로그인할 수 있습니다.',
    changeEmailSuccessDesc:
      '이메일 주소가 업데이트되었습니다. <br/> 이제 새 이메일 주소로 로그인할 수 있습니다.',
    enableSecuritySuccessDesc: '보안 인증 앱이 활성화되었습니다.',
    updateSecuritySuccessDesc: '보안 인증 앱이 업데이트되었습니다.',
    passwordReq: '비밀번호 요구 사항:',
    passwordCharacters: '8-16 자',
    passwordMix: '영문 (a-z 및 A-Z) 및 숫자 (0-9) 혼합',
    passwordSpecial: '!@#$%^&*(). 와 같은 특수 문자',
    confirmPwd: '비밀번호 확인',
    enableNow: '지금 활성화',
    changeDesc:
      '계정 자금의 안전을 보장하기 위해 {method} 변경 후 T+ {numDays} 일 동안 인출이 지원되지 않습니다.',
    verificationTitle: '확인이 필요합니다',
    verificationDesc: '진행하기 전에 보안 인증기 앱을 활성화하세요.',
    updateSuccess: '업데이트 성공',
    verifiedSuccess: '성공적으로 인증되었습니다',
    verificationRequired: '확인 코드를 입력하세요',
    next: '다음',
  },
  reserPwMobile: { header: '암호를 재설정' },
  downloads: {
    appDesc: '앱스토어 및 구글 플레이 스토어에서 {platform} 앱을 다운로드하세요',
    mt4: 'METATRADER4',
    mt4Desc:
      'PC, 모바일, 태블릿 등 사용하는 소프트웨어에 적합한 MT4 거래 플랫폼을 다운로드 할 수 있습니다.',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android',
    web: 'WebTrader',
    webTrader: 'Web Trader',
    smartTrader: 'SmartTrader 도구',
    mt5: 'METATRADER 5',
    mt5Desc: 'PC 데스크탑,  웹 브라우저, 스마트폰 및 태블릿용 MetaTrader 5 다운로드',
    button: { download: '다운로드', launch: '론칭하기' },
  },
  forgetPwReq: {
    headerMobile: '로그인 전화 번호 입력',
    labelEmail: 'Email',
    labelMobile: '전화 번호',
    loginMobile: '로그인 전화 번호',
    loginSMSCode: 'SMS 코드',
    loginSmsCodeExpires: 'SMS 코드가 5 분 안에 만료됩니다',
    getCode: '보냄 SMS 코드',
    failedCode: '휴대 전화로 SMS 코드를 보낼 수 없습니다',
    sentAlready: '보냄',
    expired: 'SMS 코드가 필요합니다',
    tip: '비밀번호를 재설정하려면 계정 이메일 주소를 입력하세요.',
    formValidation: {
      emailReq: '이메일을 입력해주십시오',
      mobileReq: '로그인 전화 번호 코드가 필요합니다',
      smsCodeReq: 'SMS 코드가 필요합니다',
    },
    succ: '비밀번호 재설정 메일이 발송되었습니다. <br><br>받은 메일함에 확인되지 않을 경우 스팸 메일함을 확인해주십시오.',
  },
  loginClient: {
    header: '고객포털',
    failedMasseage: '다시 시도하십시오',
    existingClientPortalAccess:
      '이미 등록되어 있는 이메일입니다. <br /><br /> 등록된 이메일로 로그인하여 실계좌를 개설하시기 바랍니다.',
    existingClientPortalAccessMobile:
      '이미 등록되어 있는 이메일입니다. <br /><br /> 실제 계정을 개설하려면 등록 된 전화 번호로 로그인하십시오.',
    formValidation: { emailReq: '이메일을 입력해주십시오.', mobileReq: '숫자 만 입력하십시오' },
  },
  myProfile: {
    changeDetails: '프로필 세부 정보의 업데이트가 필요하시면 {mailTo}로 문의하시기 바랍니다.',
  },
  openAdditAcc: {
    chooseTradePlat: '거래 플랫폼 선택',
    chooseAccType: '계좌 유형 선택',
    chooseCurrency: '계좌 통화 선택',
    chooseLeverage: '레버리지 선택',
    chooseBalance: '계정 잔액',
    notes: '비고',
    currError: 'MT4 계좌 통화를 선택하십시오.',
    platErr: '거래 플랫폼을 선택하십시오',
    accTypeErr: '계좌 유형을 선택하십시오',
    leverageErr: '레버리지를 선택해야 합니다',
    balanceErr: '잔액을 선택해야 합니다',
    succ: '<p>귀하의 추가 실계좌 개설이 완료 되었습니다. 로그인 세부 내용은 귀하의 이메일로 발송됩니다.</p><p>또한 고객포털의 대시보드에서도  확인 하실 수 있습니다.</p>',
    byTicking: '아래의 내용에 동의하며 박스에 체크합니다. ',
    swapFreeNote: '*참고: 스왑 요금 대신 약간의 관리 수수료가 적용될 수 있습니다.',
    pammNote:
      '*PAMM 계정은 기존에 금융 자산 관리자와 협의가 된 투자자만 사용할 수 있습니다. PAMM 계정은 금융 자산 관리자를 제외한 누구도 거래할 수 없습니다.',
  },
  openDemoAdditAcc: {
    header: '추가 데모 계좌 개설',
    button: '데모계좌개설',
    liveButton: '실계좌개설',
    succ: '귀하의 추가 데모계좌 개설이 완료되었습니다. 로그인 세부내용은 귀하의 이메일로 발송됩니다. ',
  },
  resetPassword: {
    header: '비밀번호 재설정',
    succ: '비밀번호 재설정이 완료되었습니다. 변경된 비밀번호로 MT4/MT5 거래 계좌를 로그인해주십시오.',
    succ2: '비밀번호 재설정이 완료되었습니다. ',
    failed: '토큰이 만료되었습니다. ',
    emailNotFound: '귀하의 이메일 주소가 유효하지 않습니다. ',
  },
  fileUploader: {
    supportedFormat: '지원 파일 형태 : png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: '지원 파일 형태: {files}',
    maxSize: '파일 업로드 크기: {maxSize} MB',
    maxFileNum: '최대 파일업로드 개수:{maxFileNum}',
    failedFileExtension: '올바른 파일 형식을 업로드하세요.',
  },
  transcationHistory: {
    deposit: '예치',
    withdraw: '인출',
    desc: {
      incomplete:
        '최종 단계에서 혹은 지불 단계에서 예치를 정상적으로 진행하지 않고 종료 할 경우, 표시되는 상태 입니다. 지불이 정상적으로 승인이 되지 않았으므로, 입금을 재 요청을 해야만 합니다. ',
      success: '성공적으로 지불이 승인되었으며, 곧 자금이 고객님의 계좌에 반영될 것입니다.',
      proc: '귀하의 거래 요청이 승인중입니다. 자세한 내용은 {mailTo} 로 문의하시기 바랍니다. ',
      failed: '귀하의 거래 요청이 실패하였습니다. 자세한 내용은 {mailTo} 로 문의하시기 바랍니다.  ',
      rejected:
        '귀하의 거래 요청이 거절되었습니다. 자세한 사항은 {mailTo} 로 문의하시기 바랍니다. ',
    },
    withdrawDesc: {
      sub: '귀하의 거래 요청이 제출되었습니다. 승인이 완료까지 잠시만 기다려주시기 바랍니다. ',
      suc: '성공적으로 지불이 승인되었으며, 곧 자금이 고객님의 계좌에 반영될 것입니다.',
      proc: '귀하의 거래 요청이 승인중입니다. 자세한 내용은 {mailTo} 로 문의하시기 바랍니다. ',
      incomp:
        '귀하의 거래를 완료하기 위하여 추가적인 단계가 필요합니다. 자세한 사항은 {mailTo} 로 문의하시기 바랍니다.',
      cancel: '귀하의 거래가 취소되었습니다. 자세한 사항은 {mailTo} 로 문의하시기 바랍니다.',
      fail: '귀하의 거래 요청이 실패하였습니다. 자세한 내용은 {mailTo} 로 문의하시기 바랍니다.  ',
      rej: '귀하의 거래 요청이 거절되었습니다. 자세한 사항은 {mailTo} 로 문의하시기 바랍니다. ',
    },
  },
  transferFunds: {
    default: {
      equityStop:
        '죄송합니다. 계정의 사용 가능한 마진이 스탑 아웃 수준인 100 % 이하로 떨어지기 때문에 이체를 처리할 수 없습니다.',
      equityMargin: '죄송합니다. 귀하의 계정이 완전히 헤지되어 현재 이체를 처리할 수 없습니다.',
      equityContinue:
        '현재 귀하의 계정에 오픈 포지션이 있는 것으로 확인되었습니다. 이체 신청서를 제출한 후 스탑 아웃이 될 수 있습니다. 계속하려면 확인해주세요.',
      blackList: '계정 간 이체가 제한된 계정입니다. {supportEmail}로 문의하시기 바랍니다.',
    },
    pleaseNote: '주의사항:',
    suc: '귀하의 자금이 성공적으로 이체되었습니다.',
    warn1:
      '계좌 간 이체시, 보내는 계좌상에 유지 포지션이 있을 경우  충분한 여유 자금을 확인하시길 바랍니다. 계좌 간 이체로 발생된 마진콜에 대한 책임과 판단은 전적으로 귀하에게 귀속됩니다. 현재 모든 포지션이 청산 된 상태에서 이체를 진행하시기를 권장 드립니다. 계좌 간의 통화가 동일할 경우, 이체 신청은 즉시 처리됩니다.',
    warn2:
      '각 MT4 계좌의 통화가 다른 경우 계좌 간 이체의 진행을 원하신다면 {mailTo} 으로 이메일을 보내 주십시오.',
    warn3: '읽기 전용 계좌는 계좌 간 이체가 불가능합니다.',
  },
  transferHistory: {
    payStatus: { processing: '처리중', paid: '지불', unpaid: '미지불', rejected: '거절' },
  },
  identityProof: {
    home: {
      header: '계좌 개설 미완료',
      content:
        '안녕하세요. {name}님, 계좌개설이 거의 완료되었습니다. 다음의 자료들을 제출해주십시오.',
    },
    shufti: {
      content: '제공된 세부 정보가 ID 문서와 일치하고 동일한 언어로되어 있는지 확인하십시오.',
      countryOfDocument: '문서에 표시된 국가',
    },
    popup: {
      header: '안녕하세요. {name}님, 실계좌 개설을 완료하기 전 아래의 자료들을 업로드 해주십시오.',
      addressProofSpan:
        '<strong>\n      <b>주소확인</b>\n      </strong>\n      <br />\n      <I>\n      다음 서류를<b>제출</b>해 주시길 바랍니다.\n        <br /><br />\n      </I>\n      - 공과금(수도,전기,가스) 고지서 사본 (최근 6개월 이내 본인명의로 발급된 출력본)\n      <br />\n      <b class="or">or</b><br />\n      - 은행 명세서 사본 (최근 6개월 이내 거래 내역 출력본)\n      <br />\n      <br />\n      <b>주의!</b> 파일명을 반드시 영문명으로 변경하여 업로드해 주시기 바랍니다. (예: Gil Dong Hong)\n      <br />',
      idProofSpan:
        '<strong>\n      <b>신분증명</b>\n      </strong>\n      <br />\n      <I>선택합니다.\n      아래의 <b>신분증명 중 하나</b>를\n        <br />\n        <br />\n      </I>\n      - 여권(서명란 포함)<br/>\n\n      - 운전면혀증(앞/뒤)\n      <br />\n      (앞/뒷)\n      <br />\n      or</b><br />\n      - 신분증(앞/뒤)\n      <br />\n      <br />\n      <b>주의!</b> 파일명을 반드시 영문명으로 변경하여 업로드해 주시기 바랍니다. (예: Gil Dong Hong)<br />',
    },
    reasonType: {
      4: '미완료된 문서',
      5: '통과되지 않은 문서',
      6: '컬러 사본 필요',
      8: '계정 개설 이름 불일치',
      9: '주소 증명 문서 발급 날짜가 적합하지 않음',
    },
    reason: '문서를 인증할 수 없습니다.<br> 이유: {reason}. 다시 시도해주세요.',
  },
  header: { secPortal: '보안되는 고객페이지 입니다.' },
  menu: {
    home: '메인',
    accManagement: '계좌관리',
    liveAcc: '실계좌',
    openAdditAcc: '신규 계좌 추가 하기',
    openAnAdditLiveAcc: '추가 실시간 계좌 개설',
    homeDemo: '데모계좌',
    depositFund: '자금예치',
    withdrawFunds: '출금신청',
    transferBetweenAccs: '계좌 간 이체',
    payDetails: '지불방식상세정보',
    funds: '자금',
    transactionHis: '거래내역',
    tranHis: '이체내역',
    download: '다운로드',
    profile: '프로필',
    myProfile: '내 정보',
    cpPassword: '비밀번호변경',
    contactUs: '연락하기',
    helpSupport: '도움말 및 지원 양식',
    register: '신청 계속하기',
    tradingTools: 'PROTRADER 도구',
    MARKET_BUZZ: '마켓 버즈 ',
    ECON_CALENDAR: '경제 캘린더',
    FEAT_FX: '주요 아이디어',
    FX_IDEAS: '기술적 뷰',
    ALPHA_GENERATION: '알파 생성',
    MARKET_MASTERS: '시장 마스터',
    referAFriend: '친구 추천',
    marketWidgets: '마켓 위젯',
    technicalAnalysis: '기술 분석',
    techAnalysisTab: {
      forex: '외환',
      indices: '지수',
      commodities: '원자재',
      sharedcfds: 'CFD 주식',
      crypto: '가상화폐',
    },
    marketData: '시장 데이터',
    forexCrossRates: '외환 교차 환율',
    forexHeatMap: '외환 히트(heat) 맵',
    forexScreener: '외환 사전 심사',
    wholesale: '프로로 업그레이드',
    tradingView: 'TradingView에 의한 CHARTS',
    protraderPlatform: '프로트레이더',
    twoFactorAuthentication: '2 단계 인증',
    promotion: '프로모션',
    depositBonus: '50% 입금 보너스',
    hmcTrader: '라이브 트레이딩 룸',
    support: '지원',
    securityManagement: '보안 관리',
  },
  contact: {
    desc: '문의사항이 있으실 경우 아래의 방법을 통해 연락하시기 바랍니다. 24시간 내로 고객지원팀이 귀하의 질문에 회신해 드리도록 하겠습니다. ',
    tip: '고객지원팀 {email} 이메일로 연락주십시오.',
    liveChat: '실시간 채팅',
    helpSupportForm: {
      partner: '파트너십 양도 양식',
      investigation: 'Moneta 거래 조사 양식',
      promotion: 'Moneta 프로모션 참여 취소 양식',
      request: '계정 폐쇄 요청 양식',
    },
    accountManager: '계정 관리자 - {userName}',
    monetaSupport: 'Moneta 지원',
  },
  depositResultDialog: { msg: '자금 예치는 완료하셨습니까?' },
  dialog: {
    identityProof: {
      success: '감사합니다. 귀하의 자료는 성공적으로 접수되었으며 현재 담당부서에서 심사중입니다.',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: '계좌 개설 심사중',
      id: '신분증명',
      poa: '주소증명',
      both: '신분증명 및 주소증명',
      content:
        '귀하가 제출하신 {doc}서류는 담당부서에서 심사중이며 곧 승인이 완료됩니다.  만약 계좌의 우선 개설을 원하신다면, 자금 예치를 통해 개설 활성화에 우선권을 부여 할 수 있습니다.',
    },
  },
  idPoa: {
    IdPoaNoPass: {
      header: '계정 신청서 완료되지 않음 ',
      content:
        '트레이딩을 시작하시려면 신분증 서류 증명과 주소 증명을 업로드해주시기 바랍니다. 제출이 완료되면, 우선 계정 개설을 위해 계정에 펀딩하십시오.',
    },
    onlyIdPass: {
      header: '감사합니다!',
      content: '신청서가 처리되고 있습니다. 우선순위 계좌 개설을 위해 지금 계좌에 펀딩하십시오.',
    },
    onlyPoaPass: {
      header: '계정 신청서 완료되지 않음 ',
      content:
        '트레이딩을 시작하시려면 신분증 서류 증명을 업로드해주시기 바랍니다. 제출이 완료되면, 우선 계정 개설을 위해 계정에 펀딩하십시오.',
    },
    bothIdPoaPass: {
      header: '감사합니다!',
      content: '신청서가 처리되고 있습니다. 우선순위 계좌 개설을 위해 지금 계좌에 펀딩하십시오.',
    },
    uploadID: '신분증 업로드',
    uploadPoa: '주소 업로드',
    depositNow: '지금 예치하세요',
  },
  register: {
    progressStatus: {
      title: '계좌 인증 심사 진행중',
      estimatedTimeTitle: '예상 소요 시간:',
      min: '분',
    },
    tabs: {
      personalDetails: '개인정보',
      moreAboutYou: '더 알아보기',
      accountConfig: '계좌 환경',
      confirmYourId: '신분증명',
      fundYourAcc: '자금예치',
    },
    btn: {
      next: '다음',
      back: '이전',
      fundnow: '입금하기',
      upload: '업로드',
      done: '완료',
      uploadNew: '신규 업로드',
    },
    authority: {
      dialog1: '해당 페이지의 접속을 희망하실 경우 실계좌로 로그인 하시기 바랍니다.',
      dialog2: '지금 바로 실계좌를 개설하여 해당 페이지에 대한 접근 권한을 활성화 하시길 바랍니다.',
      openLiveAcc: '실계좌개설',
    },
    demo: {
      congrad: '축하드립니다!',
      title: '30일 데모 MT{platform} 계정이 활성화되었습니다',
      title2: '고객님의 데모 계좌 상세정보',
      li: { login: '로그인:', password: '비밀번호:', srv: '서버 :', expDate: '유효기간 :' },
      emailSent: '*로그인 정보는 가입하신 이메일로도 발송되었습니다.',
      dl: 'MT{platform} 거래 플랫폼 다운로드',
      proTraderPlatform: {
        content:
          'MT{platform} 계정 세부 정보를 사용하여 차세대 PRO 트레이더 플랫폼에 로그인하십시오:',
      },
      appTrader: {
        content:
          '클라이언트 포털 로그인을 사용하여 Moneta Markets AppTrader에 로그인하십시오. iOS 또는 안드로이드용 AppTrader를 지금 받으세요!',
      },
      improveQ: '당사의 트레이딩 환경이 궁금하신가요?',
      improveWay:
        '지금 실계좌를 개설하면 다양한 트레이딩 툴과 특별한 프로모션의 기회를 누릴 수 있습니다.',
      proTrade: '전문 트레이더 도구',
      depositBonus: '50% 입금 보너스',
      smartTradeTool: '스마트 트레이더 도구',
      openLiveAcc: '실계좌개설',
      poweredBy: '제공 업체:',
    },
    personalDetails: {
      page1: {
        pageTitle: '개인정보',
        userTitle: '호칭',
        firstName: '이름',
        lastName: '성',
        email: '이메일',
        dob: '생년월일',
        idType: '신분증명유형',
        referral: '당사를 추천해 주신 분이 있으신가요?  (선택사항)',
        middleName: '중간 이름',
        nationality: '국가',
        phoneNum: '연락처',
        date: '일',
        month: '월',
        year: '년도',
        placeOfBirth: '출생지',
        idNum: '신분증번호',
        passportNum: '여권번호',
        driverLicenseNum: '운전면허증번호',
        titleDropdown: {
          mr: '남성',
          mrs: '기혼 여성',
          ms: '여성',
          miss: '미혼 여성',
          dr: '의사',
          prof: '전문가/교수',
        },
        idTypeDropdown: { passport: '여권', idCard: '주민등록증', driverLicense: '운전면허증' },
      },
      page2: {
        pageTitle: '현 주소지 정보',
        pageTitleForFCA: '추가 세부 사항',
        mainResidency: '현재 거주지',
        streetNumber: '상세주소 (번지수, 동, 호)',
        streetName: '도로명',
        address: '상세주소',
        provinceOrState: '시/도',
        cityOrSuburb: '시/군/구',
        postcode: '우편번호',
        previousCountry: '이전 국가',
        usQuestion:
          '미국에 세금을 납부하는 시민권/영주권을 가진 국민이거나 미국 소재의 은행 계좌가 있습니까?',
        taxResidencyCountry: '세금 거주 국가',
        nationalInsuranceNumber: '국민 보험 (NI) 번호',
        yearsAtAddress: '주소의 년수',
        previousResidentialAddressTip: '아래에 이전 거주지 주소를 입력하십시오',
      },
    },
    moreAboutYou: {
      pageTitle1: '고용 현황 및 재무 상태 입력',
      pageTitle2: '거래 지식 및 경험',
      complianceExplain:
        '아래의 질문은 준법감시부의 AML/CTF (자금세탁방지 및 테러자금조달방지) 규범 요구 사항을 준수하기 위한 목적으로 수집됩니다. ',
      FCAQuestion10Tip: '지난 12 개월 동안 다음 상품에서 분기당 평균 몇 건의 거래를 실행 했습니까?',
    },
    accountConfig: {
      pageTitle: '계좌 환경',
      chooseTradingPlatform: '거래 플랫폼 선택',
      chooseAccType: '계좌 유형 선택',
      chooseAccCurrency: '계좌 통화 선택',
    },
    confirmYourId: {
      pageTitle: '신분증명',
      description:
        '당사는 정식으로 금융 규제를 준수하는 외환 중개사로서 귀하의 신원을 확인해야 합니다. 신분증명 및 거주증명 확인을 위해 다음 문서 중 하나를 업로드해 주십시오. 혹은 {mailTo}로 증명 문서를 보낼 실 수 있습니다.',
      poidTitle: '신분증명자료',
      porTitle: '주소증명자료',
      uploadDescription: '문서 유형을 선택한 후 파일을 업로드 해주십시오.',
      note: '중요: 파일명에 반드시 영문명을 입력해 주시기 바랍니다. (예: Gil Dong Hong)',
      pending: { upload: '업로드 대기중', verification: '승인 대기중' },
      poidTypes: {
        photoId: '신분증',
        passport: '여권 (서명란 포함)',
        drivingLicence: '운전면허증',
      },
      porTypes:
        '<li>공과금 고지서</li>\n          <li>은행명세서</li>\n          <li>영문등초본</li>',
    },
    finishPage: {
      title: '{individualUserName} 님, {platformName} 실계좌 개설을 신청해주셔서 감사합니다!',
      contentDefault:
        '귀하의 계좌 개설 신청이 정상적으로 접수되었으며, <b>{account}</b> 실계좌가 개설 완료되었습니다.<br />\n      곧 귀하의 {email}로 로그인 세부 정보 및 계좌의 활성화 상태를 볼 수있는 링크가 포함 된 이메일이 발송됩니다. <br />',
      contentWithoutId3WithAccount:
        '귀하의 거래 계좌는 승인중이며 이와 관련된 사항은 이메일로 발송됩니다.<br /> <br />\n      만약 계좌의 우선 개설을 원하신다면, 자금 예치를 통해 개설 활성화에 우선권을 부여 할 수 있습니다. <br />',
      contentWithoutId3WithoutAccount:
        '귀하의 실계좌는 담당부서에서 심사중이며 이와 관련된 사항은 이메일로 발송됩니다. <br />',
    },
  },
  tradingTools: {
    MARKET_BUZZ:
      '35,000개 이상의 거래 가능 자산을 다루는 뉴스 보도 및 미디어 컨텐츠에 액세스합니다.',
    FEAT_FX: '실행 가능한 기술 거래 설정을 보고 실시간으로 진행 상황을 추적합니다.',
    ECON_CALENDAR: '전 세계의 실시간 경제 데이터를 24시간 즐기십시오.',
    FX_IDEAS: '80,000개의 수단에 대한 매력적이고 교육적인 기술 거래의 인사이트를 확인하십시오.',
    ALPHA_GENERATION: '여러분의 거래 우위를 높이고 3가지 고급 차세대 MT4 및 MT5 지표를 얻으십시오.',
    MARKET_MASTERS:
      '100개 이상의 고급 동영상 거래 튜토리얼을 통해 전문가처럼 거래하는 방법을 알아보십시오.',
    condition: {
      needDeposit:
        '프로 거래자 도구 모음과 독점적인 동영상 거래 튜토리얼에 액세스하려면 라이브 계정에 $500 이상을 펀딩하십시오.',
      congratulations: '축하합니다! 프리미엄 거래자 도구를 잠금 해제할 준비가 되셨습니다!',
      needTnc: '{link}동의해 주시기 바랍니다.',
      link: '이용약관에',
      unlock: '프로 거래자 도구 잠금 해제',
      depositNow: '지금 예치하기',
      tncAisc: '이러한 도구에 액세스하는 데 동의합니다.',
    },
    techAna: '기술적 분석',
    byTradingView: 'TradingView에 의한',
    alphaGeneration: {
      intro: 'MT4 및 MT5용 알파 제너레이션',
      introDesc: '모네타 마켓 전용 프리미엄 EA 3개로 여러분의 트레이딩 우위를 강화하십시오.',
      analystViews: {
        title: '기술적 뷰',
        desc: '가격 차트에 표시되는 즉각적인 기술 가격 수준과 트렌드.',
      },
      adaptiveCandlesticks: {
        title: '적응형 캔들스틱',
        desc: '인기 있는 16개의 캔들스틱 패턴을 실시간으로 스캔',
      },
      adc: { title: 'ADC', desc: '롱 및 숏 방향의 단기 진입 및 퇴장 거래 신호.' },
      install: '알파 제너레이션 설치 방법',
      installSteps: {
        one: '아래의 "알파 제너레이션 다운로드" 버튼을 클릭하여 PC용 알파 제너레이션을 다운로드하십시오.',
        two: '파일을 두 번 클릭하여 실행하고 선택한 언어로 과정을 안내하는 설치 마법사를 따릅니다.',
        three: '모네타 마켓 MT4 또는 MT5 대상 폴더를 선택하십시오.',
        four: '설치가 완료되면 MT4 또는 MT5 플랫폼을 열고 MetaTrader 표시기 목록에 있는 다른 표시기와 함께 Trading Central 표시기를 찾습니다.',
        five: '차트에 지표를 끌어다 놓으면 즉시 실행 가능한 거래 인사이트를 얻을 수 있습니다.',
      },
      button: { guid: '사용자 가이드', download: '알파 제너레이션 다운로드' },
    },
  },
  tradingView: {
    launch: '론칭하기',
    tnc: 'MT{mtServer} 로그인 정보로 TradingView 차트 및 거래 플랫폼에 로그인하세요. <a href= https://{regulatorUrl}/platforms/trading-platforms/charts-by-tradingview/ >여기</a>에서 TradingView의 CHARTS에 대해 자세히 알아보세요.',
    subTitle:
      '1,500만 명의 다른 투자자들과 함께 TradingView의 차트, 지표 및 그리기 도구로 거래를 시작하세요.',
    intro:
      'TradingView의 CHARTS를 소개합니다. Moneta 고객은 이제 내장된 TradingView 플랫폼인 CHARTS를 통해 거래 아이디어를 찾고, 추세를 파악하여, 실시간 거래를 할 수 있습니다.',
    descp1: '100개 이상의 기술 지표',
    descp2: '실시간 시장 뉴스 업데이트',
    descp3: '50개 이상의 지능형 그리기 도구',
    descp4: '다중 시간 프레임 분석',
    descp5: '12가지 다양한 거래 알림 환경',
    descp6: '다양한 기간',
    descp7: ' 50개 이상의 스마트 그리기 도구',
    descp8: '20개 이상의 차트 레이아웃',
    descp9: ' 12개 이상의 커스텀 가능한 차트 유형',
    descp10: '내재 시장 뉴스 보도',
    learnMore: '더 알아보기',
    tnc02:
      '지금 MT{mtServer} 로그인 정보를 사용하여 프로트레이더 웹 플랫폼을 경험하십시오. {findMore}',
    tnc03: '더 찾아보기',
    subTitle02:
      'Moneta 프로트레이더 플랫폼 사용자는 이제 TradingView에서 제공하는 실시간 가격 차트, 기술 지표 및 그리기 도구에 액세스 할 수 있습니다. 300 개 이상의 FX 쌍, 지수, 상품, 주식 CFD 등이 있습니다.',
    intro02: '다음에 액세스 할 수 있습니다.',
    intro03: '제공',
  },
  TFAuth: {
    title: '2 단계 인증',
    enable: {
      enableTitle: '2 단계 인증 활성화',
      enableDesc:
        '모바일 이중 인증을 활성화하여 계정을 안전하게 유지하세요. 2 단계 인증은 로그인할 때마다 2 단계의 인증 단계를 통해 추가적인 보안 단계를 제공하여 안심할 수 있습니다. 활성화되면 언제든지 iOS 또는 Android 휴대폰의 앱에서 이중 인증 코드를 검색하여 제출하고 안전하게 로그인할 수 있습니다.',
      enableSubtitle: '2 단계 인증을 활성화하는 방법',
      step1:
        'iOS 또는 Android 앱 스토어에서 무료로 Google Authenticator를 다운로드하여 설치하세요.',
      step2: '앱에서 "바코드 스캔"을 선택하고 아래 바코드를 스캔하세요.',
      step3: '아래 앱에 숫자 코드를 입력하세요 (30 초마다 새로고침).',
      placeHolder: ' 코드',
    },
    disable: {
      disableTitle: '2 단계 인증 비활성화',
      disableDesc: '이중 인증을 끄려면 여기에 Google OTP 앱의 숫자 코드를 제출하세요.',
    },
    dialog: {
      title: '2 단계 인증 코드를 입력하세요',
      contact: ' 로그인에 문제가 있으신가요? {link}.',
      link: '문의하기',
    },
    popup: { popup1: '2 단계 인증이 활성화됩니다.', popup2: '2 단계 인증이 비활성화됩니다.' },
  },
  promotion: {
    depositBonus: {
      depositBonus: '입금을 하고 거래 보너스를 받으세요',
      title: '보너스를 받고 입금액을 늘리세요!',
      subtitle1:
        '더 큰 거래 능력을 원하십니까? 여러분은 실거래 계정에 자금을 입금하기만 하면 됩니다. 입금이 접수되면, 입금 보너스가 거래 계정에 표시됩니다.',
      fund: '계정에 자금을 입금하세요',
      tnc: '*{link}',
      link: '이용 약관이 적용됩니다. ',
      bonusHistory: '보너스 내역',
      overBonus:
        '* 최대 ${totalLimit} 보너스 크레딧에 도달했습니다. 추가 입금에 대한 보너스 크레딧을 받을 수 없습니다.',
      opt: '입금 보너스 신청에 참여하시겠습니까?',
      tick: '입금 보너스 프로모션에 참여하려면, 체크박스를 선택하세요.',
      tick2:
        'Moneta는 다음과 같은 경우 행사 또는 프로모션에 대한 고객의 참여를 제외 또는 취소할 수 있는 권리를 보유합니다.<br><br> a) 과당매매(단순히 이익을 얻기 위한 마진 FX 또는 CFD 거래의 집행 및 청산)<br> b) 고객이 고객 계약을 위반하거나 프로모션 이용약관을<br><br> 위반한 것으로 판명된 경우, 그러한 위법 행위 또는 위반의 경우 Moneta는 고객이 거래 계정에서 얻은 보너스 금액과 이익을 공제할 권리가 있습니다.\n      <br><br>당사에서 고객이 내부적으로(당사에서 보유한 다른 거래 계정을 사용하여) 또는 외부적으로(다른 브로커가 보유한 다른 거래 계정을 사용하여) 본인의 포지션을 헤지함으로써 이 보너스 제도의 이용약관을 남용 또는 조작하거나 선의로 행동하지 않았다고 의심 또는 믿을 만한 이유가 있는 경우, 당사는 절대적인 재량으로 고객의 동의 없이 고객이 거래 계정에서 얻은 보너스 금액과 이익을 즉시 제거할 수 있는 권리를 보유합니다.\n      <br><br>Moneta는 언제든지 이 이용약관을 변경할 수 있으며 변경된 경우, Moneta 웹사이트에 변경된 약관을 게시하여 이러한 변경사항에 대해 공지하게 됩니다. 이 약관을 정기적으로 변경하고 Moneta 웹사이트 및 서비스를 계속 이용하려면 변경된 약관 수락을 권장합니다. Moneta는 단독 재량에 따라 언제든지 행사를 변경하거나 취소할 수 있는 권리를 보유합니다.',
      saveChange: '변경 사항 저장',
      creditReceived: '받은 크레딧',
      welcomeBonusTable: '환영 보너스',
      depositBonusTable: '입금 보너스',
      depositDate: '입금일',
      credit: '신용 거래',
      optIn: '성공적으로 참여하세요',
      optOut: '성공적으로 옵트 아웃',
      notification:
        '<div>입금 보너스 캠페인에 참여할 수 있습니다. 입금 보너스를 받으려면 입금 전에 \'<a href="{depositBonusLink}">참여</a>\'를 하세요. 여기에서 자세히 <a href="{tncLink}" target="_blank">알아보세요</a>.</div>',
      optInButton: '참여',
      dontShowAgain: '이 메시지를 다시 표시하지 않음',
    },
    referAFriend: {
      congratulations:
        '축하합니다! 여러분은 추천인으로 친구 추천하기 프로모션에 성공적으로 참여했습니다.',
      redeem: '사용하기',
      redeemed: '사용 완료',
      dayRemain: '남은 일수',
      voucherDesc: '바우처는 {lots}랏 이상 거래 시 사용 가능합니다',
      title: '친구를 추천하면 최대 ${amount} 및 친구를 위해 ${secAmount}까지 얻을 수 있습니다!',
      desc: '추천한 친구가 30일 이내에 다음 단계를 완료하면 여러분은 최대 ${amount}를 얻게 됩니다! 그리고 추천할 수 있는 친구의 수에는 제한이 없습니다! *<a href="{tncLink}">이용약관 적용</a>. ',
      register: '가입하기',
      forYourFriend: '친구를 위해',
      liveAcc: '실거래 계정 개설하기 ',
      makeDeposit: '입금하기',
      trade1Lot: '1랏 거래',
      trade5Lots: '5랏 거래',
      totalEarning: '전체 수익',
      referralAct: '추천 활동',
      referralName: '추천 이름',
      registered: '가입 완료',
      accOpen: '계정 개설 완료',
      madeDeposit: '입금 완료',
      redeemStatus: '사용 상태',
      notApplicable: '해당 없음',
      country: '국가',
      acc: '계정',
      share: '공유하기',
      copyLink: '링크 복사',
      redeemYourbonus: '보너스 사용하기',
      tatolAmtConvert: '기본 통화로 변환된 총 금액은 {convertAmt} {currency}입니다',
      remark: '*지금 사용하기를 클릭하면 이용약관에 동의하게 됩니다',
      redeemNow: '지금 사용하기',
      overlimit: '한도 초과',
      newUser: '신규 사용자',
      tradeCash: '현금 거래',
    },
    cashback: {
      button: {
        applyForBonus: '보너스 신청',
        redeemCashback: '캐시백 신청',
        howItWorks: '작동 방법은?',
        depositNow: '지금 입금하기',
        stp: '스탠다드 어카운트',
        ecn: '프라임 ECN어카운트',
        agree: '동의',
        cancel: '취소',
      },
      title: {
        monetaProgram: '모네타 마켓 캐시백 프로그램',
        claim: '지금 50% 캐시백 보너스를 받으세요!',
        howItWorks: '이런 방식으로 작동합니다.',
        howMuch: '거래에서 얼마나 수익을 올리시나요?',
        treeSimpleSteps: '지금 50% 보너스를 받아보세요 - 세 가지 간단한 단계로:',
        depositTradEarn: '입금하고 거래하고 수익 창출하세요!',
        bonusSummary: '보너스 요약',
        historicalEarnings: '현재 및 과거 수익',
        program: '캐시백 프로그램',
        cashSummary: '캐시백 요약',
        faqs: '캐시백 보너스 자주 묻는 질문들',
        redeemCashback: '캐시백 신청',
        cashbackBalance: '캐시백 잔액',
      },
      subTitle: {
        lowerYourCost: '비용을 낮추고 현금을 벌어보세요.',
        bonus: '보너스',
        cashback: '캐시백',
        wallet: '지갑',
        availableBonus: '보너스 가능',
        totalBalance: '총 잔액',
        step1: '자금을 입금하고 신청하세요!',
        step2: '거래를 시작하세요!',
        step3: '캐시백을 청구하세요!',
        FX: '외환',
        XAU: '골드',
        OIL: '오일',
        perLot: '랏당',
        seetheBonusStatus: "사용 가능한 보너스를 신청하려면 '보너스 상태' 열을 확인하세요.",
        tradeFxOilAndGold: '환율, 원유 및 금을 거래하여 보너스를 캐시백으로 전환하세요!',
        totalCashback: '총 캐시백',
        FebruaryCashback: '보류 중인 캐시백',
        cashbackRedeemable: '환급 가능한 캐시백',
        account: '계좌',
        amount: '금액',
      },
      information: {
        availableBonus1:
          '사용 가능한 보너스는 모든 적격 입금에 대해 얻은 보너스 크레딧의 금액입니다. 500달러 이상의 각 입금에 대해 해당 금액의 50%를 보너스 크레딧으로 받게 됩니다.',
        total: '총 잔액은 환급 가능한 캐시백과 현재 달의 캐시백 금액의 합계입니다.',
        availableBonus2:
          '가용 보너스는 모든 유효한 입금에 대한 보너스 크레딧 중 아직 환전되지 않고 캐시백 지갑으로 이체되지 않은 수량입니다.',
        totalCashback: '총 캐시백은 환전하여 현금으로 변환된 보너스 크레딧의 수량입니다.',
        februaryCashback:
          '현재 달에 환전하여 현금으로 변환된 보너스 크레딧입니다. 다음 달 초에 이를 거래 계좌로 이체할 수 있습니다.',
        cashbackRedeemable:
          '지난달 끝까지 환전하여 현금으로 변환된 크레딧의 수입니다. 이 금액을 언제든지 거래 계정으로 이체할 수 있습니다.',
        selectTheTradingAccount: '캐시백을 이체하길 원하는 거래 계정을 선택하세요.',
        enterTheAmount: '거래 계정으로 이체하길 원하는 금액(미국 달러)을 입력하세요.',
      },
      content: {
        claimContent1:
          '이제부터 라이브 계정을 500달러 이상 입금하실 때마다 50% 보너스가 지급됩니다. 이 보너스는 클라이언트 포털 홈페이지의 보너스 지갑에 표시됩니다. 그리고 FX, 골드, 또는 오일을 거래할 때 보너스 크레딧은 실제 현금으로 변환되어 캐시백 지갑에 표시됩니다. 매월 초, 캐시백 지갑의 자금은 라이브 거래 계정으로 이체되어 실제 자금으로 사용할 수 있습니다!',
        claimContent2:
          '더 좋은 점은 앞으로 500달러 이상의 입금을 할 때마다 보너스가 자동으로 보너스 지갑에 추가된다는 것입니다. 이렇게 하면 더 많은 보너스를 지속적으로 얻을 수 있습니다!',
        howItWorksContent1:
          "500달러 이상의 금액으로 실제 계정을 입금하고, 그런 다음 '신청'을 클릭하고 약관에 동의하시면 50%의 보너스를 받으실 수 있습니다.",
        howItWorksContent2:
          "FX, Gold, 또는 Oil을 거래하면 보너스 크레딧이 자동으로 실제 거래 가능한 현금으로 전환되며, 이를 'Cashback Wallet'에서 확인하실 수 있습니다!",
        howItWorksContent3:
          "새로운 달이 시작될 때마다, 'Cashback Wallet'에서 'Cashback Redeemable'로 표시된 금액을 교환하기 위해 'Cashback 상환'을 클릭해주세요.",
        stepsContent1: '500달러 이상을 입금하고 아래에서 보너스를 신청하세요.',
        stepsContent2: 'FX, Gold 및 Oil을 거래하여 보너스를 실제 현금으로 변환하세요!',
        stepsContent3: '이전 달의 거래 활동에서 Cashback을 청구하세요!',
        depositTradEarnContent1: '더 많이 거래하실수록 더 많이 수익이 발생합니다.',
        depositTradEarnContent2: '$500 이상을 입금하시면 50% 캐시백 보너스를 받으실 수 있습니다!',
        optInContent1:
          'Moneta Markets Cashback 프로모션에 참여하려면 아래의 약관에 동의해야 합니다.',
        electTheDestinationAccount: '교환하려는 대상 계정을 선택하세요.',
      },
      column: {
        date: '날짜',
        deposit: '입금',
        withdraws: '출금',
        bonus: '보너스 청구',
        status: '보너스 상태',
        account: '계좌',
        redeem: '환급된 캐시백',
        reason: '노트',
      },
      tnc: {
        title: '이용 약관',
        content: {
          item1: {
            title: '캐시백 이용 약관',
            li1: 'Moneta Markets는 등록 번호 2023-00068로 세인트루시아 국제 비즈니스 기업 등록처에 등록된 Moneta Markets Ltd의 상호명입니다.',
            li2: '캐시백 보너스 이용 약관"은 "Moneta Markets Ltd 이용 약관"의 필수 부분이며, 여기에 기재된 조항은 모두 "Moneta Markets Ltd 이용 약관"의 부록으로 간주되어 함께 읽어야 합니다.',
          },
          item2: {
            title: '적격 조건',
            li1: '모든 기타 이용 약관에 따라 "캐시백 보너스" 혜택을 받을 수 있는 Moneta Markets의 클라이언트들을 대상으로 합니다 ("적격 클라이언트"). 이후 섹션에서 기술된 조건을 충족한 클라이언트가 적격 클라이언트입니다.',
            li2: 'Moneta Markets는 Moneta Markets와 라이브 트레이딩 계정(데모 트레이딩 계정이 아님)을 개설한 고객에게 "캐시백 보너스"를 제공합니다.',
            li3: '캐시백 보너스"는 직접 STP와 Prime ECN 계정에만 적용됩니다. PAMM, MAM, Ultra ECN과 "Cent" 계정은 "캐시백 보너스" 프로모션에 참여할 수 없습니다.',
            li4: 'Moneta Markets는 재량에 따라 어떠한 국가/지역의 클라이언트들에게도 "캐시백 보너스"를 제공할 수 있으며, Moneta Markets가 제공하려고 하는 기간에 대해 이를 철회하거나 취소할 수 있습니다. 회사는 지역 규정 및/또는 요구 사항에 따라 자격을 철회하거나 취소할 권리를 보유합니다.',
            li5: '"캐시백 보너스"에 참여할 수 있는 조건에 해당하는 국가 거주자는 해당 국가의 적법한 계약을 체결할 수 있는 자입니다. 앞의 내용을 제한하는 것 없이 ""캐시백 보너스""에 참여할 수 없는 사람들은 18세 미만의 미성년자입니다. 이들은 회사와 계정을 개설할 자격이 없습니다.',
            li6: '"중개인/관련 당사자"는 "캐시백 보너스"에 참여할 수 없습니다. "캐시백 보너스" 참여자의 등록 및/또는 트레이딩 데이터가 다른 "캐시백 보너스" 참여자의 등록 및/또는 트레이딩 정보(예: IP 주소)와 일치하는 경우, Moneta Markets는 이러한 일치를 즉시 탈락 사유로 간주할 수 있습니다. 여기에 기술된 목적을 위해, 이용 약관에서 "중개인(들) / 관련 당사자(들)"라는 용어는 맥락에 따라 그렇지 않는 한 "캐시백 보너스" 참여자와 관련된 모든 사람 또는 단체를 의미합니다. 이는 다음과 같은 사람들을 포함합니다:',
            li6_sub_items: {
              li1: '가족 구성원, 형제, 자매, 배우자, 선조, 직계 자손 및 역계 자손',
              li2: '참여자와 직접 또는 간접적으로 통제 또는 통제를 공유하는 사람 또는 단체. 이에 대한 정의는 해당 참여자에 대한 "컨트롤"이라는 용어를 포함합니다. 컨트롤은 해당 참여자에 대해 직접 또는 간접적으로 하나 이상의 중개인을 통해 경영 정책을 지시하거나 방향을 설정하는 권한을 보유하는 것을 의미합니다.',
              li3: 'Moneta Markets와/또는 Moneta Markets의 특정 웹사이트 및/또는 소셜 네트워크 사이트와 어떠한 방식으로든 관련된 사람들.',
            },
          },
          item3: {
            title: '구체적인 "캐시백 보너스" 조건:',
            li1: '고객은 먼저 최소 $500을 입금한 후에 클라이언트 포털에서 50% 크레딧 보너스를 성공적으로 신청하여 보너스 크레딧을 받아야 합니다. 이후 입금으로 인한 보너스 크레딧은 자동으로 적용됩니다.',
            li1_sub_desc:
              '프로모션 기간 동안에만 유효하며 프로모션 기간 내 입금이 유효하며 이에 참여 가능합니다. 내부 이체, 추천인 보너스 및 현금 조정은 이 프로모션에 해당하지 않습니다.',
            li2: '캐시백 보너스"는 직접 STP와 Prime ECN 계정에만 적용됩니다. Ultra ECN 및 "센트" 계정은 "캐시백 보너스" 프로모션에 참여할 수 없습니다.',
            li3: '자격이 있는 고객(신규 및 기존 고객 모두)은 다음과 같은 기준을 충족하는 경우 현금 환급을 받을 수 있습니다:',
            li3_sub_content: {
              title1: '[직접 STP 및 ECN 리베이트 표]',
              info1: '직접 STP: FX, 원유, 금 로트 당 $2',
              info2: 'Prime ECN: FX, 원유, 금 로트 당 50센트',
              title2: '[IB 속한 STP 및 ECN 리베이트 표]',
              info3: '직접 STP: FX, 원유, 금 로트 당 50센트',
              info4: 'Prime ECN: FX, 원유, 금 로트 당 25센트',
            },
            li3_sub_desc:
              '캐시백이 활성화되기 위한 최소 누적 거래 로트 크기는 0.01 표준 로트입니다. 만약 클라이언트의 해당 날짜에 유효한 거래 로트가 0.1 표준 로트로 축적되지 않으면 0으로 초기화됩니다. 누적 로트 수는 0.01 표준 로트의 배수로 계산되며, 예를 들어 0.02 표준 로트, 0.03 표준 로트와 같이 될 수 있습니다. (0.12 표준 로트는 0.12 표준 로트로 계산됩니다).',
            li4: '적격 상품: 모든 외환, 금 및 유가 기초 계약 (CFD) 상품들',
            li5: '유효한 거래량: 프로모션 기간 동안 새롭게 개설하거나 청산한 주문들. 10분 미만으로 개설된 포지션, 롱과 숏이 락 거래 된 포지션, 헷지된 포지션은 이 프로모션의 유효한 거래로 간주되지 않습니다.',
            li6: '이 제공은 다른 제공들과 병행하여 사용할 수 있습니다.',
            li7: '자금 인출은 사용자의 보너스 지갑에서 동일한 금액을 제거합니다. 인출 금액이 보너스 크레딧 금액보다 큰 경우 크레딧 금액은 0으로 감소됩니다.',
            li8: 'Moneta Markets는 이러한 약관에 따라 고려하여 고객 계정을 재설정하거나 조정함으로 인해 발생할 수 있는 모든 비용, 경비 또는 손해에 대해 책임지지 않습니다. 이로 인한 손실에는 자동으로 폐쇄된 오픈 포지션에 기인한 손실도 포함됩니다.',
            li9: 'Moneta Markets는 다음과 같은 단독 재량으로 결정할 권리가 있습니다:',
            li9_sub_items: {
              li1: '"Cashback Bonus"에 참가할 수 있는 모든 참가자의 등록을 거부할 수 있습니다.',
              li2: '"Cashback Bonus"에 참가하는 참가자들 중 위법하거나 이 약관 또는 Moneta Markets 웹사이트에 기재된 "Moneta Markets 약관 및 조건"에 위반하는 경우 해당 참가자를 탈락시킬 수 있습니다.',
            },
            li10: 'Moneta Markets는 합리적인 판단에 따라 이 "Cashback Bonus"를 제공하는 것을 중단할 권리가 있으며, 이에 대해 설명할 필요 없이 이를 적용할 수 있습니다. 해당 고객들은 이메일을 통해 통지됩니다.',
            li11: 'Moneta Markets는 "Cashback Bonus" 또는 이와 관련된 어떤 측면도 언제든지 변경, 수정 또는 종료할 권리가 있으며 이에 대한 보상을 제공할 필요 없습니다. 이러한 변경 사항에 대한 사전 공지가 클라이언트에게 제공됩니다. "Cashback Bonus"에 참여하는 것은 이러한 변경, 수정 및/또는 변화를 준수하고 동의하는 것으로 간주됩니다.',
            li12: '모네타 마켓스의 단독 판단에 따라 어떠한 형태의 아비트리지(무위험 수익 포함) 또는 남용(참가자의 거래 활동 패턴이 참가자가 시장에서 진정으로 거래하거나 시장 리스크를 감수하지 않고 현금백을 경제적 이익만 추구하는 것을 나타냄) 또는 사기, 조작, 캐시백 프로모션과 관련된 캐시백 아비트리지 또는 기타 부정직하거나 사기적인 활동의 증거 또는 의심이 있을 경우, 해당 대상자의 실제 거래 계좌의 모든 이전 캐시백 및 모든 거래와 이로 인해 발생한 이익 또는 손실은 무효화됩니다. 이러한 상황에서 모네타 마켓스는 단독 재량에 따라 해당 대상자의 실제 거래 계좌를 일시적 또는 영구적으로 폐쇄/중지하고, 모든 주문을 취소하며 해당 대상자의 모든 이익을 취소할 수 있습니다. 이러한 상황에서 모네타 마켓스는 캐시백 취소로 인한 어떠한 결과에 대해서도 책임을 지지 않습니다.',
            li13: '"캐시백 보너스"에 참여함으로써 이 약관과 모네타 마켓스 웹사이트에 명시된 모네타 마켓스 약관을 동의하는 것으로 간주됩니다.',
            li14: '"캐시백 보너스"는 특정 프로모션 또는 설문조사의 참여 규칙에 명시된 기간에 한해 제공됩니다.',
            li15: '"캐시백 보너스"의 약관과 조건이 영어 이외의 언어로 번역된 경우, 영어 버전이 상충되는 해석의 경우 우선합니다.',
            li16: '캐시백 보너스는 튀르키예에 거주하는 고객에게는 적용되지 않습니다.',
            li17: '2023년 7월 19일부터 500달러 이상의 예금만 캐시백 보너스를 받을 수 있습니다.',
          },
          item4: {
            title: '위험 경고',
            li1: '파생 상품 및 외환 거래는 투자자본에 대한 고위험을 내포하며 손실 가능성을 충분히 감수할 수 있는 자금으로만 거래해야 합니다. 거래 결정 전에 Legal Documents 페이지에서 문서를 읽고 독립적인 자문을 구해 위험을 완전히 이해하십시오. 이 프로모션은 귀하의 목표, 금융 상황 또는 필요에 따라 준비되었습니다.',
          },
        },
      },
      faqsList: {
        question_1: 'Moneta Markets Cashback 프로그램이란 무엇이며, 어떻게 작동하나요?',
        question_2: 'Cashback 프로모션에 가입하는 방법은 무엇인가요?',
        question_3: '보너스 크레딧이란 무엇인가요?',
        question_4: 'Cashback이란 무엇인가요?',
        question_5: '보너스 및 Cashback 잔액을 어떻게 확인하나요?',
        question_6: '보너스 크레딧은 만료될 수 있나요?',
        question_7: 'Cashback 잔액은 만료될 수 있나요?',
        question_8: '혜택은 무엇인가요?',
        question_9: '얼마나 많은 보너스 크레딧을 얻을 수 있나요?',
        question_10: '얼마나 많은 Cashback을 얻을 수 있나요?',
        question_11: '언제 Cashback 보너스를 인출할 수 있나요?',
        question_12: '사용 가능한 Cashback을 거래 계정으로 어떻게 이체하나요?',
        question_13: 'Cashback은 얼마나 자주 계산되나요?',
        question_14: '모든 상품에서 Cashback을 얻을 수 있나요?',
        question_15: 'Cashback 보너스를 어떻게 인출할 수 있나요?',
        question_16: '인출이 보너스 크레딧에 영향을 미치나요?',
        question_17: '인출이 Cashback 보너스에 영향을 미치나요?',
        question_18: '보너스를 Cashback으로 전환하기 위한 최소 거래 기간이 있나요?',
        question_19: '보너스 크레딧을 어떻게 증가시킬 수 있나요?',
        question_20: '월간 Cashback을 극대화하기 위해 어떻게 해야 하나요?',
        question_21: 'Direct STP와 Prime ECN 계정에서 동일한 Cashback 금액을 받을 수 있나요?',
        question_22: 'Ultra ECN 또는 Cent 계정에서 Cashback을 받을 수 있나요?',
        question_23: '보너스 상태는 무엇이며, 그 의미는 무엇인가요?',
        question_24: 'Cashback 프로그램에서 탈퇴하는 방법은 무엇인가요?',
        answer_1:
          'Moneta Markets Cashback 프로그램은 고객이 최소 $500을 예금하여 50%의 보너스 크레딧을 받는 충성도 프로그램입니다. 이러한 보너스 크레딧은 FX, Gold 및 Oil 상품에서의 거래 활동을 기반으로 인출/거래 가능한 현금으로 전환됩니다.',
        answer_2:
          'Moneta Markets의 자격을 갖춘 거래 계정을 가진 고객은 Moneta Markets 클라이언트 포털 내에서 첫 번째 보너스 크레딧 금액을 "신청"해야 합니다. 보너스 지갑(Bonus Wallet)에서 "보너스 신청"을 클릭한 다음 "현재 및 과거 수입" 테이블에서 "신청" 버튼을 클릭하고 약관에 동의하면 됩니다. 이후에는 자격을 갖춘 예금에 대한 추가 보너스 크레딧이 자동으로 적용됩니다.',
        answer_3:
          '보너스 크레딧은 $500 이상의 자격을 갖춘 예금에 대해 50%의 비율로 적립되는 것입니다. 보너스 크레딧은 보너스 지갑에 보유되며 모든 FX, Gold 및 Oil 상품에서의 거래 활동에 기반하여 실제 Cashback으로 전환됩니다.',
        answer_4:
          'Cashback은 거래 활동으로 인한 보너스 크레딧 변환을 기반으로 획득한 현금 금액을 의미합니다. Cashback 금액은 거래 계정으로 이체하여 실제 현금으로 사용할 수 있습니다.',
        answer_5:
          '보너스 및 Cashback 잔액은 클라이언트 포털의 홈 화면에서 확인할 수 있습니다. 보너스 지갑(Bonus Wallet)과 Cashback 지갑(Cashback Wallet)이 표시됩니다. Cashback 지갑의 세부 내역을 보려면 "Promotions" - "Cashback"을 클릭하거나 클라이언트 포털 홈 페이지의 "Cashback Wallet"에서 "Cashback Redeemable" 및 현재 달의 Cashback 금액을 확인할 수 있습니다.',
        answer_6:
          '아니요, 보너스 크레딧은 만료되지 않습니다. Cashback Bonus에 가입하면 모든 예금 및 인출이 이전 30일 동안 계산되어 사용 가능한 보너스를 계산합니다. 이후에는 향후 예금에서 추가 보너스 크레딧이 자동으로 적용되며 만료되지 않습니다. "현재 및 과거 수입" 테이블에서 현재 및 과거 보너스 수입 상태("신청", "지급", "자격 없음", "차감")를 확인할 수 있습니다.',
        answer_7:
          'Cashback Bonus 금액은 만료되지 않으며 매월 초에 거래 계정으로 이체하거나 인출할 수 있습니다.',
        answer_8:
          'Moneta Markets는 여러분의 거래에 감사하며, 자격을 갖춘 예금에 대해 보너스 크레딧을 제공하여 여러분의 충성도에 감사의 마음을 전합니다. 이후에는 지속적으로 거래하면서 크레딧이 실제 Cash로 전환되어 계속된 충성도에 대한 감사의 표시로 드립니다.',
        answer_9:
          '$500 이상의 예금을 하면 해당 금액의 50%인 보너스 크레딧을 받게 됩니다. 이후에는 추가 자격을 갖춘 예금을 하면 계속해서 보너스 크레딧을 적립할 수 있습니다.',
        answer_10:
          'Cashback 금액은 계정 유형과 거래하는 상품에 따라 다릅니다. Moneta Markets Direct STP 계정에서 FX, Gold 및 Oil을 거래하면 수익을 극대화할 수 있습니다. 보너스 크레딧은 다음과 같은 비율로 Cashback으로 전환됩니다:<br/>[Direct STP 및 Prime ECN 환급 테이블]<br/>Direct STP: FX, Oil, Gold 1롯당 $2<br/>Prime ECN: FX, Oil, Gold 1롯당 $0.50',
        answer_11:
          'Available Cashback"으로 표시된 잔액은 언제든지 거래 계정으로 이체할 수 있습니다. 현재 달에 적립된 Cashback은 다음 달 초에 거래 계정으로 이체할 수 있습니다.',
        answer_12:
          'Cashback Wallet에서 "Redeem Cashback"을 클릭한 다음 이체하려는 거래 계정과 Cashback 금액을 선택한 후 "Redeem Cashback"을 다시 클릭하면 됩니다.',
        answer_13:
          'FX, Gold 및 Oil 상품에서의 거래 활동에 의한 Cashback은 GMT+2 기준으로 매일 계산되며 Cashback 지갑에 표시됩니다.',
        answer_14:
          'Cashback 보너스는 Direct STP 및 Prime ECN 계정에서 FX, Gold 및 Oil 상품의 모든 거래에서 얻을 수 있습니다. 환급 테이블에 따라 Cashback 금액이 다릅니다.<br/>[Direct STP 및 Prime ECN 환급 테이블]<br/>Direct STP: FX, Oil, Gold 1롯당 $0.50<br/>Prime ECN: FX, Oil, Gold 1롯당 $0.25',
        answer_15:
          '사용 가능한 Cashback 보너스를 인출하는 것은 간단합니다. Cashback Wallet에서 "Redeem Cashback"을 클릭한 다음 이체하려는 거래 계정과 Cashback 금액을 선택한 후 "Redeem Cashback"을 다시 클릭하면 됩니다. 이후에는 이체된 자금으로 거래를 진행하거나 처리를 위해 인출 요청을 제출하면 됩니다.',
        answer_16:
          '보너스 지갑에 보유한 크레딧은 자금을 인출하면 비례적으로 차감됩니다. 예를 들어, 보너스 지갑에 500 크레딧이 있고 거래 계정에서 $250을 인출한다면 250 보너스 크레딧이 차감됩니다. 하지만 자금을 인출하더라도 보너스 크레딧은 음수 잔액이 될 수 없습니다.',
        answer_17:
          '아니요. Cashback 보너스는 거래를 인출하더라도 영향을 받지 않으며 그대로 유지됩니다.',
        answer_18:
          '보너스가 Cashback으로 전환되기 위해서는 FX, Gold 또는 Oil 거래가 최소 10분 동안 유지되어야 합니다.',
        answer_19:
          '자격을 갖춘 예금을 하면 보너스 크레딧이 계속해서 증가합니다. $500 이상의 예금을 하면 해당 금액의 50%인 보너스 크레딧을 받을 수 있습니다.',
        answer_20:
          'Cashback을 극대화하기 위해서는 두 가지 요인이 중요합니다. 먼저 충분한 크레딧이 보너스 지갑에 있어야 하며, FX, Gold 및 Oil을 Direct STP 계정에서 거래하여 Cashback을 극대화해야 합니다. 또한 더 많은 거래를 하고 거래량을 늘릴수록 보너스 크레딧이 더 빨리 Cashback으로 전환됩니다.',
        answer_21:
          'Direct STP와 Prime ECN 계정에서 FX, Gold 및 Oil 상품에 대한 Cashback 금액이 다릅니다.<br/>[Direct STP 및 Prime ECN 환급 테이블]<br/>Direct STP: FX, Oil, Gold 1롯당 $0.50<br/>Prime ECN: FX, Oil, Gold 1롯당 $0.25',
        answer_22:
          '아쉽게도 Ultra ECN 또는 Cent 계정에서 FX, Gold 또는 Oil 거래로부터 Cashback을 얻을 수 없습니다. Direct STP 또는 Prime ECN 계정에서 거래해야 합니다.',
        answer_23:
          '보너스 상태는 다음과 같은 의미를 갖습니다.<br/> 신청(Apply): 처음 자격을 갖춘 예금 시 보너스를 받기 위해 "신청"해야 합니다. 이후의 예금은 자동으로 지급됩니다.<br/>지급(Credited): 보너스가 지급되어 보너스 지갑에 표시됩니다.<br/>자격 없음(Ineligible): 최소 $500의 요구 사항을 충족하지 못하여 보너스를 받을 수 없습니다.<br/>차감(Deducted): 거래 계정에서 자금을 인출하면 보너스가 차감됩니다.',
        answer_24:
          'Cashback 프로그램에서 탈퇴하려면 <a href="mailto:{email}">{email}</a> 으로 요청하면 됩니다',
      },
      reason: {
        1: '보너스 지갑에 50% 보너스가 추가되었습니다.',
        2: '수동 보너스 조정 - 추가됨',
        3: '출금 금액이 보너스 지갑에서 제거되었습니다.',
        4: '이 보너스 금액이 캐시백으로 전환되었습니다.',
        5: '수동 보너스 조정 - 공제됨',
        6: '이 입금은 최소 입금 금액을 충족시키지 못하여 자격이 없습니다.',
      },
      status: { 0: '신청', 1: '지급됨', 2: '공제됨', 3: '자격 없음' },
      zeroMsg: '적립된 캐시백을 환급할 수 없습니다.',
    },
  },
  hmcTraderPortal: {
    overview: '개요',
    interactiveAnalysis: '대화형 분석',
    learnFromTheBest: '최고로부터 배우기',
    requestAResearch: '조사 요청',
    vipSupport: 'VIP 지원',
    eligibility: '해당 자격',
    login: 'HMC 라이브 트레이딩 룸에 로그인',
    depositRequirement: 'HMC 라이브 트레이딩 룸에 액세스하기 위한 예치액 요건 충족',
    basic: '기본',
    vip: 'VIP',
    exclusiveAnalysis: '독점 분석',
    analysisDiscussions: '분석 논의',
    tradingSignals: '거래 신호',
    tradingSignalRequest: '거래 신호 요청',
    privateAnalysisRequest: '비공개 분석 요청',
    prioritySupport: '우선 지원',
    minimumFundingRequirement: '최소 자금 요건 및 매월 기준 수량 $200,000 거래 명목 가치*',
    tnc1: '<a href="{url}" target="_blank">portal.hmctrader.com</a>에 제시된 정보는 제3자 제공업체(‘HMC 트레이더’)가 Moneta(‘Moneta Global Limited’, ‘당사’)에 제공합니다. 이 정보는 전적으로 연구 및 정보 제공 목적을 위한 것이며, 특정 상품을 매매 또는 보유하거나 특정 투자 전략을 추구하기 위한 어떠한 재무 조언도 포함하지 않습니다. 이 정보는 특정인의 투자 요구에 따라 맞춤형으로 제공되지 않으므로 정보 열람자의 투자 목적, 재무 상황 또는 요구를 고려하지 않습니다. 또한 과거의 성과가 미래의 성과 및/또는 결과에 대한 신뢰할 수 있는 지표가 아님에 유의하십시오. 실제 결과는 미래 예측 또는 과거 성과 보고에서 예상한 결과와 상당히 다를 수 있습니다. 당사는 여기에 제공된 정보의 정확성 또는 완전성에 대해 어떠한 책임도 지지 않으며 HMC 트레이더가 제공한 정보로부터 발생할 수 있는 손실에 대해서도 책임 지지 않습니다.',
    tnc2: '* HMC 라이브 트레이딩 룸 서비스 이용 자격을 갖추려면 출시일 이후 상기 명시된 초기 최소 자금 요건을 충족해야 합니다. HMC 라이브 트레이딩 룸에서 액세스 권한을 유지하려면 매월 Moneta가 제공하는 상품의 기준 수량 $200,000 거래 명목 가치 이상을 거래해야 합니다. 한 달에 최소 기준 수량 $200,000 거래 명목 가치를 거래하지 않으면 귀하의 액세스 권한이 자동으로 비활성화됩니다. HMC 라이브 트레이딩 룸에서 액세스를 재활성화하려면 자금 요청액을 추가로 예치해야 합니다.',
  },
  supportTickets: {
    support: '지원',
    mySupportTickets: '나의 지원 티켓',
    createNewTicket: '새로운 티켓 만들기 ',
    ticketNo: '티켓 번호 ',
    subject: '제목 ',
    status: '상태 ',
    lastUpdated: '마지막 업데이트',
    newTicketRequest: '새로운 티켓 요청 만들기',
    content: '내용 ',
    attachments: '첨부 파일 ',
    upload: '업로드',
    viewingTicket: '티켓 번호 보기',
    postReply: '답장 올리기 =',
    update: '업데이트',
    cancel: '취소',
    close: '닫기',
    number: '번호',
    description: '설명',
    postReplyRequired: '게시물 답글 입력란은 필수 항목입니다',
    contentFieldRequired: '내용 입력란은 필수 항목입니다',
    subjectFieldRequired: '제목 입력란은 필수 항목입니다',
    successMsg: '지원 티켓이 성공적으로 생성되었습니다!',
  },
  responseMsg: {
    410: '매개변수 검증에 실패하였습니다',
    411: '비밀번호 불일치',
    420: '사용자가 존재하지 않습니다',
    421: '비밀번호 재설정이 거부되었습니다',
    490: '중복된 카드 정보입니다. ',
    500: '에러가 발생했습니다.',
    501: '실행이 취소되었습니다. 나중에 다시 시도하십시오.',
    505: '파일 업로드에 실패하였습니다',
    510: '잘못된 SMS 코드',
    511: '휴대 전화로 SMS 코드를 보낼 수 없습니다',
    520: '로그인에 실패했습니다. 다시 로그인하시기 바랍니다.',
    521: '사용자가 이미 동일 기기에 로그인했습니다.',
    522: '다시 로그인해주십시오.',
    523: '다시 로그인해주십시오.',
    524: '귀하의 이메일 주소가 유효하지 않습니다. ',
    525: '아이디 또는 비밀번호가 일치하지 않습니다.',
    526: '보안문자가 일치하지 않습니다. 재입력해 하시길 바랍니다.',
    527: '사용자가 IB가 아닙니다',
    528: '사용자가 존재하지 않습니다',
    529: '아이디 또는 비밀번호가 일치하지 않습니다.',
    530: '로그인 세션이 유효하지 않습니다',
    531: '잘못된 코드',
    540: '귀하의 거래 계좌를 찾을 수 없습니다.',
    541: '리베이트 계좌를 찾을 수 없습니다.',
    542: '같은 이름으로 계좌를 개설 할 수 없습니다.',
    544: '신분증이 인증될 때까지 추가 계정을 신청할 수 없습니다',
    550: '계좌번호 불일치',
    551: '잔액이 부족합니다.',
    554: '요청하신 금액이 0 또는 무효입니다.',
    562: '결제를 처리할 수 없습니다. 다시 시도해주세요. 문제가 지속되면 실시간 채팅을 통해 문의하거나 {email}로 이메일을 보내주세요',
    564: '국가가 결제 채널과 일치하지 않습니다',
    565: '은행코드 불일치',
    566: '지불 방식을 찾을 수 없습니다.',
    567: '안타깝게도 이 결제 방법은 현재 사용할 수 없습니다. 불편을 끼쳐드려 죄송합니다',
    568: '이 계정은 입금이 제한되어 있습니다. {email}으로 문의해주세요',
    569: '이름은/성은/전화번호는 필수 항목입니다. 도움이 필요하면 {depositEmail}으로 문의해주세요',
    581: '프로모션에 성공적으로 선택했습니다. 자금을 인출하려면 입금해야합니다.',
    584: '요청 가능한 리베이트가 존재하지 않습니다.',
    587: '인출 금액이 잘못되었습니다.',
    590: '송금 계좌를 찾을 수 없습니다.',
    591: '귀하의 은행 계좌를 찾을 수 없습니다.',
    593: '귀하의 계정에 크레딧이 포함되어 있기 때문에 선택한 계정에서 자금 이체를 처리할 수 없습니다.',
    594: '이 계정에서 자금 이체는 허용되지 않습니다',
    624: '이 계정의 마이너스 잔액은 삭제할 수 없습니다. 자세한 내용은 support@monetamarkets.com으로 문의하십시오.',
    625: '귀하의 신청서가 접수되어 처리 중입니다. 궁금한 점이 있으시면 support@monetamarkets.com으로 문의하십시오.',
    626: '잔액이 0보다 큽니다. 페이지를 새로 고치고 다시 확인하십시오.',
    647: '확인 코드가 잘못 입력되었습니다',
    1001: '계정을 일시적으로 사용할 수 없습니다. 계정 관리자에게 문의하십시오.',
    1102: '활성화할 수 없습니다. 계정이 라이브 또는 데모 계정으로 생성되었습니다.',
    1103: '계정이 비활성화되었습니다',
    1202: '확인이 만료되었습니다. 다시 시도하세요',
    1204: '다른 이메일 주소로 시도해주세요',
    1208: '확인 코드는 반복해서 보낼 수 없습니다',
    1209: '인증 코드 전송 오류',
    session_timeout: '장 시간 로그인 하지 않았습니다. 재 로그인 해주십시오.',
    vload: {
      4019: '바우처 유형 또는 값이 허용되지 않습니다',
      4071: '사기 의혹',
      4072: '바우처 사용 완료',
      4073: '바우처가 활성화되지 않았습니다',
      4074: '바우처가 만료되었습니다',
      4075: '바우처를 찾을 수 없습니다',
      4080: '바우처의 값이 부족합니다',
      4444: '잘못된 바우처가 사용되었습니다. 통화가 일치하지 않습니다.',
      5000: '스토어 데이터를 사용할 수 없습니다',
    },
  },
  lock: {
    premium: {
      trading: { tools: { description: '프리미엄 기능을 잠금 해제하려면 지금 신청을 완료하세요' } },
    },
  },
};
