<template>
  <div class="bank_info" v-if="isShow">
    <ul v-for="item in bankInfos" :key="item.title">
      <li v-if="item.info.show">
        <span class="title_name">{{ $t(item.title) }}</span>
        <span class="value_info">{{ item.info.value }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import EqualsBankInfo from '@/bankInfo/vfsc/EqualsBankInfo';
import BankInfoSetting from '@/bankInfo/vfsc/BankInfoSetting.json';
import BankInfo from '@/bankInfo/vfsc/BankInfo.json';
// import OpenpaydBankInfo from '@/bankInfo/vfsc/OpenpaydBankInfo';
import { WHITELIST, GREYLIST } from '@/bankInfo/IbtCountry';
import { countryCodeEnum } from '@/constants/country';

export default {
  name: 'InternationalVFSCBankInfo',
  props: { isShow: Boolean, currency: String, country: String },
  computed: {
    bankInfos() {
      // const specifiedCurrency = ['USD', 'CAD', 'EUR', 'GBP'];
      const countryId = parseInt(this.country);

      // if (OPENPAYD.includes(countryId) && specifiedCurrency.includes(this.currency)) {
      //   return OpenpaydBankInfo.bank_infos(this.currency);
      // }
      switch (true) {
        case this.specifiedCountries.includes(countryId):
          return this.getBankInfo();
        case WHITELIST.includes(countryId):
          return EqualsBankInfo.bank_infos(1);
        case GREYLIST.includes(countryId):
          return EqualsBankInfo.bank_infos(2);
        default:
          return [];
      }
    }
  },
  data() {
    return {
      specifiedCountries: [
        countryCodeEnum.JORDAN,
        countryCodeEnum.OMAN,
        countryCodeEnum.BRAZIL,
        countryCodeEnum.ITALY,
        countryCodeEnum.UNITED_KINGDOM,
        countryCodeEnum.CHINA,
        countryCodeEnum.UNITED_ARAB_EMIRATES
      ]
    };
  },
  methods: {
    getBankInfo() {
      const countryId = parseInt(this.country);
      const setting = BankInfoSetting.filter(el => el.country.includes(countryId))[0];
      let bank_infos = [];
      const bankSetting = setting.currency[this.currency] ? setting.currency[this.currency] : setting.currency.default;
      for (const [key, value] of Object.entries(bankSetting)) {
        bank_infos.push({
          title: BankInfo[key].name,
          info: {
            show: true,
            value: BankInfo[key][value]
          }
        });
      }
      return bank_infos;
    }
  }
};
</script>
