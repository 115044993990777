<template>
  <div class="l_select" @click.capture="visible = true" :class="{ active: visible }">
    <el-form-item :label="label" :prop="name">
      <el-select v-on="listeners" v-bind="$attrs" :disabled="disableField" :id="name" filterable>
        <el-option
          v-for="(item, index) in lists"
          :key="index"
          :label="item[labelName] || item"
          :value="item[valueName] || item"
        ></el-option>
      </el-select>
      <slot></slot>
    </el-form-item>
  </div>
</template>

<script>
export default {
  name: 'selectTemplate',
  props: {
    name: String,
    label: String,
    lists: Array,
    valueName: { type: String, default: 'value' },
    labelName: { type: String, default: 'label' }
  },
  data() {
    return {
      visible: false
    };
  },
  computed: {
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: event => this.$emit('input', event)
      });
    },
    disableField() {
      return this.$store.state.register.disableField;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/form/select.scss';
</style>
