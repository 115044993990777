<template>
  <InternationalDefaultHeader></InternationalDefaultHeader>
</template>

<script>
import InternationalDefaultHeader from '@/components/template/deposit/InternationalDefaultHeader';

export default {
  components: { InternationalDefaultHeader }
};
</script>
