// basic group
export const WHITELIST = [
  3436, //	Ireland
  7082, //	Saint Helena, Ascension and Tristan
  7049, //	Anguilla
  7073, //	Montserrat
  7085, //	South Georgia
  7092, //	Turks & Caicos
  6431, //	Virgin Islands, British
  3915, //	Germany
  5932, //	Switzerland
  4101, //	France
  4912, //	Luxembourg
  3554, //	Austria
  3701, //	Belgium
  3481, //	Andorra
  7071, //	Mayotte
  5617, //	Monaco
  5828, //	Portugal
  7081, //	Saint Berthélemy
  6054, //	San Marino
  7044, //	Vatican City
  7095, //	Wallis and Futuna
  4688, //	Cameroon
  4160, //Cape Verde
  7008, //Chad
  5161, //Mauritania
  5696, //South Africa
  5619, //Mexico
  4186, //Colombia
  4221, //Costa Rica
  6056, //Saint Vincent and the Grenadines
  6544, //Uruguay
  7055, //Christmas Island
  7056, //Cocos (Keeling) Islands
  5156, //Marshall Islands
  6647, //New Zealand
  4986, //Maldives
  7041, //South Korea
  6565, //Uzbekistan
  3416, //Egypt
  7048, //Aland Islands
  7087, //Svalbard and Mayen
  3899, //Denmark
  4126, //Faroe Islands
  4138, //Finland
  4232, //Greenland
  5804, //Norway
  5909, //Sweden
  6109, //Eswatini (fmr. "Swaziland")
  5010, //Malawi
  6976, //Jordan
  3389, //Oman
  3636, //Brazil
  6777, // Italy
  6907, // United Kingdom
  3382, //United Arab Emirates
]
export const GREYLIST = [
  6991, //Vietnam
  3490, //Angola
  3346, //Argentina
  7050, //Aruba
  3588, //Bahamas
  7028, //Bangladesh
  3809, //Belize
  3686, //Benin
  3817, //Bhutan
  3781, //Bolivia
  3808, //Botswana
  4809, //Cook Islands
  3981, //Dominica
  3982, //Dominican Republic
  5961, //El Salvador
  7032, //Equatorial Guinea
  4137, //Fiji
  4624, //Gabon
  4183, //Gambia
  4611, //Ghana
  6404, //Guatemala
  7063, //Guernsey
  4254, //Guyana
  4514, //Honduras
  7065, //Isle Of Man
  7066, //Jersey
  4735, //Kuwait
  4546, //Kyrgyzstan
  4850, //Laos
  4838, //Lesotho
  4910, //Liechtenstein
  7069, //Macao
  4978, //Madagascar
  5681, //Namibia
  5750, //Nauru
  5751, //Nepal
  5786, //Niger
  5796, //Nigeria
  7076, //Niue
  5826, //Palau
  3600, //Paraguay
  7039, //Saint Kitts and Nevis
  6053, //Saint Lucia
  5511, //Samoa
  6024, //Seychelles
  6133, //Solomon Islands
  6057, //Sri Lanka
  6121, //Suriname
  7043, //Taiwan
  6146, //Tajikistan
  6163, //Thailand
  3959, //Timor-Leste
  3974, //Togo
  6268, //Tonga
  6385, //Turkmenistan
  3624, //Bahrain
  7010, //Chile
  4661, //Czech Republic
  3464, //Estonia
  5859, //Georgia
  6633, //Greece
  4231, //Grenada
  7062, //Guadeloupe
  7059, //French Guiana
  4127, //French Polynesia
  7033, //Hong Kong
  6673, //Hungary
  3714, //Iceland
  6845, //India
  4810, //Latvia
  4897, //Lithuania
  5015, //Malaysia
  7070, //Martinique
  4482, //Netherlands
  7075, //New Caledonia
  7077, //Norfolk Island
  5558, //Peru
  7079, //Pitcairn
  3716, //Poland
  4700, //Qatar
  7080, //Reunion
  7084, //Saint Pierre and Miquelon
  6025, //Saudi Arabia
  6084, //Slovakia
  7091, //Tokelau
  5160, //Mauritius
  3290, //Algeria
  7047, //Antigua and Barbuda
  6458, //Brunei
  5527, //Mongolia
  6877, //Indonesia
]
export const WHITELIST_AND_GREYLIST = [...WHITELIST, ...GREYLIST]

// other groups
export const OPENPAYD = [...WHITELIST]
export const EQUALS = [...OPENPAYD, ...GREYLIST]
