const vfsc = require('@/regulator/vfsc.js')

const regulator = { vfsc }

module.exports = {
  platformLang: 'en_US',
  defaultRegulator: 'vfsc',
  style: {
    menuBackground: `#1f222f`,
    menuTextColor: `#ffffff`,
    menuActiveTextColor: `#ffffff`,
  },
  info: {
    fullName: 'Moneta Markets',
    entityName: 'Monenta International Group Limited',
    getUrl(reg) {
      return reg ? regulator[reg].info.url : ''
    },
    getProtraderURL(reg) {
      return reg ? regulator[reg].info.protraderURL : ''
    },
    getMap(reg) {
      return regulator[reg].info.map
    },
    getAddress(reg) {
      return regulator[reg].info.address
    },
    getPhone(reg) {
      console.log(regulator[reg].info)
      return reg ? regulator[reg].info.phone : ''
    },
    getEmail(reg) {
      return reg ? regulator[reg].info.email : ''
    },
    getDepositEmail(reg) {
      return reg ? regulator[reg].info.depositEmail : ''
    },
  },
  restrictOpenAdditionAccountCountries(reg) {
    return reg ? regulator[reg].restrictOpenAdditionAccountCountries : {}
  },
  restrictDepositFundsByCountries(reg) {
    return reg ? regulator[reg].restrictDepositFundsByCountries : {}
  },
  getPayments(reg, vm) {
    return reg ? regulator[reg].getPayments(vm) : []
  },
  getMenus(reg) {
    return reg ? regulator[reg].menus : []
  },
  accountTypeMaps(reg) {
    return reg ? regulator[reg].accountTypeMaps : {}
  },
  getTradingPlatform(reg) {
    return reg ? regulator[reg].tradingPlatform : {}
  },
  geti18n(reg, key) {
    return reg ? key.split('.').reduce((object, property) => object[property], regulator[reg].i18n) : ''
  },
  getCheckMethodMinAmount(reg) {
    return reg ? regulator[reg].withdrawSetup.checkMethodMinAmount : false
  },
  openLiveAccount: {
    choosePlatform(reg) {
      return regulator[reg].openLiveAccount.choosePlatform
    },
    // open mt4 live account
    chooseLiveType(reg, clientType = '') {
      return clientType
        ? regulator[reg].openLiveAccount.chooseLiveTypeByClientType[clientType]
        : regulator[reg].openLiveAccount.chooseLiveType
    },
    // register mt4 account
    mt4RegisterChooseType(reg) {
      return regulator[reg].openLiveAccount.mt4RegisterChooseType
    },
    // open mt4 demo account
    mt4DemoChooseType(reg) {
      return regulator[reg].openLiveAccount.mt4DemoChooseType
    },
    // open mt5 live account
    mt5ChooseType(reg, clientType = '') {
      return clientType
        ? regulator[reg].openLiveAccount.mt5ChooseTypeByClientType[clientType]
        : regulator[reg].openLiveAccount.mt5ChooseType
    },
    // register mt5 account
    mt5RegisterChooseType(reg) {
      return regulator[reg].openLiveAccount.mt5RegisterChooseType
    },
    // open mt5 demo account
    mt5DemoChooseType(reg) {
      return regulator[reg].openLiveAccount.mt5DemoChooseType
    },
    chooseCurrency(reg) {
      return regulator[reg].openLiveAccount.chooseCurrency
    },
    chooseLeverage(reg, clientType = '') {
      return clientType
        ? regulator[reg].openLiveAccount.chooseLeverageByClientType[clientType]
        : regulator[reg].openLiveAccount.chooseLeverage
    },
    chooseBalance(reg) {
      return regulator[reg].openLiveAccount.chooseBalance
    },
    getAgreeCondition(reg) {
      return regulator[reg].openLiveAccount.getAgreeCondition
    },
    getSwapFreeCountries(reg) {
      return regulator[reg].swapFreeCountries
    },
    getDirectStpCountries(reg) {
      return regulator[reg].directStpCountries
    },
    getSwapFreeStpCountries(reg) {
      return regulator[reg].swapFreeStpCountries
    },
    getSwapFreeMenaCountries(reg) {
      return regulator[reg].swapFreeMenaCountries
    },
    getFilterLiveType(reg) {
      return regulator[reg].filterLiveType
    },
    getFilterMenaLiveType(reg) {
      return regulator[reg].filterMenaLiveType
    },
    getFilterCurrencyType(reg) {
      return regulator[reg].filterSwapCurrencyList
    },
  },
  getMetaTraderLinks(reg, url) {
    return regulator[reg].metaTraderLinks(url)
  },
  getCancelWithdraw(reg) {
    return reg ? regulator[reg].withdrawSetup.cancelWithdraw : false
  },
  getDepositBankTransferSelection(reg) {
    return regulator[reg].depositBankTransferSelection
  },
  bank_infos(currency, reg) {
    let bank_infos = []
    bank_infos.push({
      title: 'common.field.bankName',
      info: regulator[reg].bankInfoDetails.bank_name,
    })
    bank_infos.push({
      title: 'common.field.branchAddress',
      info: regulator[reg].bankInfoDetails.branch_address,
    })
    bank_infos.push({
      title: 'common.field.bankBranch',
      info: regulator[reg].bankInfoDetails.branch_name(currency),
    })
    const account_name = regulator[reg].bankInfoDetails.account_name(currency)
    bank_infos.push({
      title: 'common.field.accName',
      info: account_name[currency] ? account_name[currency] : account_name,
    })
    const account_number = regulator[reg].bankInfoDetails.account_number(currency)
    bank_infos.push({
      title: 'common.field.bankBeneAccNum',
      info: account_number[currency] ? account_number[currency] : account_number,
    })
    bank_infos.push({
      title: 'common.field.bankBeneAddress',
      info: regulator[reg].bankInfoDetails.beneficiary_address(currency),
    })
    bank_infos.push({
      title: 'common.field.bsbNum',
      info: regulator[reg].bankInfoDetails.bsb_number(currency),
    })
    bank_infos.push({
      title: 'common.field.swift',
      info: regulator[reg].bankInfoDetails.swift_code,
    })
    return bank_infos
  },
  country_transferOptions: [
    {
      code: 2,
      nameKey: 'common.country.international',
    },
  ],
  paymentMethods: [{ label: 'common.withdrawChannel.unionpay', component: 'UnionPayRegister' }],
  depositDefaultAmountMin: 0,
  depositDefaultAmountMax: 99999999.99,
  depositOneAmountMin: 1,
  deposit50AmountMin: 50,
  withdrawMethod: {
    allowedCurrencies: {
      hkBankTransfer: ['HKD', 'USD'],
    },
  },
  withdrawMethods(reg) {
    return regulator[reg].withdrawMethods
  },
  banner(reg, url) {
    return regulator[reg].banner(url)
  },
  isPageVisibleByIdPoa(reg, routeName) {
    return regulator[reg].isPageVisibleByIdPoa[routeName]
  },
  isPageVisibleByTradableAccounts(reg, routeName) {
    return regulator[reg].isPageVisibleByTradableAccounts[routeName]
  },
  pageLoader(regulator = 'vfsc', componentName) {
    return import(`@/views/regulator/${regulator}/${componentName}`)
  },
  componentLoader(regulator = 'vfsc', componentName, checkFileExist = false) {
    function fileInfo(exist = false, component) {
      this.exist = exist
      this.component = component
    }

    let file = new fileInfo()

    if (checkFileExist) {
      let components

      if (regulator == 'vfsc') {
        components = require.context(`@/components/regulator/vfsc`, true, /^\.\/.*\.vue$/)
      }

      if (components) {
        components.keys().map(requireContext => {
          const fileName = requireContext.replace('./', '').replace('.vue', '')
          if (fileName === componentName) {
            file.exist = true
            file.component = import(`@/components/regulator/${regulator}/${componentName}`)
          }
        })
      }
    } else {
      file.exist = true
      file.component = import(`@/components/regulator/${regulator}/${componentName}`)
    }

    return file
  },
  getWithdrawMethods(reg) {
    return regulator[reg].getWithdrawMethods
  },
}
