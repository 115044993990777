export default {
  common: {
    liveChat: { desc: '<li><p>Benötigen Sie Hilfe?</p><span>Live-Chat</span></li>' },
    country: { international: 'International', australia: 'Australien', uk: 'UK' },
    verification: { toSlide: 'Schieben, um das Rätsel zu vervollständigen' },
    hover: {
      BankName: 'Der Name der Bank oder des Finanzinstituts, das die Gelder erhält.',
      BankBeneficiaryName:
        'Der Name der Person oder des Unternehmens, die das Bankkonto besitzt, auf das das Geld überwiesen wird.',
      AccountHoldersAddress:
        'Die physische Adresse der Person oder des Unternehmens, die/das Eigentümer des Bankkontos ist.',
      BankAddress:
        'Die physische Adresse der Bank oder des Finanzinstituts, das die Gelder erhält.',
      BankAccountNumber:
        'Die Bankkontonummer oder IBAN (International Bank Account Number) ist die Nummer, mit der Sie Ihr Bankkonto identifizieren.',
      SwiftCode:
        'Der SWIFT-Code, auch Bank Identifier Code (BIC) genannt, ist ein internationaler Code, der zur Identifizierung des Landes, der Bank und der Filiale und wird bei internationalen Geldtransfers benötigt.',
    },
    field: {
      nameOnCard: 'Name auf Karte',
      first4Digits: 'Erste 4 Ziffern auf der Karte',
      last3Digits: 'Letzte 3 Ziffern auf der Karte',
      first6Digits: 'Erste 6 Ziffern auf der Karte',
      last4Digits: 'Letzte 4 Ziffern auf der Karte',
      bankName: 'Bankname',
      bankAddress: 'Bankadresse',
      branchAddress: 'Filialadresse',
      bankBranch: 'Bankfiliale',
      bankCity: 'Bank Stadt',
      bankProvince: 'Bank Provinz',
      bankCode: 'Bankleitzahl',
      bankBranchCode: 'Filialcode',
      bankBeneficiaryName: 'Bank Begünstigter Name',
      bankBeneAccName: 'Bank Begünstigter Kontoname',
      bankBeneAccNum: 'Bank Begünstigter Kontonummer',
      bankBeneAddress: 'Adresse des Begünstigten',
      bankAccNum: 'Kontonummer der Bank',
      bankAccName: 'Name des Bankkontos',
      bankHolderAddress: 'Adresse des Kontoinhabers',
      address: 'Adresse',
      accName: 'Name des Kontos',
      accNum: 'Kontonummer',
      accNumTo: 'Kontonummer',
      bsbNum: 'BSB-Nummer',
      swift: 'SWIFT-Code',
      region: 'Region',
      sort: 'Bankleitzahl',
      ifscCode: 'IFSC-Code',
      swiftNonAUD: 'SWIFT-Code (Nicht-AUD-Konten)',
      amt: 'Betrag',
      amtUSD: 'Betrag (USD)',
      imptNotes: 'Wichtige Hinweise',
      yes: 'Ja',
      no: 'Nein',
      payMethod: 'Zahlungsmethode:',
      expDate: 'Ablaufdatum',
      bsb: 'BSB',
      abaSortCode: 'ABA/Sort-Code:',
      country_region: 'Land/Region',
      currLeverage: 'Aktuelle Hebelwirkung',
      accEquity: 'Konto-Eigenkapital',
      newLeverage: 'Gewünschte Hebelwirkung',
      currPw: 'Aktuelles Passwort',
      newPw: 'Neues Passwort',
      confirmPw: 'Bestätigen Sie das neue Passwort',
      loginEmail: 'E-Mail Anmeldung',
      email: 'E-Mail',
      pw: 'Passwort',
      accType: 'Konto-Typ',
      name: 'Name',
      emailAdd: 'E-Mail-Adresse',
      phone: 'Telefonnummer',
      countryCode: 'Ländercode',
      dob: 'Geburtsdatum',
      nat: 'Nationalität',
      country: 'Land des Wohnsitzes',
      street: 'Straßennummer/Straßenname',
      suburbOrCity: 'Vorort/Stadt',
      provinceOrState: 'Bundesland/Bundesstaat',
      postcode: 'Postleitzahl',
      empStat: 'Beschäftigungsstatus',
      occupation: 'Beruf',
      annIncome: 'Jährliches Einkommen',
      save: 'Ersparnis/Investition',
      source: 'Quelle des Einkommens',
      industry: 'Branche',
      statusDef: 'STATUS-DEFINITIONEN',
      month: 'Monat',
      year: 'Jahr',
      select: 'Wählen',
      completed: 'Abgeschlossen',
      incomplete: 'Unvollständig',
      submitted: 'Abgesendet',
      successful: 'Erfolgreich',
      processing: 'Bearbeitung',
      cancelled: 'Abgebrochen',
      failed: 'Gescheitert',
      rejected: 'Abgelehnt',
      upload: 'Hochladen',
      ccNum: 'Kreditkartennummer',
      bankCardNumber: 'Bankkartennummer',
      cardHolderName: 'Name des Karteninhabers',
      reservedMobileNumber: 'Mobiltelefonnummer für die Bank',
      nationalId: 'Nationale ID-Karte',
      cardPhoto: 'Kartenfoto (Vorderseite)',
      notes: 'Anmerkungen',
      bankAccOrIBAN: 'Bankkontonummer/IBAN',
      selectWithdrawCard: 'Wählen Sie Ihre Auszahlungskarte',
      updateCardPhoto: 'Bitte laden Sie Ihr Kartenfoto (Vorderseite) hoch',
      transitNumber: 'Transitnummer',
      institutionNumber: 'Institutsnummer',
      wallet: 'Wallet',
      resetBalTo: 'Guthaben zurücksetzen auf',
      confirmation: 'Sind Sie sicher?',
      removeNote:
        'Wollen Sie diesen Datensatz wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.',
      depositCurrency: 'Einzahlungswährung',
      bankAccountCurrency: 'Währung des Bankkontos',
      ipChangeWarning:
        'Erhalten Sie eine E-Mail, wenn sich jemand von einem anderen Standort aus in Ihr Konto einloggt',
      documentType: 'Dokument type',
      documentDetails: 'Dokumentdetaljer',
      pixKeyType: 'PIX-nøgletype',
      pixKey: 'PIX nøgle',
      enterVerificationCode: 'Verifizierungscode eingeben',
      accDigit: 'Kontonummer',
      docID: 'ID des Dokuments',
      paymentAccName: '{name} Name des Kontos',
      paymentAccNum: '{name} Kontonummer',
      walletNumber: '{name} Wallet-Nummer',
      beneficiaryIBANNumber: 'IBAN-Nummer des begünstigten Kontos',
      beneficiaryBankSwift: 'SWIFT-Code der begünstigten Bank',
      beneficiaryBankName: 'Name der begünstigten Bank',
      beneficiaryBankAddress: 'Adresse der begünstigten Bank',
      beneficiaryBankSortCode: 'Sortiercode der Begünstigten Bank',
      beneAccNum: 'Kontonummer des Begünstigten',
      reversedfailed: 'Umgekehrtes Versagen',
      cardNumber: 'Kartennummer',
      beneficiaryName: 'Begünstigter Name',
    },
    column: {
      date: 'DATUM',
      type: 'TYP',
      server: 'SERVER',
      status: 'STATUS',
      comment: 'KOMMENTAR',
      accNum: 'KONTONUMMER',
      currency: 'WÄHRUNG',
      equity: 'EIGENKAPITAL',
      credits: 'CREDITS',
      balance: 'GLEICHGEWICHT',
      leverage: 'HEBELWIRKUNG',
      resetMT4PW: 'MT4 PASSWORT ZURÜCKSETZEN',
      resetMT5PW: 'MT5 PASSWORT ZURÜCKSETZEN',
      tradingAcc: 'TRADING-KONTO',
      method: 'METHODE',
      amt: 'BETRAG',
      procNote: 'VERFAHRENSHINWEISE',
      number: 'KARTENNUMMER',
      resetBal: 'Guthaben zurücksetzen',
      remove: 'Löschen',
    },
    key: { from: 'VON', to: 'BIS' },
    fund: {
      must: 'MUSS',
      infoHover:
        'Der Name auf Ihrer Karte {must} mit dem Namen auf Ihrem Trading-Konto übereinstimmen',
      ccUploadReminder:
        'Bitte laden Sie ein Foto der Vorderseite Ihrer Karte zur Verifizierung hoch. (Bitte decken Sie die mittleren 6 Ziffern ab)',
    },
    button: {
      bkToHm: 'Zurück zur Startseite',
      smsLogin: 'MOBILE ANMELDUNG',
      userLogin: 'E-MAIL ANMELDUNG',
      goToIB: 'Zum IB-Portal wechseln',
      logout: 'ABMELDEN',
      submit: 'ABSENDEN',
      selectFile: 'Datei auswählen',
      depositFunds: 'GELDER EINZAHLEN',
      withdrawFunds: 'GELDER AUSZAHLEN',
      ibPortal: 'IB-PORTAL',
      download: 'DOWNLOADS',
      changeLeverage: 'HEBELWIRKUNG ÄNDERN',
      cancel: 'ABBRUCH',
      changePw: 'PASSWORT ÄNDERN',
      forgetPw: 'PASSWORT VERGESSEN',
      confirm: 'BESTÄTIGEN',
      login: 'ANMELDEN',
      forgotPw: 'Passwort vergessen?',
      completeQuestionnaire: 'FRAGEBOGEN AUSFÜLLEN',
      identityProof: 'ANTRAG AUSFÜLLEN',
      upload: 'HOCHLADEN',
      continue: 'Weiter',
      enable: 'AKTIVIEREN SIE',
      disable: 'DEAKTIVIEREN',
      export: 'EXPORTIEREN',
      ok: 'OK',
      hide: 'EINBLENDEN',
      unhide: 'EINBLENDEN',
      showAll: 'ALLES ANZEIGEN',
      beginner: 'Anfänger',
      intermediate: 'Mittlere Kenntnisse',
      advanced: 'Fortgeschrittene',
      sendCode: 'Code senden',
      resendCode: 'Code erneut senden',
    },
    formValidation: {
      common: 'Dieses Feld ist erforderlich.',
      accReq: 'Kontonummer ist erforderlich',
      amtReq: 'Betrag ist erforderlich',
      ccReq: 'Kreditkartennummer ist erforderlich',
      cardReq: 'Kartennummer ist erforderlich',
      cardOnNameReq: 'Name auf der Karte ist erforderlich',
      digits16: 'Bitte 16 Ziffern eingeben',
      digits3or4: 'Bitte geben Sie 3 oder 4 Ziffern ein',
      digitsFirst6: 'Bitte geben Sie die ersten 6 Ziffern ein',
      digitsLast4: 'Bitte geben Sie die letzten 4 Ziffern ein',
      digitsLast3: 'Bitte geben Sie die letzten 3 Ziffern ein',
      mid6Digits: 'Bitte mittlere {number} Ziffern eingeben',
      expDateReq: 'Verfallsdatum ist erforderlich',
      cvvReq: 'Sicherheitscode CVV ist erforderlich',
      file: 'Bitte laden Sie eine Datei hoch',
      filePhoto: 'Kreditkartenfoto ist erforderlich',
      fileReceipt: 'Bitte laden Sie einen Beleg hoch',
      filePhotoReq: 'Kartenfoto ist erforderlich',
      amtLarger:
        'Betrag kann nicht null sein und muss größer oder gleich ${minLimit} sein {currency}',
      withdrawAmtLarger:
        'Der Mindestauszahlungsbetrag beträgt ${minLimit} {currency} oder den entsprechenden Gegenwert.',
      amt0: 'Betrag kann nicht 0 sein',
      amtLess: 'Der Betrag Ihrer Einzahlung kann nicht höher sein als ${maxLimit} {currency}',
      note256: 'Das Limit für die Textlänge beträgt 256 Zeichen',
      noteMoreThan: 'Das Limit für die Textlänge beträgt {limit} Zeichen',
      noteLessThan: 'Das Limit für die Textlänge beträgt {limit} Zeichen',
      emailFormat: 'Bitte korrekte E-Mail-Adresse eingeben',
      payMethodReq: 'Zahlungsmethode ist erforderlich',
      expReq: 'Verfallsdatum ist erforderlich',
      bankNameReq: 'Bankname ist erforderlich',
      bankCityReq: 'Bank Stadt ist erforderlich',
      bankProvinceReq: 'Bank Provinz ist erforderlich',
      bankAddressReq: 'Adresse der Bank ist erforderlich',
      bankBranchReq: 'Bankfiliale ist erforderlich',
      bankBranchCodeReq: 'Filialcode ist erforderlich',
      bankAccNumReq: 'Bankkontonummer ist erforderlich',
      ifscCodeReq: 'IFSC-Code ist erforderlich',
      bankBeneficiaryNameReq: 'Bank Begünstigter Name ist erforderlich',
      bankHolderAddressReq: 'Adresse des Kontoinhabers ist erforderlich',
      swiftReq: 'Swift-Code ist erforderlich',
      accHolderAddress: 'Adresse des Kontoinhabers ist erforderlich',
      bsbReq: 'BSB ist erforderlich',
      accNumIBANReq: 'Bankkontonummer/IBAN ist erforderlich',
      accNameReq: 'Bankkontoname ist erforderlich',
      withdrawMethodReq: 'Bitte wählen Sie eine Auszahlungsmethode',
      tnc: 'Bitte stimmen Sie den Allgemeinen Geschäftsbedingungen zu.',
      currPwReq: 'Aktuelles Passwort ist erforderlich',
      currPwWrong: 'Das aktuelle Passwort ist falsch',
      newPwReq: 'Neues Passwort ist erforderlich',
      newPwFormat:
        'Ihr Passwort muss zwischen 8 und 16 Zeichen lang und eine Kombination aus Buchstaben (a-z und A-Z), Zahlen (0-9) und Sonderzeichen wie !@#$%^&*.() sein',
      newPwAccFormat:
        'Ihr Passwort muss zwischen 8 und 16 Zeichen lang und eine Kombination aus Buchstaben (a-z und A-Z), Zahlen (0-9) und Sonderzeichen wie !@#$%^&* sein',
      IdenficalPw: 'Das neue Passwort kann nicht mit dem alten identisch sein',
      confirmPwReq: 'Bitte geben Sie das neue Passwort erneut ein',
      confirmNotMatch: 'Zwei Eingaben stimmen nicht überein',
      pwReq: 'Passwort ist erforderlich',
      accCurrReq: 'Sie müssen eine Kontowährung auswählen',
      tradAccReq: 'Bitte wählen Sie ein Trading-Konto',
      answerReq: 'Bitte wählen Sie',
      bankCardReq: 'Bitte wählen Sie eine Karte aus',
      phoneNumberReq: 'Mobiltelefonnummer für Bank ist erforderlich',
      nationaIdReq: 'Nationaler Ausweis ist erforderlich',
      numberReq: 'Bitte Nummer eingeben',
      alphanumericReq: 'Bitte alphanumerisch eingeben',
      idTypeReq: 'Bitte wählen Sie einen Ausweis-Typ aus',
      dobReq: 'Bitte wählen Sie Ihr Geburtsdatum',
      pobReq: 'Bitte wählen Sie Ihren Geburtsort',
      nationalityReq: 'Bitte wählen Sie Ihre Nationalität',
      titleReq: 'Bitte wählen Sie Ihren Titel',
      firstNameReq: 'Bitte geben Sie Ihren Vornamen ein',
      lastNameReq: 'Bitte geben Sie Ihren Nachnamen ein',
      emailReq: 'Bitte geben Sie Ihre E-Mail Adresse ein',
      addressReq: 'Bitte geben Sie Ihre Adresse ein',
      suburbReq: 'Bitte geben Sie Ihre Stadt oder Ihren Vorort ein',
      stateReq: 'Bundesland oder Provinz ist erforderlich',
      postcodeReq: 'Bitte geben Sie Ihre Postleitzahl ein',
      countryReq: 'Bitte wählen Sie ihr Wohnsitzland',
      NIReq: 'Bitte geben Sie die Nummer ihrer staatlichen Sozialversicherung ein',
      taxResidencyReq: 'Bitte wählen Sie Ihr Land/Ihre Länder des steuerlichen Wohnsitzes',
      yearsAtAddressReq: 'Bitte geben Sie die Anzahl der Jahre an der Adresse ein',
      bankCodeReq: 'Bitte geben Sie die kartenausgebende Bank ein',
      dynamicReq: '{dynamic} ist erforderlich',
      mobReq: 'Mobil ist erforderlich',
      confirmed: 'Bitte bestätigen',
      resetBalAmt: 'Bitte stellen Sie einen Betrag zwischen 0 und 1.000.000 ein',
      verCodeReq: 'Verifizierungscode ist erforderlich',
      noEligibleAccounts: 'Keine berechtigten Konten',
      AlphanumericOnlyNotSpace:
        'Nur Buchstaben und Zahlen erlaubt. Leerzeichen sind nicht erlaubt.',
      AlphanumericOnly: 'Nur Buchstaben und Zahlen.',
      NumbersSymbol: 'Nur Zahlen und Symbole.',
      NumbersOnlyNotSpace: 'Nur Zahlen erlaubt. Leerzeichen sind nicht erlaubt.',
      NumbersOnly: 'Nur Zahlen.',
      LettersSymbol: 'Nur Buchstaben und Symbole.',
      LettersSymbolNotSpace: 'Nur Buchstaben und Symbole erlaubt. Leerzeichen sind nicht erlaubt.',
      LettersOnlyNotSpace: 'Nur Buchstaben erlaubt. Leerzeichen sind nicht erlaubt.',
      NotSpace: 'Leerzeichen sind nicht erlaubt.',
      beneficiaryNameReq: 'Begünstigtenname ist erforderlich',
    },
    withdrawChannel: {
      banktransferbpaypolipay: 'Banküberweisung/BPay/PoliPay',
      banktransfer: 'Internationale Banküberweisung',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'UnionPay Überweisung',
      fasapay: 'FasaPay',
      creditcard: 'Kreditkarte',
      archiveThisCard:
        'Bitte archivieren Sie diese Karte, wenn sie nicht mehr gültig oder in Gebrauch ist',
      update: 'Aktualisieren',
      archive: 'Archivieren',
      updateExpiryMsg:
        'Bitte aktualisieren Sie das Verfallsdatum Ihrer Karte, um Ihre Karte für Auszahlungen zu verwenden ',
      available: 'Verfügbar',
      pending: 'Ausstehend',
      unavailable: 'Nicht verfügbar',
      fullyRefunded: 'Vollständig erstattet',
      requiredDocument: 'Für die Archivierung der Karte erforderliche Dokumente',
      officialLetter:
        'Offizielles Schreiben/E-Mail der Bank, dass die Karte nicht länger verfügbar ist',
      bankStatement: 'Kontoauszug mit den mit dieser Karte getätigten Transaktionen',
      expiredCard: 'Abgelaufene/nicht verfügbare Karten',
      confirmArchive: 'Sind Sie sicher, dass Sie diese Karte archivieren möchten?',
      confirmUpdate:
        'Sind Sie sicher, dass Sie das Ablaufdatum dieser Karte aktualisieren möchten? Hinweis: Moneta ist nicht dafür verantwortlich, wenn die Auszahlung aufgrund eines falsch angegebenen Ablaufdatums scheitert.',
      bpay: 'Bpay',
      creditdebitcard: 'Kredit-/Debitkarte',
      bankwiretransferinternational: 'Banküberweisung (International)',
      banktransferaustralia: 'Banküberweisung (Australien)',
      banktransferinternational: 'Internationale Banküberweisung',
      banktransferchina: 'Banküberweisung (International)',
      banktransferuk: 'Banküberweisung (UK)',
      mobilepay: 'Mobiles Bezahlen',
      brokertobrokertransfer: 'Broker-zu-Broker-Überweisung',
      skrillmoneybookers: 'Skrill/Moneybookers',
      internetbankingthailand: 'Internet-Banking (Thailand)',
      internetbankingnigeria: 'Internet-Banking (Nigeria)',
      internetbankingvietnam: 'Internet-Banking (Vietnam)',
      internetbankingmalaysia: 'Internet-Banking (Malaysia)',
      internetbankingindonesia: 'Internet-Banking (Indonesien)',
      internetbankingkorea: 'Internet-Banking (Korea)',
      internetbankingsouthkorea: 'Internet-Banking (Südkorea)',
      internetbankingindia: 'Internet-Banking (Indien)',
      internetbankingphilippine: 'Internet-Banking (Philippinen)',
      internetbankingsouthafrica: 'Internet-Banking (Südafrika)',
      internetbankinguk: 'Internet-Banking (UK)',
      internetbankinglaos: 'Internet-Banking (Laos)',
      internetbankinghk: 'Internet-Banking (Hongkong)',
      internetbankinguganda: 'Internet-Banking (Uganda)',
      internetbankingrwanda: 'Internet-Banking (Ruanda)',
      internetbankingzambia: 'Internet-Banking (Sambia)',
      internetbankingcongo: 'Internet-Banking (Kongo)',
      internetbankingcameroon: 'Internet-Banking (Kamerun)',
      internetbankingburundi: 'Internet-Banking (Burundi)',
      internetbankingkenya: 'Internet-Banking (Kenia)',
      internetbankingghana: 'Internet-Banking (Ghana)',
      internetbankingtanzania: 'Internet-Banking (Tansania)',
      internetbankingbrazil: 'Internet-Banking (Brasilien)',
      internetbankingmexico: 'Internet-Banking (Mexiko)',
      internetbankingcolombia: 'Internet-Banking (Kolumbien)',
      internetbankingchile: 'Internet-Banking (Chile)',
      internetbankingperu: 'Internet-Banking (Peru)',
      internetbankingusd: 'Internet-Banking (USD)',
      skrillneteller: 'Skrill/Neteller',
      vietnambanktransfer: 'Vietnam Banküberweisung',
      nigeriabanktransfer: 'Nigeria Banküberweisung',
      polipayment: 'Poli-Zahlung',
      bankwiretransferaustralia: 'Banküberweisung (Australien)',
      thailandinstantbankwiretransfer: 'Thailändische Sofortüberweisung ',
      nigeriainstantbankwiretransfer: 'Nigerianische Sofortüberweisung',
      vietnaminstantbankwiretransfer: 'Vietnamesische Sofortüberweisung',
      laosinstantbankwiretransfer: 'Laos Sofortige Banküberweisung',
      malaysiainstantbankwiretransfer: 'Malaysia Sofortige Banküberweisung',
      indonesiainstantbankwiretransfer: 'Indonesien Sofortüberweisung',
      indiainstantbanktransfer: 'Indien Sofortige Banküberweisung',
      southafricainstantbankwiretransfer: 'Südafrikanische Instant BankÜberweisung',
      philippinesinstantbankwiretransfer: 'Philippinische Sofortige Banküberweisung',
      indialocalbankwiretransfer: 'Indien Sofortige Banküberweisung',
      hongkongbanktransfer: 'Lokale Banküberweisung (Hongkong)',
      ugandabanktransfer: 'Uganda lokale Banküberweisung',
      rwandabanktransfer: 'Ruanda lokale Banküberweisung',
      zambiabanktransfer: 'Sambia lokale Banküberweisung',
      congobanktransfer: 'Kongo lokale Banküberweisung',
      cameroonbanktransfer: 'Kamerun lokale Banküberweisung',
      burundibanktransfer: 'Burundi lokale Banküberweisung',
      kenyabanktransfer: 'Kenia lokale Banküberweisung',
      ghanabanktransfer: 'Ghana lokale Banküberweisung',
      tanzaniabanktransfer: 'Tansania lokale Banküberweisung',
      brazilbanktransfer: 'Brasilianische Banküberweisung',
      mexicobanktransfer: 'Lokale Banküberweisung in Mexiko',
      pagsmileBrazil: 'Brasilianische Sofortige Banküberweisung',
      pagsmileMexico: 'Mexico lokale Banküberweisung',
      pagsmileColombia: 'Colombia lokale Banküberweisung',
      pagsmileChile: 'Chile lokale Banküberweisung',
      pagsmilePeru: 'Peru lokale Banküberweisung',
      banktransferbpaypolipayaustralia: 'Banküberweisung/BPay/PoliPay (Australien)',
      banktransferbpaypolipayinternational: 'Banküberweisung/BPay/PoliPay (International)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Kanadische lokale Banküberweisung',
      cryptobitcoin: 'Krypto-Bitcoin',
      cryptotetheromni: 'Krypto-USDT (OMNI)',
      cryptotethererc20: 'Krypto-USDT (ERC20)',
      cryptotethertrc20: 'Krypto-USDT (TRC20)',
      cryptocurrencybitcoin: 'Kryptowährung-Bitcoin',
      cryptocurrencyusdt: 'Kryptowährung-USDT',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      trustly: 'Trustly',
      astropay: 'Astropay',
      webMoney: 'WebMoney',
      vload: 'Vload',
      dasshpewallet: 'Indien-Wallets',
      cryptocurrencyethcps: 'Krypto-ETH',
      cryptocurrencyeth: 'Krypto-ETH',
      cryptocurrencyusdc: 'Krypto-USDC(ERC-20)',
      cryptocurrencyusdcerc20: 'Krypto-USDC(ERC-20)',
      cryptocurrencyusdccps: 'Krypto-USDC(ERC-20)',
      chileBankTransfer: 'Chile Banküberweisung',
      colombiaBankTransfer: 'Kolumbien Banküberweisung',
      perubanktransfer: 'Peru Banküberweisung',
      alipay: 'Alipay',
    },
  },
  home: {
    pendingTooltip:
      'Sobald Ihr Live-Konto genehmigt ist, erhalten Sie eine E-Mail mit Ihren MT{number}-Login-Daten, damit Sie mit dem Traden beginnen können! ',
    leverage: {
      tncTitle: '<span>Allgemeine Geschäftsbedingungen</span>',
      tnc: '<p>Mit dem Absenden dieses Antrags erkenne ich an, dass mir bewusst ist, dass eine hohe Hebelwirkung ein großes Risiko für mein Kapital beinhaltet und die Möglichkeit besteht, dass ich einen Verlust erleiden könnte, der größer als das von mir eingezahlte Kapital ist und nicht auf dieses begrenzt ist. <br><br>Ich erkläre mich damit einverstanden, dass der Produktanbieter berechtigt ist, meine Trading-Aktivitäten zu überprüfen und die Stufen der Hebelwirkung auf meinem Trading-Konto nach eigenem Ermessen und ohne vorherige Warnung oder Benachrichtigung anzupassen.<br><br>Ich verstehe, dass ich durch die Wahl einer hohen Hebelwirkung mit einer größeren Margin traden kann, aber potenziell erhebliche Verluste erleiden kann.',
      tncConfirm: 'Ich habe die Allgemeinen Geschäftsbedingungen gelesen und stimme ihnen zu.',
      changeLeverageConfirm:
        'Ihre Anfrage wurde abgeschickt und wird in Kürze von unserem Kundendienst-Team bearbeitet.',
      changeLeverageAutoComplete: 'Ihre Anfrage zur Änderung der Hebelwirkung ist abgeschlossen',
      changeLeverageReject: 'Die Anfrage zur Änderung der Hebelwirkung wurde abgelehnt',
      changeLeverageFail:
        'Die Anfrage zur Änderung der Hebelwirkung ist fehlgeschlagen, bitte versuchen Sie es später erneut',
      changeLeverageDefault:
        'Es ist ein Fehler aufgetreten, bitte kontaktieren Sie {supportEmail}, um Ihren Antrag abzuschließen',
      alreadyApplied:
        'Sie haben bereits eine Änderung der Hebelwirkung für Ihr Konto beantragt, die derzeit vom Kundendienst-Team der {platform} geprüft wird. Bitte warten Sie, bis dieser Antrag abgeschlossen ist, bevor Sie einen weiteren Antrag einreichen.',
      reduceLeverageWarn:
        'Bitte beachten Sie: Die Verringerung Ihrer Hebelwirkung kann zur erzwungenen Schließung offener Positionen führen, wenn Ihr Konto nicht über eine ausreichende Margin verfügt.',
      reduceLeverageConfirm: 'Möchten Sie fortfahren?',
      autoRestore:
        '<p>Da Ihr Guthaben >= 20.000 ist, beträgt Ihre verfügbare Hebelwirkung 500:1</p><br /><p>Wenn Ihr Guthaben wieder weniger als 20.000 beträgt, können Sie wieder auf 1.000:1 umstellen</p>',
      oneThousandRatio:
        '<p>Bitte beachten Sie die unten stehenden Bedingungen, wenn Sie zu einer Hebelwirkung von 1.000:1 wechseln möchten:<br><br>1. Sie können keine gutschriftbezogenen Promotionen beantragen<br><br>2. Ihr Kontoguthaben darf zum Zeitpunkt der Antragstellung USD 15.000 oder den entsprechenden Gegenwert in anderer Währung nicht überschreiten<br><br>3. Die Hebelwirkung Ihres Kontos wird auf 500:1 umgestellt, wenn Ihr Kontoguthaben USD 20.000 oder den entsprechenden Gegenwert in anderer Währung übersteigt<br><br>4. Die Obergrenzen für die Hebelwirkung können je nach den geltenden Regulierungen variieren<br><br>5. Die Änderung der Hebelwirkung gilt nicht für Produkte, die mit einer festen Hebelwirkung ausgestattet sind <br><br>6. Gemäß Kundenvereinbarung werden Maßnahmen, einschließlich, aber nicht beschränkt auf, Kündigung oder Abzug, durchgeführt, wenn Sie die Klauseln in Bezug auf Standard-Ereignisse und/oder verdächtige Handelsaktivitäten verletzen<br><br></p>',
    },
    pw: {
      error: 'Sie konnten Ihr Passwort nicht zurücksetzen. Bitte versuchen Sie es später erneut',
      confirm:
        'Ihr Passwort wurde aktualisiert.<br>Sie können nun Ihr neues Passwort verwenden, um auf Ihr MT4/MT5 Trading-Konto zuzugreifen.',
      email:
        '<p class="first_info">Wir haben Ihnen soeben eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts gesendet.</p><p>Bitte überprüfen Sie auch den Spam-Ordner Ihrer E-Mail.</p>',
    },
    activate: {
      question: 'Möchten Sie Ihr Live-Konto erneut aktivieren?',
      confirm: 'Wir haben Ihre Aktivierungsanfrage erhalten.<br>Ihr Konto wird in Kürze aktiviert.',
    },
    accStatus: {
      active: 'Aktiv',
      rejected: 'Abgelehnt',
      inactive: 'Inaktiv',
      processing: 'Bearbeitung',
      activate: 'Aktivieren',
      expired: 'Abgelaufen',
    },
    appNote: { desc02: 'Klicken Sie hier, um mehr zu erfahren.' },
    app: {
      title: 'Wie es funktioniert',
      desc01: 'Laden Sie die {platform} herunter',
      desc02: 'Loggen Sie sich mit Ihrer bestehenden E-Mail-Adresse ein',
      desc03: 'Verifizieren Sie Ihre Telefonnummer',
      desc04: 'Sie sind bereit. Genießen Sie den leistungsstarkes Trading',
    },
    reset: {
      success: 'Das Zurücksetzen war erfolgreich.',
      creditTip:
        'Ihr Kontosaldo ist kleiner als 0. Wenn Sie Ihr Kontosaldo auf 0 zurücksetzen, wird auch Ihr Guthaben auf 0 zurückgesetzt. Möchten Sie wirklich fortfahren?',
      resetBtn: 'Zurücksetzen',
      tableHeader: 'Negativen Saldo',
    },
  },
  oneThousandRatioError: {
    error1:
      'Dieses Konto hat einen Bonus und kann nicht auf eine Hebelwirkung von 1.000:1 eingestellt werden',
    error4:
      'Das Guthaben auf dem Konto ist größer als $ 15.000 (oder dem Gegenwert in einer anderen Währung) und kann nicht auf eine Hebelwirkung von 1.000:1 eingestellt werden',
    error5: 'The ID audit is not pass, cannot be adjusted to 1000:1 leverage',
    error6:
      'Das Konto hat an der Bonusaktivität teilgenommen und kann nicht auf eine Hebelwirkung von 1.000:1 eingestellt werden',
  },
  paymentDetails: {
    subHeader: 'EINE ZAHLUNGSMETHODE HINZUFÜGEN',
    payMethod: 'Zahlungsmethode',
    tip: '<p>Wählen Sie Ihre bevorzugte Zahlungsmethode für zukünftige Ein- und Auszahlungen aus dem untenstehenden Menü.</p><br />\n    <p><b>Bitte beachten Sie:</b> Um sicherzustellen, dass Ihre Ein- und Auszahlungsanträge zeitnah bearbeitet werden können, ist es aufgrund der AML/CTF-Bestimmungen erforderlich, dass der Name auf der von Ihnen gewählten Zahlungsmethode lautet\n    <b> {individualUserName}</b>.</p>',
    result: {
      defaultSuccessMessage:
        'Vielen Dank, dass Sie die Zahlungsmethode für Ihr Trading-Konto eingereicht haben. Wir bearbeiten derzeit Ihre Anfrage und werden uns in Kürze mit Ihnen in Verbindung setzen.',
      unionPayCardSuccessMessage:
        'Union Pay Card Details erfolgreich übermittelt, Ihre neue Bankverbindung wird verfügbar sein, sobald sie genehmigt wurde',
    },
    history: { header: 'ZAHLUNGSMETHODEN' },
    cc: {
      field: {
        ccNum: 'Kreditkartennummer',
        first6: 'Erste Sechs',
        last4: 'Letzte Vier',
        withrawlable: 'Der maximal verfügbare Auszahlungsbetrag für diese Karte ist {amount}.',
      },
      formValidation: { upload: 'Kreditkartenfoto ist erforderlich' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'Abrufen Ihres Einzahlungsstatus... {countDown} Sekunden übrig.',
      successMsg:
        'Vielen Dank für Ihre Einzahlung, Ihr Guthaben wird in Kürze auf Ihrem Konto erscheinen.',
      rejectMsg: 'Abgelehnt',
      failureMsg: 'Ihre Einzahlung war nicht erfolgreich. Bitte versuchen Sie es erneut.',
      message:
        'Vielen Dank für die Einzahlung auf Ihr Konto. Ihre Einzahlung wird in Kürze manuell vom Kontoteam der {platform} genehmigt. Das Geld wird auf Ihr Konto eingezahlt, sobald es genehmigt wurde.',
      defaultException:
        'Um Ihre Einzahlung bei {platform} zu bestätigen, kontaktieren Sie bitte den Kundendienst {email}',
    },
  },
  deposit: {
    default: {
      deposit: 'Einzahlung',
      reference: 'Ihre Referenz',
      sendReceipt: 'Beleg senden',
      sendReceiptTip:
        'Sobald Sie die Überweisung durchgeführt haben, füllen Sie bitte das untenstehende Formular aus und fügen Sie einen Screenshot, ein Foto oder eine gescannte Kopie des Überweisungsbelegs bei, auf dem Ihr vollständiger Name, Ihre Kontonummer und der Einzahlungsbetrag deutlich zu erkennen sind, damit wir Ihre Transaktion bearbeiten können.',
      reminder: 'Bitte warten Sie 1-2 Werktage, bis das Geld auf Ihrem Konto gutgeschrieben wird.',
      successMsg:
        'Das Geld wird auf Ihrem Trading-Konto erscheinen, sobald wir die Einzahlung erhalten und Ihren Transaktionsbeleg verifiziert haben.',
      alt: 'Alternativ können Sie Ihren Transaktionsbeleg auch per E-Mail an {mailTo} senden, wenn Sie es wünschen.',
      reminderFundAppear:
        'Ihre Gelder werden auf Ihrem Konto erscheinen, sobald wir die Gelder von Ihrem bisherigen Broker erhalten.',
      failed: 'Einzahlung fehlgeschlagen, bitte versuchen Sie es später erneut.',
      fee: 'GEBÜHR',
      instant: 'SOFORT',
      hour: 'STUNDE',
      hours: 'STUNDEN',
      businessDays: 'GESCHÄFT TAGE',
      disclaimer:
        '<li>1. {platform} akzeptiert grundsätzlich keine Zahlungen von Dritten. Alle Gelder, die auf Ihr Trading-Konto eingezahlt werden, müssen auf denselben Namen wie Ihr {platform} Trading-Konto lauten.</li>\n      <li> </br>2. Die Kunden erkennen an, dass von ihnen verlangt werden kann, Informationen über die Quelle(n) der Einzahlung(en), das Vermögen der Kunden im Allgemeinen und alle anderen Informationen, welche {platform} als notwendig erachtet, um die Quelle der Gelder zu identifizieren, zur Verfügung zu stellen, wenn eine Einzahlungsanfrage bearbeitet wird. Sie stimmen zu, alle von {platform} geforderten Informationen zur Verfügung zu stellen. Das Versäumnis, die erforderlichen Informationen bereitzustellen, kann zu erheblichen Verzögerungen bei der Bearbeitung Ihrer Anträge führen. {platform} ist nicht verantwortlich für Verzögerungen oder Verluste, die als Ergebnis dieser Verzögerung entstehen.</li>\n      <li> </br>3. Der Kunde versteht, dass das Einreichen eines Einzahlungsantrags keine Annahme von Geldern durch {platform} darstellt. Wenn Ihr Konto kurz vor oder in einem Margin Call ist, stellen Sie bitte sicher, dass Sie andere Vorkehrungen getroffen haben.</li>\n      <li> </br>4. {platform} ist nicht verantwortlich für Verzögerungen/Fehler, die durch Zahlungssysteme verursacht werden.</li>\n      <li> </br>5. {platform} ist nicht verantwortlich für Gebühren (d. h. Umrechnungsgebühren, Gebühren für Vorauszahlungen usw.), die das sendende Finanzinstitut oder dritte Zahlungsanbieter Ihnen für das Senden von Zahlungen in Rechnung stellen können.</li>\n      <li> </br>6. Bitte beachten Sie, dass Moneta Markets in Ihrem Abrechnungssystem als <a href="https://www.monetamarkets.com/" target="_blank">www.monetamarkets.com</a> – Gladstonos & Filokyprou, 120, House Office E2 erscheint.</li>',
      channels: {
        creditOrDebit: 'Kredit-/Debitkarte',
        i12BankTransfer: 'Internationale Banküberweisung',
        auBankTransfer: 'Australien Banküberweisung',
        bPay: 'BPAY Banküberweisung',
        skrill: 'Skrill/Moneybookers',
        sticPay: 'SticPay',
        b2b: 'Broker-zu-Broker-Überweisung',
        unionPayCN: 'China Union Pay Überweisung',
        vietInstant: 'Vietnam Sofortige Banküberweisung',
        thaiInstant: 'Thailändische Sofortüberweisung ',
        nigeriaInstant: 'Nigeria Sofortüberweisung',
        laosInstant: 'Laos Sofortige Banküberweisung',
        malayInstant: 'Malaysia Sofortüberweisung',
        indonesiaInstant: 'Indonesien Sofortüberweisung',
        philippineInstant: 'Philippine Sofortüberweisung',
        mobilePay: 'Mobiles Bezahlen',
        poli: 'POLi-Zahlungen',
        paypal: 'Paypal Einzahlung',
        mijipay: 'Mijipay Einzahlung',
        crypto: 'Kryptowährung',
        canada: 'Interac E-Transfer',
        tinkbit: 'Tinkbit Einzahlung',
        bitwallet: 'Bitwallet Einzahlung',
        indiaInstant: 'Indien Sofortige Banküberweisung',
        trustly: 'Trustly',
        pagsmileBrazil: 'Brasilianische Sofortige Banküberweisung',
        pagsmileMexico: 'Mexico Instant Bank Transfer',
        pagsmileColombia: 'Colombia Instant Bank Transfer',
        pagsmileChile: 'Chile Instant Bank Transfer',
        pagsmilePeru: 'Peru Instant Bank Transfer',
        astropay: 'Astropay Einzahlung',
        dasshpeWallet: 'Indien-Wallets',
        southkoreabanktransfer: 'Banküberweisung in Südkorea',
        alipay: 'Alipay',
        indonesiainstantbanktransfer: 'Indonesien Sofortüberweisung',
        thailandinstantbanktransfer: 'Sofortige Banküberweisung Thailand',
      },
      rate: {
        rate: 'Umrechnungskurse({oldCurrency}/{newCurrency}): ',
        result: {
          rateError: 'Kursabruf {oldCurrency} zu {newCurrency} fehlgeschlagen.',
          channelError: 'Abrufen des Zahlungskanals fehlgeschlagen.',
        },
      },
      cn: { rate: 'Umrechnungskurse (USD/RMB): ', amt: 'RMB: ' },
      viet: {
        result: { rateError: 'Kursabruf USD zu VND fehlgeschlagen.' },
        rate: 'Umrechnungskurse (USD/VND): ',
        amt: 'VND: ',
      },
    },
    method: {
      desc: 'Wie Sie Ihr Konto mit {method} aufladen',
      inst1:
        '{number}.   Füllen Sie das untenstehende Formular aus und drücken Sie auf "Absenden".',
      inst2:
        '{number}.   Wählen Sie die Bank auf der Bankauswahlseite aus und melden Sie sich bei Ihrer {country} Online Bank an.',
      inst3:
        '{number}.   Folgen Sie den Anweisungen und tätigen Sie die Zahlung über Ihre Online/Mobile Bank.',
      inst4:
        '{number}.   Sie werden zurück zum Kundenportal weitergeleitet und Ihre Transaktion wird abgeschlossen.',
      inst5: '{number}.   Loggen Sie sich in Ihr {method}-Konto ein',
      inst6:
        '{number}.   Folgen Sie den Anweisungen in Ihrem {method}-Konto, um Ihre Zahlung abzuschließen',
      inst7:
        'Bitte beachten Sie: Wenn Sie sich für eine manuelle Einzahlung entscheiden, kann es zu Verzögerungen kommen und es kann sehr lange dauern, bis Ihr Geld Ihrem Handelskonto gutgeschrieben wird.',
      inst8:
        '{number}.   Bitte beachten Sie, dass bei der Verwendung von {payment} der Einzahlungsbetrag auf {currency} {amount} pro Transaktion begrenzt ist.',
      form: { header: '{method} FORMULAR' },
      inst12: "{number}.   Füllen Sie das untenstehende Formular aus und wählen Sie 'Absenden'",
      inst13: '{number}.   Scannen Sie den QR-Code, um Ihre Mobile-Banking-Anwendung zu öffnen',
      inst14:
        '{number}.   Folgen Sie den Aufforderungen auf Ihrem mobilen Gerät, um Ihre Einzahlung abzuschließen',
      inst15: '{number}.   Füllen Sie das untenstehende Formular aus und drücken Sie ‘Absenden’',
      inst16: "{number}.   Geben Sie nach Eingabe Ihrer Kartendaten 'Absenden' ein",
    },
    cpsInfo: {
      thispaymentchannelistemporaryunavailable:
        'Dieser Zahlungskanal ist vorübergehend nicht verfügbar.',
    },
    uk: { header: 'UK Sofortige Banküberweisung', country: 'UK' },
    eu: { header: 'Lokale EU-Banküberweisung', country: 'EU' },
    southAfrica: { header: 'Südafrikanische Instant BankÜberweisung', country: 'Südafrikanische' },
    idn: { header: 'Indonesien Sofortüberweisung', country: 'Indonesien' },
    ida: { header: 'Indien Sofortige Banküberweisung', country: 'Indien' },
    brazil: { header: 'Brasilianische Sofortige Banküberweisung', country: 'Brasilien' },
    nigeria: { header: 'Nigeria Sofortüberweisung', country: 'Nigeria' },
    thailand: { header: 'Thailändische Sofortüberweisung', country: 'Thailand' },
    vietnam: { header: 'Vietnam Sofortige Banküberweisung', country: 'Vietnam' },
    laos: {
      header: 'Laos Sofortige Banküberweisung',
      country: 'Laos',
      inst: '2.   Scannen Sie den QR-Code mit Ihrer OnePay-App',
    },
    hongkong: {
      country: 'Hongkong',
      inst1: '2.   Folgen Sie den Anweisungen und tätigen Sie Ihre Zahlung',
      inst2: '3.   Ihre Transaktion wird abgeschlossen',
      inst3: 'Bitte folgen Sie den nachstehenden Anweisungen, um die Zahlung durchzuführen:',
      inst4: '1.   Bitte loggen Sie sich in Ihr Bankkonto ein. ',
      inst5: '2.   Überweisen Sie das Geld an:',
      inst6:
        '3.   Nachdem Sie die Zahlung abgeschlossen haben, klicken Sie bitte auf die Schaltfläche FERTIG, um fortzufahren.',
      inst8: 'Hinweis:',
      inst9:
        'Diese generierte Kontonummer ist nur für eine einmalige Einzahlung gültig und sollte nicht für zukünftige Transaktionen verwendet werden, um Verzögerungen beim Eingang der Gelder zu vermeiden.',
      inst10:
        'Der überwiesene Betrag muss mit dem in der Einzahlungsanforderung angegebenen Betrag übereinstimmen.',
      inst11:
        'Bitte stellen Sie sicher, dass Sie Ihre Überweisung innerhalb von 1 Stunde nach Einreichung der Einzahlungsanforderung tätigen, um eine fehlgeschlagene Einzahlung zu vermeiden.',
    },
    malay: { header: 'Malaysia Sofortüberweisung', country: 'Malaysia' },
    php: { header: 'Philippinische Sofortige Banküberweisung', country: 'Philippinische' },
    uganda: { header: 'Uganda lokale Banküberweisung', country: 'Uganda' },
    rwanda: { header: 'Ruanda lokale Banküberweisung', country: 'Ruanda' },
    zambia: { header: 'Sambia lokale Banküberweisung', country: 'Sambia' },
    congo: { header: 'Kongo lokale Banküberweisung', country: 'Kongo' },
    cameroon: { header: 'Kamerun lokale Banküberweisung', country: 'Kamerun' },
    burundi: { header: 'Burundi lokale Banküberweisung', country: 'Burundi' },
    kenya: { header: 'Kenia lokale Banküberweisung', country: 'Kenia' },
    ghana: { header: 'Ghana lokale Banküberweisung', country: 'Ghana' },
    tanzania: { header: 'Tansania lokale Banküberweisung', country: 'Tansania' },
    bankWireAu: {
      header: 'BANKÜBERWEISUNG: AUSTRALIEN',
      instruction:
        'Gelder können über die Online-Banking-Einrichtung Ihres Finanzinstituts an {platform} überwiesen werden, oder alternativ, indem Sie sich an Ihr Finanzinstitut wenden und die Überweisung an {platform} beantragen.',
      tip: 'Wählen Sie die Kontowährung, in der Sie einzahlen möchten. Bitte stellen Sie sicher, dass Sie die gleiche Währung wie die Währung Ihres Trading-Kontos wählen.',
      form: {
        bankDetails: 'Bankdaten (nur AUD-Einzahlung)',
        refTip: 'Bitte geben Sie hier Ihre Anmeldenummer an',
        afp: 'Australische Schnelleinzahlung (NUR AUD-Einzahlung im Inland)',
        payIdOrABNNum: 'PayID/ABN-Nummer',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip:
          'Bitte geben Sie Ihre {platform} Trading-Kontonummer in das Referenzfeld für die Banküberweisung ein.',
      },
    },
    bPay: {
      header: 'BPAY EINZAHLUNG',
      option: 'So zahlen Sie mit BPay auf Ihr Konto ein (nur in Australien)',
      inst1:
        '1.   Wählen Sie Ihr MT4/MT5-Konto, um Ihren BPay-Rechnungssteller-Code und Ihre BPay-Referenznummer anzuzeigen.',
      inst2:
        '2.   Geben Sie die Zahlungsdetails, Ihren BPay-Rechnungssteller-Code und Ihre BPay-Referenznummer in Ihr Internet-Banking ein und senden Sie die Zahlung ab.',
      inst3:
        '3.   Kehren Sie auf diese Seite zurück und füllen Sie das folgende BPay-Formular aus, damit wir Ihre Transaktion umgehend bearbeiten können.',
      form: {
        header: 'BPAY-FORMULAR',
        billerCode: 'BPay-Rechnungssteller-Code',
        refNum: 'BPay-Referenznummer',
        receipt: 'BPAY-Überweisungsbeleg',
        reminderNotUploaded:
          'Wir haben festgestellt, dass Sie Ihren Transaktionsbeleg nicht hochgeladen haben. Um Ihre Einzahlung bearbeiten zu können, senden Sie bitte schnellstmöglich eine Kopie des Belegs per E-Mail an {mailTo}.',
        reminderProc:
          'Bitte beachten Sie: Ihre Transaktion kann NICHT bearbeitet werden, bevor wir Ihren Beleg erhalten haben.',
      },
    },
    b2b: {
      header: 'VON BROKER ZU BROKER',
      option: 'So überweisen Sie Gelder von einem anderen Broker zu {platform}',
      inst1: '1.   Laden und vervollständigen Sie den {download}.',
      inst2:
        '2.   Füllen Sie das Online-Formular unten aus und laden Sie das ausgefüllte Formular für die Überweisung von Broker zu Broker hoch.',
      inst3:
        '3.   Senden Sie eine Kopie des ausgefüllten Formulars von Broker zu Broker an Ihren bisherigen Broker, um die Überweisung zu veranlassen.',
      b2bForm: 'Formular für die Überweisung von Broker zu Broker',
      formValidation: { upload: 'Das Broker-zu-Broker-Formular ist erforderlich.' },
    },
    cc: {
      header: 'EINZAHLUNG PER KREDIT- ODER DEBITKARTE',
      remind:
        'Sollten Sie Schwierigkeiten bei der Einzahlung auf Ihr Konto mit der primären Methode haben, verwenden Sie bitte die sekundäre Kredit-/Debitmethode.',
      field: {
        fundNow: 'Jetzt per Kredit-/Debitkarte einzahlen',
        newCard: 'Neue Karte',
        cardNum: 'Kreditkartennummer',
        nameOnCard: 'Name auf Karte',
        expDate: 'Gültigkeitsdatum',
        cvv: 'Sicherheitscode CVV',
        bankCode: 'Kartenausstellende Bank',
      },
      placeholder: { bankCode: 'Bitte geben Sie den Namen Ihrer kartenausgebenden Bank ein.' },
      cvvTip: 'Die 3 Ziffern, die auf <br />der Rückseite Ihrer Karte angezeigt werden.',
      threeDomainSecQ: 'Unterstützt Ihre Karte 3D-Secure?',
      threeDomainSec:
        'Was ist die 3D-Secure-Authentifizierung? <br /><strong>Die 3D-Secure-Authentifizierung ist eine von Visa und Mastercard anerkannte Methode zur Betrugsprävention.<br /><br />Wenn Ihre Karte 3D-Secure unterstützt, werden Ihre Einzahlungen sofort bearbeitet.</strong>',
      cardUpload:
        'Die Karte, die Sie zur Einzahlung verwenden, wurde nicht mit Ihrem Trading-Konto verknüpft. Bitte laden Sie ein Foto der Vorderseite Ihrer Karte zur Verifizierung hoch. (Bitte decken Sie die mittleren 6 Ziffern ab.)',
      result: {
        success:
          'Wir danken Ihnen. Wir sind gerade dabei, Ihre Kartendaten zu verifizieren. Nach der Verifizierung sollte das Geld innerhalb von 24 Stunden auf Ihrem Konto erscheinen.',
        depositSuccess:
          'Vielen Dank für Ihre Einzahlung, Ihr Guthaben wird in Kürze auf Ihrem Konto erscheinen.',
        error:
          'Um die Sicherheit Ihres Kontos zu schützen,<br> haben wir  Ihre Einzahlungen vorübergehend ausgesetzt, da Sie die maximale Anzahl versuchter Einzahlungstransaktionen erreicht haben.<br> Bitte kontaktieren Sie {email}',
      },
      mastercardonly: 'Bitte verwenden Sie diesen Zahlungskanal nur mit Mastercard weiter.',
    },
    fasa: {
      header: 'Fasapay Einzahlung',
      description: 'Wie Sie Ihr Konto mit FasaPay aufladen',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Melden Sie sich bei Ihrem FasaPay-Konto an.',
      inst3: '3.   Folgen Sie den Anweisungen im FasaPay-Konto, um Ihre Zahlung abzuschließen.',
      inst4:
        '4.   Fasapay-Einzahlungen sind auf $ 25.000 USD pro Transaktion und maximal $ 75.000 USD pro Tag begrenzt.',
      form: { header: 'FASAPAY FORMULAR' },
    },
    miji: {
      header: 'Thailändische Sofortüberweisung ',
      description: 'Wie Sie Ihr Konto mit Thailand Sofortüberweisung aufladen',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Sie werden dann auf die Zahlungsseite weitergeleitet.',
      inst3:
        '3.   Loggen Sie sich in Ihre Mobile Banking App ein und scannen Sie den QR-Code auf der Zahlungsseite.',
      inst4:
        'Bitte beachten Sie: <b>Das System addiert ein paar Cent zu Ihrem gewünschten Betrag.</b>',
      form: { header: 'Thailand Sofortüberweisung Formular' },
    },
    intSwift: {
      header: 'BANKÜBERWEISUNG: INTERNATIONALE SWIFT',
      desc: 'Gelder können über die Online-Banking-Einrichtung Ihres Finanzinstituts an {platform} überwiesen werden, oder alternativ, indem Sie sich an Ihr Finanzinstitut wenden und die Überweisung an {platform} beantragen.',
      desc2:
        'Sobald Sie die Überweisung getätigt haben, füllen Sie bitte das untenstehende Formular aus und fügen Sie eine Kopie des Überweisungsbelegs bei, aus dem Ihr vollständiger Name, Ihre Kontonummer und der Einzahlungsbetrag hervorgehen, damit wir Ihre Transaktion bearbeiten können.',
      options: 'Bitte wählen Sie das Trading-Konto, auf das Sie einzahlen möchten.',
      refInfo:
        'Bitte geben Sie Ihre {platform} Trading-Kontonummer in das Referenzfeld für die Banküberweisung ein.',
      bussDayInfo:
        'Bitte warten Sie 2-5 Werktage, bis das Geld auf Ihrem Konto gutgeschrieben wird.',
      tip: 'Bitte vergewissern Sie sich, dass auf Ihrem Überweisungsbeleg deutlich Ihr <b>voller Name</b>, Ihre <b>Kontonummer</b> und Ihr <b>Einzahlungsbetrag</b> angegeben sind.',
    },
    mobile: {
      header: 'Mobiles Bezahlen',
      desc: 'Wie Sie Ihr Konto mit Mobile Pay aufladen',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Scannen Sie den QR-Code, um Ihre Mobile-Banking-Anwendung zu öffnen.',
      inst3:
        '3.   Folgen Sie den Anweisungen auf Ihrem mobilen Gerät, um Ihre Einzahlung abzuschließen.',
      form: { payMethod: 'Zahlungsmethode:', rate: 'Umrechnungskurse (USD/CNY):', amt: 'CNY:' },
      formValidation: { cnyAmt: 'CNY Betrag kann nicht höher sein als $ {limit}.' },
    },
    net: {
      header: 'Neteller',
      inst2: '2.   Melden Sie sich bei Ihrem Neteller-Konto an.',
      inst3: '3.   Folgen Sie den Aufforderungen im Neteller-Konto, um Ihre Zahlung abzuschließen.',
      inst4: {
        fca: '4.   Wir können Zahlungen in USD, EUR und GBP akzeptieren.',
        default: '4.   Wir können Zahlungen in AUD, USD, EUR, GBP und SGD akzeptieren.',
      },
    },
    canada: {
      header: 'Interac E-Transfer',
      desc: 'Wie Sie Ihr Konto mit Interac E-Transfer aufladen',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2:
        '2.   Wählen Sie die Bank auf der Bankauswahlseite und melden Sie sich bei Ihrer Online-Bank an.',
      inst3:
        '3.   Folgen Sie den Anweisungen und tätigen Sie die Zahlung über Ihre Online/Mobile Banking.',
      inst4: '4.   Wir können Zahlungen in CAD akzeptieren. Maximal $ 9.999 CAD pro Einzahlung.',
      form: { header: 'Interac E-Transfer Formular' },
    },
    bitwallet: {
      header: 'BITWALLET-EINZAHLUNG',
      desc: 'Wie Sie Ihr Konto mit BITWALLET aufladen',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Melden Sie sich bei Ihrem Bitwallet-Konto an.',
      inst3:
        '3.   Folgen Sie den Aufforderungen in Ihrem Bitwallet-Konto, um Ihre Zahlung abzuschließen.',
      inst4: '4.   Wir können Zahlungen in USD, EUR und JPY akzeptieren.',
      form: { header: 'BITWALLET-FORMULAR' },
    },
    astropay: {
      header: 'ASTROPAY Einzahlung',
      desc: 'Wie Sie Ihr Konto mit ASTROPAY aufladen',
      inst2: '2.   Melden Sie sich bei Ihrem ASTROPAY-Konto an.',
      inst3:
        '3.   Folgen Sie den Aufforderungen in Ihrem ASTROPAY-Konto, um Ihre Zahlung abzuschließen.',
      inst4: '4.   wir können Zahlungen in allen Währungen akzeptieren.',
      form: { header: 'ASTROPAY-FORMULAR' },
    },
    crypto: {
      choose: 'Bitte wählen Sie Ihre bevorzugte Kryptowährung',
      proceedQuestion: 'Möchten Sie mit Ihrer <br /><b>{method}</b> Einzahlung fortfahren?',
      remark:
        'Bitte beachten Sie, dass wir keine Einzahlungen oder Auszahlungen über BUSDT vornehmen können. Bitte stellen Sie sicher, dass die Adresse und die Kryptowährung mit der von uns akzeptierten Chain und Währung übereinstimmen, sonst könnten Sie die Gelder verlieren.',
      inst2:
        '2.    Sie werden dann zu einem neuen Fenster weitergeleitet, das eine {method}-Wallet-Adresse anzeigt.',
      inst3:
        '3.    Kopieren Sie die {method}-Wallet-Adresse, loggen Sie sich dann in Ihre persönliche {method}-Wallet ein und überweisen Sie den gewünschten Betrag an die {method}-Adresse.',
      inst4:
        'Bitte beachten Sie: BITCOIN-Einzahlungen werden von B2BinPay zu dem zum Zeitpunkt der Transaktion angegebenen Wechselkurs verarbeitet.',
    },
    poli: {
      header: 'POLI EINZAHLUNG',
      desc: 'Wie Sie Ihr Konto bei Poli aufladen',
      reminder:
        '<li>Bitte beachten Sie:</li><li>* Die Einzahlung muss auf den gleichen Namen lauten wie Ihr {platform}-Konto</li><li>* Einzahlungen dürfen nur in AUD erfolgen</li>',
      form: { header: 'POLI-FORM' },
    },
    skrill: {
      header: 'SKRILL/MONEYBOOKERS EINZAHLUNG',
      desc: 'Wie Sie Ihr Konto mit Skrill aufladen',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Melden Sie sich bei Ihrem Skrill-Konto an.',
      inst3:
        '3.   Folgen Sie den Aufforderungen in Ihrem Skrill-Konto, um Ihre Zahlung abzuschließen.',
      inst4: '4.   Wir können Zahlungen in USD, EUR, GBP und CAD akzeptieren.',
      inst4FCA: '4.   Wir können Zahlungen in USD, EUR und GBP akzeptieren.',
      form: { header: 'SKRILL-FORMULAR', email: 'Skrill/Moneybookers E-Mail' },
      reminder:
        '<li>Bitte beachten Sie:</li>\n      <li>* Auf Konten kann nur in der gleichen Währung wie Ihr MT4-Trading-Konto eingezahlt werden. Skrill konvertiert Gelder automatisch in die Basiswährung Ihres Kontos, wenn die falsche E-Mail verwendet wird. Es können Gebühren anfallen.</li>\n      <li>* Einzahlungen müssen auf denselben Namen wie Ihr {platform}-Konto lauten.</li>',
    },
    sticPay: {
      inst2: '2.   Melden Sie sich bei Ihrem SticPay-Konto an.',
      inst3: '3.   Folgen Sie den Anweisungen im SticPay-Konto, um Ihre Zahlung abzuschließen.',
    },
    webMoney: {
      header: 'WEBMONEY',
      inst4: '4.   Wir können Zahlungen in USD und EUR akzeptieren.',
      inst5: '5.   Vload-Einzahlungen sind auf 1300 USD/1100 EUR pro Transaktion begrenzt',
    },
    vload: {
      header: 'VLOAD',
      inst1: '2.   Wir können Zahlungen in USD, EUR und JPY akzeptieren.',
      inst2:
        '<li>3.   Kaufen Sie einen Vload-Gutschein, klicken Sie <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">hier</a>.</li>',
      form: { voucherpin: 'Gutschein-Pin', voucherpinReq: 'Gutschein-PIN ist erforderlich' },
    },
    dasshpe: {
      headerWallet: 'Indien-Wallets',
      inst1: '2.   Genehmigen Sie die Transaktion von Ihrer UPI-App aus',
      inst2: '2.   Folgen Sie den Anweisungen und nehmen Sie die Zahlung über Ihre Wallet-App vor',
    },
    thaiPaytoday: {
      header: 'Thailändische Sofortüberweisung ',
      desc: 'Wie Sie Ihr Konto mit Thailand Sofortüberweisung aufladen',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Folgen Sie den Anweisungen, um Ihre Zahlung abzuschließen.',
      form: { header: 'Thailand Sofortüberweisung Formular' },
      scan: 'Scannen, um eine Einzahlung vorzunehmen',
      exp: 'Ablauf in {expireTime}',
      reminder:
        'Das Geld sollte innerhalb von 1 Stunde auf Ihrem Konto angezeigt werden. <br/><br/>\n      Sie können den aktuellen Status Ihrer Einzahlung in Ihrer "Transaktionshistorie" einsehen. <br/>\n      Sollten Sie Probleme mit Ihrer Einzahlung haben, wenden Sie sich bitte an Ihren Account Manager.',
    },
    unionPay: {
      header: 'UnionPay-Einzahlung',
      desc: 'Wie Sie Ihr Konto bei UnionPay finden',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2:
        '2.   Wählen Sie die Bank auf der Bankauswahlseite und melden Sie sich bei Ihrer Online-Bank an.',
      inst3:
        '3.   Folgen Sie den Anweisungen und tätigen Sie die Zahlung über Ihre Online/Mobile Banking.',
      form: { header: 'UNION PAY FORMULAR' },
    },
    unionPayCN: {
      header: 'China Union Pay Überweisung',
      desc: 'Wie Sie Ihr Konto mit China Union Pay Transfer aufladen können',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Eine Seite mit Zahlungsdetails wird angezeigt.',
      inst3:
        '3.   Folgen Sie den Anweisungen und tätigen Sie die Zahlung über Ihre Online/Mobile Banking.',
      inst4:
        'Bitte beachten Sie: Möglicherweise werden Sie bei der Einzahlung nach Ihrer Mitgliedsnummer gefragt, bitte geben Sie Ihre Trading-Kontonummer als Mitgliedsnummer ein.',
      form: {
        header: 'CHINA UNION PAY FORMULAR',
        holderName: 'Name des UnionPay-Karteninhabers:',
        holderNameInfo:
          'Bitte stellen Sie sicher, dass der Name, den Sie oben eingeben, mit dem Namen des Karteninhabers Ihrer Bank übereinstimmt',
      },
    },
    vietZota: {
      header: 'Vietnam Sofortige Banküberweisung',
      desc: 'Wie Sie Ihr Konto mit Vietnam Sofortüberweisung aufladen',
      inst: '<li>1.   Füllen Sie das untenstehende Formular aus und drücken Sie auf "Absenden"</li><li>2.   Wählen Sie die Bank auf der Bankauswahlseite aus und melden Sie sich bei Ihrer Vietnam Online Bank an</li><li>3.   Folgen Sie den Anweisungen und tätigen Sie die Zahlung über Ihre Online/Mobile Bank</li> <li>4.   Sie werden zurück zum Kundenportal weitergeleitet und Ihre Transaktion wird abgeschlossen.</li>',
      form: { header: 'Vietnam Sofortüberweisungsformular' },
    },
    paypal: {
      header: 'PayPal',
      desc: 'Wie Sie Ihr Konto mit PayPal aufladen',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Melden Sie sich bei Ihrem PayPal-Konto an.',
      inst3: '3.   Folgen Sie den Anweisungen im PayPal-Konto, um Ihre Zahlung abzuschließen.',
      inst4: 'Bitte beachten Sie: Wir können keine Zahlungen von einer dritten Partei akzeptieren.',
      form: { header: 'PAYPAL-FORMULAR' },
    },
    cardpay: {
      desc: 'Wie Sie auf Ihr Konto mit Kreditkarte einzahlen',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2:
        '2.   Sie werden dann zu einem weiteren Formular weitergeleitet, um Ihre Kartendaten einzugeben.',
      inst3: '3.   Nachdem Sie Ihre Kartendaten eingegeben haben, drücken Sie auf "Senden".',
      note: "Bitte beachten Sie, dass die Beschreibung der Transaktion, die auf Ihrer Kartenabrechnung erscheint, je nach verwendetem Finanz-Gateway variiert. Wenn Sie Bedenken bezüglich der Beschreibung der Transaktion haben, wenden Sie sich bitte an <a href='mailto:{mail}'>{mail}</a>",
      form: { header: 'KREDITKARTENFORMULAR' },
    },
    tinkbit: { inst5: '5.   Maximal $ 1.500 USD pro Einzahlung' },
    trustly: {
      header: 'Trustly',
      desc: 'Wie Sie Ihr Konto mit Trustly aufladen',
      inst1: '1.   Füllen Sie das untenstehende Formular aus und klicken Sie auf "Senden".',
      inst2: '2.   Melden Sie sich bei Ihrem Trustly-Konto an.',
      inst3: '3.   Folgen Sie den Anweisungen im Trustly-Konto, um Ihre Zahlung abzuschließen.',
      inst4: '4.   Wir können Zahlungen in EUR und GBP akzeptieren.',
      form: { header: 'TRUSTLY-FORMULAR' },
      reminder:
        '<li>Bitte beachten Sie:</li>\n      <li>* Auf Konten kann nur in der gleichen Währung wie Ihr MT4-Trading-Konto eingezahlt werden. Skrill konvertiert Gelder automatisch in die Basiswährung Ihres Kontos, wenn die falsche E-Mail verwendet wird. Es können Gebühren anfallen.</li>\n      <li>* Einzahlungen müssen auf denselben Namen wie Ihr {platform}-Konto lauten.</li> ',
    },
    fxir: {
      desc: '',
      desc2: '',
      field: {
        id: 'Sender FX-IR ID',
        accName: 'FX-IR Kontoname',
        walletNumber: 'FX-IR Wallet-Nummer',
        transactionID: 'Transaktions-ID',
        senderFxirID: 'Sender FX-IR ID',
      },
    },
  },
  restrictDeposit: {
    header: 'WICHTIGER HINWEIS',
    info: 'Leider nehmen wir keine Einzahlungen von Kunden mit Wohnsitz in Kanada mehr an.',
  },
  withdraw: {
    default: {
      selectCC: 'Kreditkarte auswählen',
      selectBA: 'Bankkonto auswählen',
      anotherCC: 'Kreditkarte manuell eingeben',
      anotherBA: 'Bankkonto hinzufügen',
      remember: 'Mein Konto merken',
      success: 'Ihre Auszahlungsanforderung war erfolgreich und wird in Kürze bearbeitet.',
      ccFail:
        'Um die Sicherheit Ihres Kontos zu schützen, haben wir Ihre Auszahlungen wurden vorübergehend ausgesetzt, da Sie die maximale Anzahl versuchter Einzahlungstransaktionen erreicht haben. Bitte kontaktieren Sie {email}.',
      fetchTradeAccFailed: 'Abrufen des Trading-Kontos fehlgeschlagen',
      failed: 'Auszahlung fehlgeschlagen. Bitte versuchen Sie es später erneut.',
      notEnoughFunds: 'Der eingegebene Betrag überschreitet das Limit für diese Karte.',
      cardExpird:
        'Diese Karte ist abgelaufen. Bitte wählen Sie eine andere Karte oder eine andere Zahlungsmethode.',
      minAmountWarn:
        'Der beantragte Auszahlungsbetrag ist niedriger als der Mindestbetrag für das verwendete Zahlungsportal. Bitte laden Sie Ihren Kontostand auf/über den Mindestbetrag auf, bevor Sie eine Auszahlung beantragen. Weitere Informationen finden Sie <a href="https://{regulatorUrl}/clients/accounts/withdraw/">hier</a>. ',
      noCardWarn:
        'Sie haben keine verfügbare Karte für eine Auszahlung, bitte verknüpfen Sie Ihre Karte zuerst in den Auszahlungsdetails.',
      equityStop:
        'Leider kann Ihre Auszahlung nicht bearbeitet werden, da sie dazu führt, dass die verfügbare Margin Ihres Kontos unter die Stop-Out-Grenze von 100% fällt.',
      equityMargin:
        'Leider können wir Ihre Auszahlung derzeit nicht bearbeiten, da Ihr Konto vollständig abgesichert ist.',
      equityContinue:
        'Wir haben festgestellt, dass Sie derzeit offene Positionen haben. Es kann sein, dass Sie nach dem Einreichen des Auszahlungsantrags einen Stop-Out erleben, bitte bestätigen Sie dies.',
      YourPaymentGreaterThanAvailableBalance:
        'Ihre Zahlung ist größer als das verfügbare Guthaben.',
      blackList:
        'Leider können Sie derzeit keine Auszahlungsanfrage stellen. Bitte kontaktieren Sie {supportEmail} für weitere Informationen',
      NetellerChargeAlert:
        'Neteller-Auszahlungen sind auf 500.000 US-Dollar pro Transaktion begrenzt und es fällt eine Gebühr von 2 % (maximal 30 US-Dollar) an.',
      FasaPayChargeAlert: 'FasaPay berechnet für jede Auszahlung eine Transaktionsgebühr von 0,5%.',
      SkrillChargeAlert: 'Skrill erhebt eine Transaktionsgebühr von 1% für jede Auszahlung.',
      localBankTransfer: 'Local Bank Transfer',
    },
    fasa: {
      field: { accName: 'FasaPay Kontoname', accNum: 'FasaPay Kontonummer' },
      formValidation: {
        accNameReq: 'FasaPay Kontoname ist erforderlich',
        accNumReq: 'FasaPay Kontonummer ist erforderlich',
      },
    },
    neteller: {
      field: { email: 'Neteller E-Mail' },
      formValidation: { emailReq: 'Neteller E-Mail ist erforderlich' },
    },
    bitcoin: {
      field: { address: 'Bitcoin-Wallet-Adresse' },
      formValidation: { addressReq: 'Bitcoin-Wallet-Adresse ist erforderlich' },
    },
    eth: {
      field: { address: 'ETH-Wallet-Adresse' },
      formValidation: { addressReq: 'ETH-Wallet-Adresse ist erforderlich' },
    },
    usdc: {
      field: { address: 'USDC-Wallet-Adresse' },
      formValidation: { addressReq: 'USDC-Wallet-Adresse ist erforderlich' },
    },
    usdt: {
      field: { address: 'USDT-Wallet-Adresse' },
      formValidation: { addressReq: 'USDT-Wallet-Adresse ist erforderlich' },
    },
    paypal: {
      field: { email: 'Paypal-E-Mail' },
      formValidation: { emailReq: 'Paypal E-Mail ist erforderlich' },
    },
    skrill: {
      field: { email: 'Skrill-E-Mail' },
      formValidation: { emailReq: 'Skrill E-Mail ist erforderlich' },
    },
    webMoney: {
      field: { email: 'WebMoney-E-Mail' },
      formValidation: { emailReq: 'WebMoney E-Mail ist erforderlich' },
    },
    sticpay: {
      field: { email: 'SticPay E-Mail' },
      formValidation: { emailReq: 'SticPay E-Mail ist erforderlich' },
    },
    vload: {
      field: { email: 'Registrierte Vload E-Mail-Adresse  ' },
      formValidation: { emailReq: 'Registrierte Vload-E-Mail-Adresse ist erforderlich' },
    },
    bitwallet: {
      field: { email: 'Bitwallet E-Mail' },
      formValidation: { emailReq: 'Bitwallet E-Mail ist erforderlich' },
    },
    astropay: {
      field: { accountNumber: 'Astropay-Konto' },
      formValidation: { accountNumberReq: 'Astropay-Konto ist erforderlich' },
    },
    transfer: {
      info: '{platform} wird die Bankgebühr für <u>EINE</u> Auszahlung pro Monat zurückerstatten. Für weitere Auszahlungen innerhalb des Monats fällt eine Bankgebühr von 20 Einheiten Ihrer Auszahlungswährung an.',
      label: {
        upload:
          'Bitte laden Sie eine Kopie Ihres Kontoauszugs hoch (aktuell für die letzten 3 Monate).',
        upload2:
          'Stellen Sie sicher, dass das Datum des Kontoauszugs oder das Datum der letzten Transaktion im aktuellen Monat bis zu den letzten drei Monaten liegt. Wenn dies den Zeitrahmen überschreitet, laden Sie bitte den Kontoauszug erneut hoch.',
      },
      formValidation: { upload: 'Bitte laden Sie einen Kontoauszug hoch.' },
    },
    ccMethod: {
      desc1:
        'Aufgrund der AML/CTFCompliance muss das Geld auf die Karte überwiesen werden, die Sie für Ihre erste Einzahlung verwendet haben',
      desc2:
        'Wenn die Karte, mit der Sie Geld auf Ihr Konto eingezahlt haben, nicht verfügbar ist, wenden Sie sich bitte an {mailto}',
    },
    otherMethod: {
      otherType: 'Andere Arten',
      withdrawalMethod: 'Auszahlungsmethoden',
      desc1:
        'Bitte beachten Sie, dass bei Banküberweisungen eine Gebühr anfallen kann. Bitte stellen Sie sicher, dass Ihr Auszahlungsbetrag ausreicht, um eventuell anfallende Gebühren zu decken.',
      desc2:
        'Aufgrund der AML/CTF-Compliance müssen alle Auszahlungen mit der gleichen Methode überwiesen werden, mit der Sie Geld auf Ihr Konto eingezahlt haben.',
      note: "Aufgrund aktueller Änderungen ist unsere Bank möglicherweise nicht in der Lage, internationale Banküberweisungen für Kunden zu bearbeiten, die in einigen Ländern registriert sind.<br/>\n      Wenn Sie eine Auszahlung per internationaler Banküberweisung vornehmen müssen, wenden Sie sich bitte an unseren Kundensupport unter +44 2080 363 883 oder <a href='mailto:{mail}'>{mail}</a>.<br/>\n      Wir entschuldigen uns für die Unannehmlichkeiten.",
      verificationCode:
        'Der Verifizierungscode wurde an {email} gesendet. Bitte kontaktieren Sie {supportEmail}, wenn Sie weitere Hilfe benötigen',
      note2:
        'Aufgrund der Auswirkungen der Travel Rule Politik Südkoreas akzeptieren wir keine Abhebungen von koreanischen Börsenadressen über 735 USDT (ca. 1.000.000 KRW) pro Transaktion oder von Kunden, deren Adresse nicht KYC-verifiziert ist.',
    },
  },
  transfer: {
    creditWarn:
      'Wir können Ihren Geldtransfer von dem ausgewählten Konto nicht bearbeiten, da Ihr Konto ein Promotionsguthaben enthält.',
    blackListWarn:
      'Ein Geldtransfer von Ihrem MAM-Konto kann zur Liquidation der verwalteten Positionen führen. Bitte kontaktieren Sie {mailTo}, wenn Sie Gelder von diesem Konto überweisen möchten.',
  },
  changePw: {
    header: 'PASSWORT ÄNDERN',
    field: {
      currPw: 'Aktuelles Passwort',
      newPw: 'Neues Passwort',
      confirmPw: 'Bestätigen Sie das neue Passwort',
    },
    succ: 'Ihr Passwort wurde aktualisiert. Sie können nun Ihr neues Passwort für den Zugang zum Kundenportal verwenden.',
  },
  security: {
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    understand: 'Ich verstehe',
    passwordRenewalReminder: {
      header: 'Ändern Sie das Login-Passwort',
      desc: 'Zum Schutz Ihrer Kontoguthaben werden Auszahlungen T+{days} Tage lang nach Änderung Ihres Login-Passworts nicht unterstützt.',
    },
    withdrawalFreeze: {
      header: 'Auszahlungen werden vorübergehend nicht unterstützt',
      userLoginDesc:
        'Aufgrund der Änderung Ihrer Anmelde-E-Mail-/Telefonnummer werden Abhebungen für T+{days} Tage vorübergehend nicht unterstützt. Diese Einschränkung wird voraussichtlich in {hours} Stunden aufgehoben.',
      passDesc:
        'Aufgrund der Änderung Ihres Login-Passworts werden Abhebungen vorübergehend für T+{days} Tage nicht unterstützt, um die Sicherheit Ihres Kontos zu gewährleisten. Diese Einschränkung wird voraussichtlich in {hours} Stunden aufgehoben.',
      limitSecurityAuthenticator:
        'Aufgrund der Änderung Ihrer Sicherheits-Authenticator-App werden Auszahlungen vorübergehend für T+{days} Tage nicht unterstützt, um die Sicherheit Ihres Kontos zu gewährleisten. Diese Einschränkung wird voraussichtlich in {hours} Stunden aufgehoben.',
    },
    securityManagement: 'Sicherheitsmanagement',
    securityAuthentication: 'Sicherheitsauthentifizierung',
    authenticationMethod: 'Authentifizierungsmethode',
    changeAuthenticatorTitle: 'Sicherheitsauthentifikator ändern',
    isVerified: 'Verifiziert',
    isNotVerified: 'Nicht verifiziert',
    password: 'Passwort',
    securityAuthenticatorApp: 'Sicherheitsauthentifikator-App',
    enableLoginAuthentication: 'Anmeldung Authentifizierung aktivieren',
    loginLocationChange: 'E-Mail-Benachrichtigung bei Änderung des Anmeldeorts',
    lastUpdate: 'Letztes Update',
    modify: 'Ändern',
    verify: 'Überprüfen',
    enable: 'Aktivieren',
    resend: 'Erneut senden',
    enterVerificationCode: 'Bestätigungscode eingeben',
    verifyPreEmailNote: 'Der Code wird an {email} gesendet',
    verifyPostEmailNote: 'Code wurde an {email} gesendet',
    preEmailCode: 'Der Code wird an Ihre neue E-Mail-Adresse gesendet',
    authenticatorTooltip:
      'Der Bestätigungscode der Authenticator-App wird alle 30 Sekunden aktualisiert',
    contactSupport:
      'Zugriff auf die Authentifizierungsmethode nicht möglich? Bitte kontaktieren Sie {contact_email} oder Livechat',
    changeEmail: 'E-Mail-Adresse ändern',
    emailTitle: 'Neue E-Mail-Adresse eingeben',
    editAuthenticator: 'Authentifizierungsgerät bearbeiten',
    addAuthenticator: 'Authentifizierungsgerät hinzufügen',
    scanWithApp: 'Mit Authenticator-App scannen',
    scanAppTooltip:
      'Sie können Google Authenticator oder Microsoft Authenticator aus dem App Store Ihres Telefons herunterladen. Verwenden Sie dann die In-App-Kamera, um den bereitgestellten QR-Code zu scannen.',
    scanAppTooltipForChina:
      'Sie können Google Authenticator oder Microsoft Authenticator aus dem Telefonanwendungsstore herunterladen oder nach Google Authenticator im WeChat Mini-Programm suchen und das erste Ergebnis auswählen. Verwenden Sie dann die In-App-Kamera, um den bereitgestellten QR-Code zu scannen.',
    scanAuthenticatorAppTips: {
      tips1:
        'Sie können Google Authenticator oder Microsoft Authenticator aus dem mobilen App-Store herunterladen',
      tips2:
        'Alternativ können Sie auch im WeChat Mini-Programm nach Google Authenticator suchen und das erste Ergebnis auswählen.',
      tips3: 'Verwenden Sie dann die In-App-Kamera, um den bereitgestellten QR-Code zu scannen',
    },
    userGuide: 'Benutzerhandbuch',
    setupKey: 'Einrichtungsschlüssel',
    tnc: 'Durch das Verknüpfen einer Authentifizierungs-App bestätigen Sie, dass Sie die {link} gelesen und akzeptiert haben.',
    disclaimer: 'Haftungsausschluss',
    changePasswordSuccess: 'Passwort aktualisiert',
    changeEmailSuccess: 'E-Mail-Adresse aktualisiert',
    enableSecuritySuccess: 'Sicherheitsauthentifikator aktiviert',
    updateSecuritySuccess: 'Sicherheitsauthentifikator aktualisiert',
    changePasswordSuccessDesc:
      'Ihr Passwort wurde aktualisiert. <br/> Sie können jetzt ein neues Passwort verwenden, um sich anzumelden.',
    changeEmailSuccessDesc:
      'Ihre E-Mail-Adresse wurde aktualisiert. <br/> Sie können jetzt eine neue E-Mail-Adresse verwenden, um sich anzumelden.',
    enableSecuritySuccessDesc: 'Ihre Sicherheitsauthentifizierungs-App wurde aktiviert.',
    updateSecuritySuccessDesc: 'Ihre Sicherheitsauthentifizierungs-App wurde aktualisiert.',
    passwordReq: 'Passwortanforderungen:',
    passwordCharacters: '8-16 Zeichen',
    passwordMix: 'Eine Mischung aus Buchstaben (a-z und A-Z) und Zahlen (0-9)',
    passwordSpecial: 'Sonderzeichen wie !@#$%^&*().',
    confirmPwd: 'Passwort bestätigen',
    enableNow: 'Jetzt aktivieren',
    changeDesc:
      'Um die Sicherheit der Gelder auf Ihrem Konto zu gewährleisten, werden Auszahlungen T+ {numDays} Tage nach der Änderung Ihrer {method} nicht unterstützt.',
    verificationTitle: 'Verifizierung erforderlich',
    verificationDesc:
      'Bitte aktivieren Sie die Sicherheitsauthentifikator-App, bevor Sie fortfahren.',
    updateSuccess: 'Erfolgreich aktualisiert',
    verifiedSuccess: 'Erfolgreich verifiziert',
    verificationRequired: 'Bitte geben Sie den Bestätigungscode ein',
    next: 'Weiter',
  },
  reserPwMobile: { header: 'PASSWORT ZURÜCKSETZEN' },
  downloads: {
    appDesc: 'Laden Sie die {platform}-App im App Store und Google Play Store herunter',
    mt4: 'METATRADER 4',
    mt4Desc: 'Download MetaTrader 4 für PC, Webbrowser, Smartphones und Tablets',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android',
    web: 'WebTrader',
    webTrader: 'Web Trader',
    smartTrader: 'SmartTrader-Werkzeuge',
    mt5: 'METATRADER 5',
    mt5Desc: 'MetaTrader 5 für PC, Webbrowser, Smartphones und Tablets herunterladen',
    button: { download: 'DOWNLOADS', launch: 'STARTEN' },
  },
  forgetPwReq: {
    headerMobile: 'EINGABE IHRER ANMELDUNGS-TELEFONNUMMER',
    labelEmail: 'E-Mail',
    labelMobile: 'Telefonnummer',
    loginMobile: 'Anmeldungs-Telefonnummer',
    loginSMSCode: 'SMS-Code',
    loginSmsCodeExpires: 'SMS-Code läuft in 5 Minuten ab',
    getCode: 'SMS-Code senden',
    failedCode: 'SMS-Code konnte nicht an Ihr Telefon gesendet werden',
    sentAlready: 'Gesendet',
    expired: 'Der SMS-Code ist abgelaufen',
    tip: 'Geben Sie die E-Mail-Adresse Ihres Kontos ein, um Ihr Passwort zurückzusetzen.',
    formValidation: {
      emailReq: 'E-Mail für Anmeldung ist erforderlich',
      mobileReq: 'Mobiltelefonnummer für Anmeldung ist erforderlich',
      smsCodeReq: 'SMS-Code ist erforderlich',
    },
    succ: 'Wir haben Ihnen soeben eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts gesendet.<br><br>Bitte überprüfen Sie auch den Spam-Ordner Ihrer E-Mail.',
  },
  loginClient: {
    header: 'KUNDENPORTAL',
    failedMasseage: 'Versuchen Sie es erneut',
    existingClientPortalAccess:
      'Wie wir sehen, haben Sie bereits einen Kundenportalzugang.<br /><br />Loggen Sie sich mit Ihrer E-Mail ein, um ein neues Konto zu eröffnen!',
    existingClientPortalAccessMobile:
      'Wie wir sehen, haben Sie bereits einen Kundenportalzugang.<br /><br />Melden Sie sich mit Ihrer Telefonnummer an, um ein neues Konto zu eröffnen!',
    formValidation: {
      emailReq: 'E-Mail-Adresse ist erforderlich',
      mobileReq: 'Bitte nur numerische Werte eingeben',
    },
  },
  myProfile: {
    changeDetails:
      'Wenn Sie Ihre bestehenden Profildaten aktualisieren möchten, zögern Sie nicht, {mailTo} zu kontaktieren.',
  },
  openAdditAcc: {
    chooseTradePlat: 'WÄHLEN SIE EINE TRADING-PLATTFORM',
    chooseAccType: 'WÄHLEN SIE EINEN KONTOTYP',
    chooseCurrency: 'WÄHLEN SIE EINE KONTOWÄHRUNG',
    chooseLeverage: 'WÄHLEN SIE DIE HEBELWIRKUNG',
    chooseBalance: 'KONTOSTAND',
    notes: 'ZUSÄTZLICHE HINWEISE',
    currError: 'Sie müssen eine Kontowährung auswählen',
    platErr: 'Sie müssen eine Trading-Plattform auswählen',
    accTypeErr: 'Sie müssen einen Kontotyp wählen',
    leverageErr: 'Sie müssen eine Hebelwirkung wählen',
    balanceErr: 'Sie müssen einen Kontostand wählen',
    succ: '<p>Ihr zusätzliches Live-Konto wird gerade eingerichtet und Sie werden in Kürze eine E-Mail mit Ihren Zugangsdaten erhalten.</p><p>Sie können den aktuellen Status Ihres Antrags auf der Startseite einsehen.</p>',
    byTicking: 'Indem Sie dieses Kästchen ankreuzen:',
    swapFreeNote:
      '*Bitte beachten Sie: Anstelle von Swap-Gebühren kann eine kleine Verwaltungsgebühr anfallen.',
    pammNote:
      '*PAMM-Konten sind nur für Anleger mit einer bestehenden Vermögensverwaltervereinbarung. PAMM-Konten können von niemandem außer dem Vermögensverwalter gehandelt werden.',
  },
  openDemoAdditAcc: {
    header: 'EIN ZUSÄTZLICHES DEMO-KONTO ERÖFFNEN',
    button: 'Demo-Konto eröffnen',
    liveButton: 'Live-Konto eröffnen',
    succ: 'Ihr zusätzliches Demo-Konto wurde eingerichtet und Sie erhalten in Kürze eine E-Mail mit Ihren Zugangsdaten.',
  },
  resetPassword: {
    header: 'PASSWORT ZURÜCKSETZEN',
    succ: 'Ihr Passwort wurde aktualisiert. Sie können nun Ihr neues Passwort verwenden, um auf Ihr MT4/MT5-Tradingkonto zuzugreifen.',
    succ2: 'Ihr Passwort wurde aktualisiert.',
    failed: 'Token ist abgelaufen',
    emailNotFound: 'Leider wurde Ihre E-Mail-Adresse nicht erkannt',
  },
  fileUploader: {
    supportedFormat: 'Unterstützte Dateitypen: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Unterstützte Dateitypen: {files}',
    maxSize: 'Maximale Dateigröße beim Hochladen: {maxSize}MB',
    maxFileNum: 'Maximale Dateianzahl: {maxFileNum}',
    failedFileExtension: 'Bitte laden Sie den richtigen Dateityp hoch',
  },
  transcationHistory: {
    deposit: 'EINZAHLUNGEN',
    withdraw: 'AUSZAHLUNGEN',
    desc: {
      incomplete:
        'Wenn Sie den Einzahlungsvorgang abbrechen oder nicht abschließen können, wird der Transaktionsstatus als "unvollständig" angezeigt. Sie müssen dann eine neue Einzahlung erstellen, da diese Transaktion nicht wieder aufgenommen werden kann.',
      success:
        'Ihre Transaktion war erfolgreich. Ihr Guthaben sollte in Kürze auf Ihrem Konto erscheinen.',
      proc: 'Ihre Transaktion wird gerade bearbeitet. Bitte kontaktieren Sie {mailTo}.',
      failed:
        'Ihre Transaktion ist fehlgeschlagen. Bitte kontaktieren Sie {mailTo} für weitere Informationen.',
      rejected:
        'Ihre Transaktion wurde abgelehnt. Bitte kontaktieren Sie {mailTo} für weitere Informationen.',
    },
    withdrawDesc: {
      sub: 'Ihre Transaktion wurde abgeschickt. Bitte warten Sie bis zum Abschluss.',
      suc: 'Ihre Transaktion war erfolgreich. Ihr Guthaben sollte in Kürze auf Ihrem Konto erscheinen.',
      proc: 'Ihre Transaktion wird gerade bearbeitet. Bitte kontaktieren Sie {mailTo}.',
      incomp:
        'Ihre Transaktion erfordert zusätzliche Schritte, um abgeschlossen zu werden. Bitte kontaktieren Sie {mailTo} für weitere Informationen.',
      cancel:
        'Ihre Transaktion wurde storniert. Bitte kontaktieren Sie {mailTo} für weitere Informationen.',
      fail: 'Ihre Transaktion ist fehlgeschlagen. Bitte kontaktieren Sie {mailTo} für weitere Informationen.',
      rej: 'Ihre Transaktion wurde abgelehnt. Bitte kontaktieren Sie {mailTo} für weitere Informationen.',
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'Leider kann Ihr Transfer nicht bearbeitet werden, da er dazu führt, dass die verfügbare Margin Ihres Kontos unter das Stop-Out-Niveau von 100 % fällt.',
      equityMargin:
        'Leider können wir Ihren Transfer derzeit nicht bearbeiten, da Ihr Konto durch Hedges vollständig abgesichert ist.',
      equityContinue:
        'Wir haben festgestellt, dass Sie derzeit offene Positionen haben. Es kann sein, dass Sie nach dem Einreichen Ihres Transferantrags einen Stop-Out erleben. Bitte bestätigen Sie, wenn Sie fortfahren möchten.',
      blackList:
        'Dieses Konto wurde für Transfers zwischen Konten gesperrt. Bitte kontaktieren Sie {supportEmail}',
    },
    pleaseNote: 'Bitte beachten:',
    suc: 'Ihre Gelder wurden erfolgreich überwiesen.',
    warn1:
      'Sie sind dafür verantwortlich, einen Kontostand beizubehalten, der keinen Margin Call auslöst. Wenn Sie aktuelle offene Positionen haben, stellen Sie bitte sicher, dass Sie die erforderliche Margin auf Ihrem Konto beibehalten. Überweisungen von Geldern auf Konten, die in der gleichen Währung geführt werden, werden sofort bearbeitet.',
    warn2:
      'Wenn Sie Gelder zwischen Konten, die in unterschiedlichen Währungen geführt werden, überweisen möchten, senden Sie eine E-Mail an {mailTo}.',
    warn3:
      'Bitte beachten Sie, dass Gelder nicht zwischen schreibgeschützten Konten übertragen werden können.',
  },
  transferHistory: {
    payStatus: {
      processing: 'Bearbeitung',
      paid: 'Bezahlt',
      unpaid: 'Unbezahlt',
      rejected: 'Abgelehnt',
    },
  },
  identityProof: {
    home: {
      header: 'KONTOANTRAG UNVOLLSTÄNDIG',
      content:
        'Hallo {name}, Ihr Kontoantrag ist fast vollständig, aber Sie müssen noch zusätzliche Unterlagen einreichen, um Ihr Konto abzuschließen.',
    },
    shufti: {
      content:
        'Bitte stellen Sie sicher, dass die angegebenen Daten mit Ihren Ausweisdokumenten übereinstimmen und in der gleichen Sprache sind.',
      countryOfDocument: 'Land wie auf dem Dokument angegeben',
    },
    popup: {
      header:
        'Hallo {name}, bevor Ihr Live-Konto fertiggestellt werden kann, müssen Sie das folgende Dokument zur Verifizierung hochladen.',
      addressProofSpan:
        '<strong>\n        <b>Adresse bestätigen</b>\n        </strong>\n        <br />\n        <I>\n        Reichen Sie <b>eines</b> der folgenden Dokumente ein\n        <br /><br />\n        </I>\n        - Kopie einer aktuellen Nebenkostenabrechnung </br>(nicht älter als 6 Monate)\n        <b class=or">oder</b><br />\n        - Kopie eines aktuellen Kontoauszugs </br>(nicht älter als 6 Monate)\n        <br />\n        <br />\n        <b>WICHTIG !</b> Der Name auf dem Dokument MUSS deutlich Ihren vollständigen Namen und Ihre aktuelle Adresse zeigen.\n        <br />',
      idProofSpan:
        '<strong>\n      <b>Identität bestätigen</b>\n      </strong>\n      <br />\n      <I>Wählen\n        <b>Sie</b> der folgenden Optionen\n        <br />\n        <br />\n      </I>\n      - Kopie eines gültigen Reisepasses<br />\n\n      - Kopie eines gültigen Führerscheins\n      <br />\n      (Vorder- und Rückseite)\n      <br />\n      oder</b><br />\n      - Kopie des nationalen Personalausweises\n      <br />\n      <br />\n      <b>WICHTIG !</b> Der Name auf dem Dokument MUSS deutlich Ihren vollen Namen zeigen.<br />',
    },
    reason:
      'Ihre Unterlagen konnten nicht verifiziert werden,<br> Grund: {reason}. Bitte versuchen Sie es erneut.',
  },
  header: { secPortal: 'Sicherer Kundenbereich' },
  menu: {
    home: 'STARTSEITE',
    accManagement: 'KONTEN',
    liveAcc: 'LIVE-KONTEN',
    openAdditAcc: 'WEITERE KONTEN ERÖFFNEN',
    openAnAdditLiveAcc: 'EIN ZUSÄTZLICHES LIVE-KONTO ERÖFFNEN',
    homeDemo: 'DEMO-KONTEN',
    depositFund: 'GELDER EINZAHLEN',
    withdrawFunds: 'GELDER AUSZAHLEN',
    transferBetweenAccs: 'ÜBERWEISUNG ZWISCHEN KONTEN',
    payDetails: 'ZAHLUNGSDETAILS',
    funds: 'GELDER',
    transactionHis: 'TRANSAKTIONSHISTORIE',
    tranHis: 'ÜBERWEISUNGEN',
    download: 'DOWNLOADS',
    profile: 'PROFIL',
    myProfile: 'MEIN PROFIL',
    cpPassword: 'KUNDENPORTAL-PASSWORT ÄNDERN',
    contactUs: 'KONTAKT',
    helpSupport: 'HILFE & SUPPORT FORMULARE',
    register: 'ANTRAG FORTSETZEN',
    tradingTools: 'PRO TRADER TOOLS',
    MARKET_BUZZ: 'MARKET BUZZ ',
    ECON_CALENDAR: 'WIRTSCHAFTSKALENDER',
    FEAT_FX: 'AUSGEWÄHLTE IDEEN',
    FX_IDEAS: 'Technische Ansichten',
    ALPHA_GENERATION: 'ALPHA GENERATION',
    MARKET_MASTERS: 'MARKET MASTERS',
    marketWidgets: 'Markt Widgets',
    technicalAnalysis: 'TECHNISCHE ANALYSE',
    techAnalysisTab: {
      forex: 'FOREX',
      indices: 'INDIZES',
      commodities: 'ROHSTOFFE',
      sharedcfds: 'AKTIEN-CFDs',
      crypto: 'CRYPTO',
    },
    marketData: 'Marktdaten',
    forexCrossRates: 'Forex-Kreuzkurse',
    forexHeatMap: 'Forex-Heatmap',
    forexScreener: 'Forex-Screener',
    referAFriend: 'Werben Sie einen Freund',
    tradingView: 'CHARTS von TradingView',
    twoFactorAuthentication: 'ZWEI-FAKTOR-AUTHENTIFIZIERUNG',
    promotion: 'PROMOTION',
    depositBonus: '50 % Einzahlungsbonus',
    hmcTrader: 'Live-Trading-Raum',
    support: 'Support',
    securityManagement: 'Sicherheitsmanagement',
  },
  contact: {
    desc: 'Wenn Sie Fragen haben oder weitere Informationen wünschen, setzen Sie sich bitte über eine der unten stehenden Kontaktmöglichkeiten mit uns in Verbindung. Wir werden uns bemühen, alle Anfragen innerhalb von 24 Stunden zu beantworten.',
    tip: 'Für Kunden, die sich über {email} angemeldet haben',
    liveChat: 'Live-Chat',
    helpSupportForm: {
      partner: 'Formular zur Übertragung einer Partnerschaft',
      investigation: 'Moneta Trade-Untersuchungsformular',
      promotion: 'Formular zur Abmeldung von Moneta Promotions',
      request: 'Antragsformular für Kontoauflösung',
    },
    accountManager: 'Account Manager - {userName}',
    monetaSupport: 'Moneta Support',
  },
  depositResultDialog: { msg: 'Haben Sie Ihre Einzahlung erfolgreich abgeschlossen' },
  dialog: {
    identityProof: {
      success:
        'Vielen Dank! Wir haben Ihre Unterlagen erfolgreich erhalten und bearbeiten diese derzeit für die Verifizierung.',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: 'KONTOANTRAGSBEARBEITUNG',
      id: 'Ausweis ist',
      poa: 'Adresse ist',
      both: 'Ausweis und Adressnachweis sind',
      content:
        'Ihr Nachweis von {doc} wird derzeit bearbeitet und in Kürze fertiggestellt. Um die Bearbeitung Ihres Live-Kontos zu beschleunigen und eine bevorzugte Eröffnung zu erhalten, können Sie jetzt auf Ihr Konto einzahlen.',
    },
  },
  idPoa: {
    IdPoaNoPass: {
      header: 'Kontoeröffnungsantrag unvollständig ',
      content:
        'Um mit dem Trading zu beginnen, laden Sie bitte Ihr Ausweisdokument und Ihren Adressnachweis hoch. Zahlen Sie nach der Übermittlung Geld auf Ihr Konto ein für eine vorrangige Kontoeröffnung.',
    },
    onlyIdPass: {
      header: 'Vielen Dank!',
      content:
        'Ihr Antrag wird gerade bearbeitet. Zahlen Sie jetzt Geld auf Ihr Konto ein, um eine bevorzugte Kontoeröffnung zu erhalten.',
    },
    onlyPoaPass: {
      header: 'Kontoeröffnungsantrag unvollständig ',
      content:
        'Um mit dem Trading zu beginnen, laden Sie bitte Ihr Identitätsnachweisdokument hoch. Zahlen Sie nach der Übermittlung Geld auf Ihr Konto ein für eine vorrangige Kontoeröffnung.',
    },
    bothIdPoaPass: {
      header: 'Vielen Dank!',
      content:
        'Ihr Antrag wird gerade bearbeitet. Zahlen Sie jetzt Geld auf Ihr Konto ein, um eine bevorzugte Kontoeröffnung zu erhalten.',
    },
    uploadID: 'ID hochladen',
    uploadPoa: 'Adresse Hochladen',
    depositNow: 'Jetzt einzahlen',
  },
  register: {
    progressStatus: {
      title: 'Fortschritt der Verifizierung',
      estimatedTimeTitle: 'Geschätzte Zeit bis zum Abschluss:',
      min: 'Minuten',
    },
    tabs: {
      personalDetails: 'Persönliche Details',
      moreAboutYou: 'Mehr über Sie',
      accountConfig: 'Konto-Konfiguration',
      confirmYourId: 'Bestätigen Sie Ihre Identität',
      fundYourAcc: 'Auf Ihr Konto einzahlen',
    },
    btn: {
      next: 'WEITER',
      back: 'ZURÜCK',
      fundnow: 'JETZT EINZAHLEN',
      upload: 'Hochladen',
      done: 'FERTIG',
      uploadNew: 'Neu hochladen',
    },
    authority: {
      dialog1:
        'Sie müssen ein Live-Konto haben, um auf diesen Bereich des Kundenportals zugreifen zu können.',
      dialog2:
        'Eröffnen Sie jetzt ein Live-Konto, um den vollen Zugang zu Ihrem Kundenportal freizuschalten, das eine Reihe von Trading-Werkzeugen, Promotionen und vieles mehr enthält.',
      openLiveAcc: 'Live-Konto eröffnen',
    },
    demo: {
      congrad: 'Herzlichen Glückwunsch!',
      title: 'Ihr 30-tägiges MT{platform} DEMO-Konto ist jetzt aktiv',
      title2: 'Details zu Ihrem DEMO-Konto',
      li: {
        login: 'Anmeldung: ',
        password: 'Passwort: ',
        srv: 'Server: ',
        expDate: 'Verfallsdatum: ',
      },
      emailSent: '* Die Anmeldedaten wurden auch an Ihre registrierte E-Mail gesendet.',
      dl: 'Laden Sie Ihre MT{platform} Trading-Plattform herunter',
      proTraderPlatform: {
        content:
          'Loggen Sie sich mit Ihren MT{platform}-Kontodaten in unsere zukunftsweisende PRO Trader-Plattform ein:',
      },
      appTrader: {
        content:
          'Loggen Sie sich mit Ihrem Kundenportal-Login in den Moneta Markets AppTrader ein. Holen Sie sich jetzt den AppTrader für iOS oder Android!',
      },
      improveQ: 'Möchten Sie Ihr Trading verbessern?',
      improveWay:
        'Eröffnen Sie einfach ein Live-Konto in nur wenigen Minuten, um eine Reihe von exklusiven Trading-Werkzeugen und Promotionen freizuschalten.',
      proTrade: 'PRO-TRADER-WERKZEUGE',
      depositBonus: '50 % Einzahlungsbonus',
      smartTradeTool: 'Intelligente Trader-Werkzeuge',
      openLiveAcc: 'Live-Konto eröffnen',
      poweredBy: 'Bereitgestellt von',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Persönliche Details',
        userTitle: 'Titel',
        firstName: 'Vorname',
        lastName: 'Nachname',
        email: 'E-Mail',
        dob: 'Geburtsdatum',
        idType: 'Identifikations-Typ',
        referral: 'Wurden Sie von jemandem empfohlen? (optional)',
        middleName: 'Mittlerer Name',
        nationality: 'Nationalität',
        phoneNum: 'Telefonnummer',
        date: 'Tag',
        month: 'Monat',
        year: 'Jahr',
        placeOfBirth: 'Geburtsort',
        idNum: 'Identifikationsnummer',
        passportNum: 'Reisepass-Nummer',
        driverLicenseNum: 'Führerscheinnummer',
        titleDropdown: {
          mr: 'Herr',
          mrs: 'Frau',
          ms: 'Fräulein',
          miss: 'Fräulein',
          dr: 'Dr.',
          prof: 'Prof.',
        },
        idTypeDropdown: {
          passport: 'Reisepass',
          idCard: 'Nationale ID-Karte',
          driverLicense: 'Führerschein',
        },
      },
      page2: {
        pageTitle: 'HAUPTWOHNSITZADRESSE',
        pageTitleForFCA: 'ZUSÄTZLICHE ANGABEN',
        mainResidency: 'Hauptwohnsitzland',
        streetNumber: 'Hausnummer',
        streetName: 'Straßenname',
        address: 'Adresse',
        provinceOrState: 'Provinz / Bundesland',
        cityOrSuburb: 'Stadt / Vorort',
        postcode: 'Postleitzahl',
        previousCountry: 'Vorheriges Land',
        usQuestion:
          'Sind Sie aus steuerlichen Gründen US-Bürger oder haben Sie irgendwelche Verbindungen zu den USA (z.B. Bankkonto, etc.)?',
        taxResidencyCountry: 'Land/Länder des Steuerwohnsitzes',
        nationalInsuranceNumber: 'Nationale Versicherungsnummer (NV)',
        yearsAtAddress: 'Anzahl der Jahre an der Adresse',
        previousResidentialAddressTip: 'Bitte geben Sie Ihre vorherige Wohnadresse an',
      },
    },
    moreAboutYou: {
      pageTitle1: 'BESCHÄFTIGUNG UND FINANZIELLE ANGABEN',
      pageTitle2: 'Trading-Kenntnisse und Erfahrungen',
      complianceExplain:
        'Die folgenden Fragen werden zur Einhaltung der AML/CTF-Anforderungen erhoben.',
      FCAQuestion10Tip:
        'Wie viele Trades haben Sie in den letzten 12 Monaten im Durchschnitt pro Quartal mit den folgenden Instrumenten ausgeführt?',
    },
    accountConfig: {
      pageTitle: 'Konto-Konfiguration',
      chooseTradingPlatform: 'Trading-Plattform wählen',
      chooseAccType: 'Kontotyp wählen',
      chooseAccCurrency: 'Kontowährung wählen',
    },
    confirmYourId: {
      pageTitle: 'Bestätigen Sie Ihre Identität',
      description:
        'Als regulierter Forex-Broker sind wir verpflichtet, Ihre Identität zu verifizieren. Bitte laden Sie eines der folgenden Dokumente zur Verifizierung Ihres Identitäts- und Adressnachweises hoch. Alternativ können Sie Ihre Verifizierungsdokumente auch per E-Mail an {mailTo} senden',
      poidTitle: 'Identitätsnachweis',
      porTitle: 'Wohnsitznachweis',
      uploadDescription: 'Wählen Sie einen Dokumententyp und laden Sie ihn hoch',
      note: 'WICHTIG: Der Name auf dem Dokument MUSS deutlich Ihren vollen Namen zeigen.',
      pending: { upload: 'Ausstehender Upload', verification: 'Ausstehende Verifizierung' },
      poidTypes: {
        photoId: 'Lichtbildausweis',
        passport: 'Eine Kopie Ihres internationalen Reisepasses (Unterschriftsseite)',
        drivingLicence: 'Führerschein',
      },
      porTypes: '<li>Nebenkostenabrechnung</li><li>Kontoauszug</li><li>Wohnsitzbescheinigung</li>',
    },
    finishPage: {
      title:
        '{individualUserName}, vielen Dank, dass Sie ein Live-Konto bei {platformName} beantragt haben!',
      contentDefault:
        'Wir haben Ihren Antrag erhalten und Ihr Live-Konto <b>{account}</b> eingerichtet. <br />\n      In Kürze erhalten Sie eine E-Mail an <b>{email}</b> mit Ihren Anmeldedaten und einem Link, um den Status Ihres Kontos einzusehen. <br />',
      contentWithoutId3WithAccount:
        'Ihr Live-Trading-Konto wird derzeit bearbeitet und Sie werden in Kürze eine E-Mail mit weiteren Informationen erhalten.<br />\n      Um die Bearbeitung Ihres Live-Kontos zu beschleunigen, können Sie jetzt auf Ihr Konto einzahlen und erhalten eine bevorzugte Eröffnung.<br />',
      contentWithoutId3WithoutAccount:
        'Ihr Live-Trading-Konto wird derzeit bearbeitet und Sie werden in Kürze eine E-Mail mit weiteren Informationen erhalten.<br />',
    },
  },
  tradingTools: {
    MARKET_BUZZ:
      'Greifen Sie auf Nachrichten und Medieninhalte für mehr als 35.000 handelbare Vermögenswerte zu.',
    FEAT_FX:
      'Sehen Sie sich umsetzbare technische Tradingsetups an und verfolgen Sie deren Entwicklung in Echtzeit.',
    ECON_CALENDAR: 'Genießen Sie rund um die Uhr Wirtschaftsdaten in Echtzeit aus der ganzen Welt.',
    FX_IDEAS:
      'Erhalten Sie interessante und lehrreiche Einblicke in den technischen Handel für 80.000 Instrumente.',
    ALPHA_GENERATION:
      'Verleihen Sie Ihrem Tradingvorteil einen Schub und nutzen Sie 3 fortschrittliche MT4- und MT5-Indikatoren der nächsten Generation.',
    MARKET_MASTERS:
      'Lernen Sie in über 100 fortgeschrittenen Video-Trading-Tutorials zu traden wie die Profits.',
    condition: {
      needDeposit:
        'Um Zugang zu Ihren PRO Trader Tools und exklusiven Video-Trading-Tutorials zu erhalten, zahlen Sie einfach $ 500 oder mehr auf Ihr Live-Konto ein.',
      congratulations:
        'Herzlichen Glückwunsch! Sie sind bereit, Ihre Premium Trader Tools freizuschalten!',
      needTnc: 'Bitte stimmen Sie den{link}zu.',
      link: 'Teilnahmebedingungen',
      unlock: 'SCHALTEN SIE DIE PRO TRADER TOOLS FREI',
      depositNow: 'JETZT EINZAHLEN',
      tncAisc: 'Ich bin damit einverstanden, Zugang zu diesen Tools zu erhalten.',
    },
    techAna: 'Technische Analyse für',
    byTradingView: 'durch TradingView',
    alphaGeneration: {
      intro: 'ALPHA GENERATION FÜR MT4 UND MT5',
      introDesc:
        'Verschaffen Sie sich einen Vorteil beim Trading mit drei Premium-EAs, die es exklusiv bei Moneta Markets gibt.',
      analystViews: {
        title: 'Technische Ansichten',
        desc: 'Sofortige Anzeige der technischen Kursniveaus und Trends in Ihrem Kurschart.',
      },
      adaptiveCandlesticks: {
        title: 'ADAPTIVE CANDLESTICKS',
        desc: 'Echtzeit-Scan von 16 beliebten Candlestick-Mustern.',
      },
      adc: {
        title: 'ADC',
        desc: 'Kurzfristige Trading-Signale für den Ein- und Ausstieg sowohl in Long- als auch in Short-Richtung.',
      },
      install: 'WIE KANN ICH ALPHA GENERATION INSTALLIEREN?',
      installSteps: {
        one: 'Laden Sie Alpha Generation für PC herunter, indem Sie unten auf die Schaltfläche „Alpha Generation herunterladen“ klicken.',
        two: 'Klicken Sie doppelt auf die Datei, um sie auszuführen, und folgen Sie dem Installationsassistenten, der Sie in der Sprache Ihrer Wahl durch den Prozess führt.',
        three: 'Wählen Sie Ihren Moneta Markets MT4- oder MT5-Zielordner.',
        four: 'Nach der Installation öffnen Sie Ihre MT4- oder MT5-Plattform und suchen die Trading Central-Indikatoren zusammen mit den anderen in Ihrer MetaTrader-Indikatorenliste.',
        five: 'Ziehen Sie einen Indikator auf das Chart und erhalten Sie sofort verwertbare Erkenntnisse für Ihr Trading.',
      },
      button: { guid: 'Benutzerhandbuch', download: 'Alpha Generation herunterladen' },
    },
  },
  tradingView: {
    launch: 'STARTEN',
    tnc: 'Melden Sie sich mit Ihren MT {mtServer} -Anmeldedaten bei der Charting- und Handelsplattform TradingView an. Erfahren Sie mehr über CHARTS von TradingView <a href="https://{regulatorUrl}/platforms/trading-platforms/charts-by-tradingview/">Hier</a>.',
    subTitle:
      'Schließen Sie sich 15 Millionen anderen Investoren an und handeln Sie mit den Diagrammen, Indikatoren und Zeichentools von TradingView.',
    intro:
      'Einführung in CHARTS von TradingView. Moneta-Kunden können jetzt über unsere integrierte TradingView-Plattform CHARTS Handelsideen finden, Trends erkennen und Live-Trades platzieren.',
    descp1: 'Über 100 technische Indikatoren',
    descp2: 'Live-Marktnachrichten-Updates',
    descp3: 'Über 50 intelligente Zeichenwerkzeuge',
    descp4: 'Multi-Timeframe-Analyse',
    descp5: '12 verschiedene Handelsalarmbedingungen',
    descp6: 'Mehrere Zeitrahmen',
    descp7: '50+ intelligente Zeichenwerkzeuge',
    descp8: '20+ Chart-Layouts',
    descp9: '12+ anpassbare Chart-Typen',
    descp10: 'Integrierte Marktberichterstattung',
    learnMore: 'Erfahren Sie mehr',
    tnc02:
      'Nutzen Sie Ihre MT{mtServer}-Login-Daten und erleben Sie jetzt die Protrader-Webplattform. {findMore}',
    tnc03: 'Erfahren Sie mehr',
    subTitle02:
      'Benutzer der Moneta Protrader-Plattform haben jetzt Zugang zu Live-Kurs-Charts, technischen Indikatoren und Zeichenwerkzeugen, die alle von TradingView für über 300 FX-Paare, Indizes, Rohstoffe, Aktien-CFDs und mehr unterstützt werden.',
    intro02: 'Erhalten Sie Zugang zu:',
    intro03: 'Powered by',
  },
  TFAuth: {
    title: 'ZWEI-FAKTOR-AUTHENTIFIZIERUNG',
    enable: {
      enableTitle: 'AKTIVIEREN SIE DIE ZWEI-FAKTOR-AUTHENTIFIZIERUNG',
      enableDesc:
        'Halten Sie Ihr Konto sicher, indem Sie die mobile Zwei-Faktor-Authentifizierung aktivieren. Die Zwei-Faktor-Authentifizierung bietet eine zusätzliche Sicherheitsebene und bringt Ihnen Gewissheit durch einen zweiten Verifizierungsschritt bei jedem Login. Sobald die Zwei-Faktor-Authentifizierung aktiviert ist, können Sie jederzeit einen Zwei-Faktor-Verifizierungscode von einer App auf Ihrem iOS- oder Android-Telefon abrufen, ihn übermitteln und sich sicher einloggen.',
      enableSubtitle: 'So aktivieren Sie die Zwei-Faktor-Authentifizierung',
      step1:
        'Laden Sie Google Authenticator kostenlos aus dem iOS- oder Android-App-Store herunter und installieren Sie ihn.',
      step2:
        'Wählen Sie in der App die Option „Barcode scannen“ und scannen Sie den Barcode unten.',
      step3:
        'Geben Sie den unten stehenden Zahlencode in der App ein (wird alle 30 Sekunden aktualisiert).',
      placeHolder: 'code',
    },
    disable: {
      disableTitle: 'ZWEI-FAKTOR-AUTHENTIFIZIERUNG DEAKTIVIEREN',
      disableDesc:
        'Um die Zwei-Faktor-Authentifizierung zu deaktivieren, geben Sie hier den Zahlencode aus Ihrer Google Authenticator-App ein.',
    },
    dialog: {
      title: 'Bitte geben Sie Ihren Code für die Zwei-Faktor-Authentifizierung ein Code',
      contact: 'Haben Sie Probleme beim Einloggen? {link}.',
      link: 'Kontaktieren Sie uns.',
    },
    popup: {
      popup1: 'Die Zwei-Faktor-Authentifizierung ist aktiviert.',
      popup2: 'Die Zwei-Faktor-Authentifizierung ist deaktiviert.',
    },
  },
  promotion: {
    depositBonus: {
      depositBonus: 'Zahlen Sie ein und nehmen Sie Ihren Trading-Bonus in Anspruch',
      title: 'Holen Sie sich Ihren Bonus & verleihen Sie Ihrer Einzahlung einen Schub',
      subtitle1:
        'Wollen Sie mehr Trading-Power? Sie müssen nur Geld auf Ihr Live-Konto einzahlen. Sobald wir Ihre Einzahlung erhalten haben, wird Ihr Einzahlungsbonus auf Ihrem Handelskonto erscheinen.',
      fund: 'Zahlen Sie Geld auf Ihr Konto ein',
      tnc: '*{link} Geschäftsbedingungen.',
      link: 'Es gelten die Allgemeinen',
      bonusHistory: 'Bonus-Verlauf',
      overBonus:
        '* Sie haben das Maximum von ${totalLimit} Bonusguthaben erreicht. Zusätzliche Einzahlungen qualifizieren sich nicht mehr für Bonusgutschriften.',
      opt: 'Möchten Sie sich anmelden, um Ihren Einzahlungsbonus zu erhalten?',
      tick: 'Bitte markieren Sie das Kästchen, wenn Sie an der Promotion für den Einzahlungsbonus teilnehmen möchten.',
      tick2:
        'Moneta behält sich das Recht vor, einen Kunden von der Teilnahme an seinen Angeboten oder Promotionen auszuschließen und/oder zu stornieren, wenn:<br><br> a) Churning (Öffnen und Schließen von Margin-FX- oder CFD-Transaktionen nur zum Zwecke der Erzielung eines Gewinns);<br> b) festgestellt wird, dass der Kunde gegen seine Kundenvereinbarung verstößt oder gegen die Promotionsbedingungen verstoßen hat.<br><br> Im Falle eines solchen Fehlverhaltens oder eines Verstoßes hat Moneta das Recht, alle Bonusbeträge und Gewinne von dem Handelskonto abzuziehen.\n      <br><br>Wenn das Unternehmen den Verdacht hat oder Grund zu der Annahme hat, dass ein Kunde die Bedingungen dieses Bonusprogramms missbraucht und/oder manipuliert hat, indem er seine Positionen intern (unter Verwendung anderer Handelskonten beim Unternehmen) oder extern (unter Verwendung anderer Handelskonten bei anderen Brokern) abgesichert hat und/oder nicht in gutem Glauben gehandelt hat, behält sich das Unternehmen das Recht vor, nach eigenem Ermessen und ohne Einholung der Zustimmung des Kunden den Bonusbetrag und den erzielten Gewinn mit sofortiger Wirkung vom Handelskonto zu entfernen.\n      <br><br>Moneta kann jederzeit Änderungen an diesen Bedingungen vornehmen und wird Sie über diese Änderungen durch Veröffentlichung der geänderten Bedingungen auf der Website von Moneta informieren. Wir empfehlen Ihnen, diese Bedingungen regelmäßig zu überprüfen. Durch Ihre fortgesetzte Nutzung der Moneta-Website und -Dienste bestätigen Sie, dass Sie solche geänderten Bedingungen akzeptieren. Moneta behält sich das Recht vor, Angebote nach eigenem Ermessen jederzeit zu ändern oder zu stornieren.',
      saveChange: 'Änderungen speichern',
      creditReceived: 'Erhaltenes Guthaben',
      welcomeBonusTable: 'Willkommensbonus',
      depositBonusTable: 'Einzahlungsbonus',
      depositDate: 'Einzahlungsdatum',
      credit: 'Guthaben',
      optIn: 'Anmeldung erfolgreich',
      optOut: 'Abmeldung erfolgreich',
      notification:
        '<div>Sie sind berechtigt, an der Einzahlungsbonus-Kampagne teilzunehmen. Bitte <a href="{depositBonusLink}">melden</a> Sie sich vor der Einzahlung dafür an, um den Einzahlungsbonus zu erhalten. Weitere Informationen finden Sie <a href="{tncLink}" target="_blank">hier</a>.</div>',
      optInButton: 'melden',
      dontShowAgain: 'Diese Nachricht nicht mehr anzeigen',
    },
    referAFriend: {
      congratulations:
        'Herzlichen Glückwunsch! Sie haben soeben erfolgreich an der Freundschaftswerbungsaktion als Schiedsrichter teilgenommen.',
      redeem: 'Einlösen',
      redeemed: 'Eingelöst',
      dayRemain: 'verbleibende Tage',
      voucherDesc: 'Der Gutschein kann eingelöst werden, sobald Sie ≥ {lots} Lots getradet haben.',
      title:
        'Werben Sie einen Freund und verdienen Sie bis zu ${amount} und ${secAmount} für Ihren Freund!',
      desc: 'Wenn Ihre Empfehlung innerhalb von 30 Tagen die folgenden Schritte ausführt, erhalten Sie bis zu ${amount}! Und Sie können unbegrenzt viele Freunde werben! *<a href="{tncLink}">Es gelten die Allgemeinen</a> Geschäftsbedingungen.',
      register: 'Registrieren Sie sich bei uns',
      forYourFriend: 'für Ihren Freund',
      liveAcc: 'Eröffnen Sie ein Live-Konto',
      makeDeposit: 'Nehmen Sie eine Einzahlung vor ',
      trade1Lot: 'Traden Sie 1 Lot',
      trade5Lots: 'Traden Sie 5 Lots ',
      totalEarning: 'Gesamtverdienst',
      referralAct: 'Empfehlungsaktivität ',
      referralName: 'Empfehlung Name ',
      registered: 'Registriert',
      accOpen: 'Konto eröffnet',
      madeDeposit: 'Einzahlung vorgenommen',
      redeemStatus: 'Einlösestatus',
      notApplicable: 'Nicht zutreffend',
      country: 'Land',
      acc: 'KONTO',
      share: 'AKTIE',
      copyLink: 'Link kopieren',
      redeemYourbonus: 'Lösen Sie Ihren Bonus ein',
      tatolAmtConvert:
        'Der in Ihre Basiswährung umgerechnete Gesamtbetrag ist {currency} {convertAmt}',
      remark:
        '*Indem Sie auf „Jetzt einlösen“ klicken, erklären Sie sich mit den Allgemeinen Geschäftsbedingungen einverstanden.',
      redeemNow: 'Jetzt einlösen',
      overlimit: 'Über Limit',
      newUser: 'Neuer Benutzer',
      tradeCash: 'Traden Sie Bargeld',
    },
    cashback: {
      button: {
        applyForBonus: 'BONUS BEANTRAGEN',
        redeemCashback: 'CASHBACK EINLÖSEN',
        howItWorks: 'WIE FUNKTIONIERT ES?',
        depositNow: 'Jetzt einzahlen',
        stp: 'DIRECT STP',
        ecn: 'PRIME ECN',
        agree: 'ZUSTIMMEN',
        cancel: 'ABBRECHEN',
      },
      title: {
        monetaProgram: 'MONETA MARKETS CASHBACK-PROGRAMM',
        claim: 'SICHERN SIE SICH JETZT IHREN 50 % CASHBACK-BONUS!',
        howItWorks: 'UND SO FUNKTIONIERT ES',
        howMuch: 'WIE VIEL VERDIENEN SIE AN DEN TRADES?',
        treeSimpleSteps: 'SICHERN SIE SICH JETZT IHREN 50 % BONUS – IN DREI EINFACHEN SCHRITTEN:',
        depositTradEarn: 'EINZAHLEN. TRADEN. VERDIENEN!',
        bonusSummary: 'Ihre Bonusübersicht',
        historicalEarnings: 'IHRE AKTUELLEN & HISTORISCHEN VERGÜTUNGEN',
        program: 'CASHBACK-PROGRAMM',
        cashSummary: 'CASHBACK-ÜBERSICHT',
        faqs: 'CASHBACK-BONUS FAQ',
        redeemCashback: 'CASHBACK EINLÖSEN',
        cashbackBalance: 'Cashback-Guthaben',
      },
      subTitle: {
        lowerYourCost: 'Reduzieren Sie Ihre Kosten. Verdienen Sie Bargeld.',
        bonus: 'BONUS',
        cashback: 'CASHBACK',
        wallet: 'WALLET',
        availableBonus: 'Verfügbarer Bonus',
        totalBalance: 'Gesamtguthaben',
        step1: 'Zahlen Sie Geld ein und stellen Sie einen Antrag!',
        step2: 'Beginnen Sie mit dem Trading!',
        step3: 'Beantragen Sie Ihren Cashback',
        FX: 'FOREX',
        XAU: 'GOLD',
        OIL: 'ÖL',
        perLot: 'pro Lot',
        seetheBonusStatus: 'In der Spalte BONUS-STATUS können Sie alle nutzbaren Boni BEANTRAGEN.',
        tradeFxOilAndGold: 'Traden Sie FX, Öl und Gold und wandeln Sie Ihren BONUS in CASHBACK um!',
        totalCashback: 'Cashback gesamt',
        FebruaryCashback: 'Ausstehender Cashback',
        cashbackRedeemable: 'Einlösbarer Cashback',
        account: 'Konto',
        amount: 'Betrag',
      },
      information: {
        availableBonus1:
          'Der verfügbare Bonus ist die Summe der Bonusgutschriften, die Sie für alle anrechnungsfähigen Einzahlungen erhalten haben. Für jede Einzahlung von $ 500 oder mehr erhalten Sie 50 % dieses Betrags als Bonusgutschrift.',
        total:
          'Das Gesamtguthaben ist die Summe aus dem einlösbaren Cashback und dem Cashback-Betrag des aktuellen Monats.',
        availableBonus2:
          'Ihr verfügbarer Bonus ist die Anzahl der Bonusgutschriften aus allen berechtigten Einzahlungen, die noch nicht umgewandelt und auf Ihre Cashback-Wallet übertragen wurden.',
        totalCashback:
          'Der Gesamt-Cashback ist die Anzahl der Bonusgutschriften, die in Bargeld umgewandelt wurden.',
        februaryCashback:
          'Die Bonusgutschriften des aktuellen Monats, die in Bargeld umgewandelt wurden. Sie können diese zu Beginn des nächsten Monats auf Ihr Handelskonto überweisen.',
        cashbackRedeemable:
          'Die Anzahl der Guthaben, die bis zum Ende des letzten Monats in Bargeld umgewandelt wurden. Sie können diesen Betrag jederzeit auf Ihr Handelskonto übertragen.',
        selectTheTradingAccount:
          ' Wählen Sie das Handelskonto, auf das Sie Ihren Cashback überweisen möchten.',
        enterTheAmount: 'Der Betrag (USD), den Sie auf Ihr Handelskonto überweisen möchten.',
      },
      content: {
        claimContent1:
          'Wann immer Sie jetzt $ 500 oder mehr auf Ihr Live-Konto einzahlen, erhalten Sie einen 50 % Bonus, der in der Bonus-Wallet auf der Startseite Ihres Kundenportals erscheint! Wenn Sie dann mit FX, Gold oder Öl handeln, werden Ihre Bonusgutschriften in echtes Geld umgewandelt und in der Cashback-Wallet angezeigt! Zu Beginn eines jeden Monats können Sie das Guthaben in Ihrer Cashback-Wallet auf Ihre Live-Handelskonten übertragen und als echtes Geld verwenden!',
        claimContent2:
          'Und das Beste ist: bei jeder weiteren Einzahlung von $ 500 oder mehr wird Ihr Bonus automatisch Ihrem Bonuskonto gutgeschrieben, so dass Sie noch mehr Geld verdienen können!',
        howItWorksContent1:
          'Zahlen Sie $ 500 oder mehr auf Ihr Live-Konto ein, klicken Sie dann auf „Beantragen“ und stimmen Sie den Geschäftsbedingungen zu, um Ihren 50 % Bonus zu erhalten',
        howItWorksContent2:
          'Wenn Sie mit FX, Gold oder Öl handeln, werden Ihre Bonusgutschriften automatisch in echtes, handelbares Bargeld umgewandelt, das Sie in Ihrer „Cashback-Wallet“ verfolgen können!',
        howItWorksContent3:
          'Klicken Sie zu Beginn jedes neuen Monats einfach auf „Cashback einlösen“ in Ihrer „Cashback-Wallet“ und lösen Sie den Betrag ein, der als „Cashback einlösbar“ angezeigt wird.',
        stepsContent1: 'Zahlen Sie $ 500 oder mehr ein und beantragen Sie Ihren Bonus unten',
        stepsContent2:
          'Fangen Sie an, mit FX, Gold und Öl zu handeln und wandeln Sie Ihren Bonus in echtes Geld um!',
        stepsContent3:
          'Beantragen Sie Ihren Cashback für die Tradingaktivitäten des vergangenen Monats!',
        depositTradEarnContent1: 'Je mehr Sie traden, desto mehr verdienen Sie.',
        depositTradEarnContent2:
          'Wenn Sie eine Einzahlung von $ 500 oder mehr tätigen, können Sie einen 50 % Einzahlungsbonus erhalten!',
        optInContent1:
          'Um sich für die Cashback-Promotion von Moneta Markets anzumelden, müssen Sie den nachstehenden Bedingungen zustimmen.',
        electTheDestinationAccount: 'Wählen Sie das Zielkonto für die Einlösung',
      },
      column: {
        date: 'DATUM',
        deposit: 'EINZAHLUNGEN',
        withdraws: 'AUSZAHLUNGEN',
        bonus: 'BEANSPRUCHBARER BONUS',
        status: 'BONUS-STATUS',
        account: 'KONTO',
        redeem: 'CAHSBACK EINLÖSEN',
        reason: 'HINWEISE',
      },
      tnc: {
        title: 'Allgemeine Geschäftsbedingungen',
        content: {
          item1: {
            title: 'Cashback-Teilnahmebedingungen',
            li1: 'Moneta Markets ist ein Handelsname der Moneta Markets Ltd., eingetragen im Saint Lucia Registry of International Business Companies unter der Registernummer 2023-00068.',
            li2: 'Die „Cashback-Bonus-Teilnahmebedingungen“ sind ein integraler Bestandteil der „Allgemeinen Geschäftsbedingungen von Moneta Markets Ltd.“, und die hierin enthaltenen Bestimmungen gelten als Anhang zu den „Allgemeinen Geschäftsbedingungen von Moneta Markets Ltd.Moneta Markets Ltd.“ und sollten in Verbindung damit verstanden werden.',
          },
          item2: {
            title: 'Bedingungen für eine Teilnahme. ',
            li1: 'Vorbehaltlich und unbeschadet aller anderen Geschäftsbedingungen steht das Angebot den Kunden von Moneta Markets zur Verfügung, die die in den folgenden Abschnitten dargelegten Zulassungskriterien für das Angebot erfüllen („teilnahmeberechtigte Kunden“).',
            li2: 'Moneta Markets bietet den „Cashback-Bonus“ für Kunden an, die ein Live-Handelskonto (kein Demo-Konto) bei Moneta Markets eröffnen.',
            li3: 'Der „Cashback-Bonus“ ist nur für Direct STP- und Prime ECN-Konten verfügbar. PAMM, MAM, Ultra ECN- und „Cent“-Konten sind von der Teilnahme an der „Cashback-Bonus“-Aktion ausgeschlossen. ',
            li4: 'Moneta Markets bietet den „Cashback-Bonus“ nach eigenem Ermessen jedem Kunden aus jedem Land/Region an, wie und so lange es Moneta Markets für angemessen hält. Das Unternehmen behält sich das Recht vor, die Teilnahmeberechtigung aufgrund lokaler Beschränkungen und/oder Anforderungen zurückzuziehen und/oder zu beenden.',
            li5: 'Am "Cashback-Bonus" können nur Personen teilnehmen, die nach dem Recht ihres Wohnsitzlandes rechtsverbindliche Verträge abschließen können. Ohne Einschränkung des Vorstehenden ist die Teilnahme am "Cashback-Bonus" Personen unter 18 Jahren nicht gestattet, da diese nicht berechtigt sind, ein Konto beim Unternehmen zu eröffnen.',
            li6: 'Die Teilnahme von „Vermittlern/Verbundenen Parteien“ am "Cashback-Bonus" ist untersagt. Wenn die Registrierungs- und/oder Tradingdaten eines Teilnehmers am "Cashback-Bonus" mit den Registrierungs- und/oder Tradingdaten, einschließlich, aber nicht beschränkt auf, der IP-Adresse eines anderen Teilnehmers, am „Cashback-Bonus“ übereinstimmen, behält sich Moneta Markets das Recht vor, diese Übereinstimmung als Grund für eine sofortige Disqualifizierung zu betrachten. Für die Zwecke dieser Teilnahmebedingungen bezeichnet der Begriff "Vermittler/verbundene Partei(en)", wenn er in diesen Geschäftsbedingungen verwendet wird, sofern der Kontext nichts anderes erfordert, jede natürliche oder juristische Person, die in einer Beziehung zu einem Teilnehmer am „Cashback-Bonus“ steht, einschließlich, aber nicht beschränkt auf:',
            li6_sub_items: {
              li1: 'Familienangehörige wie Geschwister, Ehepartner, Vorfahren, direkte und indirekte Nachfahren;',
              li2: 'eine natürliche oder juristische Person, die ein Teilnehmer am „Cashback-Bonus“ direkt oder indirekt über einen oder mehrere Vermittler kontrolliert oder die direkt oder indirekt über einen oder mehrere Vermittler von einem Teilnehmer am „Cashback-Bonus“ kontrolliert wird oder unter gemeinsamer Kontrolle mit einem Teilnehmer am „Cashback-Bonus“ steht; Für die Zwecke dieser Definition bedeutet der Begriff „Kontrolle“ (einschließlich der Ausdrücke „kontrolliert von“ und „unter gemeinsamer Kontrolle mit“) in Bezug auf einen Teilnehmer am „Cashback-Bonus“, dass er direkt oder indirekt über einen oder mehrere Vermittler die Macht hat, die Geschäftspolitik dieser Person oder Einheit zu lenken oder zu bestimmen, sei es durch den Besitz von Stimmrechtsanteilen oder auf andere Weise.',
              li3: 'Ebenso sind Personen, die in irgendeiner Weise mit Moneta Markets und/oder den spezifischen Websites und/oder sozialen Netzwerken in Verbindung stehen, auf denen Moneta Markets von Zeit zu Zeit bestimmte Werbeaktionen, Wettbewerbe und/oder Umfragen durchführt, in deren Rahmen der Zugang zum „Cashback-Bonus“ angeboten wird, von der Teilnahme am Angebot ausgeschlossen.',
            },
          },
          item3: {
            title: 'Besondere Bedingungen für den "Cashback-Bonus"',
            li1: ' Kunden müssen zunächst eine Mindesteinzahlung von $ 500 vornehmen und dann erfolgreich einen 50 % Bonus über ihr Kundenportal beantragen, um Bonusgutschriften zu erhalten. Bonusgutschriften aus nachfolgenden Einzahlungen werden automatisch gewährt.',
            li1_sub_desc:
              '*Nur Einzahlungen, die während des Aktionszeitraums getätigt werden, sind gültig und werden für die Aktion berücksichtigt. Interne Geldtransfers, Boni für Freundschaftswerbungen und Bargeldanpassungen sind davon ausgenommen.',
            li2: 'Der „Cashback-Bonus“ ist nur für Direct STP- und Prime ECN-Konten verfügbar. Ultra ECN- und „Cent“-Konten sind von der Teilnahme an der „Cashback-Bonus“-Promotion ausgeschlossen. ',
            li3: 'Teilnahmeberechtigte Kunden (sowohl Neu- als auch Bestandskunden), die die hier genannten Kriterien erfüllen, können einen Cashback erhalten in Höhe von:',
            li3_sub_content: {
              title1: '[Direct client STP and ECN rebate table]',
              info1: 'Direct STP: $ 2 pro gehandeltem FX-, Öl-, Gold-Lot',
              info2: 'Prime ECN: $ 0,50 pro gehandeltem FX-, Öl-, Gold-Lot ',
              title2: '[Client under IB STP and ECN rebate table]',
              info3: 'Direct STP: $ 0, 50 pro gehandeltem FX-, Öl-, Gold-Lot',
              info4: 'Prime ECN: $ 0.25 pro gehandeltem FX-, Öl-, Gold-Lot   ',
            },
            li3_sub_desc:
              '*Die minimale kumulierte Handelslotgröße, für die der Cashback ausgelöst werden kann, beträgt 0,01 Standard-Lot. Wenn das gültige Handelslot des Kunden an diesem Tag nicht 0,1 Standardlot erreicht, wird es auf Null gesetzt. Die kumulierte Anzahl der Lots ist ein Vielfaches von 0,01 Standard-Lots, z.B. 0,02 Standard-Lots, 0,03 Standard-Lots (0,12 Standard-Lots werden als 0,12 Standard-Lots berechnet).',
            li4: 'Die zulässigen Produkte: Alle Forex-, Gold, und Öl-CFD-Produkte',
            li5: 'Das effektive Handelsvolumen: Die neu eröffneten und geschlossenen Orders während des Aktionszeitraums. Positionen, die weniger als 10 Minuten offen sind, gesperrte Positionen und Hedging-Positionen zählen nicht als gültige Trades für diese Aktion.',
            li6: 'Dieses Angebot kann in Verbindung mit jedem anderen Angebot genutzt werden. ',
            li7: 'Bei Auszahlung eines Guthabens wird der gleiche Betrag vom Bonusguthaben des Nutzers abgezogen. Ist der Auszahlungsbetrag höher als die Bonusgutschrift, wird das Bonusguthaben auf Null reduziert.',
            li8: 'Moneta Markets ist nicht haftbar für Kosten, Ausgaben oder Schäden, die dadurch entstehen, dass Moneta Markets das Konto des Kunden in Übereinstimmung mit diesen Allgemeinen Geschäftsbedingungen wiederherstellt oder anpasst, einschließlich, aber nicht beschränkt auf, Verlusten, die auf automatisch geschlossene offene Positionen zurückzuführen sind.',
            li9: 'Moneta Markets behält sich das Recht vor, nach eigenem Ermessen:',
            li9_sub_items: {
              li1: 'die Registrierung eines Teilnehmers für den „Cashback-Bonus“ abzulehnen; und',
              li2: 'jeden Teilnehmer am "Cashback-Bonus" zu disqualifizieren, der diese Bedingungen und/oder die auf der Website von Moneta Markets dargelegten „Allgemeinen Geschäftsbedingungen von Moneta Markets“ missachtet oder dagegen verstößt.',
            },
            li10: 'Moneta Markets behält sich das Recht vor, nach eigenem Ermessen und ohne Angabe von Gründen das Angebot des „Cashback-Bonus“ für jeden seiner Kunden einzustellen. Diese Kunden werden per E-Mail benachrichtigt.',
            li11: 'Moneta Markets behält sich das Recht vor, den "Cashback-Bonus" oder einen Teil davon jederzeit zu ändern, zu ergänzen oder zu beenden, ohne zu einer Entschädigung verpflichtet zu sein. Die Kunden werden im Voraus über solche Änderungen informiert. Bitte beachten Sie, dass die Teilnahme am „Cashback-Bonus“ die Annahme und Zustimmung zu diesen Änderungen, Ergänzungen und/oder Modifikationen bedeutet.',
            li12: 'Alle Anzeichen oder Verdachtsmomente, die nach dem alleinigen Ermessen von Moneta Markets auf irgendeine Form von Arbitrage (einschließlich, aber nicht beschränkt auf, risikoloser Gewinne), Missbrauch (einschließlich, aber nicht beschränkt auf, Tradingaktivitäten des Teilnehmers, die darauf hindeuten, dass der Teilnehmer nur darauf abzielt, finanziell von der Cashback-Promotion zu profitieren, ohne tatsächlich am Handel an den Märkten interessiert zu sein und/oder Marktrisiken einzugehen), Betrug, Manipulation, Cashback-Arbitrage in Verbindung mit einer Cashback-Promotion oder jede andere Form von arglistigem oder betrügerischem Verhalten führen zur Annullierung des gesamten Cashbacks der realen Handelskonten des teilnahmeberechtigten Kunden bei Moneta Markets und/oder aller Transaktionen und/oder Gewinne oder Verluste, die damit erzielt wurden. Unter diesen Umständen behält sich Moneta Markets das Recht vor, nach eigenem Ermessen (entweder vorübergehend oder dauerhaft) alle Live-Handelskonten dieser berechtigten Kunden bei Moneta Markets zu schließen/auszusetzen, alle Orders zu stornieren und alle Gewinne dieses Teilnehmers zu annullieren. Unter diesen Umständen haftet Moneta Markets nicht für die Folgen der Cashback-Stornierung, einschließlich, aber nicht beschränkt auf, der Schließung von Orders durch Stop Out.',
            li13: 'Mit der Teilnahme am „Cashback-Bonus“ akzeptieren Sie diese Teilnahmebedingungen sowie die Allgemeinen Geschäftsbedingungen von Moneta Markets, die auf der Website von Moneta Markets einsehbar sind.',
            li14: 'Der "Cashback-Bonus" ist nur für einen begrenzten Zeitraum verfügbar, wie in den Teilnahmebedingungen der jeweiligen Aktion und/oder Umfrage angegeben, zu der der "Cashback-Bonus" gehört.',
            li15: 'Falls diese Teilnahmebedingungen für den „Cashback-Bonus“ in eine andere Sprache als Englisch übersetzt werden, ist im Falle einer widersprüchlichen Auslegung die englische Version maßgeblich.',
            li16: 'Der Cashback-Bonus gilt nicht für Kunden mit Wohnsitz in der Türkei.',
            li17: 'Nur Einzahlungen von $ 500 oder mehr ab dem 19. Juli 2023 sind für den Cashback-Bonus qualifiziert.',
          },
          item4: {
            title: 'Risikowarnung',
            li1: 'Der Handel mit Derivaten und Devisen birgt ein hohes Risiko für Ihr Kapital und sollte nur mit Geld getätigt werden, dessen Verlust Sie sich leisten können. Bitte lesen Sie unbedingt die Dokumente auf unserer Seite „Rechtliche Dokumente“ und holen Sie unabhängigen Rat ein, um die Risiken vollständig zu verstehen, bevor Sie traden. Dieses Angebot wurde ohne Berücksichtigung Ihrer Ziele, Ihrer finanziellen Situation oder Ihrer Bedürfnisse erstellt.',
          },
        },
      },
      faqsList: {
        question_1: 'Was ist das Moneta Markets Cashback-Programm, und wie funktioniert es?',
        question_2: 'Wie kann ich mich für die Cashback-Aktion registrieren?',
        question_3: 'Was sind Bonusgutschriften?',
        question_4: 'Was ist ein Cashback?',
        question_5: 'Wie kann ich mein Bonus- und Cashback-Guthaben überprüfen?',
        question_6: 'Können meine Bonusgutschriften verfallen?',
        question_7: 'Kann mein Cashback-Guthaben verfallen?',
        question_8: 'Was sind die Vorteile?',
        question_9: 'Wie viele Bonusgutschriften kann ich verdienen?',
        question_10: 'Wie viel Cashback kann ich verdienen?',
        question_11: 'Wann kann ich meinen Cashback-Bonus abheben?',
        question_12: 'Wie übertrage ich meinen verfügbaren Cashback auf mein Handelskonto?',
        question_13: 'Wie oft wird mein Cashback berechnet?',
        question_14: 'Kann ich für alle Produkte Cashback erhalten?',
        question_15: 'Wie kann ich meinen Cashback-Bonus abheben?',
        question_16: 'Wirken sich Auszahlungen auf meine Bonusgutschriften aus?',
        question_17: 'Wirken sich Auszahlungen auf meinen Cashback-Bonus aus?',
        question_18: 'Gibt es eine Mindesthandelsdauer, um meinen Bonus in Cashback umzuwandeln?',
        question_19: 'Wie kann ich meine Bonusgutschriften erhöhen?',
        question_20: 'Wie kann ich meinen monatlichen Cashback maximieren?',
        question_21:
          'Erhalte ich den gleichen Cashback-Betrag auf Direct STP- und Prime ECN-Konten?',
        question_22: 'Verdiene ich Cashback auf Ultra ECN- oder Cent-Konten?',
        question_23: 'Was sind die Bonusstatus und was bedeuten sie?',
        question_24: 'Wie melde ich mich vom Cashback-Programm ab?',
        answer_1:
          'Das Cashback-Programm von Moneta Markets ist ein Treueprogramm, bei dem Kunden bei einer Einzahlung von mindestens $ 500 eine Bonusgutschrift von 50 % erhalten. Diese Bonusgutschriften werden dann in auszahlbares/handelbares Bargeld umgewandelt, je nach Tradingaktivität mit FX-, Gold- und Öl-Produkten.',
        answer_2:
          'Kunden von Moneta Markets, die über ein teilnahmeberechtigtes Handelskonto verfügen, müssen ihre erste Bonusgutschrift im Kundenportal von Moneta Markets „beantragen“. Klicken Sie einfach auf der Startseite des Kundenportals in der Bonus-Wallet auf „Bonus beantragen“, klicken Sie in der Tabelle „Ihre aktuellen & historischen Vergütungen“ auf die Schaltfläche „Beantragen“ und akzeptieren Sie die Bedingungen. Nachfolgende Bonusgutschriften, die Sie für berechtigte Einzahlungen erhalten, werden automatisch gutgeschrieben.',
        answer_3:
          'Bonusgutschriften werden für anrechenbare Einzahlungen von $ 500 oder mehr in Höhe von 50 % pro anrechenbarer Einzahlung gewährt. Bonusgutschriften befinden sich in Ihrer Bonus-Wallet und werden auf Basis Ihrer Tradingaktivitäten mit allen FX-, Gold- und Öl-Produkten in Echtgeld-Cashback umgewandelt.',
        answer_4:
          'Ihr Cashback ist der Bargeldbetrag, den Sie durch die Umwandlung von Bonusgutschriften als Ergebnis Ihrer Tradingaktivitäten erhalten. Ihr Cashback-Betrag kann auf Ihr Handelskonto übertragen und als Echtgeld verwendet werden.',
        answer_5:
          'Sie können Ihren Kontostand auf der Startseite Ihres Kundenportals einsehen, wo auch Ihre Bonus-Wallet und Ihre Cashback-Wallet angezeigt werden. Um Ihr Cashback-Guthaben aufzuschlüsseln, klicken Sie einfach auf „Aktionen“ – „Cashback“ oder „Cashback einlösen“ auf der Startseite Ihres Kundenportals. Dort sehen Sie Ihren gesamten Cashback-Betrag, den einlösbaren Cashback-Betrag und den Cashback-Betrag des aktuellen Monats.',
        answer_6:
          'Nein, Bonusgutschriften verfallen nicht. Wenn Sie sich für den Cashback-Bonus anmelden, werden alle Ein- und Auszahlungen der letzten 30 Tage berechnet, um Ihren verfügbaren Bonus zu ermitteln. Alle weiteren Bonusgutschriften, die Sie für zukünftige Einzahlungen erhalten, werden automatisch gutgeschrieben und verfallen nicht. Sie können den Status Ihrer aktuellen und historischen Bonusvergütungen in der Tabelle „Ihre aktuellen & historischen Vergütungen“ einsehen, der entweder „Beantragen“, „Gutgeschrieben“, „Nicht berechtigt“ oder „Abgezogen“ anzeigt',
        answer_7:
          'Verdiente Cashback-Bonusbeträge verfallen nicht und stehen Ihnen zum Übertrag auf Ihr Handelskonto für den Live-Handel oder zur Auszahlung am Monatsanfang zur Verfügung.',
        answer_8:
          'Wir freuen uns, Sie zu unseren Kunden zählen zu dürfen und danken Ihnen für Ihr Vertrauen in Moneta Markets. Als solcher erhalten Sie eine Belohnung in Form von Bonusgutschriften auf alle anrechenbaren Einzahlungen. Wenn Sie weiterhin mit uns traden, werden Ihre Gutschriften als Dankeschön für Ihre Treue in echtes Bargeld umgewandelt.',
        answer_9:
          'Wenn Sie $ 500 oder mehr einzahlen, erhalten Sie 50 % dieses Betrages als Bonusgutschrift. Für jede weitere anrechenbare Einzahlung erhalten Sie weitere Bonusgutschriften.',
        answer_10:
          'Wie viel Cashback Sie verdienen können, hängt von der Art Ihres Kontos und den Produkten ab, mit denen Sie traden möchten. Sie können Ihren Verdienst maximieren, indem Sie FX, Öl und Gold auf einem Moneta Markets Direct STP-Konto handeln.<br/><br/>Ihre Bonusgutschriften werden zu folgenden Sätzen in Bargeld umgewandelt:<br/><br/>[Direct STP and Prime ECN rebate table]<br/>Direct STP: $ 2 pro gehandeltem Lot FX, Öl, Gold<br/>Prime ECN: $ 0,50 pro gehandeltem Lot FX, Öl, Gold',
        answer_11:
          'Sie können das Guthaben, das als „Verfügbarer Cashback“ angezeigt wird, jederzeit auf Ihr Handelskonto übertragen. Der Cashback, den Sie im laufenden Monat verdienen, können Sie zu Beginn des nächsten Monats auf Ihr Handelskonto transferieren.',
        answer_12:
          'Klicken Sie einfach in Ihrer Cashback-Wallet auf „Cashback einlösen“, wählen Sie das Handelskonto und den Cashback-Betrag, den Sie übertragen möchten, und klicken Sie erneut auf „Cashback einlösen“.',
        answer_13:
          'Der Cashback, den Sie durch Ihr Trading mit allen FX-, Gold- und Öl-Produkten verdienen, wird täglich um GMT+2 berechnet und in Ihrer Cashback-Wallet angezeigt.',
        answer_14:
          'Der Cashback-Bonus kann für alle FX-, Gold- und Öl-Produkte sowohl auf Direct STP- als auch auf Prime ECN-Konten gemäß der folgenden Tabelle gewährt werden.<br/><br/>[Direct STP and Prime ECN rebate table]<br/>Direct STP: $ 0,50 pro gehandeltem FX-, Öl-, Gold-Lot<br/>Prime ECN: $ 0,25 pro gehandeltem FX-, Öl-, Gold-Lot.',
        answer_15:
          'Die Auszahlung Ihres verfügbaren Cashback-Bonus ist ganz einfach. Klicken Sie einfach in Ihrer Cashback-Wallet auf „Cashback einlösen“, wählen Sie das Handelskonto und den Cashback-Betrag, den Sie überweisen möchten, und klicken Sie erneut auf „Cashback einlösen“. Sobald das Geld auf Ihr Handelskonto überwiesen wurde, können Sie es entweder für das Trading verwenden oder einen Auszahlungsantrag zur Bearbeitung einreichen.',
        answer_16:
          'Gutschriften in Ihrer Bonus-Wallet werden anteilig abgezogen, wenn Sie sich Geld auszahlen lassen. Wenn Ihre Bonus-Wallet zum Beispiel 500 an Gutschriften enthält und Sie sich entscheiden, $ 250 von Ihrem Handelskonto abzuheben, werden auch 250 an Bonusgutschriften abgezogen. Bitte beachten Sie, dass Ihr Bonusguthaben nie ins Minus fallen wird, unabhängig davon, wie viel Geld Sie sich auszahlen lassen.',
        answer_17:
          'Nein. Ihr Cashback-Bonus gehört Ihnen und wird durch Auszahlungen Ihrerseits nicht beeinflusst.',
        answer_18:
          'Jeder FX-, Gold- oder Öl-Trade muss mindestens 10 Minuten lang gehalten werden, damit Ihr Bonus in Cashback umgewandelt werden kann.',
        answer_19:
          'Ihre Bonusgutschriften werden jedes Mal verbucht, wenn Sie eine qualifizierte Einzahlung vornehmen. Für jede Einzahlung von $ 500 oder mehr erhalten Sie 50 % dieses Betrags in Form von Bonusgutschriften.',
        answer_20:
          'Der maximale Cashback-Betrag, den Sie verdienen können, hängt von zwei Faktoren ab: der Art Ihres Kontos und Ihren Tradingaktivitäten mit FX-, Gold- und Öl-Produkten, wobei Direct STP-Konten einen höheren Prozentsatz in Cashback umwandeln als Prime ECN-Konten. Stellen Sie einfach sicher, dass Sie genügend Guthaben in Ihrer Bonus-Wallet haben und handeln Sie FX, Gold und Öl über ein Direct STP-Konto, um Ihren Cashback zu maximieren. Und je mehr Transaktionen Sie durchführen und je höher Ihr Handelsvolumen ist, desto schneller werden Ihre Bonusgutschriften in Cashback umgewandelt.',
        answer_21:
          'Die Höhe des Cashbacks, den Sie für FX, Gold und Öl erhalten, hängt von Ihrem Kontotyp ab.<br/><br/>[Direct STP and Prime ECN rebate table]<br/>Direct STP: $ 0,50 pro gehandeltem FX-, Öl-, Gold-Lot<br/>Prime ECN: $ 0,25 pro gehandeltem FX-, Öl-, Gold-Lot.',
        answer_22:
          'Leider gibt es auf Ultra ECN- oder Cent-Konten keinen Cashback für den Handel mit FX, Gold oder Öl. Sie müssen entweder mit Direct STP- oder Prime ECN-Konten handeln.',
        answer_23:
          'Beantragen: Wenn Sie das erste Mal eine qualifizierte Einzahlung vornehmen, müssen Sie auf „Beantragen“ klicken, damit Ihr Bonus gutgeschrieben wird. Alle nachfolgenden Einzahlungen werden automatisch gutgeschrieben.<br/>Gutgeschrieben: Der Bonus wurde gutgeschrieben und wird in Ihrer Bonus-Wallet erscheinen<br/>Nicht berechtigt: Der Bonus hat die Mindestanforderung von $ 500 nicht erfüllt<br/>Abgezogen: Der Bonus wurde abgezogen, weil Sie sich Geld von Ihrem Handelskonto auszahlen haben lassen.',
        answer_24:
          'Wenn Sie nicht mehr an dem Programm teilnehmen möchten, senden Sie bitte Ihren Antrag auf Abmeldung an <a href="mailto:{email}">{email}</a>',
      },
      reason: {
        1: 'Ein 50 % Bonus wurde Ihrer Bonus-Wallet gutgeschrieben.',
        2: 'Manuelle Bonusanpassung – Gutgeschrieben',
        3: 'Der Auszahlungsbetrag wurde aus Ihrer Bonus-Wallet entfernt',
        4: 'Dieser Bonusbetrag wurde in Cashback umgewandelt',
        5: 'Manuelle Bonusanpassung – Abgezogen',
        6: 'Diese Einzahlung entsprach nicht dem erforderlichen Mindestbetrag für eine Berechtigung.',
      },
      status: { 0: 'Beantragen', 1: 'Gutgeschrieben', 2: 'Abgezogen', 3: 'Nicht berechtigt' },
      zeroMsg: 'Sie haben keinen verbleibenden Cashback, den Sie einlösen können',
    },
  },
  hmcTraderPortal: {
    overview: 'Übersicht',
    interactiveAnalysis: 'Interaktive Analyse',
    learnFromTheBest: 'Vom Besten lernen',
    requestAResearch: 'Anfrage-A-Recherche',
    vipSupport: 'VIP-Support',
    eligibility: 'Berechtigung',
    login: 'Anmeldung beim HMC Live-Trading-Raum',
    depositRequirement:
      'Erfüllen Sie die Einzahlungsvoraussetzungen, um Zugang zum Live-Trading-Raum zu erhalten',
    basic: 'Basic',
    vip: 'VIP',
    exclusiveAnalysis: 'Exklusive Analyse',
    analysisDiscussions: 'Analyse-Diskussionen',
    tradingSignals: 'Handels-Signale',
    tradingSignalRequest: 'Handels-Signale-Anfrage',
    privateAnalysisRequest: 'Private Analyse-Anfrage',
    prioritySupport: 'Vorrangiger Support',
    minimumFundingRequirement:
      'Mindestfinanzierungsanforderung und Handel von $200,000 Nominalwert (Volumen) von geschlossenen Trades*',
    tnc1: 'Bitte beachten Sie, dass die auf <a href="{url}" target="_blank">portal.hmctrader.com</a> präsentierten Informationen Moneta („Moneta Global Limited“, „wir“) von einem Drittanbieter („HMC Trader“) bereitgestellt werden. Sie dienen ausschließlich zu Forschungs- und Informationszwecken und stellen keine Finanzberatung zum Kauf, Verkauf oder Halten eines bestimmten Produkts oder zur Verfolgung einer bestimmten Anlagestrategie dar. Die Informationen sind nicht auf die Anlagebedürfnisse einer bestimmten Person zugeschnitten und beinhalten daher keine Berücksichtigung der Anlageziele, der finanziellen Situation oder der Bedürfnisse eines Lesers, der sie erhalten kann. Bitte beachten Sie auch, dass die Wertentwicklung in der Vergangenheit kein zuverlässiger Indikator für zukünftige Wertentwicklungen und/oder Ergebnisse ist. Die tatsächlichen Ergebnisse können erheblich von jenen abweichen, die in zukunftsgerichteten oder früheren Angaben über die Wertentwicklung erwartet werden. Wir übernehmen keine Haftung für die Richtigkeit oder die Vollständigkeit der hierin bereitgestellten Informationen oder für Verluste, die sich aus von HMC Trader bereitgestellten Informationen ergeben können.',
    tnc2: '*Um den HMC Live-Trading-Raum nutzen zu können, müssen Sie die oben genannten Mindestfinanzierungsanforderungen seit dem Startdatum erfüllen. Sie müssen jeden Monat mindestens $200.000 an Nominalwert (Volumen) an geschlossenen Trades mit Produkten, die von Moneta angeboten werden, handeln, um die Zugangsberechtigung zum HMC Live-Trading-Raum zu erhalten. Ihre Zugangsberechtigung wird automatisch deaktiviert, wenn Sie in einem Monat nicht mindestens 200.000 $ Nominalwert (Volumen) an abgeschlossenen Trades handeln. Sie sind verpflichtet, den erforderlichen Betrag einzuzahlen, um den Zugang zum HMC Live-Trading-Raum wieder zu aktivieren.',
  },
  supportTickets: {
    support: 'Support',
    mySupportTickets: 'Meine Support-Tickets',
    createNewTicket: 'Neues Ticket erstellen',
    ticketNo: 'Ticket-Nr.',
    subject: 'Betreff',
    status: 'Status',
    lastUpdated: 'Zuletzt aktualisiert',
    newTicketRequest: 'Erstellen Sie eine neueTicketanfrage',
    content: 'Inhalt',
    attachments: 'Anhänge',
    upload: 'Hochladen',
    viewingTicket: 'Anzeige Ticket-Nr.',
    postReply: 'Eine Antwort verfassen',
    update: 'Update',
    cancel: 'Abbrechen',
    close: 'Schließen',
    number: 'Nr.',
    description: 'Beschrijving',
    postReplyRequired: 'Feld „Antwort verfassen“ ist erforderlich',
    contentFieldRequired: 'Feld „Inhalt“ ist erforderlich',
    subjectFieldRequired: 'Feld „Betreff“ ist erforderlich',
    successMsg: 'Ihr Support-Ticket wurde erfolgreich erstellt!',
  },
  responseMsg: {
    410: 'Parametervalidierung fehlgeschlagen',
    411: 'Falsches Passwort',
    420: 'Benutzer existiert nicht',
    421: 'Passwortrücksetzung verweigert',
    490: 'Bitte geben Sie keine doppelten Karteninformationen ein',
    500: 'Es ist ein Fehler aufgetreten.',
    501: 'Antrag fehlgeschlagen. Bitte versuchen Sie es später erneut.',
    505: 'Datei-Upload fehlgeschlagen',
    510: 'Falscher SMS-Code',
    511: 'Es konnte kein SMS-Code an Ihr Telefon gesendet werden',
    520: 'Anmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.',
    521: 'Es ist bereits ein Benutzer auf diesem Gerät angemeldet!',
    522: 'Bitte melden Sie sich erneut an',
    523: 'Bitte melden Sie sich erneut an',
    524: 'Leider wurde Ihre E-Mail-Adresse nicht erkannt',
    525: 'Falscher Benutzername oder falsches Passwort',
    526: 'Recaptcha stimmt nicht überein',
    527: 'Benutzer ist kein IB',
    528: 'Benutzer existiert nicht',
    529: 'Falscher Benutzername oder falsches Passwort',
    530: 'Ungültige Anmeldesitzung',
    531: 'Code ungültig',
    540: 'Ihr Trading-Konto konnte nicht gefunden werden',
    541: 'Kann Rabatt-Konto nicht finden',
    542: 'Sie dürfen kein zusätzliches Konto eröffnen',
    544: 'Sie können kein weiteres Konto beantragen, bis Ihr Identitätsnachweis verifiziert wurde',
    550: 'Falsche Kontonummer',
    551: 'Ihr Guthaben ist nicht ausreichend',
    554: 'Der angeforderte Betrag ist Null oder nicht gültig',
    562: 'Wir konnten Ihre Zahlung nicht verarbeiten, bitte versuchen Sie es erneut. Wenn das Problem weiterhin besteht, kontaktieren Sie uns bitte per Live-Chat oder E-Mail {email}',
    564: 'Land stimmt nicht mit dem Zahlungskanal überein',
    565: 'Falsche Bankleitzahl',
    566: 'Zahlungsmethode nicht gefunden',
    567: 'Leider ist diese Zahlungsmethode derzeit nicht verfügbar. Wir bitten um Ihr Verständnis',
    568: 'Auf dieses Konto kann keine Einzahlung vorgenommen werden, bitte wenden Sie sich an {email}',
    569: 'Ihr Vorname/Nachname/Telefonnummer ist erforderlich. Wenn Sie Hilfe benötigen, wenden Sie sich bitte an {depositEmail}',
    581: 'Sie haben sich erfolgreich für die Promotion angemeldet. Bitte beachten Sie, dass Sie eine Einzahlung vornehmen müssen, um Geld auszahlen zu können.',
    584: 'Sie haben keine anwendbaren Rabatte',
    587: 'Auszahlungsbetrag ungültig.',
    590: 'Transfer-Out-Konto nicht gefunden',
    591: 'Kann Einbuchungskonto nicht finden',
    593: 'Wir können Ihren Geldtransfer von dem ausgewählten Konto nicht bearbeiten, da Ihr Konto Guthaben enthält.',
    594: 'Geldtransfer von diesem Konto ist nicht erlaubt',
    624: 'Der negative Saldo dieses Kontos kann nicht entfernt werden. Bitte kontaktieren Sie support@monetamarkets.com für weitere Informationen.',
    625: 'Ihr Antrag ist eingegangen und wird derzeit bearbeitet. Bei Fragen wenden Sie sich bitte an support@monetamarkets.com.',
    626: 'Wir haben festgestellt, dass Ihr Saldo größer als Null ist. Bitte aktualisieren Sie die Seite und überprüfen Sie erneut.',
    647: 'Verifizierungscode ist nicht korrekt',
    1001: 'Ihr Konto steht vorübergehend nicht zur Verfügung, bitte kontaktieren Sie Ihren Account Manage',
    1102: 'Die Aktivierung ist nicht möglich, da das Konto als Live- oder Demokonto erstellt wurde.',
    1103: 'Konto wurde deaktiviert',
    1202: 'Verifizierung abgelaufen, bitte erneut versuchen',
    1204: 'Bitte versuchen Sie es mit einer anderen E-Mail-Adresse',
    1208: 'Überprüfungscodes können nicht wiederholt gesendet werden',
    1209: 'Fehler beim Senden des Bestätigungscodes',
    session_timeout: 'Sitzungszeitüberschreitung, Weiterleitung zur Anmeldeseite.',
    vload: {
      4019: 'Gutscheintyp oder Wert wird nicht akzeptiert',
      4071: 'Betrugsverdacht',
      4072: 'Gutschein in Verwendung',
      4073: 'Gutschein ist nicht aktiv',
      4074: 'Gutschein ist abgelaufen',
      4075: 'Gutschein wird nicht gefunden',
      4080: 'Gutschein hat zu wenig Wert',
      4444: 'Ungültiger Gutschein verwendet. Währung stimmt nicht überein.',
      5000: 'Store-Daten sind nicht verfügbar',
    },
  },
  lock: {
    premium: {
      trading: {
        tools: {
          description:
            'Vervollständigen Sie jetzt Ihren Antrag, um Premium-Features freizuschalten',
        },
      },
    },
  },
};
