export default {
  common: {
    liveChat: { desc: '<li><p>Precisa de ajuda?</p><span>Chat ao Vivo</span></li>' },
    country: { international: 'Internacional', australia: 'Austrália', uk: 'Reino Unido' },
    verification: { toSlide: 'Deslize para completar o quebra-cabeça' },
    hover: {
      BankName: 'O nome do banco ou da instituição financeira que recebe os fundos.',
      BankBeneficiaryName:
        'O nome da pessoa ou empresa proprietária da conta bancária que recebe os fundos.',
      AccountHoldersAddress: 'A morada física da pessoa ou empresa proprietária da conta bancária.',
      BankAddress: 'A morada física do banco ou instituição financeira que recebe os fundos.',
      BankAccountNumber:
        'O número da conta bancária ou IBAN (Internacional Bank Account Number) é o número utilizado para identificar a sua conta bancária.',
      SwiftCode:
        'O código SWIFT, também conhecido como Código de Identificação Bancária (BIC), é um código internacional utilizado para identificar o país, banco e agência e é necessário quando o dinheiro é transferido internacionalmente.',
    },
    field: {
      nameOnCard: 'Nome no cartão',
      first4Digits: 'Primeiros 4 Dígitos no Cartão',
      last3Digits: 'Últimos 3 Dígitos no Cartão',
      first6Digits: 'Primeiros 6 Dígitos no Cartão',
      last4Digits: 'Últimos 4 Dígitos no Cartão',
      bankName: 'Nome do Banco',
      bankAddress: 'Endereço do Banco',
      branchAddress: 'Endereço da Filial',
      bankBranch: 'Agência Bancária',
      bankCity: 'Cidade do Banco',
      bankProvince: 'Província do Banco',
      bankCode: 'Código do Banco',
      bankBranchCode: 'Código de sucursal',
      bankBeneficiaryName: 'Nome do Beneficiário Bancário',
      bankBeneAccName: 'Nome da Conta do Beneficiário Bancário',
      bankBeneAccNum: 'Número de Conta do Beneficiário Bancário',
      bankBeneAddress: 'Endereço do Beneficiário',
      bankAccNum: 'Número de Conta Bancária',
      bankAccName: 'Nome da Conta Bancária',
      bankHolderAddress: 'Endereço do Titular da Conta',
      address: 'Endereço',
      accName: 'Nome da Conta',
      accNum: 'Número de Conta',
      accNumTo: 'Número de Conta',
      bsbNum: 'Número BSB',
      swift: 'Código SWIFT',
      region: 'Região',
      sort: 'Sort code',
      ifscCode: 'Código IFSC',
      swiftNonAUD: 'Código SWIFT (Contas Não AUD)',
      amt: 'Valor',
      amtUSD: 'Valor (USD)',
      imptNotes: 'Notas importantes',
      yes: 'Sim',
      no: 'Não',
      payMethod: 'Método de Pagamento:',
      expDate: 'Data de Validade',
      bsb: 'BSB',
      abaSortCode: 'Código ABA/Sort:',
      country_region: 'País / Região',
      currLeverage: 'Alavancagem Atual',
      accEquity: 'Capital da conta',
      newLeverage: 'Alavancagem Desejada',
      currPw: 'Palavra-passe Atual',
      newPw: 'Nova Palavra-passe',
      confirmPw: 'Confirmar a Nova Palavra-passe',
      loginEmail: 'E-mail para Início de Sessão',
      email: 'E-mail',
      pw: 'Palavra-passe',
      accType: 'Tipo de Conta',
      name: 'Nome',
      emailAdd: 'Endereço de E-mail',
      phone: 'Número de Telefone',
      countryCode: 'País',
      dob: 'Data de Nascimento',
      nat: 'Nacionalidade',
      country: 'País de residência',
      street: 'Número da Rua/Nome da Rua',
      suburbOrCity: 'Freguesia/Cidade',
      provinceOrState: 'Concelho/Distrito',
      postcode: 'Código Postal',
      empStat: 'Situação Profissional',
      occupation: 'Profissão',
      annIncome: 'Rendimento Anual',
      save: 'Poupança/Investimento',
      source: 'Fonte de Rendimento',
      industry: 'Atividade',
      statusDef: 'DEFINIÇÕES DO ESTADO',
      month: 'Mês',
      year: 'Ano',
      select: 'Selecionar',
      completed: 'Concluído',
      incomplete: 'Incompleto',
      submitted: 'Submetido',
      successful: 'Com Sucesso',
      processing: 'Em Processamento',
      cancelled: 'Cancelado',
      failed: 'Falhado',
      rejected: 'Rejeitado',
      upload: 'Carregar',
      ccNum: 'Número do Cartão de Crédito',
      bankCardNumber: 'Número do Cartão Bancário',
      cardHolderName: 'Nome do Titular do Cartão',
      reservedMobileNumber: 'Número de Telemóvel para o Banco',
      nationalId: 'Cartão Nacional de Identidade',
      cardPhoto: 'Foto do Cartão (frente)',
      notes: 'Notas',
      bankAccOrIBAN: 'Número da Conta Bancária/IBAN',
      selectWithdrawCard: 'Selecione o Seu Cartão para Levantamentos',
      updateCardPhoto: 'por favor, faça o carregamento da foto do seu cartão (Frente)',
      transitNumber: 'Número de Trânsito',
      institutionNumber: 'Número da instituição',
      wallet: 'Carteira',
      resetBalTo: 'Redefinir o Saldo para',
      confirmation: 'Tem a certeza?',
      removeNote: 'Quer mesmo apagar este registo? Este processo não pode ser desfeito.',
      depositCurrency: ' Moeda de depósito',
      bankAccountCurrency: 'Moeda da Conta Bancária',
      ipChangeWarning:
        'Receba um e-mail de alerta quando alguém entrar na sua conta a partir de um local diferente',
      documentType: 'Tipo de documento',
      documentDetails: 'Detalhes do Documento',
      pixKeyType: 'Tipo de Chave PIX',
      pixKey: 'Chave PIX',
      enterVerificationCode: 'Insira o Código de Verificação',
      accDigit: 'Número de Conta',
      docID: 'ID do Documento',
      paymentAccName: '{name} Nome da Conta',
      paymentAccNum: '{name} Número de Conta',
      walletNumber: 'Número da carteira {name}',
      beneficiaryIBANNumber: 'Número IBAN da conta do beneficiário',
      beneficiaryBankSwift: 'Código SWIFT do banco beneficiário',
      beneficiaryBankName: 'Nome do banco beneficiário',
      beneficiaryBankAddress: 'Beneficiary Bank Address',
      beneficiaryBankSortCode: 'Código de classificação do banco beneficiário',
      beneAccNum: 'Número da conta do beneficiário',
      reversedfailed: 'Falha Revertida',
      cardNumber: 'Número do cartão',
      beneficiaryName: 'Nome do beneficiário',
    },
    column: {
      date: 'DATA',
      type: 'TIPO',
      server: 'SERVIDOR',
      status: 'ESTADO',
      comment: 'COMENTÁRIO',
      accNum: 'NÚMERO DE CONTA',
      currency: 'MOEDA',
      equity: 'CAPITAL PRÓPRIO',
      credits: 'CRÉDITOS',
      balance: 'EQUILÍBRIO',
      leverage: 'ALAVANCAGEM',
      resetMT4PW: 'REDEFINIR A PALAVRA PASSE DO MT4',
      resetMT5PW: 'REDEFINIR A PALAVRA PASSE DO MT5',
      tradingAcc: 'CONTA DE NEGOCIAÇÃO',
      method: 'MÉTODO',
      amt: 'VALOR',
      procNote: 'NOTAS DO PROCESSO',
      number: 'NÚMERO DO CARTÃO',
      resetBal: 'Redefinir o Saldo',
      remove: 'Excluir',
    },
    key: { from: 'DE', to: 'PARA' },
    fund: {
      must: 'OBRIGAÇÃO',
      infoHover: 'O nome no seu cartão {must} corresponder ao nome da sua conta de negociação',
      ccUploadReminder:
        'Por favor, faça o carregamento de uma foto da frente do seu cartão para verificação. (Por favor, oculte os 6 dígitos do meio)',
    },
    button: {
      bkToHm: 'VOLTAR AO INÍCIO',
      smsLogin: 'INÍCIO DE SESSÃO NO TELEMÓVEL',
      userLogin: 'LOGIN DE E-MAIL',
      goToIB: 'IR Para o Portal IB',
      logout: 'ENCERRAR SESSÃO',
      submit: 'SUBMETER',
      selectFile: 'Selecionar Ficheiro',
      depositFunds: 'DEPOSITAR FUNDOS',
      withdrawFunds: 'LEVANTAR FUNDOS',
      ibPortal: 'PORTAL IB',
      download: 'DESCARREGAMENTOS',
      changeLeverage: 'ALTERAR ALAVANCAGEM',
      cancel: 'CANCELAR',
      changePw: 'ALTERAR PALAVRA-PASSE',
      forgetPw: 'ESQUECEU A PALAVRA-PASSE',
      confirm: 'CONFIRMAR',
      login: 'INÍCIO DE SESSÃO',
      forgotPw: 'Esqueceu a Palavra-passe?',
      completeQuestionnaire: 'QUESTIONÁRIO COMPLETO',
      identityProof: 'INSCRIÇÃO COMPLETA',
      upload: 'CARREGAR',
      continue: 'Continuar',
      enable: 'HABILITAR',
      disable: 'DESATIVAR',
      export: 'EXPORTAR',
      ok: 'OK',
      hide: 'OCULTAR',
      unhide: 'MOSTRAR',
      showAll: 'MOSTRAR TUDO',
      beginner: 'PRINCIPIANTE',
      intermediate: 'INTERMEDIÁRIO',
      advanced: 'AVANÇADO',
      sendCode: 'Enviar Código',
      resendCode: 'Reenviar código',
    },
    formValidation: {
      common: 'Este campo é obrigatório.',
      accReq: 'O número da conta é obrigatório',
      amtReq: 'O valor é obrigatório',
      ccReq: 'O número do cartão de crédito é obrigatório',
      cardReq: 'O número do cartão é obrigatório',
      cardOnNameReq: 'O nome no cartão é obrigatório',
      digits16: 'Por favor, insira 16 dígitos',
      digits3or4: 'Por favor, insira 3 ou 4 dígitos',
      digitsFirst6: 'Por favor, insira os primeiros 6 dígitos',
      digitsLast4: 'Por favor, insira os últimos 4 dígitos',
      digitsLast3: 'Por favor, insira os últimos 3 dígitos',
      mid6Digits: 'Por favor, insira os {number} dígitos do meio',
      expDateReq: 'A data de validade é obrigatória',
      cvvReq: 'O Código de Segurança CVV é obrigatório',
      file: 'Por favor, faça o carregamento de um ficheiro',
      filePhoto: 'A Foto do Cartão de Crédito é obrigatória',
      fileReceipt: 'Por favor, faça o carregamento de um recibo',
      filePhotoReq: 'A Foto do Cartão é obrigatória',
      amtLarger: 'O valor não pode ser nulo e deve ser maior ou igual a ${minLimit} {currency}',
      withdrawAmtLarger: 'O valor mínimo de retirada é de ${minLimit} {currency} ou o equivalente.',
      amt0: 'O valor não pode ser 0',
      amtLess: 'O valor do seu depósito não pode ser superior a ${maxLimit} {currency}',
      note256: 'O limite do comprimento da nota é de 256 caracteres',
      noteMoreThan: 'O limite do comprimento da nota é de {limit} caracteres',
      noteLessThan: 'O limite do comprimento da nota é de {limit} caracteres',
      emailFormat: 'Por favor, insira o endereço de e-mail correto',
      payMethodReq: 'O método de pagamento é obrigatório',
      expReq: 'A data de validade é obrigatória',
      bankNameReq: 'O Nome do Banco é obrigatório',
      bankCityReq: 'A Cidade do Banco é obrigatória',
      bankProvinceReq: 'O Concelho do Banco é obrigatório',
      bankAddressReq: 'O Endereço do Banco é obrigatório',
      bankBranchReq: 'A Agência Bancária é obrigatória',
      bankAccNumReq: 'O Número da Conta Bancária é obrigatório',
      ifscCodeReq: 'O código IFSC é obrigatório',
      bankBeneficiaryNameReq: 'O Nome do Beneficiário Bancário é obrigatório',
      bankHolderAddressReq: 'O Endereço do Titular da Conta é obrigatório',
      swiftReq: 'O código Swift é obrigatório',
      accHolderAddress: 'O Endereço do Titular da Conta é obrigatório',
      bsbReq: 'O BSB é obrigatório',
      accNumIBANReq: 'O Número da Conta Bancária/IBAN é obrigatório',
      accNameReq: 'O Nome da Conta Bancária é obrigatório',
      withdrawMethodReq: 'Por favor, selecione um método de levantamento',
      tnc: 'Por favor, concorde com os Termos e Condições.',
      currPwReq: 'A palavra-passe atual é obrigatória',
      currPwWrong: 'A palavra-passe atual está incorreta',
      newPwReq: 'A nova palavra-passe é obrigatória',
      newPwFormat:
        'A sua palavra-chave deve ter entre 8-16 caracteres e uma mistura de letras (a-z e A-Z), números (0-9) e caracteres especiais como !@#$%^&*',
      IdenficalPw: 'A nova palavra-passe não pode ser igual à antiga',
      confirmPwReq: 'Por favor, insira a nova palavra-passe novamente',
      confirmNotMatch: 'Os dois dados inseridos não coincidem',
      pwReq: 'A palavra-passe é obrigatória',
      accCurrReq: 'Selecione uma moeda de base para sua conta',
      tradAccReq: 'Por favor, selecione uma conta de negociação',
      answerReq: 'Por favor, selecione',
      bankCardReq: 'Por favor, selecione um cartão',
      phoneNumberReq: 'O Número de Telemóvel para o Banco é obrigatório',
      nationaIdReq: 'A identificação Nacional é obrigatória',
      numberReq: 'Por favor, insira o número',
      alphanumericReq: 'Por favor, insira o alfanumérico',
      idTypeReq: 'Por favor, escolha um Tipo de Identificação',
      dobReq: 'Por favor, escolha a sua data de nascimento',
      pobReq: 'Por favor, escolha o seu local de nascimento',
      nationalityReq: 'Por favor, escolha a sua nacionalidade',
      titleReq: 'Por favor, escolha o seu título',
      firstNameReq: 'Por favor, insira o seu nome próprio',
      lastNameReq: 'Por favor, insira o seu sobrenome',
      emailReq: 'Por favor, insira o seu endereço de e-mail',
      addressReq: 'Por favor, insira a sua morada',
      suburbReq: 'Por favor, insira a sua cidade ou freguesia',
      stateReq: 'O distrito ou concelho é obrigatório',
      postcodeReq: 'Por favor, insira o seu código postal',
      countryReq: 'Por favor, selecione o seu país de residência',
      NIReq: 'Por favor, digite seu número de Seguro Nacional (NI)',
      taxResidencyReq: 'Favor selecionar seu(s) país(es) de residência fiscal',
      yearsAtAddressReq: 'Por favor, digite seu Número de anos de residência no endereço',
      bankCodeReq: 'Por favor, insira o Banco Emissor do Cartão',
      bankBranchCodeReq: 'O Código da sucursal é obrigatório',
      dynamicReq: '{dynamic} é obrigatório',
      mobReq: 'o telemóvel é obrigatório',
      confirmed: 'Por favor, confirme',
      resetBalAmt: 'Por favor, defina o montante entre 0 e 1.000.000',
      verCodeReq: 'O código de verificação é obrigatório',
      noEligibleAccounts: 'Não existem contas elegíveis',
      AlphanumericOnlyNotSpace: 'Apenas alfanumérico. Espaço não é permitido.',
      AlphanumericOnly: 'Apenas alfanumérico.',
      NumbersSymbol: 'Apenas números e símbolos.',
      NumbersOnlyNotSpace: 'Apenas números. Espaço não é permitido.',
      NumbersOnly: 'Apenas números.',
      LettersSymbol: 'Apenas letras e símbolos.',
      LettersSymbolNotSpace: 'Apenas letras e símbolos. Espaço não é permitido.',
      LettersOnlyNotSpace: 'Apenas letras. Espaço não é permitido.',
      NotSpace: 'Espaço não permitido.',
      beneficiaryNameReq: 'O nome do beneficiário é obrigatório',
    },
    withdrawChannel: {
      banktransferbpaypolipay: 'Transferência Bancária/BPay/PoliPay',
      banktransfer: 'Transferência Bancária Internacional',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'Transferência UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Cartão de Crédito',
      archiveThisCard: 'Por favor arquive este cartão se já não for válido ou não estiver em uso',
      update: 'Atualização',
      archive: 'Arquivo',
      updateExpiryMsg:
        'Atualize a data de expiração do seu cartão para utilizar o seu cartão para levantamentos ',
      available: 'Disponível em',
      pending: 'Pendente',
      unavailable: 'Não Disponível',
      fullyRefunded: 'Totalmente Reembolsado',
      requiredDocument: 'Documentos necessários para o arquivamento de cartões',
      officialLetter: 'Carta/email oficial do banco declarando que o cartão já não está disponível',
      bankStatement: 'Extrato bancário apresentando uma transação feita com este cartão',
      expiredCard: 'Cartões expirados/não disponíveis',
      confirmArchive: 'Tem a certeza de que quer arquivar este cartão?',
      confirmUpdate:
        'Tem a certeza de que quer atualizar a data de validade deste cartão? Nota: A Moneta não será responsável se o levantamento falhar devido à data de expiração incorreta fornecida',
      bpay: 'Bpay',
      creditdebitcard: 'Cartão de Crédito/Débito',
      bankwiretransferinternational: 'Transferência Bancária (Internacional)',
      banktransferaustralia: 'Transferência Bancária (Austrália)',
      banktransferinternational: 'Transferência Bancária Internacional',
      banktransferchina: 'Transferência Bancária (Internacional)',
      banktransferuk: 'Transferência Bancária (Reino Unido)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Transferência de Corretora para Corretora',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Serviço Bancário Online (Tailândia)',
      internetbankingnigeria: 'Serviço Bancário Online (Nigéria)',
      internetbankingvietnam: 'Serviço Bancário Online (Vietname)',
      internetbankingmalaysia: 'Serviço Bancário Online (Malásia)',
      internetbankingindonesia: 'Serviço Bancário Online (Indonésia)',
      internetbankingkorea: 'Serviço Bancário Online (Korea)',
      internetbankingsouthkorea: 'Serviço Bancário Online (Coreia do Sul)',
      internetbankingindia: 'Serviço Bancário Online (India)',
      internetbankingphilippine: 'Serviço Bancário Online (Philippine)',
      internetbankingsouthafrica: 'Serviço Bancário Online (África do Sul)',
      internetbankinguk: 'Serviço Bancário Online (Reino Unido)',
      internetbankinglaos: 'Serviço Bancário Online (Laos)',
      internetbankinghk: 'Serviço Bancário Online (Hong Kong)',
      internetbankinguganda: 'Serviço Bancário Online (Uganda)',
      internetbankingrwanda: 'Serviço Bancário Online (Ruanda)',
      internetbankingzambia: 'Serviço Bancário Online (Zâmbia)',
      internetbankingcongo: 'Serviço Bancário Online (Congo)',
      internetbankingcameroon: 'Serviço Bancário Online (Camarões)',
      internetbankingburundi: 'Serviço Bancário Online (Burundi)',
      internetbankingkenya: 'Serviço Bancário Online (Quênia)',
      internetbankingghana: 'Serviço Bancário Online (Gana)',
      internetbankingtanzania: 'Serviço Bancário Online (Tanzânia)',
      internetbankingbrazil: 'Serviço Bancário Online (Brasil)',
      internetbankingmexico: 'Serviço Bancário Online (México)',
      internetbankingcolombia: 'Serviço Bancário Online (Colômbia)',
      internetbankingchile: 'Serviço Bancário Online (Chile)',
      internetbankingperu: 'Serviço Bancário Online (Peru)',
      internetbankingusd: 'Serviço Bancário Online (USD)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Transferência Bancária do Vietnam',
      nigeriabanktransfer: 'Transferência Bancária da Nigéria',
      polipayment: 'Pagamento com Poli',
      bankwiretransferaustralia: 'Transferência Bancária (Austrália)',
      thailandinstantbankwiretransfer: 'Transferência Bancária Instantânea Tailandesa',
      nigeriainstantbankwiretransfer: 'Transferência Bancária Instantânea Nigeriana',
      vietnaminstantbankwiretransfer: 'Transferência Bancária Instantânea Vietnamita',
      laosinstantbankwiretransfer: 'Transferência bancária instantânea de Laos',
      malaysiainstantbankwiretransfer: 'Transferência Bancária Instantânea Malásia',
      indonesiainstantbankwiretransfer: 'Transferência Bancária Instantânea Indonésia',
      indiainstantbanktransfer: 'Transferência Bancária Instantânea India',
      southafricainstantbankwiretransfer: 'Transferência Bancária Instantânea África do Sul',
      philippinesinstantbankwiretransfer: 'Transferência Bancária Instantânea Philippines',
      indialocalbankwiretransfer: 'Transferência Bancária Local Índia',
      hongkongbanktransfer: 'Transferência Bancária Local de Hong Kong',
      ugandabanktransfer: 'Transferência de banco local de Uganda',
      rwandabanktransfer: 'Transferência de banco local de Ruanda',
      zambiabanktransfer: 'Transferência de banco local da Zâmbia',
      congobanktransfer: 'Transferência de banco local do Congo',
      cameroonbanktransfer: 'Transferência de banco local de Camarões',
      burundibanktransfer: 'Transferência bancária local do Burundi',
      kenyabanktransfer: 'Transferência bancária local do Quênia',
      ghanabanktransfer: 'Transferência de banco local de Gana',
      tanzaniabanktransfer: 'Transferência de banco local da Tanzânia',
      brazilbanktransfer: 'Transferência bancária no Brasil',
      mexicobanktransfer: 'Lokalny przelew bankowy w Meksyku',
      pagsmileBrazil: 'Transferência Bancária Instantânea Brasil',
      pagsmileMexico: 'Transferência de banco local da Mexico',
      pagsmileColombia: 'Transferência de banco local da Colombia',
      pagsmileChile: 'Transferência de banco local da Chile',
      pagsmilePeru: 'Transferência de banco local da Peru',
      banktransferbpaypolipayaustralia: 'Transferência Bancária/BPay/PoliPay (Austrália)',
      banktransferbpaypolipayinternational: 'Transferência Bancária/BPay/PoliPay (Internacional)',
      paypal: 'Paypal',
      cryptobitcoin: 'Criptomoeda-Bitcoin',
      cryptotetheromni: 'Criptomoeda-USDT(OMNI)',
      cryptotethererc20: 'Criptomoeda-USDT(ERC20)',
      cryptotethertrc20: 'Criptomoeda-USDT(TRC20)',
      cryptocurrencybitcoin: 'Criptomoeda-Bitcoin',
      cryptocurrencyusdt: 'Criptomoeda-USDT',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      trustly: 'Trustly',
      astropay: 'Astropay',
      webmoney: 'WebMoney',
      vload: 'Vload',
      dasshpewallet: 'Carteiras da Índia',
      cryptocurrencyethcps: 'Criptomoeda-ETH',
      cryptocurrencyeth: 'Criptomoeda-ETH',
      cryptocurrencyusdc: 'Criptomoeda-USDC(ERC-20)',
      cryptocurrencyusdcerc20: 'Criptomoeda-USDC(ERC-20)',
      cryptocurrencyusdccps: 'Criptomoeda-USDC(ERC-20)',
      chileBankTransfer: 'Transferência bancária no Chile',
      colombiaBankTransfer: 'Transferência bancária na Colômbia',
      perubanktransfer: 'Transferência bancária no Peru',
      alipay: 'Alipay',
    },
  },
  home: {
    pendingTooltip:
      'Depois da sua conta real ser aprovada, receberá um e-mail com os seus detalhes de início de sessão na MT{number} para que possa começar a negociar! ',
    leverage: {
      tncTitle: '<span>Termos e Condições</span>',
      tnc: '<p>Ao submeter este pedido, eu reconheço que estou ciente de que a elevada alavancagem acarreta um elevado grau de risco para o meu capital e que existe a possibilidade de sofrer uma perda maior que, e não limitada, ao capital que eu depositei.<br><br>Concordo que o emissor do produto tem o direito de rever as minhas atividades de negociação e ajustar os níveis de alavancagem na minha conta de negociação a seu critério e sem nenhum aviso ou notificação prévia.<br><br>Eu compreende que, ao escolher uma elevada alavancagem, posso negociar com margem maior, mas sofrer potencialmente perdas significativas.</p>',
      tncConfirm: 'Eu li e concordo com os termos e condições.',
      changeLeverageConfirm:
        'O seu pedido foi submetido e será processado em breve pela nossa equipa de suporte.',
      changeLeverageAutoComplete: 'O seu pedido de alteração de alavancagem foi concluído',
      changeLeverageReject: 'O seu pedido de alteração da alavancagem foi rejeitado',
      changeLeverageFail:
        'O pedido de alteração da alavancagem falhou, por favor, tente mais tarde',
      changeLeverageDefault:
        'Ocorreu um erro, por favor, entre em contacto com ${supportEmail} para finalizar a sua inscrição',
      alreadyApplied:
        'Já solicitou a alteração da alavancagem da sua conta e está atualmente em revisão pela equipa de suporte da {platform}. Por favor, aguarde até que este pedido seja finalizado antes de enviar outro pedido.',
      reduceLeverageWarn:
        'Observação: A redução de sua alavancagem pode resultar no fechamento forçado de posições abertas se sua conta tiver margem insuficiente.',
      reduceLeverageConfirm: 'Você deseja continuar?',
      autoRestore:
        '<p>Uma vez que a sua liquidez >= 20.000, a sua alavancagem disponível é de 500:1</p><br /><p>Quando a sua liquidez for, novamente, inferior a 20.000, poderá ajustar para 1.000:1 novamente</p>',
      oneThousandRatio:
        '<p>Por favor, consulte os termos abaixo se desejar mudar para alavancagem de 1000:1:<br><br>1. Não pode inscrever-se para nenhuma promoção relacionada a crédito<br><br>2. A liquidez da sua conta não pode exceder 15.000 USD ou equivalente no momento da candidatura<br><br>3. A alavancagem da conta será alterada para 500:1 caso a liquidez da sua conta exceda 20.000 USD ou equivalente <br><br>4. Os limites de alavancagem podem variar de acordo com os regulamentos aplicáveis<br><br>5. A alteração de alavancagem não se aplica a produtos com alavancagem fixa <br><br> 6. De acordo com o contrato do cliente, medidas incluindo, mas não se limitando a, Rescisão ou Dedução serão realizadas caso viole as cláusulas relacionadas a Eventos Padrão e/ou Atividade de Negociação Suspeita<br><br></br>',
    },
    pw: {
      error: 'Falha ao redefinir a sua palavra-passe. Por favor, tente novamente mais tarde',
      confirm:
        'A sua palavra-passe foi atualizada.<br>Agora pode utilizar a sua nova palavra-passe para aceder à sua conta de negociação MT4/MT5.',
      email:
        '<p class="first_info">Acabamos de lhe enviar um e-mail com instruções para redefinir a sua palavra-passe.</p><p>Por favor, certifique-se de que verifica a pasta de spam do seu e-mail.</p>',
    },
    activate: {
      question: 'Deseja reativar a sua conta real?',
      confirm: 'Recebemos o seu pedido de ativação.<br>A sua conta será ativada em breve.',
    },
    accStatus: {
      active: 'Ativa',
      rejected: 'Rejeitado',
      inactive: 'Inativa',
      processing: 'Em Processamento',
      activate: 'Ativar',
      expired: 'Expirada',
    },
    appNote: { desc02: 'Clique aqui para saber mais.' },
    app: {
      title: 'Como funciona',
      desc01: 'Transferir a {platform}',
      desc02: 'Inicie sessão com o seu e-mail existente',
      desc03: 'Verifique o seu número de telefone',
      desc04: 'Está pronto. Desfrute de uma negociação poderosa',
    },
    reset: {
      success: 'Sua redefinição foi bem-sucedida.',
      creditTip:
        'O saldo da sua conta é menor do que 0. Se você redefinir o saldo da sua conta para 0, também redefinirá o seu crédito para 0. Tem certeza de que deseja continuar?',
      resetBtn: 'Redefinir',
      tableHeader: 'Saldo Negativo',
    },
  },
  oneThousandRatioError: {
    error1: 'Esta conta tem um bónus e não pode ser ajustada para a alavancagem de 1000:1',
    error4:
      'A liquidez da conta é superior a $15,000 (ou moeda equivalente) e não pode ser ajustada para a alavancagem de 1.000:1',
    error5: 'The ID audit is not pass, cannot be adjusted to 1000:1 leverage',
    error6:
      'A conta participou na atividade de bónus e não pode ser ajustada para a alavancagem de 1000:1',
  },
  paymentDetails: {
    subHeader: 'ADICIONE UM MÉTODO DE PAGAMENTO',
    payMethod: 'Forma de Pagamento',
    tip: '<p>Selecione o seu método de pagamento preferido para depósitos e levantamentos futuros, no menu abaixo.</p><br />\n   <p><b>Por favor, tenha  atenção:</b> Para garantir que os seus pedidos de depósito e levantamento possam ser processados imediatamente, os regulamentos de AML/CTF exigem que o nome apresentado no método de pagamento escolhido seja\n      <b> {individualUserName}</b>.</p>',
    result: {
      defaultSuccessMessage:
        'Obrigado por submeter o método de financiamento para a sua conta de negociação. Estamos a processar o seu pedido e entraremos em contacto em breve.',
      unionPayCardSuccessMessage:
        'Os detalhes do Union Pay Card foram submetidos com sucesso, os seus novos dados bancários estarão disponíveis assim que forem aprovados',
    },
    history: { header: 'MÉTODOS DE PAGAMENTO' },
    cc: {
      field: {
        ccNum: 'Número do Cartão de Crédito',
        first6: 'Primeiros Seis',
        last4: 'Últimos Quatro',
        withrawlable: 'O levantamento máximo disponível para este cartão é {amount}.',
      },
      formValidation: { upload: 'A Foto do Cartão de Crédito é obrigatória' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'A buscar o seu estado de depósito... {countDown} segundos restantes.',
      successMsg: 'Obrigado pelo seu depósito, os seus fundos vão aparecer na sua conta em breve.',
      rejectMsg: 'Rejeitado',
      failureMsg: 'O seu depósito não foi bem sucedido. Por favor, tente novamente.',
      message:
        'Obrigado por depositar na sua conta. O seu depósito será aprovado manualmente pela equipa de contas da {platform} em breve. Os fundos serão depositados na sua conta assim que forem aprovados',
      defaultException:
        'Para confirmar o seu depósito com a {platform}, por favor entre em contacto com o suporte {email}',
    },
  },
  deposit: {
    default: {
      deposit: 'Depositar',
      reference: 'A sua referência',
      sendReceipt: 'Enviar recibo',
      sendReceiptTip:
        'Depois de fazer a transferência, por favor preencha o formulário abaixo e anexe uma captura de tela, foto ou cópia digitalizada do recibo da transferência bancária que mostre claramente o seu nome completo, número da conta e valor do depósito para que possamos processar a sua transação.',
      reminder:
        'Por favor, aguarde de 1 a 2 dias úteis para que os fundos sejam creditados na sua conta.',
      successMsg:
        'Os fundos vão aparecer na sua conta de negociação assim que recebermos o depósito e verificarmos o recibo da transação.',
      alt: 'Como alternativa, pode enviar o recibo da transação por e-mail para {mailTo} o mais brevemente possível.',
      reminderFundAppear:
        'Os seus fundos vão aparecer na sua conta assim que recebermos os fundos da sua corretora anterior.',
      failed: 'Falha no depósito, por favor tente novamente mais tarde.',
      fee: 'TAXA',
      instant: 'INSTANTÂNEO',
      hour: 'HORA',
      hours: 'HORAS',
      businessDays: 'DIAS ÚTEIS',
      disclaimer:
        '<li>1. {platform} strictly does not accept third-party payments. All funds deposited into your trading account must be under the same name as your {platform} trading account.</li>   \n        <li> </br>2. Clients acknowledge that they may be required to provide information on the source(s) of the deposit(s), the Clients’ wealth in general and any other information {platform} deems necessary to identify the source of funds when processing a deposit request and agree to provide all information required by {platform}. Failure to provide the required information may result in significant delaysin the process of your requests. {platform} will not be responsible for any delays or losses suffered as results of that delay.</li>\n        <li> </br>3. Client understands that submitting a deposit request does not constitute an acceptance of funds by {platform}. If your account is close to or in a margin call, please make sure you have other arrangements in place.</li>\n        <li> </br>4. {platform} will not be responsible for any delays/errors caused by payments systems.</li>\n        <li> </br>5. {platform} will not be responsible any fees (i.e. conversion fees, cash in advance fees, etc.) that the sending financial institution or third-party payment providers may charge you for sending payments.</li>\n        <li> </br>6. Informamos que a descrição da Moneta Markets no seu sistema de faturação aparecerá como <a href="https://www.monetamarkets.com/" target="_blank">www.monetamarkets.com</a> - Gladstonos & Filokyprou, 120, House Office E2<li>',
      channels: {
        creditOrDebit: 'Cartão de Crédito/Débito',
        i12BankTransfer: 'Transferência Bancária Internacional',
        auBankTransfer: 'Transferência Bancária na Austrália',
        bPay: 'Transferência Bancária de BPAY',
        skrill: 'Skrill/Moneybookers',
        sticPay: 'SticPay',
        b2b: 'Transferência de Corretora para Corretora',
        unionPayCN: 'Transferência China Union Pay',
        vietInstant: 'Transferência Bancária Instantânea do Vietnam',
        thaiInstant: 'Transferência Bancária Instantânea Tailandesa',
        nigeriaInstant: 'Transferência Bancária Instantânea da Nigéria',
        laosInstant: 'Transferência Bancária Instantânea de Laos',
        malayInstant: 'Transferência Bancária Instantânea Malaia',
        indonesiaInstant: 'Transferência Bancária Instantânea da Indonésia',
        philippineInstant: 'Transferência Bancária Instantânea Filipinas',
        mobilePay: 'Mobile Pay',
        poli: 'Pagamentos POLi',
        paypal: 'Depósito com Paypal',
        canadianlocalbanktransfer: 'Transferência Bancária Local Canadense',
        mijipay: 'Depósito com Mijipay',
        crypto: 'Criptomoeda',
        tinkbit: 'Depósito com Tinkbit',
        canada: 'Interac E-Transfer',
        bitwallet: ' Depósito com bitwallet',
        indiaInstant: ' Transferência Bancária Instantânea India',
        pagsmileBrazil: 'Transferência Bancária Instantânea Brasil',
        pagsmileMexico: 'Mexico Instant Bank Transfer',
        pagsmileColombia: 'Colombia Instant Bank Transfer',
        pagsmileChile: 'Chile Instant Bank Transfer',
        pagsmilePeru: 'Peru Instant Bank Transfer',
        trustly: ' Trustly',
        astropay: 'Depósito com Astropay',
        dasshpeWallet: 'Carteiras da Índia',
        brazilinstantbanktransfer: 'Transferência Bancária Brasil',
        southkoreabanktransfer: 'Transferência bancária para a Coreia do Sul',
        alipay: 'Alipay',
        indonesiainstantbanktransfer: 'Transferência Bancária Instantânea da Indonésia',
        thailandinstantbanktransfer: 'Transferência bancária instantânea Tailândia',
      },
      rate: {
        rate: 'Taxas de Câmbio({oldCurrency}/{newCurrency}): ',
        result: {
          rateError: 'Falha ao buscar a taxa de {oldCurrency} para {newCurrency}.',
          channelError: 'Falha ao buscar o canal de pagamento.',
        },
      },
      cn: { rate: 'Taxas de Câmbio(USD/RMB): ', amt: 'RMB: ' },
      viet: {
        result: { rateError: 'Falha ao buscar a taxa de USD para VND.' },
        rate: 'Taxas de Câmbio(USD/VND): ',
        amt: 'VND: ',
      },
    },
    method: {
      desc: 'Como depositar na sua conta com o {method}',
      inst1: '{number}.   Preencha o formulário abaixo e pressione em "Submeter"',
      inst2:
        '{number}.   Selecione o banco na página de seleção de bancos e inicie a sessão no seu banco online da {country}',
      inst3: '{number}.   ga as instruções e efetue o pagamento através do seu Banco Online /Móvel',
      inst4:
        '{number}.   Será redirecionado de volta ao portal do cliente e a sua transação será finalizada',
      inst5: '{number}.   Inicie a sessão na sua conta {method}',
      inst6: '{number}.   Siga as instruções na conta {method} para concluir o seu pagamento',
      inst7:
        'Por favor, tenha em atenção: Se escolher o processo de depósito manual, o seu financiamento pode causar atrasos e levar muito tempo a creditar na sua conta de negociação',
      inst8:
        '{number}.   Por favor, tenha em atenção que se estiver a utilizar {payment}, o montante do depósito é limitado a apenas {currency} {amount} por transação',
      form: { header: 'Formulário de {method}' },
      inst12: "{number}.   Complete o formulário abaixo e selecione 'Enviar'",
      inst13: '{number}.   Escaneie o código QR para abrir seu aplicativo bancário móvel',
      inst14: '{number}.   Siga as instruções no seu dispositivo móvel para concluir seu depósito',
      inst15: '{number}.   Preencha o formulário abaixo e pressione ‘Enviar’',
      inst16: "{number}.   Após inserir os dados do seu cartão, pressione 'Enviar'",
    },
    cpsInfo: {
      thispaymentchannelistemporaryunavailable:
        'Este canal de pagamento está temporariamente indisponível.',
    },
    uk: { header: 'Transferência Bancária Instantânea da Reino Unido', country: 'Reino Unido' },
    southAfrica: {
      header: 'Transferência Bancária Instantânea da África do Sul',
      country: 'África do Sul',
    },
    idn: { header: 'Transferência Bancária Instantânea da Indonésia', country: 'Indonésia' },
    ida: { header: 'Transferência Bancária Instantânea da India', country: 'India' },
    brazil: { header: 'Transferência Bancária Instantânea Brasil', country: 'Brasil' },
    nigeria: { header: 'Transferência Bancária Instantânea da Nigéria', country: 'Nigéria' },
    thailand: { header: 'Transferência Bancária Instantânea Tailândia', country: 'Tailândia' },
    vietnam: { header: 'Transferência Bancária Instantânea do Vietnam', country: 'Vietnam' },
    laos: {
      header: 'Transferência Bancária Instantânea de Laos',
      country: 'Laos',
      inst: '2.   Digitalize o código QR com a sua app OnePay',
    },
    hongkong: {
      country: 'Hong Kong',
      inst1: '2.   Siga as instruções e faça o pagamento',
      inst2: '3.   A sua transação será finalizada',
      inst3: 'Por favor, siga as instruções abaixo para efetuar o pagamento:',
      inst4: '1.   Por favor, inicie a sessão na sua conta bancária. ',
      inst5: '2.   Transfira os fundos para:',
      inst6:
        '3.   Após completar o pagamento, por favor, clique no botão CONCLUÍDO para prosseguir.',
      inst8: 'Aviso: ',
      inst9:
        'Este número de conta gerado é válido apenas para um depósito único e não deve ser utilizado para transações futuras, a fim de evitar atrasos na chegada de fundos.',
      inst10:
        'O montante de pagamento transferido deve corresponder ao montante submetido no pedido de depósito.',
      inst11:
        'Por favor, certifique-se de que efetua a sua transferência bancária dentro do prazo de 1 hora após ter submetido o pedido de depósito para evitar que o depósito falhe.',
    },
    malay: { header: 'Transferência Bancária Instantânea da Malásia', country: 'Malásia' },
    php: { header: 'Transferência Bancária Instantânea da Philippine', country: 'Philippine' },
    uganda: { header: 'Transferência de banco local de Uganda', country: 'Uganda' },
    rwanda: { header: 'Transferência de banco local de Ruanda', country: 'Ruanda' },
    zambia: { header: 'Transferência de banco local da Zâmbia', country: 'Zâmbia' },
    congo: { header: 'Transferência de banco local do Congo', country: 'Congo' },
    cameroon: { header: 'Transferência de banco local de Camarões', country: 'Camarões' },
    burundi: { header: 'Transferência bancária local do Burundi', country: 'Burundi' },
    kenya: { header: 'Transferência bancária local do Quênia', country: 'Quênia' },
    ghana: { header: 'Transferência de banco local de Gana', country: 'Gana' },
    tanzania: { header: 'Transferência de banco local da Tanzânia', country: 'Tanzânia' },
    bankWireAu: {
      header: 'TRANSFERÊNCIA BANCÁRIA: AUSTRÁLIA',
      instruction:
        'Os fundos podem ser transferidos para a {platform} através do serviço bancário online da sua instituição financeira ou, em alternativa, entrando em contacto com a instituição financeira e solicitando a transferência para a {platform}.',
      tip: 'Selecione a moeda da conta na qual deseja depositar. Por favor, certifique-se de que seleciona a mesma moeda que a moeda da sua conta de negociação',
      form: {
        bankDetails: 'Detalhes Bancários (Apenas Depósito em AUD)',
        refTip: 'Por favor, especifique aqui o seu Número de Cliente.',
        afp: 'Pagamento Rápido Australiano (APENAS Depósito Nacional em AUD)',
        payIdOrABNNum: 'PayID/Número ABN',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip:
          'Por favor, inclua o número da sua conta de negociação da {platform} no campo de referência da transferência bancária.',
      },
    },
    bPay: {
      header: 'DEPÓSITO BPAY',
      option: 'Como depositar na sua conta com o BPay (apenas na Austrália)',
      inst1:
        '1. Selecione a sua conta MT4/MT5 para visualizar o seu Código de Faturação BPay e o Número de Referência Bpay',
      inst2:
        '2. Insira os detalhes do pagamento, o seu Código de Faturação BPay e o Número de Referência BPay no seu serviço bancário online e submeta o seu pagamento',
      inst3:
        '3. Retorne a esta página e preencha o formulário BPay abaixo para garantir que poderemos processar a sua transação imediatamente',
      form: {
        header: 'FORMULÁRIO DO BPAY',
        billerCode: 'Código de faturação BPay',
        refNum: 'Número de Referência BPay',
        receipt: 'Recibo de transferência BPAY',
        reminderNotUploaded:
          'Nós reparámos que não fez o carregamento do recibo da transação. Para processarmos o seu depósito, por favor envie um e-mail com a cópia do recibo para {mailTo} o mais brevemente possível.',
        reminderProc:
          'Por favor, tenha em atenção. A sua transação NÃO PODE ser processada até recebermos o seu recibo.',
      },
    },
    b2b: {
      header: 'CORRETORA PARA CORRETORA',
      option: 'Como transferir fundos de outra corretora para a {platform}',
      inst1: '1. Descarregue e complete o {download}',
      inst2:
        '2. Preencha o formulário online abaixo e faça o carregamento do Formulário de Transferência de Corretora para Corretora preenchido',
      inst3:
        '3.   Envie uma cópia do seu formulário Broker to Broker preenchido para o seu corretor anterior para iniciar a transferência',
      b2bForm: 'Formulário de Corretora para Corretora',
      formValidation: { upload: 'O formulário de Corretora para Corretora é obrigatório' },
    },
    cc: {
      header: 'DEPÓSITO DE CARTÃO DE CRÉDITO OU DÉBITO',
      remind:
        'Caso tenha alguma dificuldade em depositar na sua conta utilizando o método Primário, por favor utilize o método Crédito/Débito Secundário',
      field: {
        fundNow: 'Deposite Agora com Cartão de Crédito / Débito',
        newCard: 'Novo Cartão',
        cardNum: 'Número do Cartão de Crédito',
        nameOnCard: 'Nome no cartão',
        expDate: 'Data de Validade',
        cvv: 'Código de Segurança CVV',
        bankCode: 'Banco Emissor do Cartão',
      },
      placeholder: { bankCode: 'Por favor, insira o nome do banco emissor do cartão' },
      cvvTip: 'Os 3 dígitos exibidos no <br />verso do seu cartão',
      threeDomainSecQ: 'O seu cartão suporta 3D-Secure?',
      threeDomainSec:
        'O que é autenticação 3D Secure? <br /><strong>A autenticação 3D Secure é um método de prevenção de fraudes reconhecido pela Visa e Mastercard.<br /><br />Se o seu cartão suportar o 3D Secure, os seus fundos serão processados imediatamente.</strong>',
      cardUpload:
        'O cartão que está a utilizar para depositar fundos não foi vinculado à sua conta de negociação. Por favor, faça o carregamento de uma foto da frente do seu cartão para verificação. (Por favor, oculte os 6 dígitos do meio)',
      result: {
        success:
          'Obrigado. Neste momento, estamos a verificar os detalhes do seu cartão e, uma vez verificados, os fundos deverão aparecer na sua conta dentro de 24 horas.',
        depositSuccess:
          'Obrigado pelo seu depósito, os seus fundos vão aparecer na sua conta em breve',
        error:
          'De modo a proteger a segurança da sua conta, já atingiu o máximo de tentativas de transações de depósito e suspendemos temporariamente os seus depósitos. Por favor, entre em contacto com {email}.',
      },
      mastercardonly: 'Por favor, continue a usar este canal de pagamento apenas com Mastercard.',
    },
    fasa: {
      header: 'DEPÓSITO COM FASAPAY',
      description: 'Como depositar na sua conta com o FasaPay',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Inicie a sessão na sua conta FasaPay',
      inst3: '3. Siga as instruções na conta FasaPay para concluir o seu pagamento',
      inst4:
        '4. Os depósitos Fasapay são limitados a $25.000 USD por transação e um máximo de $75.000 USD por dia.',
      form: { header: 'FORMULÁRIO FASAPAY' },
    },
    miji: {
      header: 'Transferência Bancária Instantânea Tailandesa',
      description:
        'Como depositar na sua conta com a Transferência Bancária Instantânea da Tailândia',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Você será redirecionado para a página de pagamento',
      inst3:
        '3. Inicie a sessão no seu app bancário móvel e leia o código QR na página de pagamento',
      inst4:
        'Por favor, tenha em atenção: <b>O sistema irá adicionar alguns centavos ao valor solicitado.</b>',
      form: { header: 'Formulário de Transferência Bancária Instantânea da Tailândia.' },
    },
    intSwift: {
      header: 'TRANSFERÊNCIA BANCÁRIA: SWIFT INTERNACIONAL',
      desc: 'Os fundos podem ser transferidos para a {platform} através do serviço bancário online da sua instituição financeira ou, em alternativa, entrando em contacto com a instituição financeira e solicitando a transferência para a {platform}.',
      desc2:
        'Depois de fazer a transferência, por favor preencha o formulário abaixo e anexe uma cópia do recibo da transferência bancária que mostre claramente o seu nome completo, número da conta e valor do depósito para que possamos processar a sua transação.',
      options: 'Por favor, selecione a conta de negociação que deseja financiar',
      refInfo:
        'Por favor, inclua o número da sua conta de negociação da {platform} no campo de referência da transferência bancária.',
      bussDayInfo:
        'Por favor, aguarde de 2 a 5 dias úteis para que os fundos sejam creditados na sua conta.',
      tip: 'Por favor, verifique se o recibo da transferência mostra claramente o <b>nome completo</b>, <b> número da conta</b> e o <b>valor do depósito</b>.',
    },
    mobile: {
      header: 'MOBILE PAY',
      desc: 'Como depositar na sua conta com o Mobile Pay',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Leia o código QR para abrir a sua aplicação bancária móvel',
      inst3: '3. Siga as instruções no seu dispositivo móvel para concluir o seu depósito',
      form: { payMethod: 'Método de pagamento:', rate: 'Taxas de Câmbio (USD/CNY):', amt: 'CNY:' },
      formValidation: { cnyAmt: 'O Valor CNY não pode exceder $ {limit}' },
    },
    net: {
      header: 'Neteller',
      inst2: '2.   Inicie a sessão na sua conta Neteller',
      inst3: '3.   Siga as instruções na conta Neteller para concluir o seu pagamento',
      inst4: {
        fca: '4.   Podemos aceitar o pagamento em USD, EUR e GBP',
        default: '4.   Podemos aceitar o pagamento em AUD, USD, EUR, GBP e SGD',
      },
    },
    canada: {
      header: 'Interac E-Transfer',
      desc: 'Como depositar na sua conta com o  Interac E-Transfer',
      inst1: '1.   Preencha o formulário abaixo e pressione em "Submeter"',
      inst2:
        '2.   Selecione o banco na página de seleção de bancos e inicie a sessão no seu banco online',
      inst3: '3.   Siga as instruções e efetue o pagamento através do seu Banco Online /Móvel',
      inst4: '4.   Podemos aceitar o pagamento em CAD. Máximo de $9.999 CAD por depósito',
      form: { header: 'FORMULÁRIO Interac E-Transfer' },
    },
    bitwallet: {
      header: 'DEPÓSITO COM BITWALLET',
      desc: 'Como depositar na sua conta com o BITWALLET',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Inicie a sessão na sua conta Bitwallet',
      inst3: '3. Siga as instruções na conta Bitwallet para concluir o seu pagamento',
      inst4: '4. Podemos aceitar o pagamento em USD, EUR e JPY',
      form: { header: 'FORMULÁRIO BITWALLET' },
    },
    astropay: {
      header: 'DEPÓSITO COM ASTROPAY',
      desc: 'Como depositar na sua conta com o ASTROPAY',
      inst2: '2. Inicie a sessão na sua conta ASTROPAY',
      inst3: '3. Siga as instruções na conta ASTROPAY para concluir o seu pagamento',
      inst4: '4. Podemos aceitar pagamentos em todas as moedas.',
      form: { header: 'FORMULÁRIO ASTROPAY' },
    },
    crypto: {
      choose: 'Por favor, escolha a sua criptomoeda preferida',
      proceedQuestion: 'Você deseja prosseguir com seu depósito <br /><b>{method}</b>?',
      remark:
        'Por favor, tenha em atenção que não podemos depositar ou levantar via BUSDT, por favor, certifique-se de que o endereço e a moeda Criptomoeda correspondem à corrente e moeda que aceitamos ou poderá perder o fundo.',
      inst2:
        '2. Você será redirecionado para uma nova janela exibindo um endereço de carteira {method}',
      inst3:
        '3. Copie o endereço da carteira de {method} e depois inicie a sessão na sua carteira de {method} pessoal e transfira o valor desejado para o endereço {method}.',
      inst4:
        'Por favor, tenha em atenção: os depósitos de Bitcoin são processados pelo B2BinPay à taxa de câmbio fornecida no momento da transação.',
    },
    poli: {
      header: 'DEPÓSITO POLI',
      desc: 'Como depositar na sua conta com o Poli',
      reminder:
        '<li>Por favor, tenha em atenção:</li><li>* O depósito deve estar no mesmo nome da sua conta de {platform}</li><li>* Os depósitos devem estar apenas em AUD</li>',
      form: { header: 'FORMULÁRIO DO POLI' },
    },
    skrill: {
      header: 'DEPÓSITO SKRILL/MONEYBOOKERS',
      desc: 'Como depositar na sua conta com o Skrill',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Inicie a sessão na sua conta Skrill',
      inst3: '3. Siga as instruções na conta Skrill para concluir o seu pagamento',
      inst4: '4. Podemos aceitar o pagamento em USD, EUR, GBP e CAD',
      inst4FCA: '4. Podemos aceitar o pagamento em USD, EUR e GBP',
      form: { header: 'FORMULÁRIO DO SKRILL', email: 'E-mail do Skrill/Moneybookers' },
      reminder:
        '<li>Por favor, tenha em atenção:</li>\n        <li>* As contas só podem ser financiadas na mesma moeda da sua conta de negociação MT4. O Skrill irá converter os fundos automaticamente para a moeda base da sua conta se for utilizado o e-mail incorreto. Podem ser aplicadas taxas.</li>\n        <li>* Os depósitos devem ter o mesmo nome da sua conta {platform}.</li>',
    },
    sticPay: {
      inst2: '2.   Inicie a sessão na sua conta SticPay',
      inst3: '3.   Siga as instruções na conta SticPay para concluir o seu pagamento',
    },
    webMoney: {
      header: 'WEBMONEY',
      inst4: '4.   Podemos aceitar o pagamento em USD e EUR',
      inst5: '5.   Os depósitos WebMoney são limitados a 1300 USD/1100 EUR por transação.',
    },
    vload: {
      header: 'VLOAD',
      inst1: '2.   Podemos aceitar pagamentos em USD, EUR e JPY.',
      inst2:
        '<li>3.   Comprar voucher Vload, clique <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">aqui</a>.</li>',
      form: { voucherpin: 'Pin do Voucher', voucherpinReq: 'É necessário o PIN do Voucher' },
    },
    dasshpe: {
      headerWallet: 'Carteiras da Índia',
      inst1: '2.   Aprove a transação a partir da sua aplicação UPI',
      inst2: '2.   Siga as instruções e faça o pagamento através da sua app de carteira',
    },
    thaiPaytoday: {
      header: 'Transferência Bancária Instantânea Tailandesa',
      desc: 'Como depositar na sua conta com a Transferência Bancária Instantânea da Tailândia',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Siga as instruções para concluir o seu pagamento',
      form: { header: 'Formulário de Transferência Bancária Instantânea da Tailândia.' },
      scan: 'Digitalize para fazer um depósito',
      exp: 'Expira em {expireTime}',
      reminder:
        'Os fundos devem aparecer na sua conta dentro de 1 hora. <br/><br/>\n      Pode visualizar o estado atual do seu depósito no seu "histórico de transações". <br/>\n      Se houver algum problema com o seu depósito, por favor entre em contacto com o seu gestor de conta',
    },
    unionPay: {
      header: 'Depósito com Union Pay',
      desc: 'Como depositar na sua conta com o UnionPay',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2:
        '2. Selecione o banco na página de seleção de bancos e inicie a sessão no seu banco online',
      inst3: '3. Siga as instruções e efetue o pagamento através do seu Banco Online /Móvel',
      form: { header: 'FORMULÁRIO DO UNION PAY' },
    },
    unionPayCN: {
      header: 'Transferência China Union Pay',
      desc: 'Como depositar na sua conta com a Transferência China Union Pay',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Será exibida uma página de detalhes do Pagamento',
      inst3: '3. Siga as instruções e efetue o pagamento através do seu Banco Online /Móvel',
      inst4:
        'Por favor, tenha em atenção: pode ser-lhe solicitado o número de adesão ao fazer um depósito; por favor, insira o número da sua conta de negociação como número de adesão',
      form: {
        header: 'FORMULÁRIO DO CHINA UNION PAY',
        holderName: 'Nome do Titular do Cartão UnionPay:',
        holderNameInfo:
          'Por favor, certifique-se de que o nome inserido acima seja igual ao nome do titular do seu cartão bancário',
      },
    },
    vietZota: {
      header: 'Transferência Bancária Instantânea do Vietnam',
      desc: 'Como depositar na sua conta com a Transferência Bancária Instantânea do Vietnam',
      inst: '<li>1.   Preencha o formulário abaixo e pressione em "Submeter"</li><li>2.   Selecione o banco na página de seleção de bancos e inicie a sessão no seu banco online do Vietnam</li><li>3.   Siga as instruções e efetue o pagamento através do seu Banco Online /Móvel</li> <li>4.   Será redirecionado de volta ao portal do cliente e a sua transação será finalizada</li>',
      form: { header: 'Formulário de Transferência Bancária Instantânea do Vietnam' },
    },
    paypal: {
      header: 'PayPal',
      desc: 'Como depositar na sua conta com o PayPal',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Inicie a sessão na sua conta PayPal',
      inst3: '3. Siga as instruções na conta do PayPal para concluir o seu pagamento',
      inst4: 'Por favor, tenha em atenção: Nós não podemos aceitar pagamentos de terceiros',
      form: { header: 'FORMULÁRIO DO PAYPAL' },
    },
    cardpay: {
      desc: 'Como depositar na sua conta com Cartão de Crédito',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2:
        '2. Será então redirecionado para outro formulário para inserir os detalhes do seu cartão',
      inst3: '3. Depois de inserir os detalhes do seu cartão, pressione "Submeter"',
      note: "Por favor, tenha em atenção que a descrição da transação que aparece na declaração do seu cartão irá variar dependendo da via financeira utilizada. Se tiver alguma preocupação sobre a descrição da transação, por favor contacte o <a href='mailto:{mail}'>{mail}</a>",
      form: { header: 'FORMULÁRIO DO CARTÃO DE CRÉDITO' },
    },
    tinkbit: { inst5: '5. Máximo de $1500 por depósito' },
    trustly: {
      header: 'Trustly',
      desc: 'Como depositar na sua conta com o Trustly',
      inst1: '1. Preencha o formulário abaixo e pressione em "Submeter"',
      inst2: '2. Inicie a sessão na sua conta Trustly',
      inst3: '3. Siga as instruções na conta do Trustly para concluir o seu pagamento',
      inst4: '4. Podemos aceitar o pagamento em EUR e GBP',
      form: { header: 'FORMULÁRIO DO TRUSTLY' },
      reminder:
        '<li>Por favor, tenha em atenção:</li>\n        <li>* As contas só podem ser financiadas na mesma moeda da sua conta de negociação MT4. O Skrill irá converter os fundos automaticamente para a moeda base da sua conta se for utilizado o e-mail incorreto. Podem ser aplicadas taxas.</li>\n        <li>* Os depósitos devem ter o mesmo nome da sua conta {platform}.</li>',
    },
    fxir: {
      desc: '',
      desc2: '',
      field: {
        id: 'ID do remetente FX-IR',
        accName: 'Nome da conta FX-IR',
        walletNumber: 'Número da carteira FX-IR',
        transactionID: 'ID do remetente FX-IR',
        senderFxirID: 'ID do remetente FX-IR',
      },
    },
  },
  restrictDeposit: {
    header: 'NOTIFICAÇÃO IMPORTANTE',
    info: 'Infelizmente, já não estamos a aceitar depósitos de clientes residentes no Canadá.',
  },
  withdraw: {
    default: {
      selectCC: 'Selecione o cartão de crédito',
      selectBA: 'Selecione a Conta Bancária',
      anotherCC: 'Digite o cartão de crédito manualmente',
      anotherBA: 'Adicionar conta bancária',
      remember: 'Lembrar minha conta',
      success: 'O seu pedido de levantamento foi bem-sucedido e será processado brevemente',
      ccFail:
        'De modo a proteger a segurança da sua conta, já atingiu o máximo de tentativas de transações de depósito e suspendemos temporariamente os seus levantamentos. Por favor, entre em contacto com {email}.',
      fetchTradeAccFailed: 'Falha na busca da Conta de Negociação',
      failed: 'O levantamento falhou. Por favor, tente novamente mais tarde',
      notEnoughFunds: 'O valor inserido excede o limite para este cartão',
      cardExpird:
        'Este cartão expirou. Por favor, escolha outro cartão ou outra forma de pagamento.',
      minAmountWarn:
        'O montante de levantamento solicitado é inferior ao mínimo do portal de pagamento solicitado. Por favor carregue o saldo da sua conta no montante mínimo ou superior antes de submeter um pedido de levantamento. Poderá consultar mais informações <a href="https://{regulatorUrl}/clients/accounts/withdraw/">aqui</a>.',
      noCardWarn:
        'Você não possui nenhum cartão disponível para fazer o levantamento, por favor, em primeiro lugar registe o seu cartão em Detalhes de Levantamento.',
      equityStop:
        'Infelizmente, o seu levantamento não pode ser processado, porque vai resultar na queda da margem disponível da sua conta abaixo do nível permitido de 100%',
      equityMargin:
        'Infelizmente, atualmente não podemos processar seu saque porque sua conta está totalmente coberta.',
      equityContinue:
        'Detectamos que atualmente você tem posições em aberto; poderá ocorrer uma interrupção após a submissão do pedido de levantamento, por favor confirme.',
      YourPaymentGreaterThanAvailableBalance:
        'O valor da sua transferência é superior ao saldo disponível',
      blackList:
        'Infelizmente, não pode atualmente submeter um pedido de levantamento. Por favor contacte {supportEmail} para mais informações',
      NetellerChargeAlert:
        'As retiradas do Neteller são limitadas a $ 500.000 por transação e aplica-se uma taxa de 2% (limitada a $ 30).',
      FasaPayChargeAlert: 'O FasaPay cobra uma taxa de transação de 0,5% para cada retirada.',
      SkrillChargeAlert: 'Skrill cobra taxa de transação de 1% para cada retirada.',
      phone: 'Celular',
      evp: 'Chave Aleatória',
      localBankTransfer: 'Local Bank Transfer',
    },
    fasa: {
      field: { accName: 'Nome da conta do FasaPay', accNum: 'Número da conta do FasaPay' },
      formValidation: {
        accNameReq: 'O nome da conta do FasaPay é obrigatório',
        accNumReq: 'O número da conta do FasaPay é obrigatório',
      },
    },
    neteller: {
      field: { email: 'E-mail do Neteller' },
      formValidation: { emailReq: 'E-mail do Neteller é obrigatório' },
    },
    bitcoin: {
      field: { address: 'Endereço da Carteira Bitcoin' },
      formValidation: { addressReq: 'O endereço da Carteira Bitcoin é obrigatório' },
    },
    eth: {
      field: { address: 'Endereço da Carteira ETH' },
      formValidation: { addressReq: 'O endereço da Carteira ETH é obrigatório' },
    },
    usdc: {
      field: { address: 'Endereço da Carteira USDC' },
      formValidation: { addressReq: 'O endereço da Carteira USDC é obrigatório' },
    },
    usdt: {
      field: { address: 'Endereço da carteira USDT' },
      formValidation: { addressReq: 'O endereço da Carteira USDT é obrigatório' },
    },
    paypal: {
      field: { email: 'E-mail do Paypal' },
      formValidation: { emailReq: 'O e-mail do Paypal é obrigatório' },
    },
    skrill: {
      field: { email: 'E-mail do Skrill' },
      formValidation: { emailReq: 'O e-mail do Skrill é obrigatório' },
    },
    webMoney: {
      field: { email: 'E-mail do WebMoney' },
      formValidation: { emailReq: 'O e-mail do WebMoney é obrigatório' },
    },
    vload: {
      field: { email: 'E-mail Registado Vload' },
      formValidation: { emailReq: 'É necessário o E-mail de Vload Registado' },
    },
    sticpay: {
      field: { email: 'E-mail do SticPay' },
      formValidation: { emailReq: 'O e-mail do SticPay é obrigatório' },
    },
    bitwallet: {
      field: { email: 'E-mail do Bitwallet' },
      formValidation: { emailReq: 'O e-mail do Bitwallet é obrigatório' },
    },
    astropay: {
      field: { accountNumber: 'Conta Astropay' },
      formValidation: { accountNumberReq: 'É necessária uma conta Astropay' },
    },
    transfer: {
      info: 'A {platform} irá reembolsar a taxa bancária cobrada por <u>UM</u> levantamento por mês. Os levantamentos adicionais dentro do mês incorrem numa taxa bancária de 20 unidades da sua moeda de levantamento.',
      label: {
        upload:
          'Por favor, faça o carregamento de uma cópia do seu extrato bancário (dos últimos 3 meses correntes)',
        upload2:
          'Certifique-se de que a data do extrato ou a data da última transação esteja dentro do mês atual até os últimos três meses. Se ultrapassar esse período, por favor, faça o reenvio do extrato.',
      },
      formValidation: { upload: 'Por favor, faça o envio de um extrato bancário' },
    },
    ccMethod: {
      desc1:
        'Devido à conformidade com AML/CTF, os fundos devem ser transferidos para o cartão utilizado no seu depósito inicial',
      desc2:
        'Se o cartão usado para colocar fundos na sua conta estiver indisponível, por favor contacte {mailto}',
    },
    otherMethod: {
      otherType: 'Outros tipos',
      withdrawalMethod: 'Método de Levantamento',
      desc1:
        'Por favor, tenha em consideração que as transferências bancárias podem incorrer numa taxa. Por favor, certifique-se de que o montante do seu levantamento é suficiente para cobrir quaisquer taxas que possam ser cobradas.',
      desc2:
        'Devido à conformidade AML/CTF, todos os levantamentos devem ser transferidos utilizando o mesmo método utilizado para financiar a sua conta.',
      note: "Devido a alterações recentes, o nosso banco poderá não conseguir processar transferências bancárias internacionais para clientes registados nalguns países.<br/>\n      Se tiver de efetuar levantamentos através de transferências bancárias internacionais, por favor contacte o nosso Apoio ao Cliente pelo telefone +44 2080 363 883 ou pelo endereço <a href='mailto:{mail}'>{mail}</a>.<br/>\n      Pedimos desculpa pelos inconvenientes.",
      verificationCode:
        'O código de verificação foi enviado para {email}. Por favor, entre em contacto com {supportEmail} se precisar de mais assistência',
      note2:
        'Devido ao impacto da política da Coreia do Sul sobre o Travel Rule, não aceitaremos saques de endereços de exchanges sul-coreanas que excedam 735 USDT (aproximadamente 1.000.000 KRW) por transação, ou de clientes cujos endereços não tenham passado pela verificação KYC.',
    },
  },
  transfer: {
    creditWarn:
      'Não podemos processar a sua transferência de fundos da conta selecionada uma vez que a sua conta contém crédito promocional.',
    blackListWarn:
      'A transferência de fundos da sua conta MAM pode resultar na liquidação de posições geridas. Por favor, entre em contacto com {mailTo} se desejar transferir fundos desta conta.',
  },
  changePw: {
    header: 'ALTERAR PALAVRA-PASSE',
    field: {
      currPw: 'Palavra-passe Atual',
      newPw: 'Nova Palavra-passe',
      confirmPw: 'Confirmar a Nova Palavra-passe',
    },
    succ: 'A sua palavra-passe foi atualizada. Já pode utilizar a sua nova palavra-passe para aceder ao Portal do Cliente.',
  },
  security: {
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    understand: 'Eu entendo',
    passwordRenewalReminder: {
      header: 'Alterar a senha de login',
      desc: 'Para garantir a segurança dos fundos na sua conta, os saques não serão suportados por T+{days} dias após a alteração da sua senha de login.',
    },
    withdrawalFreeze: {
      header: 'As retiradas não são temporariamente suportadas',
      userLoginDesc:
        'Devido à modificação do seu e-mail/número de telefone de login, os saques não são temporariamente suportados por T+{days} dias. Esta restrição deverá ser levantada em {hours} horas.',
      passDesc:
        'Devido à modificação da sua senha de login, os saques não são temporariamente suportados por T+{days} dias para garantir a segurança da sua conta. Esta restrição deverá ser levantada em {hours} horas.',
      limitSecurityAuthenticator:
        'Devido à modificação do seu aplicativo Autenticador de Segurança, os saques não são temporariamente suportados por T+{days} dias para garantir a segurança de sua conta. Essa restrição deve ser removida em {hours} horas.',
    },
    securityManagement: 'Gestão de Segurança',
    securityAuthentication: 'Autenticação de Segurança',
    authenticationMethod: 'Método de Autenticação',
    isVerified: 'Verificado',
    isNotVerified: 'Não verificado',
    changeAuthenticatorTitle: 'Alterar Autenticador de Segurança',
    password: 'Palavra-passe',
    securityAuthenticatorApp: 'Aplicativo Autenticador de Segurança',
    enableLoginAuthentication: 'Ativar Autenticação de Login',
    loginLocationChange: 'Notificação por Email de Alteração de Local de Login',
    lastUpdate: 'Última Atualização',
    modify: 'Modificar',
    verify: 'Verificar',
    enable: 'Ativar',
    resend: 'Reenviar',
    enterVerificationCode: 'Inserir código de verificação',
    verifyPreEmailNote: 'O código será enviado para {email}',
    verifyPostEmailNote: 'Código enviado para {email}',
    preEmailCode: 'O código será enviado para o seu novo endereço de e-mail',
    authenticatorTooltip:
      'O código de verificação do aplicativo Authenticator é atualizado a cada 30 segundos',
    contactSupport:
      'Não é possível acessar o método de autenticação? Por favor, entre em contato com {contact_email} ou Livechat',
    changeEmail: 'Alterar Endereço de Email',
    emailTitle: 'Introduzir novo endereço de email',
    editAuthenticator: 'Editar Dispositivo de Autenticação',
    addAuthenticator: 'Adicionar Dispositivo Autenticador',
    scanWithApp: 'Digitalizar com o aplicativo Authenticator',
    scanAppTooltip:
      'Pode baixar o Google Authenticator ou o Microsoft Authenticator na loja de aplicativos do seu telefone. Em seguida, use a câmera no aplicativo para escanear o código QR fornecido.',
    scanAppTooltipForChina:
      'Você pode baixar o Google Authenticator ou o Microsoft Authenticator na loja de aplicativos do seu telefone, ou buscar o Google Authenticator no Mini Programa do WeChat e escolher o primeiro resultado. Em seguida, use a câmera do aplicativo para escanear o código QR fornecido.',
    scanAuthenticatorAppTips: {
      tips1:
        'Você pode baixar o Google Authenticator ou o Microsoft Authenticator na loja de aplicativos móveis',
      tips2:
        'Ou você também pode pesquisar o Google Authenticator no Mini Programa do WeChat e selecionar o primeiro resultado.',
      tips3:
        'Em seguida, utilize a câmera dentro do aplicativo para escanear o código QR fornecido',
    },
    userGuide: 'Guia do Utilizador',
    setupKey: 'Chave de Configuração',
    tnc: 'Ao vincular um aplicativo de autenticação, você confirma que leu e aceitou o {link}.',
    disclaimer: 'aviso legal',
    changePasswordSuccess: 'Senha Atualizada',
    changeEmailSuccess: 'Endereço de Email Atualizado',
    enableSecuritySuccess: 'Autenticador de Segurança Ativado',
    updateSecuritySuccess: 'Autenticador de Segurança Atualizado',
    changePasswordSuccessDesc:
      'A sua palavra-passe foi atualizada. <br/> Agora pode usar uma nova palavra-passe para iniciar sessão.',
    changeEmailSuccessDesc:
      'O seu endereço de e-mail foi atualizado. <br/> Agora pode usar um novo endereço de e-mail para iniciar sessão.',
    enableSecuritySuccessDesc: 'O seu aplicativo de autenticação de segurança foi ativado.',
    updateSecuritySuccessDesc: 'O seu aplicativo de autenticação de segurança foi atualizado.',
    passwordReq: 'Requisitos de Senha:',
    passwordCharacters: '8-16 caracteres',
    passwordMix: 'Uma mistura de letras (a-z e A-Z) e números (0-9)',
    passwordSpecial: 'Caracteres especiais como !@#$%^&*().',
    confirmPwd: 'Confirmar Senha',
    enableNow: 'Ativar agora',
    changeDesc:
      'Para garantir a segurança dos fundos em sua conta, os saques não serão suportados durante T+ {numDays} dias após a mudança do seu {method}.',
    verificationTitle: 'Verificação necessária',
    verificationDesc:
      'Por favor, ative o aplicativo Authenticator de Segurança antes de prosseguir.',
    updateSuccess: 'Atualizado com sucesso',
    verifiedSuccess: 'Verificado com sucesso',
    verificationRequired: 'Por favor, insira o código de verificação',
    next: 'Próximo',
  },
  reserPwMobile: { header: 'REDEFINIR PALAVRA-PASSE' },
  downloads: {
    appDesc: 'Descarregue a App da {platform} na App Store e na Google Play Store',
    mt4: 'METATRADER 4',
    mt4Desc:
      'Descarregue a MetaTrader 4 Para PC, Mac, navegadores de internet, smartphones e tablets',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android',
    web: 'WebTrader',
    webTrader: 'Web Trader',
    smartTrader: 'Ferramentas SmartTrader',
    mt5: 'METATRADER 5',
    mt5Desc:
      'Descarregue a MetaTrader 5 Para PC, Mac, navegadores de internet, smartphones e tablets',
    button: { download: 'DESCARREGAMENTOS', launch: 'iniciar' },
  },
  forgetPwReq: {
    headerMobile: 'INSIRA O SEU NÚMERO DE TELEFONE PARA INÍCIO DE SESSÃO',
    labelEmail: 'E-mail',
    labelMobile: 'Número de Telefone',
    loginMobile: 'Número de Telefone para Início de Sessão',
    loginSMSCode: 'Código SMS',
    loginSmsCodeExpires: 'O código SMS expira dentro de 5 minutos',
    getCode: 'Enviar código SMS',
    failedCode: 'Falha no envio do Código SMS para o seu telefone',
    sentAlready: 'enviado',
    expired: 'O código SMS expirou',
    tip: 'Digite o endereço de e-mail de sua conta para redefinir sua senha.',
    formValidation: {
      emailReq: 'O e-mail de início de sessão é obrigatório',
      mobileReq: 'Início de sessão móvel obrigatório',
      smsCodeReq: 'o código sms é obrigatório',
    },
    succ: 'Acabamos de lhe enviar um e-mail com instruções para redefinir a sua palavra-passe.<br><br>Por favor, certifique-se de que verifica a pasta de spam do seu e-mail.',
  },
  loginClient: {
    header: 'PORTAL DO CLIENTE',
    failedMasseage: 'Por favor, tente novamente.',
    existingClientPortalAccess:
      'Verificamos que já tem acesso ao Portal do Cliente.<br /><br />Inicie a sessão utilizando o seu e-mail para abrir uma nova conta!',
    existingClientPortalAccessMobile:
      'Vemos que você já tem acesso ao Portal do Cliente.<br /><br /> Registre-se usando seu número de telefone para abrir uma nova conta!',
    formValidation: {
      emailReq: 'O endereço de E-mail é obrigatório',
      mobileReq: 'Por favor, insira apenas valores numéricos',
    },
  },
  myProfile: {
    changeDetails:
      'Se desejar atualizar os detalhes do seu perfil existente, não hesite em entrar em contacto com {mailTo}',
  },
  openAdditAcc: {
    chooseTradePlat: 'ESCOLHA UMA PLATAFORMA DE NEGOCIAÇÃO',
    chooseAccType: 'ESCOLHA UM TIPO DE CONTA',
    chooseCurrency: 'ESCOLHA UMA MOEDA DE CONTA',
    chooseLeverage: 'Escolha Alavancagem',
    chooseBalance: 'SALDO DA CONTA',
    notes: 'NOTAS ADICIONAIS',
    currError: 'Selecione uma moeda de base para sua conta',
    platErr: 'Deve selecionar uma plataforma de negociação',
    accTypeErr: 'Deve selecionar um tipo de conta',
    leverageErr: 'Você deve selecionar uma alavancagem',
    balanceErr: 'Você deve selecionar um saldo',
    succ: '<p>A sua conta real adicional está a ser configurada e brevemente receberá um e-mail com os seus detalhes de acesso.</p><p>Pode visualizar o estado atual da sua inscrição na página inicial.</p>',
    byTicking: 'Ao marcar esta caixa:',
    swapFreeNote:
      '*Por favor, tenha em atenção: Em vez de taxas de swap, poderá ser aplicada uma pequena taxa administrativa.',
    pammNote:
      '*As contas PAMM são apenas para investidores com um acordo de Gestor de Dinheiro existente. As contas PAMM não podem ser negociadas por ninguém, exceto pelo Gestor de Dinheiro.',
  },
  openDemoAdditAcc: {
    header: 'ABRA UMA CONTA DE DEMONSTRAÇÃO ADICIONAL',
    button: 'abrir conta de demonstração',
    liveButton: 'abrir conta real',
    succ: 'A sua conta de demonstração adicional foi configurada e brevemente receberá um e-mail com seus detalhes de acesso.',
  },
  resetPassword: {
    header: 'REDEFINIR PALAVRA-PASSE',
    succ: 'A sua palavra-passe foi atualizada. Já pode utilizar a sua nova palavra-passe para aceder à sua conta de negociação da MT4/MT5.',
    succ2: 'A sua palavra-passe foi atualizada.',
    failed: 'O token expirou',
    emailNotFound: 'Infelizmente o seu endereço de e-mail não foi reconhecido',
  },
  fileUploader: {
    supportedFormat: 'Tipos de ficheiros suportados: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Tipos de ficheiros suportados: {files}',
    maxSize: 'Tamanho máximo do ficheiro para carregamento: {maxSize}MB',
    maxFileNum: 'Número máximo de ficheiros: {maxFileNum}',
    failedFileExtension: 'Carregue o tipo de arquivo correto',
  },
  transcationHistory: {
    deposit: 'DEPOSITAR',
    withdraw: 'LEVANTAR',
    desc: {
      incomplete:
        'Se terminar ou não concluir o processo de depósito, o estado da transação será exibido como "incompleto". Será solicitado que crie um novo depósito, uma vez que esta transação não pode ser retomada.',
      success:
        'A sua transação foi bem sucedida. Os seus fundos deverão aparecer na sua conta brevemente',
      proc: 'A sua transação está a ser processada. Por favor, entre em contacto com {mailTo}',
      failed:
        'A sua transação falhou. Por favor, entre em contacto com {mailTo} para obter mais informações',
      rejected:
        'A sua transação foi rejeitada. Por favor, entre em contacto com {mailTo} para obter mais informações',
    },
    withdrawDesc: {
      sub: 'A sua transação foi submetida. Por favor, aguarde que seja concluída.',
      suc: 'A sua transação foi bem sucedida. Os seus fundos deverão aparecer na sua conta brevemente',
      proc: 'A sua transação está a ser processada. Por favor, entre em contacto com {mailTo}',
      incomp:
        'A sua transação requer etapas adicionais para ser finalizada. Por favor, entre em contacto com {mailTo} para obter mais informações',
      cancel:
        'A sua transação foi cancelada. Por favor, entre em contacto com {mailTo} para obter mais informações',
      fail: 'A sua transação falhou. Por favor, entre em contacto com {mailTo} para obter mais informações',
      rej: 'A sua transação foi rejeitada. Por favor, entre em contacto com {mailTo} para obter mais informações',
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'Infelizmente, a sua transferência não pode ser processada porque resultará na queda da margem disponível na sua conta abaixo do nível de stop out de 100%',
      equityMargin:
        'Infelizmente, não podemos, de momento, processar a sua transferência porque a sua conta está totalmente coberta.',
      equityContinue:
        'Detetámos que tem atualmente posições em aberto, poderá enfrentar stop out depois de submeter o seu pedido de transferência. Por favor, confirme se deseja prosseguir.',
      blackList:
        'Esta conta foi restringida de fazer transferências entre contas, por favor, contacte {supportEmail}',
    },
    pleaseNote: 'Por favor, tenha em atenção:',
    suc: 'Os seus fundos foram transferidos com sucesso',
    warn1:
      'Você é responsável por manter um saldo de conta que não acione um aviso de margem. Se tem alguma posição em aberto no momento, por favor certifique-se de que mantém a margem necessária na sua conta. Os fundos transferidos para as contas mantidas na mesma moeda são processados instantaneamente.',
    warn2:
      'Se estiver a transferir fundos entre contas mantidas em moedas diferentes, envie um e-mail para {mailTo}.',
    warn3:
      'Por favor, tenha em atenção que os fundos não podem ser transferidos entre contas em modo somente leitura.',
  },
  transferHistory: {
    payStatus: {
      processing: 'Em Processamento',
      paid: 'Pago',
      unpaid: 'Por pagar',
      rejected: 'Rejeitado',
    },
  },
  identityProof: {
    home: {
      header: 'INSCRIÇÃO DE CONTA INCOMPLETA',
      content:
        'Olá, {name}, a sua inscrição de conta está quase completa, no entanto, ainda é necessário enviar documentação adicional para finalizar a sua conta.',
    },
    shufti: {
      content:
        'Certifique-se de que os detalhes fornecidos correspondem à sua documentação de identificação e estão no mesmo idioma.',
      countryOfDocument: 'País conforme mostrado no documento',
    },
    popup: {
      header:
        'Olá, {name}, antes que a sua conta real possa ser finalizada, é necessário fazer o carregamento do seguinte documento para verificação.',
      addressProofSpan:
        '<strong>\n      <b>Confirmar Endereço</b>\n      </strong>\n      <br />\n      <I>\n        Submeta <b>um</b> dos documentos abaixo\n        <br /><br />\n      </I>\n      - Cópia de uma Fatura de Serviços recente </br> (com até 6 meses de validade)\n      <br />\n      <b class="or">or</b><br />\n      -  Cópia de um extrato bancário recente </br> (com até 6 meses de validade)\n      <br />\n      <br />\n      <b>IMPORTANTE !</b> O documento DEVE mostrar claramente o seu nome completo e endereço atual.\n      <br />',
      idProofSpan:
        '<strong>\n      <b>Confirmar a Identidade</b>\n      </strong>\n      <br />\n      <I>escolha\n       <b>uma</b> das opções abaixo\n        <br />\n        <br />\n      </I>\n      - Cópia de Passaporte Válido<br />\n\n      - - Cópia da Carta de Condução Válida\n      <br />\n   (frente e verso)\n      <br />\n      ou</b><br />\n      - Cópia do Cartão de Identidade Nacional\n      <br />\n      <br />\n      <b>IMPORTANTE !</b> O documento DEVE mostrar claramente o seu nome completo.<br />',
    },
    reasonType: {
      4: 'Documentos Incompletos',
      5: 'Documentos Ilegíveis',
      6: 'Cópia a Cores Obrigatória',
      8: 'Divergência do Nome de Abertura da Conta',
      9: 'Data de Emissão do Comprovativo de Morada Não Qualificada',
    },
    reason:
      'Não foi possível verificar a sua documentação,<br> Motivo: {reason}. Por favor, tente novamente.',
  },
  header: { secPortal: 'Área de cliente Segura' },
  menu: {
    home: 'PÁGINA INICIAL',
    accManagement: 'CONTAS',
    liveAcc: 'CONTAS REAIS',
    openAdditAcc: 'ABRIR CONTAS ADICIONAIS',
    openAnAdditLiveAcc: 'ABRA UMA CONTA REAL ADICIONAL',
    homeDemo: 'CONTAS DE DEMONSTRAÇÃO',
    depositFund: 'DEPOSITAR FUNDOS',
    withdrawFunds: 'LEVANTAR FUNDOS',
    transferBetweenAccs: 'TRANSFERIR ENTRE CONTAS',
    payDetails: 'DETALHES DO PAGAMENTO',
    funds: 'FUNDOS',
    transactionHis: 'HISTÓRICO DE TRANSAÇÕES',
    tranHis: 'TRANSFERÊNCIAS',
    download: 'DESCARREGAMENTOS',
    profile: 'PERFIL',
    myProfile: 'O MEU PERFIL',
    cpPassword: 'ALTERAR SENHA',
    contactUs: 'Contato',
    helpSupport: 'FORMULÁRIOS DE AJUDA E SUPORTE',
    register: 'CONTINUAR A APLICAÇÃO',
    tradingTools: 'FERRAMENTAS PROTRADER',
    MARKET_BUZZ: 'MARKET BUZZ ',
    ECON_CALENDAR: 'CALENDÁRIO ECONÓMICO',
    FEAT_FX: 'IDEIAS EM DESTAQUE',
    FX_IDEAS: 'Visualizações técnicas',
    ALPHA_GENERATION: 'GERAÇÃO ALFA',
    MARKET_MASTERS: 'MESTRES DE MERCADO',
    marketWidgets: 'Aplicativos de Trade',
    technicalAnalysis: 'Análise técnica',
    techAnalysisTab: {
      forex: 'FOREX',
      indices: 'ÍNDICES',
      commodities: 'COMMODITIES',
      sharedcfds: 'CFDs DE AÇÕES',
      crypto: 'CRIPTO',
    },
    marketData: 'dados de mercado',
    forexCrossRates: 'taxas de câmbio entre divisas',
    forexHeatMap: 'mapa de popularidade forex',
    forexScreener: 'examinador forex',
    referAFriend: 'Recomendar Um Amigo',
    wholesale: 'ATUALIZAR PARA PRO',
    tradingView: 'CHARTS da TradingView',
    twoFactorAuthentication: 'AUTENTICAÇÃO DE DOIS FACTORES',
    promotion: 'Promoções',
    depositBonus: '50% Bónus de Depósito',
    hmcTrader: 'Sala de Negociação ao Vivo',
    support: 'Suporte',
    securityManagement: 'Gestão de Segurança',
  },
  contact: {
    desc: 'Se tiver questões ou quiser mais informações, por favor, contacte-nos através de um dos contactos abaixo. Procuraremos responder a todas as questões no prazo de 24 horas.',
    tip: 'Para clientes que integraram via {email}',
    liveChat: 'Chat ao Vivo',
    helpSupportForm: {
      partner: 'Formulário de transferência de parceria',
      investigation: 'Formulário de Investigação de Negociação Moneta',
      promotion: 'Formulário de exclusão de promoções Moneta',
      request: 'Formulário de Pedido de Encerramento de Conta',
    },
    accountManager: 'Gestor de Conta - {userName}',
    monetaSupport: 'Suporte da Moneta',
  },
  depositResultDialog: { msg: 'Completou com sucesso o seu depósito' },
  dialog: {
    identityProof: {
      success:
        'Obrigado. Recebemos os seus documentos com sucesso e estamos a processá-los para verificação.',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: 'PROCESSAMENTO DA INSCRIÇÃO DE CONTA',
      id: 'ID',
      poa: 'endereço',
      both: 'O ID e o comprovativo de endereço são',
      content:
        'O seu Comprovativo de {doc} esta sendo processado no momento, e será finalizado em breve. Para acelerar o processamento da sua conta real, você pode financiar sua conta agora e receber uma abertura prioritária.',
    },
  },
  idPoa: {
    IdPoaNoPass: {
      header: 'Candidatura de conta incompleta ',
      content:
        'Para começar a negociar, por favor, carregue o seu Documento de Identificação e o seu Comprovativo de Morada. Uma vez submetida a candidatura, financie a sua conta para abertura prioritária de conta.',
    },
    onlyIdPass: {
      header: 'Obrigado!',
      content:
        'A sua candidatura está a ser processada. Financie a sua conta agora para ter prioridade na abertura da conta.',
    },
    onlyPoaPass: {
      header: 'Candidatura de conta incompleta ',
      content:
        'Para começar a negociar, por favor, carregue o seu Documento de Identificação. Uma vez submetida a candidatura, Financie a sua conta para abertura prioritária de conta.',
    },
    bothIdPoaPass: {
      header: 'Obrigado!',
      content:
        'A sua candidatura está a ser processada. Financie a sua conta agora para ter prioridade na abertura da conta.',
    },
    uploadID: 'Carregar ID',
    uploadPoa: 'Upload Address',
    depositNow: 'Deposite Agora',
  },
  register: {
    progressStatus: {
      title: 'Progresso da verificação',
      estimatedTimeTitle: 'Tempo Estimado para Conclusão:',
      min: 'minutos',
    },
    tabs: {
      personalDetails: 'Detalhes Pessoais',
      moreAboutYou: 'Mais Sobre Si',
      accountConfig: 'Configuração da Conta',
      confirmYourId: 'Confirme a sua Identidade',
      fundYourAcc: 'Financiar a sua Conta',
    },
    btn: {
      next: 'PRÓXIMO',
      back: 'VOLTAR',
      fundnow: 'FINANCIAR AGORA',
      upload: 'Carregar',
      done: 'CONCLUÍDO',
      uploadNew: 'Carregar Novo',
    },
    authority: {
      dialog1: 'Deve ter uma conta real para aceder a esta secção do portal do cliente.',
      dialog2:
        'Abra uma conta real agora para desbloquear o acesso total ao portal do cliente, o que inclui uma variedade de ferramentas de negociação, promoções e muito mais.',
      openLiveAcc: 'abrir conta real',
    },
    demo: {
      congrad: 'Parabéns!',
      title: 'A sua conta DEMO MT{platform} de 30 dias está agora ativa',
      title2: 'Detalhes da sua Conta de DEMONSTRAÇÃO',
      li: {
        login: 'Início de sessão: ',
        password: 'Palavra-passe: ',
        srv: 'Servidor: ',
        expDate: 'Data de Validade: ',
      },
      emailSent:
        '* Os detalhes de início de sessão também foram enviados para o seu e-mail registado.',
      dl: 'Descarregue a sua Plataforma de Negociação MT{platform}',
      proTraderPlatform: {
        content:
          'Inicie a sessão na nossa plataforma de última geração PRO Trader  utilizando os detalhes da sua conta MT{platform}:',
      },
      appTrader: {
        content:
          'Inicie a sessão na AppTrader da Moneta Markets utilizando as suas credenciais do Portal do Cliente. Obtenha já a AppTrader para iOS ou Android!',
      },
      improveQ: 'Quer melhorar a sua negociação？',
      improveWay:
        'Basta abrir uma conta real em apenas alguns minutos para desbloquear uma variedade de ferramentas de negociação e promoções exclusivas.',
      proTrade: 'Ferramentas Pro Trader',
      depositBonus: 'Bónus de Depósito de 50%',
      smartTradeTool: 'Ferramentas Smart Trader',
      openLiveAcc: 'abrir conta real',
      poweredBy: 'Desenvolvido por',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Detalhes Pessoais',
        userTitle: 'Título',
        firstName: 'Nome Próprio',
        lastName: 'Apelido',
        email: 'E-mail',
        dob: 'Data de Nascimento',
        idType: 'Tipo de Identificação',
        referral: 'Foi recomendado por alguém？ (opcional)',
        middleName: 'Nome do Meio',
        nationality: 'Nacionalidade',
        phoneNum: 'Número de Telefone',
        date: 'Dia',
        month: 'Mês',
        year: 'Ano',
        placeOfBirth: 'Naturalidade',
        idNum: 'Número de Identificação',
        passportNum: 'Número do Passaporte',
        driverLicenseNum: 'Número da Carta de Condução',
        titleDropdown: {
          mr: 'Sr.',
          mrs: 'Sra.',
          ms: 'Sra.',
          miss: 'Srta.',
          dr: 'Dr.',
          prof: 'Professor',
        },
        idTypeDropdown: {
          passport: 'Passaporte',
          idCard: 'Cartão Nacional de Identidade',
          driverLicense: 'Carta de Condução',
        },
      },
      page2: {
        pageTitle: 'ENDEREÇO DE RESIDÊNCIA PRINCIPAL',
        pageTitleForFCA: 'DETALHES ADICIONAIS',
        mainResidency: 'País de residência principal',
        streetNumber: 'Número na Rua',
        streetName: 'Nome de Rua',
        address: 'Endereço',
        provinceOrState: 'Concelho / Distrito',
        cityOrSuburb: 'Cidade / Freguesia',
        postcode: 'Código Postal',
        previousCountry: 'País Anterior',
        usQuestion:
          'É Cidadão/Residente nos EUA para fins fiscais ou possui ligações/laços nos EUA (por exemplo, conta Bancária etc.)',
        taxResidencyCountry: 'País(es) de residência fiscal',
        nationalInsuranceNumber: 'Número do Seguro Nacional(NI)',
        yearsAtAddress: 'Número de anos no seu domicílio',
        previousResidentialAddressTip: 'Favor inserir seu endereço residencial anterior abaixo',
      },
    },
    moreAboutYou: {
      pageTitle1: 'DETALHES FINANCEIROS E DA SITUAÇÃO PROFISSIONAL',
      pageTitle2: 'Conhecimento e Experiência de Negociação',
      complianceExplain:
        'As seguintes perguntas são recolhidas para conformidade com os requisitos da AML/CTF.',
      FCAQuestion10Tip:
        'Nos últimos 12 meses, quantas negociações, em média, executou nos seguintes instrumentos, por trimestre?',
    },
    accountConfig: {
      pageTitle: 'Configuração da Conta',
      chooseTradingPlatform: 'Escolha a Plataforma de Negociação',
      chooseAccType: 'Escolha o Tipo de Conta',
      chooseAccCurrency: 'Escolha a Moeda da Conta',
    },
    confirmYourId: {
      pageTitle: 'Confirme a sua Identidade',
      description:
        'Como corretora forex regulamentada, somos obrigados a verificar a sua identidade. Por favor, faça o carregamento de um dos seguintes documentos, para a verificação do seu Comprovativo de Identificação e Comprovativo de Morada. Como alternativa, pode enviar os seus documentos de verificação por e-mail para {mailTo}',
      poidTitle: 'Comprovativo de Identificação',
      porTitle: 'Comprovativo de Endereço',
      uploadDescription: 'Escolha um tipo de documento e faça o carregamento em concordância',
      note: 'IMPORTANTE: O documento DEVE mostrar claramente o seu nome completo.',
      pending: { upload: 'Carregamento Pendente', verification: 'Verificação Pendente' },
      poidTypes: {
        photoId: 'Identificação com Foto',
        passport: 'Uma cópia do seu passaporte internacional (Página de Assinatura)',
        drivingLicence: 'Carta de Condução',
      },
      porTypes:
        '<li>Fatura de Serviços</li>\n      <li>Extrato Bancário</li>\n      <li>Certificado de Residência</li>',
    },
    finishPage: {
      title: '{individualUserName}, obrigado por se inscrever numa conta real {platformName}!',
      contentDefault:
        'Recebemos a sua inscrição e configuramos a sua conta real <b>{account}</b>. <br />\n      Em breve, receberá um e-mail em <b>{email}</b> contendo os seus detalhes de início de sessão e um link para visualizar o estado da sua conta. <br />',
      contentWithoutId3WithAccount:
        'A sua conta de negociação real está a ser processada e vai receber um e-mail com mais informações em breve.<br /><br />\n      Para acelerar o processamento da sua conta real, você pode financiar sua conta agora e receber uma abertura prioritária.<br /><br />',
      contentWithoutId3WithoutAccount:
        'A sua conta de negociação real está a ser processada e vai receber um e-mail com mais informações em breve.<br />',
    },
  },
  tradingTools: {
    MARKET_BUZZ:
      'Aceda à cobertura noticiosa e ao conteúdo de media cobrindo 35.000 ativos transacionáveis.',
    FEAT_FX:
      'Veja as configurações técnicas de negociação acionáveis e acompanhe o seu progresso em tempo real.',
    ECON_CALENDAR: 'Desfrute de dados económicos de todo o mundo a toda a hora e em tempo real.',
    FX_IDEAS:
      'Veja perspetivas técnicas envolventes e educativas de negociação em 80.000 instrumentos.',
    ALPHA_GENERATION:
      'Aumente a sua vantagem de negociação e ganhe 3 indicadores avançados de última geração em MT4 e MT5.',
    MARKET_MASTERS:
      'Aprenda a negociar como os profissionais com mais de 100 tutoriais avançados de negociação em vídeo.',
    condition: {
      needDeposit:
        'Para obter acesso ao seu conjunto de ferramentas de negociação PRO e tutoriais exclusivos de negociação em vídeo, basta financiar a sua conta real com $500 ou mais.',
      congratulations: 'Parabéns! Está pronto para desbloquear as suas Ferramentas Premium Trader!',
      needTnc: 'Por favor, concorde com os{link}.',
      link: 'Termos e Condições',
      unlock: 'DESBLOQUEAR FERRAMENTAS PRO TRADER',
      depositNow: 'DEPOSITAR AGORA',
      tncAisc: 'Eu concordo em ter acesso a essas ferramentas',
    },
    techAna: 'Análise técnica de',
    byTradingView: 'Por Tradingview',
    alphaGeneration: {
      intro: 'ALPHA GENERATION PARA MT4 E MT5',
      introDesc:
        'Aumente a sua margem de negociação com três EAs premium exclusivos da Moneta Markets.',
      analystViews: {
        title: 'Visualizações técnicas',
        desc: 'Níveis de preços técnicos instantâneos e tendências exibidas na sua tabela de preços.',
      },
      adaptiveCandlesticks: {
        title: 'GRÁFICOS DE VELAS ADAPTATIVOS',
        desc: 'Verificação em tempo real de 16 padrões populares de gráficos de velas.',
      },
      adc: {
        title: 'ADC',
        desc: 'Sinais de negociação de entrada e saída a curto prazo, tanto em direções longas como curtas.',
      },
      install: 'COMO INSTALAR O ALPHA GENERATION?',
      installSteps: {
        one: 'Descarregue o Alpha Generation para PC clicando no botão "Descarregar Alpha Generation" abaixo.',
        two: 'Faça duplo clique no ficheiro para o executar e siga o assistente de instalação que o guia através do processo no seu idioma selecionado.',
        three: 'Escolha a sua pasta de destino Moneta Markets MT4 ou MT5.',
        four: 'Uma vez instalado, abra a sua plataforma MT4 ou MT5, e localize os indicadores da Trading Central juntamente com os outros na sua lista de indicadores MetaTrader.',
        five: 'Arraste um indicador para o gráfico e obtenha imediatamente uma visão de negociação acionável.',
      },
      button: { guid: 'Guia do Utilizador', download: 'Descarregar Alpha Generation' },
    },
  },
  tradingView: {
    launch: 'iniciar',
    tnc: 'Inicie sessão na plataforma de gráficos e negociação TradingView com os seus dados de início de sessão MT{mtServer}. Saiba mais sobre a CHARTS da TradingView <a href= https://{regulatorUrl}/platforms/trading-platforms/charts-by-tradingview/ >aqui</a>.',
    subTitle:
      'Junte-se a 15 milhões de outros investidores e comece a negociar com os gráficos, indicadores e ferramentas de desenho da TradingView.',
    intro:
      'Apresentamos os CHARTS da TradingView. Os clientes Moneta podem agora encontrar ideias de negociação, tendências pontuais e colocar negociações ao vivo através da nossa plataforma TradingView integrada, CHARTS.',
    descp1: 'Mais de 100 indicadores técnicos',
    descp2: 'Atualizações de notícias do mercado em direto',
    descp3: 'Mais de 50 ferramentas de desenho inteligentes',
    descp4: 'Análise de vários prazos',
    descp5: '12 condições diferentes de alerta de negociação',
    descp6: 'Múltiplos períodos de tempo',
    descp7: ' Mais de 50 ferramentas de desenho inteligente',
    descp8: 'Mais de 20 esquemas de gráficos',
    descp9: 'Mais de 12 tipos de gráficos personalizáveis',
    descp10: 'Cobertura noticiosa integrada do mercado',
    learnMore: 'Saiba mais',
    tnc02:
      'Utilize os seus dados de início de sessão da MT{mtServer} e experimente agora a plataforma web Protrader. {findMore}',
    tnc03: 'Saiba Mais',
    subTitle02:
      'Os utilizadores da plataforma Protrader da Moneta têm agora acesso a gráficos de preços ao vivo, indicadores técnicos e ferramentas de desenho, todos desenvolvidos pela TradingView; em mais de 300 pares de FX, Índices, Matérias-primas, CFDs de Ações e muito mais.',
    intro02: 'Obtenha acesso a:',
    intro03: 'Desenvolvido por',
  },
  TFAuth: {
    title: 'AUTENTICAÇÃO DE DOIS FACTORES',
    enable: {
      enableTitle: 'ATIVE A AUTENTICAÇÃO DE DOIS FATORES',
      enableDesc:
        'Mantenha a sua conta segura, ativando a autenticação móvel de dois fatores. A autenticação de dois fatores proporciona uma camada adicional de segurança e paz de espírito, através de uma segunda etapa de verificação cada vez que iniciar sessão. Uma vez ativada, pode recuperar um código de verificação de dois fatores a partir de uma aplicação no seu telefone iOS ou Android, em qualquer altura, submetê-lo e iniciar sessão em segurança.',
      enableSubtitle: 'Como ativar a autenticação de dois fatores',
      step1:
        'Descarregue e instale o Google Authenticator gratuitamente a partir da loja de aplicações iOS ou Android.',
      step2:
        'Uma vez na aplicação, selecione "Ler Código de Barras" e proceda à leitura do código de barras abaixo.',
      step3: 'Introduza o código numérico na aplicação (atualiza-se a cada 30 segundos) abaixo.',
      placeHolder: 'código',
    },
    disable: {
      disableTitle: 'DESATIVAR A AUTENTICAÇÃO DE DOIS FATORES',
      disableDesc:
        'Para desativar a autenticação de dois fatores, submeta aqui o código numérico da sua aplicação Google Authenticator.',
    },
    dialog: {
      title: 'Por favor, introduza o seu código de autenticação de dois fatores',
      contact: 'Está a ter dificuldades em iniciar a sessão? {link}',
      link: 'Contacte-nos.',
    },
    popup: {
      popup1: 'A autenticação de dois fatores está ativada.',
      popup2: 'A autenticação de dois fatores está desativada.',
    },
  },
  promotion: {
    depositBonus: {
      depositBonus: 'Deposite e reclame o seu bónus de negociação',
      title: 'Reclame o seu bónus e aumente o seu depósito!',
      subtitle1:
        'Quer mais poder de negociação? Tudo o que tem de fazer é financiar a sua conta real. Assim que tivermos recebido o seu depósito, o seu bónus de depósito aparecerá na sua conta de negociação.',
      fund: 'Financie a sua conta',
      tnc: '*{link}',
      link: 'Aplicam-se os termos e condições. ',
      bonusHistory: 'Histórico de Bónus',
      overBonus:
        '* Atingiu o máximo de ${totalLimit} de créditos de bónus. Os depósitos adicionais não se qualificam para créditos de bónus.',
      opt: 'Quer optar por reclamar o seu bónus de depósito?',
      tick: 'Por favor, selecione a caixa se quiser participar na promoção do bónus de depósito.',
      tick2:
        'A Moneta reserva-se o direito de excluir e/ou cancelar a participação de um cliente nas suas ofertas ou promoções se:<br><br> a) Rotatividade (abertura e encerramento de uma transação de Margem FX ou CFD apenas pelo benefício de ganhar um benefício);<br> b) se for considerado que o cliente violou o seu Contrato de Cliente ou tiver violado os termos e condições das promoções.<br><br> No caso de tal má conduta ou violação, a Moneta terá o direito de deduzir qualquer montante de bónus e lucros obtidos a partir da conta de negociação.\n      <br><br>Se a empresa suspeitar ou tiver razões para acreditar que um cliente abusou e/ou manipulou os termos e condições deste esquema de bónus, cobrindo as suas posições internamente (utilizando outras contas de negociação detidas na empresa) ou externamente (utilizando outras contas de negociação detidas com outras corretoras) e/ou não agiu de boa fé, a empresa reserva-se o direito, à sua discrição absoluta e sem obter o consentimento do cliente, de retirar o montante do bónus e o lucro obtido da conta de negociação com efeito imediato.\n      <br><br>A Moneta pode, a qualquer momento, fazer alterações a estes termos e condições e notificá-lo-á destas alterações através da publicação dos termos modificados no site da Moneta. O utilizador é aconselhado a rever estes termos regularmente, e pela utilização contínua do site e serviços da Moneta que aceita quaisquer termos modificados. A Moneta reserva-se o direito de modificar ou cancelar quaisquer ofertas a seu exclusivo critério, a qualquer momento.',
      saveChange: 'Guardar Alterações',
      creditReceived: 'Crédito Recebido',
      welcomeBonusTable: 'Bónus de Boas-vindas',
      depositBonusTable: 'Bónus de Depósito',
      depositDate: 'Data de Depósito',
      credit: 'Crédito',
      optIn: 'Participação com sucesso',
      optOut: 'Desativação com sucesso',
      notification:
        '<div>É elegível para aderir à campanha de Bónus de Depósito. Por favor, <a href="{depositBonusLink}">adira antes</a> do depósito para receber o bónus de depósito. Saiba mais <a href="{tncLink}" target="_blank">aqui</a>.</div>',
      optInButton: 'adira antes',
      dontShowAgain: 'Não mostrar esta mensagem novamente',
    },
    referAFriend: {
      congratulations:
        'Parabéns! Acabou de aderir com sucesso à promoção "Recomendar um Amigo" como referência.',
      redeem: 'Resgatar',
      redeemed: 'Resgatado',
      dayRemain: 'dias restantes',
      voucherDesc: 'O voucher pode ser resgatado uma vez que tenha negociado ≥ {lots} lotes',
      title: 'Recomende um amigo e ganhe até ${amount} e ${secAmount} pelo seu amigo!',
      desc: 'Quando o seu recomendado completar os seguintes passos no prazo de 30 dias, você ganhará até ${amount}! E, não há limite para quantos amigos pode recomendar! *<a href="{tncLink}">Aplicam-se termos e condições</a>.',
      register: 'Registe-se connosco',
      forYourFriend: 'para o seu amigo',
      liveAcc: 'Abrir uma conta real',
      makeDeposit: 'Fazer Depósito',
      trade1Lot: 'Negociar 1 Lote',
      trade5Lots: 'Negociar 5 Lotes',
      totalEarning: 'Ganhos Totais',
      referralAct: 'Atividade de Recomendação',
      referralName: 'Nome de Recomendação',
      registered: 'Registado',
      accOpen: 'Conta Aberta',
      madeDeposit: 'Realizou Depósito',
      redeemStatus: 'Estado do Resgate',
      notApplicable: 'Não aplicável ',
      country: 'País',
      acc: 'CONTA',
      share: 'PARTILHAR',
      copyLink: 'Copiar link',
      redeemYourbonus: 'Resgate o seu bónus',
      tatolAmtConvert: 'O montante total convertido na sua moeda base é {convertAmt} {currency}',
      remark: '**Ao clicar em resgatar agora, está a concordar com os termos e condições',
      redeemNow: 'Resgatar agora',
      overlimit: 'Acima do limite',
      newUser: 'Novo utilizador',
      tradeCash: 'Negociar dinheiro',
    },
    cashback: {
      button: {
        applyForBonus: 'SOLICITAR BONUS',
        redeemCashback: 'RESGATAR O CASHBACK',
        howItWorks: 'Como funciona?',
        depositNow: 'Deposite agora',
        stp: 'STP DIRECT',
        ecn: 'PRIME ECN',
        agree: 'ACEITAR',
        cancel: 'CANCELAR',
      },
      title: {
        monetaProgram: 'PROGRAMA DE CASHBACK DO MONETA MARKETS',
        claim: 'SOLICITE SEU BÔNUS DE CASHBACK DE 50% AGORA!',
        howItWorks: 'VEJA COMO FUNCIONA',
        howMuch: 'QUANTO VOCÊ GANHA COM SUAS NEGOCIAÇÕES',
        treeSimpleSteps: 'SOLICITE SEU BÔNUS DE 50% - EM 3 ETAPAS SIMPLES',
        depositTradEarn: 'DEPÓSITO. NEGOCIAR. GANHE!',
        bonusSummary: 'Resumo do seu bônus',
        historicalEarnings: 'SEUS GANHOS ATUAIS E HISTÓRICOS',
        program: 'PROGRAMA DE CASHBACK',
        cashSummary: 'RESUMO DO CASHBACK',
        faqs: 'PERGUNTAS FREQUENTES SOBRE BÔNUS DE CASHBACK',
        redeemCashback: 'RESGATE DE SEU CASHBACK',
        cashbackBalance: 'SALDO DE CASHBACK',
      },
      subTitle: {
        lowerYourCost: 'Reduza seu custo. Ganhe dinheiro de volta',
        bonus: 'BÔNUS',
        cashback: 'CASHBACK',
        wallet: 'CARTEIRA',
        availableBonus: 'Bônus disponível',
        totalBalance: 'Saldo total',
        step1: 'Deposite fundos e inscreva-se!',
        step2: 'Comece a negociar!',
        step3: 'Solicite seu cashback',
        FX: 'FOREX',
        XAU: 'OURO',
        OIL: 'PETRÓLEO',
        perLot: 'por lote',
        seetheBonusStatus:
          'Veja na coluna o STATUS DO SEU BONUS, para APLICAR a quaisquer bônus disponíveis',
        tradeFxOilAndGold:
          'Negocie FOREX, OURO e PETRÓLEO para converter seu BÔNUS em DINHEIRO DE VOLTA!',
        totalCashback: 'Total do resgate',
        FebruaryCashback: 'Resgate pendente',
        cashbackRedeemable: 'Resgate resgatável Resgate',
        account: 'Conta',
        amount: 'Valor do bônus',
      },
      information: {
        availableBonus1:
          'O bônus disponível é a quantidade de créditos de bônus que você ganhou em todos os depósitos elegíveis. Para cada depósito de US$ 500 ou mais, você receberá 50% desse valor como créditos de bônus',
        total: 'O saldo total é a soma do cashback resgatável e do valor do cashback do mês atual',
        availableBonus2:
          'Seu bônus disponível é o número de créditos de bônus de todos os depósitos qualificados que ainda não foram convertidos e transferidos para a sua carteira de resgate.',
        totalCashback:
          'O resgate total é o número de créditos de bônus que foram convertidos em dinheiro.", "O resgate total é o número de créditos de bônus que foram convertidos em dinheiro,',
        februaryCashback:
          'Os Créditos de Bônus do mês atual que foram convertidos em dinheiro. Você pode transferi-los para sua conta de negociação no início do próximo mês',
        cashbackRedeemable:
          'O número de créditos que foram convertidos em dinheiro até o final do último mês. Você pode transferir esse valor para sua conta de negociação a qualquer momento',
        selectTheTradingAccount:
          'Selecione a conta de negociação para a qual você gostaria de transferir seu reembolso, O valor (USD) para o qual você gostaria de transferir seu reembolso',
        enterTheAmount:
          'O valor (USD) que você gostaria de transferir para sua conta de negociação',
      },
      content: {
        claimContent1:
          'Agora, toda vez que você depositar US$ 500 ou mais em sua conta real, nós lhe daremos um bônus de 50%, que aparecerá na Carteira de Bônus na página inicial do Portal do Cliente! Em seguida, ao negociar FX, ouro ou petróleo, seus créditos de bônus serão convertidos em dinheiro real, exibido na Carteira de Cashback! No início de cada mês, os fundos em sua Cashback Wallet podem ser transferidos para suas contas de negociação reais para serem usados como fundos reais!',
        claimContent2:
          'O melhor de tudo é que, para cada depósito futuro de US$ 500 ou mais, seu bônus será automaticamente creditado em sua carteira de bônus, para que você possa continuar ganhando mais.',
        howItWorksContent1:
          'Finalize sua conta real com US$ 500 ou mais, depois clique em "Apply" (Aplicar) e aceite os termos e condições para receber seu bônus de 50%,',
        howItWorksContent2:
          'Ao negociar Forex, ouro ou petróleo, seus créditos de bônus serão automaticamente convertidos em dinheiro real e negociável, que pode ser acompanhado em sua "Carteira de Cashback"!',
        howItWorksContent3:
          'No início de cada novo mês, basta clicar em "Resgatar Resgate" em sua "Carteira de Resgate" e resgatar o valor mostrado como "Resgate Resgatável"',
        stepsContent1: 'Deposite US$ 500 ou mais e reivindique seu bônus abaixo',
        stepsContent2:
          'Comece a negociar Forex, ouro e petróleo para converter seu bônus em dinheiro real!',
        stepsContent3: 'Solicite seu reembolso da atividade de negociação dos meses anteriores,',
        depositTradEarnContent1: 'Quanto mais você negociar, mais você ganha',
        depositTradEarnContent2:
          'Ao fazer um depósito de US$ 500 ou mais, você pode solicitar um bônus de cashback de 50%!',
        optInContent1:
          "Para se inscrever na promoção de cashback da Moneta Markets, você deve concordar com os termos e condições abaixo.', 'Quanto mais você negocia, mais você ganha', 'Quanto mais você negocia, mais você ganha', 'Quanto mais você negocia, mais você ganha",
        electTheDestinationAccount: 'Selecione a conta de destino para resgatar',
      },
      column: {
        date: 'DATA',
        deposit: 'DEPÓSITOS',
        withdraws: 'RETIRADAS',
        bonus: 'BÔNUS RESGATÁVEL',
        status: 'STATUS DO CUPOM',
        account: 'CONTA',
        redeem: 'RESGATAR BÔNUS',
        reason: 'NOTAS',
      },
      tnc: {
        title: 'Termos e condições',
        content: {
          item1: {
            title: 'Termos e condições de resgate',
            li1: 'Moneta Markets é um nome comercial da Moneta Markets Ltd, registrada no Registro Internacional de Empresas de Negócios de Santa Lúcia sob o número de registro 2023-00068.',
            li2: 'Os "Termos e Condições do Bônus de Reembolso em Dinheiro" são parte integrante dos "Termos e Condições da Moneta Markets Ltd" e as disposições estabelecidas neste documento são consideradas um anexo a todos os "Termos e Condições da Moneta Markets Ltd" e devem ser lidas em conjunto.',
          },
          item2: {
            title: 'Condições de elegibilidade.',
            li1: 'Sujeita a, e sem prejuízo de, todos os outros Termos e Condições, a Oferta está disponível para os Clientes da Moneta Markets que tenham satisfeito os Critérios de Elegibilidade para a Oferta ("Clientes Elegíveis"), conforme estabelecido nas seguintes seções abaixo.',
            li2: 'A Moneta Markets oferece o "Bônus de Cashback" aos Clientes que abrem contas de negociação reais (ou seja, não demo) com a Moneta Markets.',
            li3: 'O "Bônus de Cashback" está disponível apenas para contas Direct STP e Prime ECN. As contas PAMM, MAM, Ultra ECN e "Cent" não são elegíveis para participar da promoção "Bônus de Cashback',
            li4: 'A Moneta Markets, a seu critério absoluto, oferece o "Bônus de Cashback" a qualquer Cliente de qualquer país/região, conforme julgar apropriado e pelo tempo que a Moneta Markets julgar apropriado para fornecê-lo. A Empresa se reserva o direito de retirar e/ou cancelar qualquer elegibilidade com base em restrições e/ou requisitos locais.',
            li5: 'Somente pessoas que podem celebrar contratos legalmente vinculativos sob as leis aplicáveis em seu país de residência podem participar do "Bônus de Cashback". Sem limitar o precedente, a participação no "Bônus de Cashback" não é permitida para pessoas com menos de 18 anos de idade, pois elas não são elegíveis para abrir uma conta com a Empresa.',
            li6: 'A participação de "intermediários/partes relacionadas" no "Bônus de Cashback" é proibida. Se os detalhes de registro e/ou negociação de um participante no "Bônus de Cashback" corresponderem às informações de registro e/ou negociação, incluindo, mas não se limitando ao endereço IP, de outro participante no "Bônus de Cashback", a Moneta Markets reserva-se o direito de considerar essa correspondência como motivo para desqualificação imediata. Para os fins deste documento, o termo "Intermediário(s)/Parte(s) Relacionada(s)", quando usado nestes Termos e Condições, a menos que o contexto exija o contrário, significará qualquer pessoa ou entidade que tenha um relacionamento com qualquer participante no "Bônus de Cashback", que inclui, mas não está limitado a:',
            li6_sub_items: {
              li1: 'Membros da família, tais como irmãos, irmãs, cônjuges, ascendentes, descendentes diretos e parentes colaterais;',
              li2: 'Pessoa ou entidade que qualquer participante do "Bônus de Reembolso", direta ou indiretamente, por meio de um ou mais intermediários, controla, ou que, direta ou indiretamente, por meio de um ou mais intermediários, é controlada por, ou está sob controle comum com, qualquer participante do "Bônus de Reembolso"; Para os fins desta definição, o termo "controle" (incluindo, com significado correlato, os termos "controlado por" e "sob controle comum com"), conforme usado com relação a qualquer participante do "Bônus de Reembolso", significará a posse, direta ou indiretamente por meio de um ou mais intermediários, do poder de dirigir ou causar a direção das políticas de administração de tal pessoa ou entidade, seja por meio da propriedade de títulos com direito a voto ou de outra forma.',
              li3: 'Da mesma forma, as pessoas associadas de qualquer forma com a Moneta Markets e/ou com os sites específicos e/ou sites de redes sociais nos quais a Moneta Markets pode, de tempos em tempos, estar executando certas promoções específicas, concursos e/ou pesquisas, no contexto do qual o acesso ao "Bônus de Cashback" é oferecido, não estão autorizados a participar da oferta.',
            },
          },
          item3: {
            title: 'Termos específicos do "Bônus de Cashback',
            li1: 'Os clientes devem primeiro depositar um mínimo de US$ 500 e, em seguida, solicitar com êxito o crédito de bônus de 50% no portal do cliente para receber créditos de bônus. Os créditos de bônus de depósitos subsequentes são aplicados automaticamente.',
            li1_sub_desc:
              'Somente os depósitos feitos durante o período da promoção são válidos e serão considerados para a promoção. Isso exclui transferências internas, bônus por indicação de amigos e ajustes de dinheiro.',
            li2: 'O "Bônus de Cashback" está disponível somente para contas Direct STP e Prime ECN. As contas Ultra ECN e Cent não são elegíveis para participar da promoção Bônus de Reembolso.',
            li3: 'Os clientes qualificados (novos e existentes), que atendem aos critérios estabelecidos neste documento, podem receber Cashback equivalente a:',
            li3_sub_content: {
              title1: 'Tabela de bônus para clientes diretos STP e ECN.',
              info1: 'STP Direto: US$ 2 por lote negociado em Forex, petróleo e ouro',
              info2: 'Prime ECN: $50c por lote negociado em Forex, petróleo e ouro.',
              title2: 'Cliente sob a tabela de descontos de STP e ECN do IB',
              info3: 'STP direto: US$ 0,50 por lote negociado em Forex, petróleo e ouro',
              info4: 'Prime ECN: US$ 0,25 por lote de câmbio, petróleo e ouro negociado.',
            },
            li3_sub_desc:
              'O tamanho mínimo cumulativo do lote de negociação para o qual o desconto pode ser acionado é de 0,01 lote padrão. Se o lote de negociação válido do cliente para o dia não acumular 0,1 lote padrão, ele será liquidado em zero. O número cumulativo de lotes é um múltiplo de 0,01 lote padrão, como 0,02 lote padrão, 0,03 lote padrão (0,12 lote padrão será calculado como 0,12 lote padrão).',
            li4: 'Produtos elegíveis: Todos os produtos de CFDs de Forex, ouro e petróleo.',
            li5: 'Volume efetivo de negociação: ordens recém-abertas e fechadas durante o período da promoção. As posições abertas por menos de 10 minutos, as posições bloqueadas e as posições cobertas não contam como transações válidas para essa promoção.',
            li6: 'Essa oferta pode ser usada em conjunto com qualquer outra oferta.',
            li7: 'A retirada de fundos resultará na remoção de um valor igual em créditos da Carteira de Bônus do usuário. Se o valor da retirada for maior que o valor do Crédito de Bônus, o valor do Crédito será reduzido a um saldo zero.',
            li8: 'A Moneta Markets não será responsável por quaisquer custos, despesas ou danos que possam ser incorridos como resultado da redefinição ou ajuste da conta do cliente pela Moneta Markets, conforme contemplado nestes Termos e Condições, incluindo, mas não se limitando a perdas atribuíveis a contas fechadas automaticamente. Fora das posições abertas.',
            li9: 'A Moneta Markets reserva-se o direito, a seu exclusivo critério:',
            li9_sub_items: {
              li1: 'recusar o registro de qualquer participante para o "Bônus de Cashback"; e',
              li2: 'desqualificar qualquer participante do "Bônus de Cashback" que manipule ou viole estes termos e/ou qualquer um dos "Termos e Condições da Moneta Markets" estabelecidos no site da Moneta Markets.',
            },
            li10: 'A Moneta Markets reserva-se o direito, a seu critério razoável, de suspender a oferta deste "Bônus de Cashback" a qualquer um dos seus clientes, sem ter que explicar as razões. Tais clientes serão informados por e-mail.',
            li11: 'A Moneta Markets reserva-se o direito de alterar, modificar ou encerrar o "Bônus de Cashback" ou qualquer aspecto do mesmo a qualquer momento, sem ter que compensar ninguém por isso. O aviso prévio de tais mudanças será dado aos clientes. Observe que a participação no "Bônus de Cashback" constitui aceitação e concordância em cumprir com tais alterações, emendas e/ou mudanças.',
            li12: 'Qualquer indicação ou suspeita, a critério exclusivo da Moneta Markets, de qualquer forma de arbitragem (incluindo, mas não se limitando a, lucro sem risco), abuso (incluindo, mas não se limitando a, padrões de atividade de negociação pelo participante, indicando que o único objetivo do participante é se beneficiar financeiramente do cashback sem estar realmente interessado em negociar nos mercados e/ou assumir riscos de mercado), fraude, manipulação, arbitragem de cashback relacionada a uma promoção de cashback ou qualquer outra forma de atividade enganosa ou fraudulenta, anulará todos os itens acima. As contas de negociação reais do Cliente Elegível com a Moneta Markets e/ou todas e quaisquer transações feitas e/ou lucros ou perdas feitas nelas serão reembolsadas. Em tais circunstâncias, a Moneta Markets reserva-se o direito, a seu exclusivo critério, de fechar/suspender (temporária ou permanentemente) todas as contas de negociação ao vivo de tal Cliente Elegível com a Moneta Markets, cancelar todas as ordens e anular todos os lucros de tal participante. Em tais circunstâncias, a Moneta Markets não será responsável pelas consequências do cancelamento do resgate, incluindo, mas não se limitando ao encerramento da(s) ordem(ns) por Stop Out.',
            li13: 'A participação no "Bônus de Cashback" constitui a aceitação destes Termos e Condições e dos Termos e Condições da Moneta Markets estabelecidos no site da Moneta Markets.',
            li14: 'O "Bônus de Cashback" está disponível por um período limitado, conforme indicado nas regras de participação da promoção e/ou pesquisa específica da qual o "Bônus de Cashback" é mencionado como parte.',
            li15: 'Se estes termos e condições do "Bônus de Cashback" forem traduzidos para um idioma diferente do inglês, a versão em inglês é superior no caso de uma interpretação incompatível.',
            li16: 'O Bónus de Reembolso não é elegível para clientes residentes na Turquia.',
            li17: 'Apenas os depósitos de $500 ou mais a partir de 19 de julho de 2023 são elegíveis para o Bónus de Reembolso.',
          },
          item4: {
            title: 'Aviso de risco',
            li1: 'a negociação de derivativos e de câmbio envolve um alto nível de risco para o seu capital e você só deve negociar com dinheiro que possa perder. Certifique-se de ler os documentos em nossa página de Documentos Legais e procure aconselhamento independente, para entender completamente os riscos, antes de decidir negociar. Esta promoção foi preparada sem levar em conta seus objetivos, situação financeira ou necessidades.',
          },
        },
      },
      faqsList: {
        question_1: 'O que é o Programa de Cashback da Moneta Markets e como ele funciona?',
        question_2: 'Como faço para me inscrever na promoção de Cashback?',
        question_3: 'O que são créditos de bônus?',
        question_4: 'O que é CashBack?',
        question_5: 'Como verifico meus saldos de bônus e cashback?',
        question_6: 'Meus créditos de bônus podem expirar?',
        question_7: 'Meu saldo de CashBack pode expirar?',
        question_8: 'Quais são os benefícios?',
        question_9: 'Quantos créditos de bônus posso ganhar?',
        question_10: 'Quanto Cashback posso ganhar?',
        question_11: 'Quando posso sacar meu bônus de Cashback?',
        question_12: 'Como transfiro meu Cashback disponível para minha conta de negociação?',
        question_13: 'Com que frequência meu Cashback é calculado?',
        question_14: 'Posso ganhar Cashback em todos os produtos?',
        question_15: 'Como faço para retirar meu bônus de cashback?',
        question_16: 'Os saques afetarão meus créditos de bônus?',
        question_17: 'Os saques afetarão meu bônus de cashback?',
        question_18: 'Há um período mínimo de negociação para converter meu bônus em cashback?',
        question_19: 'Como faço para aumentar meus créditos de bônus?',
        question_20: 'Como faço para maximizar meu Cashback mensal?',
        question_21: 'Recebo o mesmo valor de Cashback nas contas Direct STP e Prime ECN?',
        question_22: 'Recebo Cashback nas contas Ultra ECN ou Cent?',
        question_23: 'O que são os status de bônus e o que eles significam?',
        question_24: 'Como posso optar por sair do Programa de Cashback?',
        answer_1:
          'O Programa de Cashback da Moneta Markets é um programa de fidelidade, no qual os clientes depositam um mínimo de US$ 500 para receber um valor de crédito de bônus de 50%. Esses créditos de bônus são, então, convertidos em dinheiro para saque/troca com base na atividade de negociação em moeda estrangeira, ouro e produtos petrolíferos,',
        answer_2:
          'Os clientes da Moneta Markets com uma conta de negociação elegível precisarão "solicitar" seu primeiro valor de Crédito de Bônus no Portal do Cliente da Moneta Markets. Basta clicar em "Solicitar Bônus" no Portfólio de Bônus na página inicial do Portal do Cliente, clicar no botão "Solicitar" na tabela intitulada "Seus ganhos atuais e históricos" e, em seguida, aceitar os termos e condições. Os créditos de bônus subsequentes ganhos em depósitos elegíveis serão aplicados automaticamente,',
        answer_3:
          'Os créditos de bônus são ganhos em depósitos elegíveis de US$ 500 ou mais, a uma taxa de 50% por depósito elegível. Os créditos de bônus são mantidos em sua carteira de bônus e são convertidos em reembolsos reais com base em sua atividade de negociação em todos os produtos de forex, ouro e petróleo,',
        answer_4:
          'Seu Cashback é o valor em dinheiro ganho com base na conversão de Créditos de Bônus como resultado de sua atividade de negociação. O valor do seu bônus pode ser transferido para sua conta de negociação e usado como dinheiro real",',
        answer_5:
          'Você pode visualizar seus saldos na tela inicial do Portal do Cliente, onde são exibidas a Carteira de Bônus e a Carteira de Cashback. Para obter um detalhamento de sua Cashback Wallet, basta clicar em "Promoções" - "Cashback" ou "Resgatar Cashback" em "Cashback Wallet" na página inicial do Portal do Cliente, onde você poderá ver o total de Cashback, Cashback resgatável e o mês atual. Valores de cashback",',
        answer_6:
          'Não, os créditos de bônus não expiram. Ao optar pelo bônus de cashback, todos os depósitos e saques serão calculados a partir dos 30 dias anteriores para calcular o bônus disponível. Todos os créditos de bônus subsequentes ganhos em depósitos futuros são aplicados automaticamente e não expiram. É possível visualizar o status dos seus ganhos de bônus atuais e históricos na tabela intitulada "Seus ganhos atuais e históricos", que mostrará "Aplicar", "Creditado", "Não elegível" ou "Deduzido",',
        answer_7:
          'Os valores de bônus de cashback ganhos não expiram e podem ser transferidos para sua conta de negociação ativa ou sacados no início de cada mês,',
        answer_8:
          'Nós valorizamos tê-lo como cliente e queremos agradecê-lo por negociar com a Moneta Markets. Como tal, você receberá uma recompensa na forma de créditos de bônus em todos os depósitos elegíveis. Depois, à medida que continuar a negociar conosco, seus créditos serão convertidos em dinheiro real, como forma de agradecimento por sua fidelidade contínua",',
        answer_9:
          'Ao depositar US$ 500 ou mais, você receberá 50% desse valor em créditos de bônus. Depois, para cada depósito elegível subsequente, você continuará a acumular créditos de bônus,',
        answer_10:
          'A quantidade de Cashback que você pode ganhar depende do seu tipo de conta e dos produtos que deseja negociar. Você pode maximizar seus lucros negociando moedas, petróleo e ouro em uma conta STP direta da Moneta Markets.<br/><br/>Seus créditos de bônus são convertidos em dinheiro na seguinte taxa:<br/><br/>[Direto Tabela de descontos STP e Prime ECN]<br/>STP direto: US$ 2 por lote FX, Petróleo e Ouro negociado<br/>Prime ECN: US$ 0,50 por lote FX, Petróleo e Ouro negociado',
        answer_11:
          'Você pode transferir o saldo mostrado como seu "cashback disponível" para sua conta de negociação a qualquer momento. Enquanto o Cashback ganho no mês atual está disponível para transferência para sua conta de negociação no início do mês seguinte.',
        answer_12:
          'Tudo o que você precisa fazer é clicar em "Resgatar Cashback" em sua carteira de cashback, selecionar a conta de negociação e o valor do cashback que deseja transferir e clicar em "Resgatar Cashback" novamente.',
        answer_13:
          'O cashback ganho com sua atividade de negociação em todos os produtos de câmbio, ouro e petróleo é calculado diariamente no horário de Brasília e aparecerá em sua carteira de cashback.',
        answer_14:
          'O bônus de cashback pode ser ganho em todos os produtos Forex, Gold e Oil nas contas Direct STP e Prime ECN conforme a tabela abaixo.<br/><br/>[Tabela de Cashback Direct STP e Prime ECN]<br/> Direct STP: $ 0,50 por FX, petróleo, lote de ouro negociado<br/>Prime ECN: $ 0,25 por FX, petróleo, lote de ouro negociado',
        answer_15:
          'Retirar o bônus de Cashback disponível é simples. Tudo o que você precisa fazer é clicar em "Resgatar Cashback" em sua carteira de cashback, selecionar a conta de negociação e o valor do cashback que deseja transferir e clicar em "Resgatar Cashback" novamente. Depois que os fundos forem transferidos para sua conta de negociação, você poderá usá-los para negociação ou enviar uma solicitação de saque para processamento.',
        answer_16:
          'Retirar o bônus de Cashback disponível é simples. Tudo o que você precisa fazer é clicar em "Resgatar Cashback" em sua carteira de cashback, selecionar a conta de negociação e o valor do cashback que deseja transferir e clicar em "Resgatar Cashback" novamente. Depois que os fundos forem transferidos para sua conta de negociação, você poderá usá-los para negociação ou enviar uma solicitação de saque para processamento.',
        answer_17:
          'Não. O bônus de cashback é seu e não será afetado por nenhum saque que você fizer.',
        answer_18:
          'Qualquer negociação de forex, ouro ou petróleo deve ser mantida por um mínimo de 10 minutos para que seu bônus seja convertido em cashback.',
        answer_19:
          'Seus créditos de bônus são acumulados toda vez que você faz um depósito qualificado. Para cada depósito de US$ 500 ou mais, você receberá 50% desse valor em créditos de bônus.',
        answer_20:
          'A maximização do valor de Cashback que você pode ganhar depende de dois fatores: seu tipo de conta e sua atividade de negociação em produtos de câmbio, ouro e petróleo, com as contas Direct STP convertendo-se em Cashback a uma taxa mais alta do que as contas Prime ECN. Apenas certifique-se de ter uma quantidade suficiente de créditos em sua carteira de bônus e negocie FX, ouro e petróleo em uma conta STP direta para maximizar seu cashback. E, quanto mais negociações você fizer e quanto maior for o volume negociado, mais rapidamente seus créditos de bônus serão convertidos em cashback.',
        answer_21:
          'O valor do desconto ganho em FX, Gold e Oil difere entre os tipos de conta.<br/><br/>[Tabela de Rebate Direct STP e Main ECN]<br/>Direct STP: $0,50 por lote negociado em FX , Oil e Gold<br/>Prime ECN: $ 0,25 por lote FX, Petróleo, Ouro negociado',
        answer_22:
          'Infelizmente, as contas Ultra ECN ou Cent não acumulam nenhum bônus para negociação de moedas, ouro ou petróleo. Você deve negociar em contas Direct STP ou Prime ECN,',
        answer_23:
          "Aplicar: Na primeira vez que fizer um depósito qualificado, você deve clicar em 'Aplicar' para que seu bônus seja creditado. Todos os depósitos subsequentes serão creditados automaticamente.<br/>Credito: o bônus foi creditado e aparecerá em sua carteira de bônus<br/>Inelegível: o bônus não atingiu o requisito mínimo de $ 500<br/>Deduzido: o bônus foi deduzido como resultado da retirada de fundos de sua conta de negociação.",
        answer_24:
          'Se você decidir que não deseja mais fazer parte do programa, envie sua solicitação de exclusão para <a href="mailto:{email}">{email}</a>',
      },
      reason: {
        1: 'Um bônus de 50 % foi adicionado à sua bolsa de bônus',
        2: 'Ajuste manual do bônus: creditado',
        3: 'O valor do saque foi removido da sua carteira de bônus.',
        4: 'Esse valor de bônus foi convertido em Cashback',
        5: 'Ajuste manual do bônus - Deduzido',
        6: 'Esse depósito não atingiu o valor mínimo de depósito para se qualificar',
      },
      status: { 0: 'Solicitar', 1: 'Creditar', 2: 'Deduzir', 3: 'Inelegivel' },
      zeroMsg: 'você não tem mais nenhum reembolso para resgatar',
    },
  },
  hmcTraderPortal: {
    overview: 'Descrição geral',
    interactiveAnalysis: 'Análise interativa',
    learnFromTheBest: 'Aprenda com os melhores',
    requestAResearch: 'Pedir uma pesquisa',
    vipSupport: 'Assistência VIP',
    eligibility: 'Elegibilidade',
    login: 'Fazer login no HMC Sala de Negociação ao Vivo',
    depositRequirement:
      'Cumprir o requisito de depósito para obter acesso ao HMC Sala de Negociação ao Vivo',
    basic: 'Básico',
    vip: 'VIP',
    exclusiveAnalysis: 'Análises exclusivas',
    analysisDiscussions: 'Discussões de análises',
    tradingSignals: 'Sinais de negociação',
    tradingSignalRequest: 'Pedido de sinais de negociação',
    privateAnalysisRequest: 'Pedido de análise privada',
    prioritySupport: 'Apoio prioritário',
    minimumFundingRequirement:
      'Requisito mínimo de financiamento e $200.000 em negociações completas (alavancagem incluída) por mês*',
    tnc1: 'Informamos que as informações apresentadas no <a href="{url}" target="_blank">portal.hmctrader.com</a> são fornecidas à Moneta (“Moneta Global Limited”, “nós”) por um fornecedor terceiro (“HMC Trader”). Destina-se apenas a fins informativos e de pesquisa e não constitui qualquer aconselhamento financeiro para comprar, vender ou manter um produto específico ou seguir qualquer estratégia de investimento específica. A informação não é adaptada às necessidades de investimento de nenhuma pessoa específica e, portanto, não envolve uma consideração de nenhum dos objetivos de investimento, situação financeira ou necessidades de qualquer visitante que a possa receber. Tenha também em conta que o desempenho passado não é um indicador fiável de desempenho futuro e/ou resultados. Os resultados reais podem diferir substancialmente dos previstos nas declarações de desempenho relativas ao futuro ou ao passado. Não assumimos qualquer responsabilidade quanto à exatidão ou integralidade de qualquer informação aqui fornecida, nem qualquer perda que possa surgir de qualquer informação fornecida pelo HMC Trader.',
    tnc2: '*Para ser elegível para utilizar o serviço do HMC Sala de Negociação ao Vivo, deve cumprir o requisito mínimo inicial de financiamento indicado como acima desde a data do lançamento. Terá de concluir, pelo menos, $200.000 em negociações abertas e fechadas (alavancagem incluída) produtos oferecidos pela Moneta todos os meses para manter a permissão de acesso no HMC Sala de Negociação ao Vivo. A sua permissão de acesso será desativada automaticamente se não concluir, pelo menos, $200.000 em negociações (alavancagem incluida) completas num mês. Ainda terá de depositar o montante de financiamento necessário para reativar o acesso ao HMC Sala de Negociação ao Vivo.',
  },
  supportTickets: {
    support: 'Suporte',
    mySupportTickets: 'Os meus tickets de suporte',
    createNewTicket: 'Criar um novo pedido de ticket',
    ticketNo: 'N.º do ticket',
    subject: 'Assunto',
    status: 'Estado',
    lastUpdated: 'Última atualização',
    newTicketRequest: 'Criar um novo pedido de ticket',
    content: 'Conteúdo',
    attachments: 'Anexos',
    upload: 'Carregar',
    viewingTicket: 'Visualização nº ticket',
    postReply: 'Publicar uma resposta',
    update: 'Atualizar',
    cancel: 'Cancelar',
    close: 'Fechar',
    number: 'N.º',
    description: 'Descrição',
    postReplyRequired: 'O campo Publicar resposta é obrigatório',
    contentFieldRequired: 'O campo Conteúdo é obrigatório',
    subjectFieldRequired: 'O campo Assunto é obrigatório',
    successMsg: 'O seu ticket de suporte foi criado com sucesso!',
  },
  responseMsg: {
    410: 'A validação dos parâmetros falhou',
    411: 'Palavra-passe incorreta',
    420: 'O utilizador não existe',
    421: 'Redefinição de Palavra-passe negada',
    490: 'Por favor, não submeta informações duplicadas do cartão',
    500: 'Ocorreu um erro.',
    501: 'A Inscrição falhou. Por favor, tente novamente mais tarde.',
    505: 'O carregamento de ficheiros falhou',
    510: 'Código SMS incorreto',
    511: 'Não foi possível enviar o código SMS para o seu telefone',
    520: 'Falha na autenticação. Por favor, tente novamente.',
    521: 'Um utilizador já está ligado neste dispositivo!',
    522: 'Por favor, inicie a sessão novamente',
    523: 'Por favor, inicie a sessão novamente',
    524: 'Infelizmente o seu endereço de e-mail não foi reconhecido',
    525: 'Nome de utilizador ou palavra-passe incorretos',
    526: 'O recaptcha não corresponde',
    527: 'O utilizador não é um IB',
    528: 'O utilizador não existe',
    529: 'Nome de utilizador ou palavra-passe incorretos',
    530: 'Sessão de login inválida',
    531: 'Código inválido',
    540: 'Não foi possível encontrar a sua conta de negociação',
    541: 'Não foi possível encontrar a conta de compensação',
    542: 'Você não tem permissão para abrir uma conta adicional',
    544: 'Não está autorizado a candidatar-se a uma conta adicional até que o seu Comprovativo de Identificação seja verificado',
    550: 'Número de conta incorreto',
    551: 'O seu saldo é insuficiente',
    554: 'O valor solicitado é zero ou nulo',
    562: 'Não foi possível processar seu pagamento, por favor tente novamente. Se o problema persistir, favor entrar em contato conosco via chat, ou e-mail {email}.',
    564: 'O país não corresponde ao canal de pagamento',
    565: 'Código Bancário incorreto',
    566: 'Método de pagamento não encontrado',
    567: 'Infelizmente, este método de pagamento não está atualmente disponível. Pedimos desculpa por qualquer inconveniente causado',
    568: 'Esta conta está impedida de fazer um depósito, por favor contacte {email}',
    569: 'O seu nome/apelido/número de telefone próprio é obrigatório. Se precisar de assistência, por favor, contacte {depositEmail}',
    581: 'Você optou com sucesso pela promoção. Observe que você deve fazer um depósito a fim de retirar fundos.',
    584: 'Não tem compensações aplicáveis',
    587: 'Valor de saque inválido.',
    590: 'Não foi possível encontrar a conta da transferência realizada',
    591: 'Não foi possível encontrar a conta da transferência recebida',
    593: 'Não podemos processar a sua transferência de fundos da conta selecionada devido à sua conta conter crédito.',
    594: 'A transferência de fundos a partir desta conta não é permitida',
    624: 'Não é possível remover o saldo negativo desta conta. Para mais informações, envie um e-mail para support@monetamarkets.com.',
    625: 'Recebemos sua solicitação e ela já está sendo proessada. Se tiver dúvidas, envie um e-mail para support@monetamarkets.com.',
    626: 'Notamos que o saldo da sua conta é maior do que zero. Por favor, atualize a página e verifique novamente.',
    647: 'O código de verificação está incorreto',
    1001: 'A sua conta está temporariamente indisponível, por favor, contacte o seu gestor de conta',
    1102: 'Não foi possível ativar, a conta foi criada como uma conta real ou demo.',
    1103: 'A conta foi desativada',
    1202: 'Verificação expirada, por favor tente novamente',
    1204: 'Por favor, tente com outro endereço de e-mail',
    1208: 'Os códigos de verificação não podem ser enviados repetidamente',
    1209: 'Erro ao enviar o código de verificação',
    session_timeout:
      'O tempo limite da sessão foi excedido, está a ser redirecionado para a página de início de sessão.',
    vload: {
      4019: 'Tipo ou valor de Voucher não é aceite',
      4071: 'Suspeita de fraude',
      4072: 'O Voucher é utilizado',
      4073: 'O voucher não está ativo',
      4074: 'O Voucher está expirado',
      4075: 'O Voucher não é encontrado',
      4080: 'O valor do voucher é insuficiente',
      4444: 'Voucher inválido utilizado. Incompatibilidade da moeda.',
      5000: 'Os dados da loja não estão disponíveis',
    },
  },
  lock: {
    premium: {
      trading: {
        tools: {
          description: 'Para desbloquear funcionalidades premium, conclua já a sua inscrição',
        },
      },
    },
  },
};
