import i18n from '@/lang/index'
import store from '@/store'
const mapVariable = {
  email: 'email',
  totp: 'totp',
  addTwoFactor: 'enable-auth-2fa',
  login: 'login',
  switchLoginEmail: 'login-email-close',
}

/**
 * 安全管理页四种不同操作对应的显示限制出金提示
 * @param {*} operation  'modify-password' 'modify-email' 'enable-auth-2fa' 'modify-auth-2fa'
 * @param {*} showDays
 * @returns {*} {title: '', desc: {}, factorAuth: '', success: {}, confirmButton: '', cancelButton: ''}
 */
const mapOperation = (operation, showDays = 1) => {
  switch (operation) {
    case 'modify-password':
      return {
        title: i18n.t('common.button.changePw'),
        desc: i18n.t('security.changeDesc', {
          numDays: showDays,
          method: i18n.t('security.password'),
        }),
        factorAuth: 'modify-password',
        success: {
          title: i18n.t('security.changePasswordSuccess'),
          desc: i18n.t('security.changePasswordSuccessDesc'),
        },
        confirmButton: i18n.t('common.button.confirm'),
        cancelButton: i18n.t('common.button.cancel'),
      }
    case 'modify-email':
      return {
        title: i18n.t('security.changeEmail'),
        desc: i18n.t('security.changeDesc', {
          numDays: showDays,
          method: i18n.t('common.field.emailAdd'),
        }),
        factorAuth: 'modify-email',
        success: {
          title: i18n.t('security.changeEmailSuccess'),
          desc: i18n.t('security.changeEmailSuccessDesc'),
        },
        confirmButton: i18n.t('common.button.confirm'),
        cancelButton: i18n.t('common.button.cancel'),
      }
    case 'enable-auth-2fa':
      return {
        title: i18n.t('security.addAuthenticator'),
        desc: i18n.t('security.changeDesc', {
          numDays: showDays,
          method: i18n.t('security.securityAuthenticatorApp'),
        }),
        factorAuth: 'enable-auth-2fa',
        success: {
          title: i18n.t('security.enableSecuritySuccess'),
          desc: i18n.t('security.enableSecuritySuccessDesc'),
        },
        confirmButton: i18n.t('common.button.confirm'),
        cancelButton: i18n.t('common.button.cancel'),
      }
    case 'modify-auth-2fa':
      return {
        title: i18n.t('security.editAuthenticator'),
        desc: i18n.t('security.changeDesc', {
          numDays: showDays,
          method: i18n.t('security.securityAuthenticatorApp'),
        }),
        factorAuth: 'modify-auth-2fa',
        success: {
          title: i18n.t('security.updateSecuritySuccess'),
          desc: i18n.t('security.updateSecuritySuccessDesc'),
        },
        confirmButton: i18n.t('common.button.confirm'),
        cancelButton: i18n.t('common.button.cancel'),
      }
    default:
      return {}
  }
}

/**
 * 邮箱验证码发送提示语
 * @param {*} operation 'email'|'new-email'
 * @param {*} emailAddress
 * @returns {*} {showCodeButton: true, preDesc: '', postDesc: '', needEmail: }
 */
const mapVerificationOperation = (operation, emailAddress) => {
  switch (operation) {
    case mapVariable['email']: {
      return {
        showCodeButton: true,
        preDesc: i18n.t('security.verifyPreEmailNote', {
          email: emailAddress,
        }),
        postDesc: i18n.t('security.verifyPostEmailNote', {
          email: emailAddress,
        }),
      }
    }
    case 'new-email': {
      return {
        showCodeButton: true,
        needEmail: true,
        preDesc: i18n.t('security.preEmailCode'),
        postDesc: i18n.t('security.preEmailCode'),
      }
    }
    default: {
      return {
        showCodeButton: false,
        preDesc: i18n.t('security.authenticatorTooltip'),
      }
    }
  }
}

/**
 * 切换login authentication开关 打开提示语、关闭类型(factorAuth):'login-email-close'|'login-totp-close'
 * @param {*} value
 * @param {*} usedAuthenticator
 * @returns {*} {title:'', usedVariable: 'loginSwitch', factorAuth: ''} || null
 */
const mapSwitchComponent = (value, usedAuthenticator) => {
  switch (value) {
    case mapVariable['login']:
      return {
        title: i18n.t('security.enableLoginAuthentication'),
        usedVariable: 'loginSwitch',
        factorAuth: usedAuthenticator == mapVariable['email'] ? 'login-email-close' : 'login-totp-close',
      }
    default:
      return null
  }
}

/**
 * 是否显示验证弹框
 * @param {*} authType 'email'|'totp'
 * @param {*} verifiedAuths
 * @returns {*} true|false
 */
const mapVerifiedAuth = (authType, verifiedAuths) => {
  //for showing verification dialog purpose (don't need for email)
  switch (authType) {
    case mapVariable['email']:
      return true
    case mapVariable['totp']:
      return verifiedAuths.filter(item => item == authType).length > 0
  }
}

/**
 * 跳转对应验证页面(验证/添加验证)
 * @param {*} type 'email'|'totp'
 * @param {*} verified
 * @returns {*} route path: '/verifyAuthentication'|'/addAuthentication'
 */
const getToPath = (type, verified) => {
  switch (type) {
    case mapVariable['email']:
      return '/verifyAuthentication'
    case mapVariable['totp']:
      return verified ? '/verifyAuthentication' : '/addAuthentication'
  }
}

/**
 * 处理响应内容返回的需要验证的方式
 * @param {*} factorAuth
 * @param {*} resp
 * @returns {*} {type: '', authenticated: false, verified: }
 */
const mapReverifiedBody = (factorAuth, resp) => {
  const respBody = resp.data.data
  const usedAuths = resp.data.code == 1201 ? respBody.lackAuthMethods : respBody.effectiveAuthMethods
  store.commit('common/setCheckedAuthStatus', respBody.authStatus)
  let usedRequiredAuths = store.state.common.requiredAuths
  usedRequiredAuths[factorAuth] =
    resp.data.code == 1201
      ? respBody.effectiveAuthMethods.concat(respBody.lackAuthMethods)
      : respBody.effectiveAuthMethods
  store.commit('common/setRequiredAuths', usedRequiredAuths)
  return usedAuths.map(item => {
    return {
      type: item,
      authenticated: false,
      verified: mapVerifiedAuth(item, respBody.authStatus),
    }
  })
}
export {
  mapOperation,
  mapVerificationOperation,
  mapVariable,
  mapSwitchComponent,
  getToPath,
  mapVerifiedAuth,
  mapReverifiedBody,
}
