// 進行異步執行
import { apiTcmktGetUrl, apiEmploymentFinanceAnswers, apiTradingAnswers } from '@/resource'
import { apiGetCurrentStepV2 } from '@/resource/register'

export default {
  async actionRegisterStep({ commit, state }) {
    return new Promise((resolve, reject) => {
      apiGetCurrentStepV2()
        .then(result => {
          if (result.data.code === 0 && result.data.data) {
            commit('setRegisterStep', result.data.data)
            resolve(result.data.data)
          }
          reject(state.registerStep)
        })
        .catch(e => {
          reject(e)
        })
    })
  },
  async actionTradingToolUrl({ commit, state }, lang) {
    if (state.tradingToolUrl.length > 0) return
    return new Promise((resolve, reject) => {
      apiTcmktGetUrl().then(result => {
        commit('setTradingToolUrl', result.data.data)
        resolve(result.data.data)
      })
    })
  },
  async actionEmploymentFinanceAnswers({ commit, state }) {
    if (state.registerStep > 4 && state.employmentFinanceAnswers.length > 0) return state.employmentFinanceAnswers
    return new Promise((resolve, reject) => {
      apiEmploymentFinanceAnswers().then(result => {
        commit('getEmploymentFinanceAnswers', result.data)
        resolve(result.data)
      })
    })
  },
  async actionTradingAnswers({ commit, state }) {
    if (state.registerStep > 4 && state.tradingAnswers.length > 0) return state.tradingAnswers
    return new Promise((resolve, reject) => {
      apiTradingAnswers().then(result => {
        commit('getTradingAnswers', result.data)
        resolve(result.data)
      })
    })
  },
  updateCheckedAuthStatus({ commit }, values) {
    commit('setCheckedAuthStatus', values)
  },
}
