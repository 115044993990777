export default {
  common: {
    liveChat: { desc: '<li><p>Hai bisogno di aiuto?</p><span>Chat dal vivo</span></li>' },
    country: { international: 'Internazionale', australia: 'Australia', uk: 'Regno Unito' },
    field: {
      nameOnCard: 'Nome sulla carta',
      first4Digits: 'Prime 4 Cifre sulla Carta',
      last3Digits: 'Ultimi 3 Cifre sulla Carta',
      first6Digits: 'Prime 6 Cifre sulla Carta',
      last4Digits: 'Ultime 4 Cifre sulla Carta',
      bankCode: 'Codice Bancario',
      bankBranchCode: 'Codice Filiale',
      bankAddress: 'Indirizzo della banca',
      branchAddress: 'Indirizzo della filiale',
      bankCity: 'Città della Banca',
      bankProvince: 'Provincia Bancaria',
      bankBeneficiaryName: 'Nome del Beneficiario Bancario',
      bankBeneAccName: "Nome dell'Account del Beneficiario Bancario",
      bankBeneAccNum: 'Numero di Conto del Beneficiario Bancario',
      beneAccName: "Nome dell'Account Beneficiario",
      beneAccNum: 'Numero di Conto del Beneficiario',
      bankBeneAddress: 'Indirizzo del Beneficiario',
      bankAccNum: 'Numero di Conto Bancario',
      bankAccName: 'Nome del Conto Bancario',
      bankHolderAddress: 'Indirizzo del Titolare del Conto',
      address: 'Indirizzo',
      accName: "Nome dell'account",
      accNum: 'Numero di Conto',
      accNumTo: 'Numero di Conto',
      bsbNum: 'Numero BSB',
      swift: 'codice SWIFT',
      swiftBank: 'Codice Swift della banca',
      sort: 'Codice di ordinamento',
      ifscCode: 'codice IFSC',
      swiftNonAUD: 'Codice SWIFT (Conti non AUD)',
      amt: 'Importo',
      amtUSD: 'Importo (USD)',
      QRaccNum: 'Numero di Conto',
      QRamount: 'Importo in {valuta}',
      QRamountHKD: 'Importo in HKD',
      imptNotes: 'Note importanti',
      yes: 'Sì',
      no: 'No',
      payMethod: 'Metodo di Pagamento:',
      bsb: 'BSB',
      abaSortCode: 'ABA/Codice di ordinamento:',
      country_region: 'Paese / Regione',
      currLeverage: 'Leverage Corrente',
      accEquity: 'Equità del Conto',
      newLeverage: 'Leva Desiderata',
      currPw: 'Password attuale',
      newPw: 'Nuova Password',
      confirmPw: 'Conferma Nuova Password',
      loginEmail: 'Email di accesso',
      email: 'Email',
      pw: 'Password',
      accType: 'Tipo di Account',
      name: 'Nome',
      emailAdd: 'Indirizzo Email',
      phone: 'Numero di Telefono',
      countryCode: 'Codice del Paese',
      dob: 'Data di Nascita',
      nat: 'Nazionalità',
      country: 'Paese di residenza',
      street: 'Numero civico/Nome della strada',
      suburbOrCity: 'Sobborgo/Città',
      provinceOrState: 'Provincia/Stato',
      postcode: 'Codice postale',
      empStat: 'Stato Occupazionale',
      occupation: 'Occupazione',
      annIncome: 'Reddito annuale',
      save: 'Risparmio/Investimento',
      source: 'Fonte di Reddito',
      industry: 'Industria',
      statusDef: 'DEFINIZIONI DI STATO',
      month: 'Mese',
      year: 'Anno',
      select: 'Seleziona',
      completed: 'Completato',
      incomplete: 'Incompleto',
      submitted: 'Inviato',
      successful: 'Riuscito',
      processing: 'Elaborazione',
      cancelled: 'Annullato',
      failed: 'Fallito',
      rejected: 'Rifiutato',
      upload: 'Carica',
      ccNum: 'Numero della Carta di Credito',
      expDate: 'Data di Scadenza',
      bankName: 'Nome della Banca',
      bankCardNumber: 'Numero della Carta Bancaria',
      bankBranch: 'Filiale bancaria',
      cardHolderName: 'Nome del Titolare della Carta',
      reservedMobileNumber: 'Numero di Telefono Cellulare per la Banca',
      nationalId: "Carta d'Identità Nazionale",
      cardPhoto: 'Foto della Carta (Fronte)',
      notes: 'Appunti',
      bankAccOrIBAN: 'Numero di Conto Bancario/IBAN',
      selectWithdrawCard: 'Seleziona la tua carta di prelievo',
      updateCardPhoto: 'per favore carica la foto della tua carta (Fronte)',
      transitNumber: 'Numero di Transito',
      institutionNumber: "Numero dell'Istituzione",
      resetBalTo: 'Reimposta Saldo a',
      confirmation: 'Sei sicuro?',
      removeNote: 'Vuoi davvero eliminare questo record? Questo processo non può essere annullato.',
      vpa: 'VPA',
      wallet: 'Portafoglio',
      depositCurrency: 'Valuta del Deposito',
      bankAccountCurrency: 'Valuta del Conto Bancario',
      ABACode: 'Codice Fedwire/ABA',
      maximumAmountTip: 'Importo massimo per il deposito: {amount} {currency}',
      bankRouteNum: 'Numero di instradamento bancario',
      paymentRef: 'Riferimento di Pagamento',
      branch: 'Ramo',
      ipChangeWarning:
        "Ricevi un'email di avviso quando qualcuno accede al tuo account da una posizione diversa",
      documentType: 'Tipo di Documento',
      documentDetails: 'Dettagli del Documento',
      pixKeyType: 'Tipo di Chiave PIX',
      pixKey: 'Chiave PIX',
      enterVerificationCode: 'Inserisci il codice di verifica',
      accDigit: "Cifra dell'Account",
      docID: 'ID Documento',
      paymentAccName: 'Nome Account {name}',
      paymentAccNum: 'Numero di Conto {name}',
      region: 'Regione',
      beneficiaryIBANNumber: 'Numero IBAN del beneficiario',
      beneficiaryBankSwift: 'Codice Swift della banca beneficiaria',
      beneficiaryBankName: 'Nome della Banca Beneficiaria',
      beneficiaryBankAddress: 'Indirizzo della Banca Beneficiaria',
      beneficiaryBankSortCode: 'Codice di ordinamento della banca beneficiaria',
      reversedfailed: 'Fallimento Invertito',
      cardNumber: 'Numero della carta',
      beneficiaryName: 'Nome del beneficiario',
    },
    hover: {
      SwiftCode:
        'Il codice SWIFT, noto anche come Codice Identificativo Bancario (BIC), è un codice internazionale utilizzato per identificare il paese, la banca e la filiale, ed è richiesto quando si trasferisce denaro a livello internazionale.',
      BankAccountNumber:
        'Il numero di conto bancario o IBAN (International Bank Account Number) è il numero utilizzato per identificare il tuo conto bancario.',
      BankName: "Il nome della banca o dell'istituzione finanziaria che sta ricevendo i fondi.",
      BankBeneficiaryName:
        "Il nome della persona o dell'azienda che possiede il conto bancario che sta ricevendo i fondi.",
      AccountHoldersAddress:
        "L'indirizzo fisico della persona o dell'azienda che possiede il conto bancario.",
      BankAddress:
        "L'indirizzo fisico della banca o dell'istituto finanziario che sta ricevendo i fondi.",
    },
    verification: { toSlide: 'Scorri per completare il puzzle' },
    column: {
      date: 'DATA',
      type: 'TIPO',
      server: 'SERVER',
      status: 'STATO',
      comment: 'COMMENTO',
      accNum: 'NUMERO DI CONTO',
      currency: 'VALUTA',
      equity: 'EQUITY',
      credits: 'CREDITI',
      balance: 'BILANCIA',
      leverage: 'LEVA',
      resetMT4PW: 'REIMPOSTA LA PASSWORD MT4',
      resetMT5PW: 'REIMPOSTA LA PASSWORD MT5',
      tradingAcc: 'CONTO DI TRADING',
      method: 'METODO',
      amt: 'IMPORTO',
      procNote: 'NOTE SUL PROCESSO',
      number: 'NUMERO DI CARTA',
      resetBal: 'RESETTA SALDO',
      remove: 'RIMUOVI',
    },
    key: { from: 'DA', to: 'A' },
    fund: {
      must: 'DEVE',
      infoHover: 'Il nome sulla tua carta {deve} corrispondere al nome sul tuo conto di trading',
      ccUploadReminder:
        'Si prega di caricare una foto del fronte della tua carta per la verifica. (Si prega di coprire le 6 cifre centrali)',
    },
    button: {
      bkToHm: 'Torna alla Pagina Principale',
      smsLogin: 'ACCESSO MOBILE',
      userLogin: 'ACCESSO EMAIL',
      goToIB: 'Passa al portale IB',
      logout: 'ESCI',
      submit: 'INVIA',
      selectFile: 'Seleziona File',
      depositFunds: 'DEPOSITA FONDI',
      withdrawFunds: 'PRELEVA FONDI',
      ibPortal: 'PORTALE IB',
      download: 'DOWNLOAD',
      changeLeverage: 'CAMBIA LEVA',
      cancel: 'ANNULLA',
      changePw: 'CAMBIA PASSWORD',
      forgetPw: 'PASSWORD DIMENTICATA',
      confirm: 'CONFERMA',
      login: 'ACCESSO',
      forgotPw: 'Hai dimenticato la password?',
      completeQuestionnaire: 'COMPILA IL QUESTIONARIO',
      identityProof: 'DOMANDA COMPLETA',
      upload: 'CARICA',
      continue: 'Continua',
      enable: 'ABILITA',
      disable: 'DISABILITA',
      export: 'ESPORTAZIONE',
      ok: 'OK',
      hide: 'NASCONDI',
      unhide: 'SCOPRI',
      showAll: 'Mostra tutto',
      beginner: 'Principiante',
      intermediate: 'Intermedio',
      advanced: 'Avanzato',
      sendCode: 'Invia Codice',
      resendCode: 'Rinvia il Codice',
    },
    formValidation: {
      accReq: 'È richiesto il numero di conto',
      amtReq: "È richiesto l'importo",
      ccReq: 'È richiesto il numero della carta di credito',
      cardReq: 'È richiesto il numero della carta',
      cardOnNameReq: 'Il nome sulla carta è richiesto',
      digits16: 'Si prega di inserire 16 cifre',
      digits3or4: 'Si prega di inserire 3 o 4 cifre',
      digitsFirst6: 'Si prega di inserire le prime 6 cifre',
      digitsLast4: 'Si prega di inserire le ultime 4 cifre',
      digitsLast3: 'Si prega di inserire le ultime 3 cifre',
      mid6Digits: 'Si prega di inserire le cifre centrali {numero}',
      expDateReq: 'La data di scadenza è obbligatoria',
      cvvReq: 'È richiesto il codice di sicurezza CVV',
      file: 'Per favore carica un file',
      filePhoto: 'È richiesta la foto della carta di credito',
      fileReceipt: 'Per favore, carica una ricevuta',
      filePhotoReq: 'È richiesta la foto della carta',
      amtLarger:
        "L'importo non può essere nullo e deve essere maggiore o uguale a ${minLimit} {valuta}",
      amt0: "L'importo non può essere 0",
      amtLess: "L'importo del tuo deposito non può essere superiore a ${maxLimit} {currency}",
      note256: 'Si noti che il limite di lunghezza è di 256 caratteri',
      noteMoreThan: 'La lunghezza massima della nota è di {limit} caratteri',
      noteLessThan: 'La lunghezza massima della nota è di {limit} caratteri',
      emailFormat: 'Si prega di inserire un indirizzo email corretto',
      payMethodReq: 'È richiesto un metodo di pagamento',
      expReq: 'La data di scadenza è richiesta',
      bankNameReq: 'È richiesto il nome della banca',
      bankCityReq: 'È richiesta la città della banca',
      bankProvinceReq: 'È richiesta la Provincia della Banca',
      bankAddressReq: "È richiesto l'indirizzo della banca",
      bankBranchReq: 'È richiesta la filiale bancaria',
      bankBranchCodeReq: 'È richiesto il codice filiale',
      bankAccNumReq: 'È richiesto il numero di conto bancario',
      ifscCodeReq: 'È richiesto il codice IFSC',
      bankBeneficiaryNameReq: 'Il nome del beneficiario della banca è richiesto',
      bankHolderAddressReq: "È richiesto l'indirizzo del titolare del conto",
      swiftReq: 'È richiesto il codice Swift',
      accHolderAddress: "È richiesto l'indirizzo del titolare del conto",
      bsbReq: 'È richiesto BSB',
      accNumIBANReq: 'È richiesto il numero di conto bancario/IBAN',
      accNameReq: 'Il nome del conto bancario è richiesto',
      withdrawMethodReq: 'Si prega di selezionare un metodo di prelievo',
      tnc: 'Si prega di accettare i Termini e le Condizioni.',
      currPwReq: 'È richiesta la password attuale',
      currPwWrong: 'La password attuale non è corretta',
      newPwReq: 'È richiesta una nuova password',
      newPwFormat:
        'La tua password deve essere compresa tra 8-16 caratteri e un mix di lettere (a-z e A-Z), numeri (0-9) e caratteri speciali come !@#$%^&*.()',
      newPwAccFormat:
        'La tua password deve essere compresa tra 8-16 caratteri e un mix di lettere (a-z e A-Z), numeri (0-9) e caratteri speciali come !@#$%^&*',
      IdenficalPw: 'La nuova password non può essere la stessa della vecchia',
      confirmPwReq: 'Si prega di inserire nuovamente la nuova password',
      confirmNotMatch: 'Due input non corrispondono',
      pwReq: 'La password è richiesta',
      accCurrReq: 'Devi selezionare una valuta per il tuo account',
      tradAccReq: 'Si prega di selezionare un conto di trading',
      answerReq: 'Si prega di selezionare',
      bankCardReq: 'Si prega di selezionare una carta',
      phoneNumberReq: 'È richiesto il numero di cellulare per la Banca',
      nationaIdReq: "È richiesto il documento d'identità nazionale",
      numberReq: 'Si prega di inserire il numero',
      alphanumericReq: 'Si prega di inserire alfanumerico',
      idTypeReq: 'Si prega di scegliere un tipo di ID',
      dobReq: 'Si prega di scegliere la data di nascita',
      pobReq: 'Si prega di scegliere il luogo di nascita',
      nationalityReq: 'Si prega di scegliere la vostra nazionalità',
      titleReq: 'Si prega di scegliere il vostro titolo',
      firstNameReq: 'Si prega di inserire il vostro nome di battesimo',
      lastNameReq: 'Si prega di inserire il proprio cognome',
      emailReq: 'Si prega di inserire il proprio indirizzo email',
      addressReq: 'Si prega di inserire il vostro indirizzo',
      suburbReq: 'Si prega di inserire la vostra città o sobborgo',
      stateReq: 'È richiesto lo stato o la provincia',
      postcodeReq: 'Si prega di inserire il vostro codice postale',
      countryReq: 'Si prega di selezionare il proprio paese di residenza',
      NIReq: 'Si prega di inserire il vostro numero di assicurazione nazionale (NI)',
      taxResidencyReq: 'Si prega di selezionare il tuo Paese(i) di residenza fiscale',
      yearsAtAddressReq: "Si prega di inserire il numero di anni di residenza all'indirizzo",
      bankCodeReq: 'Si prega di inserire la Banca Emettitrice della Carta',
      dynamicReq: '{dynamic} è richiesto',
      mobReq: 'è richiesto il cellulare',
      confirmed: 'Si prega di confermare',
      resetBalAmt: "Si prega di impostare l'importo tra 0 e 1.000.000",
      common: 'Questo campo è obbligatorio.',
      verCodeReq: 'È richiesto il codice di verifica',
      noEligibleAccounts: 'Nessun Conto Idoneo',
      withdrawAmtLarger: "L'importo minimo di prelievo è ${minLimit} {currency} o equivalente",
      AlphanumericOnlyNotSpace: 'Solo alfanumerico. Non è consentito lo spazio.',
      AlphanumericOnly: 'Solo alfanumerico.',
      NumbersSymbol: 'Solo numeri e simboli.',
      NumbersOnlyNotSpace: 'Solo numeri. Non è consentito lo spazio.',
      NumbersOnly: 'Solo numeri.',
      LettersSymbol: 'Solo lettere e simboli.',
      LettersSymbolNotSpace: 'Solo lettere e simboli. Non è consentito lo spazio.',
      LettersOnlyNotSpace: 'Solo lettere. Lo spazio non è consentito.',
      NotSpace: 'Non è permesso lo spazio.',
      beneficiaryNameReq: 'Il nome del beneficiario è richiesto',
    },
    withdrawChannel: {
      banktransferbpaypolipay: 'Trasferimento Bancario/BPay/PoliPay',
      banktransfer: 'Trasferimento Bancario Internazionale',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      advcash: 'ADVcash',
      unionpaytransfer: 'Trasferimento UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Carta di Credito',
      archiveThisCard: 'Si prega di archiviare questa carta se non è più valida o in uso',
      update: 'Aggiornamento',
      archive: 'Archivio',
      updateExpiryMsg:
        'Si prega di aggiornare la data di scadenza della tua carta per utilizzare la tua carta per il prelievo',
      available: 'Disponibile',
      pending: 'In sospeso',
      unavailable: 'Non disponibile',
      fullyRefunded: 'Totalmente Rimborsato',
      requiredDocument: "Documenti richiesti per l'archiviazione della carta",
      officialLetter:
        'Lettera/email ufficiale della banca che afferma che la carta non è più disponibile',
      bankStatement: 'Estratto conto con transazione effettuata con questa carta',
      expiredCard: 'Carte Scadute/Non Disponibili',
      confirmArchive: 'Sei sicuro di voler archiviare questa carta?',
      confirmUpdate:
        'Sei sicuro di voler aggiornare la data di scadenza di questa carta? Nota: Moneta non sarà responsabile se il prelievo fallisce a causa della data di scadenza errata fornita.',
      bpay: 'Bpay',
      creditdebitcard: 'Carta di Credito/Debito',
      bankwiretransferinternational: 'Bonifico Bancario (Internazionale)',
      banktransferaustralia: 'Trasferimento Bancario (Australia)',
      banktransferinternational: 'Trasferimento Bancario Internazionale',
      banktransferchina: 'Trasferimento Bancario (Internazionale)',
      banktransferuk: 'Trasferimento Bancario (UK)',
      mobilepay: 'Pagamento Mobile',
      brokertobrokertransfer: 'Trasferimento da Broker a Broker',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Internet Banking (Thailandia)',
      internetbankingnigeria: 'Internet Banking (Nigeria)',
      internetbankingvietnam: 'Internet Banking (Vietnam)',
      internetbankingmalaysia: 'Internet Banking (Malesia)',
      internetbankingindonesia: 'Internet Banking (Indonesia)',
      internetbankingkorea: 'Internet Banking (Corea)',
      internetbankingsouthkorea: 'Internet Banking (Corea del Sud)',
      internetbankingindia: 'Internet Banking (India)',
      internetbankingphilippine: 'Internet Banking (Filippine)',
      internetbankingsouthafrica: 'Internet Banking (Sudafrica)',
      internetbankinguk: 'Internet Banking (Regno Unito)',
      internetbankinglaos: 'Internet Banking (Laos)',
      internetbankinghk: 'Internet Banking (Hong Kong)',
      internetbankinguganda: 'Internet Banking (Uganda)',
      internetbankingrwanda: 'Internet Banking (Ruanda)',
      internetbankingzambia: 'Internet Banking (Zambia)',
      internetbankingcongo: 'Internet Banking (Congo)',
      internetbankingcameroon: 'Internet Banking (Camerun)',
      internetbankingburundi: 'Internet Banking (Burundi)',
      internetbankingkenya: 'Internet Banking (Kenya)',
      internetbankingghana: 'Internet Banking (Ghana)',
      internetbankingtanzania: 'Internet Banking (Tanzania)',
      internetbankingbrazil: 'Internet Banking (Brasile)',
      internetbankingmexico: 'Internet Banking (Messico)',
      internetbankingcolombia: 'Internet Banking (Colombia)',
      internetbankingchile: 'Internet Banking (Cile)',
      internetbankingperu: 'Internet Banking (Perù)',
      internetbankingusd: 'Internet Banking (USD)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Trasferimento Bancario Vietnam',
      nigeriabanktransfer: 'Trasferimento bancario Nigeria',
      polipayment: 'Pagamento Poli',
      bankwiretransferaustralia: 'Bonifico Bancario (Australia)',
      thailandinstantbankwiretransfer: 'Bonifico Bancario Istantaneo Thailandia',
      nigeriainstantbankwiretransfer: 'Bonifico Bancario Istantaneo Nigeriano',
      vietnaminstantbankwiretransfer: 'Bonifico Bancario Istantaneo Vietnamita',
      laosinstantbankwiretransfer: 'Trasferimento Bancario Istantaneo del Laos',
      malaysiainstantbankwiretransfer: 'Trasferimento Bancario Istantaneo Malese',
      indonesiainstantbankwiretransfer: 'Trasferimento Bancario Istantaneo in Indonesia',
      indiainstantbanktransfer: "Bonifico Bancario Istantaneo dell'India",
      southafricainstantbankwiretransfer: 'Bonifico Bancario Istantaneo del Sud Africa',
      philippinesinstantbankwiretransfer: 'Trasferimento Istantaneo di Banca Filippina',
      indialocalbankwiretransfer: 'Bonifico Bancario Locale in India',
      indiabanktransfer: 'Trasferimento Bancario India',
      hongkongbanktransfer: 'Trasferimento bancario locale di Hong Kong',
      dollarSmartQR: 'Trasferimento bancario QR di Hong Kong',
      ugandabanktransfer: 'Trasferimento bancario locale in Uganda',
      rwandabanktransfer: 'Trasferimento Bancario Locale in Ruanda',
      zambiabanktransfer: 'Trasferimento bancario locale Zambia',
      congobanktransfer: 'Trasferimento bancario locale del Congo',
      cameroonbanktransfer: 'Trasferimento bancario locale in Camerun',
      burundibanktransfer: 'Trasferimento bancario locale del Burundi',
      kenyabanktransfer: 'Trasferimento bancario locale in Kenya',
      ghanabanktransfer: 'Trasferimento bancario locale in Ghana',
      tanzaniabanktransfer: 'Trasferimento bancario locale in Tanzania',
      brazilbanktransfer: 'Trasferimento Bancario Brasile',
      mexicobanktransfer: 'Trasferimento Bancario Istantaneo Messico',
      pagsmileBrazil: 'Trasferimento Bancario Istantaneo del Brasile',
      pagsmileMexico: 'Trasferimento Bancario Istantaneo Messico',
      pagsmileColombia: 'Trasferimento Bancario Istantaneo Colombia',
      pagsmileChile: 'Trasferimento Bancario Istantaneo del Cile',
      pagsmilePeru: 'Trasferimento Bancario Istantaneo del Peru',
      banktransferbpaypolipayaustralia: 'Trasferimento Bancario/BPay/PoliPay (Australia)',
      banktransferbpaypolipayinternational: 'Trasferimento Bancario/BPay/PoliPay (Internazionale)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Trasferimento bancario locale canadese',
      cryptobitcoin: 'Crypto-Bitcoin',
      cryptotetheromni: 'Crypto-USDT(OMNI)',
      cryptotethererc20: 'Crypto-USDT(ERC20)',
      cryptotethertrc20: 'Crypto-USDT(TRC20)',
      cryptocurrencybitcoin: 'Criptovaluta-Bitcoin',
      cryptocurrencyusdt: 'Criptovaluta-USDT',
      cryptocurrencyeth: 'Criptovaluta-ETH',
      cryptocurrencyusdc: 'Criptovaluta-USDC(ERC20)',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      trustly: 'Trustly',
      astropay: 'Astropay',
      webmoney: 'WebMoney',
      vload: 'Vload',
      dasshpeupi: 'UPI',
      dasshpewallet: 'Portafogli India',
      localdepositor: 'Depositante Locale',
      cryptocurrencyethcps: 'Criptovaluta-ETH',
      cryptocurrencyusdcerc20: 'Criptovaluta-USDC(ERC-20)',
      cryptocurrencyusdccps: 'Criptovaluta-USDC(ERC-20)',
      dragonphoenix: 'Internet Banking (Corea del Sud)',
      pix: 'PIX',
      chileBankTransfer: 'Trasferimento bancario Cile',
      colombiaBankTransfer: 'Trasferimento Bancario Colombia',
      perubanktransfer: 'Trasferimento bancario Peru',
      spei: 'SPEI',
      alipay: 'Alipay',
    },
    currencyIDR: 'IDR',
    currencyTHB: 'THB',
    currencyVND: 'VND',
  },
  home: {
    pendingTooltip:
      "Una volta approvato il tuo account live, riceverai un'email contenente i tuoi dettagli di accesso MT{numero} così potrai iniziare a fare trading!",
    leverage: {
      tncTitle: '<span>Termini e Condizioni</span>',
      tnc: "<p>Inviando questa richiesta, riconosco di essere consapevole che un'elevata leva finanziaria comporta un grande rischio per il mio capitale e c'è la possibilità che potrei subire una perdita superiore e non limitata al capitale che ho depositato.<br><br>Accetto che l'emittente dei prodotti abbia il diritto di esaminare le mie attività di trading e di regolare i livelli di leva sul mio conto di trading a sua discrezione e senza alcun preavviso o notifica.<br><br>Comprendo che scegliendo un'elevata leva finanziaria, posso fare trading con un margine maggiore ma potenzialmente incorrere in perdite significative.</p>",
      tncConfirm: 'Ho letto e accettato i termini e le condizioni.',
      changeLeverageConfirm:
        'La tua richiesta è stata inviata e verrà elaborata dal nostro team di supporto a breve.',
      changeLeverageAutoComplete: 'La tua richiesta di modifica della leva è stata completata',
      changeLeverageReject: 'La richiesta di modifica del leverage è stata respinta',
      changeLeverageFail:
        'La richiesta di modifica della leva è fallita, si prega di riprovare più tardi',
      changeLeverageDefault:
        'Si è verificato un errore, si prega di contattare {supportEmail} per finalizzare la tua domanda',
      alreadyApplied:
        "Hai già richiesto di modificare la leva sul tuo conto ed è attualmente in fase di revisione da parte del team di supporto {platform}. Attendi che questa richiesta sia finalizzata prima di inviare un'altra richiesta.",
      autoRestore:
        '<p>Poiché il tuo patrimonio netto è >= 20.000, la tua leva finanziaria disponibile è 500:1</p><br /><p>Quando il tuo patrimonio netto è inferiore a 20.000 di nuovo, puoi regolare a 1000:1 di nuovo</p>',
      oneThousandRatio:
        "<p>Si prega di fare riferimento ai seguenti termini se si desidera passare a una leva di 1000:1:<br><br>1. Non è possibile richiedere promozioni relative al credito<br><br>2. Il patrimonio del tuo conto non può superare 15.000 USD o equivalente al momento della domanda<br><br>3. La leva del conto sarà cambiata a 500:1 se il patrimonio del tuo conto supera 20.000 USD o equivalente <br><br>4. I limiti di leva possono variare in base alle normative applicabili <br><br>5. Il cambio di leva non si applica ai prodotti che hanno una leva fissa <br><br> 6. Secondo l'accordo con il cliente, saranno adottate misure che includono, ma non si limitano a, la Terminazione o la Detrazione, se violi le clausole relative agli Eventi di Inadempimento e/o all'Attività di Trading Sospetta<br><br></p>",
      reduceLeverageWarn:
        'Si prega di notare: la riduzione della tua leva potrebbe comportare la chiusura forzata delle posizioni aperte se il tuo conto non ha un margine sufficiente.',
      reduceLeverageConfirm: 'Desideri continuare?',
    },
    pw: {
      error: 'Impossibile reimpostare la tua password. Si prega di riprovare più tardi',
      confirm:
        'La tua password è stata aggiornata.<br>Ora puoi utilizzare la tua nuova password per accedere al tuo account di trading MT4/MT5.',
      email:
        '<p class="first_info">Abbiamo appena inviato un\'email con le istruzioni per reimpostare la tua password.</p><p>Assicurati di controllare la cartella spam della tua email.</p>',
    },
    activate: {
      question: 'Desideri riattivare il tuo account live?',
      confirm:
        'Abbiamo ricevuto la tua richiesta di attivazione.<br>Il tuo account sarà attivato a breve.',
    },
    accStatus: {
      active: 'Attivo',
      rejected: 'Rifiutato',
      inactive: 'Inattivo',
      processing: 'Elaborazione',
      activate: 'Attiva',
      expired: 'Scaduto',
    },
    app: {
      title: 'Come funziona',
      desc01: 'Scarica il {platform}',
      desc02: 'Accedi con la tua email esistente',
      desc03: 'Verifica il tuo numero di telefono',
      desc04: 'Sei a posto. Goditi il trading potente.',
    },
    reset: {
      success: 'Il tuo ripristino è stato eseguito con successo',
      creditTip:
        'Il saldo del tuo conto è inferiore a 0, azzerando il saldo del tuo conto si azzererà anche il tuo credito. Sei sicuro di procedere?',
      resetBtn: 'Reimposta',
      tableHeader: 'Saldo Negativo',
    },
    appNote: { desc02: 'Clicca qui per saperne di più.' },
  },
  oneThousandRatioError: {
    error1: 'Questo account ha un bonus e non può essere regolato con una leva di 1000:1',
    error4:
      'Il patrimonio del conto è superiore a $15.000 (o valuta equivalente) e non può essere regolato con una leva di 1000:1',
    error5: "L'audit dell'ID non è passato, non può essere regolato su una leva di 1000:1",
    error6:
      "L'account ha partecipato all'attività bonus e non può essere regolato su una leva di 1000:1",
  },
  paymentDetails: {
    subHeader: 'AGGIUNGI UN METODO DI PAGAMENTO',
    payMethod: 'Metodo di Pagamento',
    result: {
      defaultSuccessMessage:
        'Grazie per aver inviato il metodo di finanziamento per il tuo conto di trading. Stiamo attualmente elaborando la tua richiesta e ti contatteremo a breve.',
      unionPayCardSuccessMessage:
        'I dettagli della carta Union Pay sono stati inviati con successo, i tuoi nuovi dettagli bancari saranno disponibili una volta approvati.',
    },
    history: { header: 'METODI DI PAGAMENTO' },
    cc: {
      field: {
        ccNum: 'Numero della Carta di Credito',
        first6: 'Primi Sei',
        last4: 'Ultime Quattro',
        withrawlable: 'Il prelievo massimo disponibile per questa carta è di {importo}.',
      },
      formValidation: { upload: 'È richiesta la foto della carta di credito' },
    },
    tip: '<p>Seleziona il tuo metodo di pagamento preferito per futuri depositi e prelievi dal menu sottostante.</p><br />\n    <p><b>Si prega di notare:</b> Per garantire che le tue richieste di deposito e prelievo possano essere elaborate prontamente, le normative AML/CTF richiedono che il nome visualizzato sul tuo metodo di pagamento scelto sia\n      <b> {individualUserName}</b>.</p>',
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'Recupero lo stato del tuo deposito... {countDown} secondi rimasti.',
      successMsg: 'Grazie per il tuo deposito, i tuoi fondi appariranno presto nel tuo conto.',
      rejectMsg: 'Rifiutato',
      failureMsg: 'Il tuo deposito non è stato effettuato con successo. Per favore, riprova.',
      message:
        "Grazie per aver finanziato il tuo account. Il tuo deposito sarà approvato manualmente dal team dell'account {platform} a breve. I fondi saranno depositati nel tuo account una volta che è stato approvato",
      defaultException:
        'Per confermare il tuo deposito con {platform}, si prega di contattare {email}',
    },
  },
  deposit: {
    default: {
      deposit: 'Deposito',
      reference: 'Il tuo riferimento',
      sendReceipt: 'Invia Ricevuta',
      sendReceiptTip:
        'Una volta effettuato il trasferimento, si prega di compilare il modulo sottostante e allegare uno screenshot, una foto o una copia scannerizzata della ricevuta del bonifico bancario che mostra chiaramente il tuo nome completo, numero di conto e importo del deposito, in modo che possiamo elaborare la tua transazione.',
      reminder:
        'Si prega di consentire 1-2 giorni lavorativi affinché i fondi vengano accreditati sul tuo conto.',
      successMsg:
        'I fondi appariranno nel tuo conto di trading una volta che riceveremo il deposito e verificheremo la ricevuta della tua transazione.',
      alt: 'In alternativa, puoi inviare la ricevuta della tua transazione a {mailTo} al più presto.',
      reminderFundAppear:
        'I tuoi fondi appariranno nel tuo conto non appena riceveremo i fondi dal tuo broker precedente.',
      failed: 'Deposito non riuscito, si prega di riprovare più tardi.',
      fee: 'TASSA',
      instant: 'ISTANTANEO',
      hour: 'ORA',
      hours: 'ORE',
      businessDays: 'GIORNI LAVORATIVI',
      channels: {
        creditOrDebit: 'Carta di Credito/Debito',
        i12BankTransfer: 'Trasferimento Bancario Internazionale',
        auBankTransfer: 'Trasferimento Bancario Australia',
        bPay: 'Trasferimento bancario BPAY',
        skrill: 'Skrill/Moneybookers',
        sticPay: 'SticPay',
        webMoney: 'WebMoney',
        neteller: 'Neteller',
        b2b: 'Trasferimento da Broker a Broker',
        unionPay: 'Union Pay',
        unionPayCN: 'Trasferimento China Union Pay',
        fasa: 'Fasapay',
        vietInstant: 'Bonifico Bancario Istantaneo Vietnam',
        thaiInstant: 'Bonifico Bancario Istantaneo Thailandia',
        nigeriaInstant: 'Bonifico Bancario Istantaneo Nigeria',
        laosInstant: 'Bonifico Bancario Istantaneo del Laos',
        malayInstant: 'Trasferimento Bancario Istantaneo in Malesia',
        indonesiaInstant: 'Trasferimento Bancario Istantaneo in Indonesia',
        philippineInstant: 'Trasferimento Istantaneo di Banca Filippina',
        mobilePay: 'Pagamento Mobile',
        poli: 'Pagamenti POLi',
        paypal: 'Deposito Paypal',
        mijipay: 'Deposito Mijipay',
        crypto: 'Criptovaluta',
        canada: 'Trasferimento Elettronico Interac',
        tinkbit: 'Deposito Tinkbit',
        bitwallet: 'Deposito bitwallet',
        indiaInstant: "Bonifico Bancario Istantaneo dell'India",
        trustly: 'Trustly',
        pagsmileBrazil: 'Trasferimento Bancario Istantaneo in Brasile',
        pagsmileMexico: 'Trasferimento Bancario Istantaneo Messico',
        pagsmileColombia: 'Trasferimento Bancario Istantaneo Colombia',
        pagsmileChile: 'Trasferimento Bancario Istantaneo del Cile',
        pagsmilePeru: 'Trasferimento Bancario Istantaneo del Peru',
        astropay: 'Deposito Astropay',
        vload: 'Vload',
        dasshpeUPI: 'UPI',
        dasshpeWallet: 'Portafogli India',
        ugandabanktransfer: 'Trasferimento bancario locale in Uganda',
        rwandabanktransfer: 'Trasferimento bancario locale in Ruanda',
        zambiabanktransfer: 'Trasferimento bancario locale Zambia',
        congobanktransfer: 'Trasferimento bancario locale del Congo',
        cameroonbanktransfer: 'Trasferimento bancario locale in Camerun',
        burundibanktransfer: 'Trasferimento bancario locale del Burundi',
        kenyabanktransfer: 'Trasferimento bancario locale in Kenya',
        ghanabanktransfer: 'Trasferimento bancario locale in Ghana',
        tanzaniabanktransfer: 'Trasferimento bancario locale in Tanzania',
        advcash: 'Advcash',
        thailandqrpayment: 'Pagamento QR Thailandia',
        vietnamqr: 'QR Vietnam',
        philippinesinstantbanktransfer: 'Trasferimento Bancario Istantaneo nelle Filippine',
        cryptocurrencydeposit: 'Deposito di Criptovaluta',
        indonesiainstantbanktransfer: 'Trasferimento Bancario Istantaneo in Indonesia',
        southkoreabanktransfer: 'Trasferimento Bancario Corea del Sud',
        alipay: 'Alipay',
        thailandinstantbanktransfer: 'Trasferimento bancario istantaneo Thailandia',
      },
      rate: {
        rate: 'Tassi di cambio ({oldCurrency}/{newCurrency}):',
        result: {
          rateError: 'Impossibile recuperare il tasso di cambio da {oldCurrency} a {newCurrency}.',
          channelError: 'Recupero del canale di pagamento non riuscito.',
        },
      },
      cn: { rate: 'Tassi di cambio (USD/RMB):', amt: 'RMB:' },
      viet: {
        result: { rateError: 'Impossibile recuperare il tasso di cambio da USD a VND.' },
        rate: 'Tassi di cambio (USD/VND):',
        amt: 'VND:',
      },
      disclaimer:
        '<li>1. {platform} non accetta assolutamente pagamenti da terze parti. Tutti i fondi depositati nel tuo conto di trading devono essere sotto lo stesso nome del tuo conto di trading {platform}.</li>\n      <li> </br>2. I clienti riconoscono che potrebbe essere richiesto loro di fornire informazioni sulla/e fonte/i del/dei deposito/i, sulla ricchezza generale dei clienti e su qualsiasi altra informazione che {platform} ritiene necessaria per identificare la fonte dei fondi durante l\'elaborazione di una richiesta di deposito e accettano di fornire tutte le informazioni richieste da {platform}. Il mancato fornimento delle informazioni richieste può comportare ritardi significativi nel processo delle vostre richieste. {platform} non sarà responsabile per eventuali ritardi o perdite subite a causa di tale ritardo.</li>\n      <li> </br>3. Il cliente comprende che l\'invio di una richiesta di deposito non costituisce un\'accettazione dei fondi da parte di {platform}. Se il tuo conto è vicino o in una chiamata a margine, assicurati di avere altre disposizioni in atto.</li>\n      <li> </br>4. {platform} non sarà responsabile per eventuali ritardi/errori causati dai sistemi di pagamento.</li>\n      <li> </br>5. {platform} non sarà responsabile per eventuali commissioni (ad es. commissioni di conversione, commissioni per anticipo in contanti, ecc.) che l\'istituto finanziario di invio o i fornitori di pagamento di terze parti potrebbero addebitarti per l\'invio di pagamenti.</li>\n      <li> </br>6. Si prega di notare che la descrizione di {platform} sul tuo sistema di fatturazione apparirà come <a href="https://www.monetamarkets.com/" target="_blank">www.monetamarkets.com</a> - Gladstonos & Filokyprou, 120, House Office E2.</li>"""',
    },
    method: {
      desc: 'Come finanziare il tuo account con {method}',
      inst1: "{number}.   Completa il modulo sottostante e premi 'Invia'",
      inst2:
        '{number}.   Seleziona la banca nella pagina di selezione della banca e accedi al tuo conto bancario online {country}',
      inst3:
        '{number}.   Segui le istruzioni e effettua il pagamento tramite la tua Banca Online/Mobile',
      inst4:
        '{number}.   Verrai reindirizzato nuovamente al portale del cliente e la tua transazione sarà finalizzata.',
      inst5: '{number}.   Accedi al tuo account {method}',
      inst6: '{number}.   Segui le istruzioni nel tuo account {method} per completare il pagamento',
      inst7:
        'Si prega di notare: se si sceglie di effettuare un deposito manuale, i vostri fondi potrebbero subire ritardi e richiedere un notevole quantitativo di tempo per essere accreditati sul vostro conto di trading.',
      inst8:
        "{number}.   Si prega di notare che se si utilizza {payment}, l'importo del deposito è limitato a soli {currency} {amount} per transazione",
      inst9: '{number}.   Inserisci il tuo ID UPI/VPA o scansiona il codice QR',
      inst10:
        '{number}.   I depositi Fasapay sono limitati a $25.000 USD per transazione e un massimo di $75.000 USD al giorno',
      ins11: '{number}. Scansiona il codice QR',
      form: { header: '{method} Modulo' },
      choose: 'Si prega di scegliere il {metodo} preferito',
      inst12: "{number}.   Completa il modulo qui sotto e seleziona 'Invia'",
      inst13: "{number}.   Scansiona il codice QR per aprire l'applicazione di mobile banking",
      inst14:
        '{number}.   Segui le istruzioni sul tuo dispositivo mobile per completare il tuo deposito',
      inst15: '{number}.   Compila il modulo sottostante e premi ‘Invia’',
      inst16: "{number}.   Dopo aver inserito i dettagli della tua carta, premi 'Invia'",
    },
    time: { instant: '24/7', onehour: '1 Ora' },
    cpsInfo: {
      usdttrc20: 'USDT-TRC20',
      eth: 'ETH',
      usdcerc20: 'USDC-ERC20',
      bitcoin: 'BTC',
      usdterc20: 'USDT-ERC20',
      thispaymentchannelistemporaryunavailable:
        'Questo canale di pagamento è temporaneamente non disponibile.',
    },
    uk: { header: 'Trasferimento Bancario Istantaneo UK', country: 'Regno Unito' },
    southAfrica: { header: 'Bonifico Bancario Istantaneo del Sud Africa', country: 'Sudafrica' },
    idn: { header: 'Trasferimento Bancario Istantaneo in Indonesia', country: 'Indonesia' },
    ida: { header: 'Trasferimento Bancario Istantaneo in India', country: 'India' },
    brazil: { country: 'Brasile', header: 'Brasile' },
    nigeria: { header: 'Bonifico Bancario Istantaneo Nigeria', country: 'Nigeria' },
    thailand: { header: 'Bonifico Bancario Istantaneo Thailandia', country: 'Tailandia' },
    vietnam: { header: 'Bonifico Bancario Istantaneo Vietnam', country: 'Vietnam' },
    malaysia: { header: 'Bonifico Bancario Istantaneo in Malesia', country: 'Malesia' },
    indonesia: { header: 'Bonifico Bancario Istantaneo in Indonesia', country: 'Indonesia' },
    india: { header: 'Trasferimento Bancario Istantaneo in India', country: 'India' },
    mexico: { header: 'Messico', country: 'Messico' },
    colombia: { header: 'Colombia', country: 'Colombia' },
    chile: { header: 'Cile', country: 'Cile' },
    peru: { header: 'Perù', country: 'Perù' },
    country: '{country} Trasferimento Bancario Istantaneo',
    laos: {
      header: 'Bonifico Bancario Istantaneo del Laos',
      country: 'Laos',
      inst: '2. Scansiona il codice QR con la tua app OnePay',
    },
    hongkong: {
      country: 'Hong Kong',
      inst1: '2. Segui le istruzioni e effettua il pagamento',
      QRinst1:
        '2.   Scansiona il codice QR utilizzando la tua applicazione bancaria e effettua il pagamento',
      inst2: '3. La tua transazione sarà finalizzata',
      inst3: 'Si prega di seguire le istruzioni sottostanti per effettuare il pagamento:',
      inst4: '1. Per favore accedi al tuo conto bancario.',
      inst5: '2. Trasferisci fondi a:',
      inst6:
        '3. Dopo aver completato il pagamento, si prega di fare clic sul pulsante FINE per procedere.',
      inst7: "L'account è valido solo per un deposito una tantum",
      inst8: 'Avviso:',
      inst9:
        "Questo numero di conto generato è valido solo per un deposito una tantum e non dovrebbe essere utilizzato per transazioni future al fine di evitare ritardi nell'arrivo dei fondi.",
      inst10:
        "L'importo del pagamento trasferito deve corrispondere all'importo presentato nella richiesta di deposito.",
      inst11:
        'Si prega di assicurarsi di effettuare il trasferimento bancario entro 1 ora dopo aver inviato la richiesta di deposito per evitare un deposito non riuscito.',
    },
    malay: { header: 'Trasferimento Bancario Istantaneo in Malesia', country: 'Malesia' },
    php: { header: 'Trasferimento Istantaneo di Banca Filippina', country: 'Filippina' },
    uganda: { header: 'Trasferimento bancario locale in Uganda', country: 'Uganda' },
    rwanda: { header: 'Trasferimento bancario locale in Ruanda', country: 'Ruanda' },
    zambia: { header: 'Trasferimento bancario locale Zambia', country: 'Zambia' },
    congo: { header: 'Trasferimento bancario locale del Congo', country: 'Congo' },
    cameroon: { header: 'Trasferimento bancario locale in Camerun', country: 'Camerun' },
    burundi: { header: 'Trasferimento bancario locale del Burundi', country: 'Burundi' },
    kenya: { header: 'Trasferimento bancario locale in Kenya', country: 'Kenya' },
    ghana: { header: 'Trasferimento bancario locale in Ghana', country: 'Ghana' },
    tanzania: { header: 'Trasferimento bancario locale in Tanzania', country: 'Tanzania' },
    bankWireAu: {
      header: 'BONIFICO BANCARIO: AUSTRALIA',
      instruction:
        "I fondi possono essere trasferiti a {platform} tramite l'infrastruttura bancaria online della tua istituzione finanziaria, o alternativamente contattando la tua istituzione finanziaria e richiedendo il trasferimento a {platform}.",
      tip: 'Seleziona la valuta del conto in cui desideri effettuare il deposito. Assicurati di selezionare la stessa valuta del tuo conto di trading.',
      form: {
        refTip: 'Si prega di specificare qui il vostro numero di accesso',
        afp: 'Pagamento Veloce Australiano (Deposito Domestico AUD SOLAMENTE)',
        payIdOrABNNum: 'Numero PayID/ABN',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip:
          'Si prega di includere il numero del tuo conto di trading {platform} nel campo di riferimento del bonifico bancario.',
        bankDetails: 'Dettagli Bancari (Solo Deposito AUD)',
      },
    },
    bPay: {
      header: 'DEPOSITO BPAY',
      option: 'Come finanziare il tuo account con BPay (solo Australia)',
      inst1:
        '1. Seleziona il tuo account MT4/MT5 per visualizzare il tuo Codice Biller BPay e il Numero di Riferimento BPay',
      inst2:
        '2. Inserisci i dettagli del pagamento, il tuo Codice Biller BPay e il Numero di Riferimento BPay nel tuo servizio di internet banking e invia il tuo pagamento',
      inst3:
        '3.   Torna a questa pagina e completa il modulo BPay qui sotto per garantire che possiamo elaborare prontamente la tua transazione',
      form: {
        header: 'MODULO BPAY',
        billerCode: 'Codice Biller BPay',
        refNum: 'Numero di Riferimento BPay',
        receipt: 'Ricevuta di trasferimento BPAY',
        reminderNotUploaded:
          'Abbiamo notato che non hai caricato la ricevuta della tua transazione. Per poter elaborare il tuo deposito, ti preghiamo di inviare una copia della ricevuta a {mailTo} al più presto possibile.',
        reminderProc:
          'Si prega di notare. La tua transazione NON può essere elaborata fino a quando non riceviamo la tua ricevuta.',
      },
    },
    b2b: {
      header: 'DA BROKER A BROKER',
      option: 'Come trasferire fondi da un altro broker a {platform}',
      inst1: '1. Scarica e completa il {download}',
      inst2:
        '2.   Completa il modulo online sottostante e carica il tuo modulo di trasferimento da Broker a Broker completato',
      inst3:
        '3. Invia una copia del tuo modulo Broker a Broker completato al tuo precedente broker per avviare il trasferimento',
      b2bForm: 'Modulo di Trasferimento da Broker a Broker',
      formValidation: { upload: 'È richiesto il modulo da Broker a Broker' },
    },
    cc: {
      header: 'DEPOSITO CON CARTA DI CREDITO O DEBITO',
      remind:
        'Se riscontri difficoltà nel finanziare il tuo account utilizzando il metodo Primario, ti preghiamo di utilizzare il metodo Secondario di Credito/Debito',
      field: {
        fundNow: 'Finanzia Ora con Carta di Credito / Debito',
        newCard: 'Nuova Carta',
        cardNum: 'Numero della Carta di Credito',
        nameOnCard: 'Nome sulla carta',
        expDate: 'Data di Scadenza',
        cvv: 'Codice di Sicurezza CVV',
        bankCode: 'Banca Emittente della Carta',
      },
      placeholder: {
        bankCode: 'Si prega di inserire il nome della banca emittente della tua carta',
      },
      cvvTip: 'I 3 numeri visualizzati sul retro della tua carta',
      threeDomainSecQ: 'La tua carta supporta 3D-Secure?',
      threeDomainSec:
        "Cos'è l'autenticazione 3D Secure? <br /><strong>L'autenticazione 3D Secure è un metodo di prevenzione delle frodi riconosciuto da Visa e Mastercard.<br /><br />Se la tua carta supporta 3D Secure, i tuoi fondi verranno elaborati immediatamente.</strong>",
      cardUpload:
        'La carta che stai utilizzando per depositare fondi non è stata collegata al tuo conto di trading. Per favore carica una foto del fronte della tua carta per la verifica. (Si prega di coprire le 6 cifre centrali)',
      result: {
        success:
          'Grazie. Stiamo attualmente verificando i dettagli della tua carta, e una volta verificati, i fondi dovrebbero apparire nel tuo account entro 24 ore.',
        depositSuccess: 'Grazie per il tuo deposito, i tuoi fondi appariranno presto nel tuo conto',
        error:
          'Al fine di proteggere la sicurezza del tuo account,<br> hai raggiunto il massimo tentativo di transazioni di deposito e abbiamo temporaneamente sospeso i tuoi depositi.<br> Si prega di contattare {email}',
      },
      mastercardonly:
        'Si prega di continuare a utilizzare questo canale di pagamento solo con Mastercard.',
    },
    fasa: {
      header: 'DEPOSITO FASAPAY',
      description: 'Come finanziare il tuo account con FasaPay',
      inst1: "1. Completa il modulo sottostante e premi 'Invia'",
      inst2: '2. Accedi al tuo account FasaPay',
      inst3: '3. Segui le istruzioni nel tuo account FasaPay per completare il tuo pagamento',
      inst4:
        '4. I depositi Fasapay sono limitati a $25,000 USD per transazione e un massimo di $75,000 USD al giorno',
      form: { header: 'MODULO FASAPAY' },
    },
    advcash: {
      description: 'Come finanziare il tuo account con FasaPay',
      inst1: "1. Completa il modulo sottostante e premi 'Invia'",
      inst2: '2. Accedi al tuo account FasaPay',
      inst3: '3. Segui le istruzioni nel tuo account FasaPay per completare il tuo pagamento',
      inst4:
        '4. I depositi Fasapay sono limitati a $25.000 USD per transazione e un massimo di $75.000 USD al giorno',
      form: { header: 'MODULO ADVCASH' },
    },
    indiaBankTransfer: {
      desc: 'Si prega di assicurarsi che mostri chiaramente il tuo nome completo, numero di conto e importo del deposito in modo che possiamo elaborare la tua transazione.',
      bussDayInfo:
        'Si prega di consentire da 1 a 6 ore perché i fondi vengano accreditati sul tuo conto.',
    },
    miji: {
      header: 'Bonifico Bancario Istantaneo Thailandia',
      description: 'Come finanziare il tuo conto con il bonifico bancario istantaneo thailandese',
      inst1: "1. Completa il modulo sottostante e premi 'Invia'",
      inst2: '2. Verrai quindi reindirizzato alla pagina di pagamento',
      inst3:
        '3. Accedi alla tua app di mobile banking e scansiona il codice QR sulla pagina di pagamento',
      form: { header: 'Modulo di Trasferimento Bancario Istantaneo Thailandia' },
      inst4:
        "Si prega di notare: <b>Il sistema aggiungerà alcuni centesimi all'importo richiesto.</b>",
    },
    intSwift: {
      header: 'BONIFICO BANCARIO: SWIFT INTERNAZIONALE',
      desc: "I fondi possono essere trasferiti a {platform} tramite l'infrastruttura bancaria online della tua istituzione finanziaria, o alternativamente contattando la tua istituzione finanziaria e richiedendo il trasferimento a {platform}.",
      desc2:
        'Una volta effettuato il trasferimento, si prega di compilare il modulo sottostante e allegare una copia della ricevuta del bonifico bancario che mostra chiaramente il tuo nome completo, numero di conto e importo del deposito, così possiamo elaborare la tua transazione.',
      options: 'Si prega di selezionare il conto di trading che si desidera finanziare',
      refInfo:
        'Si prega di includere il numero del tuo conto di trading {platform} nel campo di riferimento del bonifico bancario.',
      refInfo2: 'Trasferimento bancario solo tramite IMPS e RTGS! Nessuna transazione UPI.',
      bussDayInfo:
        'Si prega di consentire da 2 a 5 giorni lavorativi perché i fondi vengano accreditati sul tuo conto.',
      tip: 'Si prega di assicurarsi che la ricevuta del trasferimento mostri chiaramente il tuo <b>nome completo</b>, <b>numero di conto</b> e <b>importo del deposito</b>.',
    },
    mobile: {
      header: 'PAGAMENTO MOBILE',
      desc: 'Come finanziare il tuo account con Mobile Pay',
      inst1: "1. Completa il modulo sottostante e premi 'Invia'",
      inst2: '2.   Scansiona il codice QR per aprire la tua applicazione di mobile banking',
      inst3: '3. Segui le istruzioni sul tuo dispositivo mobile per completare il tuo deposito',
      form: { payMethod: 'Metodo di pagamento:', rate: 'Tasso di cambio (USD/CNY):', amt: 'CNY: ' },
      formValidation: { cnyAmt: "L'importo in CNY non può superare $ {limite}" },
    },
    net: {
      header: 'Neteller',
      inst2: '2. Accedi al tuo account Neteller',
      inst3: '3. Segui le istruzioni nel tuo account Neteller per completare il tuo pagamento',
      inst4: {
        fca: '4. Possiamo accettare pagamenti in USD, EUR e GBP',
        default: '4. Possiamo accettare pagamenti in AUD, USD, EUR, GBP e SGD',
      },
    },
    canada: {
      header: 'Trasferimento Elettronico Interac',
      desc: 'Come finanziare il tuo conto con Interac E-Transfer',
      inst1: "1. Completa il modulo sottostante e premi 'Invia'",
      inst2:
        '2. Seleziona la banca nella pagina di selezione della banca e accedi al tuo conto bancario online',
      inst3: '3. Segui le istruzioni e effettua il pagamento tramite la tua Banca Online/Mobile',
      inst4: '4. Possiamo accettare pagamenti in CAD. Massimo $9,999 CAD per deposito',
      form: { header: 'Modulo di Trasferimento Elettronico Interac' },
    },
    bitwallet: {
      header: 'DEPOSITO BITWALLET',
      desc: 'Come finanziare il tuo account con BITWALLET',
      inst1: "1. Completa il modulo sottostante e premi 'Invia'",
      inst2: '2. Accedi al tuo account Bitwallet',
      inst3: '3. Segui le istruzioni nel tuo account Bitwallet per completare il pagamento',
      inst4: '4. Possiamo accettare pagamenti in USD, EUR e JPY',
      form: { header: 'MODULO BITWALLET' },
    },
    astropay: {
      header: 'DEPOSITO ASTROPAY',
      desc: 'Come finanziare il tuo account con ASTROPAY',
      inst2: '2. Accedi al tuo account ASTROPAY',
      inst3: '3. Segui le istruzioni nel tuo account ASTROPAY per completare il tuo pagamento',
      inst4: '4. Possiamo accettare pagamenti in tutte le valute.',
      form: { header: 'MODULO ASTROPAY' },
    },
    crypto: {
      choose: 'Si prega di scegliere la vostra criptovaluta preferita',
      proceedQuestion: 'Desideri procedere con il tuo deposito <br /><b>{metodo}</b>?',
      remark:
        "Si prega di notare che non siamo in grado di depositare o prelevare tramite BUSDT, assicurati che l'indirizzo e la criptovaluta corrispondano alla catena e alla valuta che accettiamo o potresti perdere il fondo.",
      inst2:
        '2.    Verrai quindi indirizzato a una nuova finestra che mostra un indirizzo del portafoglio {method}',
      inst3:
        "3.    Copia l'indirizzo del portafoglio {method} e poi accedi al tuo portafoglio personale {method} e trasferisci l'importo desiderato all'indirizzo {method}.",
      inst4:
        'Si prega di notare: i depositi BITCOIN sono processati da B2BinPay al tasso di cambio fornito al momento della transazione.',
    },
    poli: {
      header: 'DEPOSITO POLI',
      desc: 'Come finanziare il tuo account con Poli',
      reminder:
        '<li>Si prega di notare:</li><li>* Il deposito deve essere intestato allo stesso nome del tuo account {platform}</li><li>* I depositi devono essere solo in AUD</li>',
      form: { header: 'FORMA POLI' },
    },
    skrill: {
      header: 'DEPOSITO SKRILL/MONEYBOOKERS',
      desc: 'Come finanziare il tuo account con Skrill',
      inst1: "1. Completa il modulo sottostante e premi 'Invia'",
      inst2: '2. Accedi al tuo account Skrill',
      inst3: '3. Segui le istruzioni nel tuo account Skrill per completare il tuo pagamento',
      inst4: '4. Possiamo accettare pagamenti in USD, EUR, GBP e CAD',
      inst4FCA: '4. Possiamo accettare pagamenti in USD, EUR e GBP',
      form: { header: 'MODULO SKRILL', email: 'Email Skrill/Moneybookers' },
      reminder:
        "<li>Si prega di notare:</li>\n        <li>* Gli account possono essere finanziati solo nella stessa valuta del tuo account di trading MT4. Skrill convertirà automaticamente i fondi nella valuta base del tuo account se viene utilizzata un'email errata. Potrebbero essere applicate delle commissioni.</li>\n        <li>* I depositi devono essere intestati allo stesso nome del tuo account {piattaforma}.</li>",
    },
    sticPay: {
      inst2: '2. Accedi al tuo account SticPay',
      inst3: '3. Segui le istruzioni nel tuo account SticPay per completare il pagamento',
    },
    webMoney: {
      header: 'WEBMONEY',
      inst4: '4. Possiamo accettare pagamenti in EUR e USD',
      inst5: '5. I depositi WebMoney sono limitati a 1300 USD/1100 EUR per transazione',
    },
    vload: {
      header: 'VLOAD',
      inst1: '2. Possiamo accettare pagamenti in USD, EUR e JPY.',
      form: { voucherpin: 'PIN del voucher', voucherpinReq: 'È richiesto il PIN del voucher' },
      inst2:
        '<li>3.   Acquista il voucher Vload, clicca <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">qui</a>.</li>',
    },
    dasshpe: {
      headerUPI: 'UPI',
      headerWallet: 'Portafogli India',
      inst1: '2. Approva la transazione dalla tua app UPI',
      inst2: '2.   Segui le istruzioni e effettua il pagamento tramite la tua app portafoglio',
    },
    thaiPaytoday: {
      header: 'Bonifico Bancario Istantaneo Thailandia',
      desc: 'Come finanziare il tuo conto con il bonifico bancario istantaneo della Thailandia',
      inst1: "1. Completa il modulo sottostante e premi 'Invia'",
      inst2: '2. Segui le istruzioni per completare il tuo pagamento',
      form: { header: 'Modulo di Trasferimento Bancario Istantaneo Thailandia' },
      scan: 'Scansiona per effettuare un deposito',
      exp: 'Scade tra {expireTime}',
      reminder:
        "I fondi dovrebbero apparire nel tuo account entro 1 ora. <br/><br/>\nPuoi visualizzare lo stato attuale del tuo deposito nella tua 'cronologia delle transazioni'. <br/>\nSe dovessi riscontrare problemi con il tuo deposito, ti preghiamo di contattare il tuo Responsabile dell'Account.",
    },
    unionPay: {
      header: 'DEPOSITO UNION PAY',
      desc: 'Come finanziare il tuo conto con UnionPay',
      inst1: "1. Completa il modulo sottostante e premi 'Invia'",
      inst2:
        '2. Seleziona la banca nella pagina di selezione della banca e accedi al tuo conto bancario online',
      inst3: '3. Segui le istruzioni e effettua il pagamento tramite la tua Banca Online/Mobile',
      form: { header: 'MODULO DI PAGAMENTO UNIONE' },
    },
    unionPayCN: {
      header: 'Trasferimento China Union Pay',
      desc: 'Come finanziare il tuo conto con il trasferimento China Union Pay',
      inst1: "1. Completa il modulo sottostante e premi 'Invia'",
      inst2: '2.   Una pagina con i dettagli del pagamento apparirà',
      inst3: '3. Segui le istruzioni e effettua il pagamento tramite la tua Banca Online/Mobile',
      inst4:
        'Si prega di notare: Potrebbe essere richiesto il numero di socio durante il deposito, si prega di inserire il numero del conto di trading come numero di socio.',
      form: {
        header: 'MODULO DI PAGAMENTO UNIONE CINA',
        holderName: 'Nome del Titolare della Carta UnionPay:',
        holderNameInfo:
          'Si prega di assicurarsi che il nome inserito sopra sia lo stesso del titolare della carta bancaria',
      },
    },
    vietZota: {
      header: 'Bonifico Bancario Istantaneo Vietnam',
      desc: 'Come finanziare il tuo conto con il bonifico bancario istantaneo del Vietnam',
      inst: "<li>1.   Completa il modulo sottostante e premi 'Invia'</li><li>2.   Seleziona la banca nella pagina di selezione della banca e accedi alla tua banca online in Vietnam</li><li>3.   Segui le istruzioni e effettua il pagamento tramite la tua Banca Online/Mobile</li><li>4.   Sarai reindirizzato nuovamente al portale del cliente e la tua transazione sarà finalizzata</li>",
      form: { header: 'Modulo di bonifico bancario istantaneo Vietnam' },
    },
    paypal: {
      header: 'PayPal',
      desc: 'Come finanziare il tuo account con PayPal',
      inst1: "1. Completa il modulo sottostante e premi 'Invia'",
      inst2: '2. Accedi al tuo account PayPal',
      inst3: '3. Segui le istruzioni e nel tuo account PayPal per completare il pagamento',
      inst4: 'Si prega di notare: Non possiamo accettare pagamenti da un terzo.',
      form: { header: 'MODULO PAYPAL' },
    },
    cardpay: {
      desc: 'Come finanziare il tuo conto con Carta di Credito',
      inst1: "1. Completa il modulo sottostante e premi 'Invia'",
      inst2:
        '2. Verrai quindi reindirizzato ad un altro modulo per inserire i dettagli della tua carta',
      inst3: "3. Dopo aver inserito i dettagli della tua carta, premi 'Invia'",
      note: 'la descrizione, si prega di contattare <a href="',
      form: { header: 'MODULO CARTA DI CREDITO' },
    },
    tinkbit: { inst5: '5. Massimo $1500 USD per deposito' },
    trustly: {
      header: 'Trustly',
      desc: 'Come finanziare il tuo conto con Trustly',
      inst1: "1. Completa il modulo sottostante e premi 'Invia'",
      inst2: '2. Accedi al tuo account Trustly',
      inst3: '3. Segui le istruzioni nel tuo account Trustly per completare il pagamento',
      inst4: '4. Possiamo accettare pagamenti in EUR e GBP',
      form: { header: 'MODULO TRUSTLY' },
      reminder:
        "<li>Si prega di notare:</li>\n        <li>* Gli account possono essere finanziati solo nella stessa valuta del tuo account di trading MT4. Trustly convertirà automaticamente i fondi nella valuta base del tuo account se viene utilizzata un'email errata. Potrebbero essere applicate delle commissioni.</li>\n        <li>* I depositi devono essere intestati allo stesso nome del tuo account {piattaforma}.</li>",
    },
    cps: '{name}',
  },
  restrictDeposit: {
    header: 'NOTIFICA IMPORTANTE',
    info: 'Sfortunatamente non accettiamo più depositi da clienti residenti in Canada.',
  },
  withdraw: {
    default: {
      selectCC: 'Seleziona Carta di Credito',
      selectBA: 'Seleziona Conto Bancario',
      anotherCC: 'Inserisci manualmente la carta di credito',
      anotherBA: 'Aggiungi conto bancario',
      remember: 'Ricorda il mio account',
      success:
        'La tua richiesta di prelievo è stata eseguita con successo e verrà elaborata a breve',
      ccFail:
        'Per proteggere la sicurezza del tuo account, hai raggiunto il massimo tentativo di transazioni di deposito e abbiamo temporaneamente sospeso i tuoi prelievi. Si prega di contattare {email}.',
      fetchTradeAccFailed: 'Recupero del conto di trading fallito',
      failed: 'Prelievo fallito. Si prega di riprovare più tardi.',
      notEnoughFunds: "L'importo inserito supera il limite per questa carta",
      cardExpird:
        "Questa carta è scaduta. Si prega di scegliere un'altra carta o un altro metodo di pagamento.",
      minAmountWarn:
        'L\'importo di prelievo applicato è inferiore al minimo per il gateway di pagamento applicato. Si prega di ricaricare il saldo del tuo account al/di sopra del minimo prima di inviare una richiesta di prelievo. Maggiori informazioni possono essere trovate <a href="https://{regulatorUrl}/clients/accounts/withdraw/">qui</a>.',
      noCardWarn:
        'Non hai alcuna carta disponibile per effettuare prelievi, per favore collega la tua carta nei Dettagli Prelievo prima.',
      equityStop:
        'Sfortunatamente, il tuo prelievo non può essere elaborato perché comporterebbe una riduzione del margine disponibile del tuo conto al di sotto del livello di stop out del 100%',
      equityMargin:
        'Sfortunatamente al momento non siamo in grado di elaborare il tuo prelievo perché il tuo conto è completamente coperto.',
      equityContinue:
        'Abbiamo rilevato che attualmente hai posizioni aperte, potresti riscontrare interruzioni dopo aver inviato la richiesta di prelievo, si prega di confermare.',
      YourPaymentGreaterThanAvailableBalance: 'Il tuo pagamento è superiore al saldo disponibile',
      NetellerChargeAlert:
        'I prelievi Neteller sono limitati a $500.000 per transazione, e si applica una commissione del 2% (con un massimo di $30).',
      FasaPayChargeAlert:
        'FasaPay addebita una commissione di transazione dello 0,5% per ogni prelievo.',
      SkrillChargeAlert:
        "Skrill addebita una commissione di transazione dell'1% per ogni prelievo.",
      VerificationCode: 'Inserisci il codice di verifica',
      SendCode: 'Invia Codice',
      disclaimer:
        'This response was truncated by the cut-off limit (max tokens). Open the sidebar, Increase the parameter in the settings and then regenerate.\n-------------------------\n"""\n<p>1. A causa delle leggi AML / CTF, {platform} non può depositare fondi a terzi. Tutti i soldi prelevati dal tuo account devono andare a un conto bancario o Moneybookers con lo stesso nome del tuo conto di trading {platform}.</p>\n      <p>2. {platform} non accetta depositi o prelievi effettuati con un nome diverso da quello del conto di trading registrato.</p>\n      <p>3. È responsabilità del cliente assicurarsi che ci siano fondi sufficienti disponibili per l\'elaborazione della richiesta di prelievo. Il mancato mantenimento di un saldo sufficiente nel conto può comportare il rifiuto della richiesta di prelievo.</p>\n      <p>4. Tutte le richieste iniziali di prelievo tramite carta di credito/debito e bonifico bancario devono essere verificate per motivi di sicurezza attraverso la fornitura di un estratto conto; che include informazioni sul titolare del conto e dettagli bancari.</p>\n      <p>5. Tutte le richieste iniziali di prelievo tramite Moneybookers e E-wallet (Skrill, Neteller, Fasapay, ecc.) devono essere verificate per motivi di sicurezza attraverso la fornitura di un estratto conto; che include informazioni sul titolare del conto e dettagli bancari.</p>\n      <p>6. I clienti riconoscono che potrebbero essere richieste informazioni sulla/e fonte/i del/dei deposito/i e/o sulla ricchezza dei clienti in generale durante l\'elaborazione di una richiesta di prelievo e accettano di fornire tutte le informazioni richieste da {platform}. Il mancato fornimento delle informazioni richieste può comportare ritardi significativi nell\'elaborazione delle vostre richieste.</p>\n      <p>7. {platform} si riserva il diritto di rifiutare di elaborare una richiesta di prelievo se ha motivi ragionevoli per riten',
      blackList:
        'Sfortunatamente al momento non sei in grado di inviare una richiesta di prelievo. Si prega di contattare {supportEmail} per ulteriori informazioni',
      localBankTransfer: 'Bonifico dalla banca locale',
    },
    fasa: {
      field: { accName: 'Nome Account FasaPay', accNum: 'Numero di Conto FasaPay' },
      formValidation: {
        accNameReq: "È richiesto il nome dell'account FasaPay",
        accNumReq: 'È richiesto il numero di conto FasaPay',
      },
    },
    adv: {
      field: { accName: 'Nome Account ADVcash', accNum: 'Numero di conto/Email ADVcash' },
      formValidation: {
        accNameReq: "È richiesto il nome dell'account ADVcash",
        accNumReq: 'È richiesto il numero di conto ADVcash',
      },
    },
    neteller: {
      field: { email: 'Email Neteller' },
      formValidation: { emailReq: "È richiesta l'email di Neteller" },
    },
    bitcoin: {
      field: { address: 'Indirizzo del Portafoglio Bitcoin' },
      formValidation: {
        addressReq: "È richiesto l'indirizzo del portafoglio Bitcoin",
        addressFormat: "Formato dell'indirizzo del portafoglio Bitcoin non corretto",
      },
    },
    eth: {
      field: { address: 'Indirizzo del portafoglio ETH' },
      formValidation: {
        addressReq: "È richiesto l'indirizzo del portafoglio ETH",
        addressFormat: "Formato dell'indirizzo del portafoglio ETH non corretto",
      },
    },
    usdc: {
      field: { address: 'Indirizzo del portafoglio USDC', chain: 'Catena' },
      formValidation: {
        addressReq: "È richiesto l'indirizzo del portafoglio USDC",
        chain: 'È richiesta una catena',
        walletFormatIncorrect: "Formato dell'indirizzo del portafoglio {type} non corretto",
      },
      note: "La commissione attuale per il gas di ERC20 è significativamente più alta del solito e di conseguenza potrebbe esserci un notevole ritardo nell'elaborazione della tua transazione. In alternativa, sei in grado di prelevare fondi tramite TRC20 fornendo l'indirizzo del portafoglio corrispondente, oppure puoi scegliere di pagare la commissione per il gas per accelerare il tuo prelievo ERC20.",
    },
    usdt: {
      field: { address: 'Indirizzo del portafoglio USDT', chain: 'Catena' },
      formValidation: {
        addressReq: "È richiesto l'indirizzo del portafoglio USDT",
        chain: 'È richiesta una catena',
        walletFormatIncorrect: "Formato dell'indirizzo del portafoglio {type} non corretto",
      },
      note: "La commissione attuale per il gas di ERC20 è significativamente più alta del solito e di conseguenza potrebbe esserci un notevole ritardo nell'elaborazione della tua transazione. In alternativa, sei in grado di prelevare fondi tramite TRC20 fornendo l'indirizzo del portafoglio corrispondente, oppure puoi scegliere di pagare la commissione per il gas per accelerare il tuo prelievo ERC20.",
    },
    paypal: {
      field: { email: 'Email Paypal' },
      formValidation: { emailReq: "È richiesta l'email di Paypal" },
    },
    skrill: {
      field: { email: 'Email Skrill' },
      formValidation: { emailReq: "È richiesta l'email Skirll" },
    },
    webMoney: {
      field: { email: 'Email WebMoney' },
      formValidation: { emailReq: "È richiesta l'email di WebMoney" },
    },
    vload: {
      field: { email: 'Email Registrata Vload' },
      formValidation: { emailReq: "È richiesta l'e-mail registrata su Vload" },
    },
    sticpay: {
      field: { email: 'Email SticPay' },
      formValidation: { emailReq: "È richiesta l'email di SticPay" },
    },
    bitwallet: {
      field: { email: 'Email Bitwallet' },
      formValidation: { emailReq: "È richiesta l'email di Bitwallet" },
    },
    astropay: {
      field: { accountNumber: 'Account Astropay' },
      formValidation: { accountNumberReq: 'È richiesto un account Astropay' },
    },
    transfer: {
      info: '{platform} rimborsa la commissione bancaria addebitata per <u>UN</u> prelievo al mese. Prelievi aggiuntivi nel corso del mese comporteranno una commissione bancaria di 20 unità della tua valuta di prelievo.',
      note: 'Per poter elaborare la tua richiesta di prelievo, fornisci il tuo passaporto, per gli account aziendali, fornisci la fattura a {email}',
      label: {
        upload:
          'Si prega di caricare una copia del tuo estratto conto bancario (aggiornato agli ultimi 3 mesi)',
        upload2:
          "Si prega di assicurarsi che la data del estratto conto o l'ultima data di transazione sia del mese corrente o degli ultimi tre mesi. Se supera questo intervallo di tempo, si prega gentilmente di caricare nuovamente l'estratto conto.",
      },
      formValidation: { upload: 'Si prega di caricare un estratto conto bancario' },
    },
    ccMethod: {
      desc1:
        'A causa della conformità AML/CTF, i fondi devono essere trasferiti sulla carta utilizzata per il tuo deposito iniziale',
      desc2:
        'Se la carta utilizzata per finanziare il tuo account non è disponibile, si prega di contattare {mailto}',
    },
    otherMethod: {
      verificationCode:
        'Il codice di verifica è stato inviato a {email}. Si prega di contattare {supportEmail} se avete bisogno di ulteriore assistenza.',
      otherType: 'Altri Tipi',
      withdrawalMethod: 'Metodo di Prelievo',
      desc1:
        "Si prega di notare che i trasferimenti bancari possono comportare una commissione. Assicurati che l'importo del prelievo sia sufficiente a coprire eventuali commissioni che potrebbero essere addebitate.",
      desc2:
        'A causa della conformità AML/CTF, tutti i prelievi devono essere trasferiti utilizzando lo stesso metodo utilizzato per finanziare il tuo account.',
      note: "A causa di recenti modifiche, la nostra banca potrebbe non essere in grado di elaborare bonifici bancari internazionali per clienti registrati in alcuni paesi.<br/>Se devi prelevare tramite bonifici bancari internazionali, ti preghiamo di contattare il nostro Servizio Clienti al +44 2080 363 883 o all'indirizzo <a href='mailto:{mail}'>{mail}</a>.<br/>\n      Ci scusiamo per gli inconvenienti.",
      note2:
        "A causa dell'impatto della politica sul Travel Rule della Corea del Sud, non accetteremo prelievi da indirizzi di scambi coreani che superano i 735 USDT (circa 1.000.000 KRW) per transazione o da clienti il cui indirizzo non ha superato la verifica KYC. |",
    },
  },
  transfer: {
    creditWarn:
      'Non siamo in grado di elaborare il trasferimento dei fondi dal conto selezionato a causa del credito promozionale presente nel tuo conto.',
    blackListWarn:
      'Il trasferimento di fondi dal tuo conto MAM può comportare la liquidazione delle posizioni gestite. Si prega di contattare {mailTo} se si desidera trasferire fondi da questo conto.',
  },
  changePw: {
    header: 'CAMBIA PASSWORD',
    field: {
      currPw: 'Password attuale',
      newPw: 'Nuova Password',
      confirmPw: 'Conferma Nuova Password',
    },
    succ: 'La tua password è stata aggiornata. Ora puoi utilizzare la tua nuova password per accedere al Portale Clienti.',
  },
  security: {
    confirm: 'Conferma',
    cancel: 'Annulla',
    understand: 'Capisco',
    passwordRenewalReminder: {
      header: 'Cambia la Password di Accesso',
      desc: 'Per garantire la sicurezza dei fondi nel tuo account, i prelievi non saranno supportati per T+{giorni} giorni dopo la modifica della tua password di accesso.',
    },
    withdrawalFreeze: {
      header: 'I prelievi non sono temporaneamente supportati',
      userLoginDesc:
        "A causa della modifica della tua email/numero di telefono per l'accesso, i prelievi non sono temporaneamente supportati per T+{days} giorni. Si prevede che questa restrizione sarà rimossa tra {hours} ore.",
      passDesc:
        'A causa della modifica della tua password di accesso, i prelievi non sono temporaneamente supportati per T+{days} giorni per garantire la sicurezza del tuo account. Si prevede che questa restrizione sarà rimossa tra {hours} ore.',
      limitSecurityAuthenticator:
        "A causa della modifica dell'applicazione di Autenticazione di Sicurezza, i prelievi non sono temporaneamente supportati per T+{days} giorni per garantire la sicurezza del tuo account. Questa restrizione è prevista essere rimossa in {hours} ore.",
    },
    securityManagement: 'Gestione della sicurezza',
    authenticationMethod: 'Metodo di autenticazione',
    isVerified: 'Verificato',
    isNotVerified: 'Non verificato',
    password: 'Password',
    securityAuthenticatorApp: 'Applicazione autenticatore di sicurezza',
    securityAuthentication: 'Autenticazione di sicurezza',
    enableLoginAuthentication: "Abilita l'autenticazione del login",
    loginLocationChange: 'Notifica via email del cambio di posizione di accesso',
    lastUpdate: 'Ultimo aggiornamento',
    modify: 'Modifica',
    verify: 'Verificare',
    enable: 'Abilita',
    resend: 'Rispedisci',
    enterVerificationCode: 'Inserisci il codice di verifica',
    verifyPreEmailNote: 'Il codice verrà inviato a {email}',
    verifyPostEmailNote: 'Codice inviato a {email}',
    preEmailCode: 'Il codice verrà inviato al tuo nuovo indirizzo email',
    authenticatorTooltip:
      "Il codice di verifica dell'app Authenticator si aggiorna ogni 30 secondi",
    contactSupport:
      'Impossibile accedere al metodo di autenticazione? Contattare {contact_email} o Livechat',
    changeEmail: "Cambia l'indirizzo email",
    emailTitle: 'Inserisci il nuovo indirizzo email',
    editAuthenticator: 'Modifica dispositivo di autenticazione',
    addAuthenticator: 'Aggiungi dispositivo di autenticazione',
    scanWithApp: "Scansiona con l'app Authenticator",
    scanAppTooltip:
      "Puoi scaricare Google Authenticator o Microsoft Authenticator dal negozio di applicazioni del tuo telefono. Quindi, utilizza la fotocamera dell'app per scansionare il codice QR fornito.",
    scanAppTooltipForChina:
      "Puoi scaricare Google Authenticator o Microsoft Authenticator dal negozio delle applicazioni del tuo telefono, o cercare Google Authenticator nel Mini Programma di WeChat e scegliere il primo risultato. Poi, utilizza la fotocamera dell'app per scannerizzare il codice QR fornito.",
    scanAuthenticatorAppTips: {
      tips1:
        'È possibile scaricare Google Authenticator o Microsoft Authenticator dal negozio di app mobili',
      tips2:
        'Oppure puoi anche cercare Google Authenticator nel Mini Programma di WeChat e selezionare il primo risultato.',
      tips3:
        "Successivamente, utilizza la fotocamera dell'applicazione per scannerizzare il codice QR fornito",
    },
    userGuide: "Guida per l'utente",
    setupKey: 'Chiave di configurazione',
    tnc: "Collegando un'app di autenticazione, confermi di aver letto e accettato il {link}.",
    disclaimer: 'disclaimer',
    changePasswordSuccess: 'Password Aggiornata',
    changeEmailSuccess: 'Indirizzo Email Aggiornato',
    enableSecuritySuccess: 'Autenticatore di Sicurezza Abilitato',
    updateSecuritySuccess: 'Authenticator di Sicurezza Aggiornato',
    changePasswordSuccessDesc:
      'La tua password è stata aggiornata. <br/> Ora puoi utilizzare la nuova password per accedere.',
    changeEmailSuccessDesc:
      'Il tuo indirizzo email è stato aggiornato. <br/> Ora puoi utilizzare il nuovo indirizzo email per accedere.',
    enableSecuritySuccessDesc: 'La tua app di autenticazione di sicurezza è stata abilitata.',
    updateSecuritySuccessDesc: 'La tua app di autenticazione di sicurezza è stata aggiornata.',
    passwordReq: 'Requisiti della password:',
    passwordCharacters: '8-16 caratteri',
    passwordMix: 'Una combinazione di lettere (a-z e A-Z) e numeri (0-9)',
    passwordSpecial: 'Caratteri speciali come !@#$%^&*().',
    confirmPwd: 'Conferma password',
    enableNow: 'Abilita ora',
    changeDesc:
      'Per garantire la sicurezza dei fondi nel tuo account, i prelievi non saranno supportati per T+ {numDays} giorni dopo la modifica del tuo {method}.',
    verificationTitle: 'Verifica richiesta',
    verificationDesc: "Si prega di abilitare l'app Authenticator di sicurezza prima di procedere.",
    updateSuccess: 'Aggiornato con successo',
    verifiedSuccess: 'Verificato con successo',
    verificationRequired: 'Inserisci il codice di verifica',
    next: 'Avanti',
  },
  reserPwMobile: { header: 'REIMPOSTA PASSWORD' },
  downloads: {
    app: 'App {platform}',
    appDesc: "Scarica l'app {platform} su App Store e Google Play Store",
    mt4: 'METATRADER 4',
    mt4Desc: 'Scarica MetaTrader 4 per PC, browser web, smartphone e tablet',
    windows: 'Finestre',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android',
    proTrader: 'Commerciante PRO',
    webTrader: 'Web Trader',
    smartTrader: 'Strumenti SmartTrader',
    mt5: 'METATRADER 5',
    mt5Desc: 'Scarica MetaTrader 5 per PC, browser web, smartphone e tablet',
    appTrader: { title: 'APPTRADER & PROTRADER', iosAndroid: 'AppTrader iOS & Android' },
    button: { download: 'SCARICA', launch: 'LANCIARE' },
  },
  forgetPwReq: {
    headerMobile: "INSERISCI IL TUO NUMERO DI TELEFONO PER L'ACCESSO",
    labelEmail: 'Email',
    labelMobile: 'Numero di Telefono',
    loginMobile: "Numero di Telefono per l'Accesso",
    loginSMSCode: 'Codice SMS',
    loginSmsCodeExpires: 'Il codice SMS scade tra 5 minuti',
    getCode: 'Invia codice SMS',
    failedCode: 'Impossibile inviare il codice SMS al tuo telefono',
    sentAlready: 'Inviato',
    expired: 'Il codice SMS è scaduto',
    tip: "Inserisci l'indirizzo email del tuo account per reimpostare la tua password.",
    formValidation: {
      emailReq: "È richiesto l'email di accesso",
      mobileReq: "È richiesto l'accesso mobile",
      smsCodeReq: 'è richiesto il codice sms',
    },
    succ: "Ti abbiamo appena inviato un'email con le istruzioni per reimpostare la tua password.<br><br>Assicurati di controllare la cartella spam della tua email.",
  },
  loginClient: {
    header: 'PORTALE CLIENTI',
    failedMasseage: 'Prova ancora',
    existingClientPortalAccess:
      'Vediamo che hai già accesso al Portale Clienti.<br /><br />Accedi utilizzando la tua email per aprire un nuovo account!',
    existingClientPortalAccessMobile:
      'Vediamo che hai già accesso al Portale Clienti.<br /><br />Accedi utilizzando il tuo numero di telefono per aprire un nuovo account!',
    registerBlacklist:
      'Ci scusiamo per informarvi che questa email non è idonea per la registrazione. Se avete ulteriori domande, vi preghiamo di contattare il nostro servizio clienti.',
    formValidation: {
      emailReq: "È richiesto l'indirizzo email",
      mobileReq: 'Si prega di inserire solo valori numerici',
    },
  },
  myProfile: {
    changeDetails:
      'Se desideri aggiornare i dettagli del tuo profilo esistente, non esitare a contattare {mailTo}',
  },
  openAdditAcc: {
    chooseTradePlat: 'SCEGLI UNA PIATTAFORMA DI TRADING',
    chooseAccType: 'SCEGLI UN TIPO DI ACCOUNT',
    chooseCurrency: 'SCEGLI UNA VALUTA PER IL CONTO',
    chooseLeverage: 'SCEGLI LEVA',
    chooseBalance: 'SALDO DEL CONTO',
    notes: 'NOTE AGGIUNTIVE',
    currError: 'Devi selezionare una valuta per il tuo account',
    platErr: 'Devi selezionare una piattaforma di trading',
    accTypeErr: 'Devi selezionare un tipo di account',
    leverageErr: 'Devi selezionare una leva',
    balanceErr: 'Devi selezionare un saldo',
    succ: "<p>Il tuo account live aggiuntivo è in fase di configurazione e riceverai un'email con i tuoi dati di accesso a breve.</p><p>Puoi visualizzare lo stato attuale della tua richiesta dalla pagina principale.</p>",
    byTicking: 'Spuntando questa casella:',
    swapFreeNote:
      '*Si prega di notare: Invece delle commissioni di scambio, potrebbe essere applicata una piccola tassa amministrativa.',
    pammNote:
      '*Le PAMM Accounts sono solo per investitori con un accordo di Money Manager già esistente. Le PAMM accounts non possono essere negoziate da nessuno tranne il Money Manager.',
    vfsc: {
      disclaimer:
        '<li>1. Riconosco di aver letto e compreso l\'<a href="https://{regulatorUrl}/company-profile/legal-documentation/moneta-llc-risk-warning-notice" target="_blank" rel="noopener noreferrer">Avviso di Rischi</a> che è fornito online come parte di questa domanda. Capisco che i <a href="https://{regulatorUrl}/company-profile/legal-documentation/moneta-llc-key-facts-summary" target="_blank" rel="noopener noreferrer">Documenti Informativi Chiave</a> sono disponibili per me sul sito web di Moneta Markets Ltd (“{platform}”).\n      <li>2. Riconosco di aver letto, compreso e accettato il <a href="https://{regulatorUrl}/company-profile/legal-documentation/moneta-llc-client-agreement" target="_blank" rel="noopener noreferrer">Contratto Cliente {platform}</a> fornito online come parte di questa domanda.\n      <li>3. Capisco che {platform} non mi fornirà alcun consiglio di investimento sulle transazioni effettuate sulla sua piattaforma.</li>\n      <li>4. Confermo anche di aver letto, compreso e accetto di essere vincolato dalla <a href="https://{regulatorUrl}/company-profile/legal-documentation/moneta-llc-privacy-policy" target="_blank" rel="noopener noreferrer">Politica sulla Privacy di {platform}.</a>\n      <li>5. Capisco che le informazioni personali inviate come parte di questa domanda saranno utilizzate per verificare la mia identità con un servizio di autenticazione di terze parti.</li>\n      <li>6. Confermo che le informazioni fornite da me e inserite in questo modulo sono corrette e riconosco che sarò obbligato a informare immediatamente {platform} in caso di qualsiasi modifica a queste informazioni.</li>\n      <li>7. Confermo di aver agito nel mio nome come specificato in questa domanda e non per conto di terzi per quanto riguarda tutte le questioni relative a questo rapporto con il cliente. Di conseguenza, tutti i fondi da depositare e negoziare',
    },
  },
  openDemoAdditAcc: {
    header: 'APRI UN ULTERIORE CONTO DEMO',
    button: 'apri un account demo',
    liveButton: 'apri un conto in tempo reale',
    succ: "Il tuo account demo aggiuntivo è stato configurato e riceverai un'email con i tuoi dettagli di accesso a breve.",
  },
  resetPassword: {
    header: 'REIMPOSTA PASSWORD',
    succ: 'La tua password è stata aggiornata. Ora puoi utilizzare la tua nuova password per accedere al tuo account di trading MT4/MT5.',
    succ2: 'La tua password è stata aggiornata.',
    failed: 'Il token è scaduto',
    emailNotFound: 'Sfortunatamente il tuo indirizzo email non è stato riconosciuto',
  },
  fileUploader: {
    supportedFormat: 'Tipi di file supportati: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Tipi di file supportati: {files}',
    maxSize: 'Dimensione massima del file di caricamento: {maxSize}MB',
    maxFileNum: 'Numero massimo di file: {maxFileNum}',
    failedFileExtension: 'Si prega di caricare il tipo di file corretto',
  },
  transcationHistory: {
    desc: {
      incomplete:
        'Se interrompi o non completi il processo di deposito, lo stato della transazione verrà mostrato come "incompleto". Sarà necessario creare un nuovo deposito poiché questa transazione non può essere ripresa.',
      success:
        'La tua transazione è stata eseguita con successo. I tuoi fondi dovrebbero apparire nel tuo account a breve',
      proc: 'La tua transazione è in fase di elaborazione. Si prega di contattare {mailTo}',
      failed:
        'La tua transazione è fallita. Si prega di contattare {mailTo} per ulteriori informazioni',
      rejected:
        'La tua transazione è stata rifiutata. Si prega di contattare {mailTo} per ulteriori informazioni',
    },
    withdrawDesc: {
      sub: 'La tua transazione è stata inviata. Si prega di attendere per completare.',
      suc: 'La tua transazione è stata eseguita con successo. I tuoi fondi dovrebbero apparire nel tuo account a breve',
      proc: 'La tua transazione è in fase di elaborazione. Si prega di contattare {mailTo}',
      incomp:
        'La tua transazione richiede passaggi aggiuntivi per essere finalizzata. Si prega di contattare {mailTo} per ulteriori informazioni',
      cancel:
        'La tua transazione è stata annullata. Si prega di contattare {mailTo} per ulteriori informazioni',
      fail: 'La tua transazione è fallita. Si prega di contattare {mailTo} per ulteriori informazioni',
      rej: 'La tua transazione è stata rifiutata. Si prega di contattare {mailTo} per ulteriori informazioni',
    },
    cancelWithdraw: 'Desideri annullare la richiesta di prelievo?',
    cancelError:
      'Il tuo prelievo è in fase di elaborazione al momento. Pertanto, non possiamo accettare la tua richiesta di cancellazione. Per ulteriori informazioni, puoi contattarci utilizzando la funzione di chat dal vivo.',
    deposit: 'DEPOSITI',
    withdraw: 'PRELIEVI',
  },
  transferFunds: {
    default: {
      equityStop:
        'Sfortunatamente, il tuo trasferimento non può essere elaborato perché comporterebbe una riduzione del margine disponibile del tuo conto al di sotto del livello di interruzione del 100%',
      equityMargin:
        'Sfortunatamente, al momento non siamo in grado di elaborare il tuo trasferimento perché il tuo conto è completamente coperto.',
      equityContinue:
        "Abbiamo rilevato che attualmente hai posizioni aperte, potresti sperimentare l'interruzione dopo aver inviato la tua domanda di trasferimento. Si prega di confermare se si desidera procedere.",
      blackList:
        'Questo account è stato limitato nel fare trasferimenti tra account, si prega di contattare {supportEmail}',
    },
    pleaseNote: 'Si prega di notare:',
    suc: 'I tuoi fondi sono stati trasferiti con successo',
    warn1:
      'Sei responsabile per mantenere un saldo del conto che non attiverà una chiamata a margine. Se hai delle posizioni aperte attualmente, assicurati di mantenere il margine richiesto nel tuo conto. I fondi trasferiti su conti tenuti nella stessa valuta vengono elaborati immediatamente.',
    warn2:
      "Se si trasferiscono fondi tra conti tenuti in valute diverse, invia un'email a {mailTo}.",
    warn3:
      'Si prega di notare che i fondi non possono essere trasferiti tra account in sola lettura.',
  },
  transferHistory: {
    payStatus: {
      processing: 'Elaborazione',
      paid: 'Pagato',
      unpaid: 'Non pagato',
      rejected: 'Rifiutato',
    },
  },
  identityProof: {
    home: {
      header: 'DOMANDA DI CONTO INCOMPLETA',
      content:
        'Ciao {nome}, la tua richiesta di account è quasi completa, tuttavia devi ancora inviare documentazione aggiuntiva per finalizzare il tuo account.',
    },
    reason:
      'La tua documentazione non è stata in grado di essere verificata,<br> Motivo: {motivo}. Per favore, prova di nuovo.',
    shufti: {
      content:
        "Si prega di assicurarsi che i dettagli forniti corrispondano alla documentazione d'identità e siano nella stessa lingua.",
      countryOfDocument: 'Paese come indicato sul documento',
    },
    popup: {
      header:
        'Ciao {nome}, prima che il tuo account live possa essere finalizzato, devi caricare il seguente documento per la verifica.',
      addressProofSpan:
        '<strong>\n      <b>Conferma Indirizzo</b>\n      </strong>\n      <br />\n      <I>\n        Invia <b>uno</b> dei documenti sottostanti\n        <br /><br />\n      </I>\n      - Copia di una recente bolletta </br>(non più vecchia di 6 mesi)\n      <br />\n      <b class="or">o</b><br />\n      - Copia di un recente estratto conto bancario </br> (non più vecchio di 6 mesi)\n      <br />\n      <br />\n      <b>IMPORTANTE !</b> Il nome sul documento DEVE mostrare chiaramente il tuo nome completo e l\'indirizzo attuale.\n      <br />',
      idProofSpan:
        "<strong>\n      <b>Conferma ID</b>\n      </strong>\n      <br />\n      <I>Scegli\n        <b>una</b> delle opzioni sottostanti\n        <br />\n        <br />\n      </I>\n      - Copia del passaporto valido<br />\n\n      - Copia della patente di guida valida\n      <br />\n      (Fronte e retro)\n      <br />\n      o</b><br />\n      - Copia della carta d'identità nazionale\n      <br />\n      <br />\n      <b>IMPORTANTE !</b> Il nome sul documento DEVE mostrare chiaramente il tuo nome completo.<br />",
    },
  },
  questionnaire: {
    home: {
      header: '{regolatore} Questionario Incompleto',
      tip: 'La tua domanda per un account live non sarà approvata fino a quando non avrai completato con successo il questionario di idoneità - questionario {regolatore}. Consulta il nostro PDS per ulteriori informazioni.',
      tipFCA:
        'La tua domanda per un account live non sarà approvata fino a quando non avrai completato con successo il questionario di idoneità - questionario {regolatore}.',
    },
    header: '{regolatore} QUESTIONARIO',
    q1: '1. A differenza dei mercati azionari, il mercato forex non ha né una borsa centrale né una vera e propria sede fisica. Poiché le transazioni forex vengono condotte per telefono o attraverso reti elettroniche tra due controparti, il mercato forex è considerato un mercato "interbancario" o "over-the-counter" (OTC).',
    q2: '2. Il termine PIP sta per "percentuale in punto" ed è il più piccolo incremento di scambio in FX.',
    q3: '3. Il __________ è la quantità di pips tra il prezzo di offerta e il prezzo di domanda. Questo è il modo in cui i broker forex guadagnano i loro soldi su ogni operazione forex effettuata attraverso la loro rete.',
    q4: '4. Se hai troppe perdite fluttuanti e il tuo conto di trading non ha più abbastanza soldi per sostenere le operazioni aperte, ti verrà emesso un __________.',
    q5: "5. Se un investitore non può effettuare ulteriori pagamenti per soddisfare un requisito di Margin Call, il fornitore di OTC CFD ha la discrezione di liquidare o chiudere la posizione OTC CFD del cliente, senza fare riferimento all'investitore.",
    q6: '6. Il trading Forex può essere molto rischioso se non si utilizza una corretta gestione del rischio. Il Forex è considerato una delle forme di investimento più rischiose a causa della disponibilità di leva finanziaria.',
    q7: '7. COME investitore OTC CFD CON un broker di servizi finanziari, QUALSIASI deposito che fornisco AL broker viene conservato IN un conto fiduciario del cliente che È separato DAL conto operativo del broker.',
    q8: "8. Nel trading forex, tutte le valute sono quotate in coppia. Ad esempio, nel confrontare l'Euro Dollaro (EUR) con il Dollaro Statunitense (USD), si cita la coppia di valute EUR/USD. Quando mostriamo una quotazione forex per questa coppia, stiamo dicendo quanti dollari vale ogni Euro. Se la quotazione è EUR/USD 1.29, ciò significa che 1 Euro vale __________.",
    q9: '9. Un investitore in un CFD OTC non può perdere più del suo investimento iniziale quando investe in CFD OTC.',
    q10: "10. Se apri 2 lotti standard di USD/JPY, staresti negoziando $200,000 contro l'importo equivalente di JPY. Se avessi $10,000 nel tuo account, il tuo rapporto di leva con la posizione sopra sarebbe 20:1. Questo significa che una variazione dell'1% nella coppia di valute USD/JPY rappresenterebbe quale percentuale di variazione nel tuo account?",
    a1: { opt1: 'Falso', opt2: 'Vero' },
    a2: { opt1: 'Falso', opt2: 'Vero' },
    a3: { opt1: 'Diffusione', opt2: 'Buffer', opt3: 'Zona' },
    a4: { opt1: 'Chiamata di Margine', opt2: 'Aumento della leva', opt3: 'Facilità di Scoperto' },
    a5: { opt1: 'Falso', opt2: 'Vero' },
    a6: { opt1: 'Falso', opt2: 'Vero' },
    a7: { opt1: 'Falso', opt2: 'Vero' },
    a8: {
      opt1: '0,29 Dollari Statunitensi',
      opt2: '1,29 Dollari statunitensi',
      opt3: '3,45 Dollari Statunitensi',
      opt4: '0,78 Dollari statunitensi',
    },
    a9: { opt1: 'Falso', opt2: 'Vero' },
    a10: { opt1: '1%', opt2: '2%', opt3: '10%', opt4: '20%' },
    succ: 'Congratulazioni. Hai superato il questionario del {regolatore}.',
    fail: 'Sfortunatamente, non hai superato il questionario {regulator}. Per favore, prova di nuovo.',
  },
  header: { secPortal: 'Area Clienti Sicura' },
  menu: {
    home: 'CASA',
    accManagement: 'CONTI',
    liveAcc: 'CONTI IN TEMPO REALE',
    openAdditAcc: 'APRI CONTI AGGIUNTIVI',
    openAnAdditLiveAcc: 'APRI UN ULTERIORE CONTO LIVE',
    homeDemo: 'ACCOUNT DI PROVA',
    depositFund: 'DEPOSITA FONDI',
    withdrawFunds: 'PRELEVA FONDI',
    transferBetweenAccs: 'TRASFERIMENTO TRA ACCOUNT',
    payDetails: 'DETTAGLI DEL PAGAMENTO',
    funds: 'FONDI',
    transactionHis: 'CRONOLOGIA DELLE TRANSAZIONI',
    tranHis: 'TRASFERIMENTI',
    download: 'DOWNLOAD',
    profile: 'PROFILO',
    myProfile: 'IL MIO PROFILO',
    cpPassword: 'CAMBIA LA PASSWORD DEL PORTALE CLIENTI',
    contactUs: 'CONTATTACI',
    helpSupport: 'MODULI DI AIUTO E SUPPORTO',
    register: 'CONTINUA APPLICAZIONE',
    tradingTools: 'STRUMENTI PER TRADER PROFESSIONISTI',
    MARKET_BUZZ: 'RUMORE DI MERCATO',
    ECON_CALENDAR: 'CALENDARIO ECONOMICO',
    FEAT_FX: 'IDEE IN EVIDENZA',
    FX_IDEAS: 'Opinioni Tecniche',
    ALPHA_GENERATION: 'GENERAZIONE ALPHA',
    MARKET_MASTERS: 'MAESTRI DEL MERCATO',
    marketWidgets: 'Widget di Mercato',
    technicalAnalysis: 'ANALISI TECNICA',
    techAnalysisTab: {
      forex: 'FOREX',
      indices: 'INDICI',
      commodities: 'MERCANZIE',
      sharedcfds: 'CONDIVIDI CFDs',
      crypto: 'CRIPTO',
    },
    marketData: 'dati di mercato',
    forexCrossRates: 'tassi di cambio incrociati forex',
    forexHeatMap: 'mappa termica forex',
    forexScreener: 'selezionatore forex',
    referAFriend: 'RACCOMANDA UN AMICO',
    wholesale: 'PASSA ALLA VERSIONE PRO',
    tradingView: 'GRAFICI di TradingView',
    protraderPlatform: 'TRADER PROFESSIONISTA',
    twoFactorAuthentication: 'Autenticazione a Due Fattori (2FA)',
    promotion: 'PROMOZIONE',
    depositBonus: 'Bonus del 50% sul Deposito',
    support: 'Supporto',
    myTickets: 'I Miei Biglietti',
    hmcTrader: 'Sala di Trading dal Vivo',
    cashback: 'Cashback',
    securityManagement: 'Gestione della sicurezza',
  },
  contact: {
    desc: 'Se avete domande o desiderate ulteriori informazioni, vi preghiamo di contattarci attraverso uno dei dettagli di contatto qui sotto. Ci sforzeremo di rispondere a tutte le richieste entro 24 ore.',
    tip: 'Per i clienti che si sono registrati tramite {email}',
    liveChat: 'Chat dal Vivo',
    helpSupportForm: {
      partner: 'Modulo di Trasferimento di Partenariato',
      investigation: 'Modulo di Indagine sul Commercio di Moneta',
      promotion: 'Modulo per rinunciare alle promozioni di Moneta',
      request: 'Modulo di Richiesta di Chiusura Account',
    },
    accountManager: "Responsabile dell'account - {userName}",
    monetaSupport: 'Supporto Moneta',
  },
  depositResultDialog: { msg: 'Hai completato con successo il tuo deposito?' },
  dialog: {
    identityProof: {
      success:
        'Grazie. Abbiamo ricevuto con successo i vostri documenti e li stiamo attualmente elaborando per la verifica.',
    },
  },
  sessionDialog: {
    title: 'La tua sessione sta per scadere!',
    content: 'Verrai disconnesso in 60 secondi. Vuoi rimanere connesso?',
    keepIn: 'Sì, mantienimi connesso',
    signOut: 'No, disconnettimi',
  },
  notification: {
    pendingIdentityProof: {
      header: 'PROCESSO DI ELABORAZIONE DELLA DOMANDA DI CONTO',
      id: 'ID è',
      poa: "L'indirizzo è",
      both: 'ID e comprovante di residenza sono',
      content:
        "La tua prova di {doc} è attualmente in elaborazione e sarà finalizzata a breve. Per accelerare l'elaborazione del tuo account live, puoi finanziare il tuo account ora e ricevere l'apertura prioritaria.",
    },
  },
  idPoa: {
    IdPoaNoPass: {
      header: 'Domanda di Account Incompleta',
      content:
        "Per iniziare a fare trading, si prega di caricare il documento di Prova di Identità e Prova di Indirizzo. Una volta inviato, finanzia il tuo account per l'apertura prioritaria dell'account.",
    },
    onlyIdPass: {
      header: 'Grazie!',
      content:
        "La tua domanda è in fase di elaborazione. Finanzia il tuo account ora per l'apertura prioritaria del conto.",
    },
    onlyPoaPass: {
      header: 'Domanda di Account Incompleta',
      content:
        "Per iniziare a fare trading, si prega di caricare il documento di prova dell'ID. Una volta inviato, finanzia il tuo account per l'apertura prioritaria dell'account.",
    },
    bothIdPoaPass: {
      header: 'Grazie!',
      content:
        "La tua domanda è in fase di elaborazione. Finanzia il tuo account ora per l'apertura prioritaria del conto.",
    },
    uploadID: 'Carica ID',
    uploadPoa: 'Carica Indirizzo',
    depositNow: 'Deposita Ora',
  },
  register: {
    progressStatus: {
      title: 'Progresso della verifica',
      min: 'minuti',
      estimatedTimeTitle: 'Tempo Stimato per il Completamento:',
    },
    tabs: {
      personalDetails: 'Dati Personali',
      moreAboutYou: 'Più su di Te',
      accountConfig: "Configurazione dell'account",
      confirmYourId: 'Conferma il tuo ID',
      fundYourAcc: 'Finanzia il tuo Account',
    },
    btn: {
      next: 'PROSSIMO',
      back: 'INDIETRO',
      fundnow: 'FINANZIA ORA',
      upload: 'Carica',
      done: 'FATTO',
      uploadNew: 'Carica Nuovo',
    },
    authority: {
      dialog1: 'Devi avere un account attivo per accedere a questa sezione del portale clienti.',
      openLiveAcc: 'apri un conto in tempo reale',
      dialog2:
        "Apri un conto live ora per sbloccare l'accesso completo al tuo portale clienti, che include una serie di strumenti di trading, promozioni e molto altro.",
    },
    demo: {
      congrad: 'Congratulazioni!',
      title: 'Il tuo account DEMO MT{piattaforma} di 30 giorni è ora attivo',
      title2: 'I dettagli del tuo account DEMO',
      li: {
        login: 'Accesso:',
        password: 'Password:',
        srv: 'Server: "Server"',
        expDate: 'Data di Scadenza:',
      },
      emailSent: '* I dettagli del login sono stati anche inviati alla tua email registrata.',
      dl: 'Scarica la tua piattaforma di trading MT{platform}',
      proTraderPlatform: {
        title: 'Piattaforma del Trader PRO',
        content:
          'Accedi alla nostra piattaforma PRO Trader di prossima generazione utilizzando i dettagli del tuo account MT{piattaforma}:',
      },
      appTrader: {
        title: 'AppTrader',
        content:
          "Accedi all'AppTrader di Moneta Markets utilizzando i tuoi dati di accesso del Client Portal. Scarica subito AppTrader per iOS o Android!",
      },
      improveQ: 'Vuoi migliorare il tuo trading?',
      improveWay:
        'Semplicemente, apri un conto live in pochi minuti per sbloccare una gamma di strumenti di trading esclusivi e promozioni.',
      proTrade: 'Strumenti Pro Trader',
      depositBonus: 'Bonus del 50% sul Deposito',
      smartTradeTool: 'Strumenti Intelligenti per Trader',
      cashback: 'Bonus Cashback del 50%',
      marketMasters: 'Maestri del Mercato',
      openLiveAcc: 'apri un conto in tempo reale',
      poweredBy: 'Alimentato da',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Dati Personali',
        userTitle: 'Titolo',
        firstName: 'Nome',
        lastName: 'Cognome',
        email: 'E-mail',
        dob: 'Data di nascita',
        idType: 'Tipo di Identificazione',
        referral: 'Sei stato segnalato da qualcuno? (opzionale)',
        middleName: 'Secondo Nome',
        nationality: 'Nazionalità',
        phoneNum: 'Numero di Telefono',
        date: 'Giorno',
        month: 'Mese',
        year: 'Anno',
        placeOfBirth: 'Luogo di nascita',
        idNum: 'Numero di Identificazione',
        passportNum: 'Numero di Passaporto',
        driverLicenseNum: 'Numero di Patente di Guida',
        titleDropdown: {
          mr: 'Signor',
          mrs: 'Signora',
          ms: 'Sig.ra',
          miss: 'Signorina',
          dr: 'Dottore',
          prof: 'Professore',
        },
        idTypeDropdown: {
          passport: 'Passaporto',
          idCard: "Carta d'Identità Nazionale",
          driverLicense: 'Patente di Guida',
        },
      },
      page2: {
        pageTitle: 'INDIRIZZO RESIDENZIALE PRINCIPALE',
        pageTitleForFCA: 'DETTAGLI AGGIUNTIVI',
        mainResidency: 'Paese di residenza principale',
        streetNumber: 'Numero Civico',
        streetName: 'Nome della Strada',
        address: 'Indirizzo',
        provinceOrState: 'Provincia / Stato',
        cityOrSuburb: 'Città / Sobborgo',
        postcode: 'Codice postale',
        previousCountry: 'Paese Precedente',
        nationalInsuranceNumber: 'Numero di Assicurazione Nazionale (NI)',
        yearsAtAddress: "Numero di anni all'indirizzo",
        previousResidentialAddressTip:
          'Si prega di inserire il vostro precedente indirizzo residenziale qui sotto',
        usQuestion:
          'Sei un cittadino/residente statunitense ai fini fiscali o hai qualche legame/collegamento con gli Stati Uniti (ad es. conto bancario, ecc.)?',
        taxResidencyCountry: 'Paese(i) di residenza fiscale',
      },
    },
    moreAboutYou: {
      pageTitle1: "DETTAGLI SULL'IMPIEGO E FINANZIARI",
      pageTitle2: 'Scambio di Conoscenze ed Esperienze',
      complianceExplain: 'Le seguenti domande sono raccolte per conformità ai requisiti AML/CTF.',
      FCAQuestion10Tip:
        'Negli ultimi 12 mesi, quante operazioni, in media, hai eseguito nei seguenti strumenti per trimestre?',
    },
    accountConfig: {
      pageTitle: "Configurazione dell'account",
      chooseTradingPlatform: 'Scegli Piattaforma di Trading',
      chooseAccType: 'Scegli il tipo di account',
      chooseAccCurrency: "Scegli la Valuta dell'Account",
    },
    confirmYourId: {
      pageTitle: 'Conferma il tuo ID',
      poidTitle: 'Prova di Identità',
      porTitle: 'Prova di Residenza',
      uploadDescription: 'Scegli un tipo di documento e caricalo di conseguenza',
      note: 'IMPORTANTE: Il nome sul documento DEVE mostrare chiaramente il tuo nome completo.',
      pending: { upload: 'Caricamento in sospeso', verification: 'In Attesa di Verifica' },
      poidTypes: {
        photoId: "Documento d'identità con foto",
        passport: 'Una copia del tuo passaporto internazionale (Pagina della Firma)',
        drivingLicence: 'Patente di Guida',
      },
      description:
        "Come broker forex regolamentato, siamo tenuti a verificare la tua identità. Si prega di caricare uno dei seguenti documenti per la verifica del tuo documento d'identità e prova di residenza. In alternativa, puoi inviare i tuoi documenti di verifica a {mailTo}",
      porTypes:
        '<li>Fattura delle utenze</li>\n      <li>Estratto conto bancario</li>\n      <li>Certificato di residenza</li>',
    },
    finishPage: {
      title: '{individualUserName}, grazie per aver richiesto un account live {platformName}!',
      contentDefault:
        "Abbiamo ricevuto la tua richiesta e impostato il tuo account live <b>{account}</b>. <br />\n      A breve, riceverai un'email all'indirizzo <b>{email}</b> contenente i tuoi dati di accesso e un link per visualizzare lo stato del tuo account. <br />",
      contentWithoutId3WithAccount:
        "Il tuo account di trading dal vivo è attualmente in fase di elaborazione e riceverai un'email contenente ulteriori informazioni a breve.<br />\nPer accelerare l'elaborazione del tuo account dal vivo, puoi finanziare il tuo account ora e ricevere l'apertura prioritaria.<br />",
      contentWithoutId3WithoutAccount:
        "Il tuo account di trading dal vivo è attualmente in fase di elaborazione e riceverai un'email contenente ulteriori informazioni a breve.<br />\nPer accelerare l'elaborazione del tuo account dal vivo, puoi finanziare il tuo account ora e ricevere l'apertura prioritaria.<br />",
    },
  },
  tradingTools: {
    MARKET_BUZZ:
      'Accedi alla copertura di notizie e contenuti multimediali che coprono più di 35.000 asset negoziabili.',
    FEAT_FX:
      'Visualizza configurazioni tecniche di trading azionabili e segui i loro progressi in tempo reale.',
    ECON_CALENDAR: 'Goditi dati economici in tempo reale 24 ore su 24 da tutto il mondo.',
    FX_IDEAS:
      'Visualizza interessanti e didattici approfondimenti sul trading tecnico su 80.000 strumenti.',
    ALPHA_GENERATION:
      'Visualizza interessanti e didattici approfondimenti sul trading tecnico su 80.000 strumenti.',
    MARKET_MASTERS:
      'Aumenta il tuo vantaggio di trading e ottieni 3 indicatori avanzati di prossima generazione per MT4 e MT5.',
    condition: {
      needDeposit:
        'Scopri come fare trading come i professionisti con oltre 100 tutorial video avanzati sul trading.',
      congratulations:
        'Congratulazioni! Sei pronto per sbloccare i tuoi Strumenti Premium per Trader!',
      needTnc: 'Si prega di accettare il {link}.',
      link: 'Termini e Condizioni',
      unlock: 'SBLOCCA GLI STRUMENTI PRO TRADER',
      depositNow: 'DEPOSITA ORA',
      tncAisc: "Sono d'accordo ad avere accesso a questi strumenti.",
    },
    techAna: 'Analisi Tecnica per',
    byTradingView: 'da TradingView',
    policy: {
      title: 'TERMINI E CONDIZIONI DEGLI STRUMENTI E TUTORIAL PER PRO TRADER DI MONETA MARKETS',
      subTitle: 'Questa promozione è valida dal 15/05/2022.',
      term1:
        "Partecipando a questa promozione, i clienti riconoscono di aver letto e accettato di essere vincolati da questi Termini e Condizioni e dall'Accordo con il Cliente.",
      term2: 'Questa offerta è disponibile per tutti i clienti di Moneta Markets.',
      term3: 'Per richiedere questa offerta, devi:',
      term3Sub1: 'Avere un account Live con Moneta Markets',
      term3Sub2:
        'effettua un deposito minimo di almeno 500 unità della tua valuta di base (escludendo JPY)',
      term3Sub3: "Iscriviti tramite il Portale Clienti, sotto 'Strumenti PRO Trader'",
      term4:
        'I clienti che aderiscono con successo a questa offerta saranno in grado di accedere al pacchetto Strumenti e Tutorial per Trader PRO di Moneta Markets.',
      term5:
        'Moneta Markets non sarà responsabile per eventuali partecipazioni perse, in ritardo, incomplete, illeggibili o indirizzate erroneamente.',
      term6: 'Le domande fraudolente, multiple o completate in modo errato non saranno accettate.',
      term7:
        'Moneta Markets si riserva il diritto di variare, modificare, sostituire o revocare o annullare i Termini della Promozione in qualsiasi momento senza preavviso.',
      term8:
        'Le modifiche a questi Termini saranno rese disponibili online e ti incoraggiamo a controllare regolarmente il nostro sito web.',
      term9:
        'Moneta Markets risolverà qualsiasi controversia o situazione non coperta da questi Termini a sua esclusiva discrezione.',
      condition1:
        "Il rapporto è preparato e presentato da TRADING CENTRAL ed è reso disponibile ai clienti di Moneta Markets. Le opinioni e le vedute espresse nel rapporto sono esclusivamente quelle degli autori e degli analisti e Moneta Markets non verifica l'accuratezza di alcuna affermazione o dichiarazione. Il rapporto è destinato alla lettura informativa e non dovrebbe essere considerato come un sostituto di una ricerca indipendente estesa. Le informazioni e le opinioni fornite da TRADING CENTRAL non tengono conto degli obiettivi di investimento, della situazione finanziaria o delle esigenze di un particolare individuo, e quindi non costituiscono un consiglio o una raccomandazione rispetto a qualsiasi prodotto di investimento.",
      condition2:
        "© 2022 Trading Central. Tutti i diritti riservati. Le informazioni contenute qui: (1) sono di proprietà di Trading Central e/o dei suoi fornitori di contenuti; (2) non possono essere copiate o distribuite; (3) non sono garantite per essere accurate, complete o tempestive; e, (4) non costituiscono un consiglio o una raccomandazione da parte di Moneta Markets, Trading Central o dei suoi fornitori di contenuti riguardo all'investimento in strumenti finanziari. Né Moneta Markets né Trading Central né i suoi fornitori di contenuti sono responsabili per eventuali danni o perdite derivanti da qualsiasi utilizzo di queste informazioni. Le prestazioni passate non sono garanzia di risultati futuri.",
      condition3:
        "I prezzi, i dati storici dei grafici e i dati fondamentali dell'azienda sono forniti da Morningstar Research Inc.",
      condition4: 'Technical Event® è un marchio registrato di Trading Central.',
      condition5:
        'I prodotti e servizi di Trading Central sono protetti dal brevetto statunitense n.: 6,801,201; 7,469,226; 7,469,238; 7,835,966; e 7,853,506; e corrispondenti brevetti stranieri.',
    },
    alphaGeneration: {
      intro: 'GENERAZIONE ALPHA PER MT4 E MT5',
      introDesc:
        'Aumenta il tuo vantaggio di trading con tre EA premium esclusivi per Moneta Markets.',
      analystViews: {
        title: 'Opinioni Tecniche',
        desc: 'Livelli di prezzo tecnici istantanei e tendenze visualizzate sul tuo grafico dei prezzi.',
      },
      adaptiveCandlesticks: {
        title: 'CANDELE ADATTIVE',
        desc: 'Scansione in tempo reale di 16 popolari modelli di candeliere.',
      },
      adc: {
        title: 'ADC',
        desc: 'Segnali di trading di entrata e uscita a breve termine in entrambe le direzioni, lunga e corta.',
      },
      install: 'COME INSTALLARE ALPHA GENERATION?',
      installSteps: {
        one: 'Scarica Alpha Generation per PC cliccando sul pulsante "Scarica Alpha Generation" qui sotto.',
        two: 'Fai doppio clic sul file per eseguirlo e segui la procedura guidata di installazione che ti guida attraverso il processo nella tua lingua di scelta.',
        three: 'Scegli la tua cartella di destinazione Moneta Markets MT4 o MT5.',
        four: 'Una volta installato, apri la tua piattaforma MT4 o MT5 e trova gli indicatori di Trading Central insieme agli altri nella tua lista di indicatori MetaTrader.',
        five: 'Trascina un indicatore sul grafico e ottieni immediatamente intuizioni operative utili.',
      },
      button: { guid: "Guida per l'utente", download: 'Scarica Alpha Generation' },
    },
  },
  wholesale: {
    appForm: "Modulo di Domanda per la Categorizzazione del Cliente all'Ingrosso",
    appDetails1: 'Grazie per essere un cliente di Moneta Global Prime Ltd (“Moneta”).',
    appDetails2:
      'Se desideri richiedere una diversa categorizzazione, ti preghiamo di completare le domande pertinenti nella pagina successiva. Una volta ricevuta la tua richiesta, valuteremo le informazioni che abbiamo in archivio per te e ti informeremo riguardo la nostra decisione. Potremmo richiedere informazioni aggiuntive, prove di supporto da te o chiederti di completare un quiz, nonché chiudere qualsiasi posizione aperta che potresti attualmente detenere sotto il tuo(i) conto(i) esistente(i) prima di finalizzare la tua domanda.',
    appDetails3:
      'Per ogni categoria di clienti, si applicano livelli variabili di protezione e trattamento. Prima di inviare la tua richiesta di ricategorizzazione, assicurati di aver letto, compreso e accettato il {link}.',
    link: 'Avviso di Categorizzazione del Cliente',
    agree:
      'Con la presente confermo di aver letto e compreso la suddetta Notifica di Categorizzazione del Cliente, e comprendo le implicazioni della mia richiesta di categorizzazione. Chiedo formalmente che Moneta mi ricategorizzi di conseguenza.',
    unlock: 'AGGIORNA a PRO',
    tnc: 'Per favore, acconsenti',
    wealth: {
      title: "CATEGORIZZAZIONE CLIENTI ALL'INGROSSO",
      fullName: 'Nome Completo:',
      Email: 'Indirizzo email:',
      note1:
        'Nota per "*": (*Quando si calcolano i patrimoni netti, ricordarsi di includere quanto segue: Risparmi, Azioni/altre investimenti, Superannuazione/Fiducie, La tua casa/altre proprietà/terreni, Valore dell\'azienda, Auto/barca/roulotte).',
    },
    declaration: {
      agree: 'Spuntando questa casella:',
      declare:
        '<li>Capisco che Moneta Global Prime Pty Ltd, ACN 157768566, AFSL No. 428901, che opera come Moneta, è l\'emittente dei prodotti (Margin FX "FX" e Contratti per differenza "CFD"). </li>\n<li>Riconosco di aver letto, compreso e accettato di essere vincolato dal <a href="https://{regulatorUrl}/company-profile/legal-documentation/VGP-InvestorStatement" target="_blank">Dichiarazione di Informazioni per Clienti all\'Ingrosso</a>, <a href="https://{regulatorUrl}/company-profile/legal-documentation/VGP-Wholesale-TermsandConditions" target="_blank">Termini e Condizioni per Clienti all\'Ingrosso</a>(T&C’s), <a href="https://{regulatorUrl}/company-profile/legal-documentation/VGP-ClientCategorisationNotice" target="_blank">Avviso di Categorizzazione del Cliente</a> e <a href="http://{regulatorUrl}/pdf/Vantage_Global_Prime_Pty_Ltd_Privacy_Policy.pdf" target="_blank">Politica sulla Privacy</a> e <a href="http://{regulatorUrl}/pdf/Vantage_FX_Pty_Ltd_Privacy_Policy.pdf" target="_blank">Politica sulla Privacy di Moneta Pty Ltd</a> di Moneta Global Prime Pty Ltd.</li>\n<li>Accetto di non acquisire prodotti o servizi finanziari con Moneta Global Prime Pty Ltd ("Moneta") in relazione a un\'attività commerciale.</li>\n<li>Accetto che Moneta possa ritirare il mio status di cliente all\'ingrosso in qualsiasi momento a sua totale discrezione.</li>\n<li>Riconosco che i prodotti e i servizi finanziari che Moneta mi fornirà come cliente all\'ingrosso non hanno necessariamente gli stessi requisiti di protezione degli investitori e di divulgazione dei prodotti resi disponibili ai clienti al dettaglio, e sono soddisfatto di avere la capacità di ottenere le informazioni necessarie per prendere una decisione informata.</li>\n<li>Riconosco e accetto che, dopo aver presentato questa domanda, potrei essere richiesto',
    },
    sophisticated: {
      desc1:
        "Grazie per aver inviato il tuo modulo di domanda per la categorizzazione dei clienti all'ingrosso.",
      desc2:
        "Sulla base di quanto hai dichiarato nella tua domanda, potresti essere idoneo a essere classificato come cliente all'ingrosso e ti potrebbe essere offerto un account PRO - riservato solo ai clienti all'ingrosso.",
      desc3: 'Per finalizzare la tua domanda:',
      desc4:
        '(Sentitevi liberi di inviarci ({mailTo}) una copia del vostro storico di trading più tardi se non lo avete immediatamente disponibile).',
      access: 'Accedi al Quiz per Investitori Sofisticati qui',
      btn: 'Ripeti il Quiz',
      options:
        "<li>Completa il quiz per l'Investitore Sofisticato per aiutarci a completare la tua domanda.</li>\n      <li>Forniscici una copia del tuo storico di trading per valutare la tua esperienza di trading (puoi fornirci il tuo storico di trading con Moneta o altri broker o una combinazione)</li>",
      error:
        "Ci dispiace informarti che il tuo tentativo non è stato fruttuoso e quindi, non saremo in grado di elaborare la tua domanda in questa fase.\n      Se desideri conservare una copia dei risultati del tuo quiz, ti preghiamo di contattare {mailTo}.\n      Sei il benvenuto a riprovare il Quiz dell'Investitore Sofisticato in qualsiasi momento.",
    },
    upload: {
      errorMessage: 'Si prega di caricare tutti i documenti richiesti',
      certificate: {
        desc3:
          'Dovrai fornire un valido certificato di un commercialista certificato (<b>rilasciato negli ultimi due (2) anni</b>) che attesti che soddisfi uno dei test di ricchezza minima.',
        desc4:
          'Se non hai un certificato di ragioniere, puoi stampare il certificato di ragioniere di Moneta per farlo completare dal tuo ragioniere qualificato. Si prega di trovare il certificato {qui}.',
        here: 'qui',
      },
      statement: {
        desc3:
          'Per finalizzare la tua domanda, forniscici una copia del tuo storico di trading per valutare la tua esperienza di trading.',
        button: 'Carica il Rendiconto di Trading',
      },
    },
    thankYou: {
      desc2:
        'Grazie per aver inviato il tuo estratto conto di trading. Sarà elaborato in modo tempestivo.',
      desc3:
        "Verrai notificato tramite il tuo indirizzo email registrato dell'esito della nostra valutazione.",
      desc4:
        'Grazie per aver inviato il tuo modulo di domanda per la categorizzazione del cliente professionale.',
      desc5: 'Abbiamo ricevuto la tua domanda, e sarà elaborata in modo tempestivo.',
    },
    clientCategorizationNotice: {
      desc1: 'Modulo di Domanda per la Categorizzazione del Cliente Professionale Facoltativo',
      desc2: '"Grazie per essere un cliente di Moneta Global Prime Ltd ("Moneta")."',
      desc4:
        'Sulla base delle informazioni che ci hai fornito, ti abbiamo classificato come Cliente al Dettaglio. Se desideri richiedere una diversa classificazione, ti preghiamo di completare le domande pertinenti nella pagina successiva. Al ricevimento della tua richiesta, valuteremo le informazioni che abbiamo in archivio per te e ti informeremo sulla nostra decisione. Potremmo richiedere informazioni aggiuntive, prove di supporto da parte tua o chiederti di completare un quiz, nonché chiudere qualsiasi posizione aperta che potresti attualmente detenere sotto il tuo(i) conto(i) esistente(i) prima di finalizzare la tua domanda.',
      ccn: 'Avviso di Categorizzazione del Cliente',
      agreed:
        'Con la presente confermo di aver letto e compreso la suddetta Notifica di Categorizzazione del Cliente, e comprendo le implicazioni della mia richiesta di categorizzazione. Chiedo formalmente che Moneta mi ricategorizzi di conseguenza.',
      desc3:
        "In linea con le regole dell'Autorità di Condotta Finanziaria (FCA) sulla Categorizzazione dei Clienti, dobbiamo classificare i nostri clienti come Clienti al Dettaglio, Clienti Professionali o Controparti Idonee. Questo per garantire che, quando facciamo affari con voi, possiamo fornire il livello di informazioni, servizi e protezione che è appropriato e coerente con la vostra categorizzazione.",
      desc5:
        'Per ogni categoria di clienti, si applicano livelli variabili di protezione e trattamento. Prima di inviare la tua richiesta di ricategorizzazione, assicurati di leggere, capire e accettare le',
    },
    proClientCategorization: {
      title: 'Categorizzazione del Cliente Professionale',
      fullName: 'Nome completo',
    },
    documentSubmit: {
      desc1:
        'Grazie per aver inviato il tuo modulo di domanda per la categorizzazione del cliente professionale.',
      desc2:
        'Sulla base di quanto hai dichiarato nella tua domanda, potresti essere idoneo a essere classificato come cliente professionale e ti potrebbe essere offerto un account PRO - limitato solo ai clienti professionali.',
      desc3: 'Dovrai fornire i documenti come di seguito:',
      upload1:
        'Dichiarazioni del portafoglio che coprono un periodo di 12 mesi (potrebbero provenire da più aziende)',
      upload2: 'Estratti conto bancari Estratti conto del portafoglio (non più vecchi di un mese)',
      upload3:
        'Copia del contratto, Profilo Linkedin, screenshot del Numero di Riferimento Individuale FCA,',
      upload3_5:
        "Collegamento al profilo aziendale e alla biografia, Dichiarazione del dipartimento delle risorse umane che conferma l'impiego e il ruolo",
    },
    failure: {
      400: {
        desc1:
          "Grazie per aver inviato la tua domanda di categorizzazione del cliente all'ingrosso.",
        desc2:
          "Sulla base delle informazioni che ci hai fornito; abbiamo determinato che non soddisfi i requisiti normativi per essere classificato come cliente all'ingrosso e quindi non possiamo offrirti un conto di trading PRO in questa fase. Sei il benvenuto per fare domanda nuovamente in futuro.",
        desc3:
          "Se ritieni ancora di dover essere classificato come cliente all'ingrosso, ti preghiamo di contattare il nostro Supporto dedicato ai PRO al numero 1300 945 517 o via email a {mailTo}.",
      },
      401: {
        desc1: "Grazie per la tua richiesta di essere classificato come Cliente all'Ingrosso.",
        desc2:
          "Si prega di notare che non avete avuto successo in questo caso perché la domanda per essere classificati come Cliente all'Ingrosso sotto il Test dell'Investitore Sofisticato non è disponibile per i clienti con strutture di conto Aziendale o Fiduciario/SMSF.",
        desc3:
          "Potrebbe desiderare di prendere in considerazione la possibilità di fare una domanda per essere classificato come Cliente all'Ingrosso secondo il Test sulla Ricchezza Individuale, dove valuteremo i beni netti necessari o il reddito lordo delle persone di controllo associate alla struttura del tuo conto Aziendale o Fiduciario/SMSF.",
        desc4:
          "La Sezione 761G(7)(c) del Corporations Act consente di classificare un cliente come Cliente all'Ingrosso se il cliente fornisce a Moneta una copia di un certificato da un Contabile Qualificato che certifica che il cliente:",
        desc5: '(i) Ha un patrimonio netto di almeno A$2,5 milioni; o',
        desc6:
          "(ii) Ha un reddito lordo per ciascuno degli ultimi 2 anni fiscali di almeno A$250,000 all'anno.",
        desc7:
          'Il certificato deve essere valido per due (2) anni dalla data di emissione prima della successiva richiesta di rinnovo.',
        desc8:
          "Per qualsiasi domanda relativa al conto di trading Moneta PRO, non esitate a contattare il nostro team di supporto dedicato al numero 1300 945 517 o inviateci un'email a {mailTo}.",
      },
    },
  },
  tradingView: {
    launch: 'LANCIARE',
    subTitle:
      'Unisciti a 15 milioni di altri investitori e inizia a fare trading con i grafici, gli indicatori e gli strumenti di disegno di TradingView.',
    descp1: '100+ indicatori tecnici',
    descp2: 'Aggiornamenti in tempo reale sulle notizie di mercato',
    descp3: '50+ strumenti di disegno intelligenti',
    descp4: 'Analisi multi-temporale',
    descp5: '12 diverse condizioni di avviso di trading',
    learnMore: 'Scopri di più',
    tnc: 'Accedi alla piattaforma di grafici e trading di TradingView con i tuoi dati di accesso MT{mtServer}. Scopri di più su CHARTS di TradingView <a href="https://{regulatorUrl}/platforms/trading-platforms/charts-by-tradingview/">qui</a>.',
    intro:
      'Presentiamo CHARTS di TradingView. I clienti di Moneta possono ora trovare idee di trading, individuare tendenze e piazzare operazioni in tempo reale, tutto attraverso la nostra piattaforma integrata TradingView, CHARTS.',
    descp6: 'Multipli intervalli di tempo',
    descp7: '50+ strumenti di disegno intelligenti',
    descp8: '20+ layout di grafici',
    descp9: '12+ tipi di grafici personalizzabili',
    descp10: 'Copertura delle notizie di mercato integrate',
    tnc02:
      'Utilizza i tuoi dati di accesso MT{mtServer} ed esplora la piattaforma web Protrader ora. {findMore}',
    tnc03: 'Scopri di Più',
    subTitle02:
      'Gli utenti della piattaforma Moneta Protrader hanno ora accesso a grafici dei prezzi in tempo reale, indicatori tecnici e strumenti di disegno, tutti supportati da TradingView; su oltre 300 coppie di valute, indici, materie prime, CFD su azioni e altro ancora.',
    intro02: 'Ottieni accesso a:',
    intro03: 'Alimentato da',
  },
  TFAuth: {
    title: 'Autenticazione a Due Fattori (2FA)',
    enable: {
      enableTitle: "ABILITA L'AUTENTICAZIONE A DUE FATTORI",
      enableDesc:
        "Mantieni il tuo account sicuro abilitando l'autenticazione a due fattori sul tuo cellulare. L'autenticazione a due fattori fornisce un ulteriore livello di sicurezza e tranquillità attraverso un secondo passaggio di verifica ogni volta che effettui l'accesso. Una volta abilitata, puoi recuperare un codice di verifica a due fattori da un'app sul tuo telefono iOS o Android in qualsiasi momento, inviarlo ed effettuare l'accesso in modo sicuro.",
      enableSubtitle: "Come abilitare l'autenticazione a due fattori?",
      step1: "Scarica e installa gratuitamente Google Authenticator dall'App Store iOS o Android.",
      step2:
        'Una volta nell\'app, seleziona "Scansiona codice a barre" e scansiona il codice a barre qui sotto.',
      step3: "Inserisci il codice numerico nell'app (si aggiorna ogni 30 secondi) qui sotto.",
      placeHolder: 'codice',
    },
    disable: {
      disableTitle: "DISABILITA L'AUTENTICAZIONE A DUE FATTORI",
      disableDesc:
        "Per disattivare l'autenticazione a due fattori, invia qui il codice numerico dalla tua app Google Authenticator.",
    },
    dialog: {
      title: 'Si prega di inserire il codice di autenticazione a due fattori',
      contact: 'Hai problemi ad accedere? {link}.',
      link: 'Contattaci',
    },
    popup: {
      popup1: "L'autenticazione a due fattori è abilitata.",
      popup2: "L'autenticazione a due fattori è disabilitata.",
    },
  },
  promotion: {
    depositBonus: {
      depositBonus: 'Deposita e richiedi il tuo bonus di trading',
      title: 'Richiedi il tuo bonus e aumenta il tuo deposito!',
      subtitle1:
        'Vuoi più potere di trading? Tutto quello che devi fare è finanziare il tuo conto live. Una volta ricevuto il tuo deposito, il tuo bonus di deposito apparirà nel tuo conto di trading.',
      fund: 'Finanzia il tuo account',
      tnc: '*{link} applica.',
      link: 'Termini e condizioni',
      bonusHistory: 'Cronologia dei Bonus',
      overBonus:
        '* Hai raggiunto il massimo di ${totalLimit} crediti bonus. Depositi aggiuntivi non danno diritto a crediti bonus.',
      opt: 'Vuoi aderire per richiedere il tuo bonus sul deposito?',
      tick: 'Si prega di controllare la casella se si desidera aderire alla promozione del bonus di deposito.',
      saveChange: 'Salva Modifiche',
      creditReceived: 'Credito Ricevuto',
      welcomeBonusTable: 'Bonus di Benvenuto',
      depositBonusTable: 'Bonus sul Deposito',
      depositDate: 'DATA DEL DEPOSITO',
      credit: 'CREDITO',
      optIn: 'Iscrizione avvenuta con successo',
      optOut: 'Disiscrizione avvenuta con successo',
      titlePopup:
        'Per richiedere il tuo Bonus di deposito del <span class="important">50%</span>, scegli semplicemente l\'opzione prima di effettuare il tuo primo deposito.',
      infoPopup:
        'Per visualizzare i termini e le condizioni della promozione, clicca <a href="{tncLink}" target="_blank">qui</a>.',
      checkboxLabelPopup: 'Voglio richiedere il mio bonus del 50%!',
      notification: 'sei idoneo a partecipare alla campagna Bonus Deposito. Per favore',
      optInButton: 'Adesione volontaria',
      tick2:
        "Moneta si riserva il diritto di escludere e/o annullare la partecipazione di un cliente alle sue offerte o promozioni se:<br><br> a) Churning (apertura e chiusura di transazioni FX o CFD a margine solo a scopo di guadagno);<br> b) il cliente risulta in violazione del suo Contratto con il Cliente o ha violato i termini e le condizioni delle promozioni.<br><br> In caso di tale comportamento scorretto o violazione, Moneta avrà il diritto di dedurre qualsiasi importo bonus e profitto ottenuto dal conto di trading.\n        <br><br>Se l'azienda sospetta o ha motivo di credere che un cliente abbia abusato e/o manipolato i termini e le condizioni di questo schema di bonus coprendo le sue posizioni internamente (utilizzando altri conti di trading detenuti con l'azienda) o esternamente (utilizzando altri conti di trading detenuti con altri broker) e/o non ha agito in buona fede, l'azienda si riserva il diritto, a sua totale discrezione e senza ottenere il consenso del cliente, di rimuovere l'importo del bonus e il profitto ottenuto dal conto di trading con effetto immediato.\n        <br><br>Moneta può in qualsiasi momento apportare modifiche a questi termini e condizioni e vi informerà di queste modifiche pubblicando i termini modificati sul sito web di Moneta. Si consiglia di rivedere regolarmente questi Termini, e l'uso continuato del sito web e dei servizi di Moneta implica l'accettazione di tali termini modificati. Moneta si riserva il diritto di modificare o annullare qualsiasi offerta a sua sola discrezione in qualsiasi momento.",
      dontShowAgain: 'Non mostrare più questo messaggio',
    },
    referAFriend: {
      congratulations:
        'Congratulazioni! Ti sei appena iscritto con successo alla promozione Invita-un-Amico come referente.',
      redeem: 'Riscatta',
      redeemed: 'Riscattato',
      dayRemain: 'giorni rimanenti',
      voucherDesc: 'Il buono può essere riscattato una volta che hai scambiato ≥ {lotti} lotti',
      title: 'Riferisci un amico e guadagna fino a ${amount} e ${secAmount} per il tuo amico!',
      register: 'Registrati con noi',
      forYourFriend: 'per il tuo amico',
      liveAcc: 'Apri un conto in tempo reale',
      makeDeposit: 'Effettua un deposito',
      trade1Lot: 'Commercia 1 Lotto',
      trade5Lots: 'Commercia 5 Lotti',
      totalEarning: 'Guadagni Totali',
      referralAct: 'Attività di Referenza',
      referralName: 'Nome del Referente',
      registered: 'Registrato',
      accOpen: 'Conto Aperto',
      madeDeposit: 'Deposito Effettuato',
      redeemStatus: 'Stato del Riscatto',
      notApplicable: 'Non applicabile',
      country: 'Paese',
      acc: 'CONTO',
      share: 'CONDIVIDI',
      copyLink: 'COPIA IL LINK',
      redeemYourbonus: 'Riscatta il tuo bonus',
      tatolAmtConvert:
        "L'importo totale convertito nella tua valuta di base è {convertAmt} {currency}",
      remark: '*Cliccando su riscatta ora, accetti i termini e le condizioni',
      redeemNow: 'RISCUOTI ORA',
      overlimit: 'Oltre il limite',
      newUser: 'Nuovo Utente',
      tradeCash: 'Commercio di Contanti',
      desc: 'Quando il tuo referente completa i seguenti passaggi entro 30 giorni, guadagnerai fino a ${amount}! E, puoi fare riferimento fino a 10 referenti. Si applicano *<a href="{tncLink}">Termini e condizioni</a>*.',
    },
    cashback: {
      button: {
        applyForBonus: 'FAI DOMANDA PER IL BONUS',
        redeemCashback: 'RISCUOTI CASHBACK',
        howItWorks: 'COME FUNZIONA?',
        depositNow: 'Deposita Ora',
        stp: 'STP DIRETTO',
        ecn: 'PRIME ECN',
        agree: 'CONCORDO',
        cancel: 'ANNULLA',
      },
      title: {
        monetaProgram: 'PROGRAMMA CASHBACK DI MONETA MARKETS',
        claim: 'RICHIEDI ORA IL TUO BONUS DI RIMBORSO DEL 50%!',
        howItWorks: 'ECCO COME FUNZIONA',
        howMuch: 'QUANTO GUADAGNI SUI COMMERCi?',
        treeSimpleSteps: 'RIVENDICA ORA IL TUO BONUS DEL 50% - IN TRE SEMPLICI PASSAGGI:',
        depositTradEarn: 'DEPOSITA. FAI TRADING. GUADAGNA!',
        bonusSummary: 'Il tuo Riassunto Bonus',
        historicalEarnings: 'I TUOI GUADAGNI ATTUALI E STORICI',
        program: 'PROGRAMMA CASHBACK',
        cashSummary: 'RIEPILOGO CASHBACK',
        faqs: 'DOMANDE FREQUENTI SUL BONUS CASHBACK',
        redeemCashback: 'RISCUOTI CASHBACK',
        cashbackBalance: 'Saldo Cashback',
      },
      subTitle: {
        lowerYourCost: 'Riduci i tuoi costi. Guadagna denaro.',
        bonus: 'BONUS',
        cashback: 'CASHBACK',
        wallet: 'PORTAFOGLIO',
        availableBonus: 'Bonus disponibile',
        totalBalance: 'Saldo totale',
        step1: 'Deposita fondi e candidati!',
        step2: 'Inizia a fare trading!',
        step3: 'Richiedi il tuo cashback',
        FX: 'FOREX',
        XAU: 'ORO',
        OIL: 'OLIO',
        perLot: 'per lotto',
        seetheBonusStatus:
          'Guarda la colonna STATO BONUS per APPLICARE eventuali bonus utilizzabili',
        tradeFxOilAndGold:
          'Fai trading su FX, Petrolio e Oro per convertire il tuo BONUS in RIMBORSO!',
        totalCashback: 'Rimborso totale',
        FebruaryCashback: 'Cashback in attesa',
        cashbackRedeemable: 'Cashback riscattabile',
        account: 'Conto',
        amount: 'Importo',
      },
      information: {
        availableBonus1:
          "Il Bonus Disponibile è l'importo dei Crediti Bonus che hai guadagnato su tutti i depositi idonei. Per ogni deposito di $500 o più, riceverai il 50% di quell'importo come Crediti Bonus.",
        total:
          "Il Saldo Totale è la somma del cashback riscattabile e dell'importo del cashback del mese corrente.",
        availableBonus2:
          'Il tuo bonus disponibile è il numero di Crediti Bonus provenienti da tutti i depositi idonei, che non sono ancora stati convertiti e trasferiti nel tuo Portafoglio Cashback.',
        totalCashback:
          'Il Cashback Totale è il numero di Crediti Bonus che sono stati convertiti in contanti.',
        februaryCashback:
          "I Bonus Credits del mese corrente che sono stati convertiti in contanti. Puoi trasferirli sul tuo conto di trading all'inizio del prossimo mese.",
        cashbackRedeemable:
          'Il numero di crediti che sono stati convertiti in contanti fino alla fine del mese scorso. Puoi trasferire questo importo sul tuo conto di trading in qualsiasi momento.',
        selectTheTradingAccount:
          'Seleziona il conto di trading a cui desideri trasferire il tuo cashback.',
        enterTheAmount: "L'importo (USD) che desideri trasferire sul tuo conto di trading.",
      },
      content: {
        claimContent1:
          "Ora, ogni volta che ricarichi il tuo conto live con $500 o più, ti daremo un bonus del 50%, che apparirà nel Portafoglio Bonus sulla pagina principale del tuo Portale Clienti! Poi, quando fai trading su FX, Oro o Petrolio, i tuoi crediti bonus si convertiranno in denaro reale, mostrato nel Portafoglio Cashback! All'inizio di ogni mese, i fondi nel tuo Portafoglio Cashback possono essere trasferiti ai tuoi conti di trading live per essere utilizzati come fondi reali!",
        claimContent2:
          'La cosa migliore di tutte, per ogni futuro deposito che effettui di $500 o più, il tuo bonus verrà automaticamente accreditato nel tuo Portafoglio Bonus, così potrai continuare a guadagnare di più!',
        howItWorksContent1:
          'Finanzia il tuo conto live con $500 o più, poi clicca su "Applica" e accetta i termini e le condizioni per ricevere il tuo bonus del 50%',
        howItWorksContent2:
          '"Quando fai trading con FX, Oro o Petrolio, i tuoi crediti bonus si convertiranno automaticamente in denaro reale, negoziabile, che puoi tenere sotto controllo nel tuo "Portafoglio Cashback"!',
        stepsContent1: 'Deposita $500 o più e richiedi il tuo bonus qui sotto',
        stepsContent2:
          'Inizia a fare trading su FX, Oro e Petrolio per convertire il tuo bonus in denaro reale!',
        stepsContent3: "Richiedi il tuo cashback dall'attività di trading dei mesi precedenti!",
        depositTradEarnContent1: 'Più fai trading, più guadagni.',
        depositTradEarnContent2:
          'Quando effettui un deposito di $500 o più, puoi richiedere un bonus sul deposito del 50%!',
        optInContent1:
          'Per aderire alla Promozione Cashback di Moneta Markets, devi accettare i termini e le condizioni sottostanti.',
        electTheDestinationAccount: 'Seleziona il conto destinatario da riscattare',
        howItWorksContent3:
          'All\'inizio di ogni nuovo mese, fai semplicemente clic su "Riscatta Cashback" sul tuo "Portafoglio Cashback" e riscatta l\'importo che viene mostrato come "Cashback Riscattabile".',
      },
      column: {
        date: 'DATA',
        deposit: 'DEPOSITI',
        withdraws: 'RITIRA',
        bonus: 'BONUS RIVENDICABILE',
        status: 'STATO BONUS',
        account: 'CONTO',
        redeem: 'CASHBACK RISCATTO',
        reason: 'NOTE',
      },
      tnc: {
        title: 'Termini e Condizioni',
        content: {
          item1: {
            title: 'Termini e Condizioni del Cashback',
            li1: 'Moneta Markets è un nome commerciale di Moneta Markets Ltd, registrato presso il Registro delle Società Internazionali di San Lucia con il numero di registrazione 2023-00068.',
            li2: "I 'Termini e Condizioni del Bonus Cashback' sono parte integrante dei 'Termini e Condizioni di Moneta Markets Ltd' e le disposizioni qui stabilite sono considerate un allegato di tutti i 'Termini e Condizioni di Moneta Markets Ltd' e dovrebbero essere letti in combinazione.",
          },
          item2: {
            title: 'Condizioni di Ammissibilità.',
            li1: '"Fermo restando, e senza pregiudizio per, tutti gli altri Termini e Condizioni, l\'offerta è disponibile per i Clienti di Moneta Markets che hanno soddisfatto i Criteri di Idoneità per l\'offerta ("Clienti Idonei") come stabilito nelle sezioni seguenti."',
            li2: 'Moneta Markets offre il "Bonus Cashback" ai clienti che aprono conti di trading dal vivo (cioè non demo) con Moneta Markets.',
            li3: 'Il "Bonus Cashback" è disponibile solo per gli account Direct STP e Prime ECN. Gli account PAMM, MAM, Ultra ECN e "Cent" non sono idonei a partecipare alla promozione "Bonus Cashback". Gli account PAMM non sono idonei a partecipare.',
            li4: 'Moneta Markets, a sua totale discrezione, offre il "Bonus Cashback" a qualsiasi Cliente/i da qualsiasi paese/regione, come ritiene appropriato e per tutto il tempo che Moneta Markets ritiene di fornirlo. L\'azienda si riserva il diritto di ritirare e/o annullare qualsiasi idoneità in base a restrizioni e/o requisiti locali.',
            li5: '"Solo le persone che possono stipulare contratti legalmente vincolanti secondo le leggi applicabili nel loro paese di residenza possono partecipare al "Bonus Cashback". Senza limitare quanto precede, la partecipazione al "Bonus Cashback" non è consentita alle persone di età inferiore ai 18 anni, definite "Minori", in quanto non sono idonee ad aprire un conto con la Società"',
            li6: 'La partecipazione di "Intermediari/Parti Correlate" nel "Bonus Cashback" è proibita. Se i dati di registrazione e/o di trading di un partecipante nel "Bonus Cashback" corrispondono con le informazioni di registrazione e/o di trading, compreso ma non limitato all\'indirizzo IP, di un altro partecipante nel "Bonus Cashback", Moneta Markets si riserva il diritto di considerare questa corrispondenza come motivo per una immediata squalifica. Ai fini del presente, il termine "Intermediario(i) / Parte(i) Correlata(e)", quando utilizzato in questi Termini & Condizioni, a meno che il contesto non richieda diversamente, deve intendersi includere qualsiasi persona o entità che abbia una relazione con qualsiasi partecipante nel "Bonus Cashback", incluso, senza limitazione:',
            li6_sub_items: {
              li1: 'membri della famiglia, come fratelli, sorelle, coniugi, antenati, discendenti diretti e discendenti collaterali;',
              li2: '"persona o entità, che qualsiasi partecipante al "Cashback Bonus", direttamente o indirettamente attraverso uno o più intermediari, controlla, o che, direttamente o indirettamente, attraverso uno o più intermediari, è controllata da, o è sotto il controllo comune con qualsiasi partecipante al "Cashback Bonus"; ai fini di questa definizione, il termine "controllo" (compresi, con significato correlativo, i termini "controllato da" e "sotto controllo comune con"), come utilizzato rispetto a qualsiasi partecipante al "Cashback Bonus", deve significare il possesso, direttamente o indirettamente attraverso uno o più intermediari, del potere di dirigere o causare la direzione delle politiche di gestione di tale persona o entità, sia attraverso la proprietà di titoli di voto o altrimenti."',
              li3: 'Allo stesso modo, le persone associate in qualsiasi modo a Moneta Markets e/o ai siti web specifici e/o ai siti di social network su cui Moneta Markets potrebbe occasionalmente gestire determinate promozioni, concorsi e/o sondaggi, nel contesto dei quali viene offerto l\'accesso al "Bonus Cashback", non sono autorizzate a partecipare all\'offerta.',
            },
          },
          item3: {
            title: 'Termini specifici del "Bonus Cashback"',
            li1: 'I clienti devono prima depositare un minimo di $500, e poi fare domanda con successo per il loro bonus di credito del 50% nel loro portale clienti per ricevere crediti bonus. I crediti bonus dai depositi successivi vengono applicati automaticamente.',
            li1_sub_desc:
              '* Solo i depositi effettuati durante il periodo della promozione sono validi e saranno considerati per la promozione. Questo esclude i trasferimenti interni, i bonus per il refer-a-friend e le regolazioni in contanti.',
            li2: 'Il "Bonus Cashback" è disponibile solo per i conti Direct STP e Prime ECN. I conti Ultra ECN e "Cent" non sono idonei per partecipare alla promozione "Bonus Cashback".',
            li3: 'Clienti idonei (sia nuovi che esistenti), che soddisfano i criteri stabiliti qui, sono in grado di ricevere un rimborso pari a:',
            li3_sub_content: {
              title1: '[Tabella di rimborso diretto del cliente STP ed ECN]',
              info1: 'STP diretto: $2 per FX, petrolio, lotto oro scambiato',
              info2: 'Prime ECN: 50 centesimi di dollaro per FX, petrolio, lotto oro scambiato.',
              title2: '[Cliente sotto la tabella di rimborso IB STP e ECN]',
              info3: 'STP diretto: $0.50 per FX, petrolio, lotto oro scambiato',
              info4: 'Prime ECN: $0,25 per FX, petrolio, oro scambiato.',
            },
            li3_sub_desc:
              '* La dimensione minima accumulata del lotto di trading per cui può essere attivato il cashback è 0,01 lotto standard. Se il lotto di trading valido del cliente nel giorno non riesce ad accumulare a 0,1 lotto standard, sarà azzerato. Il numero cumulativo di lotti è un multiplo di 0,01 lotto standard, come 0,02 lotto standard, 0,03 lotto standard (0,12 lotto standard sarà calcolato come 0,12 lotto standard).',
            li4: 'I prodotti idonei: Tutti i prodotti Forex, Oro e Petrolio CFD',
            li5: 'Il volume di trading effettivo: Gli ordini aperti e chiusi di recente durante il periodo di promozione. Le posizioni aperte per meno di 10 minuti, le posizioni bloccate e le posizioni coperte non vengono considerate operazioni valide per questa promozione.',
            li6: 'Questa offerta può essere utilizzata in combinazione con qualsiasi altra offerta.',
            li7: "Il prelievo di fondi comporterà la rimozione di un importo equivalente in crediti dal Portafoglio Bonus di un utente. Se l'importo del prelievo è superiore all'importo del Credito Bonus, l'importo del credito sarà ridotto a un saldo zero.",
            li8: "Moneta Markets non sarà responsabile per eventuali costi, spese o danni che potrebbero essere subiti a seguito della reimpostazione o dell'adeguamento del conto del cliente come previsto da questi Termini e Condizioni, compresi ma non limitati a perdite attribuibili a Posizioni Aperte automaticamente Chiuse.",
            li9: 'Moneta Markets si riserva il diritto, a sua esclusiva discrezione:',
            li9_sub_items: {
              li2: '"squalificare qualsiasi partecipante al "Bonus Cashback" che manomette o viola questi termini e/o qualsiasi dei "Termini e Condizioni di Moneta Markets" stabiliti sul sito web di Moneta Markets."',
              li1: 'rifiutare la registrazione di qualsiasi partecipante al "Bonus Cashback"; e',
            },
            li10: 'Moneta Markets si riserva il diritto, a sua ragionevole discrezione, di interrompere l\'offerta di questo "Bonus Cashback" a qualsiasi dei suoi clienti, senza dover spiegare i motivi. Tali clienti saranno informati via email.',
            li11: 'Moneta Markets si riserva il diritto di modificare, emendare o terminare il "Bonus Cashback" o qualsiasi aspetto di esso in qualsiasi momento senza dover risarcire nessuno per questo. Un preavviso di tali modifiche sarà dato ai clienti. Si prega di notare che la partecipazione al "Bonus Cashback" costituisce accettazione e accordo a rispettare qualsiasi modifica, emendamento e/o cambiamento.',
            li12: "Qualsiasi indicazione o sospetto, a totale discrezione di Moneta Markets, di qualsiasi forma di arbitraggio (incluso ma non limitato a profitti senza rischio), abuso (incluso ma non limitato a modelli di attività di trading del partecipante che indicano che il partecipante mira esclusivamente a beneficiare finanziariamente del cashback senza essere genuinamente interessato a fare trading nei mercati e/o a correre rischi di mercato), frode, manipolazione, arbitraggio di cashback collegato a una promozione di cashback o qualsiasi altra forma di attività ingannevole o fraudolenta, annullerà tutti i precedenti cashback dei conti di trading reali del Cliente idoneo con Moneta Markets e/o tutte e qualsiasi transazione effettuata e/o profitti o perdite accumulati in essi. In queste circostanze, Moneta Markets si riserva il diritto, a sua totale discrezione, di chiudere/sospendere (temporaneamente o permanentemente) tutti i conti di trading reali del Cliente idoneo con Moneta Markets, annullare tutti gli ordini e annullare tutti i profitti di tale partecipante. In queste circostanze, Moneta Markets non sarà responsabile per qualsiasi conseguenza dell'annullamento del cashback, incluso, ma non limitato a, la chiusura dell'ordine(i) per Stop Out.",
            li13: 'La partecipazione al "Bonus Cashback" costituisce l\'accettazione di questi Termini e Condizioni e dei Termini e Condizioni di Moneta Markets stabiliti sul sito web di Moneta Markets.',
            li14: 'Il "Bonus Cashback" è disponibile per un periodo limitato, come indicato nelle regole di impegno della specifica promozione e/o sondaggio in cui il "Bonus Cashback" è menzionato come parte.',
            li15: '"Se questi termini e condizioni del "Bonus Cashback" vengono tradotti in una lingua diversa dall\'inglese, la versione inglese è superiore in caso di interpretazione incompatibile."',
            li16: 'Il Bonus Cashback non è disponibile per i clienti residenti in Turchia.',
            li17: 'Solo i depositi di $500 o più a partire dal 19 luglio 2023 sono idonei per il Bonus Cashback.',
          },
          item4: {
            title: 'Avvertimento sui Rischi',
            li1: 'Il trading di derivati e forex comporta un alto livello di rischio per il tuo capitale e dovrebbe essere effettuato solo con denaro che puoi permetterti di perdere. Assicurati di leggere i documenti sulla nostra pagina Documenti Legali e di cercare un consiglio indipendente, per comprendere appieno i rischi, prima di decidere di fare trading. Questa promozione è stata preparata senza tenere conto dei tuoi obiettivi, della tua situazione finanziaria o delle tue esigenze.',
          },
        },
      },
      faqsList: {
        question_1: "Cos'è il programma Cashback di Moneta Markets e come funziona?",
        question_2: 'Come posso iscrivermi alla promozione Cashback?',
        question_3: 'Cosa sono i crediti bonus?',
        question_4: "Cos'è il Cashback?",
        question_5: 'Come posso controllare il saldo del mio Bonus e Cashback?',
        question_6: 'Possono scadere i miei crediti bonus?',
        question_7: 'Il mio saldo Cashback può scadere?',
        question_8: 'Quali sono i benefici?',
        question_9: 'Quanti crediti bonus posso guadagnare?',
        question_10: 'Quanto Cashback posso guadagnare?',
        question_11: 'Quando posso ritirare il mio Bonus Cashback?',
        question_12: 'Come posso trasferire il mio Cashback disponibile sul mio conto di trading?',
        question_13: 'Quanto spesso viene calcolato il mio Cashback?',
        question_14: 'Posso guadagnare Cashback su tutti i prodotti?',
        question_15: 'Come posso ritirare il mio Bonus Cashback?',
        question_16: 'I prelievi influiranno sui miei crediti bonus?',
        question_17: 'I prelievi influiranno sul mio Bonus Cashback?',
        question_18: "C'è una durata minima di scambio per convertire il mio Bonus in Cashback?",
        question_19: 'Come posso aumentare i miei crediti bonus?',
        question_20: 'Come posso massimizzare il mio Cashback mensile?',
        question_21: 'Guadagno lo stesso importo di Cashback sui conti Direct STP e Prime ECN?',
        question_22: 'Posso guadagnare Cashback sui conti Ultra ECN o Cent?',
        question_23: 'Quali sono gli stati bonus e cosa significano?',
        question_24: 'Come posso rinunciare al Programma Cashback?',
        answer_1:
          "Il programma Cashback di Moneta Markets è un programma di fedeltà, in cui i clienti depositano un minimo di $500 per ricevere un bonus del 50% dell'importo del credito. Questi Bonus Crediti vengono poi convertiti in denaro prelevabile/commerciabile in base all'attività di trading su prodotti FX, Oro e Petrolio.",
        answer_2:
          'I clienti di Moneta Markets con un conto di trading idoneo dovranno "richiedere" il loro primo importo di Credito Bonus dal Portale Clienti di Moneta Markets. Basta fare clic su "Richiedi Bonus" nel Portafoglio Bonus sulla homepage del Portale Clienti, fare clic sul pulsante "Applica" nella tabella intitolata "I tuoi guadagni attuali e storici", quindi accettare i termini e le condizioni. I successivi Crediti Bonus guadagnati su depositi idonei verranno applicati automaticamente.',
        answer_3:
          'I Bonus Credits vengono guadagnati su depositi idonei di $500 o più, con un tasso del 50% per ogni deposito idoneo. I Bonus Credits sono conservati nel tuo Bonus Wallet e vengono convertiti in vero Cashback in base alla tua attività di trading su tutti i prodotti FX, Oro e Petrolio.',
        answer_4:
          "Il tuo Cashback è l'importo in contanti guadagnato sulla base della conversione dei Bonus Crediti come risultato della tua attività di trading. L'importo del tuo Cashback può essere trasferito sul tuo conto di trading e utilizzato come denaro reale.",
        answer_5:
          'Puoi visualizzare i tuoi saldi dalla schermata principale del tuo Portale Clienti, dove sono mostrati il tuo Portafoglio Bonus e il Portafoglio Cashback. Per una suddivisione del tuo Portafoglio Cashback, clicca semplicemente su "Promozioni" - "Cashback", o "Riscatta Cashback" dal "Portafoglio Cashback" nella pagina principale del tuo Portale Clienti, dove puoi visualizzare il tuo Cashback totale, il Cashback riscattabile e gli importi del Cashback del mese corrente.',
        answer_6:
          'No, i crediti bonus non scadono. Quando aderisci al Bonus Cashback, tutti i depositi e i prelievi saranno calcolati a partire dai precedenti 30 giorni per calcolare il tuo bonus disponibile. Eventuali Crediti Bonus successivi guadagnati su futuri depositi vengono applicati automaticamente e non scadono. Sei in grado di visualizzare lo stato dei tuoi guadagni bonus correnti e storici nella tabella intitolata "I tuoi guadagni correnti e storici", che mostrerà "Applica", "Accreditato", "Non idoneo" o "Dedotto".',
        answer_7:
          "Gli importi dei bonus Cashback guadagnati non scadranno e sono tuoi da trasferire sul tuo conto di trading per il trading in tempo reale, o da prelevare all'inizio di ogni mese.",
        answer_8:
          'Apprezziamo averti come cliente e vogliamo ringraziarti per aver scelto di fare trading con Moneta Markets. Di conseguenza, riceverai un premio sotto forma di Crediti Bonus su tutti i depositi idonei. Poi, man mano che continui a fare trading con noi, i tuoi Crediti vengono convertiti in denaro reale, come modo per ringraziarti per la tua continua fedeltà.',
        answer_9:
          "Quando depositi $500 o più, riceverai il 50% di quell'importo in Crediti Bonus. Poi, per ogni successivo deposito idoneo continuerai ad accumulare Crediti Bonus.",
        answer_10:
          '"L\'importo del Cashback che puoi guadagnare dipende dal tipo del tuo account e dai prodotti che ti piace scambiare. Puoi massimizzare i tuoi guadagni scambiando FX, Petrolio e Oro su un Account Moneta Markets Direct STP.<br/><br/>I tuoi Crediti Bonus vengono convertiti in contanti al tasso sottostante:<br/><br/>[Tabella di rimborso Direct STP e Prime ECN]<br/>Direct STP: $2 per ogni lotto di FX, Petrolio, Oro scambiato<br/>Prime ECN: $.50 per ogni lotto di FX, Petrolio, Oro scambiato".',
        answer_11:
          'Sei in grado di trasferire il saldo mostrato come il tuo "Cashback Disponibile" sul tuo conto di trading in qualsiasi momento. Mentre il Cashback guadagnato nel mese corrente è disponibile per il trasferimento sul tuo conto di trading all\'inizio del mese successivo.',
        answer_12:
          "Tutto ciò che devi fare è fare clic su “Riscatta Cashback” nel tuo Portafoglio Cashback, selezionare il conto di trading e l'importo del Cashback che desideri trasferire e fare clic nuovamente su “Riscatta Cashback”.",
        answer_13:
          'Il Cashback guadagnato dalla tua attività di trading su tutti i prodotti FX, Oro e Petrolio viene calcolato quotidianamente alle GMT+2 e apparirà nel tuo Portafoglio Cashback.',
        answer_15:
          'Il prelievo del tuo Cashback Bonus disponibile è semplice. Tutto ciò che devi fare è cliccare su "Riscatta Cashback" nel tuo Portafoglio Cashback, selezionare il conto di trading e l\'importo del Cashback che desideri trasferire e cliccare nuovamente su "Riscatta Cashback". Una volta che i fondi sono trasferiti sul tuo conto di trading, puoi utilizzarli per fare trading o inviare una richiesta di prelievo per l\'elaborazione.',
        answer_16:
          'I crediti presenti nel tuo Bonus Wallet saranno detratti pro-rata se prelevi fondi. Ad esempio, se il tuo Bonus Wallet contiene 500 crediti e scegli di prelevare $250 dal tuo conto di trading, verranno detratti anche 250 crediti bonus. Nota che i tuoi crediti bonus non cadranno mai in un saldo negativo, indipendentemente da quanti fondi scegli di prelevare.',
        answer_17:
          'No. Il tuo Bonus Cashback è tuo e rimarrà inalterato da qualsiasi prelievo tu faccia.',
        answer_18:
          'Qualsiasi operazione su FX, Oro o Petrolio deve essere mantenuta per un minimo di 10 minuti affinché il tuo Bonus possa essere convertito in Cashback.',
        answer_19:
          "I tuoi Crediti Bonus si accumulano ogni volta che effettui un deposito idoneo. Per ogni deposito di $500 o più, riceverai il 50% di quell'importo in Crediti Bonus.",
        answer_20:
          "Per massimizzare l'importo del Cashback che puoi guadagnare si basa su due fattori; il tipo del tuo conto e la tua attività di trading su prodotti FX, Oro e Petrolio, con i conti Direct STP che si convertono in Cashback ad un tasso più alto rispetto ai conti Prime ECN. Assicurati semplicemente di avere un importo sufficiente di crediti nel tuo Portafoglio Bonus e fai trading su FX, Oro e Petrolio su un conto Direct STP per massimizzare il tuo Cashback. E, più operazioni fai e maggiore è il volume che scambi, più velocemente i tuoi Crediti Bonus si convertiranno in Cashback.",
        answer_22:
          'Sfortunatamente, gli account Ultra ECN o Cent non accumulano alcun Cashback dal trading di FX, Oro o Petrolio. Devi fare trading con account Direct STP o Prime ECN.',
        answer_23:
          'Applica: La prima volta che effettui un deposito idoneo, devi fare clic su "Applica" per ottenere il tuo bonus. Tutti i depositi successivi saranno accreditati automaticamente.<br/>Accreditato: Il bonus è stato accreditato e apparirà nel tuo Portafoglio Bonus<br/>Non idoneo: Il bonus non ha soddisfatto il requisito minimo di $500<br/>Dedotto: Il bonus è stato dedotto a seguito del prelievo di fondi dal tuo conto di trading.',
        answer_24:
          'Se decidi che non vuoi più far parte del programma, invia la tua richiesta di rinuncia a <a href="mailto:{email}">{email}</a>',
        answer_14:
          'Il Bonus Cashback può essere guadagnato su tutti i prodotti FX, Oro e Petrolio sia sui conti Direct STP che Prime ECN come per la tabella sottostante.<br/><br/>[Tabella di rimborso Direct STP e Prime ECN]<br/>Direct STP: $0.50 per lotto FX, Petrolio, Oro scambiato<br/>Prime ECN: $0.25 per lotto FX, Petrolio, Oro scambiato.',
        answer_21:
          "L'importo del Cashback guadagnato su FX, Oro e Petrolio varia tra i diversi tipi di conto.<br/><br/>[Tabella di rimborso Direct STP e Prime ECN]<br/>Direct STP: $0,50 per ogni lotto di FX, Petrolio, Oro scambiato<br/>Prime ECN: $0,25 per ogni lotto di FX, Petrolio, Oro scambiato.",
      },
      reason: {
        1: 'È stato aggiunto un bonus del 50% al tuo Portafoglio Bonus',
        2: 'Regolazione manuale del bonus - Accreditato',
        3: "L'importo del prelievo è stato rimosso dal tuo Portafoglio Bonus",
        4: 'Questo importo bonus è stato convertito in Cashback',
        5: 'Regolazione manuale del bonus - Detratto',
        6: "Questo deposito non ha raggiunto l'importo minimo del deposito per qualificarsi",
      },
      status: { 0: 'Applica', 1: 'Accreditato', 2: 'Dedotto', 3: 'Ineleggibile' },
      zeroMsg: 'non hai più alcun cashback da riscattare',
    },
  },
  hmcTraderPortal: {
    overview: 'Panoramica',
    interactiveAnalysis: 'Analisi Interattiva',
    learnFromTheBest: 'Impara dai Migliori',
    requestAResearch: 'Richiedi-una-Ricerca',
    vipSupport: 'Supporto VIP',
    eligibility: 'Eleggibilità',
    login: 'Accedi alla Sala di Trading dal Vivo HMC',
    depositRequirement:
      "Incontra il requisito di deposito per ottenere l'accesso alla Sala di Trading dal Vivo di HMC",
    basic: 'Base',
    vip: 'VIP',
    exclusiveAnalysis: 'Analisi Esclusiva',
    analysisDiscussions: "Discussioni sull'Analisi",
    tradingSignals: 'Segnali di Trading',
    tradingSignalRequest: 'Richiesta di Segnale di Trading',
    privateAnalysisRequest: 'Richiesta di Analisi Privata',
    prioritySupport: 'Supporto Prioritario',
    minimumFundingRequirement:
      'Requisito minimo di finanziamento e commercio di $200.000 di valore nominale su operazioni chiuse ogni mese*',
    tnc1: "Si prega di notare che le informazioni presentate su <a href=\"{url}\" target=\"_blank\">portal.hmctrader.com</a> sono fornite a Moneta ('Moneta Global Limited', 'noi') da un fornitore terzo ('HMC Trader'). Esse sono destinate esclusivamente a scopi di ricerca e informativi e non costituiscono alcun consiglio finanziario per acquistare, vendere o detenere un particolare prodotto o perseguire una particolare strategia di investimento. Le informazioni non sono adattate alle esigenze di investimento di una persona specifica e quindi non comportano la considerazione di alcuno degli obiettivi di investimento, della situazione finanziaria o delle esigenze di qualsiasi spettatore che possa riceverlo. Si prega inoltre di notare che le prestazioni passate non sono un indicatore affidabile delle prestazioni future e/o dei risultati. I risultati effettivi possono differire notevolmente da quelli previsti nelle dichiarazioni di rendimento previsionali o passate. Non assumiamo alcuna responsabilità per l'accuratezza o l'integralità di qualsiasi delle informazioni fornite qui, né per alcuna perdita che possa derivare da qualsiasi informazione fornita da HMC Trader.",
    tnc2: "*Per essere idoneo a utilizzare il servizio HMC Live Trading Room, devi soddisfare il requisito di finanziamento iniziale minimo indicato come sopra dalla data di lancio. Dovrai completare almeno $200.000 di valore nominale su operazioni chiuse di prodotti offerti da Moneta ogni mese per mantenere l'autorizzazione all'accesso su HMC Live Trading Room. Il tuo permesso di accesso sarà disabilitato automaticamente se non riesci a completare almeno $200.000 di valore nominale su operazioni chiuse in un mese. Sei tenuto a depositare ulteriormente l'importo di finanziamento richiesto per riattivare l'accesso su HMC Live Trading Room.",
  },
  supportTickets: {
    support: 'Supporto',
    mySupportTickets: 'I Miei Ticket di Supporto',
    createNewTicket: 'Crea Nuovo Biglietto',
    ticketNo: 'Numero del Biglietto.',
    subject: 'Oggetto',
    status: 'Stato',
    lastUpdated: 'Ultimo Aggiornamento',
    newTicketRequest: 'Crea una nuova richiesta di biglietto',
    content: 'Contenuto',
    attachments: 'Allegati',
    upload: 'Carica',
    viewingTicket: 'Visualizzazione Biglietto N.',
    postReply: 'Pubblica una risposta',
    update: 'Aggiornamento',
    cancel: 'Annulla',
    close: 'Chiudi',
    number: 'No.',
    description: 'Descrizione',
    postReplyRequired: 'È richiesto il campo di risposta al post',
    contentFieldRequired: 'È richiesto il campo contenuto',
    subjectFieldRequired: 'Il campo oggetto è obbligatorio',
    successMsg: 'Il tuo ticket di supporto è stato creato con successo!',
  },
  responseMsg: {
    410: 'La convalida del parametro non è riuscita',
    411: 'Password errata',
    420: "L'utente non esiste",
    421: 'Reset della password negato',
    422: 'Errore di registrazione!',
    427: 'La tua password è scaduta. Si prega di reimpostare la password per procedere.',
    428: 'Non corrisponde con la nuova password',
    429: 'Si prega di cambiare con una nuova password',
    490: 'Si prega di non inviare informazioni duplicate della carta',
    500: 'Si è verificato un errore.',
    501: "L'applicazione non ha funzionato. Si prega di riprovare più tardi.",
    505: 'Caricamento del file non riuscito',
    510: 'Codice SMS errato',
    511: 'Impossibile inviare il codice SMS al tuo telefono',
    520: 'Accesso non riuscito. Per favore riprova.',
    521: 'Un utente è già connesso su questo dispositivo!',
    522: 'Per favore, accedi di nuovo',
    523: 'Per favore, accedi di nuovo',
    524: 'Sfortunatamente il tuo indirizzo email non è stato riconosciuto',
    525: 'Nome utente o password non corretti',
    526: 'Recaptcha non corrisponde',
    527: "L'utente non è un IB",
    528: "L'utente non esiste",
    529: 'Nome utente o password non corretti',
    530: 'Sessione di accesso non valida',
    531: 'Codice non valido',
    540: 'Il tuo conto di trading non è stato trovato',
    541: 'Impossibile trovare il conto di rimborso',
    542: 'Non ti è permesso aprire un account aggiuntivo',
    544: 'Non ti è permesso richiedere un account aggiuntivo fino a quando la tua prova di identità non è stata verificata',
    550: 'Numero di conto errato',
    551: 'Il tuo saldo è insufficiente',
    554: "L'importo richiesto è zero o nullo",
    562: 'Non siamo riusciti a elaborare il tuo pagamento, per favore riprova. Se il problema persiste, contattaci tramite chat dal vivo, o email {email}',
    564: 'Il paese non corrisponde al canale di pagamento',
    565: 'Codice bancario errato',
    566: 'Metodo di pagamento non trovato',
    567: 'Purtroppo questo metodo di pagamento non è attualmente disponibile. Ci scusiamo per eventuali disagi causati.',
    568: 'Questo account è stato limitato nel fare un deposito, si prega di contattare {email}',
    569: 'Il tuo nome/cognome/numero di telefono è richiesto. Se hai bisogno di assistenza, contatta {depositEmail}',
    581: 'Hai aderito con successo alla promozione. Si prega di notare che devi effettuare un deposito per poter prelevare fondi.',
    584: 'Non hai nessun rimborso applicabile',
    587: 'Importo del prelievo non valido.',
    590: 'Impossibile trovare il conto di trasferimento',
    591: 'Impossibile trovare il conto di trasferimento',
    593: 'Non siamo in grado di elaborare il trasferimento dei fondi dal conto selezionato a causa del credito presente nel tuo conto.',
    594: 'Il trasferimento di fondi da questo conto non è consentito',
    624: 'Il saldo negativo in questo account non può essere rimosso, si prega di contattare support@monetamarkets.com per ulteriori informazioni',
    625: 'La tua domanda è stata ricevuta ed è in fase di elaborazione. Se hai qualche domanda, ti preghiamo di contattare support@monetamarkets.com',
    626: 'Abbiamo scoperto che il tuo saldo è superiore a zero, per favore aggiorna la pagina e controlla di nuovo.',
    647: 'Codice di verifica non valido',
    1001: "Il tuo account non è temporaneamente disponibile, si prega di contattare il tuo responsabile dell'account",
    1102: "Impossibile attivare, l'account è stato creato come un account live o demo.",
    1103: "L'account è stato disabilitato",
    1202: 'Verifica scaduta, si prega di riprovare',
    1204: 'Si prega di provare con un altro indirizzo email',
    1208: 'I codici di verifica non possono essere inviati ripetutamente',
    1209: "Errore nell'invio del codice di verifica",
    session_timeout: 'Timeout della sessione, reindirizzamento alla pagina di accesso.',
    vload: {
      4019: 'Il tipo di voucher o il valore non sono accettati',
      4071: 'Sospetto di frode',
      4072: 'Viene utilizzato il voucher',
      4073: 'Il voucher non è attivo',
      4074: 'Il voucher è scaduto',
      4075: 'Il voucher non è stato trovato',
      4080: 'Il voucher ha un valore insufficiente',
      4444: 'Buono non valido utilizzato. Mancata corrispondenza della valuta.',
      5000: 'I dati del negozio non sono disponibili',
    },
  },
  lock: {
    premium: {
      trading: {
        tools: {
          description: 'Per sbloccare le funzionalità premium, completa la tua applicazione ora',
        },
      },
    },
  },
};
