import axios from 'axios'
import { startLoading, endAjaxLoading } from '@/util/loading'
import respHandler  from './respHandler'
import DOMPurify from 'dompurify'
import allowedHtmlTag from '@/util/allowedHtmlTag'
import qs from 'qs'
import { Message } from 'element-ui'
import store from '@/store'

const instance = axios.create()
const instanceExternal = axios.create()

const iterateData = obj => {
  Object.keys(obj).forEach(function(key) {
    if (obj[key] !== null && typeof obj[key] === 'object') {
      iterateData(obj[key])
      return
    }
    if (typeof obj[key] === 'string') {
      obj[key] = DOMPurify.sanitize(obj[key], { ALLOWED_TAGS: allowedHtmlTag })
    }
  })
}

// security
/**
 * 根据接口请求地址返回对应Operation操作的验证方法
 * @param {*} url : api Url
 * @param {*} data : 请求体内容
 * @returns {*} requiredAuths[Operation]
 */
const getRelatedAuths = (url, data) => {
  const headerValues = store.state.common.authHeaders
  const requiredAuths = store.state.common.requiredAuths
  if (url.includes('/cp/api/multiFactorAuth/totp/change/')) return requiredAuths['modify-auth-2fa']
  switch (url) {
    case '/cp/api/withdrawal/applyWithdrawal_cp_batch':
      return requiredAuths['withdraw']
    case '/cp/api/multiFactorAuth/verifyEmail':
      return requiredAuths['verify-email']
    case '/cp/api/multiFactorAuth/switch':
      if (data.authMethod == 'email') {
        return requiredAuths['login-email-close']
      } else {
        return requiredAuths['login-totp-close']
      }
    case '/cp/api/multiFactorAuth/changeEmail':
      return requiredAuths['modify-email']
    case '/cp/api/two-factor/enable':
      return requiredAuths['enable-auth-2fa']
    case '/cp/api/profile/updatePassword':
      return requiredAuths['modify-password']
    case '/api/login/to_login_register':
    case '/api/login/to_login':
      return requiredAuths['login']
  }
}


// 修改post请求数据格式
instance.interceptors.request.use(
  async config => {
    startLoading('ajax')

    // 为请求添加authHeaders
    let headerValues = store.state.common.authHeaders
    const requiredAuths = (await getRelatedAuths(config.url, config.data)) || []
    headerValues = {
      etxId: requiredAuths.filter(item => item == 'email').length > 0 ? headerValues.etxId : '',
      eCode: requiredAuths.filter(item => item == 'email').length > 0 ? headerValues.eCode : '',
      tCode: requiredAuths.filter(item => item == 'totp').length > 0 ? headerValues.tCode : '',
      ptxId: headerValues.ptxId,
      pCode: headerValues.pCode,
    }
    config.headers['Multi-Auth-Validate'] = [
      headerValues.etxId,
      headerValues.eCode,
      headerValues.tCode,
      headerValues.ptxId,
      headerValues.pCode,
    ]

    config.withCredentials = true

    if (config.method === 'post') {
      const data = config.data

      if (typeof data === 'string') {
        const parseData = qs.parse(data)
        iterateData(parseData)
        config.data = DOMPurify.sanitize(qs.stringify(parseData), { ALLOWED_TAGS: allowedHtmlTag })
      }

      if (typeof data === 'object') {
        iterateData(config.data)
      }
    }
    return config
  },
  function(error) {
    return Promise.reject(error)
  }
)

// 修改post请求数据格式
instance.interceptors.response.use(
  resp => respHandler(resp),
  function(error) {
    
    // 401 鉴权处理，需要正常的提示message
    if (error.response && error.response.status === 401 ) {
      return Promise.resolve(respHandler(error.response))
    }
    
    endAjaxLoading()
    
    return Promise.reject(error)
  }
)

export { instance, instanceExternal }
