export default {
  async actionSetPayment({ commit, state }, payment) {
    commit('setPayment', payment)
  },
  async actionSetCurrentPayment({ commit, state }, payment) {
    commit('setCurrentPayment', payment)
  },
  async actionSetWithdrawalPayment({ commit }, payment) {
    commit('setWithdrawalPayment', payment)
  },
}
