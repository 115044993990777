<template>
  <el-form :model="form" :rules="rules" ref="ruleForm">
    <ul>
      <li>
        <SelectForm
          v-model="form.title"
          name="title"
          :label="$t('register.personalDetails.page1.userTitle')"
          :lists="filterTitleList"
          auto-complete="off"
          data-testid="userTitle"
        ></SelectForm>
      </li>
    </ul>
    <ul>
      <li>
        <InputTemplate
          v-model="form.firstName"
          :disabled="disableField"
          name="firstName"
          :label="$t('register.personalDetails.page1.firstName')"
          type="text"
          maxlength="128"
          data-testid="firstName"
        ></InputTemplate>
      </li>
      <li>
        <InputTemplate
          v-model="form.middleName"
          :disabled="disableField"
          name="middleName"
          :label="$t('register.personalDetails.page1.middleName')"
          type="text"
          maxlength="128"
          data-testid="middleName"
        ></InputTemplate>
      </li>
    </ul>
    <ul>
      <li>
        <InputTemplate
          v-model="form.lastName"
          :disabled="disableField"
          name="lastName"
          :label="$t('register.personalDetails.page1.lastName')"
          type="text"
          maxlength="128"
          data-testid="lastName"
        ></InputTemplate>
      </li>
      <li>
        <SelectForm
          v-model="form.nationalityId"
          name="nationalityId"
          :label="$t('register.personalDetails.page1.nationality')"
          :lists="nationalities"
          valueName="id"
          labelName="nationality"
          auto-complete="new-password"
          data-testid="nationality"
        ></SelectForm>
      </li>
    </ul>
    <ul>
      <li>
        <InputTemplate
          v-model="form.email"
          :disabled="disableField"
          :readonly="emailReadOnly"
          name="email"
          :label="$t('register.personalDetails.page1.email')"
          type="email"
          data-testid="email"
        ></InputTemplate>
      </li>
      <li class="phone">
        <SelectForm
          ref="phoneSelect"
          v-model="form.phoneCode"
          :lists="phoneCodes"
          name="phoneCode"
          :label="$t('register.personalDetails.page1.phoneNum')"
          valueName="phoneCode"
          labelName="phoneCode"
          data-testid="phoneCode"
        >
          <el-form-item prop="mobile">
            <el-input
              v-model="form.mobile"
              name="mobile"
              :disabled="disableField"
              auto-complete="new-password"
              id="mobile"
              data-testid="mobile"
            />
          </el-form-item>
        </SelectForm>
      </li>
    </ul>
    <ul>
      <li class="date">
        <SelectForm
          v-model="dateOfBirth.date"
          :placeholder="$t('register.personalDetails.page1.date')"
          name="dob"
          :lists="dates"
          :label="$t('register.personalDetails.page1.dob')"
          :class="{ showPlaceholder: dayPlaceholder }"
          class="dob_label"
          data-testid="dob"
        >
          <el-select
            v-model="dateOfBirth.month"
            :placeholder="$t('register.personalDetails.page1.month')"
            :disabled="disableField"
            :class="{ showPlaceholder: monthPlaceholder }"
            @focus="monthFocus"
            data-testid="month"
          >
            <el-option
              v-for="month in months"
              :key="month"
              :label="month"
              :value="month"
              :data-testid="month"
            ></el-option>
          </el-select>
          <el-select
            v-model="dateOfBirth.year"
            :placeholder="$t('register.personalDetails.page1.year')"
            :class="{ showPlaceholder: yearPlaceholder }"
            filterable
            :disabled="disableField"
            @focus="dateFocus"
            data-testid="year"
          >
            <el-option v-for="item in years" :key="item" :label="item" :value="item" :data-testid="item"></el-option>
          </el-select>
        </SelectForm>
      </li>
      <li>
        <SelectForm
          v-model="form.placeOfBirth"
          name="placeOfBirth"
          :label="$t('register.personalDetails.page1.placeOfBirth')"
          :lists="birthPlace"
          valueName="id"
          labelName="countryName"
          auto-complete="new-password"
          data-testid="placeOfBirth"
        ></SelectForm>
      </li>
    </ul>
    <ul>
      <li>
        <SelectForm
          v-model="form.idType"
          name="idType"
          :label="$t('register.personalDetails.page1.idType')"
          :lists="identificationTypes"
          data-testid="idType"
        ></SelectForm>
      </li>
      <li>
        <InputTemplate
          v-model="form.idNumber"
          :disabled="disableField"
          name="idNumber"
          :label="idTypeLabel"
          type="text"
          data-testid="idNumber"
        ></InputTemplate>
      </li>
    </ul>
    <ul>
      <li class="pd_checkbox">
        <el-form-item prop="name">
          <el-checkbox
            :label="$t('register.personalDetails.page1.referral')"
            name="type"
            v-model="referredCheck"
            :disabled="disableField"
            data-testid="checkbox"
          ></el-checkbox>
          <InputTemplate
            v-model="form.referredBy"
            :disabled="disableField"
            v-if="referredCheck"
            name="referredBy"
            type="text"
            data-testid="referredCheck"
          ></InputTemplate>
        </el-form-item>
      </li>
    </ul>
  </el-form>
</template>

<script>
import SelectForm from '@/components/register/form/select';
import userDetail from '@/mixins/register/userDetail';

export default {
  components: { SelectForm },
  mixins: [userDetail],
  data() {
    return {
      titleList: [
        { value: 'Mr', label: this.$t('register.personalDetails.page1.titleDropdown.mr') },
        { value: 'Mrs', label: this.$t('register.personalDetails.page1.titleDropdown.mrs') },
        { value: 'Ms', label: this.$t('register.personalDetails.page1.titleDropdown.ms') },
        { value: 'Miss', label: this.$t('register.personalDetails.page1.titleDropdown.miss') },
        { value: 'Dr', label: this.$t('register.personalDetails.page1.titleDropdown.dr') },
        { value: 'Prof', label: this.$t('register.personalDetails.page1.titleDropdown.prof') }
      ],
      identificationTypes: [
        { value: 1, label: this.$t('register.personalDetails.page1.idTypeDropdown.idCard') },
        { value: 2, label: this.$t('register.personalDetails.page1.idTypeDropdown.passport') },
        { value: 3, label: this.$t('register.personalDetails.page1.idTypeDropdown.driverLicense') }
      ]
    };
  },
  computed: {
    birthPlace() {
      return this.$store.state.register.birthPlace;
    },
    idTypeLabel() {
      switch (this.form.idType) {
        case 2:
          return this.$t('register.personalDetails.page1.passportNum');
        case 3:
          return this.$t('register.personalDetails.page1.driverLicenseNum');
        default:
          return this.$t('register.personalDetails.page1.idNum');
      }
    },
    filterTitleList() {
      if (this.lang === 'ru')
        return this.titleList.filter(el => {
          return ['Mr', 'Mrs', 'Dr', 'Prof'].includes(el.value);
        });
      else if (this.lang === 'ja')
        return this.titleList.filter(el => {
          return ['Mr', 'Dr', 'Prof'].includes(el.value);
        });
      else return this.titleList;
    },
    lang() {
      return this.$store.state.common.lang;
    }
  },
  watch: {
    idTypeLabel(label) {
      this.rules.idNumber[0].message = this.$t('common.formValidation.dynamicReq', {
        dynamic: this.idTypeLabel
      });
      this.$refs.ruleForm.validateField('idNumber');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/personalDetails.scss';
</style>
