import { availableLanguages } from '@/lang/availableLang'
import config from '@/config'
function getDefaultLang() {
  let defaultLang = availableLanguages.find(ele => ele == window.navigator.language.replace('-', '_'))
  if (!defaultLang) defaultLang = config.platformLang
  return defaultLang
}

export default {
  uuid: -1,
  userName: '',
  individualUserName: '',
  userType: '',
  userTypeEnum: '',
  userId: '',
  eventEmailId: '',
  eventMobileId: '',
  ibClient: false,
  loginStatus: '',
  pendingQuestionnaireCompletion: false,
  openAccountEnabled: false,
  countryCode: '',
  clientClassification: '',
  accessToken: '',
  url: '',
  lang: getDefaultLang(),
  requireAddressProof: false,
  requireIDProof: false,
  pendingAddressProofApproval: false,
  pendingIDProofApproval: false,
  keepUploadID: false,
  keepUploadPOA: false,
  isChangedLangAtLandingPage: false,
  registerStep: 1,
  isDemo: false,
  tradingToolUrl: {},
  email: '',
  idPass: false,
  regulator: null,
  failedSecondTime: false,
  twoFactorAuth: false,
  // user info
  employmentFinanceAnswers: [],
  tradingAnswers: [],
  greenIDStatus: true,
  pendingGreenIDStatus: false,
  isLogin: true,
  autoNotifyStatus: true,
  emailMasked: '',
  // security
  checkedAuthStatus: [], // 已验证过的
  authHeaders: {
    etxId: '',
    eCode: '',
    ptxId: '',
    pCode: '',
    tCode: '',
  },
  requiredAuths: {}, // 不同规则对应所需的验证方式
  loginCredentials: {},
  beforeLoginCountry: '', // 1201 1202 根据国家显示验证器提示语
  extras:{
    logoutStacktrace: false
  }
}
