export default {
  common: {
    liveChat: { desc: "<li><p>Besoin d'aide ?</p><span>Chat en direct</span></li>" },
    country: { international: 'International', australia: 'Australie', uk: 'Royaume-Uni' },
    verification: { toSlide: 'Faites glisser pour compléter le puzzle' },
    hover: {
      BankName: "Le nom de la banque ou de l'institution financière qui reçoit les fonds.",
      BankBeneficiaryName:
        'Le nom de la personne ou de la société qui détient le compte bancaire qui reçoit les fonds.',
      AccountHoldersAddress:
        "L'adresse physique de la personne ou de la société qui détient le compte bancaire.",
      BankAddress:
        "L'adresse physique de la banque ou de l'institution financière qui reçoit les fonds.",
      BankAccountNumber:
        'Le numéro de compte bancaire ou IBAN (Numéro de compte bancaire international) est le numéro utilisé pour identifier votre compte bancaire.',
      SwiftCode:
        "Le code SWIFT, également connu sous le nom de Bank Identifier Code (BIC), est un code international utilisé pour identifier le pays, la banque et la succursale, et il est requis lorsque de l'argent est transféré à l'international.",
    },
    field: {
      nameOnCard: 'Nom figurant sur la carte',
      first4Digits: '4 premiers chiffres sur la carte',
      last3Digits: '3 derniers chiffres sur la carte',
      first6Digits: '6 premiers chiffres sur la carte',
      last4Digits: '4 derniers chiffres sur la carte',
      bankName: 'Nom de la banque',
      bankCardNumber: 'Numéro de la carte bancaire',
      bankAddress: 'Adresse de la banque',
      branchAddress: 'Adresse de la succursale',
      bankBranch: 'Succursale de la banque',
      cardHolderName: 'Nom du titulaire de la carte',
      reservedMobileNumber: 'Numéro de téléphone mobile pour la banque',
      nationalId: "Carte nationale d'identité",
      bankCity: 'Ville de la banque',
      bankProvince: 'Province de la banque',
      bankCode: 'Code de la banque',
      bankBranchCode: 'Code de la succursale',
      bankBeneficiaryName: 'Nom du bénéficiaire à la banque',
      bankBeneAccName: 'Nom du compte du bénéficiaire à la banque',
      bankBeneAccNum: 'Numéro du compte du bénéficiaire à la banque',
      bankBeneAddress: 'Adresse du bénéficiaire',
      bankAccNum: 'Numéro du compte bancaire',
      bankAccName: 'Nom du compte bancaire',
      bankHolderAddress: 'Adresse du titulaire du compte',
      address: 'Adresse',
      accName: 'Nom du compte',
      accNum: 'Numéro du compte',
      accNumTo: 'Numéro du compte',
      bsbNum: 'Numéro BSB',
      swift: 'Code SWIFT',
      region: 'Région',
      sort: 'Code guichet',
      ifscCode: 'Code IFSC',
      swiftNonAUD: 'Code SWIFT (Comptes non-AUD)',
      amt: 'Montant',
      amtUSD: 'Montant(USD)',
      imptNotes: 'Notes importantes',
      yes: 'Oui',
      no: 'Non',
      payMethod: 'Méthode de paiement:',
      expDate: "Date d'expiration",
      bsb: 'BSB',
      abaSortCode: 'Code ABA/guichet:',
      country_region: 'Pays / Région',
      currLeverage: 'Levier actuel',
      accEquity: 'Capitaux propres du compte',
      newLeverage: 'Levier souhaité',
      currPw: 'Mot de passe actuel',
      newPw: 'Nouveau mot de passe',
      confirmPw: 'Confirmer le nouveau mot de passe',
      loginEmail: 'E-mail de connexion',
      email: 'E-mail',
      pw: 'Mot de passe',
      accType: 'Type de compte',
      name: 'Nom',
      emailAdd: 'Adresse e-mail',
      phone: 'Numéro de téléphone',
      countryCode: 'Pays',
      dob: 'Date de naissance',
      nat: 'Nationalité',
      country: 'Pays de résidence',
      street: 'Numéro/Nom de la rue',
      suburbOrCity: 'Banlieue/Ville',
      provinceOrState: 'Province/État',
      postcode: 'Code postal',
      empStat: 'Statut professionnel',
      occupation: 'Profession',
      annIncome: 'Revenu annuel',
      save: 'Épargne/Investissement',
      source: 'Source de revenus',
      industry: 'Industrie',
      statusDef: 'DÉFINITION DES STATUTS',
      month: 'Mois',
      year: 'Année',
      select: 'Sélectionner',
      completed: 'Complet',
      incomplete: 'Incomplet',
      submitted: 'Soumis',
      successful: 'Réussi',
      processing: 'En cours',
      cancelled: 'Annulé',
      failed: 'Échec',
      rejected: 'Rejeté',
      upload: 'Télécharger',
      ccNum: 'Numéro de carte de crédit',
      cardPhoto: 'Carte avec photo (recto)',
      notes: 'Notes',
      bankAccOrIBAN: 'Numéro du compte bancaire/IBAN',
      selectWithdrawCard: 'Sélectionnez votre carte de retrait',
      updateCardPhoto: 'veuillez télécharger votre carte avec photo (recto)',
      transitNumber: 'Numéro de transit',
      institutionNumber: "Numéro de l'institution",
      wallet: 'Portefeuille',
      resetBalTo: 'Réinitialiser le solde à',
      confirmation: 'Êtes-vous sûr ?',
      removeNote:
        'Voulez-vous vraiment supprimer ces données ? Cette action ne peut pas être annulée.',
      depositCurrency: 'Devise de dépôt',
      bankAccountCurrency: 'Devise du compte en banque',
      ipChangeWarning:
        'Vous recevrez un e-mail d’alerte si une personne se connecte à votre compte depuis une localisation différente.',
      documentType: 'Type de document',
      documentDetails: 'Détails du document',
      pixKeyType: 'Type de clé PIX',
      pixKey: 'Clé PIX',
      enterVerificationCode: 'Saisissez le code de vérification',
      accDigit: 'Chiffre du compte',
      docID: 'ID du document',
      paymentAccName: '{name} Nom du compte',
      paymentAccNum: '{name} Numéro du compte',
      beneficiaryIBANNumber: 'Numéro IBAN du compte du bénéficiaire',
      beneficiaryBankSwift: 'Code SWIFT de la banque du bénéficiaire',
      beneficiaryBankName: 'Nom de la banque du bénéficiaire',
      beneficiaryBankAddress: 'Adresse de la banque du bénéficiaire',
      beneficiaryBankSortCode: 'Code de tri de la banque du bénéficiaire',
      beneAccNum: 'Numéro de compte du bénéficiaire',
      reversedfailed: 'Échec inversé',
      cardNumber: 'Numéro de carte',
      walletNumber: 'Numéro de portefeuille {name}',
      beneficiaryName: 'Nom du bénéficiaire',
    },
    column: {
      date: 'DATE',
      type: 'TYPE',
      server: 'Serveur',
      status: 'ÉTAT',
      comment: 'COMMENTAIRE',
      accNum: 'NUMÉRO DU COMPTE',
      currency: 'DEVISE',
      equity: 'CAPITAUX PROPRES',
      credits: 'CRÉDITS',
      balance: 'ÉQUILIBRE',
      leverage: 'LEVIER',
      resetMT4PW: 'RÉINITIALISER LE MOT DE PASSE MT4',
      resetMT5PW: 'RÉINITIALISER LE MOT DE PASSE MT5',
      tradingAcc: 'COMPTE DE TRADING',
      method: 'MÉTHODE',
      amt: 'MONTANT',
      procNote: 'NOTES SUR LE TRAITEMENT',
      number: 'NUMÉRO DE LA CARTE',
      resetBal: 'Réinitialiser le solde',
      remove: 'Effacer',
    },
    key: { from: 'DE', to: 'À' },
    fund: {
      must: 'DOIT',
      infoHover:
        'Le nom indiqué sur votre carte {must} correspondre au nom associé à votre compte de trading',
      ccUploadReminder:
        'Veuillez télécharger une photo du recto de votre carte aux fins de vérification. (Veillez à couvrir les 6 chiffres centraux)',
    },
    button: {
      bkToHm: "Retour à la page d'accueil",
      userLogin: 'CONNEXION PAR E-MAIL',
      goToIB: 'ACCÉDER au portail IB',
      logout: 'DÉCONNEXION',
      submit: 'SOUMETTRE',
      selectFile: 'Sélectionner un fichier',
      depositFunds: 'DÉPOSER DES FONDS',
      withdrawFunds: 'RETIRER DES FONDS',
      ibPortal: 'PORTAIL IB',
      download: 'TÉLÉCHARGEMENTS',
      changeLeverage: "CHANGER L'EFFET DE LEVIER",
      cancel: 'ANNULER',
      changePw: 'CHANGER DE MOT DE PASSE',
      forgetPw: 'MOT DE PASSE OUBLIÉ',
      confirm: 'CONFIRMER',
      login: 'CONNEXION',
      forgotPw: 'Mot de passe oublié?',
      completeQuestionnaire: 'REMPLIR LE QUESTIONNAIRE',
      identityProof: 'REMPLIR LA DEMANDE',
      upload: 'TÉLÉCHARGER',
      continue: 'Continuer',
      enable: 'ACTIVER',
      disable: 'DÉSACTIVER',
      export: 'EXPORTER',
      ok: 'OK',
      hide: 'MASQUER',
      unhide: 'AFFICHER',
      showAll: 'TOUT AFFICHER',
      beginner: 'Débutant',
      intermediate: 'Intermédiaire',
      advanced: 'Avancé',
      sendCode: 'Envoyer le code',
      resendCode: 'Renvoyer le code',
    },
    formValidation: {
      common: 'Ce champ est requis.',
      accReq: 'Le numéro de compte est requis',
      amtReq: 'Le montant est requis',
      ccReq: 'Le numéro de la carte de crédit est requis',
      cardReq: 'Le numéro de carte est requis',
      filePhoto: 'La photo de la carte de crédit est requise',
      fileReceipt: 'Veuillez télécharger un reçu',
      filePhotoReq: 'La photo de la carte est requise',
      noteMoreThan: 'Remarque: la limite de longueur est de {limit} caractères',
      noteLessThan: 'Remarque: la limite de longueur est de {limit} caractères',
      bankCardReq: 'Veuillez sélectionner une carte',
      phoneNumberReq: 'Le numéro de téléphone portable pour la banque est requis',
      nationaIdReq: "Une pièce d'identité nationale est requise",
      numberReq: 'Veuillez entrer le numéro',
      alphanumericReq: 'Veuillez saisir alphanumérique',
      idTypeReq: "Veuillez choisir un type d'identification",
      dobReq: 'Veuillez choisir votre date de naissance',
      pobReq: 'Veuillez choisir votre lieu de naissance',
      nationalityReq: 'Veuillez choisir votre nationalité',
      titleReq: 'Veuillez choisir votre titre',
      firstNameReq: "Entrez votre prénom s'il vous plait",
      lastNameReq: 'Veuillez entrer votre nom de famille',
      emailReq: 'Veuillez saisir votre adresse e-mail',
      addressReq: 'Veuillez entrer votre adresse',
      suburbReq: 'Veuillez entrer votre ville ou banlieue',
      stateReq: 'État ou province requis',
      postcodeReq: 'Veuillez saisir votre code postal',
      countryReq: 'Veuillez sélectionner votre pays de résidence',
      NIReq: "Veuillez saisir votre numéro d'assurance nationale (NI)",
      taxResidencyReq: 'Veuillez sélectionner votre (vos) pays de résidence fiscale',
      yearsAtAddressReq: "Veuillez entrer votre nombre d'années à l'adresse",
      bankCodeReq: 'Veuillez saisir la banque émettrice de la carte',
      bankBranchCodeReq: 'Le code de la succursale est requis',
      dynamicReq: '{dynamic} est requis',
      mobReq: 'Veuillez entrer numéro de téléphone',
      confirmed: 'Veuillez confirmer',
      cardOnNameReq: 'Le nom indiqué sur la carte est requis',
      digits16: 'Veuillez entrer 16 chiffres',
      digits3or4: 'Veuillez entrer 3 ou 4 chiffres',
      digitsFirst6: 'Veuillez entrer les 6 premiers chiffres',
      digitsLast4: 'Veuillez entrer les 4 derniers chiffres',
      digitsLast3: 'Veuillez entrer les 3 derniers chiffres',
      mid6Digits: 'Veuillez entrer les {number} chiffres centraux',
      expDateReq: "La date d'expiration est requise",
      cvvReq: 'Le code de sécurité CVV est requis',
      file: 'Veuillez télécharger un fichier',
      amtLarger:
        'Le montant ne peut pas être nul et doit être supérieur ou égal à ${minLimit} {currency}',
      withdrawAmtLarger:
        "Le montant minimum de retrait est de ${minLimit} {currency} ou l'équivalent.",
      amt0: 'Le montant ne peut pas être égal à 0',
      amtLess: 'Le montant de votre dépôt ne peut pas être supérieur à ${maxLimit} {currency}',
      note256: 'Notez que la longueur maximum est de 256 caractères',
      emailFormat: 'Veuillez entrer une adresse e-mail correcte',
      payMethodReq: 'La méthode de paiement est requise',
      expReq: "La date d'expiration est requise",
      bankNameReq: 'Le nom de la banque est requis',
      bankCityReq: 'La ville de la banque est requise',
      bankProvinceReq: 'La province de la banque est requise',
      bankAddressReq: "L'adresse de la banque est requise",
      bankBranchReq: 'La succursale de la banque est requise',
      bankAccNumReq: 'Le numéro du compte bancaire est requis',
      ifscCodeReq: 'Le code IFSC est requis',
      bankBeneficiaryNameReq: 'Le nom du bénéficiaire à la banque est requis',
      bankHolderAddressReq: "L'adresse du titulaire du compte est requise",
      swiftReq: 'Le code Swift est requis',
      accHolderAddress: "L'adresse du titulaire du compte est requise",
      bsbReq: 'Le BSB est requis',
      accNumIBANReq: 'Le numéro du compte bancaire/IBAN est requis',
      accNameReq: 'Le nom associé au compte bancaire est requis',
      withdrawMethodReq: 'Veuillez sélectionner une méthode de retrait',
      tnc: 'Veuillez accepter les Conditions générales.',
      currPwReq: 'Le mot de passe actuel est requis',
      currPwWrong: 'Le mot de passe actuel est incorrect',
      newPwReq: 'Un nouveau mot de passe est requis',
      newPwFormat:
        'Votre mot de passe doit contenir entre 8 et 16 caractères et une combinaison de lettres (a-z et A-Z), de chiffres (0-9) et de caractères spéciaux tels que !@#$%^&*.()',
      newPwAccFormat:
        'Votre mot de passe doit contenir entre 8 et 16 caractères et une combinaison de lettres (a-z et A-Z), de chiffres (0-9) et de caractères spéciaux tels que !@#$%^&*',
      IdenficalPw: 'Le nouveau mot de passe doit être différent du précédent',
      confirmPwReq: 'Veuillez entrer le nouveau mot de passe à nouveau',
      confirmNotMatch: 'Les deux mots de passe ne correspondent pas',
      pwReq: 'Le mot de passe est requis',
      accCurrReq: 'Vous devez sélectionner une devise pour le compte',
      tradAccReq: 'Veuillez sélectionner un compte de trading',
      answerReq: 'Veuillez sélectionner',
      resetBalAmt: 'Veuillez fixer le montant entre 0 et 1 000 000',
      verCodeReq: 'Le code de vérification est obligatoire',
      noEligibleAccounts: 'Aucun compte éligible',
      AlphanumericOnlyNotSpace: 'Uniquement alphanumérique. Les espaces ne sont pas autorisés.',
      AlphanumericOnly: 'Uniquement alphanumérique.',
      NumbersSymbol: 'Uniquement chiffres et symboles.',
      NumbersOnlyNotSpace: 'Uniquement des chiffres. Les espaces ne sont pas autorisés.',
      NumbersOnly: 'Uniquement des chiffres.',
      LettersSymbol: 'Uniquement des lettres et des symboles.',
      LettersSymbolNotSpace:
        'Uniquement des lettres et des symboles. Les espaces ne sont pas autorisés.',
      LettersOnlyNotSpace: 'Uniquement des lettres. Les espaces ne sont pas autorisés.',
      NotSpace: "L'espace n'est pas autorisé.",
      beneficiaryNameReq: 'Le nom du bénéficiaire est requis',
    },
    withdrawChannel: {
      banktransferbpaypolipay: 'Virement bancaire/BPay/POLiPay',
      banktransfer: 'Virement bancaire international',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'Virement UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Carte de crédit',
      archiveThisCard: "Veuillez archiver cette carte si elle n'est plus valide ou utilisée",
      update: 'Actualiser',
      archive: 'Archiver',
      updateExpiryMsg:
        "Veuillez actualiser la date d'expiration de votre carte afin de pouvoir utiliser votre carte pour le retrait",
      available: 'Disponible',
      pending: 'En attente',
      unavailable: 'Indisponible',
      fullyRefunded: 'Entièrement remboursé',
      requiredDocument: "Documents nécessaires à l'archivage de la carte",
      officialLetter:
        "Lettre / e-mail officiel de la banque indiquant que la carte n'est plus disponible",
      bankStatement: 'Relevé bancaire avec les transactions effectuées avec cette carte',
      expiredCard: 'Cartes expirées / indisponibles',
      confirmArchive: 'Êtes-vous sûr de vouloir archiver cette carte ?',
      confirmUpdate:
        "Êtes-vous sûr de vouloir actualiser la date d'expiration de cette carte ? Remarque : Moneta ne sera pas tenu responsable de l'échec du retrait en raison de la date d'expiration incorrecte que vous avez fournie.",
      bpay: 'Bpay',
      creditdebitcard: 'Carte de crédit/débit',
      bankwiretransferinternational: 'Virement bancaire (International)',
      banktransferaustralia: 'Virement bancaire (Australie)',
      banktransferinternational: 'Virement bancaire international',
      banktransferchina: 'Virement bancaire (International)',
      banktransferuk: 'Virement bancaire (Royaume-Uni)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Virement de courtier à courtier',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Banque virtuelle (Thaïlande)',
      internetbankingnigeria: 'Banque virtuelle (Nigeria)',
      internetbankingvietnam: 'Banque virtuelle (Vietnam)',
      internetbankingmalaysia: 'Banque virtuelle (Malaisie)',
      internetbankingindonesia: 'Banque virtuelle (Indonésie)',
      internetbankingkorea: 'Banque virtuelle (Corée du Sud)',
      internetbankingsouthkorea: 'Banque virtuelle (Corée du Sud)',
      internetbankingindia: 'Banque virtuelle (Inde)',
      internetbankingphilippine: 'Banque virtuelle (Philippines)',
      internetbankingsouthafrica: 'Banque virtuelle (Afrique du Sud)',
      internetbankinguk: 'Virement bancaire express Royaume-Uni',
      internetbankinglaos: 'Banque virtuelle (Laos)',
      internetbankinghk: 'Banque en ligne (Hong Kong)',
      internetbankinguganda: 'Banque virtuelle (Ouganda)',
      internetbankingrwanda: 'Banque virtuelle (Rwanda)',
      internetbankingzambia: 'Banque virtuelle (Zambie)',
      internetbankingcongo: 'Banque virtuelle (Congolais)',
      internetbankingcameroon: 'Banque virtuelle (Cameroun)',
      internetbankingburundi: 'Banque virtuelle (Burundi)',
      internetbankingkenya: 'Banque virtuelle (Kenya)',
      internetbankingghana: 'Banque virtuelle (Ghana)',
      internetbankingtanzania: 'Banque virtuelle (Tanzanie)',
      internetbankingbrazil: 'Banque virtuelle (Brésil)',
      internetbankingmexico: 'Banque virtuelle (Mexique)',
      internetbankingcolombia: 'Banque virtuelle (Colombie)',
      internetbankingchile: 'Banque virtuelle (Chili)',
      internetbankingperu: 'Banque virtuelle (Pérou)',
      internetbankingusd: 'Banque en ligne (USD)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Virement bancaire Vietnam',
      nigeriabanktransfer: 'Virement bancaire Nigeria',
      polipayment: 'Paiement POLi',
      bankwiretransferaustralia: 'Virement bancaire (Australie)',
      thailandinstantbankwiretransfer: 'Virement bancaire express Thaïlande',
      nigeriainstantbankwiretransfer: 'Virement bancaire express Nigérian',
      vietnaminstantbankwiretransfer: 'Virement bancaire express vietnamien',
      malaysiainstantbankwiretransfer: 'Virement bancaire express malaisien',
      indonesiainstantbankwiretransfer: 'Virement bancaire express Indonesia',
      indiainstantbanktransfer: 'Virement bancaire instantané en Inde',
      laosinstantbankwiretransfer: 'Virement bancaire instantané au laos',
      southafricainstantbankwiretransfer: 'Virement bancaire express Afrique du Sud',
      philippinesinstantbankwiretransfer: 'Virement bancaire instantané aux Philippines',
      indialocalbankwiretransfer: 'Virement bancaire local en Inde',
      hongkongbanktransfer: 'Virement bancaire local à Hong Kong',
      ugandabanktransfer: 'Virement bancaire local en Ouganda',
      rwandabanktransfer: 'Virement bancaire local au Rwanda',
      zambiabanktransfer: 'Virement bancaire local en Zambie',
      congobanktransfer: 'Virement bancaire local au Congo',
      cameroonbanktransfer: 'Virement bancaire local au Cameroun',
      burundibanktransfer: 'Virement bancaire local au Burundi',
      kenyabanktransfer: 'Virement bancaire local au Kenya',
      ghanabanktransfer: 'Virement bancaire local au Ghana',
      tanzaniabanktransfer: 'Virement bancaire local en Tanzanie',
      brazilbanktransfer: ' Virement bancaire au Brésil',
      mexicobanktransfer: 'Virement bancaire local au Mexique',
      pagsmileBrazil: 'Virement bancaire express Brésil',
      pagsmileMexico: 'Virement bancaire express Mexico',
      pagsmileColombia: 'Virement bancaire express Colombia',
      pagsmileChile: 'Virement bancaire express Chile',
      pagsmilePeru: 'Virement bancaire express Peru',
      banktransferbpaypolipayaustralia: 'Virement bancaire/BPay/POLiPay (Australie)',
      banktransferbpaypolipayinternational: 'Virement bancaire/BPay/POLiPay (International)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Virement bancaire local canadien',
      cryptobitcoin: 'Crypto-monnaie-Bitcoin',
      cryptotetheromni: 'Crypto-monnaie-USDT(OMNI)',
      cryptotethererc20: 'Crypto-monnaie-USDT(ERC20)',
      cryptotethertrc20: 'Crypto-monnaie-USDT(TRC20)',
      cryptocurrencybitcoin: 'Crypto-monnaie-Bitcoin',
      cryptocurrencyusdt: 'Crypto-monnaie-USDT',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      trustly: 'Trustly',
      astropay: 'Astropay',
      webmoney: 'WebMoney',
      vload: 'Vload',
      dasshpewallet: 'Portefeuilles indiens',
      cryptocurrencyeth: 'Crypto-monnaie-ETH',
      cryptocurrencyethcps: 'Crypto-monnaie-ETH',
      cryptocurrencyusdc: 'Crypto-monnaie-USDC(ERC-20)',
      cryptocurrencyusdcerc20: 'Crypto-monnaie-USDC(ERC-20)',
      cryptocurrencyusdccps: 'Crypto-monnaie-USDC(ERC-20)',
      chileBankTransfer: 'Virement bancaire au Chili',
      colombiaBankTransfer: 'Virement bancaire en Colombie',
      perubanktransfer: 'Virement bancaire au Pérou',
      alipay: 'Alipay',
    },
  },
  home: {
    pendingTooltip:
      'Une fois votre compte réel approuvé, vous recevrez un e-mail contenant vos identifiants de connexion à MT{number} afin de pouvoir commencer à trader ! ',
    leverage: {
      tncTitle: '<span>Conditions générales</span>',
      tnc: "<p>En soumettant la présente demande, je reconnais avoir été informé qu'un haut effet de levier comporte une large part de risque pour mon capital et qu'il est possible que je subisse une perte supérieure et non limitée au capital que j'ai déposé.<br><br>J'accepte le droit de l'émetteur des produits à vérifier mes activités de trading et à ajuster les niveaux de levier sur mon compte de trading, à sa discrétion et sans avertissement ou notification préalable.<br><br>Je comprends qu'en choisissant un haut effet de levier, je peux trader avec une plus grande marge, mais en subissant potentiellement des pertes plus importantes.</p>",
      tncConfirm: "J'ai lu et j'accepte les conditions générales.",
      changeLeverageConfirm:
        "Votre demande a été soumise et sera traitée dans les meilleurs délais par notre équipe d'assistance.",
      changeLeverageAutoComplete: 'Votre demande de changement de levier est terminée',
      changeLeverageReject: "La demande de modification de l'effet de levier a été rejetée",
      changeLeverageFail: 'Leverage change request is failed, please try later',
      changeLeverageDefault:
        "Une erreur s'est produite, veuillez contacter {supportEmail} pour finaliser votre candidature",
      alreadyApplied:
        "Vous avez déjà demandé à changer le levier sur votre compte et cette demande est actuellement étudiée par l'équipe d'assistance de {platform}. Veuillez attendre la finalisation de cette demande avant d'en soumettre une nouvelle.",
      reduceLeverageWarn:
        "Avertissement : réduire votre effet de levier peut entraîner la fermeture forcée de vos positions ouvertes si votre compte ne disose pas d'une marge suffisante. ",
      reduceLeverageConfirm: 'Voulez-vous continuer?',
      autoRestore:
        "<p>Dès lors que votre capital >= 20 000, votre effet de levier disponible est de 500:1</p><br /><p>Lorsque votre capital sera de nouveau inférieur à 20 000, vous pourrez réajuster l'effet de levier à 1 000:1</p>",
      oneThousandRatio:
        "<p>Veuillez vous référer aux conditions ci-dessous si vous souhaitez passer à un effet de levier de 1 000:1 :<br><br>1. Vous ne pouvez pas demander des promotions en lien avec le crédit<br><br>2. Le capital de votre compte ne peut pas dépasser 15 000 USD ou l'équivalent au moment de la demande <br><br>3. L'effet de levier du compte passera à 500:1 si le capital de votre compte dépasse 20 000 USD ou un montant équivalent<br><br>4. Les limitations de l'effet de levier peuvent varier selon les réglementations applicables<br><br>5. Le changement d'effet de levier ne s'applique pas aux produits à effet de levier fixe <br><br> 6. Conformément au contrat client, des mesures comprenant, mais sans s'y limiter, la résiliation ou la déduction seront prises si vous violez les clauses relatives aux événements par défaut et/ou aux activités de trading suspectes<br><br></p>",
    },
    pw: {
      error: 'Échec de réinitialisation de votre mot de passe. Veuillez réessayer plus tard',
      confirm:
        'Votre mot de passe a été mis à jour.<br>Vous pouvez maintenant utiliser votre nouveau mot de passe pour accéder à votre compte de trading MT4/MT5.',
      email:
        '<p class=""first_info"">Nous venons de vous envoyer un e-mail contenant toutes les instructions nécessaires pour réinitialiser votre mot de passe.</p><p>Vérifiez le dossier de courrier indésirable de votre messagerie.</p>',
    },
    activate: {
      question: 'Voulez-vous réactiver votre compte en direct ?',
      confirm:
        "Nous avons bien reçu votre demande d'activation.<br>Votre compte sera activé dans les meilleurs délais.",
    },
    accStatus: {
      active: 'Actif',
      rejected: 'Rejeté',
      inactive: 'Inactif',
      processing: 'En cours',
      activate: 'Activer',
      expired: 'Expirée',
    },
    appNote: { desc02: 'Cliquez ici pour en savoir plus.' },
    app: {
      title: 'Comment ça marche',
      desc01: 'Téléchargez {platform}',
      desc02: 'Connexion avec votre e-mail',
      desc03: 'Vérifiez votre numéro de téléphone',
      desc04: 'Vous êtes prêt. Profitez de la puissance du trading',
    },
    reset: {
      success: 'Votre réinitialisation a réussi.',
      creditTip:
        'Le solde de votre compte est inférieur à 0. En remettant le solde de votre compte à 0, vous remettez également votre crédit à 0. Êtes-vous sûr de vouloir continuer ?',
      resetBtn: 'Réinitialiser',
      tableHeader: 'Solde négatif',
    },
  },
  oneThousandRatioError: {
    error1:
      'Ce compte comporte un bonus et il ne peut pas être ajusté à un effet de levier de 1 000:1',
    error4:
      "Le capital du compte est supérieur à 15 000 $ (ou l'équivalent en devise) et il ne peut pas être ajusté à un effet de levier de 1 000:1",
    error5: 'The ID audit is not pass, cannot be adjusted to 1000:1 leverage',
    error6:
      "Le compte participe à l'activité bonus et il ne peut pas être ajusté à un effet de levier de 1 000:1",
  },
  paymentDetails: {
    subHeader: 'AJOUTER UNE MÉTHODE DE PAIEMENT',
    payMethod: 'Méthode de paiement',
    tip: "<p>Sélectionnez votre méthode de paiement favorite pour les dépôts et retraits futurs à partir du menu ci-dessous.</p><br />\n    <p><b>Remarque :</b> pour garantir le traitement rapide de vos demandes de dépôt et de retrait, la réglementation AML/CTF (Lutte contre le blanchiment d'argent/Lutte contre le financement du terrorisme) exige que le nom indiqué pour la méthode de paiement choisie soit<b> {individualUserName}</b>.</p>",
    result: {
      defaultSuccessMessage:
        "Merci d'avoir soumis la méthode de financement pour votre compte de trading. Nous traitons actuellement votre demande et nous vous contacterons sous peu.",
      unionPayCardSuccessMessage:
        'Les détails de la carte Union Pay ont été soumis avec succès. Vos nouvelles coordonnées bancaires seront disponibles après approbation',
    },
    history: { header: 'MÉTHODES DE PAIEMENT' },
    cc: {
      field: {
        ccNum: 'Numéro de carte de crédit',
        first6: 'Les six premiers',
        last4: 'Les quatre derniers',
        withrawlable: 'Le retrait maximal disponible pour cette carte est {amount}.',
      },
      formValidation: { upload: 'Photo de la carte de crédit requise' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'Récupération du statut de votre dépôt... {countDown} secondes restantes.',
      successMsg:
        'Merci pour votre dépôt. Vos fonds apparaîtront sur votre compte dans les meilleurs délais.',
      rejectMsg: 'Rejeté',
      failureMsg: 'Votre dépôt a échoué. Veuillez réessayer.',
      message:
        "Merci d'avoir approvisionné votre compte. Votre dépôt sera approuvé manuellement par l'équipe en charge des comptes de {platform} dans les meilleurs délais. Les fonds seront déposés sur votre compte après réception de l'approbation",
      defaultException:
        "Pour confirmer votre dépôt auprès de {platform}, veuillez contacter l'assistance {email}",
    },
  },
  deposit: {
    default: {
      deposit: 'Dépôt',
      reference: 'Vos références',
      sendReceipt: 'Envoyer un reçu',
      sendReceiptTip:
        "Une fois le virement effectué, veuillez compléter le formulaire ci-dessous et joindre une capture d'écran, une photo ou une copie numérisée du reçu du virement bancaire montrant clairement votre nom complet, le numéro de votre compte et le montant du dépôt afin de nous permettre de traiter votre transaction.",
      reminder:
        'Comptez un délai de 1 à 2 jours ouvrables pour que les fonds soient crédités sur votre compte',
      successMsg:
        'Les fonds apparaîtront sur votre compte de trading lorsque nous aurons reçu le dépôt et vérifié votre reçu de transaction.',
      alt: "Vous pouvez également envoyer par e-mail votre reçu de transaction à l'adresse {mailTo} dans les meilleurs délais",
      reminderFundAppear:
        'Vos fonds apparaîtront sur votre compte dès que nous aurons reçu les fonds de votre précédent courtier.',
      failed: 'Échec du dépôt. Veuillez réessayer plus tard.',
      fee: 'FRAIS',
      instant: 'EXPRESS',
      hour: 'HEURE',
      hours: 'HEURES',
      businessDays: 'JOURS OUVRABLES',
      disclaimer:
        "<li>1. {platform} n'accepte strictement aucun paiement de tiers. Tous les fonds déposés sur votre compte de trading doivent être effectués sous le même nom que celui associé à votre compte de trading {platform}.</li>\n      <li> </br>2. Les clients reconnaissent qu'il peut leur être demandé de fournir des informations sur la/les source(s) du/des dépôt(s), sur leur fortune, ainsi que toute autre information que {platform} peut juger nécessaire pour identifier la source des fonds lors du traitement d'une demande de dépôt. Ils acceptent de communiquer toutes les informations demandées par {platform}. Tout défaut de communication des informations demandées peut entraîner des délais importants dans le traitement de vos demandes. {platform} ne sera pas responsable des éventuels délais ou pertes encourus en raison de ce délai.</li>\n      <li> </br>3. Le client comprend que la soumission d'une demande de dépôt ne constitue aucunement l'acceptation des fonds par {platform}. Si votre compte est proche d'un appel de marge ou a déclenché l'appel de marge, veuillez vous assurer d'avoir prévu d'autres arrangements.</li>\n      <li> </br>4. {platform} ne sera pas responsable des éventuels délais/erreurs dus aux systèmes de paiements.</li>\n      <li> </br>5. {platform} ne sera pas responsable des éventuels frais que l'établissement financier émetteur ou les fournisseurs de services de paiement tiers peuvent vous facturer pour l'envoi des paiements.</li>\n      <li> </br>6. Veuillez noter que la désignation de Moneta Markets au niveau de votre système de facturation apparaîtra comme suit : <a href=\"https://www.monetamarkets.com/\" target=\"_blank\">www.monetamarkets.com</a> - Gladstonos & Filokyprou, 120, House Office E2<li>",
      channels: {
        creditOrDebit: 'Carte de crédit/débit',
        i12BankTransfer: 'Virement bancaire international',
        auBankTransfer: 'Virement bancaire Australie',
        bPay: 'Virement bancaire BPay',
        skrill: 'Skrill/Moneybookers',
        b2b: 'Virement de courtier à courtier',
        unionPayCN: 'Virement Union Pay Chine',
        vietInstant: 'Virement bancaire express Vietnam',
        thaiInstant: 'Virement bancaire express Thaïlande',
        nigeriaInstant: 'Virement bancaire express Nigéria',
        laosInstant: 'VIREMENT BANCAIRE INSTANTANÉ AU LAOS',
        malayInstant: 'Virement bancaire express Malaisie',
        indonesiaInstant: 'Virement bancaire express Indonésie',
        philippineInstant: 'Virement bancaire express Philippines',
        mobilePay: 'Mobile Pay',
        poli: 'Paiements POLi',
        paypal: 'Dépôt Paypal',
        mijipay: 'Dépôt Mijipay',
        crypto: 'Crypto-monnaie',
        tinkbit: 'Dépôt Tinkbit',
        bitwallet: 'Dépôt bitwallet',
        indiaInstant: 'Virement bancaire instantané en Inde',
        pagsmileBrazil: 'Virement bancaire express Brésil',
        pagsmileMexico: 'Mexico Instant Bank Transfer',
        pagsmileColombia: 'Colombia Instant Bank Transfer',
        pagsmileChile: 'Chile Instant Bank Transfer',
        pagsmilePeru: 'Peru Instant Bank Transfer',
        astropay: 'Dépôt Astropay',
        dasshpeWallet: 'Portefeuilles indiens',
        southkoreabanktransfer: 'Virement bancaire en Corée du Sud',
        alipay: 'Alipay',
        indonesiainstantbanktransfer: 'Virement bancaire express Indonésie',
        thailandinstantbanktransfer: 'Transfert bancaire instantané Thaïlande',
      },
      rate: {
        rate: 'Taux de change ({oldCurrency}/{newCurrency}): ',
        result: {
          rateError: 'Échec de récupération du taux de change {oldCurrency}/{newCurrency}',
          channelError: 'Échec de récupération du canal de paiement.',
        },
      },
      cn: { rate: 'Taux de change (USD/RMB): ', amt: 'RMB: ' },
      viet: {
        result: { rateError: 'Échec de récupération du taux de change USD/VND.' },
        rate: 'Taux de change (USD/VND): ',
        amt: 'VND: ',
      },
    },
    method: {
      desc: 'Comment approvisionner votre compte par {method}',
      inst1: '{number}.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2:
        '{number}.   Sélectionnez la banque sur la page de sélection de banque et connectez-vous à votre banque en ligne de {country}',
      inst3:
        '{number}.   Suivez les instructions et effectuez votre paiement via votre service bancaire en ligne/mobile',
      inst4:
        '{number}.   Vous serez redirigé vers le portail client et votre transaction sera finalisées',
      inst5: '{number}.   Connectez-vous à votre compte {method}',
      inst6:
        '{number}.   Suivez les instructions affichées via votre compte {method} pour effectuer votre paiement',
      inst7:
        'Remarque : si vous choisissez de procéder au dépôt manuel, cela peut entraîner des retards pour vos fonds et nécessiter plus de temps pour créditer votre compte de trading.',
      inst8:
        '{number}.   Veuillez noter que si vous utilisez {payment}, le montant du dépôt est limité à {amount} {currency} uniquement par transaction.',
      form: { header: 'Formulaire de {method}' },
      inst12: '{number}.   Complétez le formulaire ci-dessous et sélectionnez ‘Soumettre’',
      inst13: '{number}.   Scannez le code QR pour ouvrir votre application bancaire mobile',
      inst14: '{number}.   Suivez les invites sur votre appareil mobile pour compléter votre dépôt',
      inst15: '{number}.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst16: "{number}.   Après avoir entré vos coordonnées de carte, appuyez sur 'Soumettre'",
    },
    cpsInfo: {
      thispaymentchannelistemporaryunavailable:
        "Ce canal de paiement n'est temporairement pas disponible.",
    },
    uk: { header: 'Virement bancaire express Royaume-Uni', country: 'Royaume-Uni' },
    southAfrica: { header: 'Virement bancaire express Afrique du Sud', country: 'Afrique du Sud' },
    idn: { header: 'Transfert instantané de Bank Indonesia', country: 'indonésienne' },
    ida: { header: 'Virement bancaire express Inde', country: 'Inde' },
    brazil: { header: 'Virement bancaire express Brésil', country: 'Brésil' },
    nigeria: { header: 'Virement bancaire express Nigéria', country: 'Nigéria' },
    thailand: { header: 'Virement bancaire express Thaïlande', country: 'Thaïlande' },
    vietnam: { header: 'Virement bancaire express Vietnam', country: 'Vietnam' },
    laos: {
      header: 'VIREMENT BANCAIRE INSTANTANÉ AU LAOS',
      country: 'Laos',
      inst: '2.   Scannez le code QR avec votre appli OnePay',
    },
    hongkong: {
      country: 'Hong Kong',
      inst1: '2.   Suivez les instructions et effectuez le paiement',
      inst2: '3.   Votre transaction sera finalisée',
      inst3: 'Veuillez suivre les instructions ci-dessous pour effectuer le paiement :',
      inst4: '1.   Connectez-vous à votre compte en banque.',
      inst5: '2.   Transférez les fonds vers :',
      inst6: '3.   Après avoir effectué le paiement, cliquez sur le bouton TERMINÉ pour continuer.',
      inst8: 'Remarque :',
      inst9:
        "Ce numéro de compte généré n'est valable que pour un dépôt unique et ne doit pas être utilisé pour des transactions futures afin d'éviter les retards dans l'arrivée des fonds.",
      inst10:
        'Le montant du paiement par virement doit correspondre au montant soumis dans la demande de dépôt.',
      inst11:
        "Assurez-vous d'effectuer votre virement bancaire dans l'heure qui suit la soumission de la demande de dépôt pour éviter un échec du dépôt.",
    },
    malay: { header: 'Virement bancaire express Malaisie', country: 'Malaisie' },
    php: { header: 'Virement bancaire instantané aux Philippines', country: 'Philippines' },
    uganda: { header: 'Virement bancaire local en Ouganda', country: 'Ouganda' },
    rwanda: { header: 'Virement bancaire local au Rwanda', country: 'Rwanda' },
    zambia: { header: 'Virement bancaire local en Zambie', country: 'Zambie' },
    congo: { header: 'Virement bancaire local au Congo', country: 'Congolais' },
    cameroon: { header: 'Virement bancaire local au Cameroun', country: 'Cameroun' },
    burundi: { header: 'Virement bancaire local au Burundi', country: 'Burundi' },
    kenya: { header: 'Virement bancaire local au Kenya', country: 'Kenya' },
    ghana: { header: 'Virement bancaire local au Ghana', country: 'Ghana' },
    tanzania: { header: 'Virement bancaire local en Tanzanie', country: 'Tanzanie' },
    bankWireAu: {
      header: 'AUSTRALIA,VIREMENT BANCAIRE : AUSTRALIE',
      instruction:
        'Les fonds peuvent être transférés à {platform} via le service bancaire en ligne de notre établissement financier ou en prenant contact avec votre établissement financier et en demandant le virement vers {platform}.',
      tip: 'Sélectionnez la devise du compte dans laquelle vous souhaitez effectuer le dépôt. Veillez à sélectionner la même devise que celle configurée pour votre compte de trading',
      form: {
        bankDetails: 'Informations bancaires (Dépôt AUD uniquement)',
        refTip: 'Veuillez spécifier votre numéro de connexion ici',
        afp: 'Paiement rapide australien (Dépôt AUD national UNIQUEMENT)',
        payIdOrABNNum: 'Numéro PayID/ABN',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip:
          'Veuillez spécifier le numéro de votre compte de trading {platform} dans le champ de référence du virement bancaire.',
      },
    },
    bPay: {
      header: 'DÉPÔT BPay',
      option: 'Comment approvisionner votre compte avec BPay (Australie uniquement)',
      inst1:
        '1.   Sélectionnez votre compte MT4/MT5 pour afficher votre code de facturation et votre numéro de référence BPay',
      inst2:
        '2.   Entrez les détails du paiement, votre code de facturation et votre numéro de référence BPay dans votre service bancaire en ligne et soumettez votre paiement',
      inst3:
        '3.   Revenez sur cette page et remplissez le formulaire BPay ci-dessous pour nous permettre de traiter rapidement votre transaction',
      form: {
        header: 'FORMULAIRE BPAY',
        billerCode: 'Code de facturation BPay',
        refNum: 'Numéro de référence BPay',
        receipt: 'Reçu de virement BPay',
        reminderNotUploaded:
          "Nous avons constaté que vous n'avez pas téléchargé le reçu de votre transaction. Pour nous permettre de traiter votre dépôt, veuillez envoyer par e-mail une copie du reçu à {mailTo} dans les meilleurs délais.",
        reminderProc:
          "Remarque. Votre transaction NE POURRA PAS être traitée tant que nous n'aurons pas réceptionné votre reçu.",
      },
    },
    b2b: {
      header: 'DE COURTIER À COURTIER',
      option: "Comment virer des fonds d'un autre courtier vers {platform}",
      inst1: '1.   Téléchargez et remplissez le {download}',
      inst2:
        '2.   Remplissez le formulaire en ligne ci-dessous et téléchargez votre formulaire de virement de courtier à courtier rempli',
      inst3:
        '3.   Envoyez une copie de votre formulaire de virement de courtier à courtier rempli à votre précédent courtier pour initier le virement',
      b2bForm: 'Formulaire de virement de courtier à courtier',
      formValidation: { upload: 'Le formulaire de virement de courtier à courtier est requis' },
    },
    cc: {
      header: 'DÉPÔT PAR CARTE DE CRÉDIT OU DE DÉBIT',
      remind:
        "Si vous rencontrez des difficultés pour financer votre compte à l'aide de la méthode principale, veuillez utiliser la méthode de crédit / débit secondaire",
      field: {
        fundNow: 'Approvisionner maintenant par carte de crédit/débit',
        newCard: 'Nouvelle carte',
        cardNum: 'Numéro de carte de crédit',
        nameOnCard: 'Nom figurant sur la carte',
        expDate: "Date d'expiration",
        cvv: 'Code de sécurité CVV',
        bankCode: 'Banque émettrice de la carte',
      },
      placeholder: { bankCode: 'Veuillez saisir le nom de la banque émettrice de votre carte' },
      cvvTip: 'Les 3 chiffres inscrits au <br />dos de votre carte',
      threeDomainSecQ: 'Votre carte prend-elle en charge le dispositif 3D-Secure ?',
      threeDomainSec:
        "En quoi consiste l'authentification 3D-Secure ? <br /><strong>L'authentification 3D-Secure est une méthode de lutte contre la fraude reconnue par Visa et Mastercard.<br /><br />Si votre carte prend en charge le dispositif 3D-Secure, vos fonds seront traités immédiatement.</strong>",
      cardUpload:
        "La carte que vous utilisez pour effectuer votre dépôt de fonds n'a pas été associée à votre compte de trading. Veuillez télécharger une photo du recto de votre carte aux fins de vérification. (Veillez à couvrir les 6 chiffres centraux)",
      result: {
        success:
          'Merci. Nous vérifions actuellement les informations de votre carte. Une fois la vérification terminée, les fonds devraient apparaître sur votre compte dans un délai de 24 heures.',
        depositSuccess:
          'Nous vous remercions pour votre dépôt. Vos fonds apparaîtront très prochainement sur votre compte',
        error:
          'Afin de protéger la sécurité de votre compte,<br> vous avez atteint le maximum de tentatives de dépôt et suspendu temporairement vos dépôts.<br> Veuillez contacter {email}',
      },
      mastercardonly:
        'Veuillez continuer à utiliser ce canal de paiement avec Mastercard uniquement.',
    },
    fasa: {
      header: 'DÉPÔT FASAPAY',
      description: 'Comment approvisionner votre compte avec FasaPay',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2: '2.   Connectez-vous à votre compte FasaPay',
      inst3:
        '3.   Suivez les instructions affichées via votre compte FasaPay pour effectuer votre paiement',
      inst4:
        '4.   Les dépôts Fasapay sont limités à 25 000 USD par transaction et à un maximum de 75 000 USD par jour',
      form: { header: 'FORMULAIRE FASAPAY' },
    },
    miji: {
      header: 'Virement bancaire express Thaïlande',
      description: 'Comment approvisionner votre compte par virement bancaire express Thaïlande',
      inst1: '1.   Remplissez le formulaire ci-dessous et cliquez sur « soumettre »',
      inst2: '2.   Vous serez ensuite redirigé vers la page de paiement',
      inst3:
        '3.   Connectez-vous à votre application bancaire et scannez le code QR sur la page de paiement',
      inst4:
        'Veuillez prendre note que le système ajoutera automatiquement quelques centimes au montant désiré',
      form: { header: 'Formulaire de virement bancaire express Thaïlande' },
    },
    intSwift: {
      header: 'VIREMENT BANCAIRE : SWIFT INTERNATIONAL',
      desc: 'Les fonds peuvent être transférés à {platform} via le service bancaire en ligne de notre établissement financier ou en prenant contact avec votre établissement financier et en demandant le virement vers {platform}',
      desc2:
        'Une fois le virement effectué, veuillez compléter le formulaire ci-dessous et joindre une copie du reçu du virement bancaire montrant clairement votre nom complet, le numéro de votre compte et le montant du dépôt afin de nous permettre de traiter votre transaction.',
      options: 'Veuillez sélectionner le compte de trading que vous souhaitez approvisionner',
      refInfo:
        'Veuillez spécifier le numéro de votre compte de trading {platform} dans le champ de référence du virement bancaire.',
      bussDayInfo:
        'Comptez un délai de 2 à 5 jours ouvrables pour que les fonds soient crédités sur votre compte',
      tip: 'Veuillez vous assurer que votre reçu de virement indique clairement votre <b>nom complet</b>, <b>numéro de compte</b> et <b>montant du dépôt</b>.',
    },
    mobile: {
      header: 'MOBILE PAY',
      desc: 'Comment approvisionner votre compte avec Mobile Pay',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2:
        '2.   Numérisez le code QR pour ouvrir votre application de services bancaires mobiles',
      inst3:
        '3.   Suivez les instructions affichées sur votre appareil mobile pour effectuer votre dépôt',
      form: { payMethod: 'Méthode de paiement:', rate: 'Taux de change (USD/CNY):', amt: 'CNY:' },
      formValidation: { cnyAmt: 'Le montant en CNY ne peut pas être supérieur à {limit} $' },
    },
    net: {
      header: 'Neteller',
      inst2: '2.   Connectez-vous à votre compte Neteller',
      inst3:
        '3.   Suivez les instructions affichées via votre compte Neteller pour effectuer votre paiement',
      inst4: {
        fca: '4.   Nous acceptons les paiements dans les devises suivantes : USD, EUR et GBP',
        default:
          '4.   Nous acceptons les paiements dans les devises suivantes : AUD, USD, EUR, GBP et SGD',
      },
    },
    canada: {
      desc: 'Comment approvisionner votre compte avec Interac E-Transfer',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur «Soumettre».',
      inst2:
        '2.   Sélectionnez la banque dans la page de sélection de banque et connectez-vous à votre banque en ligne ',
      inst3:
        '3.   Suivez les instructions et effectuez le paiement via votre banque en ligne / mobile ',
      inst4: '4.   Nous pouvons accepter les paiements en CAD. Maximum 9,999 $ CAN par dépôt',
      form: { header: 'Interac E-Transfer Forme' },
    },
    bitwallet: {
      header: 'DÉPÔT BITWALLET',
      desc: 'Comment approvisionner votre compte avec BITWALLET',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2: '2.   Connectez-vous à votre compte BITWALLET',
      inst3:
        '3.   Suivez les instructions affichées via votre compte BITWALLET pour effectuer votre paiement',
      inst4: '4.   Nous acceptons les paiements dans les devises suivantes : USD, EUR et JPY',
      form: { header: 'FORMULAIRE BITWALLET' },
    },
    astropay: {
      header: 'DÉPÔT ASTROPAY',
      desc: 'Comment approvisionner votre compte avec ASTROPAY',
      inst2: '2.   Connectez-vous à votre compte ASTROPAY',
      inst3:
        '3.   Suivez les instructions affichées via votre compte ASTROPAY pour effectuer votre paiement',
      inst4: '4.   Nous pouvons accepter le paiement dans toutes les devises',
      form: { header: 'FORMULAIRE ASTROPAY' },
    },
    crypto: {
      choose: 'Veuillez choisir votre crypto-monnaie préférée',
      proceedQuestion: 'Souhaitez-vous procéder à votre dépôt <br /><b>{method}</b>?  ',
      remark:
        "Veuillez noter que nous ne sommes pas en mesure d'effectuer des dépôts ou des retraits via BUSDT. Assurez-vous que l'adresse et la crypto-monnaie correspondent à la chaîne et à la devise que nous acceptons, sinon vous pourriez perdre les fonds.",
      inst2:
        '2.   Vous serez alors dirigé vers une nouvelle fenêtre affichant une adresse de portefeuille {method}',
      inst3:
        "3.   Copiez l'adresse du portefeuille {method}, puis connectez-vous à votre portefeuille {method} personnel et transférez le montant souhaité à l'adresse {method}.",
      inst4:
        'Veuillez noter: les dépôts BITCOIN sont traités par B2BinPay au taux de change fourni au moment de la transaction.',
    },
    poli: {
      header: 'DÉPÔT POLi',
      desc: 'Comment approvisionner votre compte avec POLi',
      reminder:
        '<li>Remarque :</li><li>* Le dépôt doit être effectué sous le même nom que celui de votre compte {platform}.</li><li>* Les dépôts doivent être effectués dans la devise AUD uniquement.</li>',
      form: { header: 'FORMULAIRE POLi' },
    },
    skrill: {
      header: 'DÉPÔT SKRILL/MONEYBOOKERS',
      desc: 'Comment approvisionner votre compte avec Skrill',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2: '2.   Connectez-vous à votre compte Skrill',
      inst3:
        '3.   Suivez les instructions affichées via votre compte Skrill pour effectuer votre paiement',
      inst4: '4.   Nous acceptons les paiements dans les devises suivantes : USD, EUR, GBP et CAD',
      inst4FCA: '4.   Nous acceptons les paiements dans les devises suivantes : USD, EUR et GBP',
      form: { header: 'FORMULAIRE SKRILL', email: 'Skrill/Moneybookers Email' },
      reminder:
        "<li>Remarque :</li>\n      <li>* Les comptes ne peuvent être approvisionnés que dans la même devise que celle associée à votre compte de trading MT4. Skrill convertira automatiquement les fonds dans la devise de base de votre compte si une adresse e-mail incorrecte est utilisée. Des frais peuvent s'appliquer.</li>\n      <li>* Les dépôts doivent être effectués sous le même nom que celui associé à votre compte {platform}.</li>",
    },
    sticPay: {
      inst2: '2.   Connectez-vous à votre compte SticPay',
      inst3:
        '3.   Suivez les instructions affichées via votre compte SticPay pour effectuer votre paiement',
    },
    webMoney: {
      header: 'WEBMONEY',
      inst4: '4.   Nous acceptons les paiements dans les devises suivantes : USD et EUR',
      inst5: '5.   Les dépôts WebMoney sont limités à 1300 USD/1100 EUR par transaction',
    },
    vload: {
      header: 'VLOAD',
      inst1: '2.   Nous acceptons les paiements en USD, EUR et JPY. ',
      inst2:
        '<li>3.   Pour acheter un coupon Vload, cliquez <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">ici</a>.</li>',
      form: {
        voucherpin: 'Pin du coupon',
        voucherpinReq: "Un PIN provenant d'un coupon est obligatoire",
      },
    },
    dasshpe: {
      headerWallet: 'Portefeuilles indiens',
      inst1: '2.   Approuvez la transaction depuis votre appli UPI',
      inst2:
        '2.   Suivez les instructions et effectuez le paiement via votre application de portefeuille',
    },
    thaiPaytoday: {
      header: 'Virement bancaire express Thaïlande',
      desc: 'Comment approvisionner votre compte par virement bancaire express Thaïlande',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2: '2.   Suivez les instructions pour effectuer votre paiement',
      form: { header: 'Formulaire de virement bancaire express Thaïlande' },
      scan: 'Numériser pour effectuer un dépôt',
      exp: 'Expiration dans {expireTime}',
      reminder:
        'Les fonds devraient apparaître dans votre compte dans un délai d\'une heure.<br/><br/>\n      Vous pouvez voir l\'état actuel de votre dépôt dans votre "historique des transactions".<br/>\n      Si vous rencontrez des problèmes avec votre dépôt, veuillez contacter votre responsable de compte<br/>',
    },
    unionPay: {
      header: 'DÉPÔT UNION PAY',
      desc: 'Comment approvisionner votre compte avec UnionPay',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2:
        '2.   Sélectionnez la banque sur la page de sélection de banque et connectez-vous à votre banque en ligne',
      inst3:
        '3.   Suivez les instructions et effectuez votre paiement via votre service bancaire en ligne/mobile',
      form: { header: 'FORMULAIRE UNION PAY' },
    },
    unionPayCN: {
      header: 'Virement Union Pay Chine',
      desc: 'Comment approvisionner votre compte par un virement Union Pay Chine',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2: "2.   Une page de détails du paiement s'affiche",
      inst3:
        '3.   Suivez les instructions et effectuez votre paiement via votre service bancaire en ligne/mobile',
      inst4:
        "Remarque : Votre numéro de membre peut vous être demandé lors de l'exécution d'un dépôt. Dans ce cas, indiquez le numéro de votre compte de trading comme numéro de membre.",
      form: {
        header: 'FORMULAIRE UNION PAY CHINE',
        holderName: 'Nom du titulaire de la carte UnionPay:',
        holderNameInfo:
          'Assurez-vous que le nom que vous entrez ci-dessus correspond au nom du titulaire de la carte bancaire',
      },
    },
    vietZota: {
      header: 'Virement bancaire express Vietnam',
      desc: 'Comment approvisionner votre compte par virement bancaire express Vietnam',
      inst: '<li>1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre ».</li>\n      <li>2.   Sélectionnez la banque sur la page de sélection de banque et connectez-vous à votre banque en ligne du Vietnam.</li>\n      <li>3.   Suivez les instructions et effectuez un paiement via votre service bancaire en ligne/mobile.</li>\n      <li>4.   Vous serez redirigé vers le portail client et votre transaction sera finalisées</li>',
      form: { header: 'Formulaire de virement bancaire express Vietnam' },
    },
    paypal: {
      header: 'Paypal',
      desc: 'Comment approvisionner votre compte par PayPal',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2: '2.   Connectez-vous à votre compte PayPal',
      inst3:
        '3.   Suivez les instructions affichées via votre compte PayPal pour effectuer votre paiement',
      inst4: 'Remarque : Nous ne pouvons pas accepter les paiements de tiers',
      form: { header: 'FORMULAIRE PAYPAL' },
    },
    cardpay: {
      desc: 'Comment approvisionner votre compte par Credit Card',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur «Soumettre»',
      inst2:
        '2.   Vous serez ensuite redirigé vers un autre formulaire pour saisir les détails de votre carte',
      inst3: '3.   Après avoir entré les détails de votre carte, appuyez sur «Soumettre»',
      note: "Veuillez noter que la description de la transaction qui apparait sur votre relevé de carte bancaire dépendra du portail financier utilisé. Si vous avez des questions concernant la description de la transaction, veuillez nous contacter à l'adresse <a href='mailto:{mail}'>{mail}</a>",
      form: { header: 'FORMULAIRE CREDIT CARD' },
    },
    tinkbit: { inst5: '5.   1500 $ USD maximum par dépôt' },
    trustly: {
      desc: 'Comment approvisionner votre compte avec Trustly',
      inst1: '1.   Remplissez le formulaire ci-dessous et appuyez sur « Soumettre »',
      inst2: '2.   Connectez-vous à votre compte Trustly',
      inst3:
        '3.   Suivez les instructions affichées via votre compte Trustly pour effectuer votre paiement',
      inst4: '4.   Nous acceptons les paiements dans les devises suivantes : EUR et GBP',
      form: { header: 'FORMULAIRE TRUSTLY' },
      reminder:
        "<li>Remarque :</li>\n      <li>* Les comptes ne peuvent être approvisionnés que dans la même devise que celle associée à votre compte de trading MT4. Trustly convertira automatiquement les fonds dans la devise de base de votre compte si une adresse e-mail incorrecte est utilisée. Des frais peuvent s'appliquer.</li>\n      <li>* Les dépôts doivent être effectués sous le même nom que celui associé à votre compte {platform}.</li>",
    },
    fxir: {
      desc: '{number}.   Veuillez compléter le formulaire ci-dessous et sélectionner « Soumettre »',
      desc2:
        '{number}.   Pour un dépôt réussi et rapide, transférez les fonds dans la même devise que votre compte de trading et créez une demande de dépôt après avoir téléchargé le reçu de transfert.',
      desc1:
        '{number}.   Veuillez compléter le formulaire ci-dessous et sélectionner « Soumettre »',
      desc3:
        'Une fois que vous avez effectué le virement, veuillez remplir le formulaire ci-dessous et joindre une copie du reçu de virement bancaire indiquant clairement votre nom complet, votre identifiant FX-IR et le montant du dépôt, afin que nous puissions traiter votre transaction.',
      desc4:
        'Important ! Veuillez inclure (votre numéro de compte de trading) dans le champ de référence du virement.',
      field: {
        id: 'Identifiant FX-IR de l’expéditeur',
        accName: 'Nom du compte FX-IR',
        walletNumber: 'Numéro de portefeuille FX-IR',
        transactionID: 'Identifiant de transaction',
        senderFxirID: 'Identifiant FX-IR de l’expéditeur',
      },
    },
  },
  restrictDeposit: {
    header: 'NOTIFICATION IMPORTANTE',
    info: 'Malheureusement, nous ne pouvons plus accepter les dépôts de clients résidant au Canada.',
  },
  withdraw: {
    default: {
      selectCC: 'Sélectionner une carte de crédit',
      selectBA: 'Sélectionner un compte en banque',
      anotherCC: 'Saisissez manuellement la carte de crédit',
      anotherBA: 'Ajouter un compte en banque',
      remember: 'Se souvenir de mon compte',
      success:
        'Votre demande de retrait a bien été effectuée et sera traitée dans les meilleurs délais',
      ccFail:
        'Afin de protéger la sécurité de votre compte, vous avez atteint le maximum de tentatives de dépôt et suspendu temporairement vos retraits. Veuillez contacter {email}',
      fetchTradeAccFailed: 'Échec de récupération du compte de trading',
      failed: 'Échec du retrait. Veuillez réessayer plus tard',
      notEnoughFunds: 'Le montant saisi dépasse la limite de cette carte',
      cardExpird:
        'Cette carte est déjà expirée. Veuillez choisir une autre carte ou un autre mode de paiement.',
      minAmountWarn:
        'Le montant du retrait demandé est inférieur au minimum pour la passerelle de paiement requise. Veuillez relever le solde de votre compte jusqu\'à ce minimum ou plus avant de soumettre une demande de retrait. Plus d\'informations disponibles <a href="https://{regulatorUrl}/clients/accounts/withdraw/">ici</a>.',
      noCardWarn:
        "Vous n'avez aucune carte disponible pour effectuer un retrait. Veuillez d'abord associer votre carte dans les informations liées au retrait.",
      equityStop:
        "Malheureusement, votre retrait ne peut pas être traité, car la marge disponible de votre compte est entrain de tomber en dessous du niveau d'arrêt, « stop out », de 100%.",
      equityMargin:
        'Malheureusement, nous ne pouvons actuellement pas traiter votre retrait parce que votre compte est entièrement couvert.',
      equityContinue:
        'Nous avons détecté que vous avez actuellement des positions ouvertes. Vous pourriez faire face à un Stop out après avoir soumis votre demande de retrait. Veuillez confirmer si vous souhaitez continuer.',
      YourPaymentGreaterThanAvailableBalance: 'Votre paiement est supérieur au solde disponible',
      blackList:
        "Malheureusement, vous n'êtes actuellement pas en mesure de soumettre une demande de retrait. Contactez-nous à {supportEmail} pour plus d'informations",
      NetellerChargeAlert:
        "Les retraits Neteller sont limités à 500 000 $ par transaction et des frais de 2 % (plafonnés à 30 $) s'appliquent.",
      FasaPayChargeAlert: 'FasaPay facture des frais de transaction de 0,5% pour chaque retrait.',
      SkrillChargeAlert: 'Skrill facture des frais de transaction de 1% pour chaque retrait.',
      localBankTransfer: 'Local Bank Transfer',
    },
    fasa: {
      field: { accName: 'Nom du compte FasaPay', accNum: 'Numéro du compte FasaPay' },
      formValidation: {
        accNameReq: 'Le nom associé au compte FasaPay est requis',
        accNumReq: 'Le numéro du compte FasaPay est requis',
      },
    },
    neteller: {
      field: { email: 'E-mail Neteller' },
      formValidation: { emailReq: 'Une adresse e-mail Neteller est requise' },
    },
    bitcoin: {
      field: { address: 'Adresse du portefeuille Bitcoin' },
      formValidation: { addressReq: "L'adresse du portefeuille Bitcoin est requise" },
    },
    eth: {
      field: { address: 'Adresse du portefeuille ETH' },
      formValidation: { addressReq: "L'adresse du portefeuille ETH est requise" },
    },
    usdc: {
      field: { address: 'Adresse du portefeuille USDC' },
      formValidation: { addressReq: "L'adresse du portefeuille USDC est requise" },
    },
    usdt: {
      field: { address: 'Adresse du portefeuille USDT' },
      formValidation: { addressReq: "L'adresse du portefeuille USDT est requise" },
    },
    paypal: {
      field: { email: 'E-mail Paypal' },
      formValidation: { emailReq: 'Une adresse e-mail Paypal est requise' },
    },
    skrill: {
      field: { email: 'E-mail Skrill' },
      formValidation: { emailReq: 'Une adresse e-mail Skrill est requise' },
    },
    webMoney: {
      field: { email: 'E-mail WebMoney' },
      formValidation: { emailReq: 'Une adresse e-mail WebMoney est requise' },
    },
    vload: {
      field: { email: 'E-mail Vload enregistré' },
      formValidation: { emailReq: 'Un e-mail inscrit sur Vload est obligatoire' },
    },
    sticpay: {
      field: { email: 'E-mail SticPay' },
      formValidation: { emailReq: 'Une adresse e-mail SticPay est requise' },
    },
    bitwallet: {
      field: { email: 'E-mail Bitwallet' },
      formValidation: { emailReq: 'Une adresse e-mail Bitwallet est requise' },
    },
    astropay: {
      field: { accountNumber: 'Compte Astropay' },
      formValidation: { accountNumberReq: 'Un compte Astropay est requis' },
    },
    transfer: {
      info: '{platform} remboursera les frais bancaires facturés pour <u>UN</u> retrait par mois. Les retraits supplémentaires au cours du mois entraîneront des frais bancaires de 20 unités de votre devise de retrait.',
      label: {
        upload:
          "Veuillez télécharger une capture d'écran, une photo ou une copie numérisée de votre relevé bancaire (couvrant les 3 derniers mois)",
        upload2:
          'Veuillez vous assurer que la date du relevé ou la date de la dernière transaction est comprise entre le mois en cours et les trois derniers mois. Si cela dépasse cette période, veuillez réimporter le relevé.',
      },
      formValidation: { upload: 'Veuillez télécharger un relevé bancaire' },
    },
    ccMethod: {
      desc1:
        'Pour des raisons de conformité AML / CTF, les fonds doivent être transférés sur la carte utilisée pour votre dépôt initial',
      desc2:
        "Si la carte utilisée pour approvisionner votre compte n'est pas disponible, contactez-nous à : {mailto}",
    },
    otherMethod: {
      otherType: 'Autres types',
      withdrawalMethod: 'Méthode de retrait',
      desc1:
        'Veuillez noter que les virements bancaires peuvent entraîner des frais. Assurez-vous que le montant de votre retrait est suffisant pour couvrir les frais qui pourraient être appliqués.',
      desc2:
        'En raison de la conformité AML/CTF, tous les retraits doivent être effectués avec le même mode de paiement que celui utilisé pour approvisionner votre compte.',
      note: "Suite à de récentes modifications, notre banque peut ne pas être en mesure de traiter les virements bancaires internationaux pour les clients enregistrés dans certains pays.<br/>\n      Si vous devez effectuer un retrait par virement bancaire international, veuillez contacter notre service à la clientèle au +44 2080 363 883 ou par e-mail à <a href='mailto:{mail}'>{mail}</a>. <br/>\n      Nous nous excusons pour les inconvénients.",
      verificationCode:
        'Le code de vérification a été envoyé à {email}. Contactez-nous l’adresse à {supportEmail} si vous avez besoin d’une assistance supplémentaire',
      note2:
        "En raison de l'impact de la politique de la Corée du Sud sur le Travel Rule, nous n'accepterons pas les retraits d'adresses d'échanges coréens dépassant 735 USDT (environ 1 000 000 KRW) par transaction, ou de clients dont les adresses n'ont pas passé la vérification KYC.",
    },
  },
  transfer: {
    creditWarn:
      'Nous ne sommes pas en mesure de traiter votre transfert de fonds à partir du compte sélectionné car votre compte fait apparaître un crédit promotionnel.',
    blackListWarn:
      'Le transfert de fonds à partir de votre compte MAM peut entraîner la liquidation des positions gérées. Veuillez contacter {mailTo} pour transférer des fonds à partir de ce compte',
  },
  changePw: {
    header: 'CHANGER DE MOT DE PASSE',
    field: {
      currPw: 'Mot de passe actuel',
      newPw: 'Nouveau mot de passe',
      confirmPw: 'Confirmer le nouveau mot de passe',
    },
    succ: 'Votre mot de passe a été mis à jour. Vous pouvez maintenant utiliser votre nouveau mot de passe pour accéder au portail client.',
  },
  downloads: {
    appDesc: "Téléchargez l'application {platform} sur l'App Store ou le Google Play Store",
    mt4: 'METATRADER 4',
    mt4Desc: 'Téléchargez MetaTrader 4 pour PC, navigateurs Internet, smartphones et tablettes',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android',
    web: 'WebTrader',
    webTrader: 'Web Trader',
    smartTrader: 'Outils SmartTrader',
    mt5: 'METATRADER 5',
    mt5Desc: 'Téléchargez MetaTrader 5 pour PC, navigateurs Internet, smartphones et tablettes',
    button: { download: 'TÉLÉCHARGEMENTS', launch: 'lancer' },
  },
  forgetPwReq: {
    headerMobile: 'ENTREZ VOTRE NUMÉRO DE TÉLÉPHONE DE CONNEXION',
    labelEmail: 'Email',
    labelMobile: 'Numéro de téléphone',
    loginMobile: 'Numéro de téléphone de connexion',
    loginSMSCode: 'Code SMS',
    loginSmsCodeExpires: 'Le code SMS expire dans 5 minutes',
    getCode: 'Envoyer un code SMS',
    failedCode: "Échec de l'envoi par SMS du code vers votre téléphone",
    sentAlready: 'Envoyée',
    expired: 'Le code SMS a expiré',
    tip: "Saisissez l'adresse e-mail de votre compte pour réinitialiser votre mot de passe.",
    formValidation: {
      emailReq: "L'adresse e-mail de connexion est requise",
      mobileReq: 'La connexion par mobile est requise',
      smsCodeReq: 'le code par sms est requis',
    },
    succ: 'Nous venons de vous envoyer un e-mail contenant toutes les instructions nécessaires pour réinitialiser votre mot de passe.<br><br>Vérifiez le dossier de courrier indésirable de votre messagerie.',
  },
  security: {
    confirm: 'Confirmer',
    cancel: 'Annuler',
    understand: 'Je comprends',
    passwordRenewalReminder: {
      header: 'Changer le mot de passe de connexion',
      desc: 'Pour garantir la sécurité des fonds de votre compte, les retraits ne seront pas pris en charge pendant T+{days} jours après le changement de votre mot de passe de connexion.',
    },
    withdrawalFreeze: {
      header: 'Les retraits ne sont temporairement pas pris en charge',
      userLoginDesc:
        'En raison de la modification de votre adresse e-mail/numéro de téléphone de connexion, les retraits ne sont temporairement pas pris en charge pendant T+{days} jours. Cette restriction devrait être levée dans {hours} heures.',
      passDesc:
        'En raison de la modification de votre mot de passe de connexion, les retraits ne sont temporairement pas pris en charge pendant T+{days} jours pour garantir la sécurité de votre compte. Cette restriction devrait être levée dans {hours} heures.',
      limitSecurityAuthenticator:
        'En raison de la modification de votre application Authenticator de sécurité, les retraits ne sont temporairement pas pris en charge pendant T+{days} jours pour garantir la sécurité de votre compte. Cette restriction devrait être levée dans {hours} heures.',
    },
    securityManagement: 'Gestion de la sécurité',
    securityAuthentication: 'Authentification de sécurité',
    authenticationMethod: "Méthode d'authentification",
    isVerified: 'Vérifié',
    changeSecurityAuthenticator: "Changer l'authentificateur de sécurité",
    isNotVerified: 'Non vérifié',
    password: 'Mot de passe',
    securityAuthenticatorApp: "Application d'authentification de sécurité",
    enableLoginAuthentication: "Activer l'authentification de connexion",
    loginLocationChange: 'Notification par e-mail du changement de lieu de connexion',
    lastUpdate: 'Dernière mise à jour',
    modify: 'Modifier',
    verify: 'Vérifier',
    enable: 'Activer',
    resend: 'Renvoyer',
    enterVerificationCode: 'Entrer le code de vérification',
    verifyPreEmailNote: 'Le code sera envoyé à {email}',
    verifyPostEmailNote: 'Code envoyé à {email}',
    preEmailCode: 'Le code sera envoyé à votre nouvelle adresse e-mail',
    authenticatorTooltip:
      "Le code de vérification de l'application Authenticator se rafraîchit toutes les 30 secondes",
    contactSupport:
      "Impossible d'accéder à la méthode d'authentification? Veuillez contacter {contact_email} ou Livechat",
    changeEmail: "Changer l'adresse e-mail",
    emailTitle: 'Entrer une nouvelle adresse e-mail',
    editAuthenticator: "Modifier le périphérique d'authentification",
    addAuthenticator: "Ajouter un appareil d'authentification",
    scanWithApp: "Scanner avec l'application Authenticator",
    scanAppTooltip:
      "Vous pouvez télécharger Google Authenticator ou Microsoft Authenticator depuis votre magasin d'applications sur votre téléphone. Ensuite, utilisez la caméra de l'application pour scanner le code QR fourni.",
    scanAppTooltipForChina:
      "Vous pouvez télécharger Google Authenticator ou Microsoft Authenticator depuis votre magasin d'applications téléphoniques ou rechercher Google Authenticator dans le mini-programme WeChat et choisir le premier résultat. Ensuite, utilisez l'appareil photo de l'application pour scanner le code QR fourni.",
    scanAuthenticatorAppTips: {
      tips1:
        "Vous pouvez télécharger Google Authenticator ou Microsoft Authenticator depuis le magasin d'applications mobiles",
      tips2:
        'Ou vous pouvez également rechercher Google Authenticator dans le mini-programme WeChat et sélectionner le premier résultat.',
      tips3: "Ensuite, utilisez l'appareil photo de l'application pour scanner le code QR fourni",
    },
    userGuide: "Guide de l'utilisateur",
    setupKey: 'Clé de configuration',
    tnc: "En liant une application d'authentification, vous confirmez avoir lu et accepté le {link}.",
    disclaimer: 'disclaimer',
    changePasswordSuccess: 'Mot de passe mis à jour',
    changeEmailSuccess: 'Adresse e-mail mise à jour',
    enableSecuritySuccess: 'Authenticateur de sécurité activé',
    updateSecuritySuccess: 'Authenticateur de sécurité mis à jour',
    changePasswordSuccessDesc:
      'Votre mot de passe a été mis à jour. <br/> Vous pouvez maintenant utiliser un nouveau mot de passe pour vous connecter.',
    changeEmailSuccessDesc:
      'Votre adresse e-mail a été mise à jour. <br/> Vous pouvez maintenant utiliser une nouvelle adresse e-mail pour vous connecter.',
    enableSecuritySuccessDesc: "Votre application d'authentification de sécurité a été activée.",
    updateSecuritySuccessDesc:
      "Votre application d'authentification de sécurité a été mise à jour.",
    passwordReq: 'Exigences de mot de passe :',
    passwordCharacters: '8-16 caractères',
    passwordMix: 'Un mélange de lettres (a-z et A-Z) et de chiffres (0-9)',
    passwordSpecial: 'Caractères spéciaux tels que !@#$%^&*().',
    confirmPwd: 'Confirmer le mot de passe',
    enableNow: 'Activer maintenant',
    changeDesc:
      'Pour garantir la sécurité des fonds sur votre compte, les retraits ne seront pas pris en charge pendant T+ {numDays} jours après le changement de votre {method}.',
    verificationTitle: 'Vérification requise',
    verificationDesc:
      "Veuillez activer l'application d'authentification de sécurité avant de procéder.",
    updateSuccess: 'Mis à jour avec succès',
    verifiedSuccess: 'Vérifié avec succès',
    verificationRequired: 'Veuillez entrer le code de vérification',
    next: 'Suivant',
  },
  reserPwMobile: { header: 'RÉINITIALISER LE MOT DE PASSE' },
  loginClient: {
    header: 'PORTAIL CLIENT',
    failedMasseage: 'Réessayer',
    existingClientPortalAccess:
      "Nous constatons que vous disposez déjà d'un accès au portail client.<br /><br />Connectez-vous en utilisant votre e-mail pour ouvrir un nouveau compte.",
    existingClientPortalAccessMobile:
      "Nous constatons que vous disposez déjà d'un accès au portail client. <br /> <br /> Connectez-vous à l'aide de votre numéro de téléphone pour ouvrir un nouveau compte!",
    formValidation: {
      emailReq: 'Une adresse e-mail est requise',
      mobileReq: 'Veuillez saisir des valeurs numériques uniquement',
    },
  },
  myProfile: {
    changeDetails:
      "Si vous souhaitez mettre à jour les détails de votre profil existant, n'hésitez pas à contacter {mailTo}",
  },
  openAdditAcc: {
    chooseTradePlat: 'CHOISIR UNE PLATE-FORME DE TRADING',
    chooseAccType: 'CHOISIR UN TYPE DE COMPTE',
    chooseCurrency: 'CHOISIR UNE DEVISE POUR LE COMPTE',
    chooseLeverage: "CHOISIR L'EFFET DE LEVIER",
    chooseBalance: 'SOLDE DU COMPTE',
    notes: 'REMARQUES SUPPLÉMENTAIRES',
    currError: 'Vous devez sélectionner une devise pour le compte',
    platErr: 'Vous devez sélectionner une plate-forme de trading',
    accTypeErr: 'Vous devez sélectionner un type de compte',
    leverageErr: 'Vous devez sélectionner un effet de levier',
    balanceErr: 'Vous devez sélectionner un solde',
    succ: "<p>Votre compte en direct supplémentaire est en cours de configuration et vous recevrez un e-mail contenant vos informations de connexion très bientôt.</p><p>Vous pouvez afficher le statut actuel de votre demande à partir de la page d'accueil.</p>",
    byTicking: 'En cochant cette case:',
    swapFreeNote:
      "* Remarque : à la place des frais de swap, des frais d'administration minimes peuvent s'appliquer.",
    pammNote:
      "* Les comptes PAMM sont uniquement destinés aux investisseurs disposant d'un contrat existant de gestionnaire de fonds. Les comptes PAMM ne peuvent être tradés que par le gestionnaire de fonds.",
  },
  openDemoAdditAcc: {
    header: 'OUVRIR UN COMPTE DÉMO SUPPLÉMENTAIRE',
    button: 'ouvrir un compte démo',
    liveButton: 'ouvrir un compte réel',
    succ: 'Votre compte démo supplémentaire a été créé et vous recevrez sous peu un e-mail contenant vos identifiants de connexion.',
  },
  resetPassword: {
    header: 'RÉINITIALISER LE MOT DE PASSE',
    succ: 'Votre mot de passe a été mis à jour. Vous pouvez maintenant utiliser votre nouveau mot de passe pour accéder à votre compte de trading MT4/MT5.',
    succ2: 'Votre mot de passe a été mis à jour.',
    failed: 'Le jeton a expiré',
    emailNotFound: "Malheureusement votre adresse e-mail n'a pas été reconnue",
  },
  fileUploader: {
    supportedFormat: 'Types de fichier pris en charge : bmp, jpg, gif, jpeg, png, tiff, doc, pdf',
    supportedFormatCustom: 'Types de fichier pris en charge: {files}',
    maxSize: 'Taille maximale du fichier de téléchargement : {maxSize} Mo',
    maxFileNum: 'Nombre maximum de fichiers : {maxFileNum}',
    failedFileExtension: 'Veuillez télécharger le type de fichier correct',
  },
  transcationHistory: {
    deposit: 'DÉPÔT',
    withdraw: 'RETRAIT',
    desc: {
      incomplete:
        "Si vous mettez fin au processus de dépôt ou décidez de ne pas l'exécuter, le statut « incomplet » s'affichera pour la transaction. Vous devrez effectuer un nouveau dépôt car cette transaction ne peut pas être reprise.",
      success:
        'Votre transaction a bien été effectuée. Vos fonds devraient apparaître très prochainement sur votre compte',
      proc: 'Votre transaction est en cours de traitement. Veuillez contacter {mailTo}',
      failed: "Votre transaction a échoué. Pour plus d'informations, veuillez contacter {mailTo}",
      rejected:
        "Votre transaction a été refusée. Pour plus d'informations, veuillez contacter {mailTo}",
    },
    withdrawDesc: {
      sub: "Veuillez patienter jusqu'à la fin de l'exécution du processus",
      suc: 'Votre transaction a bien été effectuée. Vos fonds devraient apparaître très prochainement sur votre compte',
      proc: 'Votre transaction est en cours de traitement. Veuillez contacter {mailTo}',
      incomp:
        "Des étapes supplémentaires sont nécessaires pour finaliser votre transaction. Pour plus d'informations, veuillez contacter {mailTo}",
      cancel:
        "Votre transaction a été annulée. Pour plus d'informations, veuillez contacter {mailTo}",
      fail: "Votre transaction a échoué. Pour plus d'informations, veuillez contacter {mailTo}",
      rej: "Votre transaction a été refusée. Pour plus d'informations, veuillez contacter {mailTo}",
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'Malheureusement, votre transfert ne peut pas être traité car cela se traduira dans votre compte par une marge disponible tombant sous le niveau Stop out de 100 %.',
      equityMargin:
        'Malheureusement, nous ne sommes pas en mesure de traiter actuellement votre transfert du fait de la couverture complète de votre compte.',
      equityContinue:
        'Nous avons détecté que vous avez actuellement des positions ouvertes. Vous pourriez faire face à un Stop out après avoir soumis votre demande de transfert. Veuillez confirmer si vous souhaitez continuer.',
      blackList:
        "Ce compte ne comporte pas d'autorisation pour effectuer un transfert entre comptes. Contactez-nous à {supportEmail}",
    },
    pleaseNote: 'Remarque:',
    suc: 'Vos fonds ont bien été transférés',
    warn1:
      "Il vous incombe de maintenir un solde de compte qui ne déclenchera pas d'appel de marge. Si vous possédez des positions ouvertes, veillez à maintenir la marge requise sur votre compte. Les fonds transférés sur des comptes tenus dans la même devise sont traités instantanément",
    warn2:
      'Si vous transférez des fonds entre des comptes tenus dans des devises différentes, envoyez un e-mail à {mailTo}.',
    warn3:
      "Notez qu'il n'est pas possible de transférer des fonds entre des comptes en lecture seule",
  },
  transferHistory: {
    payStatus: { processing: 'En cours', paid: 'Payé', unpaid: 'Non payé', rejected: 'Rejeté' },
  },
  identityProof: {
    home: {
      header: 'DEMANDE DE COMPTE INCOMPLÈTE',
      content:
        'Bonjour {name}, votre demande de compte est presque terminée, mais vous devez toujours soumettre des documents supplémentaires pour finaliser votre compte.',
    },
    shufti: {
      content:
        "Veuillez vous assurer que les détails fournis correspondent à votre documentation d'identité et sont dans la même langue.",
      countryOfDocument: 'Pays comme indiqué sur le document',
    },
    popup: {
      header:
        'Bonjour {name}, avant que votre compte live ne soit finalisé, vous devez télécharger le document suivant pour vérification.',
      addressProofSpan:
        "<strong>\n        <b>Confirmer l'adresse</b>\n        </strong>\n        <br />\n        <I>\n        Soumettez <b>un</b> des documents ci-dessous\n          <br /><br />\n        </I>\n        - Copie d'une facture de services publics récente </br>(de moins de 6 mois)\n        <br />\n        <b class=\"or\">ou</b><br />\n        - Copie d'un relevé bancaire récent </br> (de moins de 6 mois)\n        <br />\n        <br />\n        <b>IMPORTANT !</b> Le nom indiqué sur le document DOIT correspondre exactement à votre nom et à votre adresse actuelle.\n        <br />",
      idProofSpan:
        "<strong>\n        <b>Confirmer l'identité</b>\n        </strong>\n        <br />\n        <I>Choisissez\n          <b>une</b> des options ci-dessous\n          <br />\n          <br />\n        </I>\n        - Copie de passeport valide<br />\n\n        - Copie de permis de conduire valide\n        <br />\n        (recto verso)\n        <br />\n        ou</b><br />\n        - Copie de carte d'identité nationale\n        <br />\n        <br />\n        <b>IMPORTANT !</b> Le nom indiqué sur le document DOIT correspondre exactement à votre nom.<br />",
    },
    reasonType: {
      4: 'Documents incomplets',
      5: 'Documents illisibles',
      6: 'Copie couleur requise',
      8: "Incompatibilité de nom à l'ouverture du compte",
      9: "Date d'émission du justificatif d'adresse non qualifiée",
    },
    reason:
      "Votre documentation n'a pas pu être vérifiée,<br> Raison : {reason}. Veuillez réessayer.",
  },
  header: { secPortal: 'Zone client sécurisée' },
  menu: {
    home: 'ACCUEIL',
    accManagement: 'GESTION DES COMPTES',
    liveAcc: 'COMPTES EN DIRECT',
    openAdditAcc: "OUVRIR D'AUTRES COMPTES",
    openAnAdditLiveAcc: 'OUVRIR UN COMPTE RÉEL SUPPLÉMENTAIRE',
    homeDemo: 'COMPTES DÉMO',
    depositFund: 'DÉPOSER DES FONDS',
    withdrawFunds: 'RETIRER DES FONDS',
    transferBetweenAccs: 'TRANSFERT ENTRE COMPTES',
    payDetails: 'DÉTAILS DU PAIEMENT',
    funds: 'FONDS',
    transactionHis: 'HISTORIQUE DES TRANSACTIONS',
    tranHis: 'TRANSFERTS',
    download: 'TÉLÉCHARGEMENTS',
    profile: 'PROFIL',
    myProfile: 'MON PROFIL',
    cpPassword: 'CHANGER LE MOT DE PASSE DU PORTAIL CLIENT',
    contactUs: 'NOUS CONTACTER',
    helpSupport: 'FORMULAIRES AIDE ET ASSISTANCE',
    register: 'Poursuivre la demande',
    tradingTools: 'OUTILS PROTRADER',
    MARKET_BUZZ: 'BUZZ DU MARCHÉ',
    ECON_CALENDAR: 'CALENDRIER ÉCONOMIQUE',
    FEAT_FX: 'IDÉES EN VEDETTE',
    FX_IDEAS: 'Vues techniques',
    ALPHA_GENERATION: 'GÉNÉRATION ALPHA',
    MARKET_MASTERS: 'MAÎTRES DU MARCHÉ',
    referAFriend: 'Parrainer un ami',
    marketWidgets: 'Widgets de marché',
    technicalAnalysis: 'ANALYSE TECHNIQUE',
    techAnalysisTab: {
      forex: 'FOREX',
      indices: 'INDICES',
      commodities: 'MATIÈRES PREMIÈRES',
      sharedcfds: 'CFD SUR ACTIONS',
      crypto: 'CRYPTO',
    },
    marketData: 'données du marché',
    forexCrossRates: 'taux croisés de forex',
    forexHeatMap: 'carte thermique du forex',
    forexScreener: 'analyseur de forex',
    wholesale: 'PASSER AU COMPTE PRO',
    tradingView: 'CHARTS de TradingView',
    twoFactorAuthentication: 'AUTHENTIFICATION À 2 FACTEURS',
    promotion: 'Promotions',
    depositBonus: '50% Bonus de dépôt',
    hmcTrader: 'Salle de trading en direct',
    support: 'Support',
    securityManagement: 'Gestion de la sécurité',
  },
  contact: {
    desc: "Pour toute question ou pour un complément d'information, contactez-nous en utilisant les coordonnées ci-dessous. Nous nous efforcerons de répondre à toutes vos demandes dans un délai de 24 heures.",
    tip: 'Pour les clients qui ont intégré via {email}',
    liveChat: 'Chat en direct',
    helpSupportForm: {
      partner: 'Formulaire de transfert de partenariat',
      investigation: "Formulaire d'investigation sur un trade Moneta",
      promotion: 'Formulaire de désinscription des promotions Moneta',
      request: 'Formulaire de demande de clôture du compte',
    },
    accountManager: 'Gestionnaire de compte - {userName}',
    monetaSupport: 'Assistance Moneta',
  },
  depositResultDialog: { msg: 'Avez-vous bien effectué votre dépôt ?' },
  dialog: {
    identityProof: {
      success:
        'Merci. Nous avons bien reçu vos documents et procédons actuellement à leur traitement aux fins de vérification.',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: 'TRAITEMENT DES DEMANDES DE COMPTE',
      id: "pièce d'identité est",
      poa: 'justificatif de domicile est',
      both: "pièce d'identité et justificatif de domicile sont",
      single: 'sera vérifié',
      both2: 'seront vérifiés',
      content:
        'Votre preuve de {doc} en cours de traitement et {doc2} dès que possible. Vous pouvez approvisionner votre compte dès maintenant et accélérer le procès de vérification.  Vous recevrez une confirmation par email dès que vos documents seront traités',
    },
  },
  idPoa: {
    IdPoaNoPass: {
      header: 'Demande d’ouverture de compte incomplète ',
      content:
        'Pour commencer à trader, veuillez télécharger votre pièce d’identité et votre justificatif de domicile. Une fois soumis, approvisionnez votre compte pour une ouverture de compte prioritaire.',
    },
    onlyIdPass: {
      header: 'Merci !',
      content:
        'Votre demande est en cours de traitement. Approvisionnez votre compte maintenant pour une ouverture de compte prioritaire.',
    },
    onlyPoaPass: {
      header: 'Demande d’ouverture de compte incomplète ',
      content:
        'Pour commencer à trader, veuillez télécharger votre pièce d’identité. Une fois votre document soumis, approvisionnez votre compte pour une ouverture de compte prioritaire.',
    },
    bothIdPoaPass: {
      header: 'Merci !',
      content:
        'Votre demande est en cours de traitement. Approvisionnez votre compte maintenant pour une ouverture de compte prioritaire.',
    },
    uploadID: 'Télécharger',
    uploadPoa: 'Télécharger Adresse',
    depositNow: 'Déposer',
  },
  register: {
    progressStatus: {
      title: 'Avancée de la procédure de vérification',
      estimatedTimeTitle: 'Temps restant estimé :',
      min: 'Minutes',
    },
    tabs: {
      personalDetails: 'Informations personnelles',
      moreAboutYou: "Plus d'informations vous concernant",
      accountConfig: 'Configuration du compte',
      confirmYourId: 'Confirmer votre identité',
      fundYourAcc: 'Approvisionner votre compte',
    },
    btn: {
      next: 'SUIVANT',
      back: 'RETOUR',
      fundnow: 'APPROVISIONNER MAINTENANT',
      upload: 'Télécharger',
      done: 'terminé',
      uploadNew: 'Télécharger un nouveau document',
    },
    authority: {
      dialog1: 'Vous devez avoir un compte réel pour accéder à cette section du portail client.',
      dialog2:
        "Ouvrez un compte réel maintenant pour déverrouiller un accès complet à votre portail client, avec toute une gamme d'outils de trading, des promotions et bien plus encore.",
      openLiveAcc: 'ouvrir un compte réel',
    },
    demo: {
      congrad: 'Félicitations !',
      title: 'Votre compte DÉMO sur MT{platform} est actif pendant 30 jours',
      title2: 'Informations sur votre compte DÉMO',
      li: {
        login: 'Connexion :',
        password: 'Mot de passe :',
        srv: 'Serveur :',
        expDate: "Date d'expiration :",
      },
      emailSent:
        '* Les identifiants de connexion ont également été envoyés à votre adresse e-mail enregistrée',
      dl: 'Téléchargez votre plateforme de trading MT{platform}',
      proTraderPlatform: {
        content:
          'Connectez-vous à notre plate-forme PRO Trader de nouvelle génération avec les identifiants de votre compte MT{platform} :',
      },
      appTrader: {
        content:
          'Connectez-vous à AppTrader de Moneta Markets avec les identifiants de votre Portail client. Téléchargez AppTrader pour iOS ou Android maintenant !',
      },
      improveQ: 'Souhaitez-vous améliorer votre trading ?',
      improveWay:
        "Ouvrez un compte réel en quelques minutes pour déverrouiller toute une gamme d'outils de trading et de promotions exclusifs.",
      proTrade: 'Pro Trader Tools',
      depositBonus: 'Bonus de dépôt de 50 %',
      smartTradeTool: 'Outils Smart Trader',
      openLiveAcc: 'ouvrir un compte réel',
      poweredBy: 'Optimisé par',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Informations personnelles',
        userTitle: 'Titre',
        firstName: 'Prénom',
        lastName: 'Nom',
        email: 'Adresse e-mail',
        dob: 'Date de naissance',
        idType: "Type de document d'identité",
        referral: "Avez-vous été recommandé par quelqu'un ?  (optionnelle)",
        middleName: 'Deuxième prénom',
        nationality: 'Nationalité',
        phoneNum: 'Numéro de téléphone',
        date: 'Date de naissance',
        month: 'Mois',
        year: 'Année',
        placeOfBirth: 'Lieu de naissance',
        idNum: "Numéro d'identification",
        passportNum: 'Passeport',
        driverLicenseNum: 'Permis de conduire',
        titleDropdown: { mr: 'M.', mrs: 'Mme', ms: 'Mme', miss: 'Mlle', dr: 'Dr', prof: 'Prof' },
        idTypeDropdown: {
          passport: 'Passeport',
          idCard: "Carte d'identité nationale",
          driverLicense: 'Permis de conduire',
        },
      },
      page2: {
        pageTitle: 'ADRESSE DE DOMICILE PRINCIPAL',
        pageTitleForFCA: 'DÉTAILS SUPPLÉMENTAIRES',
        mainResidency: 'Pays de résidence',
        streetNumber: 'Numéro de rue',
        streetName: 'Nom de rue',
        address: 'Adresse',
        provinceOrState: 'Province / État',
        cityOrSuburb: 'Ville / Quartier',
        postcode: 'Code postal',
        previousCountry: 'Pays précédent',
        usQuestion:
          'Êtes-vous un citoyen des États-Unis/ Résidez-vous aux États-Unis pour des raisons fiscales ou avez-vous des liens/relations avec les États-Unis (ex. : compte bancaire, etc.)',
        taxResidencyCountry: 'Pays de résidence fiscale',
        nationalInsuranceNumber: "Numéro d'assurance nationale (NI)",
        yearsAtAddress: "Nombre d'années à l'adresse",
        previousResidentialAddressTip:
          'Veuillez saisir votre ancienne adresse résidentielle ci-dessous',
      },
    },
    moreAboutYou: {
      pageTitle1: 'EMPLOI ET DÉTAILS FINANCIERS',
      pageTitle2: 'Connaissances et expérience en matière de trading',
      complianceExplain:
        "Les questions suivantes sont posées dans le cadre des exigences requises en matière de lutte contre le blanchiment d'argent et le financement du terrorisme.",
      FCAQuestion10Tip:
        "Au cours des 12 derniers mois, combien d'opérations, en moyenne, avez-vous exécutées sur les instruments suivants par trimestre?",
    },
    accountConfig: {
      pageTitle: 'Configuration du compte',
      chooseTradingPlatform: 'Choisir la plateforme de trading',
      chooseAccType: 'Choisir le type de compte',
      chooseAccCurrency: 'Choisir la devise du compte',
    },
    confirmYourId: {
      pageTitle: 'Confirmer votre identité',
      description:
        "En tant que broker de forex régulé, nous devons vérifier votre identité. Veuillez donc télécharger un document d'identité ci-dessous ou nous l'envoyer à l'adresse {mailTo}",
      poidTitle: "Pièce d'identité",
      porTitle: 'Justificatif de domicile',
      uploadDescription: 'Choisissez un type de document et téléchargez le document correspondant',
      note: 'IMPORTANT: Le nom sur le document DOIT clairement indiquer votre nom complet.',
      pending: { upload: 'Téléchargement en cours', verification: 'Vérification en cours' },
      poidTypes: {
        photoId: "Carte d'identité avec photo",
        passport: 'Une copie de votre passeport international valide (page de signature)',
        drivingLicence: 'Permis de conduire',
      },
      porTypes:
        '<li>Facture de service public</li>\n      <li>Relevé bancaire</li>\n      <li>Certificat de résidence</li>',
    },
    finishPage: {
      title:
        "{individualUserName}, Merci d'avoir lancé la procédure de création d'un compte {platformName} en direct !",
      contentDefault:
        "Nous avons bien reçu votre formulaire d'inscription et paramétré votre compte en direct.  <b>{account}</b>. <br />\n      Vous recevrez sous peu un e-mail <b>{email}</b> contenant vos informations de connexion et un lien qui vous permettra de consulter le statut de votre compte. <br />",
      contentWithoutId3WithAccount:
        'Votre compte de trading en direct est en cours de traitement et vous recevrez sous peu un e-mail contenant de plus amples informations.<br />\n      Pour accélérer le traitement de votre compte en direct, vous pouvez financer votre compte maintenant et recevoir une ouverture prioritaire..<br />',
      contentWithoutId3WithoutAccount:
        'Votre compte de trading en direct est en cours de traitement et vous recevrez sous peu un e-mail contenant de plus amples informations.<br />',
    },
  },
  tradingTools: {
    MARKET_BUZZ:
      "Profitez d'un accès à la couverture de l'actualité et au contenu média couvrant plus de 35,000 actifs de trading.",
    FEAT_FX:
      'Consultez des configurations de trading techniques exploitables et suivez leur progression en temps réel.',
    ECON_CALENDAR:
      'Profitez des données économiques en temps réel 24h/24 en provenance du monde entier.',
    FX_IDEAS:
      'Consultez des informations de trading techniques engageantes et pédagogiques sur 80 000 instruments.',
    ALPHA_GENERATION:
      'Boostez votre avantage de trading et gagnez 3 indicateurs MT4 et MT5 avancés de nouvelle génération.',
    MARKET_MASTERS:
      'Apprenez à trader comme les professionnels avec plus de 100 tutoriels vidéo de trading avancés.',
    condition: {
      needDeposit:
        "Pour profiter d'un accès à votre suite d'outils de trader PRO et à des tutoriels vidéo de trading exclusifs, il vous suffit d'approvisionner votre compte en direct avec 500 $ ou plus.",
      congratulations:
        'Félicitations ! Vous êtes prêt(e) à déverrouiller vos outils de trader premium !',
      needTnc: 'Veuillez accepter les{link}.',
      link: 'Conditions générales',
      unlock: 'DÉVERROUILLER LES OUTILS PRO TRADER',
      depositNow: 'DÉPÔT MAINTENANT',
      tncAisc: "J'accepte d'avoir accès à ces outils.",
    },
    techAna: 'Analyse technique pour',
    byTradingView: 'par TradingView',
    alphaGeneration: {
      intro: 'GÉNÉRATION ALPHA POUR MT4 ET MT5',
      introDesc:
        'Offrez un avantage à votre trading avec trois EA premium exclusifs de Moneta Markets.',
      analystViews: {
        title: 'Vues techniques',
        desc: 'Tendances et niveaux de prix techniques instantanés affichés dans votre tableau des prix.',
      },
      adaptiveCandlesticks: {
        title: 'CHANDELIERS ADAPTATIFS',
        desc: 'Analyse en temps réel de 16 schémas en chandelier populaires.',
      },
      adc: {
        title: 'ADC',
        desc: "Signaux de trading en entrée et sortie à court terme dans les directions 'long' et 'short'.",
      },
      install: 'COMMENT INSTALLER GÉNÉRATION ALPHA ?',
      installSteps: {
        one: 'Téléchargez Génération Alpha pour PC en cliquant sur le bouton « Télécharger Génération Alpha » ci-dessous.',
        two: "Double-cliquez sur le fichier pour l'exécuter et suivez l'assistant d'installation qui vous guidera tout au long du processus dans la langue de votre choix.",
        three: 'Choisissez votre dossier cible Moneta Markets MT4 ou MT5.',
        four: "Après l'installation, ouvrez votre plateforme MT4 ou MT5 et localisez les indicateurs de Trading Central ainsi que les autres dans votre liste d'indicateurs MetaTrader.",
        five: "Faites glisser un indicateur sur le graphique et profitez immédiatement d'informations de trading exploitables.",
      },
      button: { guid: "Guide de l'utilisateur", download: 'Télécharger Génération Alpha' },
    },
  },
  tradingView: {
    launch: 'lancer',
    subTitle:
      "Rejoignez 15 millions d'investisseurs et commencez à trader avec les graphiques, les indicateurs et les outils de dessin de TradingView.",
    tnc: 'Connectez-vous à la plateforme de trading et de graphiques TradingView avec vos identifiants de connexion MT{mtServer}. En savoir plus sur CHARTS de TradingView <a href= https://{regulatorUrl}/platforms/trading-platforms/charts-by-tradingview/>ici</a>.',
    intro:
      'Présentation de CHARTS par TradingView. Les clients de Moneta peuvent désormais trouver des idées de trading, repérer les tendances et effectuer des trades en direct via notre plateforme TradingView intégrée, CHARTS.',
    descp1: 'Plus de 100 indicateurs techniques',
    descp2: 'Actualités du marché en direct',
    descp3: 'Plus de 50 outils de dessin intelligents',
    descp4: 'Analyse multi-temporelle',
    descp5: "12 conditions d'alerte de trading différentes",
    descp6: 'Plusieurs intervalles de temps',
    descp7: 'Plus de 50 outils de dessin intelligents',
    descp8: 'Plus de 20 dispositions de graphique',
    descp9: 'Plus de 12 types de graphique personnalisables',
    descp10: 'Couverture intégrée des infos du marché',
    learnMore: 'En savoir plus',
    tnc02:
      'Utilisez vos identifiants MT{mtServer} et profitez de la plateforme ProTrader sur le web dès maintenant. {findMore}',
    tnc03: 'En savoir plus',
    subTitle02:
      'Les utilisateurs de la plateforme ProTrader de Moneta ont désormais accès à des graphiques de prix en direct, des indicateurs techniques et des outils de dessin, tous optimisés par TradingView, sur plus de 300 paires de devises, indices, matières premières, CFD sur actions et plus encore.',
    intro02: 'Vous avez accès à :',
    intro03: 'Optimisé par',
  },
  TFAuth: {
    title: 'AUTHENTIFICATION À 2 FACTEURS',
    enable: {
      enableTitle: "ACTIVER L'AUTHENTIFICATION À DEUX FACTEURS",
      enableDesc:
        "Protégez votre compte en activant l'authentification sur mobile à deux facteurs. L'authentification à deux facteurs offre un niveau supplémentaire de sécurité et de tranquillité d'esprit via une deuxième étape de vérification à chacune de vos connexions. Après activation, vous pouvez récupérer un code de vérification à deux facteurs depuis une appli sur votre téléphone iOS ou Android à tout moment. Puis vous le soumettez et vous vous connectez en toute sécurité.",
      enableSubtitle: "Comment activer l'authentification à deux facteurs",
      step1:
        "Téléchargez et installez Google Authenticator gratuitement depuis l'App Store iOS ou Android.",
      step2:
        'Dans l\'appli, sélectionnez "Scanner le code-barres" et scannez le code-barres ci-dessous.',
      step3:
        "Saisissez le code numérique dans l'appli (elle s'actualise toutes les 30 secondes) ci-dessous.",
      placeHolder: 'code',
    },
    disable: {
      disableTitle: "DÉSACTIVER L'AUTHENTIFICATION À DEUX FACTEURS",
      disableDesc:
        "Pour désactiver l'authentification à deux facteurs, soumettez le code numérique de votre appli Google Authenticator ici.",
    },
    dialog: {
      title: "Saisissez votre code d'authentification à deux facteurs",
      contact: "Saisissez votre code d'authentification à deux facteurs {link}.",
      link: 'Contactez-nous',
    },
    popup: {
      popup1: "L'authentification à deux facteurs est activée.",
      popup2: "L'authentification à deux facteurs est désactivée.",
    },
  },
  promotion: {
    depositBonus: {
      depositBonus: 'Déposez et réclamez votre bonus de trading',
      title: 'Réclamez votre bonus et boostez votre dépôt !',
      subtitle1:
        "Vous voulez plus de puissance de trading ? Il vous suffit d'approvisionner votre compte réel. Après avoir reçu votre dépôt, votre bonus de dépôt apparaîtra dans votre compte de trading.",
      fund: 'Approvisionnez votre compte',
      tnc: "*{link} s'appliquent.",
      link: 'Les Conditions générales',
      bonusHistory: 'Historique de bonus',
      overBonus:
        "* Vous avez atteint le maximum de ${totalLimit} en crédits bonus. Vous ne bénéficierez d'aucun crédit bonus supplémentaire avec les prochains dépôts.",
      opt: 'Souhaitez-vous vous inscrire pour réclamer votre bonus de dépôt ?',
      tick: 'Veuillez cocher la case si vous souhaitez vous inscrire à la promotion de bonus de dépôt.',
      tick2:
        "Moneta se réserve le droit d'exclure et/ou d'annuler la participation d'un client à ses offres ou promotions :<br><br> a) en cas de manipulation (ouverture et clôture d'une transaction de FX ou de CFD sur la marge uniquement dans le but de gagner un avantage) ;<br> b) il s'avère que le client a enfreint son Contrat client ou a enfreint les Conditions générales des promotions.<br><br> Dans le cas d'une telle faute ou violation, Moneta aura le droit de déduire tout montant de bonus et tout profit gagné dans le compte de trading.\n      <br><br>Si la Société soupçonne ou a des raisons de croire qu'un client a abusé et/ou manipulé les Conditions générales de ce schéma de bonus en couvrant ses positions en interne (en utilisant d'autres comptes de trading détenus avec la Société) ou en externe (en utilisant d'autres comptes de trading détenus avec d'autres courtiers) et/ou n'a pas agi de bonne foi, la Société se réserve le droit, à son entière discrétion et sans obtenir le consentement du client, de supprimer le montant du bonus et les profits obtenus dans le compte de trading avec effet immédiat.\n      <br><br>Moneta peut à tout moment apporter des modifications à ces Conditions générales et vous informera de ces modifications en publiant les Conditions modifiées sur le site Web de Moneta. Il vous est conseillé de consulter ces Conditions régulièrement et, par votre utilisation continue du site Web et des services de Moneta, vous acceptez ces Conditions modifiées. Moneta se réserve le droit de modifier ou d'annuler toute offre à sa seule discrétion à tout moment.",
      saveChange: 'Enregistrer les modifications',
      creditReceived: 'Crédit reçu',
      welcomeBonusTable: 'Bonus de bienvenue',
      depositBonusTable: 'Bonus de dépôt',
      depositDate: 'Date de dépôt',
      credit: 'Crédit',
      optIn: 'Inscription réussie',
      optOut: 'Désinscription réussie',
      notification:
        '<div>Vous êtes éligible pour rejoindre la campagne de bonus de dépôt. <a href="{depositBonusLink}">Inscrivez-vous</a> avant le dépôt pour bénéficier du bonus de dépôt. En savoir plus <a href="{tncLink}" target="_blank">ici</a>.</div>',
      optInButton: 'Inscrivez-vous',
      dontShowAgain: 'Ne plus afficher ce message',
    },
    referAFriend: {
      congratulations:
        "Félicitations ! Vous venez de rejoindre avec succès la promotion 'Parrainer un ami' en tant que parrain.",
      redeem: 'Échanger',
      redeemed: 'Échangé',
      dayRemain: 'jours restant',
      voucherDesc: 'Le coupon peut être utilisé après avoir tradé ≥ {lots} lots',
      title: "Parrainez un ami et gagnez jusqu'à {amount} $, et {secAmount} $ pour votre ami !",
      desc: "Lorsque votre filleul aura terminé les étapes suivantes dans les 30 jours, vous gagnerez jusqu'à {amount} $ ! Et il n'y a pas de limite au nombre d'amis que vous pouvez parrainer ! *<a href=\"{tncLink}\">Les Conditions générales</a> s'appliquent.",
      register: 'Inscrivez-vous',
      forYourFriend: 'pour votre ami',
      liveAcc: 'Ouvrir un compte réel',
      makeDeposit: 'Effectuer un dépôt',
      trade1Lot: 'Trader 1 lot',
      trade5Lots: 'Trader 5 lots',
      totalEarning: 'Total des gains',
      referralAct: 'Activité de parrainage',
      referralName: 'Nom de parrainage',
      registered: 'Inscrit',
      accOpen: 'Compte ouvert',
      madeDeposit: 'Dépôt effectué',
      redeemStatus: "Statut de l'échange",
      notApplicable: 'Non applicable',
      country: 'Pays',
      acc: 'COMPTE',
      share: 'PARTAGER',
      copyLink: 'Copier le lien',
      redeemYourbonus: 'Échangez votre bonus',
      tatolAmtConvert:
        'Le montant total converti dans votre devise de base est {convertAmt} {currency}',
      remark: '* En cliquant sur Échanger, vous acceptez les Conditions générales',
      redeemNow: 'Échanger',
      overlimit: 'Limite dépassée',
      newUser: 'Nouvel utilisateur',
      tradeCash: 'Trader des espèces',
    },
    cashback: {
      button: {
        applyForBonus: 'DEMANDER UN BONUS',
        redeemCashback: 'ÉCHANGER UN CASHBACK',
        howItWorks: 'COMMENT ÇA MARCHE ?',
        depositNow: 'Déposer',
        stp: 'DIRECT STP',
        ecn: 'PRIME ECN',
        agree: 'J’ACCEPTE',
        cancel: 'ANNULER',
      },
      title: {
        monetaProgram: 'PROGRAMME DE CASHBACK MONETA MARKETS',
        claim: 'DEMANDER VOTRE BONUS EN CASHBACK DE 50 % MAINTENANT !',
        howItWorks: 'VOICI COMMENT CELA FONCTIONNE',
        howMuch: 'COMBIEN GAGNEZ-VOUS AVEC LES TRADES ?',
        treeSimpleSteps: 'DEMANDEZ VOTRE BONUS DE 50 % MAINTENANT, EN TROIS ÉTAPES SIMPLES :',
        depositTradEarn: 'DÉPOSEZ. TRADEZ. GAGNEZ !',
        bonusSummary: 'Résumé de votre bonus',
        historicalEarnings: 'VOS GAINS ACTUELS ET VOTRE HISTORIQUE',
        program: 'PROGRAMME CASHBACK',
        cashSummary: 'RÉSUMÉ DU CASHBACK',
        faqs: 'FAQ DU BONUS DE CASHBACK',
        redeemCashback: 'ÉCHANGER UN CASHBACK',
        cashbackBalance: 'Solde du cashback',
      },
      subTitle: {
        lowerYourCost: 'Réduisez vos frais. Gagnez du cash.',
        bonus: 'BONUS',
        cashback: 'CASHBACK',
        wallet: 'PORTEFEUILLE',
        availableBonus: 'Bonus disponible',
        totalBalance: 'Solde total',
        step1: 'Déposez des fonds et demandez-le !',
        step2: 'Commencez à trader !',
        step3: 'Demandez votre cashback',
        FX: 'FOREX',
        XAU: 'OR',
        OIL: 'PÉTROLE',
        perLot: 'par lot',
        seetheBonusStatus:
          'Consultez la colonne STATUT DU BONUS pour UTILISER tout bonus disponible',
        tradeFxOilAndGold:
          'Tradez le FX, le pétrole et l’or pour convertir votre BONUS en CASHBACK',
        totalCashback: 'Cashback total',
        FebruaryCashback: 'Cashback en attente',
        cashbackRedeemable: 'Cashback échangeable',
        account: 'Compte',
        amount: 'Montant',
      },
      information: {
        availableBonus1:
          'Le bonus disponible correspond au montant des crédits bonus que vous avez gagnés avec l’ensemble des dépôts éligibles. Pour chaque dépôt de 500 $ ou plus, vous recevrez 50 % de ce montant sous forme de crédits bonus.',
        total:
          'Le solde total correspond à la somme du cashback échangeable et du montant du cashback du mois en cours.',
        availableBonus2:
          'Votre bonus disponible correspond au nombre de crédits bonus issus de l’ensemble des dépôts éligibles qui n’ont pas encore été convertis et transférés vers votre portefeuille Cashback.',
        totalCashback:
          'Le cashback total correspond au nombre de crédits bonus qui ont été convertis en espèces.',
        februaryCashback:
          'Les crédits bonus du mois en cours qui sont convertis en espèces. Vous pouvez les transférer vers votre compte de trading au début du mois suivant.',
        cashbackRedeemable:
          'Le nombre de crédits qui ont été convertis en espèces jusqu’à la fin du mois précédent. Vous pouvez transférer ce montant vers votre compte de trading à tout moment.',
        selectTheTradingAccount:
          ' Sélectionnez le compte de trading vers lequel vous souhaitez transférer votre cashback.',
        enterTheAmount: 'Montant (USD) que vous souhaitez transférer vers votre compte de trading.',
      },
      content: {
        claimContent1:
          'Désormais, chaque fois que vous approvisionnez votre compte réel avec 500 $ ou plus, nous vous offrons un bonus de 50 % qui apparaît dans le portefeuille Bonus sur la page d’accueil de votre Portail client ! Ensuite, lorsque vous traderez le FX, l’or ou le pétrole, vos crédits bonus seront convertis en argent réel, tel qu’indiqué dans le portefeuille Cashback ! Au début de chaque mois, les fonds de votre portefeuille Cashback peuvent être transférés vers vos comptes de trading réels pour être utilisés comme de vrais fonds !',
        claimContent2:
          'Encore mieux, pour chaque dépôt futur de 500 $ ou plus, votre bonus sera automatiquement crédité sur votre portefeuille Bonus afin de vous permettre de continuer à gagner encore plus.',
        howItWorksContent1:
          'Approvisionnez votre compte réel avec 500 $ ou plus, puis cliquez sur « Appliquer » et acceptez les Conditions générales pour recevoir votre bonus de 50 %.',
        howItWorksContent2:
          'Lorsque vous tradez le FX, l’or ou le pétrole, vos crédits bonus sont automatiquement convertis en argent réel, disponible pour le trading, ce que vous pouvez superviser dans votre « portefeuille Cashback » !',
        howItWorksContent3:
          'Au début de chaque mois, cliquez simplement sur « Échanger le cashback » dans votre « portefeuille Cashback » et échangez le montant affiché comme « cashback échangeable ».',
        stepsContent1: 'Déposez 500 $ ou plus et demandez votre bonus ci-dessous',
        stepsContent2:
          'Commencez à trader le FX, l’or et le pétrole pour convertir votre bonus en argent réel !',
        stepsContent3:
          'Demandez votre cashback suite à votre activité de trading des mois précédents !',
        depositTradEarnContent1: 'Plus vous tradez, plus vous gagnez.',
        depositTradEarnContent2:
          'Lorsque vous effectuez un dépôt de 500 $ ou plus, vous pouvez demander un bonus de dépôt de 50 % !',
        optInContent1:
          'Pour vous inscrire à la promotion Cashback de Moneta Markets, vous devez accepter les Conditions générales ci-dessous.',
        electTheDestinationAccount: 'Sélectionnez le compte de destination pour l’échange',
      },
      column: {
        date: 'DATE',
        deposit: 'DÉPÔTS',
        withdraws: 'RETRAITS',
        bonus: 'BONUS ÉCHANGEABLE',
        status: 'STATUT DU BONUS',
        account: 'COMPTE',
        redeem: 'CASHBACK ÉCHANGEABLE',
        reason: 'REMARQUES',
      },
      tnc: {
        title: 'Conditions générales',
        content: {
          item1: {
            title: 'Conditions générales du cashback',
            li1: 'Moneta Markets est une marque de commerce de Moneta Markets Ltd, enregistrée au Registre des sociétés commerciales internationales de Sainte-Lucie sous le numéro d’enregistrement 2023-00068.',
            li2: 'Les « Conditions générales du bonus en cashback » font partie intégrante des « Conditions générales de Moneta Markets Ltd ». Les dispositions énoncées dans les présentes sont considérées comme une annexe de l’ensemble des « Conditions générales de Moneta Markets Ltd » et elles doivent être lues conjointement.',
          },
          item2: {
            title: 'Conditions d’éligibilité',
            li1: 'Sous réserve et sans préjudice de toutes les autres Conditions générales, l’offre est à la disposition des clients de Moneta Markets qui satisfont aux critères d’éligibilité de l’offre (les « Clients éligibles ») comme indiqué dans les sections suivantes ci-après.',
            li2: 'Moneta Markets offre le « Bonus en cashback » aux clients qui ouvrent des comptes de trading réels (c’est-à-dire qui ne sont pas des comptes de démonstration) chez Moneta Markets.',
            li3: 'Le « Bonus en cashback » est disponible uniquement avec les comptes Direct STP et Prime ECN. Les comptes PAMM, MAM, Ultra ECN et « Cent » ne sont pas éligibles à une participation à la promotion « Bonus en cashback ». ',
            li4: 'Moneta Markets, à son entière discrétion, offre le « Bonus en cashback » à tout client de tout pays / région, tel que nous le jugeons approprié et aussi longtemps que Moneta Markets souhaite le proposer. La Société se réserve le droit de retirer et/ou d’annuler toute éligibilité selon les restrictions et/ou les exigences locales.',
            li5: 'Seules les personnes avec l’aptitude de conclure des contrats juridiquement contraignants en vertu des lois applicables dans leur pays de résidence peuvent participer au « Bonus en cashback ». Sans se limiter à ce qui précède, la participation au « Bonus en cashback » n’est pas autorisée pour les personnes « mineures » âgées de moins de 18 ans, car elles ne sont pas éligibles à l’ouverture d’un compte auprès de la Société',
            li6: 'La participation des « Intermédiaires / Parties liées » au « Bonus en cashback » est prohibée. Si les données d’inscription et/ou de trading d’un participant au « Bonus en cashback » correspondent aux informations d’inscription et/ou de trading, y compris, mais sans s’y limiter, l’adresse IP, d’un autre participant au « Bonus en cashback », Moneta Markets se réserve le droit considérer cette concordance comme un motif d’inéligibilité immédiate. Dans le cadre des présentes, le terme « Intermédiaire(s) / Partie(s) liée(s) », lorsqu’il est utilisé dans ces Conditions générales, sauf si le contexte l’exige autrement, signifiera toute personne ou entité entretenant une relation avec tout participant au « Bonus en cashback », y compris, sans s’y limiter :',
            li6_sub_items: {
              li1: 'les membres de la famille, tels que les frères, sœurs, conjoints, aïeuls, descendants en ligne directe et descendants collatéraux ;',
              li2: 'toute personne ou entité, qu’un participant au « Bonus en cashback », directement ou indirectement par le biais d’un ou plusieurs intermédiaires, contrôle, ou qui, directement ou indirectement, par le biais d’un ou plusieurs intermédiaires, est contrôlée par, ou est sous contrôle conjoint avec un participant au « Bonus en cashback ». Aux fins de cette définition, le terme « contrôle » (y compris, avec une signification corrélative, les termes « contrôlé par » et « sous contrôle conjoint avec »), tel qu’il est utilisé à l’égard de tout participant au « Bonus en cashback », désigne le fait de posséder, directement ou indirectement par le biais d’un ou plusieurs intermédiaires, le pouvoir de diriger ou d’orienter les politiques de gestion de cette personne ou entité, que ce soit par la propriété de titres avec droit de vote ou autrement.',
              li3: 'De façon similaire, les personnes associées de quelque manière que ce soit à Moneta Markets et/ou aux sites Web spécifiques et/ou aux sites de réseaux sociaux sur lesquels Moneta Markets peut partager de temps à autre des promotions, concours et/ou sondages spécifiques, dans le cadre duquel l’accès au « Bonus en cashback » est offert, ne sont pas autorisées à participer à l’offre.',
            },
          },
          item3: {
            title: 'Conditions spécifiques du « Bonus en cashback »',
            li1: 'Les clients doivent au préalable effectuer un dépôt minimal de 500 $, puis demander avec succès leur bonus de crédit de 50 % sur leur Portail client afin de recevoir les crédits bonus. Les crédits bonus des dépôts ultérieurs seront appliqués de façon automatique.',
            li1_sub_desc:
              '* Seuls les dépôts effectués pendant la durée de la promotion sont valides et seront pris en compte pour la promotion. Cela exclut les transferts internes, les bonus de parrainage et les ajustements en espèces.',
            li2: 'Le « Bonus en cashback » est disponible uniquement avec les comptes Direct STP et Prime ECN. Les comptes Ultra ECN et « Cent » ne sont pas éligibles à la participation à la promotion « Bonus en cashback ».',
            li3: 'Les clients éligibles (clients nouveaux et existants), qui satisfont aux critères énoncés dans les présentes, peuvent recevoir un cashback égal à :',
            li3_sub_content: {
              title1: '[Tableau de cashback client STP et ECN en direct]',
              info1: 'Direct STP : 2 $ par lot FX, de pétrole et d’or tradé',
              info2: 'Prime ECN : 0,50 $ par lot FX, de pétrole et d’or tradé ',
              title2: '[Tableau de cashback client STP et ECN sous IB]',
              info3: 'Direct STP : 0,50 $ par lot FX, de pétrole et d’or tradé',
              info4: 'Prime ECN : 0,25 $ par lot FX, de pétrole et d’or tradé   ',
            },
            li3_sub_desc:
              '* La taille minimale des lots de trading cumulés pour laquelle le cashback peut être déclenché est de 0,01 lot standard. Si le lot de trading valide du client à cette date n’atteint pas le seuil de 0,1 lot standard, il sera remis à zéro. Le nombre cumulé de lots est un multiple de 0,01 lot standard, par exemple 0,02 lot standard, 0,03 lot standard (0,12 lot standard sera calculé comme 0,12 lot standard).',
            li4: 'Les produits éligibles : Tous les produits Forex, l’or, et le pétrole',
            li5: 'Le volume de trading effectif : ordres nouvellement ouverts et clôturés pendant la durée de la promotion. Les positions ouvertes depuis moins de 10 minutes, les positions verrouillées et les positions couvertes ne sont pas comptabilisées comme des trades valides pour cette promotion.',
            li6: 'Cette offre peut être cumulée avec toute autre offre. ',
            li7: 'Tout retrait de fonds entraînera la suppression d’un montant égal en crédits du portefeuille Bonus de l’utilisateur. Si le montant du retrait est supérieur au montant du crédit bonus, le montant du crédit sera réduit à un solde nul.',
            li8: 'Moneta Markets ne sera pas responsable des coûts, dépenses ou dommages qui pourraient être subis à la suite de la réinitialisation ou de l’ajustement du compte du client par Moneta Markets, comme prévu par les présentes Conditions générales, y compris, mais sans s’y limiter, les pertes attribuables aux positions ouvertes clôturées de façon automatique.',
            li9: 'Moneta Markets se réserve le droit, à sa seule discrétion :',
            li9_sub_items: {
              li1: 'de refuser l’inscription de tout participant au « Bonus en cashback » ; et',
              li2: 'de disqualifier tout participant au « Bonus en cashback » qui falsifie ou enfreint ces Conditions générales et/ou l’une des « Conditions générales de Moneta Markets » disponibles sur le site Web de Moneta Markets.',
            },
            li10: 'Moneta Markets se réserve le droit, à sa discrétion raisonnable, d’interrompre l’offre de ce « Bonus en cashback » proposée à l’un de ses clients, sans avoir à en expliquer les raisons. Ces clients seront informés par e-mail.',
            li11: 'Moneta Markets se réserve le droit de modifier, amender ou résilier le « Bonus en cashback » ou tout aspect de celui-ci à tout moment sans avoir à indemniser qui que ce soit pour cela. Une notification préalable à propos de ces modifications sera communiquée aux clients. Veuillez noter que la participation au « Bonus en cashback » constitue une acceptation et un accord de se conformer à ces modifications, amendements et/ou changements.',
            li12: 'Toute indication ou suspicion, à la seule discrétion de Moneta Markets, de toute forme d’arbitrage (y compris, mais sans s’y limiter, le profit sans risque), d’abus (y compris, mais sans s’y limiter, les schémas d’activité de trading du participant qui indiquent que le participant vise uniquement à bénéficier financièrement du cashback sans véritablement s’intéresser au trading sur les marchés et/ou à prendre des risques de marché), la fraude, la manipulation, l’arbitrage de cashback associé à une promotion de cashback ou toute autre forme d’activité trompeuse ou frauduleuse, annulera tout cashback antérieur des comptes de trading réels du client éligible auprès de Moneta Markets et/ou toutes les transactions effectuées et/ou tous les profits ou pertes engrangés avec celles-ci. Dans ces circonstances, Moneta Markets se réserve le droit, à sa seule discrétion, de clôturer / suspendre (temporairement ou définitivement) tous les comptes de trading réels de ce client éligible chez Moneta Markets, d’annuler tous les ordres et d’annuler tous les profits de ce participant. Dans ces circonstances, Moneta Markets ne sera pas responsable des conséquences de l’annulation du cashback, y compris, mais sans s’y limiter, la clôture d’ordre(s) par Stop out.',
            li13: 'La participation au « Bonus en cashback » vaut acceptation des présentes Conditions générales et des Conditions générales de Moneta Markets disponibles sur le site Web de Moneta Markets.',
            li14: 'Le « Bonus en cashback » est disponible pour une durée limitée, comme indiqué dans les règles d’engagement de la promotion spécifique, et/ou de l’enquête lorsque le « Bonus en cashback » est mentionné en faire partie.',
            li15: 'Si les présentes Conditions générales du « Bonus en cashback » sont traduites dans une langue autre que l’anglais, la version anglaise prévaudra en cas d’interprétation incompatible.',
            li16: "Le bonus en cashback n'est pas éligible pour les clients résidant en Turquie.",
            li17: 'À partir du 19 juillet 2023, seuls les dépôts de 500 $ ou plus sont éligibles au bonus en cashback.',
          },
          item4: {
            title: 'Avertissement sur les risques',
            li1: 'Le trading de produits dérivés et du Forex comporte un niveau de risque élevé pour votre capital et ils ne doivent être tradés qu’avec de l’argent que vous pouvez vous permettre de perdre. Assurez-vous de lire les documents présents sur notre page Documents juridiques et demandez des conseils indépendants afin de bien comprendre les risques avant de décider de trader. Cette promotion est élaborée sans tenir compte de vos objectifs, de votre situation financière ou de vos besoins.',
          },
        },
      },
      faqsList: {
        question_1:
          'Qu’est-ce que le programme de cashback de Moneta Markets et comment fonctionne-t-il ?',
        question_2: 'Comment puis-je m’inscrire à la promotion de cashback ?',
        question_3: 'Qu’est-ce que sont les crédits bonus ?',
        question_4: 'Qu’est-ce que le cashback ?',
        question_5: 'Comment puis-je consulter mes soldes de bonus et de cashback ?',
        question_6: 'Mes crédits bonus peuvent-ils expirer ?',
        question_7: 'Mon solde de cashback peut-il expirer ?',
        question_8: 'En quoi consistent les profits ?',
        question_9: 'Combien de crédits bonus puis-je gagner ?',
        question_10: 'Combien de cashback puis-je gagner ?',
        question_11: 'Quand puis-je retirer mon bonus de cashback ?',
        question_12:
          'Comment puis-je transférer mon cashback disponible vers mon compte de trading ?',
        question_13: 'À quelle fréquence mon cashback est-il calculé ?',
        question_14: 'Puis-je gagner du cashback avec tous les produits ?',
        question_15: 'Comment puis-je retirer mon bonus de cashback ?',
        question_16: 'Les retraits auront-ils un impact sur mes crédits bonus ?',
        question_17: 'Les retraits auront-ils un impact sur mon bonus en cashback ?',
        question_18:
          'Y a-t-il une durée minimale des trades pour la conversion de mon bonus en cashback ?',
        question_19: 'Comment puis-je augmenter mes crédits bonus ?',
        question_20: 'Comment puis-je maximiser mon cashback mensuel ?',
        question_21:
          'Est-ce que je gagne le même montant de cashback avec les comptes Direct STP et Prime ECN ?',
        question_22: 'Est-ce que je gagne du cashback avec les comptes Ultra ECN ou Cent ?',
        question_23: 'Quels sont les statuts de bonus et que signifient-ils ?',
        question_24: 'Comment se désinscrire du programme Cashback ?',
        answer_1:
          'Le programme de cashback de Moneta Markets est un programme de fidélité dans le cadre duquel les clients font un dépôt minimal de 500 $ et reçoivent un crédit bonus de 50 %. Ces crédits bonus sont ensuite convertis en espèces pouvant être retirées / tradées en fonction de l’activité de trading sur les produits FX, or et pétrole.',
        answer_2:
          'Les clients de Moneta Markets disposant d’un compte de trading éligible devront « solliciter » leur premier montant de crédit bonus sur le Portail client de Moneta Markets. Cliquez simplement sur « Demander un bonus » dans le portefeuille de bonus sur la page d’accueil du Portail client. Cliquez sur le bouton « Demander » dans le tableau intitulé « Vos gains actuels et votre historique », puis acceptez les Conditions générales. Les crédits bonus suivants gagnés avec les dépôts éligibles seront appliqués de façon automatique.',
        answer_3:
          'Les crédits bonus sont gagnés avec les dépôts éligibles de 500 $ ou plus, à un taux de 50 % par dépôt éligible. Les crédits bonus sont conservés dans votre portefeuille bonus et ils sont convertis en cashback réel en fonction de votre activité de trading sur tous les produits FX, or et pétrole.',
        answer_4:
          'Votre cashback est le montant en espèces gagné sur la base de la conversion des crédits bonus suite à votre activité de trading. Le montant de votre cashback peut être transféré vers votre compte de trading et utilisé comme de l’argent réel.',
        answer_5:
          'Vous pouvez consulter vos soldes sur la page d’accueil de votre Portail client, où votre portefeuille Bonus et votre portefeuille Cashback sont affichés. Pour une répartition de votre portefeuille Cashback, cliquez simplement sur « Promotions », « Cashback » ou « Échanger le cashback » dans le « Portefeuille cashback » de la page d’accueil de votre Portail client, où vous pouvez voir votre total de cashback, le cashback échangeable et les montants de cashback du mois en cours.',
        answer_6:
          'Non, les crédits bonus n’expirent pas. Lorsque vous vous inscrivez pour le bonus en cashback, tous les dépôts et retraits seront pris en compte sur les 30 jours précédents pour le calcul de votre bonus disponible. Tous les crédits bonus gagnés par la suite sur les dépôts ultérieurs sont appliqués de façon automatique et n’expirent pas. Vous pouvez afficher le statut de vos gains de bonus actuels et votre historique dans le tableau intitulé « Vos gains actuels et votre historiques » qui affichera soit « Demander », « Crédité », « Inéligible » ou « Déduit ».',
        answer_7:
          'Les montants des bonus en cashback gagnés n’expireront pas et il dépend de vous de les transférer vers votre compte de trading pour le trading réel ou de les retirer au début de chaque mois.',
        answer_8:
          'Nous vous apprécions comme client et nous souhaitons vous remercier pour votre trading avec Moneta Markets. De ce fait, vous recevrez une prime sous forme de crédits bonus sur tous les dépôts éligibles. Ensuite, tandis que vous continuez à trader avec nous, vos crédits sont convertis en argent réel en remerciement pour votre fidélité.',
        answer_9:
          'Lorsque vous effectuez un dépôt de 500 $ ou plus, vous recevez 50 % de ce montant en crédits bonus. Ensuite, pour chaque dépôt éligible ultérieur, vous continuerez à accumuler des crédits bonus.',
        answer_10:
          'Le montant de cashback que vous pouvez gagner dépend de votre type de compte et des produits que vous aimez trader. Vous pouvez maximiser vos gains en tradant le FX, le pétrole et l’or avec un compte Direct STP de Moneta Markets.<br/><br/>Vos crédits bonus sont convertis en espèces au taux ci-dessous :<br/><br/>[Tableau de cashback Direct STP et Prime ECN]<br/>Direct STP : 2 $ par lot de FX, de pétrole et d’or tradé<br/>Prime ECN : 0,50 $ par lot de FX, de pétrole et d’or tradé',
        answer_11:
          'Vous avez la possibilité de transférer le solde indiqué comme « Cashback disponible » vers votre compte de trading à tout moment. Par ailleurs, le cashback gagné au cours du mois en cours est disponible pour être transféré vers votre compte de trading au début du mois suivant.',
        answer_12:
          'Tout ce que vous avez à faire est de cliquer sur « Échanger le cashback » dans votre portefeuille Cashback, de sélectionner le compte de trading et le montant du cashback que vous souhaitez transférer, et de cliquer à nouveau sur « Échanger le cashback ».',
        answer_13:
          'Le cashback gagné au cours de votre activité de trading sur tous les produits FX, or et pétrole est calculé chaque jour à GMT+2 et il apparaîtra dans votre portefeuille Cashback.',
        answer_14:
          'Le bonus en cashback peut être gagné sur tous les produits FX, or et pétrole avec les comptes Direct STP et Prime ECN selon le tableau ci-dessous.<br/><br/>[Tableau du cashback Direct STP et Prime ECN]<br/>Direct STP : 0,50 $ par lot de FX, de pétrole et d’or tradé<br/>Prime ECN : 0,25 $ par lot de FX, de pétrole et d’or tradé.',
        answer_15:
          'Le retrait de votre bonus en cashback disponible est simple. Tout ce que vous avez à faire est de cliquer sur « Échanger le cashback » dans votre portefeuille Cashback, de sélectionner le compte de trading et le montant du cashback que vous souhaitez transférer, et de cliquer à nouveau sur « Échanger le cashback ». Après le transfert des fonds vers votre compte de trading, vous pouvez soit les utiliser pour trader, soit soumettre une demande de retrait.',
        answer_16:
          'Les crédits détenus dans votre portefeuille Bonus seront déduits au prorata si vous retirez des fonds. Par exemple, si votre portefeuille Bonus contient 500 crédits et que vous choisissez de retirer 250 $ de votre compte de trading, 250 crédits bonus seront également déduits. Notez que vos crédits bonus ne se trouveront jamais en situation de solde négatif, quel que soit le montant que vous choisissez de retirer.',
        answer_17:
          'Non. Votre bonus en cashback vous appartient et il ne sera pas impacté par les retraits que vous pouvez effectuer.',
        answer_18:
          'Tout trade de FX, d’or ou de pétrole doit être détenu pendant au moins 10 minutes afin que votre bonus soit converti en cashback.',
        answer_19:
          'Vos crédits bonus s’accumulent chaque fois que vous effectuez un dépôt éligible. Pour chaque dépôt de 500 $ ou plus, vous recevrez 50 % de ce montant en crédits bonus.',
        answer_20:
          'Pour maximiser le montant de cashback que vous pouvez gagner, deux facteurs interviennent : votre type de compte et votre activité de trading sur les produits FX, or et pétrole, avec des comptes Direct STP dont la conversion en cashback est à un taux plus élevé qu’avec les comptes Prime ECN. Assurez-vous simplement que vous disposez d’une quantité suffisante de crédits dans votre portefeuille Bonus et tradez le FX, l’or et le pétrole sur un compte Direct STP afin de maximiser votre cashback. Plus vous effectuez de trades et plus le volume que vous tradez est élevé, plus vos crédits bonus seront rapidement convertis en cashback.',
        answer_21:
          'Le montant du cashback gagné sur le FX, l’or et le pétrole diffère selon les types de compte.<br/><br/>[Tableau du cashback Direct STP et Prime ECN]<br/>Direct STP : 0,50 $ par lot de FX, pétrole et or tradé<br/>Prime ECN : 0,25 $ par lot de FX, pétrole et or tradé.',
        answer_22:
          'Malheureusement, les comptes Ultra ECN ou Cent n’accumulent aucun cashback avec le trading du FX, de l’or ou du pétrole. Vous devez trader avec les comptes Direct STP ou Prime ECN.',
        answer_23:
          'Demander : La première fois que vous effectuez un dépôt éligible, vous devez cliquer sur « Demander » pour que votre bonus soit crédité. Tous les dépôts ultérieurs seront crédités de façon automatique.<br/>Crédité : Le bonus a été crédité et il apparaîtra dans votre portefeuille Bonus<br/>Non éligible : Le bonus n’a pas atteint l’exigence minimale de 500 $<br/>Déduit : Le bonus a été déduit suite au retrait de fonds depuis votre compte de trading.',
        answer_24:
          'Si vous ne souhaitez plus participer au programme, adressez votre demande de désinscription à <a href="mailto:{email}">{email}</a>',
      },
      reason: {
        1: 'Un bonus de 50 % a été ajouté à votre portefeuille Bonus',
        2: 'Ajustement manuel du bonus - Crédité',
        3: 'Le montant du retrait a été supprimé de votre portefeuille Bonus',
        4: 'Ce montant de bonus a été converti en cashback',
        5: 'Ajustement manuel du bonus - Déduit',
        6: 'Ce dépôt n’a pas atteint le montant minimal pour être éligible',
      },
      status: { 0: 'Demander', 1: 'Crédité', 2: 'Déduit', 3: 'Inéligible' },
      zeroMsg: 'Il ne vous reste plus de cashback à échanger',
    },
  },
  hmcTraderPortal: {
    overview: 'Aperçu de la plate-forme',
    interactiveAnalysis: 'Analyse Interactive',
    learnFromTheBest: 'Apprendre des meilleurs',
    requestAResearch: 'Demande de Recherche',
    vipSupport: 'Assistance VIP',
    eligibility: 'Éligibilité',
    login: 'Connectez-vous au HMC Salle de trading en direct',
    depositRequirement:
      'Remplir les conditions de dépôt pour avoir accès au HMC Salle de trading en direct.',
    basic: 'Basique',
    vip: 'VIP',
    exclusiveAnalysis: 'Analyse Exclusive',
    analysisDiscussions: "Discussions sur l'Analyse",
    tradingSignals: 'Signaux de Trading',
    tradingSignalRequest: 'Demande de Signaux de Trading',
    privateAnalysisRequest: "Demande d'Analyse Privée",
    prioritySupport: 'Assistance Prioritaire',
    minimumFundingRequirement:
      'Conditions de Financement Minimum et opérations de 200 000 $ de valeur notionnelle sur les transactions fermées chaque mois*.',
    tnc1: "Veuillez noter que les informations présentées sur <a href=\"{url}\" target=\"_blank\">portal.hmctrader.com</a> sont fournies à Moneta ('Moneta Global Limited', 'nous') par un fournisseur tiers ('HMC Trader'). Il est entièrement destiné à des fins de recherche et d'information uniquement et ne constitue pas un conseil financier pour acheter, vendre ou détenir un produit particulier ou poursuivre une stratégie d'investissement particulière. Les informations ne sont pas adaptées aux besoins d'investissement d'une personne spécifique et n'impliquent donc pas une prise en compte des objectifs d'investissement, de la situation financière ou des besoins de tout spectateur susceptible de les recevoir. Veuillez également noter que les performances passées ne sont pas un indicateur fiable des performances et/ou résultats futurs. Les résultats réels peuvent différer sensiblement de ceux prévus dans les déclarations prospectives ou de performance passée. Nous n'assumons aucune responsabilité quant à l'exactitude ou l'exhaustivité des informations fournies ici, ni aucune perte pouvant résulter des informations fournies par HMC Trader.",
    tnc2: "*Pour pouvoir utiliser le service du HMC Salle de trading en direct, vous devez remplir les conditions de financement minimum initial indiquées ci-dessus depuis la date de lancement. Vous devrez effectuer des transactions fermées d'une valeur notionnelle d'au moins 200 000 $ sur des produits offerts par Moneta chaque mois pour conserver votre droit d'accès au HMC Salle de trading en direct. Votre autorisation d'accès sera automatiquement désactivée si vous n'effectuez pas des transactions fermées d'une valeur notionnelle d'au moins 200 000 $ au cours d'un mois. Vous êtes obligé de déposer à nouveau le montant requis pour réactiver l'accès au HMC Salle de trading en direct.",
  },
  supportTickets: {
    support: 'Support',
    mySupportTickets: 'Mes tickets de support',
    createNewTicket: 'Créer un nouveau ticket',
    ticketNo: 'Ticket N°',
    subject: 'Objet',
    status: 'Statut',
    lastUpdated: 'Dernière mise à jour',
    newTicketRequest: 'Créer une nouvelle demande de ticket',
    content: 'Contenu',
    attachments: 'Pièces jointes',
    upload: 'Télécharger',
    viewingTicket: 'Afficher le ticket N°',
    postReply: 'Poster une réponse',
    update: 'Actualiser',
    cancel: 'Annuler',
    close: 'Fermer',
    number: 'N°',
    description: 'La description',
    postReplyRequired: 'Le champ "Publier une réponse" est obligatoire',
    contentFieldRequired: 'Le champ "Message" est obligatoire',
    subjectFieldRequired: 'Le champ "Objet" est obligatoire',
    successMsg: 'Votre ticket de support a été créé avec succès !',
  },
  responseMsg: {
    410: 'Échec de la validation du paramètre',
    411: 'Mot de passe incorrect',
    420: "L'utilisateur n'existe pas",
    421: 'Réinitialisation du mot de passe refusée',
    490: 'Ne soumettez pas les informations de votre carte en double',
    500: "Une erreur s'est produite.",
    501: 'Échec de la demande. Veuillez réessayer plus tard.',
    505: 'Échec du téléchargement du fichier',
    510: 'Code SMS incorrect',
    511: "Impossible d'envoyer le code SMS sur votre téléphone",
    520: 'Échec de connexion. Veuillez réessayer.',
    521: 'Un utilisateur est déjà connecté sur cet appareil !',
    522: 'Veuillez vous reconnecter',
    523: 'Veuillez vous reconnecter',
    524: "Malheureusement votre adresse e-mail n'a pas été reconnue",
    525: "Nom d'utilisateur ou mot de passe incorrect",
    526: 'Le captcha ne correspond pas',
    527: "L'utilisateur n'est pas un apporteur d'affaires",
    528: "L'utilisateur n'existe pas",
    529: "Nom d'utilisateur ou mot de passe incorrect",
    530: 'Session de connexion non valide',
    531: 'Code invalide',
    540: 'Votre compte de trading est introuvable',
    541: 'Compte de bonus introuvable',
    542: "Vous n'êtes pas autorisé à ouvrir un compte supplémentaire",
    544: "Vous n'êtes pas autorisé à demander un compte supplémentaire tant que votre pièce d'identité n'a pas été vérifiée",
    550: 'Numéro de compte incorrect',
    551: 'Votre solde est insuffisant',
    554: 'La somme demandée est égale à zéro ou est nulle',
    562: "Nous n'avons pas pu traiter votre paiement. Veuillez réessayer. Si le problème persiste, contactez-nous via le chat ou par e-mail {email}",
    564: 'Le pays ne correspond pas au canal de paiement',
    565: 'Code de banque incorrect',
    566: 'Méthode de paiement introuvable',
    567: 'Malheureusement, ce mode de paiement est actuellement indisponible. Nous nous excusons pour toute gêne occasionnée',
    568: 'Ce compte est restreint pour effectuer un dépôt. Contactez-nous à {email}',
    569: "Votre prénom/nom/numéro est obligatoire. Si vous avez besoin d'aide, contactez-nous à l'adresse : {depositEmail}",
    581: 'Vous avez bien choisi la promotion. Veuillez noter que vous devez effectuer un dépôt pour retirer des fonds.',
    584: "Vous n'avez aucune bonus applicable",
    587: 'Montant du retrait invalide.',
    590: 'Compte du transfert sortant introuvable',
    591: 'Compte du transfert entrant introuvable',
    593: 'Nous ne sommes pas en mesure de traiter votre transfert de fonds depuis le compte sélectionné car votre compte contient du crédit.',
    594: "Le transfert de fonds depuis ce compte n'est pas autorisé",
    624: "Le solde négatif de ce compte ne peut être éliminé. Veuillez contacter support@monetamarkets.com pour plus d'informations.",
    625: 'Votre demande a été reçue et est en cours de traitement. Si vous avez des questions, veuillez contacter support@monetamarkets.com.',
    626: 'Nous avons remarqué que votre solde est supérieur à zéro. Veuillez rafraîchir la page et vérifier à nouveau.',
    647: 'Le code de vérification est incorrect',
    1001: 'Votre compte est temporairement indisponible. Veuillez contacter votre gestionnaire de compte',
    1102: "Impossible d'activer, le compte a été créé en tant que compte réel ou démo.",
    1103: 'Le compte a été désactivé',
    1202: 'La vérification a expiré, veuillez réessayer',
    1204: 'Veuillez essayer avec une autre adresse e-mail',
    1208: 'Les codes de vérification ne peuvent pas être envoyés à plusieurs reprises',
    1209: "Erreur d'envoi du code de vérification",
    session_timeout: 'Expiration de la session. Redirection vers la page de connexion.',
    vload: {
      4019: "Le type de coupon ou la valeur n'est pas accepté",
      4071: 'Suspicion de fraude',
      4072: 'Le coupon est utilisé',
      4073: "Le coupon n'est pas actif",
      4074: 'Le coupon est expiré',
      4075: "Le coupon n'a pas été trouvé",
      4080: 'La valeur du coupon est insuffisante',
      4444: 'Coupon utilisé non valide. Non-concordance des devises.',
      5000: 'Les données de la boutique sont indisponibles',
    },
  },
  lock: {
    premium: {
      trading: {
        tools: {
          description:
            'Pour débloquer les fonctionnalités premium, complétez votre inscription maintenant',
        },
      },
    },
  },
};
