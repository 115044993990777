<template>
  <el-form :model="apiForm" ref="ruleForm">
    <section>
      <p>{{ $t('register.moreAboutYou.complianceExplain') }}</p>
    </section>
    <ul>
      <li v-for="q in EFQuestion" v-bind:key="q.questionId">
        <el-form-item
          :prop="`${0}_${q.questionId}`"
          :rules="{ required: true, message: $t('common.formValidation.answerReq'), trigger: 'blur' }"
        >
          <div class="row">
            <div class="label">{{ q.desc }}</div>
            <div class="question">
              <el-radio-group :disabled="disableField" v-model="apiForm[`${0}_${q.questionId}`]" data-testid="question">
                <el-radio-button v-for="qo in q.questionOptions" v-bind:key="qo.id" :label="qo.id" :data-testid="qo.id">
                  {{ qo.desc }}
                </el-radio-button>
              </el-radio-group>
            </div>
          </div>
        </el-form-item>
      </li>
    </ul>
    <div>
      <h3 class="register-title">{{ $t('register.moreAboutYou.pageTitle2') }}</h3>
      <ul class="clearfix">
        <li v-for="q in tradingQuestions" v-bind:key="q.questionId">
          <el-form-item
            :prop="`${1}_${q.questionId}`"
            :rules="{ required: true, message: $t('common.formValidation.answerReq'), trigger: 'blur' }"
          >
            <div class="row">
              <div class="label">{{ q.desc }}</div>
              <div class="question">
                <el-radio-group :disabled="disableField" v-model="apiForm[`${1}_${q.questionId}`]">
                  <el-radio-button v-for="qo in q.questionOptions" v-bind:key="qo.id" :label="qo.id">
                    {{ qo.desc }}
                  </el-radio-button>
                </el-radio-group>
              </div>
            </div>
          </el-form-item>
        </li>
      </ul>
    </div>
  </el-form>
</template>

<script>
import moreAboutYou from '@/mixins/register/moreAboutYou';
import { apiGetData } from '@/resource/register';

export default {
  mixins: [moreAboutYou],
  mounted() {
    const vm = this;
    Promise.all([
      this.$store.dispatch('register/actionEFQuestion', this.lang),
      this.$store.dispatch('register/actionTradingQuestion', this.lang)
    ]).then(function(values) {
      let questionForm = {};

      values.map((m, i) => {
        m.map((mc, j) => {
          questionForm[`${i}_${mc.questionId}`] = '';
        });
      });
      console.log(questionForm);

      vm.apiForm = questionForm;
      vm.getUserDate();
    });
  },
  methods: {
    submit() {
      let employmentFinanceAnswers = [],
        tradingAnswers = [];
      Object.keys(this.apiForm).map((m, i) => {
        const split = m.split('_');
        split[0] == 0
          ? employmentFinanceAnswers.push({ questionId: split[1], answers: [this.apiForm[m]] })
          : tradingAnswers.push({ questionId: split[1], answers: [this.apiForm[m]] });
      });
      this.form.employmentFinanceAnswers = employmentFinanceAnswers;
      this.form.tradingAnswers = tradingAnswers;
    },
    getUserDate() {
      apiGetData(3).then(resp => {
        const userData = resp.data.data;
        userData.employmentFinanceAnswers.map((m, i) => {
          this.apiForm[`0_${m.questionId}`] = m.answers[0];
        });

        userData.tradingAnswers.map((m, i) => {
          this.apiForm[`1_${m.questionId}`] = m.answers[0];
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/moreAboutYou.scss';
</style>
