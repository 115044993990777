import { apiProclientGetCurrentStep, apiWealthTests, apiWholesaleTests } from '@/resource'

export default {
  async actionGetProclientGetCurrentStep({ commit, state }, regulator) {
    if (state.asicUpgradeStep !== '') return
    await apiProclientGetCurrentStep().then(result => {
      if (result.data.code === 0) {
        if (regulator === 'asic') {
          commit('setAsicUpgradeStep', result.data.data)
        }

        if (regulator === 'fca') {
          commit('setFcaUpgradeStep', result.data.data)
        }
      }
    })
  },
  async actionGetWealthTests({ commit, state }, lang) {
    if (state.wealthQuestion.length > 0) return
    await apiWealthTests(lang).then(result => {
      if (result.data.code === 0) commit('setWealthQuestion', result.data.data)
    })
  },
  async actionGetExtraTests({ commit, state }, lang) {
    if (state.extraQuestion.length > 0) return
    await apiWholesaleTests(lang).then(result => {
      if (result.data.code === 0) commit('setExtraQuestion', result.data.data)
    })
  },
}
