export default {
  common: {
    liveChat: { desc: '<li><p>¿Necesita ayuda?</p><span>Chat en vivo</span></li>' },
    country: { international: 'Internacional', australia: 'Australia', uk: 'Reino Unido' },
    verification: { toSlide: 'Deslícese para completar el rompecabezas' },
    hover: {
      BankName: 'El nombre del banco o institución financiera que recibe los fondos.',
      BankBeneficiaryName:
        'El nombre de la persona o empresa titular de la cuenta bancaria que recibe los fondos.',
      AccountHoldersAddress:
        'La dirección física de la persona o empresa titular de la cuenta bancaria.',
      BankAddress: 'La dirección física del banco o institución financiera que recibe los fondos.',
      BankAccountNumber:
        'El número de cuenta bancaria o IBAN (Número de Cuenta Bancaria Internacional, por sus siglas en inglés) es el número utilizado para identificar su cuenta bancaria.',
      SwiftCode:
        'El código SWIFT, conocido también como Código de Identificación Bancaria (BIC, por sus siglas en inglés) es un código internacional utilizado para identificar el país, banco y sucursal, y es necesario cuando el dinero se transfiere internacionalmente.',
    },
    field: {
      imptNotes: 'Notas importantes',
      nameOnCard: 'Nombre en la tarjeta',
      first4Digits: '4 primeros dígitos de la tarjeta',
      last3Digits: '3 últiamos dígitos de la tarjeta',
      first6Digits: '6 primeros dígitos de la tarjeta',
      last4Digits: '4 últimos dígitos de la tarjeta',
      branchAddress: 'Dirección de la sucursal',
      bankBeneAccName: 'Nombre de la cuenta del beneficiario del banco',
      bankBeneAccNum: 'Número de cuenta del beneficiario del banco',
      bankBeneAddress: 'Dirección del beneficiario',
      bsbNum: 'Número BSB',
      bankCity: 'Ciudad del banco',
      bankProvince: 'Provincia del banco',
      bankCode: 'Código bancario',
      bankBranchCode: 'Código de sucursal',
      swift: 'Código SWIFT',
      region: 'Región',
      swiftNonAUD: 'Código SWIFT (No cuentas AUD)',
      accName: 'Nombre de la cuenta',
      bankAccName: 'Nombre de la cuenta bancaria',
      accNum: 'Número de cuenta',
      accNumTo: 'Número de cuenta',
      bankName: 'Nombre del banco',
      bankAddress: 'Dirección bancaria',
      bankBranch: 'Sucursal bancaria',
      bankBeneficiaryName: 'Nombre del beneficiario del banco',
      bankHolderAddress: 'Dirección del titular de la cuenta',
      bankAccNum: 'Número de cuenta bancaria',
      address: 'Dirección',
      yes: 'Sí',
      payMethod: 'Método de pago',
      sort: 'Código de sucursa',
      ifscCode: 'Código IFSC',
      amt: 'Cantidad',
      amtUSD: 'Cantidad(USD)',
      bsb: 'BSB',
      name: 'Nombre',
      emailAdd: 'Dirección de email',
      phone: 'Número de teléfono',
      completed: 'Completado',
      incomplete: 'Incompleto',
      successful: 'Exitoso',
      submitted: 'Enviado',
      processing: 'Procesando',
      cancelled: 'Cancelado',
      failed: 'Fallido',
      rejected: 'Rechazado',
      notes: 'Notas',
      upload: 'Enviar',
      abaSortCode: 'Código de sucursal/ABA',
      country_region: 'País/Región:',
      currLeverage: 'Apalancamiento actual',
      accEquity: 'Capital de la cuenta',
      newLeverage: 'Apalancamiento deseado',
      currPw: 'Contraseña actual',
      newPw: 'Nueva contraseña',
      confirmPw: 'Confirmar nueva contraseña',
      loginEmail: 'Email de inicio de sesión',
      email: 'Correo electrónico',
      pw: 'Contraseña',
      accType: 'Tipo de cuenta',
      countryCode: 'Country',
      dob: 'Fecha de nacimiento',
      nat: 'Nacionalidad',
      country: 'País de residencia',
      street: 'Número de la calle/Nombre de la calle',
      suburbOrCity: 'Barrio/Ciudad',
      provinceOrState: 'Provincia/Estado',
      postcode: 'Código postal',
      empStat: 'Situación Profesional',
      occupation: 'Ocupación',
      annIncome: 'Ingresos anuales',
      save: 'Ahorro/Inversión',
      source: 'Procedencia de los ingresos',
      industry: 'Industria',
      statusDef: 'DESCRIPCIÓN DE ESTADO',
      month: 'Mes',
      year: 'Año',
      select: 'Seleccionar',
      ccNum: 'Número de la tarjeta de crédito',
      expDate: 'Fecha de vencimiento',
      bankCardNumber: 'Número de la tarjeta bancaria',
      cardHolderName: 'Nombre del titular de la tarjeta',
      reservedMobileNumber: 'Número de teléfono móvil para el banco',
      nationalId: 'Documento nacional de identidad',
      cardPhoto: 'Foto de la tarjeta (anverso)',
      bankAccOrIBAN: 'Número de la cuenta bancaria/IBAN',
      selectWithdrawCard: 'Seleccione su tarjeta para la retirada',
      updateCardPhoto: 'Por favor, envíe la foto de su tarjeta (anverso)',
      transitNumber: 'Número de tránsito',
      institutionNumber: 'Número de la institución',
      wallet: 'Cartera',
      resetBalTo: 'Restablecer saldo a',
      confirmation: '¿Está seguro?',
      removeNote: '¿Desea realmente eliminar este registro? Esta acción no se podrá deshacer.',
      depositCurrency: 'Moneda de depósito',
      bankAccountCurrency: 'Divisa de la cuenta bancaria',
      ipChangeWarning:
        'Reciba una alerta por correo electrónico cuando alguien acceda a su cuenta desde una ubicación distinta',
      documentType: 'Dokumenta Tipo',
      documentDetails: 'Dokumentaj Detaloj',
      pixKeyType: 'PIX Ŝlosila Tipo',
      pixKey: 'PIX Ŝlosilo',
      enterVerificationCode: 'Introducir código de verificación',
      accDigit: 'Dígito de la cuenta',
      docID: 'ID del documento',
      paymentAccName: '{name} Nombre de la cuenta',
      paymentAccNum: '{name} Número de cuenta',
      beneficiaryIBANNumber: 'Número IBAN de la cuenta del beneficiario',
      beneficiaryBankSwift: 'Código SWIFT del banco beneficiario',
      beneficiaryBankName: 'Nombre del banco beneficiario',
      beneficiaryBankAddress: 'Dirección del banco beneficiario',
      beneficiaryBankSortCode: 'Código de sucursal del banco beneficiario',
      beneAccNum: 'Número de cuenta del beneficiario',
      reversedfailed: 'Fracaso Revertido',
      cardNumber: 'Número de tarjeta',
      walletNumber: 'Número de billetera {name}',
      beneficiaryName: 'Nom du bénéficiaire',
    },
    column: {
      date: 'FECHA',
      status: 'ESTADO',
      accNum: 'NÚMERO DE CUENTA',
      type: 'TIPO',
      server: 'SERVIDOR',
      comment: 'COMENTARIO',
      currency: 'DIVISA',
      equity: 'CAPITAL',
      credits: 'CRÉDITOS',
      balance: 'BALANCE',
      leverage: 'APALANCAMIENTO',
      resetMT4PW: 'RESTABLECER CONTRASEÑA MT4',
      resetMT5PW: 'RESTABLECER CONTRASEÑA MT5',
      tradingAcc: 'CUENTA DE TRADING',
      method: 'MÉTODO',
      amt: 'CANTIDAD',
      procNote: 'NOTAS DE PROCESO',
      number: 'NÚMERO DE LA TARJETA',
      resetBal: 'Restablecer saldo',
      remove: 'Eliminar',
    },
    key: { from: 'DESDE', to: 'HASTA' },
    fund: {
      must: 'DEBE',
      infoHover: 'El nombre de su tarjeta {must} coincidir con el nombre de su cuenta de trading',
      ccUploadReminder:
        'Por favor, envíe una foto del anverso de su tarjeta para verificarla. (Cubra los 6 dígitos del medio)',
    },
    button: {
      bkToHm: 'VOLVER A INICIO',
      smsLogin: 'INICIAR SESIÓN DESDE EL MÓVIL',
      logout: 'CERRAR SESIÓN',
      depositFunds: 'DEPOSITAR FONDOS',
      withdrawFunds: 'RETIRAR FONDOS',
      ibPortal: 'PORTAL DE IB',
      download: 'DESCARGAS',
      changeLeverage: 'CAMBIAR APALANCAMIENTO',
      changePw: 'CAMBIAR CONTRASEÑA',
      forgetPw: 'HE OLVIDADO MI CONTRASEÑA',
      forgotPw: '¿Ha olvidado su contraseña?',
      completeQuestionnaire: 'COMPLETAR CUESTIONARIO',
      identityProof: 'COMPLETAR SOLICITUD',
      upload: 'ENVIAR',
      userLogin: 'ACCESO CON EL CORREO ELECTRÓNICO',
      goToIB: 'Ir al portal de IB',
      submit: 'ENVIAR',
      cancel: 'CANCELAR',
      confirm: 'CONFIRMAR',
      login: 'INICIAR SESIÓN',
      selectFile: 'Seleccionar archivo',
      continue: 'Continuar',
      enable: 'HABILITAR',
      disable: 'DESACTIVAR',
      export: 'EXPORTAR',
      ok: 'OK',
      hide: 'OCULTAR',
      unhide: 'MOSTRAR',
      showAll: 'MOSTRAR TODO',
      beginner: 'PRINCIPIANTE',
      intermediate: 'INTERMEDIO',
      advanced: 'AVANZADO',
      sendCode: 'Enviar código',
      resendCode: 'Reenviar codigo',
    },
    formValidation: {
      common: 'Este campo es obligatorio.',
      accReq: 'El número de cuenta es obligatorio',
      ccReq: 'El número de la tarjeta de crédito es obligatorio',
      cardReq: 'El número de la tarjeta es obligatorio',
      cardOnNameReq: 'El nombre en la tarjeta es obligatorio',
      digits16: 'Por favor, introduzca 16 dígitos',
      digits3or4: 'Por favor, introduzca 3 o 4 dígitos',
      digitsFirst6: 'Por favor, introduzca los primeros 6 dígitos',
      digitsLast4: 'Por favor, introduzca los últimos 4 dígitos',
      digitsLast3: 'Por favor, introduzca los últimos 3 dígitos',
      mid6Digits: 'Por favor, introduzca los {number} dígitos del medio',
      expDateReq: 'La fecha de vencimiento es obligatoria',
      cvvReq: 'El código de seguridad CVV es obligatorio',
      file: 'Por favor, envíe un archivo',
      filePhoto: 'La foto de la tarjeta de crédito es obligatoria',
      filePhotoReq: 'La foto de la tarjeta es obligatoria',
      note256: 'El límite de extensión de la nota es 256 caracteres',
      noteMoreThan: 'El límite de extensión de la nota es {limit} caracteres',
      noteLessThan: 'El límite de extensión de la nota es {limit} caracteres',
      payMethodReq: 'El método de pago es obligatorio',
      expReq: 'La fecha de vencimiento es obligatoria',
      bankProvinceReq: 'La provincia del banco es obligatoria',
      bankAddressReq: 'La dirección bancaria es obligatoria',
      bankBranchReq: 'La sucursal bancaria es obligatoria',
      bankHolderAddressReq: 'La dirección del titular de cuenta es obligatoria',
      swiftReq: 'El código swift es obligatorio',
      accHolderAddress: 'La dirección del titular de cuenta es obligatoria',
      accNumIBANReq: 'El número de la cuenta bancaria/IBAN es obligatorio',
      accNameReq: 'El nombre de la cuenta bancaria es obligatorio',
      withdrawMethodReq: 'Por favor, seleccione un método de retirada',
      tnc: 'Por favor, acepte los Términos y Condiciones.',
      currPwReq: 'La contraseña actual es obligatoria',
      currPwWrong: 'La contraseña actual no es correcta',
      newPwReq: 'La nueva contraseña es obligatoria',
      IdenficalPw: 'La nueva contraseña no puede ser la misma que la anterior',
      confirmPwReq: 'Por favor, introduzca una nueva contraseña de nuevo',
      confirmNotMatch: 'Los dos datos no coinciden',
      pwReq: 'La contraseña es obligatoria',
      accCurrReq: 'Seleccione una moneda base para su cuenta',
      tradAccReq: 'Por favor, seleccione una cuenta de trading',
      answerReq: 'Por favor, seleccione',
      phoneNumberReq: 'El número de teléfono móvil para el banco es obligatorio',
      nationaIdReq: 'El documento nacional de identidad es obligatorio',
      numberReq: 'Por favor, introduzca un número',
      alphanumericReq: 'Por favor, introduzca un alfanumérico',
      idTypeReq: 'Por favor, seleccione un tipo de identificación',
      dobReq: 'Por favor, seleccione su fecha de nacimiento',
      pobReq: 'Por favor, seleccione su lugar de nacimiento',
      nationalityReq: 'Por favor, seleccione su nacionalidad',
      titleReq: 'Por favor, seleccione su título',
      firstNameReq: 'Por favor, introduzca su nombre',
      lastNameReq: 'Por favor, introduzca sus apellidos',
      emailReq: 'Por favor, introduzca su correo electrónico',
      addressReq: 'Por favor, introduzca su dirección',
      suburbReq: 'Por favor, introduzca su ciudad o barrio',
      stateReq: 'El estado o provincia son obligatorios',
      postcodeReq: 'Por favor, introduzca su código postal',
      countryReq: 'Por favor, seleccione su país de residencia',
      bankCodeReq: 'Por favor, introduzca el banco emisor de la tarjeta',
      bankBranchCodeReq: 'Se necesita el código de sucursal',
      dynamicReq: 'El campo {dynamic} es obligatorio',
      mobReq: 'El teléfono móvil es obligatorio',
      confirmed: 'Por favor, confirmar',
      amtReq: 'La cantidad es obligatoria',
      fileReceipt: 'Por favor, envíe un recibo',
      amtLarger:
        'La cantidad no puede ser nula y debe ser superior o igual a ${minLimit} {currency}',
      withdrawAmtLarger: 'El monto mínimo de retiro es de ${minLimit} {currency} o su equivalente.',
      amt0: 'La cantidad no puede ser 0',
      amtLess: 'La cantidad de su depósito no puede ser superior a ${maxLimit} {currency}',
      emailFormat: 'Por favor, introduzca una dirección de email correcta',
      bankNameReq: 'El nombre del banco es obligatorio',
      bankCityReq: 'La ciudad del banco es obligatoria',
      bankAccNumReq: 'El número de la cuenta bancaria es obligatorio',
      ifscCodeReq: 'Se requiere el código IFSC',
      bankBeneficiaryNameReq: 'El nombre del beneficiario del banco es obligatorio',
      bsbReq: 'El BSB es obligatorio',
      newPwFormat:
        'Su contraseña debe tener entre 8 y 16 caracteres y una mezcla de letras (a-z y A-Z), números (0-9) y caracteres especiales como !@#$%^&*.()',
      newPwAccFormat:
        'Su contraseña debe tener entre 8 y 16 caracteres y una mezcla de letras (a-z y A-Z), números (0-9) y caracteres especiales como !@#$%^&*',
      bankCardReq: 'Por favor, seleccione una tarjeta',
      NIReq: 'Por favor, introduzca su número de la Seguridad Nacional(NI)',
      taxResidencyReq: 'Por favor, seleccione su país(es) de residencia fiscal',
      yearsAtAddressReq:
        'Por favor, introduzca su número de años de residencia en la misma dirección.',
      resetBalAmt: 'Por favor, fije una cantidad entre 0 y 1.000.000',
      verCodeReq: 'El código de verificación es obligatorio',
      noEligibleAccounts: 'No hay cuentas elegibles',
      AlphanumericOnlyNotSpace: 'Solo alfanumérico. No se permiten espacios.',
      AlphanumericOnly: 'Solo alfanumérico.',
      NumbersSymbol: 'Solo números y símbolos.',
      NumbersOnlyNotSpace: 'Solo números. No se permiten espacios.',
      NumbersOnly: 'Solo números.',
      LettersSymbol: 'Solo letras y símbolos.',
      LettersSymbolNotSpace: 'Solo letras y símbolos. No se permiten espacios.',
      LettersOnlyNotSpace: 'Solo letras. No se permiten espacios.',
      NotSpace: 'No se permite espacio.',
      beneficiaryNameReq: 'Se requiere el nombre del beneficiario',
    },
    withdrawChannel: {
      banktransferbpaypolipay: 'Transferencia bancaria/BPay/PoliPay',
      banktransfer: 'Transferencia bancaria internacional',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'Transferencia UnionPay',
      fasapay: 'FasaPay',
      creditcard: 'Tarjeta de crédito',
      archiveThisCard: 'Por favor, archive esta tarjeta si ya no es válida',
      update: 'Actualizar',
      archive: 'Archivar',
      updateExpiryMsg:
        'Por favor, actualice la fecha de caducidad de su tarjeta para usarla en retiradas',
      available: 'Disponible',
      pending: 'Pendiente',
      unavailable: 'No disponible',
      fullyRefunded: 'Reembolsado',
      requiredDocument: 'Documentos requeridos para archivar la tarjeta',
      officialLetter:
        'Email/comunicado oficial del banco indicando que la tarjeta ya no es válida.',
      bankStatement: 'Extracto bancario con una transacción realizada con esa tarjeta',
      expiredCard: 'Tarjetas caducadas/no disponibles',
      confirmArchive: '¿Está seguro de que desea archivar esta tarjeta?',
      confirmUpdate:
        '¿Está seguro de que desea actualizar la fecha de caducidad de esta tarjeta? Nota: Moneta no será responsable de ningún fallo en la retirada debido a una fecha de caducidad proporcionada incorrectamente',
      bpay: 'Bpay',
      creditdebitcard: 'Tarjeta de crédito/débito',
      bankwiretransferinternational: 'Transferencia bancaria (Internacional)',
      banktransferaustralia: 'Transferencia bancaria (Australia)',
      banktransferinternational: 'Transferencia bancaria internacional',
      banktransferchina: 'Transferencia bancaria (Internacional)',
      banktransferuk: 'Transferencia bancaria (Reino Unido)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Transferencia de Broker a Broker',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Banca online (Tailândia)',
      internetbankingnigeria: 'Banca online (Nigéria)',
      internetbankingvietnam: 'Banca online (Vietname)',
      internetbankingmalaysia: 'Banca online (Malásia)',
      internetbankingindonesia: 'Banca online (Indonésia)',
      internetbankingkorea: 'Banca online (Korea)',
      internetbankingsouthkorea: 'Banca online (Corea del Sur)',
      internetbankingindia: 'Banca online (India)',
      internetbankingphilippine: 'Banca online (Philippine)',
      internetbankingsouthafrica: 'Banca online (Sudáfrica)',
      internetbankinguk: 'Banca online (Reino Unido)',
      internetbankinglaos: 'Banca online (Laos)',
      internetbankinghk: 'Banca por internet (Hong Kong)',
      internetbankinguganda: 'Banca online (Uganda)',
      internetbankingrwanda: 'Banca online (Ruanda)',
      internetbankingzambia: 'Banca online (Zambia)',
      internetbankingcongo: 'Banca online (Congo)',
      internetbankingcameroon: 'Banca online (Camerún)',
      internetbankingburundi: 'Banca online (Burundi)',
      internetbankingkenya: 'Banca online (Kenia)',
      internetbankingghana: 'Banca online (Ghana)',
      internetbankingtanzania: 'Banca online (Tanzania)',
      internetbankingbrazil: 'Banca online (Brasil)',
      internetbankingmexico: 'Banca online (México)',
      internetbankingcolombia: 'Banca online (Colômbia)',
      internetbankingchile: 'Banca online (Chile)',
      internetbankingperu: 'Banca online (Peru)',
      internetbankingusd: 'Banca por internet (USD)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Transferencia bancaria de Vietnam',
      nigeriabanktransfer: 'Transferencia bancaria de Nigeria',
      polipayment: 'Pagamento con Poli',
      bankwiretransferaustralia: 'Transferencia bancaria (Australia)',
      thailandinstantbankwiretransfer: 'Transferencia bancaria instantánea de Tailandia',
      nigeriainstantbankwiretransfer: 'Transferencia bancaria instantánea de Nigeria',
      vietnaminstantbankwiretransfer: 'Transferencia bancaria instantánea de Vietnam',
      laosinstantbankwiretransfer: 'Transferencia bancaria instantánea de Laos',
      malaysiainstantbankwiretransfer: 'Transferencia bancaria instantánea de Malasia',
      indonesiainstantbankwiretransfer: 'Transferencia bancaria instantánea de Indonesia',
      indiainstantbanktransfer: 'Transferencia bancaria instantánea de India',
      southafricainstantbankwiretransfer: 'Transferencia bancaria instantánea de Sudáfrica',
      philippinesinstantbankwiretransfer: 'Transferencia bancaria instantánea de Philippine',
      indialocalbankwiretransfer: 'Transferencia bancaria local de la India',
      hongkongbanktransfer: 'Transferencia bancaria local de Hong Kong',
      ugandabanktransfer: 'Transferencia bancaria local de Uganda',
      rwandabanktransfer: 'Transferencia bancaria local de Ruanda',
      zambiabanktransfer: 'Transferencia bancaria local de Zambia',
      congobanktransfer: 'Transferencia bancaria local del Congo',
      cameroonbanktransfer: 'Transferencia bancaria local de Camerún',
      burundibanktransfer: 'Transferencia bancaria local de Burundi',
      kenyabanktransfer: 'Transferencia bancaria local de Kenia',
      ghanabanktransfer: 'Transferencia bancaria local de Ghana',
      tanzaniabanktransfer: 'Transferencia bancaria local de Tanzania',
      brazilbanktransfer: 'Transferencia bancaria en Brasil',
      mexicobanktransfer: 'Transferencia bancaria local en México',
      pagsmileBrazil: 'Transferencia bancaria instantánea de Brasil',
      pagsmileMexico: 'Transferencia bancaria instantánea de Mexico',
      pagsmileColombia: 'Transferencia bancaria instantánea de Colombia',
      pagsmileChile: 'Transferencia bancaria instantánea de Chile',
      pagsmilePeru: 'Transferencia bancaria instantánea de Peru',
      banktransferbpaypolipayaustralia: 'Transferencia bancaria/BPay/PoliPay (Australia)',
      banktransferbpaypolipayinternational: 'Transferencia bancaria/BPay/PoliPay (Internacional)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Transferencia bancaria local canadiense',
      cryptobitcoin: 'Criptodivisa-Bitcoin',
      cryptotetheromni: 'Criptodivisa-USDT(OMNI)',
      cryptotethererc20: 'Criptodivisa-USDT(ERC20)',
      cryptotethertrc20: 'Criptodivisa-USDT(TRC20)',
      cryptocurrencybitcoin: 'Criptodivisa-Bitcoin',
      cryptocurrencyusdt: 'Criptodivisa-USDT',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      trustly: 'Trustly',
      astropay: 'Astropay',
      webmoney: 'WebMoney',
      vload: 'Vload',
      dasshpewallet: 'Carteras de la India',
      cryptocurrencyethcps: 'Criptodivisa-ETH',
      cryptocurrencyeth: 'Criptodivisa-ETH',
      cryptocurrencyusdc: 'Criptodivisa-USDC(ERC-20)',
      cryptocurrencyusdcerc20: 'Criptodivisa-USDC(ERC-20)',
      cryptocurrencyusdccps: 'Criptodivisa-USDC(ERC-20)',
      chileBankTransfer: 'Transferencia bancaria en Chile',
      colombiaBankTransfer: 'Transferencia bancaria en Colombia',
      perubanktransfer: 'Transferencia bancaria en Perú',
      alipay: 'Alipay',
    },
  },
  home: {
    pendingTooltip:
      'Una vez que su cuenta en vivo sea aprobada, recibirá un correo electrónico con sus detalles de inicio de sesión en MT{number} y podrá empezar a operar.',
    leverage: {
      tncTitle: '<span>Términos y condiciones</span>',
      tnc: '<p>Al enviar esta solicitud, acepto que tengo en cuenta que un apalancamiento alto conlleva un elevado riesgo para mi capital y que existe la posibilidad de que pueda sufrir una pérdida mayor y no limitada al capital que he depositado.<br><br>Acepto que el emisor de productos tiene derecho a revisar mis actividades de trading y ajustar los niveles de apalancamiento en mi cuenta de trading a su discreción y sin notificaciones ni advertencias previas.<br><br>Comprendo que al elegir un apalancamiento alto, puedo operar con un margen mayor pero potencialmente tener pérdidas significativas.</p>',
      tncConfirm: 'He leído y aceptado los términos y condiciones.',
      changeLeverageConfirm:
        'Su solicitud ha sido enviada y será procesada por nuestro equipo de apoyo al cliente en breve.',
      changeLeverageAutoComplete: 'Su solicitud para cambiar el apalancamiento fue completada.',
      changeLeverageReject: 'La solicitud para cambiar el apalancamiento ha sido rechazada',
      changeLeverageFail:
        'La solicitud para cambiar el apalancamiento ha fallado. Por favor, inténtelo más tarde',
      alreadyApplied:
        'Ya ha solicitado cambiar el apalancamiento en su cuenta y su petición está siendo revisada por el equipo de atención al cliente de {platform} actualmente. Por favor, espere a que esta solicitud finalice antes de enviar otra.',
      changeLeverageDefault:
        'Se ha producido un error, por favor, contacte con {supportEmail} para terminar su solicitud',
      reduceLeverageWarn:
        'Nota: La reducción de su apalancamiento puede resultar en el cerramiento forzado de las posiciones abiertas si su cuenta no tiene suficiente margen.',
      reduceLeverageConfirm: '¿Desea continuar?',
      autoRestore:
        '<p>Dado que su capital es mayor o igual a 20.000, su apalancamiento disponible es 500:1</p><br /><p>Cuando su capital esté otra vez por debajo de 20.000, podrá ajustarse a 1000:1 de nuevo</p>',
      oneThousandRatio:
        '<p>Kui soovite 1000:1 finantsvõimendusele üle minna, vaadake allpool toodud tingimusi:<br><br>1. Te ei saa taotleda krediidiga seotud pakkumistele<br><br>2. Teie konto omakapital ei tohi taotluse esitamise hetkel ületada 15 000 USD või samaväärset summat<br><br>3. Konto finantsvõimendus muudetakse 500:1-le, kui teie konto omakapital ületab 20 000 USD või samaväärse summa<br><br>4. Finantsvõimenduse limiidid võivad erineda vastavalt kehtivatele eeskirjadele<br><br>5. Finantsvõimenduse muudatus ei kehti toodetele, mis on fikseeritud finantsvõimendusega <br><br> 6. Vastavalt kliendilepingule rakendatakse meetmeid, mis hõlmavad, kuid mitte ainult, lõpetamist või mahaarvamist, kui rikute vaikimisi sündmusi ja/või kahtlast kauplemistegevust käsitlevaid määruseid<br><br></p>',
    },
    pw: {
      error: 'Error al restablecer su contraseña. Por favor, inténtelo de nuevo más tarde',
      confirm:
        'Su contraseña ha sido actualizada. <br>Ahora puede utilizar su nueva contraseña para acceder a su cuenta de trading MT4/MT5.',
      email:
        '<p class=first_info>Acabamos de enviarle un correo electrónico con instrucciones para restablecer su contraseña.</p><p>Asegúrese de revisar la carpeta de spam de su email.</p>',
    },
    activate: {
      question: '¿Desea reactivar su cuenta en vivo?',
      confirm: 'Hemos recibido su solicitud de activación.<br>Su cuenta se activada en breve.',
    },
    accStatus: {
      rejected: 'Rechazado',
      processing: 'Procesando',
      active: 'Activa',
      inactive: 'Inactiva',
      activate: 'Activar',
      expired: 'Expirada',
    },
    appNote: { desc02: 'Haga clic aquí para más información.' },
    app: {
      title: 'Cómo funciona',
      desc01: 'Descargue {platform}',
      desc02: 'Inicie sesión con su email existente',
      desc03: 'Verifique su número de teléfono',
      desc04: 'Está listo. Disfrute de un trading potente',
    },
    reset: {
      success: 'Su saldo está otra vez en cero.',
      creditTip:
        'El saldo de su cuenta es menor a 0. Si vuelve a situarlo en 0, su crédito también pasará a 0. ¿Seguro que quiere continuar?',
      resetBtn: 'Poner a 0',
      tableHeader: 'Saldo negativo',
    },
  },
  oneThousandRatioError: {
    error1: 'Sellel kontol on boonus ja seda ei saa kohandada 1000:1 võimendusele',
    error4:
      'Konto omakapital on suurem kui $15,000 (või samaväärne valuuta) ja seda ei saa kohandada 1000:1 võimendusele',
    error5: 'The ID audit is not pass, cannot be adjusted to 1000:1 leverage',
    error6: 'Konto on osalenud boonustegevuses ja seda ei saa kohandada 1000:1 võimendusele',
  },
  paymentDetails: {
    subHeader: 'AÑADIR UN MÉTODO DE PAGO',
    tip: '<p>Seleccione su método de pago preferido para futuros depósitos y retiradas desde el siguiente menú.</p><br />\n   <p><b>Tenga en cuenta que:</b> para garantizar que sus solicitudes de depósito y retirada se puedan procesar rápidamente, las regulaciones AML/CTF requieren que el nombre que aparece en el método de pago elegido sea:\n      <b> {individualUserName}</b>.</p>',
    result: {
      defaultSuccessMessage:
        'Gracias por enviar el método para añadir fondos para su cuenta de trading. Actualmente estamos procesando su solicitud y nos pondremos en contacto en breve.',
      unionPayCardSuccessMessage:
        'Los datos de la tarjeta Union Pay se enviaron correctamente, sus nuevos datos bancarios estarán disponibles una vez sean aprobados',
    },
    history: { header: 'MÉTODOS DE PAGO' },
    cc: {
      field: {
        ccNum: 'Número de la tarjeta de crédito',
        first6: 'Primeros seis',
        last4: 'Últimos cuatro',
        withrawlable: 'La cantidad máxima disponible para esta tarjeta es de {amount}.',
      },
      formValidation: { upload: 'La foto de la tarjeta de crédito es obligatoria' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'Obteniendo el estado de su depósito... Quedan {countDown} segundos.',
      successMsg: 'Gracias por su depósito, sus fondos aparecerán en su cuenta en breve.',
      failureMsg: 'Su depósito no tuvo éxito. Por favor, vuelva a intentarlo.',
      message:
        'Gracias por añadir fondos en su cuenta. Su depósito será aprobado manualmente por el equipo de cuentas de {platform} en breve. Los fondos se depositarán en su cuenta una vez que sean aprobados',
      defaultException:
        'Para confirmar su depósito con {platform}, por favor contacte con el equipo de apoyo al cliente en {email}',
      rejectMsg: 'Rechazado',
    },
  },
  deposit: {
    default: {
      deposit: 'Depositar',
      reference: 'Su referencia',
      sendReceipt: 'Enviar recibo',
      sendReceiptTip:
        'Cuando realice la transferencia, por favor, complete el siguiente formulario y adjunte una captura de pantalla, foto o copia escaneada del recibo bancario donde se muestre claramente su nombre completo, el número de cuenta y la cantidad de su depósito para que podamos procesar su transacción.',
      reminder:
        'Por favor, espere de 1 a 2 días hábiles para que los fondos sean acreditados en su cuenta.',
      successMsg:
        'Los fondos aparecerán en su cuenta de trading una vez recibamos el depósito y verifiquemos el recibo de su transacción.',
      alt: 'También, puede enviar el recibo de su transacción por correo electrónico a {mailTo} lo antes posible.',
      reminderFundAppear: 'Gracias por su depósito, sus fondos aparecerán en su cuenta en breve.',
      failed: 'Depósito fallido, por favor inténtelo de nuevo más tarde.',
      fee: 'COMISIÓN',
      instant: 'INSTANTÁNEO',
      hour: 'HORA',
      hours: 'HORAS',
      businessDays: 'DÍAS LABORABLES',
      disclaimer:
        '<li>1. {platform} strictly does not accept third-party payments. All funds deposited into your trading account must be under the same name as your {platform} trading account.</li>     \n        <li> </br>2. Clients acknowledge that they may be required to provide information on the source(s) of the deposit(s), the Clients’ wealth in general and any other information {platform} deems necessary to identify the source of funds when processing a deposit request and agree to provide all information required by {platform}. Failure to provide the required information may result in significant delaysin the process of your requests. {platform} will not be responsible for any delays or losses suffered as results of that delay.</li>\n        <li> </br>3. Client understands that submitting a deposit request does not constitute an acceptance of funds by {platform}. If your account is close to or in a margin call, please make sure you have other arrangements in place.</li>\n        <li> </br>4. {platform} will not be responsible for any delays/errors caused by payments systems.</li>\n        <li> </br>5. {platform} will not be responsible any fees (i.e. conversion fees, cash in advance fees, etc.) that the sending financial institution or third-party payment providers may charge you for sending payments.</li>\n        <li> </br>6. Le informamos de que la descripción de Moneta Markets en su sistema de facturación aparecerá como <a href="https://www.monetamarkets.com/" target="_blank">www.monetamarkets.com</a> - Gladstonos & Filokyprou, 120, House Office E2<li>',
      channels: {
        creditOrDebit: 'Tarjeta de crédito/débito',
        i12BankTransfer: 'Transferencia bancaria internacional',
        auBankTransfer: 'Transferencia bancaria na Australia',
        bPay: 'Transferencia bancaria de BPAY',
        skrill: 'Skrill/Moneybookers',
        sticPay: 'SticPay',
        webMoney: 'WebMoney',
        b2b: 'Transferencia de Broker a Broker',
        unionPayCN: 'Transferencia da China Union Pay',
        vietInstant: 'Transferencia bancaria instantánea de Vietnam',
        thaiInstant: 'Transferencia bancaria instantánea de Tailandia',
        nigeriaInstant: 'Transferencia bancaria instantánea de Nigeria',
        laosInstant: 'Transferencia bancaria instantánea de Laos',
        malayInstant: 'Transferencia bancaria instantánea de Malaysia',
        indonesiaInstant: 'Transferencia bancaria instantánea de Indonesia',
        philippineInstant: 'Transferencia bancaria instantánea de Philippine',
        mobilePay: 'Mobile Pay',
        poli: 'Pagos con POLi',
        paypal: 'Depósito con PayPal',
        mijipay: 'Depósito con Mijipay',
        crypto: 'criptodivisas',
        canada: 'Interac E-Transfer',
        tinkbit: 'Depósito con Tinkbit',
        bitwallet: 'Depósito con bitwallet',
        indiaInstant: 'Transferencia bancaria instantánea de India',
        trustly: 'Trustly',
        pagsmileBrazil: 'Transferencia bancaria instantánea de Brasil',
        pagsmileMexico: 'Mexico Instant Bank Transfer',
        pagsmileColombia: 'Colombia Instant Bank Transfer',
        pagsmileChile: 'Chile Instant Bank Transfer',
        pagsmilePeru: 'Peru Instant Bank Transfer',
        astropay: 'Depósito con Astropay',
        dasshpeWallet: 'Carteras de la India',
        southkoreabanktransfer: 'Transferencia bancaria a Corea del Sur',
        alipay: 'Alipay',
        indonesiainstantbanktransfer: 'Transferencia bancaria instantánea de Indonesia',
        thailandinstantbanktransfer: 'Transferencia bancaria instantánea Tailandia',
      },
      rate: {
        rate: 'Tipo de cambio({oldCurrency}/{newCurrency}): ',
        result: {
          rateError: 'Error al obtener los tipos de {oldCurrency} a {newCurrency}.',
          channelError: 'Error al obtener el canal de pago.',
        },
      },
      cn: { rate: 'Tipo de cambio(USD/RMB): ', amt: 'RMB: ' },
      viet: {
        result: { rateError: 'Error al obtener los tipos de USD a VND.' },
        rate: 'Tipo de cambio(USD/VND): ',
        amt: 'VND: ',
      },
    },
    method: {
      desc: 'Cómo hacer un depósito en su cuenta con {method}',
      inst1: '{number}.   Complete el siguiente formulario y haga pulse en "Enviar"',
      inst2:
        '{number}.   Seleccione el banco en la página de selección de banco e inicie sesión en su banco online de {country}',
      inst3:
        '{number}.   Siga las instrucciones y realice el pago a través de su banco online/móvil',
      inst4:
        '{number}.   Será redirigido de nuevo al portal de cliente y su transacción se finalizará',
      inst5: '{number}.   Acceda a su cuenta {method}',
      inst6: '{number}.   Siga las indicaciones de la cuenta {method} para completar su pago',
      inst7:
        'Por favor, tenga en cuenta que: si elige depositar con proceso manual, sus fondos pueden retrasarse y tardar mucho tiempo en abonarse en su cuenta de trading',
      inst8:
        '{number}.   Por favor, tenga en cuenta que está utilizando {payment} y por tanto, la cantidad de depósito está limitada a {amount} {currency} por transacción',
      form: { header: 'Formulario de {method}' },
      inst12: "{number}.   Complete el formulario a continuación y seleccione 'Enviar'",
      inst13: '{number}.   Escanee el código QR para abrir su aplicación bancaria móvil',
      inst14:
        '{number}.   Siga las indicaciones en su dispositivo móvil para completar su depósito',
      inst15: '{number}.   Complete el formulario a continuación y presione ‘Enviar’',
      inst16: "{number}.   Después de ingresar los detalles de su tarjeta, presione 'Enviar'",
    },
    cpsInfo: {
      thispaymentchannelistemporaryunavailable:
        'Este canal de pago no está disponible temporalmente.',
    },
    uk: { header: 'Transferencia bancaria instantánea de Reino Unido', country: 'Reino Unido' },
    southAfrica: {
      header: 'Transferencia bancaria instantánea de Sudáfrica',
      country: 'Sudáfrica',
    },
    idn: { header: 'Transferencia bancaria instantánea de Indonesia', country: 'Indonesia' },
    ida: { header: 'Transferencia bancaria instantánea de India', country: 'India' },
    brazil: { header: 'Transferencia bancaria instantánea de Brasil', country: 'Brasil' },
    nigeria: { header: 'Transferencia bancaria instantánea de Nigéria', country: 'Nigéria' },
    thailand: { header: 'Transferencia bancaria instantánea de Tailandia', country: 'Tailandia' },
    vietnam: { header: 'Transferencia bancaria instantánea de Vietnam', country: 'Vietnam' },
    laos: {
      header: 'Transferencia bancaria instantánea de Laos',
      country: 'Laos',
      inst: '2.   Escanee el código QR con su aplicación OnePay',
    },
    hongkong: {
      country: 'Hong Kong',
      inst1: '2.   Siga las instrucciones y haga un pago',
      inst2: '3.   Su transacción será finalizada',
      inst3: 'Por favor, siga las instrucciones a continuación para realizar el pago:',
      inst4: '1.   Por favor, acceda a su cuenta bancaria.',
      inst5: '2.   Transfiera fondos a:',
      inst6: '3.   Tras completar el pago, por favor, haga clic en el botón HECHO para continuar.',
      inst8: 'Aviso: ',
      inst9:
        'Este número de cuenta generado solo es válido para un único depósito y no debe utilizarse en futuras transacciones para evitar retrasos en la llegada de los fondos.',
      inst10:
        'La cantidad del pago transferido debe coincidir con la cantidad enviada en la solicitud de depósito.',
      inst11:
        'Por favor, asegúrese de efectuar su transferencia bancaria en un plazo de 1 hora tras enviar su solicitud de depósito para evitar un error de depósito.',
    },
    malay: { header: 'Transferencia bancaria instantánea de Malasia', country: 'Malasia' },
    php: { header: 'Transferencia bancaria instantánea de Philippine', country: 'Philippine' },
    uganda: { header: 'Transferencia bancaria local de Uganda', country: 'Uganda' },
    rwanda: { header: 'Transferencia bancaria local de Ruanda', country: 'Ruanda' },
    zambia: { header: 'Transferencia bancaria local de Zambia', country: 'Zambia' },
    congo: { header: 'Transferencia bancaria local del Congo', country: 'Congo' },
    cameroon: { header: 'Transferencia bancaria local de Camerún', country: 'Camerún' },
    burundi: { header: 'Transferencia bancaria local de Burundi', country: 'Burundi' },
    kenya: { header: 'Transferencia bancaria local de Kenia', country: 'Kenia' },
    ghana: { header: 'Transferencia bancaria local de Ghana', country: 'Ghana' },
    tanzania: { header: 'Transferencia bancaria local de Tanzania', country: 'Tanzania ' },
    bankWireAu: {
      header: 'Transferencia bancaria (Australia)',
      instruction:
        'Los fondos se pueden transferir a {platform} a través del servicio de banca online de su institución financiera o contactando con su institución financiera y solicitando la transferencia a {platform}.',
      tip: 'Seleccione la divisa de su cuenta en la que hará sus depósitos. Asegúrese de seleccionar la misma divisa que la divisa de su cuenta de trading',
      form: {
        bankDetails: 'Datos bancarios (solo depósito en AUD)',
        refTip: 'Por favor, especifique su número de usuario aquí',
        afp: ' Pago rápido australiano (solo depósito nacional en AUD)',
        payIdOrABNNum: 'Número PayID/ABN',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip:
          'Incluya su número de cuenta de trading de {platform} en el campo de referencia de transferencia bancaria.',
      },
    },
    bPay: {
      header: 'DEPÓSITO CON BPAY',
      option: 'Cómo hacer un depósito en su cuenta con BPay (Australia solamente)',
      inst1:
        '1. Seleccione su cuenta MT4/MT5 para ver su código de acreedor y su número de referencia de BPay',
      inst2:
        '2. Introduzca los datos de pago, su código de acreedor y el número de referencia de BPay en su banco de internet y envíe su pago',
      inst3:
        '3. Vuelva a esta página y complete el siguiente formulario de BPay para asegurarse de que procesaremos su transacción rápidamente.',
      form: {
        header: 'FORMULARIO BPAY',
        billerCode: 'Código del acreedor de BPay',
        refNum: 'Número de referencia de BPayr',
        receipt: 'Recibo bancario de BPAY',
        reminderNotUploaded:
          'Nos hemos dado cuenta que no ha enviado su recibo de transacción. Para procesar su depósito, envíe una copia del recibo por correo electrónico a {mailTo} lo antes posible.',
        reminderProc:
          'Por favor, tenga en cuenta que: Su transacción NO SE PUEDE procesar hasta que recibamos su recibo.',
      },
    },
    b2b: {
      header: 'DE BROKER A BROKER',
      option: 'Cómo transferir fondos de otro broker a {platform}',
      inst1: '1. Descargar y completar el {download}',
      inst2:
        '2. Complete y envíe el siguiente formulario online de Transferencia de Broker a Broker',
      inst3:
        '3. Envíe una copia de su formulario de Broker a Broker completado a su broker anterior para iniciar la transferencia',
      b2bForm: 'Formulario de Broker a Broker',
      formValidation: { upload: 'El formulario de Broker a Broker es obligatorio' },
    },
    cc: {
      header: 'DEPÓSITO CON TARJETA DE CRÉDITO O DÉBITO',
      remind:
        'Si tiene alguna dificultad para hacer un depósito con su cuenta utilizando el método primario, utilice el método de crédito/débito secundario',
      field: {
        fundNow: 'Hacer un depósito con tarjeta de crédito/débito ahora',
        newCard: 'Nueva tarjeta',
        cardNum: 'Número de la tarjeta de crédito',
        nameOnCard: 'Nombre en la tarjeta',
        expDate: 'Fecha de vencimiento',
        cvv: 'Código de seguridad CVV',
        bankCode: 'Banco emisor de la tarjeta',
      },
      placeholder: { bankCode: 'Por favor, introduzca el nombre del banco emisor de la tarjeta' },
      cvvTip: 'Los 3 dígitos que aparecen en <br />el reverso de su tarjeta',
      threeDomainSecQ: '¿Su tarjeta es compatible con 3D Secure?',
      threeDomainSec:
        '¿Qué es la autenticación 3D Secure? <br /><strong>La autenticación 3D Secure es un método de prevención de fraude aprobado por Visa y Mastercard.<br /><br />Si su tarjeta es compatible con 3D Secure, sus fondos serán procesados de inmediato.</strong>',
      cardUpload:
        'La tarjeta que está utilizando para depositar fondos no se ha vinculado a su cuenta de trading. Por favor, envíe una foto del anverso de su tarjeta para verificarla. (Cubra los 6 dígitos del medio)',
      result: {
        success:
          'Gracias. Actualmente estamos verificando los datos de su tarjeta, y una vez que los fondos sean verificados aparecerán en su cuenta en un plazo de 24 horas.',
        depositSuccess: 'Gracias por su depósito, sus fondos aparecerán en su cuenta en breve.',
        error:
          'Para proteger la seguridad de su cuenta,<br> sus depósitos se han suspendido temporalmente ya que se ha alcanzado su límite de transacciones de depósito.<br> Por favor, contacte con {email}',
      },
      mastercardonly: 'Por favor, continúe utilizando este canal de pago solo con Mastercard.',
    },
    fasa: {
      header: 'DEPÓSITO EN FASAPAY',
      description: 'Cómo hacer un depósito en su cuenta con FasaPay',
      inst1: '1. Complete el siguiente formulario y pulse "Enviar"',
      inst2: '2. Acceda a su cuenta FasaPay',
      inst3: '3. Siga las indicaciones de la cuenta FasaPay para completar su pago',
      inst4:
        '4. Los depósitos de Fasapay se limitan a 25.000 dólares por transacción y un máximo de 75.000 dólares por día.',
      form: { header: 'FORMULARIO FASAPAY' },
    },
    miji: {
      header: 'Transferencia bancaria instantánea de Tailandia',
      description:
        'Cómo hacer un depósito en su cuenta con transferencia bancaria instantánea de Tailandia',
      inst1: '1. Complete el siguiente formulario y pulse "Enviar"',
      inst2: '2. Luego será redirigido a la página de pago',
      inst3:
        '3. Inicie sesión en su aplicación de banca móvil y escanee el código QR en la página de pago',
      inst4:
        'Tenga en cuenta que: <b>El sistema añadirá algunos céntimos a la cantidad solicitada.</b>',
      form: { header: 'Formulario de transferencia bancaria instantánea da Tailandia' },
    },
    intSwift: {
      header: 'TRANSFERENCIA BANCARIA: SWIFT INTERNACIONAL',
      desc: 'Los fondos se pueden transferir a {platform} a través del servicio de banca online de su institución financiera o contactando con su institución financiera y solicitando la transferencia a {platform}.',
      desc2:
        'Cuando realice la transferencia, complete el siguiente formulario y adjunte una copia escaneada del recibo bancario donde se muestre claramente su nombre completo, el número de cuenta y la cantidad de su depósito para que podamos procesar su transacción.',
      options: 'Por favor, seleccione la cuenta de trading con la que desea hacer el depósito',
      refInfo:
        'Incluya su número de cuenta de trading de {platform} en el campo de referencia de la transferencia bancaria.',
      bussDayInfo:
        'Por favor, espere de 2 a 5 días hábiles para que los fondos se acrediten en su cuenta.',
      tip: 'Asegúrese de que su recibo bancario muestre claramente su <b>nombre completo</b>, <b>el número de cuenta</b> y <b>la cantidad de su depósito</b>.',
    },
    mobile: {
      header: 'MOBILE PAY',
      desc: 'Cómo hacer un depósito en su cuenta con Mobile Pay',
      inst1: '1. Complete el siguiente formulario y haga pulse en "Enviar"',
      inst2: '2. Escanee el código QR para abrir su aplicación bancaria móvil',
      inst3: '3. Siga las indicaciones en su dispositivo móvil para hacer su depósito',
      form: { payMethod: 'Método de pago:', rate: 'Tipo de cambio(USD/CNY):', amt: 'CNY:' },
      formValidation: { cnyAmt: 'La cantidad de CNY no puede ser superior a {limit} $' },
    },
    net: {
      header: 'Neteller',
      inst2: '2.   Acceda a su cuenta Neteller',
      inst3: '3.   Siga las indicaciones de la cuenta Neteller para completar su pago',
      inst4: {
        fca: '4.   Aceptamos pagos en USD, EUR y GBP',
        default: '4.   Aceptamos pagos en AUD, USD, EUR, GBP y SGD',
      },
    },
    canada: {
      header: 'Interac E-Transfer',
      desc: 'Cómo hacer un depósito en su cuenta con Interac E-Transfer',
      inst1: '1. Complete el siguiente formulario y haga pulse en "Enviar"',
      inst2:
        '2. Seleccione el banco en la página de selección de banco e inicie sesión en su banco online',
      inst3: '3. Siga las instrucciones y realice el pago a través de su banco online/móvil',
      inst4: '4. Podemos aceptar el pago en CAD. Máximo 9.999 dólares canadienses por depósito',
      form: { header: 'FORMULARIO Interac E-Transfer' },
    },
    bitwallet: {
      header: 'Depósito con bitwallet',
      desc: 'Cómo hacer un depósito en su cuenta con BITWALLET',
      inst1: '1. Complete el siguiente formulario y haga pulse en "Enviar"',
      inst2: '2. Acceda a su cuenta Bitwallet',
      inst3: '3. Siga las indicaciones de la cuenta Bitwallet para completar su pago',
      inst4: '4. Aceptamos pagos en USD, EUR y JPY',
      form: { header: 'FORMULARIO BITWALLET' },
    },
    astropay: {
      header: 'DEPÓSITO CON ASTROPAY',
      desc: 'Cómo hacer un depósito en su cuenta con ASTROPAY',
      inst2: '2. Acceda a su cuenta ASTROPAY',
      inst3: '3. Siga las indicaciones de la cuenta ASTROPAY para completar su pago',
      inst4: '4. Aceptamos pagos en todas las monedas',
      form: { header: 'FORMULARIO ASTROPAY' },
    },
    crypto: {
      choose: 'Por favor, elija su criptodivisa preferida',
      proceedQuestion: '¿Desea proceder con su depósito  <br /><b>{method}</b> ?',
      remark:
        'Por favor, tenga en cuenta que no podemos depositar o retirar BUSDT. Por favor, asegúrese que la dirección y la criptodivisa coinciden con la cadena y divisa disponibles o podría resultar en una pérdida de fondos.',
      inst2:
        '2. Serás redirigido a una nueva página que muestra la dirección del billetera de {method}',
      inst3:
        '3. Copie la dirección del billetera y luego inicie sesión en su billetera personal {method} y transfiera la cantidad que desee a la dirección de {method}.',
      inst4:
        'Tenga en cuenta que: los depósitos de Bitcoin son procesados ​​por B2BinPay al tipo de cambio proporcionado en el momento de la transacción.',
    },
    poli: {
      header: 'DEPÓSITO CON POLI',
      desc: 'Cómo hacer un depósito en su cuenta con Poli',
      reminder:
        '<li>Tenga en cuenta que:</li><li>* El depósito debe estar al mismo nombre que su cuenta de {platform}</li><li>* Los depósitos deben estar solamente en AUD</li>',
      form: { header: 'FORMULARIO POLI' },
    },
    skrill: {
      header: 'DEPÓSITO CON SKRILL/MONEYBROOKERS',
      desc: 'Cómo hacer un depósito en su cuenta con Skrill',
      inst1: '1.   Complete el siguiente formulario y pulse en "Enviar"',
      inst2: '2.   Acceda a su cuenta Skrill',
      inst3: '3.   Siga las indicaciones de la cuenta Skrill para completar su pago',
      inst4: '4.   Aceptamos pagos en USD, EUR, GBP y CAD',
      inst4FCA: '4.   Aceptamos pagos en USD, EUR y GBP',
      form: { header: 'FORMULARIO SKRILL', email: 'Email de Skrill/Moneybrookers' },
      reminder:
        '<li>Tenga en cuenta que:</li>\n      <li>* Las cuentas sólo pueden ser financiadas en la misma moneda que su cuenta de operaciones MT4. Skrill convertirá automáticamente los fondos a la moneda base de su cuenta si se utiliza el correo electrónico incorrecto. Se pueden aplicar tasas.</li>\n      <li>* Los depósitos deben estar al mismo nombre que su cuenta de {platform}.</li>',
    },
    sticPay: {
      inst2: '2.   Acceda a su cuenta SticPay',
      inst3: '3.   Siga las indicaciones de la cuenta SticPay para completar su pago',
    },
    webMoney: {
      header: 'WEBMONEY',
      inst4: '4.   Aceptamos pagos en USD y EUR',
      inst5: '5.   Los depósitos de WebMoney se limitan a 1300 USD/1100 EUR por transacción.',
    },
    vload: {
      header: 'VLOAD',
      inst1: '2.   Aceptamos pagos en USD, EUR y JPY.',
      inst2:
        '<li>3.   Compre un cupón Vload, haga clic <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">aquí</a>.</li>',
      form: { voucherpin: 'Pin del cupón', voucherpinReq: 'Se requiere PIN de bono' },
    },
    dasshpe: {
      headerWallet: 'Carteras de la India',
      inst1: '2.   Apruebe la transacción desde su aplicación UPI',
      inst2: '2.   Siga las instrucciones y efectúe el pago mediante la aplicación de su monedero',
    },
    thaiPaytoday: {
      header: 'Transferencia bancaria instantánea de Tailandia',
      desc: 'Cómo hacer un depósito en su cuenta con la transferencia bancaria instantánea de Tailandia',
      inst1: '1. Complete el siguiente formulario y haga pulse en "Enviar"',
      inst2: '2. Siga las instrucciones para completar su pago.',
      form: { header: 'Formulario de transferencia bancaria instantánea da Tailandia' },
      scan: 'Escanee para hacer un depósito',
      exp: 'Caduca en {expireTime}',
      reminder:
        'Los fondos deben aparecer en su cuenta dentro de la hora siguiente. <br/><br/>\n      Puede ver el estado actual de su depósito en su "historial de transacciones". <br/>\n      Si tiene algún problema con su depósito, comuníquese con su gestor de cuenta',
    },
    unionPay: {
      header: 'Depósito con Union Pay',
      desc: 'Cómo hacer un depósito en su cuenta con UnionPay',
      inst1: '1. Complete el siguiente formulario y haga clic en "Enviar"',
      inst2:
        '2. Seleccione el banco en la página de selección de banco e inicie sesión en su banco online',
      inst3: '3. Siga las instrucciones y realice el pago a través de su banco online/móvil',
      form: { header: 'FORMULARIO UNION PAY' },
    },
    unionPayCN: {
      header: 'Transferencia da China Union Pay',
      desc: 'Cómo hacer un depósito en su cuenta con China Union Pay',
      inst1: '1. Complete el siguiente formulario y haga clic en "Enviar"',
      inst2: '2. Aparecerá una página con los datos de pago',
      inst3: '3. Siga las instrucciones y realice el pago a través de su banco online/móvil',
      inst4:
        'Tenga en cuenta que: si se le pregunta un número de membresía, cuando haga el depósito, tendrá que introducir su número de cuenta de trading',
      form: {
        header: 'FORMULARIO CHINA UNION PAY',
        holderName: 'Nombre del titular de la tarjeta UnionPay',
        holderNameInfo:
          'Asegúrese de que el nombre que introduzca arriba sea el mismo que el nombre del titular de su tarjeta bancaria',
      },
    },
    vietZota: {
      header: 'Transferencia bancaria instantánea de Vietnam',
      desc: 'Cómo hacer un depósito en su cuenta con transferencia bancaria instantánea de Vietnam',
      inst: '<li>1. Complete el siguiente formulario y pulse en "Enviar"</li><li>2. Seleccione el banco en la página de selección de banco e inicie sesión en su banco online de Vietnam</li><li>3. Siga las instrucciones y realice el pago a través de su banco online/móvil</li><li>4. Será redirigido de nuevo al portal de cliente y su transacción se finalizará</li>',
      form: { header: 'Formulario de transferencia bancaria instantánea de Vietnam' },
    },
    paypal: {
      header: 'PayPal',
      desc: 'Cómo hacer un depósito en su cuenta con PayPal',
      inst1: '1. Complete el siguiente formulario y haga clic en "Enviar"',
      inst2: '2. Acceda a su cuenta de PayPal',
      inst3: '3. Siga las indicaciones de la cuenta de PayPal para completar su pago',
      inst4: 'Tenga en cuenta que: no aceptamos pagos de terceros',
      form: { header: 'FORMULARIO DE PAYPAL' },
    },
    cardpay: {
      desc: 'Cómo hacer un depósito en su cuenta con tarjeta de crédito',
      inst1: '1. Complete el siguiente formulario y haga clic en "Enviar"',
      inst2: '2. Luego será redirigido a otro formulario para introducir los datos de su tarjeta',
      inst3: '3. Después de introducir los datos de su tarjeta, pulse en "Enviar"',
      note: "Por favor, tenga en cuenta que la descripción de la transacción que aparece en su extracto financiero puede variar en función del método de pago utilizado. Si tiene cualquier pregunta sobre ello, por favor, póngase en contacto con <a href='mailto:{mail}'>{mail}</a>",
      form: { header: 'FORMULARIO PARA TARJETA DE CRÉDITO' },
    },
    tinkbit: { inst5: '5. Un máximo de 1500 USD por depósito' },
    trustly: {
      header: 'Trustly',
      desc: 'Cómo hacer un depósito en su cuenta con  Trustly',
      inst1: '1. Complete el siguiente formulario y haga clic en "Enviar"',
      inst2: '2.   Acceda a su cuenta Trustly',
      inst3: '3.   Siga las indicaciones de la cuenta Trustly para completar su pago',
      inst4: '4.   Aceptamos pagos en EUR y GBP',
      form: { header: 'FORMULARIO TRUSTLY' },
      reminder:
        '<li>Tenga en cuenta que:</li>\n      <li>* Las cuentas sólo pueden ser financiadas en la misma moneda que su cuenta de operaciones MT4. Trustly convertirá automáticamente los fondos a la moneda base de su cuenta si se utiliza el correo electrónico incorrecto. Se pueden aplicar tasas.</li>\n      <li>* Los depósitos deben estar al mismo nombre que su cuenta de {platform}.</li>',
    },
    fxir: {
      desc: '{number}.    Complete el formulario a continuación y seleccione ‘Enviar’',
      desc2: '{number}.    Complete el formulario a continuación y seleccione ‘Enviar’',
      desc1: '{number}.    Complete el formulario a continuación y seleccione ‘Enviar’',
      desc3:
        'Una vez que haya realizado la transferencia, complete el formulario a continuación y adjunte una copia del recibo de transferencia bancaria que muestre claramente su nombre completo, su identificación FX-IR y el monto del depósito para que podamos procesar su transacción.',
      desc4:
        '¡Importante! Por favor, incluya (su número de cuenta de trading) en el campo de referencia de la transferencia.',
      field: {
        id: 'ID de remitente FX-IR',
        accName: 'Nombre de cuenta FX-IR',
        walletNumber: 'Número de billetera FX-IR',
        transactionID: 'ID de transacción',
        senderFxirID: 'ID de remitente FX-IR',
      },
    },
  },
  restrictDeposit: {
    header: 'NOTIFICACIÓN IMPORTANTE',
    info: 'Desafortunadamente, ya no aceptamos depósitos de clientes residentes en Canadá.',
  },
  withdraw: {
    default: {
      selectCC: 'Seleccione la tarjeta de crédito',
      selectBA: 'Seleccione la cuenta bancaria',
      anotherCC: 'Introduzca la tarjeta de crédito manualmente',
      anotherBA: 'Añadir cuenta bancaria',
      remember: 'Recuerda mi cuenta',
      success: 'Su solicitud de retirada se ha realizado con éxito y será procesada en breve.',
      ccFail:
        'Para proteger la seguridad de su cuenta, sus retiradas se han suspendido temporalmente ya que se ha alcanzado su límite de transacciones de retirada. Por favor, contacte con {email}',
      fetchTradeAccFailed: 'Error al obtener la cuenta de trading',
      failed: 'Error al retirar. Por favor, inténtelo de nuevo más tarde',
      notEnoughFunds: 'El valor introducido supera el límite de esta tarjeta',
      cardExpird: 'Esta tarjeta ha caducado. Por favor, elija otra tarjeta u otra forma de pago.',
      minAmountWarn:
        'La cantidad de retirada solicitada es menor al mínimo necesario para la plataforma de pago seleccionada. Por favor, asegúrese de que dispone de fondos suficientes antes de realizar una solicitud de retirada. Puede encontrar más información <a href="https://{regulatorUrl}/clients/accounts/withdraw/">aquí</a>.',
      noCardWarn:
        'No tiene ninguna tarjeta disponible para realizar las retiradas, primero vincule su tarjeta en "Datos de retirada".',
      equityStop:
        'Desafortunadamente, su retirada no puede ser procesada porque si no el margen disponible de su cuenta estará por debajo del 100%',
      equityMargin:
        'Lamentablemente, actualmente no podemos procesar su retiro porque su cuenta está totalmente cubierta.',
      equityContinue:
        'Hemos detectado que actualmente tiene posiciones abiertas, puede haber un cierre de alguna de ellas después de enviar la solicitud de retirada, por favor, confirme',
      YourPaymentGreaterThanAvailableBalance: 'Su pago es superior al saldo disponible',
      blackList:
        'Desafortunadamente actualmente no puede solicitar una retirada. Por favor, póngase en contacto con {supportEmail} para más información',
      NetellerChargeAlert:
        'Los retiros de Neteller están limitados a $ 500,000 por transacción y se aplica una tarifa del 2% (con un límite de $ 30).',
      FasaPayChargeAlert: 'FasaPay cobra una tarifa de transacción del 0,5% por cada retiro.',
      SkrillChargeAlert: 'Skrill cobra una tarifa de transacción del 1% por cada retiro.',
      localBankTransfer: 'Local Bank Transfer',
    },
    fasa: {
      field: { accName: 'Nombre de cuenta de FasaPay', accNum: 'Número de cuenta de FasaPay' },
      formValidation: {
        accNameReq: 'El nombre de la cuenta de FasaPay es obligatorio',
        accNumReq: 'El número de la cuenta de FasaPay es obligatorio',
      },
    },
    neteller: {
      field: { email: 'Email de Neteller' },
      formValidation: { emailReq: 'El email de Neteller es obligatorio' },
    },
    bitcoin: {
      field: { address: 'Dirección de la billetera de Bitcoin' },
      formValidation: { addressReq: 'La dirección de la cartera de Bitcoin es obligatoria' },
    },
    eth: {
      field: { address: 'Dirección de la billetera de ETH' },
      formValidation: { addressReq: 'La dirección de la cartera de ETH es obligatoria' },
    },
    usdc: {
      field: { address: 'Dirección de la billetera de USDC' },
      formValidation: { addressReq: 'La dirección de la cartera de USDC es obligatoria' },
    },
    usdt: {
      field: { address: 'Dirección de la cartera de USDT' },
      formValidation: { addressReq: 'La dirección de la cartera de USDT es obligatoria' },
    },
    paypal: {
      field: { email: 'Email de PayPal' },
      formValidation: { emailReq: 'El email de PayPal es obligatorio' },
    },
    skrill: {
      field: { email: 'Email de Skrill' },
      formValidation: { emailReq: 'El email de Skrill es obligatorio' },
    },
    webMoney: {
      field: { email: 'Email de WebMoney' },
      formValidation: { emailReq: 'El email de WebMoney es obligatorio' },
    },
    vload: {
      field: { email: 'Email registrado de Vload' },
      formValidation: { emailReq: 'Se requiere email de Vload registrado' },
    },
    sticpay: {
      field: { email: 'Email de SticPay' },
      formValidation: { emailReq: 'El email de SticPay es obligatorio' },
    },
    bitwallet: {
      field: { email: 'Email de Bitwallet' },
      formValidation: { emailReq: 'El email de Bitwallet es obligatorio' },
    },
    astropay: {
      field: { accountNumber: 'Cuenta de Astropay' },
      formValidation: { accountNumberReq: 'Se requiere cuenta de Astropay' },
    },
    transfer: {
      info: '{platform} reembolsará la comisión bancaria cobrada por <u>UNA</u> retirada al mes. Las retiradas adicionales durante el mes tendrán en una comisión bancaria de 20 unidades de su divisa de retirada.',
      label: {
        upload:
          'Por favor, envíe una copia de su extracto bancario (actualizado con una antigüedad no superior a 3 meses)',
        upload2:
          'Asegúrese de que la fecha del estado de cuenta o la fecha de la última transacción esté entre el mes actual y los últimos tres meses. Si supera este período, vuelva a cargar el estado de cuenta.',
      },
      formValidation: { upload: 'Por favor, envíe un extracto bancario' },
    },
    ccMethod: {
      desc1:
        'De conformidad con la política AML/CTC, los fondos deben ser transferidos a la tarjeta que se utilizó para su depósito inicial',
      desc2:
        'Si la tarjeta que utilizó para su depósito inicial no está disponible, por favor, póngase en contacto con nosotros en {mailto}',
    },
    otherMethod: {
      otherType: 'Otro tipo',
      withdrawalMethod: 'Método de retirada',
      desc1:
        'Por favor, tenga en cuenta que las transferencias bancarias pueden incurrir en comisiones. Por favor, asegúrese de que su cantidad de retirada es suficiente para cubrir cualquier comisión.',
      desc2:
        'De conformidad con la política AML/CTF, todas las retiradas deben transferirse utilizando el mismo método que se usó para añadir fondos a la cuenta.',
      note: "Debido a cambios recientes, nuestro banco podría no ser capaz de procesar transferencias internacionales para clientes registrados en ciertos países. <br/>\n      Si desea retirar a través de una transferencia bancaria internacional, por favor, póngase en contacto con atención al cliente en +44 2080 363 883 o en <a href='mailto:{mail}'>{mail}</a>.<br/>\n      Lamentamos los inconvenientes.",
      verificationCode:
        'El código de verificación ha sido enviado a {email}. Por favor, contacte con {supportEmail} si necesita más ayuda',
      note2:
        'Debido al impacto de la política de Corea del Sur sobre el Travel Rule, no aceptaremos retiros desde direcciones de intercambios coreanos que excedan los 735 USDT (aproximadamente 1,000,000 KRW) por transacción, ni de clientes cuyas direcciones no hayan pasado la verificación KYC.',
    },
  },
  transfer: {
    creditWarn:
      'No podemos procesar la transferencia de sus fondos desde la cuenta seleccionada porque su cuenta contiene crédito promocional.',
    blackListWarn:
      'La transferencia de sus fondos desde su cuenta MAM puede dar lugar a la liquidación de posiciones gestionadas. Si desea transferir sus fondos desde esta cuenta, por favor, contacte con {mailTo}.',
  },
  changePw: {
    header: 'CAMBIAR CONTRASEÑA',
    field: {
      currPw: 'Contraseña actual',
      newPw: 'Nueva contraseña',
      confirmPw: 'Confirmar nueva contraseña',
    },
    succ: 'Su contraseña ha sido actualizada. Ahora puede utilizar su nueva contraseña para acceder al "Portal de cliente".',
  },
  security: {
    confirm: 'Confirmar',
    cancel: 'Cancelar',
    understand: 'Entiendo',
    passwordRenewalReminder: {
      header: 'Cambio de Contraseña de Inicio de Sesión',
      desc: 'Para garantizar la seguridad de los fondos en su cuenta, los retiros no serán compatibles durante T+{days} días después de cambiar su contraseña de inicio de sesión.',
    },
    withdrawalFreeze: {
      header: 'Los retiros no se admiten temporalmente',
      userLoginDesc:
        'Debido a la modificación de su dirección de correo electrónico/número de teléfono de inicio de sesión, los retiros no son compatibles temporalmente durante T+{days} días. Se espera que esta restricción se levante en {hours} horas.',
      passDesc:
        'Debido a la modificación de su contraseña de inicio de sesión, los retiros no son compatibles temporalmente durante T+{days} días para garantizar la seguridad de su cuenta. Se espera que esta restricción se levante en {hours} horas.',
      limitSecurityAuthenticator:
        'Debido a la modificación de su aplicación de Autenticación de Seguridad, los retiros no son temporalmente admitidos por T+{days} días para asegurar la seguridad de su cuenta. Se espera que esta restricción se levante en {hours} horas.',
    },
    securityManagement: 'Gestión de seguridad',
    securityAuthentication: 'Autenticación de seguridad',
    changeAuthenticatorTitle: '\tCambiar Autenticador de Seguridad',
    authenticationMethod: 'Método de autenticación',
    isVerified: 'Verificado',
    isNotVerified: 'No verificado',
    password: 'Contraseña',
    securityAuthenticatorApp: 'Aplicación de autenticación de seguridad',
    enableLoginAuthentication: 'Habilitar la autenticación de inicio de sesión',
    loginLocationChange:
      'Notificación por correo electrónico de cambio de ubicación de inicio de sesión',
    lastUpdate: 'Última actualización',
    modify: 'Modificar',
    verify: 'Verificar',
    enable: 'Habilitar',
    resend: 'Reenviar',
    enterVerificationCode: 'Introducir código de verificación',
    verifyPreEmailNote: 'El código se enviará a {email}',
    verifyPostEmailNote: 'Código enviado a {email}',
    preEmailCode: 'El código se enviará a su nueva dirección de correo electrónico',
    authenticatorTooltip:
      'El código de verificación de la aplicación Authenticator se actualiza cada 30 segundos',
    contactSupport:
      '¿No puede acceder al método de autenticación? Por favor, contacte a {contact_email} o Livechat',
    changeEmail: 'Cambiar dirección de correo electrónico',
    emailTitle: 'Introduce nueva dirección de correo electrónico',
    editAuthenticator: 'Editar dispositivo de autenticación',
    addAuthenticator: 'Agregar dispositivo de autenticación',
    scanWithApp: 'Escanear con la aplicación Authenticator',
    scanAppTooltip:
      'Puede descargar Google Authenticator o Microsoft Authenticator desde la tienda de aplicaciones de su teléfono. Luego, use la cámara dentro de la aplicación para escanear el código QR proporcionado.',
    scanAppTooltipForChina:
      'Puede descargar Google Authenticator o Microsoft Authenticator desde la tienda de aplicaciones de su teléfono o buscar Google Authenticator en el programa Mini de WeChat y elegir el primer resultado. Luego, use la cámara dentro de la aplicación para escanear el código QR proporcionado.',
    scanAuthenticatorAppTips: {
      tips1:
        'Puede descargar Google Authenticator o Microsoft Authenticator desde la tienda de aplicaciones móviles',
      tips2:
        'Alternativamente, también puede buscar Google Authenticator en el Mini Programa de WeChat y seleccionar el primer resultado.',
      tips3:
        'Luego, use la cámara dentro de la aplicación para escanear el código QR proporcionado',
    },
    userGuide: 'Guía del usuario',
    setupKey: 'Clave de configuración',
    tnc: 'Al vincular una aplicación de autenticación, confirma que ha leído y aceptado el {link}.',
    disclaimer: 'descargo de responsabilidad',
    changePasswordSuccess: 'Contraseña actualizada',
    changeEmailSuccess: 'Dirección de correo electrónico actualizada',
    enableSecuritySuccess: 'Autenticador de seguridad habilitado',
    updateSecuritySuccess: 'Actualización del Autenticador de Seguridad',
    changePasswordSuccessDesc:
      'Su contraseña ha sido actualizada. <br/> Ahora puede usar una nueva contraseña para iniciar sesión.',
    changeEmailSuccessDesc:
      'Su dirección de correo electrónico ha sido actualizada. <br/> Ahora puede usar una nueva dirección de correo electrónico para iniciar sesión.',
    enableSecuritySuccessDesc: 'Su aplicación de autenticación de seguridad ha sido habilitada.',
    updateSecuritySuccessDesc: 'Su aplicación de autenticación de seguridad ha sido actualizada.',
    passwordReq: 'Requisitos de contraseña:',
    passwordCharacters: '8-16 caracteres',
    passwordMix: 'Una combinación de letras (a-z y A-Z) y números (0-9)',
    passwordSpecial: 'Caracteres especiales como !@#$%^&*().',
    confirmPwd: 'Confirmar contraseña',
    enableNow: 'Activar ahora',
    changeDesc:
      'Para garantizar la seguridad de los fondos en su cuenta, no se admitirán retiros durante T+ {numDays} días después del cambio de su {method}.',
    verificationTitle: 'Verificación requerida',
    verificationDesc:
      'Por favor, habilite la aplicación de autenticación de seguridad antes de continuar.',
    updateSuccess: 'Actualizado exitosamente',
    verifiedSuccess: 'Verificado con éxito',
    verificationRequired: 'Por favor, introduzca el código de verificación',
    next: 'Siguiente',
  },
  reserPwMobile: { header: 'RESTABLECER CONTRASEÑA' },
  downloads: {
    appDesc: 'Descargue la aplicación de {platform} en la App Store y Google Play Store',
    mt4: 'METATRADER 4',
    mt4Desc:
      'Descargue MetaTrader 4 para PC, Mac, navegadores web, teléfonos inteligentes y tablets',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android',
    web: 'WebTrader',
    webTrader: 'Web Trader',
    smartTrader: 'Herramientas de SmartTrader',
    mt5: 'METATRADER 5',
    mt5Desc:
      'Descargue MetaTrader 5 para PC, Mac, navegadores web, teléfonos inteligentes y tablets',
    button: { download: 'DESCARGAS', launch: 'ejecutar' },
  },
  forgetPwReq: {
    headerMobile: 'INTRODUZCA SU NÚMERO DE TELÉFONO DE ACCESO',
    labelEmail: 'Email',
    labelMobile: 'Número de teléfono',
    loginMobile: 'Número de teléfono de acceso',
    loginSMSCode: 'Código SMS',
    loginSmsCodeExpires: 'El código SMS expira en 5 minutos',
    getCode: 'Envía el código SMS',
    failedCode: 'No se puede enviar el código SMS a su teléfono',
    sentAlready: 'enviado',
    expired: 'El código del SMS ha expirado',
    tip: 'Introduce la dirección de correo electrónico de tu cuenta para restablecer tu contraseña.',
    formValidation: {
      emailReq: 'El email de acceso es obligatorio',
      mobileReq: 'Se requiere inicio de sesión móvil',
      smsCodeReq: 'El código sms es obligatorio',
    },
    succ: 'Acabamos de enviarle un correo electrónico con instrucciones para restablecer su contraseña.<br><br>Asegúrese de revisar la carpeta de spam de su email.',
  },
  loginClient: {
    header: 'PORTAL DE CLIENTE',
    failedMasseage: 'vuelva a intentarlo',
    existingClientPortalAccess:
      'Vemos que ya tiene acceso al "Portal de cliente".<br /><br />¡Inicie sesión con su correo electrónico para abrir una cuenta nueva!',
    existingClientPortalAccessMobile:
      'Vemos que ya tiene acceso al Portal del Cliente.<br /><br /> Regístrese usando su número de teléfono para abrir una nueva cuenta!',
    formValidation: {
      emailReq: 'La dirección de email es obligatoria',
      mobileReq: 'Por favor, introduzca solamente valores numéricos',
    },
  },
  myProfile: {
    changeDetails:
      'Si desea actualizar los datos de su perfil, no dude en ponerse en contacto con {mailTo}',
  },
  openAdditAcc: {
    chooseTradePlat: 'ELIJA UNA PLATAFORMA DE TRADING',
    chooseAccType: 'ELIJA A UN TIP DE CUENTA',
    chooseCurrency: 'ELIJA UNA DIVISA DE CUENTA',
    chooseLeverage: 'Elija el apalancamiento',
    chooseBalance: 'SALDO DE LA CUENTA',
    notes: 'NOTAS ADICIONALES',
    currError: 'Seleccione una moneda base para su cuenta',
    platErr: 'Debe seleccaionar una plataforma de trading',
    accTypeErr: 'Debe seleccionar un tipo de cuenta',
    leverageErr: 'Debe seleccionar un apalancamiento',
    balanceErr: 'Debe seleccionar un saldo',
    succ: '<p>Su cuenta real adicional esta siendo creada y pronto recibirá un correo electrónico con sus detalles de acceso.</p><p>Puede ver el estado actual de su solicitud desde la página de inicio.</p>',
    byTicking: 'Al marcar esta casilla:',
    swapFreeNote:
      '*Por favor, tenga en cuenta que: en lugar de cargos de swap puede aplicarse una pequeña tarifa administrativa.',
    pammNote:
      '*Las cuentas PAMM son solo para inversores con un acuerdo de Gestor de Capital. Las cuentas PAMM solo pueden utilizarse para operar por el Gestor de Capital.',
  },
  openDemoAdditAcc: {
    header: 'ABRIR UNA CUENTA DEMO ADICIONAL',
    button: 'abrir cuenta demo',
    liveButton: 'abrir cuenta en vivo',
    succ: 'Su cuenta demo se ha configurado y recibirá un correo electrónico con sus detalles de inicio de sesión en breve.',
  },
  resetPassword: {
    header: 'RESTABLECER CONTRASEÑA',
    succ: 'Su contraseña ha sido actualizada. Ahora puede utilizar su nueva contraseña para acceder a su cuenta de trading MT4/MT5.',
    succ2: 'Su contraseña ha sido actualizada.',
    failed: 'El token ha expirado',
    emailNotFound: 'Desafortunadamente, su dirección de correo electrónico no fue reconocida',
  },
  fileUploader: {
    supportedFormat: 'Tipos de archivo compatibles: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Tipos de archivo compatibles: {files}',
    maxSize: 'Tamaño máximo de archivo subido: {maxSize} MB',
    maxFileNum: 'Número máximo de archivos: {maxFileNum}',
    failedFileExtension: 'Cargue el tipo de archivo correcto',
  },
  transcationHistory: {
    deposit: 'DEPOSITAR',
    withdraw: 'RETIRAR',
    desc: {
      incomplete:
        'Si finaliza o no completa el proceso de depósito, el estado de la transacción se mostrará como "incompleto". Tendrá que crear un nuevo depósito ya que esta transacción no se puede reanudar.',
      success:
        'Su transacción se ha realizado con éxito Sus fondos deberían aparecer en su cuenta a la mayor brevedad posible',
      proc: 'Su transacción está siendo procesada. Por favor, contacte con {mailTo}',
      failed:
        'Error en su transacción. Por favor, póngase en contacto con {mailTo} para más información',
      rejected:
        'Su transacción ha sido rechazada. Por favor, póngase en contacto con {mailTo} para más información',
    },
    withdrawDesc: {
      sub: 'Su transacción ha sido enviada. Por favor, espere para completar.',
      suc: 'Su transacción se ha realizado con éxito Sus fondos deberían aparecer en su cuenta a la mayor brevedad posible',
      proc: 'Su transacción está siendo procesada. Por favor, contacte con {mailTo}',
      incomp:
        'Su transacción requiere pasos adicionales para ser finalizada. Por favor, póngase en contacto con {mailTo} para más información',
      cancel:
        'Su transacción ha sido cancelada. Por favor, póngase en contacto con {mailTo} para más información',
      fail: 'Error en su transacción. Por favor, póngase en contacto con {mailTo} para más información',
      rej: 'Su transacción ha sido rechazada. Por favor, póngase en contacto con {mailTo} para más información',
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'Desafortunadamente, su transferencia no puede ser procesada porque si no el margen disponible de su cuenta estará por debajo del 100%',
      equityMargin:
        'Desafortunadamente, actualmente no podemos procesar su transferencia porque su cuenta tiene cobertura total.',
      equityContinue:
        'Hemos detectado que actualmente tiene posiciones abiertas, puede haber un cierre de alguna de ellas después de enviar la solicitud de transferencia. Por favor, confirme si desea proceder.',
      blackList:
        'Esta cuenta ha sido restringida por hacer una transferencia entre cuentas, por favor, póngase en contacto en {supportEmail}',
    },
    pleaseNote: 'Por favor, tenga en cuenta que:',
    suc: 'Sus fondos han sido transferidos con éxito',
    warn1:
      'Usted es responsable de que el saldo de su cuenta no provoque una llamada de margen. Si tiene posiciones abiertas, asegúrese de mantener el margen requerido en su cuenta. Los fondos transferidos a cuentas que tienen la misma divisa se procesan instantáneamente.',
    warn2:
      'Si transfiere fondos entre cuentas que tienen diferentes divisas, envíe un correo electrónico a {mailTo}.',
    warn3: 'Tenga en cuenta que los fondos no se pueden transferir entre cuentas de solo lectura.',
  },
  transferHistory: {
    payStatus: {
      processing: 'Procesando',
      rejected: 'Rechazado',
      paid: 'Pagado',
      unpaid: 'No pagado',
    },
  },
  identityProof: {
    home: {
      header: 'SOLICITUD DE CUENTA NO COMPLETADA',
      content:
        'Hola {name}, la solicitud de su cuenta está casi completada, sin embargo, debe enviar documentación adicional para terminar.',
    },
    shufti: {
      content:
        'Asegúrese de que los detalles proporcionados coincidan con la documentación de su identificación y estén en el mismo idioma.',
      countryOfDocument: 'País como se muestra en el documento',
    },
    popup: {
      header:
        'Hola {name}, antes de que pueda finalizarse su cuenta en vivo debe enviar el siguiente documento para su verificación.',
      addressProofSpan:
        '<strong>\n     <b>Confirmar dirección</b>\n      </strong>\n      <br />\n      <I>\n        Envíe <b>uno</b> de los siguientes documentos\n        <br /><br />\n      </I>\n      -  Copia de una factura de gastos reciente </br>(Con una validez de hasta 6 meses)\n      <br />\n      <b class="or">or</b><br />\n      - Copia de un extracto bancario reciente </br>(Con una validez de hasta 6 meses)\n      <br />\n      <br />\n      <b>¡IMPORTANTE!</b> El documento DEBE mostrar claramente su nombre completo y dirección actual.\n      <br />',
      idProofSpan:
        '<strong>\n      <b><b>Confirmar identidad</b></b>\n      </strong>\n      <br />\n      <I>Seleccione\n        <b>una</b> de las siguientes opciones\n        <br />\n        <br />\n      </I>\n      - Copia de pasaporte válido<br />\n\n      - Copia de permiso de conducir válido\n      <br />\n      (Anverso y reverso)\n      <br />\n     o</b><br />\n      - Copia del documento nacional de identidad\n      <br />\n      <br />\n      <b>¡IMPORTANTE!</b> El documento DEBE mostrar claramente su nombre completo<br />',
    },
    reasonType: {
      4: 'Documentos incompletos',
      5: 'Documentos no aprobados',
      6: 'Requerida copia en color',
      8: 'El nombre de la apertura de cuenta no coincide',
      9: 'La fecha de emisión de la prueba de dirección no reúne los requisitos requeridos',
    },
    reason:
      'No se ha podido verificar su documentación,<br> Razón: {reason}. Por favor, inténtelo de nuevo.',
  },
  header: { secPortal: 'Zona de cliente segura' },
  menu: {
    home: 'INICIO',
    accManagement: 'CUENTAS',
    liveAcc: 'CUENTAS EN VIVO',
    openAdditAcc: 'ABRIR CUENTAS ADICIONALES',
    openAnAdditLiveAcc: 'ABRIR UNA CUENTA REAL ADICIONAL',
    homeDemo: 'CUENTAS DEMO',
    depositFund: 'DEPOSITAR FONDOS',
    withdrawFunds: 'RETIRAR FONDOS',
    transferBetweenAccs: 'TRANSFERENCIA ENTRE CUENTAS',
    payDetails: 'DATOS DEL PAGO',
    funds: 'FONDOS',
    transactionHis: 'HISTORIAL DE TRANSACCIONES',
    tranHis: 'TRANSFERENCIAS',
    download: 'DESCARGAS',
    profile: 'PERFIL',
    myProfile: 'MI PERFIL',
    cpPassword: 'CAMBIAR LA CONTRASEÑA',
    contactUs: 'Contacto',
    helpSupport: 'FORMATOS DE AYUDA Y SOPORTE',
    register: 'CONTINUAR LA APLICACIÓN',
    tradingTools: 'HERRAMIENTAS PROTRADER',
    MARKET_BUZZ: 'MARKET BUZZ ',
    ECON_CALENDAR: 'CALENDARIO ECONÓMICO',
    FEAT_FX: 'IDEAS DESTACADAS',
    FX_IDEAS: 'Vistas técnicas',
    ALPHA_GENERATION: 'ALPHA GENERATION',
    MARKET_MASTERS: 'EXPERTOS DEL MERCADO',
    marketWidgets: 'Aplicaciones de Trade',
    technicalAnalysis: 'Análisis técnico',
    techAnalysisTab: {
      forex: 'FOREX',
      indices: 'ÍNDICES',
      commodities: 'COMMODITIES',
      sharedcfds: 'CFDs SOBRE ACCIONES',
      crypto: 'CRYPTO',
    },
    marketData: 'información sobre el mercado',
    forexCrossRates: 'Los tipos de cambio de la moneda',
    forexHeatMap: 'mapa de popularidad de forex',
    forexScreener: 'forex examinador',
    referAFriend: 'Recomiende a un amigo',
    wholesale: 'ACTUALIZAR A PRO',
    tradingView: 'CHARTS de TradingView',
    twoFactorAuthentication: 'AUTENTICACIÓN DE DOS FACTORES',
    promotion: 'Promociones',
    depositBonus: '50% Bono por depósito',
    hmcTrader: 'Sala de trading en vivo',
    support: 'Ayuda',
    securityManagement: 'Gestión de seguridad',
  },
  contact: {
    desc: 'Si tiene cualquier pregunta o desea más información, por favor, póngase en contacto con nosotros utilizando uno de los siguientes datos de contacto. Nos esforzaremos por responder a todas las preguntas en 24 horas.',
    tip: 'Para clientes que accedieron a través de {email}',
    liveChat: 'Chat en vivo',
    helpSupportForm: {
      partner: 'Formulario de transferencia de asociación',
      investigation: 'Formulario de investigación de trading de Moneta',
      promotion: 'Dese de baja del formulario de promociones de Moneta',
      request: 'Formulario de solicitud de cierre de cuenta',
    },
    accountManager: 'Gestor de cuenta - {userName}',
    monetaSupport: 'Atención al cliente de Moneta',
  },
  depositResultDialog: { msg: '¿Ha completado su depósito correctamente?' },
  dialog: {
    identityProof: {
      success:
        'Gracias. Hemos recibido sus documentos y los estamos procesando para su verificación.',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: 'PROCESANDO SOLICITUD DE CUENTA',
      id: 'La identificación es',
      poa: 'La dirección es',
      both: 'La identificación y la prueba de dirección son',
      content:
        'Su prueba de {doc} está siendo procesada y se completará en breve. Para una mayor rapidez en el proceso de su solicitud de cuenta,  puede financiar su cuenta ahora y recibir una apertura prioritaria.',
    },
  },
  idPoa: {
    IdPoaNoPass: {
      header: 'Solicitud de cuenta incompleta ',
      content:
        'Para empezar a operar, por favor, envíe sus documentos de prueba de identidad y de prueba de dirección. Una vez enviados, añada fondos a su cuenta para obtener una apertura de cuenta prioritaria.',
    },
    onlyIdPass: {
      header: '¡Gracias!',
      content:
        'Su solicitud está siendo procesada. Añada fondos en su cuenta ahora para obtener una apertura de cuenta prioritaria.',
    },
    onlyPoaPass: {
      header: 'Solicitud de cuenta incompleta ',
      content:
        'Para empezar a operar, por favor, envíe su documento de prueba de identidad. Tras enviarlo, añada fondos a su cuenta para obtener una apertura de cuenta prioritaria.',
    },
    bothIdPoaPass: {
      header: '¡Gracias!',
      content:
        'Su solicitud está siendo procesada. Añada fondos en su cuenta ahora para obtener una apertura de cuenta prioritaria.',
    },
    uploadID: 'Cargar ID',
    uploadPoa: 'Subir Domicilio',
    depositNow: 'Depositar ahora',
  },
  register: {
    progressStatus: {
      title: 'Proceso de verificación',
      estimatedTimeTitle: 'Tiempo estimado para completar:',
      min: 'Minutos',
    },
    tabs: {
      personalDetails: 'Datos personales',
      moreAboutYou: 'Más sobre usted',
      accountConfig: 'Configuración de cuenta',
      confirmYourId: 'Confirme su identidad',
      fundYourAcc: 'Financie su cuenta',
    },
    btn: {
      next: 'SIGUIENTE',
      back: 'ANTERIOR',
      fundnow: 'AÑADIR FONDOS AHORA',
      upload: 'Enviar',
      done: 'HECHO',
      uploadNew: 'Subir nuevo',
    },
    authority: {
      dialog1: 'Debe tener una cuenta en vivo para acceder al portal de cliente.',
      dialog2:
        'Abra una cuenta real ahora para desbloquear el acceso completo al portal del cliente, que incluye una variedad de herramientas comerciales, promociones y más.',
      openLiveAcc: 'abrir cuenta en vivo',
    },
    demo: {
      congrad: '¡Felicitacion!!',
      title: 'Su cuenta DEMO MT{platform} de 30 días está activa ahora',
      title2: 'Datos de su cuenta DEMO',
      li: {
        login: 'Usuario: ',
        password: 'Contraseña: ',
        srv: 'Servidor:',
        expDate: 'Fecha de vencimiento: ',
      },
      emailSent: '* Los datos de inicio de sesión se han enviado a su correo electrónico también.',
      dl: 'Descargue su plataforma de trading MT{platform}',
      proTraderPlatform: {
        content:
          'Inicie sesión en nuestra plataforma PRO Trader de última generación usando los datos de su cuenta MT{platform}:',
      },
      appTrader: {
        content:
          'Inicie sesión en AppTrader de Moneta Markets usando sus credenciales del portal de cliente. ¡Consiga AppTrader para iOS o Android ya!',
      },
      improveQ: '¿Desea mejorar su trading?',
      improveWay:
        'Simplemente, abra una cuenta en vivo en solo unos minutos para conseguir una variedad de promociones y herramientas de trading exclusivas.',
      proTrade: 'Herramientas Pro Trader',
      depositBonus: 'Bono por depósito del 50%',
      smartTradeTool: 'Herramientas Smart Trader',
      openLiveAcc: 'abrir cuenta en vivo',
      poweredBy: 'Con tecnología de',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Datos personales',
        userTitle: 'Título',
        firstName: 'Nombre',
        lastName: 'Apellidos',
        email: 'E-mail',
        dob: 'Fecha de nacimiento',
        idType: 'Tipo de identificación',
        referral: '¿Ha sido recomendado por alguien? (opcional)',
        middleName: 'Segundo nombre',
        nationality: 'Nacionalidad',
        phoneNum: 'Número de teléfono',
        date: 'Día',
        month: 'MontMesh',
        year: 'Año',
        placeOfBirth: 'Lugar de nacimiento',
        idNum: 'Número de identificación',
        passportNum: 'Número del pasaporte',
        driverLicenseNum: 'Número del permiso de conducir',
        titleDropdown: { mr: 'Sr', mrs: 'Sra', ms: 'Srto', miss: 'Srta', dr: 'Dr', prof: 'Prof' },
        idTypeDropdown: {
          passport: 'Pasaporte',
          idCard: 'Documento nacional de identidad',
          driverLicense: 'Permiso de conducir',
        },
      },
      page2: {
        pageTitle: 'DIRECCIÓN PRINCIPAL',
        pageTitleForFCA: 'MÁS DETALLES',
        mainResidency: 'País principal de residencia',
        streetNumber: 'Número de la calle',
        streetName: 'Nombre de la calle',
        address: 'Dirección',
        provinceOrState: 'Provincia/Estado',
        cityOrSuburb: 'Ciudad/Barrio',
        postcode: 'Código postal',
        previousCountry: 'País anterior',
        usQuestion:
          '¿Es usted ciudadano estadounidense/residente en EE. UU. a efectos fiscales o tiene vínculos con los EE. UU. (como una cuenta bancaria, etc.)',
        taxResidencyCountry: 'País(es) de residencia fiscal',
        nationalInsuranceNumber: 'Número de Seguro Nacional (NI)',
        yearsAtAddress: 'Número de años en su domicilio',
        previousResidentialAddressTip: 'Por favor, introduzca su dirección anterior',
      },
    },
    moreAboutYou: {
      pageTitle1: 'EMPLEO Y DATOS FINANCIEROS',
      pageTitle2: 'Experiencia y conocimiento de trading',
      complianceExplain:
        'Las siguientes preguntas se recopilan para cumplir con los requisitos AML/CTF.',
      FCAQuestion10Tip:
        'En los últimos 12 meses, ¿cuántas operaciones ha ejecutado de media en los siguientes instrumentos por trimestre?',
    },
    accountConfig: {
      pageTitle: 'Configuración de cuenta',
      chooseTradingPlatform: 'Elija una plataforma de trading',
      chooseAccType: 'Elija un tipo de cuenta',
      chooseAccCurrency: 'Elija una divisa de cuenta',
    },
    confirmYourId: {
      pageTitle: 'Confirme su identidad',
      description:
        'Como broker de forex regulado, debemos verificar su identidad. Envíe uno de los siguientes documentos para su verificación de identidad y dirección. También, puede enviar sus documentos de verificación por correo electrónico a {mailTo}',
      poidTitle: 'Prueba de identidad',
      porTitle: 'Prueba de dirección',
      uploadDescription: 'Elija un tipo de documento y envíelo',
      note: 'IMPORTANTE: El documento DEBE mostrar claramente su nombre completo.',
      pending: { upload: 'Pendiente de enviar', verification: 'Pendiente de verificar' },
      poidTypes: {
        photoId: 'Documento de identidad con foto',
        passport: 'Una copia de su pasaporte internacional (Página de firmas)',
        drivingLicence: 'Permiso de conducir',
      },
      porTypes:
        '<li>Factura de gastos</li>\n      <li>Extracto bancario</li>\n      <li>Certificado de residencia</li>',
    },
    finishPage: {
      title: '{individualUserName}, ¡gracias por solicitar una cuenta en vivo en {platformName}!',
      contentDefault:
        'Hemos recibido su solicitud y configurado su cuenta en vivo<b>{account}</b>. <br />\n      En breve, recibirá un correo electrónico en <b>{email}</b> con sus datos de inicio de sesión y un enlace para ver el estado de su cuenta. <br />',
      contentWithoutId3WithAccount:
        'Su cuenta en vivo de trading está siendo procesada y recibirá un correo electrónico con más información en breve.<br />\n      Para agilizar el procesamiento de su cuenta real, puede financiar su cuenta ahora y recibir una apertura prioritaria.<br />',
      contentWithoutId3WithoutAccount:
        'Su cuenta en vivo de trading está siendo procesada y recibirá un correo electrónico con más información en breve.<br />',
    },
  },
  tradingTools: {
    MARKET_BUZZ:
      'Acceda a una cobertura de noticias y contenido de medios que cubre más de 35.000 activos negociables.',
    FEAT_FX: 'Vea configuraciones técnicas prácticas de trading y siga su progreso en tiempo real.',
    ECON_CALENDAR:
      'Disfrute de datos económicos de todo el mundo en tiempo real las 24 horas del día.',
    FX_IDEAS: 'Vea información técnica de trading atractiva y educativa para 80.000 instrumentos.',
    ALPHA_GENERATION:
      'Aumente su ventaja de trading y obtenga 3 indicadores avanzados para MT4 y MT5 de última generación.',
    MARKET_MASTERS:
      'Aprenda a operar como los profesionales con más de 100 tutoriales avanzados de trading en video.',
    condition: {
      needDeposit:
        'Para obtener acceso a su paquete de herramientas PRO trader y tutoriales exclusivos de trading en video, solo tiene que añadir fondos a su cuenta real por valor de 500 $ o más.',
      congratulations:
        '¡Enhorabuena! ¡Ya está listo para desbloquear sus herramientas de operador premium!',
      needTnc: 'Por favor, acepte los{link}',
      link: 'términos y condiciones',
      unlock: 'DESBLOQUEAR LAS HERRAMIENTAS PRO TRADER',
      depositNow: 'DEPOSITAR AHORA',
      tncAisc: 'Acepto tener acceso a estas herramientas.',
    },
    techAna: 'Análisis técnico de',
    byTradingView: 'Por Tradingview',
    alphaGeneration: {
      intro: 'GENERACIÓN ALPHA PARA MT4 Y MT5',
      introDesc:
        'Lleve a otro nivel sus operaciones con tres EA premium exclusivos de Moneta Markets.',
      analystViews: {
        title: 'Vistas técnicas',
        desc: 'Niveles técnicos de precios y tendencias instantáneos se despliegan en su gráfico de precios.',
      },
      adaptiveCandlesticks: {
        title: 'VELAS ADAPTATIVAS',
        desc: 'Escaneo en tiempo real de 16 patrones de velas populares.',
      },
      adc: {
        title: 'ADC',
        desc: 'Señales de trading de entrada y salida a corto plazo en direcciones largas y cortas.',
      },
      install: '¿CÓMO INSTALAR ALPHA GENERATION?',
      installSteps: {
        one: 'Descargue Alpha Generation para PC haciendo clic en el botón "Descargar Alpha Generation" que aparece a continuación.',
        two: 'Haga doble clic en el archivo para ejecutarlo y siga el asistente de instalación que le guiará a través del proceso en el idioma de su elección.',
        three: 'Elija su carpeta de destino de Moneta Markets MT4 o MT5.',
        four: 'Una vez instalado, abra su plataforma MT4 o MT5, y localice los indicadores de Trading Central al lado de los demás en su lista de indicadores de MetaTrader.',
        five: 'Arrastre un indicador al gráfico y obténga información sobre las operaciones de forma inmediata.',
      },
      button: { guid: 'Guía de usuario', download: 'Descargar Alpha Generation' },
    },
  },
  tradingView: {
    launch: 'ejecutar',
    tnc: 'Inicie sesión en la plataforma de trading y charting TradingView con sus datos de acceso de MT{mtServer}. Descubra más sobre CHARTS de TradingView <a href= https://{regulatorUrl}/platforms/trading-platforms/charts-by-tradingview/ >aquí</a>.',
    subTitle:
      'Únase a otros 15 millones de inversores y empiece a operar con los indicadores, herramientas de trazado y gráficos de TradingView.',
    intro:
      'Presentando CHARTS de TradingView. Ahora los clientes de Moneta pueden encontrar ideas de trading, descubrir tendencias y colocar operaciones en vivo en nuestra plataforma integrada de TradingView, CHARTS.',
    descp1: 'Más de 100 indicadores técnicos',
    descp2: 'Actualizaciones de noticias de mercado en vivo',
    descp3: 'Más de 50 herramientas de trazado inteligente',
    descp4: 'Análisis con varias escalas temporales',
    descp5: '12 condiciones de alerta de trading distintas',
    descp6: 'Múltiples plazos temporales',
    descp7: 'Más de 50 herramientas de trazado inteligentes',
    descp8: 'Más de 20 diseños de gráficos',
    descp9: 'Más de 12 tipos de gráficos personalizables',
    descp10: 'Cobertura de noticias de mercado integrada',
    learnMore: 'Leer más',
    tnc02:
      'Utilice sus datos de acceso a MT{mtServer} y experimente la plataforma web Protrader ahora. {findMore}',
    tnc03: 'Más información',
    subTitle02:
      'Los usuarios de la plataforma ProTrader de Moneta ahora tienen acceso a gráficos de precios en vivo, indicadores técnicos y herramientas de trazado, todo ello desarrollado por TradingView; en más de 300 pares de FX, índices, materias primas, CFDs sobre acciones y mucho más.',
    intro02: 'Obtenga acceso a:',
    intro03: 'Desarrollado por',
  },
  TFAuth: {
    title: 'AUTENTICACIÓN DE DOS FACTORES',
    enable: {
      enableTitle: 'ACTIVAR LA DOBLE AUTENTICACIÓN',
      enableDesc:
        'Mantenga la seguridad de su cuenta activando la doble autenticación por móvil. La doble autenticación proporciona una capa adicional de seguridad y tranquilidad mediante un segundo paso de verificación cada vez que inicia sesión. Al activarlo, puede obtener un código de verificación doble de una aplicación en su teléfono iOS o Android en cualquier momento, insertarlo e iniciar sesión de forma segura.',
      enableSubtitle: 'Cómo activar la doble autenticación',
      step1:
        'Descargue e instale Google Authenticator gratis de la tienda de aplicaciones de iOS o Android.',
      step2:
        'Una vez en la aplicación, seleccione "Escanear código de barras" y escanee el código de barras a continuación.',
      step3:
        'Introduzca el código numérico en la aplicación (se actualiza cada 30 segundos) a continuación.',
      placeHolder: 'código',
    },
    disable: {
      disableTitle: 'DESACTIVAR LA DOBLE AUTENTICACIÓN',
      disableDesc:
        'Para desactivar la doble autenticación, envíe el código numérico de su aplicación Google Authenticator aquí.',
    },
    dialog: {
      title: 'Introduzca su código de doble autenticación',
      contact: '¿Tiene problemas para iniciar sesión? {link}.',
      link: 'Contacte con nosotros',
    },
    popup: {
      popup1: 'La doble autenticación está activada.',
      popup2: 'La doble autenticación está desactivada.',
    },
  },
  promotion: {
    depositBonus: {
      depositBonus: 'Haga un depósito y reclame su bono de trading',
      title: '¡Reclame su bono y mejore su depósito!',
      subtitle1:
        '¿Desea más potencia de trading? Solo tiene que añadir fondos a su cuenta en vivo. En cuanto recibamos su depósito, el bono por depósito aparecerá en su cuenta de trading.',
      fund: 'Añada fondos a su cuenta',
      tnc: '*Se aplican los {link}',
      link: 'términos y condiciones.',
      bonusHistory: 'Historial de bonos',
      overBonus:
        '* Ha alcanzado el máximo de ${totalLimit} en créditos de bono. Los depósitos adicionales no optarán a créditos de bono.',
      opt: '¿Desea participar para reclamar su bono por depósito?',
      tick: 'Por favor, marque la casilla si desea participar en la promoción de bono por depósito.',
      tick2:
        'Moneta se reserva el derecho de excluir y/o cancelar la participación de un cliente en sus ofertas o promociones en caso de:<br><br> a) Churning (abrir y cerrar una transacción de FX o CFDs con margen solo por la ventaja de obtener un beneficio);<br> b) se descubre que el cliente está incumpliendo este Acuerdo de cliente o ha incumplido los términos y condiciones de las promociones.<br><br> Si se produce dicha conducta inapropiada o incumplimiento, Moneta tendrá derecho de deducir cualquier cantidad de bono y beneficio obtenido de la cuenta de trading.\n      <br><br>Si la compañía sospecha o tiene razones para creer que un cliente ha abusado y/o manipulado los términos y condiciones de este plan de bono cubriendo sus posiciones internamente (usando otras cuentas de trading mantenidas con la compañía) o externamente (usando otras cuentas de trading mantenidas con otros brokers), y/o no ha actuado de buena fe, la compañía se reserva el derecho, a su sola discreción y sin obtener el consentimiento del cliente, de eliminar la cantidad del bono y el beneficio obtenido de la cuenta de trading con efecto inmediato.\n      <br><br>Moneta puede hacer cambios en estos términos y condiciones en cualquier momento y le notificará de dichos cambios publicando los términos modificados en el sitio web de Moneta. Le recomendamos que revise estos Términos regularmente, y con su uso continuado del sitio web y los servicios de Moneta acepta cualquiera de estos términos modificados. Moneta se reserva el derecho de modificar o cancelar cualquier oferta a su sola discreción y en cualquier momento.',
      saveChange: 'Guardar cambios',
      creditReceived: 'Crédito recibido',
      welcomeBonusTable: 'Bono de bienvenida',
      depositBonusTable: 'Bono por depósito',
      depositDate: 'Fecha de depósito',
      credit: 'Crédito',
      optIn: 'Acepte participar con éxito',
      optOut: 'Exclusión exitosa',
      notification:
        '<div>Puede optar a unirse a la campaña de Bono por Depósito. Por favor, ‘<a href="{depositBonusLink}">dese de alta</a>\' antes de depositar para obtener su bono por depósito. Descubra más <a href="{tncLink}" target="_blank">aquí</a>.</div>',
      optInButton: 'dese de alta',
      dontShowAgain: 'No volver a mostrar este mensaje',
    },
    referAFriend: {
      congratulations:
        '¡Enhorabuena! Se acaba de unir con éxito a la promoción Recomiende un amigo como referenciador.',
      redeem: 'Canjear',
      redeemed: 'Canjeado',
      dayRemain: 'días restantes',
      voucherDesc: 'El cupón se puede canjear una vez que opere {lots} o más lotes.',
      title: '¡Recomiende a un amigo y gane hasta {amount}$ y {secAmount}$ para su amigo!',
      desc: 'Cuando su referido complete los siguientes pasos en un plazo de 30 días, ¡ganará hasta {amount}$! ¡Y no hay límite a cuántos amigos puede recomendar! *Se aplican los <a href="{tncLink}">términos y condiciones.</a>',
      register: 'Regístrese con nosotros',
      forYourFriend: 'para su amigo',
      liveAcc: 'Abra una cuenta en vivo',
      makeDeposit: 'Efectúe un depósito',
      trade1Lot: 'Opere con 1 lote',
      trade5Lots: 'Opere con 5 lotes',
      totalEarning: 'Ganancias totales',
      referralAct: 'Actividad de referido',
      referralName: 'Nombre del referido',
      registered: 'Registrado',
      accOpen: 'Cuenta abierta',
      madeDeposit: 'Depósito efectuado',
      redeemStatus: 'Estatus de canjeo',
      notApplicable: 'No aplicable',
      country: 'País',
      acc: 'CUENTA',
      share: 'COMPARTIR',
      copyLink: 'Copiar enlace',
      redeemYourbonus: 'Canjee su bono',
      tatolAmtConvert: 'La cantidad total convertida en su divisa base es {convertAmt} {currency}',
      remark: '*Al hacer clic en canjear ahora, acepta los términos y condiciones',
      redeemNow: 'Canjear ahora',
      overlimit: 'Por encima del límite',
      newUser: 'Nuevo usuario',
      tradeCash: 'Operar en efectivo',
    },
    cashback: {
      button: {
        applyForBonus: 'APLICAR A BONO',
        redeemCashback: 'CANJEAR REEMBOLSO',
        howItWorks: 'Como funciona?',
        depositNow: 'Deposita Ahora',
        stp: 'STP DIRECTO',
        ecn: 'PRIME ECN',
        agree: 'ACEPTAR',
        cancel: 'CANCELAR',
      },
      title: {
        monetaProgram: 'PROGRAMA DE REEMBOLSO MONETA MARKETS',
        claim: 'RECLAMA TU BONO DE 50% DE REEMBOLSO AHORA!',
        howItWorks: 'ASI ES COMO FUNCIONA',
        howMuch: 'CUANTO GANAS DE TUS OPERACIONES',
        treeSimpleSteps: 'RECLAMA TU BONO DEL 50%   -  EN 3 SENCILLOS PASOS',
        depositTradEarn: 'DEPOSITA. OPERA. GANA!',
        bonusSummary: 'Resumen de tu Bono',
        historicalEarnings: 'TUS GANANCIAS ACTUALES E HISTORICAS',
        program: 'PROGRAMA DE REEMBOLSO  ',
        cashSummary: 'RESUMEN DE REEMBOLSO',
        faqs: 'BONO DE REEMBOLSO FAQS',
        redeemCashback: 'CANJEA TU REEMBOLSO',
        cashbackBalance: 'BALANCE DE REEMBOLSO',
      },
      subTitle: {
        lowerYourCost: 'Disminuye tu costo. Gana efectivo',
        bonus: 'BONO',
        cashback: 'REEMBOLSO',
        wallet: 'BILLETERA',
        availableBonus: 'Bono disponible',
        totalBalance: 'Balance total',
        step1: 'Deposita fondos, y aplica!',
        step2: 'Empieza a operar!',
        step3: 'Reclama tu Reembolso',
        FX: 'FOREX',
        XAU: 'ORO',
        OIL: 'PETROLEO',
        perLot: 'por Lote',
        seetheBonusStatus:
          'Observa en la columna el ESTATUS DE TU BONO, para APLICAR a cualquier bono disponible',
        tradeFxOilAndGold: 'Opera FOREX, ORO Y PETROLEO para convertir tu BONO en REEMBOLSO!',
        totalCashback: 'Total de reembolso',
        FebruaryCashback: 'Reembolso pendiente',
        cashbackRedeemable: 'Reembolso canjeable',
        account: 'Cuenta',
        amount: 'Monto',
      },
      information: {
        availableBonus1:
          'La bonificación disponible es la cantidad de créditos de bonificación que ha ganado en todos los depósitos elegibles. Por cada depósito de $500 o más, recibirá el 50 % de esa cantidad como créditos de bonificación.',
        total:
          'El saldo total es la suma del reembolso canjeable y el monto del reembolso del mes actual.',
        availableBonus2:
          'Su bono disponible es el número de Créditos de Bono de todos los depósitos elegibles, que aún no han sido convertidos y transferidos a su Billetera de Reembolso',
        totalCashback:
          'El Reembolso total es el número de Créditos de bonificación que se han convertido en efectivo.',
        februaryCashback:
          'Los créditos de bonificación del mes en curso que se han convertido en efectivo. Puede transferirlos a su cuenta comercial a principios del próximo mes.',
        cashbackRedeemable:
          'El número de Créditos que se han convertido en efectivo hasta el final del último mes. Puede transferir esta cantidad a su cuenta comercial en cualquier momento.',
        selectTheTradingAccount:
          'Seleccione la cuenta comercial a la que le gustaría transferir su reembolso.',
        enterTheAmount: 'La cantidad (USD) que le gustaría transferir a su cuenta comercial.',
      },
      content: {
        claimContent1:
          'Ahora, cada vez que deposite fondos en su cuenta real con $500 o más, ¡le daremos un bono del 50 %, que aparece en la Cartera de bonos en la página de inicio de su Portal del cliente! Luego, cuando opere con FX, Oro o Petróleo, tus créditos de bonificación se convertirán en efectivo real, que se muestra en la Billetera de reembolso! ¡Al comienzo de cada mes, los fondos en su Cashback Wallet pueden transferirse a sus cuentas comerciales reales para usar como fondos reales!',
        claimContent2:
          'Lo mejor de todo es que por cada depósito futuro que haga de $500 o más, su bono se acreditará automáticamente en su Bonus Wallet, para que pueda seguir ganando más',
        howItWorksContent1:
          'Finalice su cuenta real con $ 500 o más, luego haga clic en "Aplicar" y acepte los términos y condiciones para recibir su bonificación del 50%',
        howItWorksContent2:
          '¡Cuando opere con Divisas, Oro o Petróleo, sus créditos de bonificación se convertirán automáticamente en efectivo real negociable, del que podrá realizar un seguimiento en su "Billetera de Reembolso"!',
        howItWorksContent3:
          'Al comienzo de cada nuevo mes, simplemente haga clic en "Canjear reembolso" en su "Billetera de reembolso" y canjee el monto que se muestra como "Reembolso canjeable".',
        stepsContent1: 'Deposite $500 o más y solicite su bono a continuación',
        stepsContent2:
          '¡Empieza a operar con Divisas, Oro y Petróleo para convertir tu bono en dinero real!',
        stepsContent3: '¡Reclame su reembolso de la actividad comercial de los meses anteriores!',
        depositTradEarnContent1: 'Cuanto más operas, más ganas.',
        depositTradEarnContent2:
          '¡Cuando haces un depósito de $500 o más, puedes reclamar un bono de reembolso del 50%!',
        optInContent1:
          'Para suscribirse a la promoción de reembolso de efectivo de Moneta Markets, debe aceptar los términos y condiciones a continuación.',
        electTheDestinationAccount: 'Seleccione la cuenta de destino a canjear',
      },
      column: {
        date: 'FECHA',
        deposit: 'DEPÓSITOS',
        withdraws: 'RETIROS',
        bonus: 'BONO CANJEABLE',
        status: 'ESTATUS DE BONO',
        account: 'CUENTA',
        redeem: 'CANJEAR REEMBOLSO',
        reason: 'NOTAS',
      },
      tnc: {
        title: 'Terminos y Condiciones',
        content: {
          item1: {
            title: 'Terminos y Condiciones de Reembolso',
            li1: 'Moneta Markets es un nombre comercial de Moneta Markets Ltd, registrado en el Registro de Empresas Comerciales Internacionales de Santa Lucía con el número de registro 2023-00068.',
            li2: 'Los "Términos y condiciones de la bonificación de reembolso de efectivo" son una parte integral de los "Términos y condiciones de Moneta Markets Ltd" y las disposiciones establecidas en este documento se consideran un anexo de todos los "Términos y condiciones de Moneta Markets Ltd" y deben leerse en conjunto.',
          },
          item2: {
            title: 'Condiciones de Elegibilidad.',
            li1: 'Sujeto a, y sin perjuicio de, todos los demás Términos y Condiciones, la oferta está disponible para los Clientes de Moneta Markets que hayan satisfecho los Criterios de Elegibilidad para la oferta ("Clientes Elegibles") como se establece en las siguientes secciones a continuación.',
            li2: 'Moneta Markets ofrece el "Bono de reembolso de efectivo" a los clientes que abren cuentas comerciales reales (es decir, no de demostración) con Moneta Markets.',
            li3: 'El "Bono de reembolso de efectivo" está disponible solo para cuentas Direct STP y Prime ECN. Las cuentas PAMM, MAM, Ultra ECN y "Cent" no son elegibles para participar en la promoción "Cashback Bonus".',
            li4: 'Moneta Markets, a su absoluta discreción, ofrece el "Bono de reembolso de efectivo" a cualquier Cliente de cualquier país/región, según lo considere apropiado y durante el tiempo que Moneta Markets considere proporcionarlo. La Compañía se reserva el derecho de retirar y/o cancelar cualquier elegibilidad en función de las restricciones y/o requisitos locales.',
            li5: 'Solo las personas que pueden celebrar contratos legalmente vinculantes según las leyes aplicables en su país de residencia pueden participar en el "Bono de reembolso de efectivo". Sin limitar lo anterior, la participación en el "Bono de reembolso de efectivo" no está permitida para personas menores de 18 años, ya que no son elegibles para abrir una cuenta con la Compañía',
            li6: 'Queda prohibida la participación de "Intermediarios/Partes Relacionadas" en el "Bono Cashback". Si los datos de registro y/o negociación de un participante en el "Bono Cashback" se corresponden con la información de registro y/o negociación, incluida, entre otras, la dirección IP, de otro participante en el "Bono Cashback", Moneta Markets se reserva el derecho considerar esta coincidencia como motivo de descalificación inmediata. A los efectos del presente, el término "Intermediario(s)/Parte(s) relacionada(s)", cuando se utilice en estos Términos y condiciones, a menos que el contexto requiera lo contrario, significará que incluye a cualquier persona o entidad que tenga una relación con cualquier participante en el "Bono de reembolso", que incluye, entre otros:',
            li6_sub_items: {
              li1: 'Los miembros de la familia, tales como hermanos, hermanas, cónyuges, ascendientes, descendientes directos y colaterales;',
              li2: 'Persona o entidad, a quien cualquier participante en el "Bono de Reembolso", directa o indirectamente a través de uno o más intermediarios, controla, o a quien, directa o indirectamente, a través de uno o más intermediarios, es controlado por, o está bajo control común con cualquier participante en el "Bono Cashback"; para los fines de esta definición, el término "control" (incluidos, con significado correlativo, los términos "controlado por" y "bajo control común con"), tal como se utiliza con respecto a cualquier participante en el "Bono de reembolso", significará el posesión, directa o indirectamente a través de uno o más intermediarios, del poder de dirigir o hacer que la dirección de las políticas de gestión de dicha persona o entidad, ya sea a través de la propiedad de valores con derecho a voto o de otra manera.',
              li3: 'Del mismo modo, las personas asociadas de cualquier manera con Moneta Markets y/o con los sitios web específicos y/o los sitios de redes sociales en los que Moneta Markets puede estar ejecutando de vez en cuando ciertas promociones, concursos y/o encuestas específicas, en el contexto de los cuales se ofrece acceso al "Cashback Bonus", no se les permite participar en la oferta.',
            },
          },
          item3: {
            title: 'Términos específicos de "Bono de reembolso"',
            li1: 'Los clientes primero deben depositar un mínimo de $ 500 y luego solicitar con éxito su bonificación de crédito del 50% en su portal de clientes primero para recibir créditos de bonificación. Los créditos de bonificación de depósitos posteriores se aplican automáticamente.',
            li1_sub_desc:
              'Solo los depósitos realizados durante el período de la promoción son válidos y serán considerados para la promoción. Esto excluye las transferencias internas, las bonificaciones por recomendar a un amigo y los ajustes de efectivo.',
            li2: 'El "Bono de reembolso de efectivo" está disponible solo para cuentas Direct STP y Prime ECN. Las cuentas Ultra ECN y "Cent" no son elegibles para participar en la promoción "Cashback Bonus".',
            li3: 'Los Clientes elegibles (tanto clientes nuevos como existentes), que cumplan con los criterios establecidos en este documento, pueden recibir Reembolso equivalente a:',
            li3_sub_content: {
              title1: 'Tabla de rebajas STP y ECN de cliente directo',
              info1: 'STP directo: $2 por lote operado en Divisas, Petróleo y Oro',
              info2: 'Prime ECN: $ 50c por FX, petróleo, lote de oro negociado.',
              title2: 'Cliente bajo tabla de reembolso IB STP y ECN',
              info3: 'STP directo: 0,50 USD por lote de divisas, petróleo y oro negociado',
              info4: 'Prime ECN: $0.25 por lote de FX, Petróleo y Oro negociado.',
            },
            li3_sub_desc:
              'El tamaño mínimo de lote comercial acumulado para el que se puede activar el reembolso es 0,01 lote estándar. Si el lote comercial válido del cliente en el día no se acumula a 0.1 lote estándar, se liquidará a cero. El número acumulado de lotes es un múltiplo de 0,01 lote estándar, como 0,02 lote estándar, 0,03 lote estándar (0,12 lote estándar se calculará como 0,12 lote estándar).',
            li4: 'Los productos elegibles: Todos los productos CFD de Forex, Oro y Petróleo',
            li5: 'El volumen comercial efectivo: Las órdenes recién abiertas y cerradas durante el período de promoción. Las posiciones abiertas por menos de 10 minutos, las posiciones bloqueadas y las posiciones cubiertas no cuentan como transacciones válidas para esta promoción.',
            li6: 'Esta oferta se puede utilizar junto con cualquier otra oferta.',
            li7: 'El retiro de fondos resultará en la eliminación de una cantidad igual en créditos de la Cartera de bonificación de un usuario. Si el monto del retiro es mayor que el monto del Crédito de bonificación, el monto del Crédito se reducirá a un saldo de cero.',
            li8: 'Moneta Markets no será responsable de ningún costo, gasto o daño que pueda sufrir como resultado de que Moneta Markets restablezca o ajuste la cuenta del cliente según lo contemplado en estos Términos y condiciones, incluidas, entre otras, las pérdidas atribuibles a las cuentas cerradas automáticamente. Fuera de posiciones abiertas.',
            li9: 'Moneta Markets se reserva el derecho, a su sola discreción:',
            li9_sub_items: {
              li1: 'rechazar el registro de cualquier participante en el "Bono Cashback"; y',
              li2: 'descalificar a cualquier participante en el "Bono de reembolso" que manipule o infrinja estos términos y/o cualquiera de los "Términos y condiciones de Moneta Markets" establecidos en el sitio web de Moneta Markets.',
            },
            li10: 'Moneta Markets se reserva el derecho, a su discreción razonable, de suspender la oferta de este "Bono de devolución de efectivo" a cualquiera de sus clientes, sin tener que explicar los motivos. Dichos clientes serán informados por correo electrónico.',
            li11: 'Moneta Markets se reserva el derecho de alterar, modificar o finalizar el "Bono de devolución de efectivo" o cualquier aspecto del mismo en cualquier momento sin tener que compensar a nadie por ello. Se dará aviso previo de dichos cambios a los clientes. Tenga en cuenta que participar en el "Bono de devolución de efectivo" constituye la aceptación y el acuerdo de cumplir con dichas alteraciones, enmiendas y/o cambios.',
            li12: 'Cualquier indicación o sospecha, a la sola discreción de Moneta Markets, de cualquier forma de arbitraje (incluido, entre otros, la obtención de beneficios sin riesgo), abuso (incluido, entre otros, los patrones de actividad comercial del participante que indican que el participante tiene como único objetivo beneficiarse financieramente del reembolso sin estar realmente interesado en operar en los mercados y/o asumir riesgos de mercado), fraude, manipulación, arbitraje de reembolso relacionado con una promoción de reembolso o cualquier otra forma de actividad engañosa o fraudulenta, anulará todo lo anterior. reembolso de las cuentas comerciales reales del Cliente Elegible con Moneta Markets y/o todas y cada una de las transacciones realizadas y/o las ganancias o pérdidas obtenidas en ellas. En estas circunstancias, Moneta Markets se reserva el derecho, a su exclusivo criterio, de cerrar/suspender (ya sea de manera temporal o permanente) todas las cuentas comerciales reales de dicho Cliente Elegible con Moneta Markets, cancelar todas las órdenes y anular todas las ganancias de dicho participante. En estas circunstancias, Moneta Markets no será responsable de las consecuencias de la cancelación del reembolso, incluido, entre otros, el cierre de la(s) orden(es) por parte de Stop Out.',
            li13: 'La participación en el "Bono de reembolso" implica la aceptación de estos Términos y condiciones y de los Términos y condiciones de Moneta Markets establecidos en el sitio web de Moneta Markets.',
            li14: 'El "Bono de reembolso" está disponible por un período limitado, como se indica en las reglas de participación de la promoción específica y/o la encuesta de la que se menciona que forma parte el "Bono de reembolso".',
            li15: 'Si estos términos y condiciones del "Cashback Bonus" se traducen a un idioma que no sea el inglés, la versión en inglés es superior en caso de una interpretación incompatible.',
            li16: 'El Bono de Reembolso no se aplica a clientes que residen en Turquía.',
            li17: 'Solo los depósitos de 500$ o más serán válidos para aplicar el Bono de Reembolso desde el 19 de julio de 2023.',
          },
          item4: {
            title: 'Advertencia de riesgo',
            li1: 'Operar con derivados y divisas conlleva un alto nivel de riesgo para su capital y solo debe negociarse con dinero que pueda permitirse perder. Asegúrese de leer los documentos en nuestra página de Documentos legales y busque asesoramiento independiente, para comprender completamente los riesgos, antes de decidir operar. Esta promoción se ha elaborado sin tener en cuenta sus objetivos, situación económica o necesidades.',
          },
        },
      },
      faqsList: {
        question_1: '¿Qué es el programa Cashback de Moneta Markets y cómo funciona?',
        question_2: '¿Cómo puedo suscribirme a la Promoción Cashback?',
        question_3: '¿Qué son los créditos de bonificación?',
        question_4: '¿Qué es el CashBack?',
        question_5: '¿Cómo verifico mis saldos de bonos y reembolsos?',
        question_6: '¿Pueden vencer mis créditos de bonificación?',
        question_7: '¿Puede expirar mi Saldo de Cashback?',
        question_8: '¿Cuales son los beneficios?',
        question_9: '¿Cuántos créditos de bonificación puedo ganar?',
        question_10: '¿Cuánto Cashback puedo ganar?',
        question_11: '¿Cuándo puedo retirar mi Bono de Reembolso?',
        question_12: '¿Cómo transfiero mi Cashback disponible a mi cuenta comercial?',
        question_13: '¿Con qué frecuencia se calcula mi Cashback?',
        question_14: '¿Puedo ganar Cashback en todos los productos?',
        question_15: '¿Cómo puedo retirar mi Bono Cashback?',
        question_16: '¿Afectarán los retiros mis créditos de bonificación?',
        question_17: '¿Afectarán los retiros mi bono de reembolso?',
        question_18: '¿Existe una duración mínima de operación para convertir mi Bono en Cashback?',
        question_19: '¿Cómo puedo aumentar mis créditos de bonificación?',
        question_20: '¿Cómo maximizo mi Reembolso mensual?',
        question_21: '¿Obtengo el mismo monto de Cashback en las cuentas Direct STP y Prime ECN?',
        question_22: '¿Obtengo Cashback en cuentas Ultra ECN o Cent?',
        question_23: '¿Qué son los estados de bonificación y qué significan?',
        question_24: '¿Cómo puedo optar por no participar en el Programa Cashback?',
        answer_1:
          'El programa Cashback de Moneta Markets es un programa de fidelización, mediante el cual los clientes depositan un mínimo de $500 para recibir un monto de crédito de bonificación del 50%. Estos créditos de bonificación luego se convierten en efectivo extraíble/intercambiable en función de la actividad comercial en productos de divisas, oro y petróleo.',
        answer_2:
          'Los clientes de \'Moneta Markets\' con una cuenta comercial elegible tendrán que "solicitar" su primer monto de Crédito de bonificación desde el Portal del cliente de Moneta Markets. Simplemente haga clic en "Solicitar bonificación" en la Cartera de bonificación en la página de inicio del Portal del cliente, haga clic en el botón "Aplicar" en la tabla titulada "Sus ganancias actuales e históricas", luego acepte los términos y condiciones. Los créditos de bonificación subsiguientes obtenidos en depósitos elegibles se aplicarán automáticamente.',
        answer_3:
          'Los créditos de bonificación se obtienen en depósitos elegibles de $500 o más, a una tasa del 50 % por depósito elegible. Los créditos de bonificación se guardan en su billetera de bonificación y se convierten en reembolsos reales en función de su actividad comercial en todos los productos de divisas, oro y petróleo.',
        answer_4:
          'Su Reembolso es el monto en efectivo obtenido en función de la conversión de Créditos de bonificación como resultado de su actividad comercial. El monto de su reembolso se puede transferir a su cuenta comercial y utilizar como efectivo real.',
        answer_5:
          'Puede ver sus saldos desde la pantalla de inicio de su Portal del cliente, donde se muestran su Bonus Wallet y Cashback Wallet. Para obtener un desglose de su Cashback Wallet, simplemente haga clic en "Promociones" - "Cashback" o "Canjear Cashback" en "Cashback Wallet" en la página de inicio de su Portal del cliente, donde puede ver su Cashback total, Cashback canjeable y el mes actual. Cantidades de reembolso.',
        answer_6:
          'No, los créditos de bonificación no caducan. Cuando opta por el bono de reembolso, todos los depósitos y retiros se calcularán a partir de los 30 días anteriores para calcular su bono disponible. Todos los créditos de bonificación subsiguientes ganados en depósitos futuros se aplican automáticamente y no vencen. Puede ver el estado de sus ganancias de bonificación actuales e históricas en la tabla titulada "Sus ganancias actuales e históricas", que mostrará "Aplicar", "Acreditado", "No elegible" o "Deducido".',
        answer_7:
          'Los montos de la bonificación de devolución de efectivo ganados no caducan y son suyos para transferirlos a su cuenta de operaciones para operar en vivo, o para retirarlos al comienzo de cada mes.',
        answer_8:
          'Valoramos tenerte como cliente y queremos agradecerte por operar con Moneta Markets. Como tal, recibirá una recompensa en forma de créditos de bonificación en todos los depósitos elegibles. Luego, a medida que continúe comerciando con nosotros, sus créditos se convertirán en efectivo real, como una forma de agradecer su lealtad continua.',
        answer_9:
          'Cuando deposite $500 o más, recibirá el 50% de esa cantidad en créditos de bonificación. Luego, por cada depósito elegible subsiguiente, continuará acumulando créditos de bonificación.',
        answer_10:
          'La cantidad de Cashback que puede ganar depende de su tipo de cuenta y de los productos con los que desea operar. Puede maximizar sus ganancias operando divisas, petróleo y oro en una cuenta STP directa de Moneta Markets.<br/><br/>Sus créditos de bonificación se convierten en efectivo a la siguiente tasa:<br/><br/>[ Direct STP y Prime ECN rebate table]<br/>Direct STP: $2 por FX, petróleo, lote de oro negociado<br/>Prime ECN: $0,50 por FX, petróleo, lote de oro negociado',
        answer_11:
          'Puede transferir el saldo que se muestra como su "reembolso disponible" a su cuenta de operaciones en cualquier momento. Mientras que el Cashback obtenido en el mes actual está disponible para transferirlo a su cuenta comercial a principios del próximo mes',
        answer_12:
          'Todo lo que necesita hacer es hacer clic en "Canjear reembolso" en su billetera de reembolso, seleccionar la cuenta comercial y el monto del reembolso que desea transferir y hacer clic en "Canjear reembolso" nuevamente.',
        answer_13:
          'El Cashback ganado por su actividad comercial en todos los productos FX, Gold y Oil se calcula a diario GMT+2 y aparecerá en su Cashback Wallet.',
        answer_14:
          'La bonificación de reembolso se puede ganar en todos los productos de divisas, oro y petróleo en las cuentas Direct STP y Prime ECN según la tabla a continuación.<br/><br/>[Tabla de reembolsos Direct STP y Prime ECN]<br/> STP directo: $0,50 por FX, petróleo, lote de oro negociado<br/>Prime ECN: $0,25 por FX, petróleo, lote de oro negociado',
        answer_15:
          'Retirar su bono Cashback disponible es simple. Todo lo que necesita hacer es hacer clic en "Canjear reembolso" en su billetera de reembolso, seleccionar la cuenta comercial y el monto del reembolso que desea transferir y hacer clic en "Canjear reembolso" nuevamente. Una vez que los fondos se transfieren a su cuenta comercial, puede usarlos para operar o enviar una solicitud de retiro para su procesamiento.',
        answer_16:
          'Retirar su bono Cashback disponible es simple. Todo lo que necesita hacer es hacer clic en "Canjear reembolso" en su billetera de reembolso, seleccionar la cuenta comercial y el monto del reembolso que desea transferir y hacer clic en "Canjear reembolso" nuevamente. Una vez que los fondos se transfieren a su cuenta comercial, puede usarlos para operar o enviar una solicitud de retiro para su procesamiento.',
        answer_17:
          'No. Su bono de reembolso es suyo y no se verá afectado por los retiros que realice.',
        answer_18:
          'Cualquier operación de divisas, oro o petróleo debe mantenerse durante un mínimo de 10 minutos para que su bono se convierta en reembolso.',
        answer_19:
          'Sus créditos de bonificación se acumulan cada vez que realiza un depósito elegible. Por cada depósito de $500 o más, recibirá el 50 % de esa cantidad en créditos de bonificación.',
        answer_20:
          'Para maximizar la cantidad de Cashback que puede ganar depende de dos factores; su tipo de cuenta y su actividad comercial en productos FX, Gold y Oil, con cuentas Direct STP que se convierten en Cashback a una tasa más alta que las cuentas Prime ECN. Simplemente asegúrese de tener una cantidad suficiente de créditos en su billetera de bonificación y opere divisas, oro y petróleo en una cuenta Direct STP para maximizar su reembolso. Y, cuantas más transacciones realice y mayor sea el volumen que opere, más rápido se convertirán sus créditos de bonificación en devolución de efectivo.',
        answer_21:
          'El monto de reembolso ganado en FX, oro y petróleo difiere entre los tipos de cuenta.<br/><br/>[Tabla de reembolsos de STP directo y ECN principal]<br/>STP directo: $0.50 por lote negociado en FX, petróleo y oro<br/>Prime ECN: $0.25 por lote FX, Petróleo, Oro negociado',
        answer_22:
          'Desafortunadamente, las cuentas Ultra ECN o Cent no acumulan ningún reembolso por operar con divisas, oro o petróleo. Debe operar con cuentas Direct STP o Prime ECN.',
        answer_23:
          'Aplicar: La primera vez que realiza un depósito elegible, debe hacer clic en "Aplicar" para que se acredite su bono. Todos los depósitos subsiguientes se acreditarán automáticamente.<br/>Acreditado: el bono se ha acreditado y aparecerá en su billetera de bonos<br/>No elegible: el bono no cumplió con el requisito mínimo de $500<br/>Deducido: el bono se ha deducido como resultado de la retirada de fondos de su cuenta de operaciones.',
        answer_24:
          'Si decide que ya no desea ser parte del programa, envíe su solicitud de exclusión voluntaria a <a href="mailto:{email}">{email}</a>',
      },
      reason: {
        1: 'Se ha agregado una bonificación del 50 % a su Monedero de bonificación',
        2: 'Ajuste de bonificación manual: acreditado',
        3: 'El monto del retiro se ha eliminado de su billetera de bonificación',
        4: 'Esta cantidad de bonificación se ha convertido en Cashback',
        5: 'Ajuste de bonificación manual - Deducido',
        6: 'Este depósito no alcanzó el monto mínimo de depósito para calificar',
      },
      status: { 0: 'Aplicar', 1: 'Acreditado', 2: 'Deducido', 3: 'Inelegible' },
      zeroMsg: 'no le queda ningún reembolso para canjear',
    },
  },
  hmcTraderPortal: {
    overview: 'Resumen de la plataforma',
    interactiveAnalysis: 'Análisis Interactivo',
    learnFromTheBest: 'Aprender de los mejores',
    requestAResearch: 'Solicitar una investigación',
    vipSupport: 'Asistencia VIP',
    eligibility: 'Elegibilidad',
    login: 'Inicie sesión en el HMC Sala de trading en vivo',
    depositRequirement:
      'Cumplir con el requisito de depósito para obtener acceso al HMC Sala de trading en vivo',
    basic: 'Básico',
    vip: 'VIP',
    exclusiveAnalysis: 'Análisis Exclusivo',
    analysisDiscussions: 'Discusiones sobre el Análisis',
    tradingSignals: 'Señales de Trading',
    tradingSignalRequest: 'Solicitud de Señal de trading',
    privateAnalysisRequest: 'Solicitud de Análisis Privado',
    prioritySupport: 'Asistencia Prioritaria',
    minimumFundingRequirement:
      'Requisito de Financiación Mínima y operar $200,000 de valor nocional sobre operaciones cerradas cada mes* ',
    tnc1: 'Tenga en cuenta que la información presentada en <a href="{url}" target="_blank">portal.hmctrader.com</a> es proporcionada a Moneta ("Moneta Global Limited", "nosotros") por un proveedor externo ("HMC Trader"). Está destinado exclusivamente a fines de investigación e información y no constituye ningún tipo de asesoramiento financiero para comprar, vender o mantener un producto concreto o seguir una estrategia de inversión determinada. La información no está adaptada a las necesidades de inversión de ninguna persona específica y, por lo tanto, no implica una consideración de los objetivos de inversión, la situación financiera o las necesidades de ningún espectador que pueda recibirla. Tenga en cuenta también que la rentabilidad pasada no es un indicador fiable de la rentabilidad y/o los resultados futuros. Los resultados reales pueden diferir materialmente de los previstos en las declaraciones de futuro o de resultados pasados. No asumimos ninguna responsabilidad en cuanto a la exactitud o integridad de la información proporcionada en este documento, ni a las pérdidas que puedan derivarse de cualquier información suministrada por HMC Trader.',
    tnc2: '*Para ser elegible para usar el servicio del HMC Sala de trading en vivo, se necesita cumplir el requisito de financiación mínima inicial indicado arriba desde la fecha de lanzamiento. Hay que completar al menos $200,000 de valor nocional sobre operaciones cerradas de productos ofrecidos por Moneta cada mes para mantener el permiso de acceso al HMC Sala de trading en vivo. El permiso de acceso se desactivará automáticamente si no se completa al menos $200,000 de valor nocional sobre operaciones cerradas en un mes. Hay que depositar además la cantidad de fondos requerida para reactivar el acceso al HMC Sala de trading en vivo. ',
  },
  supportTickets: {
    support: 'Ayuda',
    mySupportTickets: 'Mis tickets de ayuda',
    createNewTicket: 'Crear ticket nuevo',
    ticketNo: 'Nº de ticket',
    subject: 'Asunto',
    status: 'Estado',
    lastUpdated: 'Última actualización',
    newTicketRequest: 'Crear nuevo ticket',
    content: 'Contenido',
    attachments: 'Adjuntos',
    upload: 'Subir',
    viewingTicket: 'Viendo ticket nº',
    postReply: 'Publicar respuesta',
    update: 'Actualizar',
    cancel: 'Cancelar',
    close: 'Cerrar',
    number: 'Nº',
    description: 'Descripción',
    postReplyRequired: 'El campo "Publicar una respuesta" es obligatorio',
    contentFieldRequired: 'El campo "Contenido" es obligatorio',
    subjectFieldRequired: 'El campo "Asunto" es obligatorio',
    successMsg: '¡Se ha creado con éxito su ticket de ayuda!',
  },
  responseMsg: {
    410: 'Error de validación del parámetro',
    411: 'La contraseña no es correcta',
    420: 'El usuario no existe',
    421: 'Restablecimiento de contraseña rechazado',
    490: 'No envíe información de tarjetas duplicadas',
    500: 'Ha ocurrido un error.',
    501: 'Error en su solicitud Por favor, inténtelo de nuevo más tarde.',
    505: 'Error al enviar el archivo',
    510: 'El código SMS no es correcto',
    511: 'No se puede enviar el código SMS a su teléfono',
    520: 'Error al iniciar sesión. Por favor, vuelva a intentarlo.',
    521: '¡Un usuario ya ha iniciado sesión en este dispositivo!',
    522: 'Por favor, inicie sesión de nuevo',
    523: 'Por favor, inicie sesión de nuevo',
    524: 'Desafortunadamente, su dirección de correo electrónico no fue reconocida',
    525: 'El nombre de usuario y la contraseña no son correctos',
    526: 'El recaptcha no coincide',
    527: 'El usuario no es un IB',
    528: 'El usuario no existe',
    529: 'El nombre de usuario y la contraseña no son correctos',
    530: 'Sesión de inicio de sesión no válida',
    531: 'Código no válido',
    540: 'No se pudo encontrar su cuenta de trading',
    541: 'No se pudo encontrar la cuenta de reembolso',
    542: 'No se le permite abrir cuentas adicionales',
    544: 'No puede solicitar una cuenta adicional hasta que se verifique su prueba de identidad',
    550: 'El número de cuenta no es correcto',
    551: 'No tiene suficiente saldo',
    554: 'La cantidad solicitada es cero o nula',
    562: 'No podemos procesar su pago, por favor, inténtelo de nuevo. Si el problema continúa, por favor, póngase en contacto con nosotros mediante chat en vivo o email {email}',
    564: 'El país no coincide con el medio de pago',
    565: 'El código bancario no es correcto',
    566: 'Método de pago no encontrado',
    567: 'Desafortunadamente este método de pago no está disponible ahora. Nos disculpamos por cualquier inconveniente causado',
    568: 'Se ha deshabilitado esta cuenta para realizar depósitos, por favor, contacte con {email}',
    569: 'Su nombre/Su apellido/Su número de teléfono es obligatorio. Si necesita ayuda, por favor, póngase en contacto con {depositEmail}',
    581: 'Elegiste con éxito el ascenso. Por favor, tenga en cuenta que debe hacer un depósito para poder retirar los fondos.',
    584: 'No tiene ninguna comisíon aplicable',
    587: 'Cantidad de retiro no válida.',
    590: 'No se pudo encontrar la cuenta de su transferencia saliente',
    591: 'No se pudo encontrar la cuenta de su transferencia entrante',
    593: 'No podemos procesar la transferencia de sus fondos desde la cuenta seleccionada porque su cuenta contiene crédito.',
    594: 'No se permiten transferencias de fondos desde esta cuenta',
    624: 'No se puede eliminar el saldo negativo en esta cuenta. Para más información, contacte a support@monetamarkets.com',
    625: 'Hemos recibido su solicitud y la estamos procesando en estos momentos. Si tiene dudas o preguntas, escriba a support@monetamarkets.com',
    626: 'Nos hemos dado cuenta de que su saldo es superior a cero. Refresque la página y vuelva a probar.',
    647: 'El código de verificación es incorrecto',
    1001: 'Su cuenta no está disponible temporalmente. Por favor, póngase en contacto con su gestor de cuen',
    1102: 'No se puede habilitar, la cuenta se creó como una cuenta real o de demostración.',
    1103: 'La cuenta ha sido deshabilitada',
    1202: 'Verificación caducada, por favor inténtelo de nuevo',
    1204: 'Por favor, intente con otra dirección de correo electrónico',
    1208: 'No se pueden enviar códigos de verificación repetidamente',
    1209: 'Error al enviar el código de verificación',
    session_timeout: 'La sesión há expirado, redirigiendo a la página de inicio de sesión.',
    vload: {
      4019: 'Valor o tipo de cupón no aceptado',
      4071: 'Sospecha de fraude',
      4072: 'Se ha utilizado el cupón',
      4073: 'El cupón no está activo',
      4074: 'El cupón ha caducado',
      4075: 'No se ha encontrado el cupón',
      4080: 'El cupón no tiene suficiente valor',
      4444: 'Cupón no valido utilizado. Incompatibilidad de divisa',
      5000: 'La información de la tienda no está disponible',
    },
  },
  lock: {
    premium: {
      trading: {
        tools: {
          description: 'Para desbloquear características premium, complete ahora su solicitud',
        },
      },
    },
  },
};
