import { apiGetData } from '@/resource/register'
import { countryCodeEnum } from '@/constants/country'

export default {
  data() {
    return {
      form: {
        tradingPlatform: null,
        accountType: null,
        currency: null,
        step: 4,
      },
      activeType: null,
      accountType: '',
      activeCurrency: null,
      accountCurrencyList: [],
      rules: {
        tradingPlatform: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.platErr'),
          },
        ],
        accountType: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.accTypeErr'),
          },
        ],
        currency: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.currError'),
          },
        ],
        checkStatus: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
      },
      checkStatus: null,
    }
  },
  mounted() {
    this.getUserDate()
    this.getAccountCurrencyList()
  },
  methods: {
    getUserDate() {
      apiGetData(4).then(resp => {
        if (resp.data.code == 0) {
          this.form = { ...resp.data.data }
          if (this.form.tradingPlatform && this.form.accountType && this.form.currency) {
            this.checkStatus = true
          }
        }
      })
    },
    getAccountCurrencyList(value) {
      const filterLiveType = this.$platform.openLiveAccount.getFilterLiveType(this.regulator)
      const filterMenaLiveType = this.$platform.openLiveAccount.getFilterMenaLiveType(this.regulator)
      const filterCurrencyType = this.$platform.openLiveAccount.getFilterCurrencyType(this.regulator)
      const currencyList = this.$platform.openLiveAccount.chooseCurrency(this.regulator)

      // filter account currency type with USD only for swap free account type
      if (filterLiveType.includes(value) || filterMenaLiveType.includes(value)) {
        this.accountCurrencyList = currencyList.filter(i => {
          return filterCurrencyType.includes(i.currency)
        })
      } else {
        this.accountCurrencyList = currencyList
      }
    },
    platformClick(item) {
      this.form.tradingPlatform = item.value
      this.form.accountType = null
    },
    typeClick(index, item) {
      if (!this.disableField) {
        this.activeType = index
        this.accountType = item.value
        this.form.accountType = item.value
        this.getAccountCurrencyList(item.value)
      }
    },
    currencyClick(index, item) {
      if (!this.disableField) {
        this.activeCurrency = index
        this.currency = item.currency
        this.form.currency = item.currency
      }
    },
  },
  computed: {
    disableField() {
      return this.$store.state.register.disableField
    },
    accountTypeList() {
      const swapFreeCountries = this.$platform.openLiveAccount.getSwapFreeCountries(this.regulator)
      const swapFreeMenaCountries = this.$platform.openLiveAccount.getSwapFreeMenaCountries(this.regulator)
      const filterLiveType = this.$platform.openLiveAccount.getFilterLiveType(this.regulator)
      const filterMenaLiveType = this.$platform.openLiveAccount.getFilterMenaLiveType(this.regulator)
      const liveTypeMt4 = this.$platform.openLiveAccount.mt4RegisterChooseType(this.regulator)
      const liveTypeMt5 = this.$platform.openLiveAccount.mt5RegisterChooseType(this.regulator)
      let liveType = this.form.tradingPlatform === 'mt5' ? liveTypeMt5 : liveTypeMt4

      // for ib accounts handling only
      let directStpCountries = this.$platform.openLiveAccount.getDirectStpCountries(this.regulator)
      let swapFreeStpCountries = this.$platform.openLiveAccount.getSwapFreeStpCountries(this.regulator)

      if (this.ibClient) {
        // direct stp countries only
        if (directStpCountries.includes(parseInt(this.countryCode))) {
          // swap free stp countries only
          if (swapFreeStpCountries.includes(parseInt(this.countryCode))) {
            // enable ecn for indonesia
            if (this.countryCode == countryCodeEnum.INDONESIA) {
              return liveType.filter(item =>
                ['standardSTP', 'swapFreeStandardSTP', 'rawECN', 'swapFreeRawECN'].includes(item.value)
              )
            }
            return liveType.filter(item => ['standardSTP', 'swapFreeStandardSTP'].includes(item.value))
          }

          if (this.countryCode == countryCodeEnum.SOUTH_KOREA) {
            return liveType.filter(item => ['standardSTP', 'rawECN'].includes(item.value))
          }

          return liveType.filter(item => item.value == 'standardSTP')
        }

        // swap free mena countries only
        if (swapFreeMenaCountries.includes(parseInt(this.countryCode))) {
          return liveType.filter(
            item => filterMenaLiveType.includes(item.value) || ['standardSTP', 'rawECN'].includes(item.value)
          )
        }

        // other countries
        return liveType.filter(item => ['standardSTP', 'rawECN'].includes(item.value))
      } else {
        // hide PAMM account type
        liveType = liveType.filter(item => item.value !== 'PAMM')

        // hide 10 day swap free buttons if not mena countries
        if (!swapFreeMenaCountries.includes(parseInt(this.countryCode))) {
          liveType = liveType.filter(i => !filterMenaLiveType.includes(i.value))
        }

        // hide swap free buttons if not swap free countries
        if (!swapFreeCountries.includes(parseInt(this.countryCode))) {
          return liveType.filter(i => !filterLiveType.includes(i.value))
        }
      }

      return liveType
    },
    swapFreeFlag() {
      return this.form.accountType === 'swapFreeStandardSTP' || this.form.accountType === 'swapFreeRawECN'
        ? true
        : false
    },
    countryCode() {
      return this.$store.state.common.countryCode
    },
    ibClient() {
      return this.$store.state.common.ibClient
    },
  },
}
