<template>
  <el-table-column min-width="120">
    <template slot="header"> PROTRADER </template>
    <template>
      <el-button
        :class="{ gray: showIdentityProofAlert }"
        type="danger"
        @click="goToTradingView"
        data-testid="launch"
        :disabled="isHiddenTable"
      >
        {{ $t('tradingView.launch') }}
      </el-button>
    </template>
  </el-table-column>
</template>

<script>
import protraderPlatformMixins from '@/mixins/proTools/protraderPlatform';
import idPoaMixin from '@/mixins/idPoa';

export default {
  name: 'TradingViewColumnDefault',
  mixins: [protraderPlatformMixins, idPoaMixin],
  props: { mtServer: Number, isHiddenTable: Boolean }
};
</script>

<style lang="scss" scoped>
.gray {
  background-color: #777777;
  border-color: #777777;
}
</style>
