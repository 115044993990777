<template>
  <el-form :model="form" :rules="rules" ref="ruleForm">
    <ul>
      <li>
        <SelectForm
          v-model="form.countryId"
          name="countryCode"
          :label="$t('register.personalDetails.page2.mainResidency')"
          :lists="countries"
          valueName="id"
          labelName="countryName"
          auto-complete="off"
          data-testid="countryName"
        ></SelectForm>
      </li>
    </ul>
    <ul>
      <li v-if="showDiffField" class="streetNumber">
        <InputTemplate
          v-model="form.streetNumber"
          :disabled="disableField"
          name="streetNumber"
          :label="$t('register.personalDetails.page2.streetNumber')"
          maxlength="20"
          data-testid="streetNumber"
        >
        </InputTemplate>
        <InputTemplate
          v-model="form.address"
          :disabled="disableField"
          name="address"
          :label="$t('register.personalDetails.page2.streetName')"
          maxlength="256"
          data-testid="streetName"
        >
        </InputTemplate>
      </li>
      <li v-else>
        <InputTemplate
          v-model="form.address"
          :disabled="disableField"
          name="address"
          :label="$t('register.personalDetails.page2.address')"
          maxlength="256"
          data-testid="address"
        >
        </InputTemplate>
      </li>
      <li>
        <SelectForm
          v-if="form.countryId == 3512"
          v-model="form.state"
          name="state"
          :label="$t('register.personalDetails.page2.provinceOrState')"
          :lists="provinces"
          valueName="provinceNameEn"
          labelName="provinceName"
          auto-complete="off"
          data-testid="provinceNameEn"
        ></SelectForm>
        <InputTemplate
          v-else
          v-model="form.state"
          :disabled="disableField"
          name="state"
          :label="$t('register.personalDetails.page2.provinceOrState')"
          maxlength="128"
          data-testid="provinceOrState"
        >
        </InputTemplate>
      </li>
    </ul>
    <ul>
      <li>
        <InputTemplate
          v-model="form.suburb"
          :disabled="disableField"
          name="suburb"
          :label="$t('register.personalDetails.page2.cityOrSuburb')"
          maxlength="128"
          data-testid="cityOrSuburb"
        >
        </InputTemplate>
      </li>
      <li>
        <InputTemplate
          v-model="form.postcode"
          :disabled="disableField"
          name="postcode"
          :label="$t('register.personalDetails.page2.postcode')"
          maxlength="64"
          data-testid="postcode"
        >
        </InputTemplate>
      </li>
    </ul>
    <ul>
      <li class="usQuestion">
        <el-form-item :label="$t('register.personalDetails.page2.usQuestion')" prop="usCitizen">
          <el-radio :disabled="disableField" v-model="form.usCitizen" :label="true" data-testid="yes">
            {{ this.$t('common.field.yes') }}
          </el-radio>
          <el-radio :disabled="disableField" v-model="form.usCitizen" :label="false" data-testid="no">
            {{ this.$t('common.field.no') }}
          </el-radio>
        </el-form-item>
      </li>
    </ul>
  </el-form>
</template>

<script>
import SelectForm from '@/components/register/form/select';
import userAddress from '@/mixins/register/userAddress';

export default {
  props: {
    countries: Array
  },
  mixins: [userAddress],
  components: { SelectForm }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/personalDetails.scss';
</style>
