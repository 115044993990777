// 進行異步執行
import { apiRestrictedDeposit } from '@/resource'

export default {
  async actionRestrictDeposit({ commit, state }) {
    if (state.restrictedDeposit.length > 0) return state.restrictedDeposit
    return new Promise((resolve, reject) => {
      apiRestrictedDeposit().then(result => {
        let data = result.data.length <= 0 ? 0 : result.data
        commit('setRestrictedDeposit', data)
        resolve(data)
      })
    })
  },
}
