<template>
  <InternationalVFSCBankInfo :isShow="isShow" :currency="currency" :country="country"></InternationalVFSCBankInfo>
</template>

<script>
import InternationalVFSCBankInfo from '@/components/template/deposit/InternationalVFSCBankInfo';

export default {
  name: 'InternationalBankInfo-Vfsc',
  props: { isShow: Boolean, currency: String, country: String },
  components: { InternationalVFSCBankInfo }
};
</script>
