<template>
  <el-card id="videoTemplate">
    <span>Documentation Requirement Videos</span>
    <div class="line"></div>
    <div class="video">
      <img src="@/assets/images/register/video.png" v-show="showIdImg" @click="onIdPlayerClick" alt />
      <div id="id-player"></div>
    </div>
    <div class="title">{{ $t('register.confirmYourId.poidTitle') }}</div>
    <div class="line"></div>
    <div class="video">
      <img src="@/assets/images/register/video.png" v-if="showPoaImg" @click="onPoaPlayerClick" alt />
      <div id="poa-player"></div>
    </div>
    <div class="title">{{ $t('register.confirmYourId.porTitle') }}</div>
  </el-card>
</template>

<script>
export default {
  data() {
    return {
      showIdImg: true,
      showPoaImg: true
    };
  },
  name: 'videoTemplate',
  inject: ['onIdPlayerReady', 'onPoaPlayerReady'],
  methods: {
    onIdPlayerClick() {
      this.showIdImg = false;
      this.onIdPlayerReady();
    },
    onPoaPlayerClick() {
      this.showPoaImg = false;
      this.onPoaPlayerReady();
    }
  }
};
</script>

<style lang="scss" scoped>
#videoTemplate {
  border-radius: 8px;
  text-align: center;

  span {
    font-size: 15px;
    text-transform: uppercase;
    line-height: 1.2;
    text-align: center;
  }

  .title {
    font-size: 14px;
    font-family: 'Century Gothic';
    font-weight: bold;
    line-height: 1.731;
    text-align: center;
  }

  .line {
    width: 80%;
    height: 1px;
    margin: 15px 0;
    margin-left: calc((100% - 80%) / 2);
    background-color: $border-color;
  }

  .video {
    position: relative;

    img {
      display: block;
      width: 100%;
      height: auto;
      cursor: pointer;
    }

    /deep/ iframe {
      width: 100% !important;
      height: 100% !important;
    }
  }
}

@media (max-width: 1199px) {
  #videoTemplate .video {
    display: none;
  }
}
</style>
