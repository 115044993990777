export default {
  common: {
    liveChat: { desc: '<li><p>Butuh Bantuan?</p><span>Berbicara langsung</span></li>' },
    country: { international: 'Internasional', australia: 'Australia', uk: 'UK' },
    verification: { toSlide: 'Geser untuk melengkapi teka-teki' },
    hover: {
      BankName: 'Nama dari bank atau institusi finansial yang akan menerima dana',
      BankBeneficiaryName:
        'Nama dari orang atau perusahaan pemilik akun bank yang akan menerima dana',
      AccountHoldersAddress: 'Alamat dari orang atau perusahaan pemilik akun bank.',
      BankAddress: 'Alamat dari bank atau institusi finansial yang akan menerima dana.',
      BankAccountNumber:
        'Nomor Akun Bank atau IBAN (International Bank Account Number) adalah nomor yang digunakan untuk mengidentifikasi akun bank anda',
      SwiftCode:
        'Kode SWIFT, juga dikenal sebagai Bank Identifier Code (BIC), adalah kode internasional yang digunakan untuk mengidentifikasi negara, bank dan kantor cabang, dan diperlukan ketika melakukan transfer uang antar negara.',
    },
    field: {
      nameOnCard: 'Nama pada kartu',
      first4Digits: '4 digit pertama pada kartu',
      last3Digits: '3 digit pertama pada kartu',
      first6Digits: '6 digit pertama pada kartu',
      last4Digits: '4 digit terakhir pada kartu',
      bankName: 'Nama Bank',
      bankAddress: 'Alamat Bank',
      branchAddress: 'Alamat Kantor Cabang',
      bankBranch: 'Kantor Cabang Bank',
      bankCity: 'Kota Bank',
      bankProvince: 'Provinsi Bank',
      bankCode: 'Kode Bank',
      bankBranchCode: 'Kode cabang',
      bankBeneficiaryName: 'Nama Pemilik Rekening Bank',
      bankBeneAccName: 'Nama Akun Bank Penerima',
      bankBeneAccNum: 'Nomor Akun Bank Penerima',
      bankBeneAddress: 'Alamat Penerima',
      bankAccNum: 'Nomor Akun Bank',
      bankAccName: 'Nama Akun Bank  ',
      bankHolderAddress: 'Alamat Pemegang Akun',
      address: 'Alamat',
      accName: 'Nama Akun',
      accNum: 'Nomor Akun',
      accNumTo: 'Nomor Akun',
      bsbNum: 'Nomor BSB',
      swift: 'Kode SWIFT ',
      region: 'Wilayah',
      sort: 'Kode urut',
      ifscCode: 'Kode IFSC',
      swiftNonAUD: 'Kode SWIFT (Akun Non AUD)',
      amt: 'Jumlah',
      amtUSD: 'Jumlah (USD)',
      imptNotes: 'Catatan penting',
      yes: 'Ya',
      no: 'Tidak',
      payMethod: 'Metode Pembayaran:',
      expDate: 'Tanggal kedaluwarsa',
      bsb: 'BSB',
      abaSortCode: 'ABA/Kode Urut:',
      country_region: 'Negara/Wilayah',
      currLeverage: 'Leverage saat ini',
      accEquity: 'Jumlah Ekuitas',
      newLeverage: 'Leverage yang diinginkan',
      currPw: 'Password saat ini',
      newPw: 'Password baru',
      confirmPw: 'Konfirmasi Password Baru',
      loginEmail: 'Masuk dengan Email',
      email: 'Email',
      pw: 'Password',
      accType: 'Tipe Akun',
      name: 'Nama',
      emailAdd: 'Alamat Email',
      phone: 'Nomor Telepon',
      countryCode: 'Kode Negara',
      dob: 'Tanggal Lahir',
      nat: 'Kewarganegaraan',
      country: 'Negara tempat tinggal',
      street: 'Nomor Rumah/Nama Jalan',
      suburbOrCity: 'Kabupaten/Kota',
      provinceOrState: 'Provinsi',
      postcode: 'Kode Pos',
      empStat: 'Status Pekerjaan',
      occupation: 'Pekerjaan',
      annIncome: 'Pendapatan Tahunan',
      save: 'Tabungan/Investasi',
      source: 'Sumber Pendapatan',
      industry: 'Industri',
      statusDef: 'DEFINISI STATUS',
      month: 'Bulan',
      year: 'Tahun',
      select: 'Pilih',
      completed: 'Lengkap',
      incomplete: 'Tidak Lengkap',
      submitted: 'Dikirim',
      successful: 'Berhasil',
      processing: 'Sedang Diproses',
      cancelled: 'Dibatalkan',
      failed: 'Gagal',
      rejected: 'Ditolak',
      upload: 'Unggah',
      continue: 'Lanjutkan',
      ccNum: 'Nomor Kartu Kredit',
      bankCardNumber: 'Nomor Kartu Bank',
      cardHolderName: 'Nama Pemegang Kartu',
      reservedMobileNumber: 'Nomor Telepon Seluler untuk Bank',
      nationalId: 'Kartu Tanda Penduduk',
      cardPhoto: 'Foto Kartu (Depan)',
      notes: 'Catatan',
      bankAccOrIBAN: 'Nomor Akun Bank/IBAN',
      selectWithdrawCard: 'Pilih Kartu Penarikan Anda',
      updateCardPhoto: 'Silahkan unggah foro kartu anda (Depan)',
      transitNumber: 'Nomor Transit',
      institutionNumber: 'Nomor Institusi',
      wallet: 'Wallet',
      resetBalTo: 'Atur Ulang Saldo ke',
      confirmation: 'Apakah Anda yakin?',
      removeNote:
        'Anda benar-benar ingin menghapus catatan ini? Proses ini tidak dapat dibatalkan.',
      depositCurrency: 'Mata Uang Setoran',
      bankAccountCurrency: 'Mata Uang Akun Bank',
      ipChangeWarning:
        'Dapatkan email peringatan ketika ada yang masuk ke akun Anda dari lokasi yang berbeda',
      documentType: 'Tipe dokumen',
      documentDetails: 'Detail Dokumen',
      pixKeyType: 'Jenis Kunci PIX',
      pixKey: 'Kunci PIX',
      enterVerificationCode: 'Masukkan Kode Verifikasi',
      accDigit: 'Digit Akun',
      docID: 'ID Dokumen',
      paymentAccName: '{name} Nama Akun',
      paymentAccNum: '{name} Nomor Akun',
      beneficiaryIBANNumber: 'Nomor IBAN Penerima Manfaat',
      beneficiaryBankSwift: 'Kode SWIFT Bank Penerima Manfaat',
      beneficiaryBankName: 'Nama Bank Penerima Manfaat',
      beneficiaryBankAddress: 'Alamat Bank Penerima Manfaat',
      beneficiaryBankSortCode: 'Kode Cabang Bank Penerima Manfaat',
      beneAccNum: 'Nomor Rekening Penerima Manfaat',
      reversedfailed: 'Gagal Terbalik',
      cardNumber: 'Nomor kartu',
      walletNumber: 'Nomor dompet {name}',
      beneficiaryName: 'Nama Penerima',
    },
    column: {
      date: 'TANGGAL',
      type: 'TIPE  ',
      server: 'SERVER',
      status: 'STATUS',
      comment: 'KOMENTAR',
      accNum: 'NOMOR AKUN',
      currency: 'MATA UANG',
      equity: 'EKUITAS',
      credits: 'KREDIT',
      balance: 'KESEIMBANGAN',
      leverage: 'LEVERAGE',
      resetMT4PW: 'SETEL ULANG PASSWORD MT4',
      resetMT5PW: 'SETEL ULANG PASSWORD MT5',
      tradingAcc: 'AKUN TRADING',
      method: 'METODE',
      amt: 'JUMLAH',
      procNote: 'NOTA PROSES',
      number: 'NOMOR KARTU',
      resetBal: 'Atur Ulang Saldo',
      remove: 'hapus',
    },
    key: { from: 'DARI', to: 'KE' },
    fund: {
      must: 'HARUS',
      infoHover: 'Nama pada kartu anda {must} sama dengan nama pada trading akun anda',
      ccUploadReminder:
        'Mohon unggah foto tampak depan dari kartu anda untuk verifikasi. (Silahkan tutup 6 digit angka tengah)',
    },
    button: {
      bkToHm: 'Kembali ke Beranda',
      smsLogin: 'LOGIN DENGAN TELEPON SELULER',
      userLogin: 'LOGIN DENGAN EMAIL',
      goToIB: 'Ganti ke Portal IB',
      logout: 'KELUAR',
      submit: 'KIRIM',
      selectFile: 'Pilih File',
      depositFunds: 'SETOR DANA',
      withdrawFunds: 'TARIK DANA',
      ibPortal: 'PORTAL IB',
      download: 'UNDUH',
      changeLeverage: 'GANTI LEVERAGE',
      cancel: 'BATAL',
      changePw: 'GANTI PASSWORD',
      forgetPw: 'LUPA PASSWORD',
      confirm: 'KONFIRMASI',
      login: 'LOGIN',
      forgotPw: 'Lupa Password?',
      completeQuestionnaire: 'LENGKAPI PERTANYAAN',
      identityProof: 'LENGKAPI PERMOHONAN',
      upload: 'UNGGAH',
      enable: 'AKTIFKAN',
      disable: 'NONAKTIFKAN',
      export: 'EKSPOR',
      ok: 'OK',
      hide: 'TUTUPI',
      unhide: 'TAMPILKAN',
      showAll: 'TAMPILKAN SEMUA',
      beginner: 'Pemula',
      intermediate: 'Menengah',
      advanced: 'Lanjut',
      sendCode: 'Kirim Kode',
      resendCode: 'Kirim Ulang Kode',
    },
    formValidation: {
      common: 'Kolom ini wajib diisi.',
      accReq: 'Nomor Akun diperlukan',
      amtReq: 'Jumlah diperlukan',
      ccReq: 'Nomor Kartu Kredit diperlukan',
      cardReq: 'Nomor Kartu diperlukan',
      cardOnNameReq: 'Nama pada kartu diperlukan',
      digits16: 'Silihakan isi 16 digit',
      digits3or4: 'Silahkan isi 3 atau 4 digit',
      digitsFirst6: 'Silahkan isi 6 digit pertama',
      digitsLast4: 'Silahkan isi 4 digit pertama',
      digitsLast3: 'Silahkan isi 3 digit pertama',
      mid6Digits: 'Silahkan isi digit {number} tengah ',
      expDateReq: 'Tanggal masa berlaku diperlukan',
      cvvReq: 'Kode Keamanan CVV diperlukan',
      file: 'Silahkan unggah file',
      filePhoto: 'Foto Kartu Kredit diperlukan',
      fileReceipt: 'Silahkan unggah resi',
      filePhotoReq: 'Foto Kartu diperlukan',
      amtLarger:
        'Jumlah tidak boleh nol dan harus lebih besar atau sama dengan ${minLimit} {currency}',
      withdrawAmtLarger: 'Jumlah penarikan minimum adalah ${minLimit} {currency} atau setara.',
      amt0: 'Jumlah tidak boleh 0',
      amtLess: 'Jumlah dari penyetoran anda tidak boleh lebih dari ${maxLimit} {currency}',
      note256: 'Batas panjang catatan adalah 256 karakter',
      noteMoreThan: 'Batas panjang catatan adalah {limit} karakter',
      noteLessThan: 'Batas panjang catatan adalah {limit} karakter',
      emailFormat: 'Silahkan isi alamat email yang benar',
      payMethodReq: 'Metode pembayaran diperlukan',
      expReq: 'Tanggal masa berlaku diperlukan',
      bankNameReq: 'Nama Bank diperlukan',
      bankCityReq: 'Nama Kota Bank diperlukan',
      bankProvinceReq: 'Nama Provinsi Bank diperlukan',
      bankAddressReq: 'Alamat Bank diperlukan',
      bankBranchReq: 'Nama Kantor Cabang Bank diperlukan',
      bankAccNumReq: 'Nomor Akun Bank diperlukan',
      ifscCodeReq: 'Kode IFSC diperlukan',
      bankBeneficiaryNameReq: 'Nama Pemilik Rekening Bank diperlukan',
      bankHolderAddressReq: 'Alamat Pemegang Akun diperlukan',
      swiftReq: 'Kode SWIFT diperlukan',
      accHolderAddress: 'Alamat Pemegang Akun diperlukan',
      bsbReq: 'BSB diperlukan',
      accNumIBANReq: 'Nomor Akun Bank/IBAN diperlukan',
      accNameReq: 'Nama Akun Bank diperlukan',
      withdrawMethodReq: 'Silahkan pilih metode penarikan',
      tnc: 'Silahkan setujui Syarat dan Ketentuan',
      currPwReq: 'Password saat ini diperlukan',
      currPwWrong: 'Password saat ini salah',
      newPwReq: 'Password baru diperlukan',
      newPwFormat:
        'Kata sandi Anda harus terdiri dari 8-16 karakter dan gabungan huruf (a-z dan A-Z), angka (0-9), dan karakter khusus seperti !@#$%^&*.()',
      newPwAccFormat:
        'Kata sandi Anda harus terdiri dari 8-16 karakter dan gabungan huruf (a-z dan A-Z), angka (0-9), dan karakter khusus seperti !@#$%^&*',
      IdenficalPw: 'Password baru tidak boleh sama dengan password yang lama',
      confirmPwReq: 'Silahkan isi password baru sekali lagi',
      confirmNotMatch: 'Dua isian tidak sesuai',
      pwReq: 'Password diperlukan',
      accCurrReq: 'anda harus memilih mata uang akun',
      tradAccReq: 'Silahkan pilih akun trading',
      answerReq: 'Silahkan pilih',
      bankCardReq: 'Silahkan pilih kartu',
      phoneNumberReq: 'Nomor Telepon Seluler untuk Bank diperlukan',
      nationaIdReq: 'Kartu Tanda Penduduk diperlukan',
      numberReq: 'Silahkan isi dengan nomor',
      alphanumericReq: 'Silaihkan isi dengan alfanumerik',
      idTypeReq: 'Silahkan pilih Tipe tanda pengenal',
      dobReq: 'Silahkan pilih tanggal lahir anda',
      pobReq: 'Silahkan pilih tempat lahir anda',
      nationalityReq: 'Silahkan pilih kewarganegaraan anda',
      titleReq: 'Silahkan pilih status anda',
      firstNameReq: 'Silahkan masukan nama depan anda',
      lastNameReq: 'Silahkan masukan nama belakang anda',
      emailReq: 'Sialhkan masukan alamat email anda',
      addressReq: 'Silahkan masukan alamat anda',
      suburbReq: 'Sialhkan masukan kota atau kabupaten anda',
      stateReq: 'Provinsi diperlukan',
      postcodeReq: 'Silahkan masukan kode pos',
      countryReq: 'Silahkan pilih negara tempat tinggal anda',
      NIReq: 'Silahkan masukan nomor Asuransi Nasional (BPJS) anda',
      taxResidencyReq: 'Silahkan pilih negara tempat anda membayar pajak',
      yearsAtAddressReq: 'Silahkan masukan angka tahun anda berada pada alamat tersebut',
      bankCodeReq: 'Silahkan isi Bank yang menerbitkan Kartu',
      bankBranchCodeReq: 'Kode cabang wajib diisi',
      dynamicReq: '{dynamic} diperlukan',
      mobReq: 'Telepon Seluler diperlukan',
      confirmed: 'Silahkan konfirmasi',
      resetBalAmt: 'Harap tentukan jumlah antara 0 and 1.000.000',
      verCodeReq: 'Dibutuhkan kode verifikasi',
      noEligibleAccounts: 'Tidak Ada Akun yang Layak',
      AlphanumericOnlyNotSpace: 'Hanya alfanumerik yang diperbolehkan. Spasi tidak diizinkan.',
      AlphanumericOnly: 'Hanya huruf dan angka.',
      NumbersSymbol: 'Hanya angka dan simbol.',
      NumbersOnlyNotSpace: 'Hanya angka. Spasi tidak diizinkan.',
      NumbersOnly: 'Hanya angka.',
      LettersSymbol: 'Hanya huruf dan simbol.',
      LettersSymbolNotSpace: 'Hanya huruf dan simbol. Spasi tidak diizinkan.',
      LettersOnlyNotSpace: 'Hanya huruf. Spasi tidak diizinkan.',
      NotSpace: 'Spasi tidak diperbolehkan.',
      beneficiaryNameReq: 'Nama Penerima diperlukan',
    },
    withdrawChannel: {
      banktransferbpaypolipay: 'Bank Transfer/Bpay/PoliPay',
      banktransfer: 'Transfer Bank Internasional',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: 'UnionPay',
      unionpaytransfer: 'UnionPay Transfer',
      fasapay: 'FasaPay',
      creditcard: 'Kartu Kredit',
      archiveThisCard: 'Harap arsipkan kartu ini jika sudah tidak berlaku atau sedang digunakan',
      update: 'memperbarui',
      archive: 'Arsip',
      updateExpiryMsg:
        'Harap perbarui tanggal kedaluwarsa kartu Anda untuk menggunakan kartu Anda untuk penarikan',
      available: 'Tersedia',
      pending: 'Tertunda',
      unavailable: 'Tidak tersedia',
      fullyRefunded: 'Dikembalikan sepenuhnya',
      requiredDocument: 'Dokumen yang diperlukan untuk pengarsipan kartu',
      officialLetter: 'Surat/email bank resmi yang menyatakan bahwa kartu tidak lagi tersedia',
      bankStatement: 'Penyata Bank dengan transaksi dibuat dengan kad ini',
      expiredCard: 'Kartu Kedaluwarsa/Tidak Tersedia',
      confirmArchive: 'Apakah anda yakin ingin mengarsipkan kartu ini?',
      confirmUpdate:
        'Apakah anda yakin ingin memperbarui tanggal kedaluwarsa dari kartu ini? Catatan: Moneta tidak akan bertanggung jawab jika penarikan gagal karena tanggal kedaluwarsa yang diberikan salah',
      bpay: 'Bpay',
      creditdebitcard: 'Kartu Kredit/Debit',
      bankwiretransferinternational: 'Wire Transfer Bank (Internasional)',
      banktransferaustralia: 'Transfer Bank (Australia)',
      banktransferinternational: 'Transfer Bank Internasional',
      banktransferchina: 'Transfer Bank (Internasional)',
      banktransferuk: 'Transfer Bank (UK)',
      mobilepay: 'Mobile Pay',
      brokertobrokertransfer: 'Transfer Antar Broker',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: 'Perbankan Elektronik (Thailand)',
      internetbankingnigeria: 'Perbankan Elektronik (Nigeria)',
      internetbankingvietnam: 'Perbankan Elektronik (Vietnam)',
      internetbankingmalaysia: 'Perbankan Elektronik (Malaysia)',
      internetbankingindonesia: 'Perbankan Elektronik (Indonesia)',
      internetbankingkorea: 'Perbankan Elektronik (Korea)',
      internetbankingsouthkorea: 'Perbankan Elektronik (Korea Selatan)',
      internetbankingindia: 'Perbankan Elektronik (India)',
      internetbankingphilippine: 'Perbankan Elektronik (Filipina)',
      internetbankingsouthafrica: 'Perbankan Elektronik (Afrika Selatan)',
      internetbankinguk: 'Perbankan Elektronik (UK)',
      internetbankinglaos: 'Perbankan Elektronik (Laos)',
      internetbankinghk: 'Perbankan Internet (Hong Kong)',
      internetbankinguganda: 'Perbankan Elektronik (Uganda)',
      internetbankingrwanda: 'Perbankan Elektronik (Rwanda)',
      internetbankingzambia: 'Perbankan Elektronik (Zambia)',
      internetbankingcongo: 'Perbankan Elektronik (Kongo)',
      internetbankingcameroon: 'Perbankan Elektronik (Kamerun)',
      internetbankingburundi: 'Perbankan Elektronik (Burundi)',
      internetbankingkenya: 'Perbankan Elektronik (Kenya)',
      internetbankingghana: 'Perbankan Elektronik (Ghana)',
      internetbankingtanzania: 'Perbankan Elektronik (Tanzania)',
      internetbankingbrazil: 'Perbankan Elektronik (Brazil)',
      internetbankingmexico: 'Perbankan Elektronik (Mexico)',
      internetbankingcolombia: 'Perbankan Elektronik (Colombia)',
      internetbankingchile: 'Perbankan Elektronik (Chile)',
      internetbankingperu: 'Perbankan Elektronik (Peru)',
      internetbankingusd: 'Perbankan Internet (USD)',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: 'Transfer Bank Vietnam',
      nigeriabanktransfer: 'Transfer Bank Nigeria',
      polipayment: 'Poli Payment',
      bankwiretransferaustralia: 'Wire Transfer Bank (Australia)',
      thailandinstantbankwiretransfer: 'Wire Transfer Bank Instan Thailand',
      nigeriainstantbankwiretransfer: 'Wire Transfer Bank Instan Nigeria',
      vietnaminstantbankwiretransfer: 'Wire Transfer Bank Instan Vietnam',
      laosinstantbankwiretransfer: 'Transfer Instan Antar Bank Laos',
      malaysiainstantbankwiretransfer: 'Transfer Bank Instan Malaysia',
      indonesiainstantbankwiretransfer: 'Transfer Bank Instan Indonesia',
      indiainstantbanktransfer: 'Wire Transfer Bank Instan India',
      southafricainstantbankwiretransfer: 'Wire Transfer Bank Instan Afrika Selatan',
      philippinesinstantbankwiretransfer: 'Transfer Bank Instan Filipina',
      indialocalbankwiretransfer: 'Wire Transfer Bank Lokal India',
      hongkongbanktransfer: 'Transfer Bank Lokal Hong Kong',
      ugandabanktransfer: 'Transfer Bank Lokal Uganda',
      rwandabanktransfer: 'Transfer Bank Lokal Rwanda',
      zambiabanktransfer: 'Transfer Bank Lokal Zambia',
      congobanktransfer: 'Transfer Bank Lokal Kongo',
      cameroonbanktransfer: 'Transfer Bank Lokal Kamerun',
      burundibanktransfer: 'Transfer Bank Lokal Burundi',
      kenyabanktransfer: 'Transfer Bank Lokal Kenya',
      ghanabanktransfer: 'Transfer Bank Lokal Ghana',
      tanzaniabanktransfer: 'Transfer Bank Lokal Tanzania',
      brazilbanktransfer: 'Transfer Bank Brasil',
      mexicobanktransfer: 'Transfer Bank Lokal Mexico',
      pagsmileBrazil: 'Transfer Bank Instan Brazil',
      pagsmileMexico: 'Transfer Bank Instan Mexicor',
      pagsmileColombia: 'Transfer Bank Instan Colombia',
      pagsmileChile: 'Transfer Bank Instan Chile',
      pagsmilePeru: 'Transfer Bank Instan Peru',
      banktransferbpaypolipayaustralia: 'Transfer Bank /Bpay/PoliPay (Australia)',
      banktransferbpaypolipayinternational: 'Transfer Bank/Bpay/PoliPay (Internasional)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: 'Transfer Bank Lokal Kanada',
      cryptobitcoin: 'Cyrpto-Bitcoin',
      cryptotetheromni: 'Crypto-USDT(OMNI)',
      cryptotethererc20: 'Cyrpto-USDT(ERC20)',
      cryptotethertrc20: 'Cyrpto-USDT(TRC20)',
      cryptocurrencybitcoin: 'Cryptocurrency-Bitcoin',
      cryptocurrencyusdt: 'Cryptocurrrency-USDT',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      trustly: 'Trustly',
      astropay: 'Astropay',
      webMoney: 'WebMoney',
      vload: 'Vload',
      dasshpewallet: 'Wallet India',
      cryptocurrencyethcps: 'Cryptocurrency-ETH',
      cryptocurrencyeth: 'Cryptocurrency-ETH',
      cryptocurrencyusdc: 'Cryptocurrency-USDC(ERC-20)',
      cryptocurrencyusdcerc20: 'Cryptocurrency-USDC(ERC-20)',
      cryptocurrencyusdccps: 'Cryptocurrency-USDC(ERC-20)',
      chileBankTransfer: 'Transfer Bank Chili',
      colombiaBankTransfer: 'Transfer Bank Colombia',
      perubanktransfer: 'Transfer Bank Peru',
      alipay: 'Alipay',
    },
  },
  home: {
    pendingTooltip:
      'Setelah akun live anda disetujui, anda akan menerima email berisi detail login MT{number}anda, sehingga ada dapat memulai trading! ',
    leverage: {
      tncTitle: '<span> Syarat dan Ketentuan </span>',
      tnc: ' <p> Dengan mengirimkan permintaan ini, saya menyatakan bahwa saya menyadari jika leverage yang tinggi mengandung risiko besar bagi modal saya dan ada kemungkinan bahwa saya dapat mengalami kerugian yang lebih besar dari dan tidak terbatas pada modal yang telah saya setorkan. <br> <br> Saya setuju bahwa penyedia produk berhak untuk meninjau aktivitas trading saya dan menyesuaikan tingkat leverage pada akun trading saya atas kebijakannya sendiri dan tanpa peringatan atau pemberitahuan sebelumnya. <br><br> Saya mengerti bahwa dengan memilih leverage yang tinggi, saya bisa trading dengan margin lebih besar tapi berpotensi mengalami kerugian yang signifikan. </p>',
      tncConfirm: 'Saya sudah membaca dan setuju dengan syarat dan ketentuan.',
      changeLeverageConfirm:
        'Permintaan anda telah dikirimkan dan akan segera diproses oleh tim support kami.',
      changeLeverageAutoComplete: 'Permintaan perubahan leverage anda telah dipenuhi',
      changeLeverageReject: 'Permintaan perubahan leverage anda ditolak',
      changeLeverageFail: 'Permintaan perubahan leverage anda gagal, silahkan coba lagi',
      changeLeverageDefault:
        'Telah terjadi kesalahan, silahkan kontak {supportEmail} untuk menyelesaikan permohononan anda',
      alreadyApplied:
        'anda telah meminta untuk mengubah leverage pada akun anda, dan saat ini sedang ditinjau oleh tim support {platform}. Harap menunggu sampai permintaan ini diselesaikan sebelum mengirimkan permintaan lain.',
      reduceLeverageWarn:
        'Mohon dicatat: Bahwa mengurangi leverage dapat mengakibatkan penutupan paksa dari posisi yang masih terbuka jika anda tidak memiliki margin yang cukup.',
      reduceLeverageConfirm: 'anda masih mau melanjutkan?',
      autoRestore:
        '<p>Karena ekuitas Anda >= 20.000, leverage yang tersedia bagi Anda adalah 500:1</p><br /><p>Saat ekuitas Anda kembali berada di bawah 20.000, maka Anda akan bisa mengubah leverage ke 1000:1 lagi</p>',
      oneThousandRatio:
        '<p>Harap merujuk pada ketentuan di bawah ini bila Anda ingin beralih ke leverage 1000:1:<br><br>1. Anda tidak bisa ikut serta dalam promosi apa pun yang berkaitan dengan kredit<br><br>2. Ekuitas akun Anda tidak boleh melebihi 15.000 Usd atau yang setara pada saat pengajuan permohonan<br><br>3. Leverage akun akan dialihkan ke 500:1 jika ekuitas akun Anda melebihi 20.000 Usd atau yang setara<br><br>4. Limit leverage bisa jadi berbeda sesuai peraturan yang berlaku<br><br>5. Perubahan leverage tidak berlaku untuk produk-produk dengan leverage tetap <br><br>6. Sesuai perjanjian klien, langkah-langkah termasuk namun tidak terbatas pada Terminasi atau Pengurangan akan dilakukan apabila Anda melanggar klausul-klausul yang berkenaan dengan Kejadian Wanprestasi dan/atau Aktivitas Trading Mencurigakan<br><br></p>',
    },
    pw: {
      error: 'Gagal memperbarui password anda. Silahkan coba lagi',
      confirm:
        'Password anda telah diperbarui, <br>anda sekarang bisa mulai menggunakan password baru anda untuk mengakses akun trading MT4/MT5 ',
      email:
        '<p class="first_info">Kami baru saja mengirimi anda email berisi petunjuk untuk mengatur ulang password anda.</p><p>Mohon pastikan untuk juga memeriksa folder spam email anda.</p>',
    },
    activate: {
      question: 'Apakah anda mau mengaktivasi ulang akun live anda?',
      confirm: 'Kami telah menerima permintaan aktivasi anda.<br>Akun anda akan segera diaktivasi.',
    },
    accStatus: {
      active: 'Aktif',
      rejected: 'Ditolak',
      inactive: 'Tidak Aktif',
      processing: 'Sedang Diproses',
      activate: 'Diaktfikan',
      expired: 'kedaluwarsa',
    },
    appNote: { desc02: 'Klik di sini untuk mencari tahu selengkapnya.' },
    app: {
      title: 'Bagaimana caranya',
      desc01: 'Unduh {platform}',
      desc02: 'Masuk dengan email Anda yang telah terdaftar',
      desc03: 'Verifikasi nomor telepon Anda',
      desc04: 'Anda sudah siap. Nikmati trading dengan mantap',
    },
    reset: {
      success: 'Reset Anda berhasil',
      creditTip:
        'Saldo akun Anda saat ini kurang dari 0. Dengan reset saldo akun Anda menjadi 0 maka kredit Anda akan direset jadi 0 juga. Apakah yakin ingin melanjutkan?',
      resetBtn: 'Mengatur ulang',
      tableHeader: 'Keseimbangan negatif',
    },
  },
  oneThousandRatioError: {
    error1: 'Akun ini berisi bonus dan tidak dapat diubah ke leverage 1000:1',
    error4:
      'Ekuitas akun lebih besar dari $15,000 (atau mata uang yang setara) dan tidak dapat diubah ke leverage 1000:1',
    error5: 'The ID audit is not pass, cannot be adjusted to 1000:1 leverage',
    error6:
      'Akun telah berpartisipasi dalam program bonus dan tidak dapat diubah ke leverage 1000:1',
  },
  paymentDetails: {
    subHeader: 'TAMBAHKAN METODE PEMBAYARAN',
    payMethod: 'Metode Pembayaran',
    tip: '<p>Silahakan pilih metode pembayaran untuk melakukan setoran dan penarikan pada masa yang akan datang, melalui menu di bawah ini .</p><br />\n    <p><b>Mohon dicatat:</b> Untuk memastikan permintaan setoran dan penarikan anda segera diproses, pertauran AML/CFT mewajibkan bahwa nama yang tertera pada metode pembayaran anda\n    <b> {individualUserName}</b>.</p>',
    result: {
      defaultSuccessMessage:
        'Terima kasih telah mengirimkan metode pendanaan untuk akun trading anda. Kami sedang memproses permintaan anda dan akan segera menghubungi anda kembali',
      unionPayCardSuccessMessage:
        'Kartu pembayaran Union Pay berhasil dikirimkan, rincian bank baru anda akan segera tersedia setelah disetujui.',
    },
    history: { header: 'METODE PEMBAYARAN' },
    cc: {
      field: {
        ccNum: 'Nomor Kartu Kredit',
        first6: 'enam pertama',
        last4: 'empat terakhir',
        withrawlable: 'Jumlah maksimal penarikan yang tersedia untuk kartu ini adalah {amount}.',
      },
      formValidation: { upload: 'Foto Kartu Kredit diperlukan' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: 'Pengambilan status setoran anda ... Sisa {countDown} detik.',
      successMsg: 'Terima kasih atas setoran anda, dana anda akan segera muncul pada akun anda.',
      rejectMsg: 'Ditolak',
      failureMsg: 'Setoran anda tidak berhasil. Silahkan coba lagi.',
      message:
        'Terima kasih sudah mendanai akun anda. Setoran anda secara manual akan segera disetujui oleh tim akun {platform}. Dana akan masuk ke akun anda segera setelah disetujui.',
      defaultException:
        'Untuk mengkonfirmasi setoran dengan {platform}, silahkan hubungi support {email}',
    },
  },
  deposit: {
    default: {
      deposit: 'Setoran',
      reference: 'Referensi anda',
      sendReceipt: 'Kirim Resi',
      sendReceiptTip:
        'Setelah adan melakukan transfer, mohon lengkapi formulir di bawah ini, sertakan tangkapan layar, foto, atau salinan pindaian resi transfer bank yang menunjukan dengan jelas nama lengkap, nomor akun, dan jumlah setoran anda sehingga kami bisa segera memproses ke dalam akun anda.',
      reminder: 'Mohon tunggu 1-2 hari kerja untuk dana dimasukan ke dalam akun anda.',
      successMsg:
        'Dana akan muncul di dalam akun trading anda setelah kami menerima setoran dan melakukan verifikasi resi transaksi anda.',
      alt: 'Atau, anda dapat mengirimkan resi transaksi anda melalui email ke {mailTo} secepat anda kehendaki.',
      reminderFundAppear:
        'Dana anda akan muncul di dalam akun anda, segera setelah kami menerima dana dari broker anda sebelumnya.',
      failed: 'Setoran gagal, silahkan coba lagi.',
      fee: 'BIAYA',
      instant: 'INSTAN',
      hour: 'JAM',
      hours: 'JAM',
      businessDays: 'HARI KERJA',
      disclaimer:
        '<li> 1. {platform} secara tegas tidak menerima pembayaran pihak ketiga. Semua dana yang disimpan ke akun trading anda harus dengan nama yang sama dengan akun trading {platform} anda. </li>\n      <li> </br> 2. Klien memahami bahwa mereka mungkin perlu diminta untuk memberikan informasi tentang sumber setoran, jumlah kekayaan klien secara umum dan informasi lain yang dianggap perlu oleh {platform} untuk mengidentifikasi sumber dana saat memproses permintaan setoran, dan setuju untuk memberikan semua informasi yang dibutuhkan oleh {platform}. Ketidakmampuan untuk memberikan informasi yang diperlukan dapat mengakibatkan penundaan yang signifikan dalam proses permintaan anda. {platform} tidak akan bertanggung jawab atas keterlambatan atau kerugian yang diderita akibat penundaan tersebut. </li>\n      <li> </br> 3. Klien harus memahami bahwa pengiriman permintaan setoran bukan merupakan penerimaan dana oleh {platform}. Jika akun anda sedang dekat atau dalam keadaan margin call, pastikan anda memiliki persiapan cadangan. </li>\n      <li> </br> 4. {platform} tidak akan bertanggung jawab atas keterlambatan / kesalahan yang disebabkan oleh sistem pembayaran. </li>\n      <li> </br> 5. {platform} tidak akan bertanggung jawab atas biaya apa pun (mis. biaya konversi, biaya pembayaran di muka, dll.) yang mungkin ditagihkan oleh institusi finansial pengirim atau penyedia pembayaran pihak ketiga atas pengiriman pembayaran anda. </li>\n      <li> </br>6. Harap diketahui bahwa deskripsi Moneta Markets pada sistem penagihan Anda akan ditampilkan sebagai <a href="https://www.monetamarkets.com/" target="_blank">www.monetamarkets.com</a> - Gladstonos & Filokyprou, 120, House Office E2<li>',
      channels: {
        creditOrDebit: 'Kartu Kredit/Debit',
        i12BankTransfer: 'Transfer Bank Internasional',
        auBankTransfer: 'Transfer Bank Australia',
        bPay: 'Transfer Bank BPAY',
        skrill: 'Skrill/Moneybookers',
        sticPay: 'SticPay',
        b2b: 'Transfer Antar Broker',
        unionPayCN: 'Transfer China Union Pay',
        vietInstant: 'Wire Transfer Bank Instan Vietnam',
        thaiInstant: 'Wire Transfer Bank Instan Thailand',
        nigeriaInstant: 'Wire Transfer Bank Instan Nigeria',
        laosInstant: 'Transfer Instan Antar Bank Laos',
        malayInstant: 'Transfer Bank Instan Malaysia',
        indonesiaInstant: 'Transfer Bank Instan Indonesia',
        philippineInstant: 'Transfer Bank Instan Filipina',
        mobilePay: 'Mobile Pay',
        poli: 'Poli Payment',
        paypal: 'Setoran Paypal',
        mijipay: 'Setoran Mijipay',
        crypto: 'Cryptocurrency',
        canada: 'Interac E-Transfer',
        tinkbit: 'Setoran Tinkbit',
        bitwallet: 'Setoran bitwallet',
        indiaInstant: 'Wire Transfer Bank Instan India',
        trustly: 'Trustly',
        pagsmileBrazil: 'Transfer Bank Instan Brazil',
        pagsmileMexico: 'Mexico Instant Bank Transfer',
        pagsmileColombia: 'Colombia Instant Bank Transfer',
        pagsmileChile: 'Chile Instant Bank Transfer',
        pagsmilePeru: 'Peru Instant Bank Transfer',
        astropay: 'Setoran Astropay',
        dasshpeWallet: 'Wallet India',
        southkoreabanktransfer: 'Transfer Bank Korea Selatan',
        alipay: 'Alipay',
        indonesiainstantbanktransfer: 'Transfer Bank Instan Indonesia',
        thailandinstantbanktransfer: 'Transfer Bank Instan Thailand',
      },
      rate: {
        rate: 'Nilai Tukar({oldCurrency}/{newCurrency}):',
        result: {
          rateError: 'Pengambilan nilai {oldCurrency} ke {newCurrency} gagal.',
          channelError: 'Pengambilan kanal pembayaran gagal.',
        },
      },
      cn: { rate: 'Nilai Tukar(USD/RMB):', amt: 'RMB:' },
      viet: {
        result: { rateError: 'Pengambilan nilai USD ke VND gagal.' },
        rate: 'Nilai Tukar (USD/VND)',
        amt: 'VND:',
      },
    },
    method: {
      desc: 'Cara mendanai akun anda dengan {method}',
      inst1: '{number}.   Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2:
        '{number}.   Pilih bank di halaman pemilihan bank dan login ke bank online {country} anda',
      inst3: '{number}.   Ikuti instruksi dan lakukan pembayaran melalui Online / Mobile Bank anda',
      inst4:
        '{number}.   Anda akan diarahkan kembali ke portal klien dan transaksi anda akan diselesaikan',
      inst5: '{number}.   Log masuk ke akun {method} Anda',
      inst6: '{number}.   Ikuti instruksi dalam akun {method} untuk menuntaskan pembayaran Anda',
      inst7:
        'Harap diperhatikan: Bila Anda memilih proses deposit manual, dana Anda bisa mengalami penundaan dan membutuhkan waktu cukup lama untuk bisa dikreditkan ke akun trading Anda',
      inst8:
        '{number}.   Harap diperhatikan bahwa jika Anda menggunakan {payment}, jumlah deposit akan dibatasi hanya sampai {currency} {amount} per transaksi',
      form: { header: 'Formulir {method}' },
      inst12: '{number}.   Lengkapi formulir di bawah ini dan pilih ‘Kirim’',
      inst13: '{number}.   Pindai kode QR untuk membuka aplikasi perbankan seluler Anda',
      inst14:
        '{number}.   Ikuti petunjuk di perangkat seluler Anda untuk menyelesaikan setoran Anda',
      inst15: '{number}.   Isi formulir di bawah ini dan tekan ‘Kirim’',
      inst16: "{number}.   Setelah memasukkan detail kartu Anda, tekan 'Kirim'",
    },
    cpsInfo: {
      thispaymentchannelistemporaryunavailable: 'Saluran pembayaran ini sementara tidak tersedia.',
    },
    uk: { header: 'Transfer Bank Instan UK', country: 'UK' },
    eu: { header: 'Transfer Bank Lokal UE', country: 'UE' },
    southAfrica: { header: 'Wire Transfer Bank Instan Afrika Selatan', country: 'Afrika Selatan' },
    idn: { header: 'Transfer Bank Instan Indonesia', country: 'Indonesia' },
    ida: { header: 'Wire Transfer Bank Instan India', country: 'India' },
    brazil: { header: 'Transfer Bank Instan Brazil', country: 'Brazil' },
    nigeria: { header: 'Wire Transfer Bank Instan Nigeria', country: 'Nigeria' },
    thailand: { header: 'Wire Transfer Bank Instan Thailand', country: 'Thailand' },
    vietnam: { header: 'Wire Transfer Bank Instan Vietnam', country: 'Vietnam' },
    laos: {
      header: 'Transfer Instan Antar Bank Laos',
      country: 'Laos',
      inst: '2.   Pindai kode QR dengan aplikasi OnePay Anda',
    },
    hongkong: {
      country: 'Hong Kong',
      inst1: '2.   Ikuti instruksinya dan lakukan pembayaran',
      inst2: '3.   Transaksi Anda akan dituntaskan',
      inst3: 'Harap ikuti instruksi di bawah ini untuk melakukan pembayaran:',
      inst4: '1.   Silahkan login ke rekening bank Anda.',
      inst5: '2.   Transfer dana ke:',
      inst6: '3.   Setelah menyelesaikan pembayaran, harap klik tombol SELESAI untuk melanjutkan.',
      inst7: 'Akun hanya berlaku untuk satu kali deposit',
      inst8: 'Peringatan: ',
      inst9:
        'Nomor yang dihasilkan oleh akun ini berlaku hanya untuk satu kali deposit dan tidak semestinya dipergunakan lagi pada transaksi di masa mendatang untuk menghindari kelambatan dalam masuknya dana.',
      inst10:
        'Jumlah pembayaran yang ditransfer harus sesuai dengan jumlah pada permintaan deposit.',
      inst11:
        'Harap pastikan Anda melakukan transfer bank dalam waktu 1 jam setelah mengajukan permintaan deposit guna menghindari kegagalan deposit.',
    },
    malay: { header: 'Transfer Bank Instan Malaysia', country: 'Malaysia' },
    php: { header: 'Transfer Bank Instan Filipina', country: 'Filipina' },
    uganda: { header: 'Transfer Bank Lokal Uganda', country: 'Uganda' },
    rwanda: { header: 'Transfer Bank Lokal Rwanda', country: 'Rwanda' },
    zambia: { header: 'Transfer Bank Lokal Zambia', country: 'Zambia' },
    congo: { header: 'Transfer Bank Lokal Kongo', country: 'Kongo' },
    cameroon: { header: 'Transfer Bank Lokal Kamerun', country: 'Kamerun' },
    burundi: { header: 'Transfer Bank Lokal Burundi', country: 'Burundi' },
    kenya: { header: 'Transfer Bank Lokal Kenya', country: 'Kenya' },
    ghana: { header: 'Transfer Bank Lokal Ghana', country: 'Ghana' },
    tanzania: { header: 'Transfer Bank Lokal Tanzania', country: 'Tanzania' },
    bankWireAu: {
      header: 'WIRE TRANSFER BANK AUSTRALIA',
      instruction:
        'Dana dapat ditransfer ke {platform} melalui fasilitas perbankan online dari institusi finansial anda, atau dengan menghubungi institusi finansial anda dan meminta transfer ke {platform}.',
      tip: 'Pilih mata uang akun yang ingin anda setorkan. Harap pastikan anda memilih mata uang yang sama dengan mata uang akun trading anda',
      form: {
        bankDetails: 'Rincian Bank(Hanya untuk setoran AUD)',
        refTip: 'Silahkan tentukan nomor login anda di sini',
        afp: 'Pembayaran cepat Australia(HANYA untuk Setoran Domestik AUD)',
        payIdOrABNNum: 'PayID/Nomor ABN',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip:
          'Mohon sertakan nomor akun trading anda {platform} di dalam isian referensi transfer bank.',
      },
    },
    bPay: {
      header: 'SETORAN BPAY',
      option: 'Bagaimana cara untuk mendanai akun dengan Bpay (hanya untuk Australia)',
      inst1:
        '1. Pilih akun MT4/MT5 anda untuk melihat kode Penagihan Bpay dan Nomor Referensi Bpay anda',
      inst2:
        '2. Masukan rincian pembayaran, Kode Penagihan Bpay dan Nomor Referensi Bpay anda ke dalam fasilitas internet banking dan kirimkan pembayaran anda.',
      inst3:
        '3. Kembali ke laman ini dan lengkapi formulir Bpay di bawah ini untuk memastikan pemrosesan segera dari transaksi anda',
      form: {
        header: 'FORMULIR BPAY',
        billerCode: 'Kode Bpay Biller',
        refNum: 'Nomor Referensi Bpay',
        receipt: 'Resi Transfer Bpay',
        reminderNotUploaded:
          'Kami melihat bahwa anda belum mengunggah tanda terima transaksi anda. Untuk memproses setoran anda, mohon secepatnya kirimkan salinan tanda terima melalui email ke {mailTo}.',
        reminderProc:
          'Mohon dicatat. Transaksi anda TIDAK DAPAT diproses sampai kami menerima resi dari anda.',
      },
    },
    b2b: {
      header: 'BROKER KE BROKER',
      option: 'Bagaimana cara untuk melakukan transfer dari broker lain ke {platform}',
      inst1: '1. Unduh dan lengkapi {download}',
      inst2:
        '2. Lengkapi formulir online di bawah ini dan unggah formulir lengkap Transfer Broker ke Broker anda',
      inst3:
        '3. Kirimkan salinan dari formulir Broker ke Broker anda ke broker anda sebelumnya untuk memulai proses transfer',
      b2bForm: 'Formulir Transfer Broker ke Broker',
      formValidation: { upload: 'Formulir Broker ke Broker diperlukan' },
    },
    cc: {
      header: 'SETORAN DENGAN KARTU KREDIT ATAU DEBIT',
      remind:
        'Seandainya anda mengalami kesulitan dalam mendanai akun anda dengan menggunakan metode utama, silahkan menggunakan metode Kredit/Debit tambahan.',
      field: {
        fundNow: 'Danai Sekarang dengan Kartu Kredit/Debit',
        newCard: 'Kartu Baru',
        cardNum: 'Nomor Kartu Kredit',
        nameOnCard: 'Nama pada kartu',
        expDate: 'Tanggal kedaluwarsa',
        cvv: 'Kode Keamanan CVV ',
        bankCode: 'Bank Penerbit Kartu',
      },
      placeholder: { bankCode: 'Silahkan masukan nama bank penerbit kartu anda' },
      cvvTip: '3 digit angka yang ditampilkan pada <br />belakang kartu anda',
      threeDomainSecQ: 'Apakah kartu anda mendukung 3D-Secure?',
      threeDomainSec:
        'Apa itu autentikasi 3D Secure? <br /> <strong> Autentikasi 3D Secure adalah metode pencegahan penipuan yang digunakan oleh Visa dan Mastercard. <br /> <br /> Jika kartu anda mendukung 3D Secure, dana anda dapat diproses dengan segera. </strong>',
      cardUpload:
        'Kartu yang anda gunakan untuk penyetoran dana belum ditautkan ke akun trading anda. Harap unggah foto bagian depan kartu anda untuk melakukan verifikasi. (Mohon 6 digit angka ditengah ditutup)',
      result: {
        success:
          'Terima kasih. Saat ini kami sedang melakukan verifikasi pada kartu anda, dana yang telah diverifikasi akan muncul di akun anda dalam waktu 24 jam.',
        depositSuccess:
          'Terima kasih atas setoran anda, dana anda akan segera muncul pada akun anda.',
        error:
          'Untuk melindungi keamanan akun anda, <br> anda telah mencapai batas percobaan transaksi setoran dan simpanan anda telah ditangguhkan untuk sementara. <br> Harap hubungi {email}',
      },
      mastercardonly:
        'Silakan lanjutkan menggunakan saluran pembayaran ini hanya dengan Mastercard.',
    },
    fasa: {
      header: 'SETORAN FASAPAY',
      description: 'Bagaimana cara mendanai akun anda dengan FasaPay',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Masuk ke akun Fasapay anda.',
      inst3: '3. Ikuti petunjuk dari Fasapay untuk melengkapi pembayaran anda.',
      inst4:
        '4. Setoran melalui Fasapay dibatasi sampai $25.000 USD per transaksi dan maksimal $75.000 per hari',
      form: { header: 'FORMULIR FASAPAY' },
    },
    miji: {
      header: 'Wire Transfer Bank Instan Thailand',
      description: 'Bagaimana cara mendanai akun anda dengan Transfer Bank Instan Thailand ',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Anda kemudian akan diarahkan ke laman pembayaran',
      inst3: '3. Masuk ke app mobile banking anda dan pindai QR code di laman pembayaran',
      inst4:
        'Mohon dicatat: <b>Sistem akan menambahkan beberapa sen dalam jumlah yang anda minta,</b>',
      form: { header: 'Formulir Wire Transfer Bank Instan Thailand' },
    },
    intSwift: {
      header: 'WIRE TRANSFER BANK: SWIFT INTERNASIONAL',
      desc: 'Dana dapat ditransfer ke {platform} melalui fasilitas perbankan online dari institusi finansial anda, atau dengan menghubungi institusi finansial anda dan meminta transfer ke {platform}.',
      desc2:
        'Setelah anda melakukan transfer, harap lengkapi formulir di bawah ini dan lampirkan salinan resi transfer bank yang dengan jelas menunjukkan nama lengkap, nomor rekening, dan jumlah setoran anda sehingga kami dapat memproses transaksi anda.',
      options: 'Silahkan pilih akun trading yang hendak anda danai',
      refInfo:
        'Mohon sertakan nomor akun trading anda {platform} di dalam isian referensi transfer bank.',
      bussDayInfo: 'Silahkan tunggu 2-5 hari kerja sampai dana masuk ke dalam akun anda',
      tip: 'Harap pastikan bahwa resi transfer anda dengan jelas menunjukkan <b> nama lengkap </b>, <b> nomor rekening </b>, dan <b> jumlah setoran </b> .',
    },
    mobile: {
      header: 'Mobile Pay',
      desc: 'Cara mendanai akun anda dengan Mobile Pay',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Pindai kode QR untuk membuka aplikasi mobile banking anda',
      inst3: '3. Ikuti petunjuk di perangkat seluler anda untuk menyelesaikan setoran',
      form: { payMethod: 'Metode Pembayaran:', rate: 'Nilai Tukar (USD / CNY):', amt: 'CNY:' },
      formValidation: { cnyAmt: 'Jumlah CNY tidak boleh lebih dari $ {limit}' },
    },
    net: {
      header: 'Neteller',
      inst2: '2. Masuk ke akun Neteller anda',
      inst3: '3. Ikuti petunjuk di akun Neteller untuk menyelesaikan pembayaran anda',
      inst4: {
        fca: '4. Kami menerima pembayaran dalam USD, EUR dan GBP',
        default: '4. Kami menerima pembayaran dalam AUD, USD, EUR, GBP dan SGD',
      },
    },
    canada: {
      header: 'Interac E-Transfer',
      desc: 'Cara mendanai akun anda dengan Interac E-Transfer',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Pilih bank di halaman pemilihan bank dan login ke bank online anda',
      inst3: '3. Ikuti instruksi dan lakukan pembayaran melalui Online / Mobile Bank anda',
      inst4: '4. Kami dapat menerima pembayaran dalam CAD. Maksimum $ 9.999 CAD per setoran',
      form: { header: 'Formulir Interac E-Transfer' },
    },
    bitwallet: {
      header: 'SETORAN BITWALLET',
      desc: 'Cara mendanai akun anda dengan BITWALLET',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Masuk ke akun Bitwallet anda',
      inst3: '3. Ikuti petunjuk di akun Bitwallet untuk menyelesaikan pembayaran anda',
      inst4: '4. Kami dapat menerima pembayaran dalam USD, EUR dan JPY',
      form: { header: 'FORMULIR BITWALLET' },
    },
    astropay: {
      header: 'SETORAN ASTROPAY',
      desc: 'Cara mendanai akun anda dengan ASTROPAY',
      inst2: '2. Masuk ke akun ASTROPAY anda',
      inst3: '3. Ikuti petunjuk di akun ASTROPAY untuk menyelesaikan pembayaran anda',
      inst4: '4. Kami menerima pembayaran dalam semua mata uang.',
      form: { header: 'FORMULIR ASTROPAY' },
    },
    crypto: {
      choose: 'Silakan pilih mata uang kripto pilihan anda',
      proceedQuestion: 'Apakah anda ingin melanjutkan setoran <br /> <b> {method} </b> anda?',
      remark:
        'Harap dicatat bahwa kami tidak dapat melakukan deposit atau penarikan melalui BUSDT, pastikan alamat dan Mata Uang Kripto Anda sesuai dengan alamat rantai dan mata uang yang kami terima atau Anda bisa kehilangan dana Anda.',
      inst2:
        '2. Anda kemudian akan diarahkan ke window baru yang menampilkan alamat dompet {method}',
      inst3:
        '3. Salin alamat dompet {method} dan kemudian masuk ke dompet {method} pribadi anda dan transfer jumlah yang anda inginkan ke alamat {method}.',
      inst4:
        'Mohon diperhatikan: Setoran BITCOIN diproses oleh B2BinPay dengan nilai tukar yang diberikan pada saat transaksi.',
    },
    poli: {
      header: 'SETORAN POLI',
      desc: 'Cara mendanai akun anda dengan Poli',
      reminder:
        '<li> Mohon diperhatikan: </li> <li> * Deposit harus atas nama yang sama dengan akun {platform} anda </li> <li> * Deposit harus dalam AUD saja </li>',
      form: { header: 'FORMULIR POLI' },
    },
    skrill: {
      header: 'SETORAN SKRILL/MONEYBOOKERS',
      desc: 'Cara mendanai akun anda dengan Skrill',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Masuk ke akun Skrill anda',
      inst3: '3. Ikuti petunjuk di akun Skrill untuk menyelesaikan pembayaran anda',
      inst4: '4. Kami menerima pembayaran dalam USD, EUR, GBP dan CAD',
      inst4FCA: '4. Kami menerima pembayaran dalam USD, EUR dan GBP',
      form: { header: 'FORMULIR SKRILL', email: 'Email Skrill / Moneybookers' },
      reminder:
        '<li>Mohon diperhatikan:</li><li>* Akun hanya dapat didanai dalam mata uang yang sama dengan akun trading MT4 anda. Skrill akan secara otomatis mengonversi dana ke mata uang basis akun anda jika email yang digunakan salah. Mungkin dikenakan biaya.</li><li>*  Deposit harus atas nama yang sama dengan akun {platform} anda.</li>',
    },
    sticPay: {
      inst2: '2. Masuk ke akun SticPay anda',
      inst3: '3. Ikuti petunjuk di akun SticPay untuk menyelesaikan pembayaran anda',
    },
    webMoney: {
      header: 'WEBMONEY',
      inst4: '4.   Kami dapat menerima pembayaran dalam USD dan EUR.',
      inst5: '5.   Setoran melalui WebMoney dibatasi sampai 1300 USD/1100 EUR per transaksi',
    },
    vload: {
      header: 'VLOAD',
      inst1: '2.   Kami dapat menerima pembayaran dalam USD, EUR dan JPY.',
      inst2:
        '<li>3.   Beli voucher Vload, klik <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">di sini</a>.</li>',
      form: { voucherpin: 'Pin Voucher', voucherpinReq: 'Pin Voucher diperlukan' },
    },
    dasshpe: {
      headerWallet: 'Wallet India',
      inst1: '2.   Setujui transaksinya dari aplikasi UPI Anda',
      inst2: '2.   Ikuti instruksinya dan lakukan pembayaran via aplikasi wallet Anda',
    },
    thaiPaytoday: {
      header: 'Wire Transfer Bank Instan Thailand',
      desc: 'Cara mendanai akun anda dengan Transfer Bank Instan Thailand',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Ikuti instruksi untuk menyelesaikan pembayaran anda',
      form: { header: 'Formulir Wire Transfer Bank Instan Thailand' },
      scan: 'Pindai untuk melakukan setoran',
      exp: 'Kedaluarsa dalam {expireTime}',
      reminder:
        "Dana akan muncul di akun anda dalam waktu 1 jam. <br/> <br/>\n      Anda dapat melihat status setoran anda saat ini di 'riwayat transaksi' anda. <br/>\n      Jika anda mengalami masalah dengan setoran, silakan hubungi Manajer Akun anda",
    },
    unionPay: {
      header: 'Setoran UnionPay',
      desc: 'Bagaimana cara menemukan akun UnionPay anda',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Pilih bank di halaman pemilihan bank dan login ke bank online anda',
      inst3: '3. Ikuti instruksi dan lakukan pembayaran melalui Online / Mobile Bank anda',
      form: { header: 'FORMULIR UNION PAY' },
    },
    unionPayCN: {
      header: 'Transfer China Union Pay',
      desc: 'Cara mendanai akun anda dengan China Union Pay Transfer',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Halamanrincian pembayaran akan muncul',
      inst3: '3. Ikuti instruksi dan lakukan pembayaran melalui Online / Mobile Bank anda',
      inst4:
        'Mohon Dicatat: anda mungkin akan dimintai nomor keanggotaan saat melakukan penyetoran, harap masukkan nomor akun perdagangan anda sebagai nomor Keanggotaan',
      form: {
        header: 'FORMULIR CHINA UNION PAY',
        holderName: 'Nama Pemegang Kartu UnionPay:',
        holderNameInfo:
          'Harap pastikan nama yang anda masukkan di atas sama dengan nama kartu bank anda',
      },
    },
    vietZota: {
      header: 'Wire Transfer Bank Instan Vietnam',
      desc: 'Cara mendanai akun anda dengan Transfer Bank Instan Vietnam',
      inst: '<li> 1. Lengkapi formulir di bawah dan tekan ‘Kirim’ </li> <li> 2. Pilih bank di halaman pemilihan bank dan login ke bank online Vietnam anda </li> <li> 3. Ikuti instruksi dan lakukan pembayaran melalui Online / Mobile Bank </li><li> 4. Anda akan diarahkan kembali ke portal klien dan transaksi anda akan diselesaikan</li>',
      form: { header: 'Formulir Transfer Bank Instan Vietnam' },
    },
    paypal: {
      header: 'PayPal',
      desc: 'Cara mendanai akun anda dengan PayPal',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Masuk ke akun PayPal anda',
      inst3: '3. Ikuti petunjuknya dan di akun PayPal untuk menyelesaikan pembayaran anda',
      inst4: 'Mohon diperhatikan: Kami tidak dapat menerima pembayaran dari pihak ketiga',
      form: { header: 'FORMULIR PAYPAL' },
    },
    cardpay: {
      desc: 'Bagaimana mendanai akun anda dengan Kartu Kredit',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Anda kemudian akan diarahkan ke formulir lain untuk memasukkan rincian kartu anda',
      inst3: "3. Setelah memasukkan rincian kartu anda, tekan 'Kirim'",
      note: "Harap dicatat bahwa deskripsi transaksi yang muncul pada laporan kartu Anda akan berbeda-beda tergantung dari portal finansial yang Anda pakai. Jika Anda memiliki pertanyaan terkait deskripsi transaksi, harap hubungi <a href='mailto:{mail}'>{mail}</a>",
      form: { header: 'FORMULIR KARTU KREDIT' },
    },
    tinkbit: { inst5: '5. Maksimum $ 1500 USD per setoran' },
    trustly: {
      header: 'Trustly',
      desc: 'Cara mendanai akun anda dengan Trustly',
      inst1: '1. Lengkapi formulir di bawah ini dan tekan "Kirim"',
      inst2: '2. Masuk ke akun Trustly anda',
      inst3: '3. Ikuti petunjuk di akun Trustly untuk menyelesaikan pembayaran anda',
      inst4: '4. Kami menerima pembayaran dalam EUR dan GBP',
      form: { header: 'FORMULIR TRUSTLY' },
      reminder:
        '<li> Mohon diperhatikan: </li>\n      <li> * Akun hanya dapat didanai dalam mata uang yang sama dengan akun trading MT4 anda. Skrill akan secara otomatis mengonversi dana ke mata uang basis akun anda jika email yang digunakan salah. Mungkin dikenakan biaya.</li>\n      <li> * Deposit harus atas nama yang sama dengan akun {platform} anda. </li>',
    },
    fxir: {
      desc: '{number}.    Lengkapi formulir di bawah ini dan pilih ‘Kirim’',
      desc2:
        '{number}.    Untuk deposit yang berhasil dan cepat, transfer dana dalam mata uang yang sama dengan akun trading Anda dan buat permintaan deposit setelah mengunggah bukti transfer.',
      desc1: '{number}.    Lengkapi formulir di bawah ini dan pilih ‘Kirim’',
      desc3:
        'Setelah Anda melakukan transfer, lengkapi formulir di bawah ini dan lampirkan salinan tanda terima transfer bank yang jelas menunjukkan nama lengkap Anda, ID FX-IR, dan jumlah deposit agar kami dapat memproses transaksi Anda.',
      desc4: 'Penting! Mohon sertakan (nomor akun trading Anda) di dalam kolom referensi transfer.',
      field: {
        id: 'Pengirim FX-IR ID',
        accName: 'Nama Akun FX-IR',
        walletNumber: 'Nomor dompet FX-IR',
        transactionID: 'ID Transaksi',
        senderFxirID: 'Pengirim FX-IR ID',
      },
    },
  },
  restrictDeposit: {
    header: 'PEMBERITAHUAN PENTING',
    info: 'Mohon maaf, kami tidak lagi menerima setoran dari klien yang tinggal di Kanada.',
  },
  withdraw: {
    default: {
      selectCC: 'Pilih Kartu Kredit',
      selectBA: 'Pilih Rekening Bank',
      anotherCC: 'Masukkan kartu kredit secara manual',
      anotherBA: 'Tambahkan rekening bank',
      remember: 'Ingat akun saya',
      success: 'Permintaan penarikan anda berhasil dan akan segera diproses',
      ccFail:
        'Untuk melindungi keamanan akun anda, anda telah mencapai percobaan transaksi setoran maksimum dan penarikan anda untuk sementara ditangguhkan. Harap hubungi {email}.',
      fetchTradeAccFailed: 'Pengambilan Akun Perdagangan gagal',
      failed: 'Penarikan gagal. Silakan coba lagi nanti',
      notEnoughFunds: 'Jumlah yang dimasukkan melebihi batas untuk kartu ini',
      cardExpird: 'Kartu ini sudah tidak berlaku. Pilih kartu lain atau metode pembayaran lain.',
      minAmountWarn:
        'Jumlah penarikan yang diajukan kurang dari jumlah pengajuan minimum portal pembayaran. Harap tambahkan dana ke saldo Anda hingga melampaui jumlah minimum sebelum mengajukan permintaan penarikan. Informasi selengkapnya dapat dilihat di <a href="https://{regulatorUrl}/clients/accounts/withdraw/">sini</a>. ',
      noCardWarn:
        'anda tidak memiliki kartu yang tersedia untuk melakukan penarikan, harap tautkan kartu anda di Rincian Penarikan terlebih dahulu.',
      equityStop:
        'Mohon maaf, penarikan anda tidak dapat diproses karena akan mengakibatkan margin tersedia untuk akun anda turun di bawah level stop out 100%',
      equityMargin:
        'Mohon maaf, saat ini kami tidak dapat memproses penarikan anda karena akun anda dalam keadaan hedge sepenuhnya.',
      equityContinue:
        'Kami melihat anda saat ini memiliki posisi terbuka, anda dapat mengalami stop out setelah mengajukan permohonan penarikan, harap konfirmasi',
      YourPaymentGreaterThanAvailableBalance:
        'Pembayaran anda lebih besar dari saldo yang tersedia',
      blackList:
        'Sayangnya saat ini Anda tidak dapat mengirimkan permintaan penarikan. Harap hubungi {supportEmail} untuk informasi lebih lanjut',
      NetellerChargeAlert:
        'Penarikan Neteller dibatasi hingga $500.000 per transaksi, dan biaya 2% (dibatasi pada $30) berlaku.',
      FasaPayChargeAlert: 'FasaPay mengenakan biaya transaksi 0,5% untuk setiap penarikan.',
      SkrillChargeAlert: 'Skrill membebankan biaya transaksi 1% untuk setiap penarikan.',
      localBankTransfer: 'Transfer Bank Lokal',
    },
    fasa: {
      field: { accName: 'Nama Akun FasaPay', accNum: 'Nomor Akun FasaPay' },
      formValidation: {
        accNameReq: 'Nama Akun FasaPay wajib diisi',
        accNumReq: 'Nomor Akun FasaPay diperlukan',
      },
    },
    neteller: {
      field: { email: 'Email Neteller' },
      formValidation: { emailReq: 'Email Neteller diperlukan' },
    },
    bitcoin: {
      field: { address: 'Alamat Dompet Bitcoin' },
      formValidation: { addressReq: 'Alamat Dompet Bitcoin diperlukan' },
    },
    eth: {
      field: { address: 'Alamat Dompet ETH' },
      formValidation: { addressReq: 'Alamat Dompet ETH diperlukan' },
    },
    usdc: {
      field: { address: 'Alamat Dompet USDC' },
      formValidation: { addressReq: 'Alamat Dompet USDC diperlukan' },
    },
    usdt: {
      field: { address: 'Alamat Dompet USDT' },
      formValidation: { addressReq: 'Alamat USDT Wallet diperlukan' },
    },
    paypal: {
      field: { email: 'Email Paypal' },
      formValidation: { emailReq: 'Email Paypal diperlukan' },
    },
    skrill: {
      field: { email: 'Email Skrill' },
      formValidation: { emailReq: 'Skirll Email diperlukan' },
    },
    sticpay: {
      field: { email: 'Email SticPay' },
      formValidation: { emailReq: 'Email SticPay diperlukan' },
    },
    webMoney: {
      field: { email: 'Email WebMoney' },
      formValidation: { emailReq: 'Email WebMoney diperlukan' },
    },
    vload: {
      field: { email: 'Email Terdaftar Vload' },
      formValidation: { emailReq: 'Email Terdaftar Vload diperlukan' },
    },
    bitwallet: {
      field: { email: 'Email Bitwallet' },
      formValidation: { emailReq: 'Email Bitwallet diperlukan' },
    },
    astropay: {
      field: { accountNumber: 'Akun Astropay' },
      formValidation: { accountNumberReq: 'Akun Astropay diperlukan' },
    },
    transfer: {
      info: '{platform} akan mengganti biaya bank yang dikenakan untuk <u> SATU </u> penarikan per bulan. Penarikan tambahan dalam sebulan akan dikenakan biaya bank sebesar 20 unit mata uang penarikan anda.',
      label: {
        upload: 'Harap unggah salinan rekening koran anda (terkini hingga 3 bulan terakhir)',
        upload2:
          'Pastikan tanggal laporan atau tanggal transaksi terakhir berada dalam rentang bulan ini hingga tiga bulan yang lalu. Jika melebihi batas waktu tersebut, silakan unggah ulang laporan.',
      },
      formValidation: { upload: 'Harap unggah rekening koran' },
    },
    ccMethod: {
      desc1:
        'Demi mematuhi APU/PPT, dana harus ditransfer ke kartu yang digunakan untuk mendanai deposit awal Anda',
      desc2:
        'Bila kartu yang Anda pergunakan untuk mendanai akun tidak lagi tersedia, harap menghubungi {mailto}',
    },
    otherMethod: {
      otherType: 'Jenis lain',
      withdrawalMethod: 'Metode Penarikan',
      desc1:
        'Harap dicatat bahwa transfer bank mungkin dikenai biaya. Harap pastikan jumlah penarikan Anda mencukupi untuk menutupi biaya yang akan dikenakan.',
      desc2:
        'Demi mematuhi peraturan APU/PPT, semua penarikan harus ditransfer menggunakan metode yang sama seperti digunakan untuk mendanai akun Anda.',
      note: "Akibat adanya perubahan baru-baru ini, bank kami mungkin tidak dapat memproses transfer antar bank internasional bagi para klien yang terdaftar di beberapa negara.<br/>\n      Bila Anda terpaksa melakukan penarikan melalui transfer bank, harap hubungi layanan Dukungan Pelanggan kami di nomor telepon +44 2080 363 883 atau pada <a href='mailto:{mail}'>{mail}</a>.<br/>\n      Kami mohon maaf atas ketidaknyamanan ini.",
      verificationCode:
        'Kode verifikasi telah dikirimkan ke {email}. Harap hubungi{supportEmail} bila Anda membutuhkan bantuan lebih lanjut',
      note2:
        'Karena dampak kebijakan Travel Rule Korea Selatan, kami tidak akan menerima penarikan dari alamat pertukaran Korea yang melebihi 735 USDT (sekitar 1.000.000 KRW) per transaksi atau dari pelanggan yang alamatnya belum lulus verifikasi KYC.',
    },
  },
  transfer: {
    creditWarn:
      'Kami tidak dapat memproses transfer dana anda dari akun yang dipilih karena akun anda berisi kredit promosi.',
    blackListWarn:
      'Transfer dana dari akun MAM anda dapat mengakibatkan likuidasi posisi yang dikelola. Harap hubungi {mailTo} jika anda ingin mentransfer dana dari akun ini.',
  },
  changePw: {
    header: 'GANTI PASSWORD',
    field: {
      currPw: 'Password saat ini',
      newPw: 'Password baru',
      confirmPw: 'Konfirmasi Password Baru',
    },
    succ: 'Password anda telah diperbarui. anda sekarang dapat menggunakan password baru anda untuk mengakses Portal Klien.',
  },
  security: {
    confirm: 'Konfirmasi',
    cancel: 'Batal',
    understand: 'Saya mengerti',
    passwordRenewalReminder: {
      header: 'Ubah Password Login',
      desc: 'Untuk keamanan dana akun Anda, withdraw tidak dapat dilakukan selama T+{days} hari setelah mengganti password login Anda.',
    },
    withdrawalFreeze: {
      header: 'Withdraw dana tidak dapat dilakukan sementara waktu',
      userLoginDesc:
        'Dikarenakan perubahan alamat email/nomor telepon masuk Anda, withdraw dana tidak dapat dilakukan sementara waktu selama T+{days} hari. Pembatasan ini diharapkan akan berakhir dalam {hours} jam.',
      passDesc:
        'Dikarenakan perubahan password masuk Anda, withdraw dana tidak dapat dilakukan sementara waktu selama T+{days} hari karena alasan keamanan. Pembatasan ini diharapkan akan berakhir dalam {hours} jam.',
      limitSecurityAuthenticator:
        'Karena modifikasi aplikasi Authenticator Keamanan Anda, penarikan tidak didukung sementara waktu selama T+{days} hari untuk memastikan keamanan akun Anda. Pembatasan ini diperkirakan akan dicabut dalam {hours} jam.',
    },
    securityManagement: 'Manajemen Keamanan',
    changeAuthenticatorTitle: 'Ubah Pemastian Keamanan',
    authenticationMethod: 'Metode Autentikasi',
    securityAuthentication: 'Keamanan Autentikasi',
    isVerified: 'Terverifikasi',
    isNotVerified: 'Belum terverifikasi',
    password: 'Kata Sandi',
    securityAuthenticatorApp: 'Aplikasi Autentikasi Keamanan',
    enableLoginAuthentication: 'Aktifkan Autentikasi Login',
    loginLocationChange: 'Pemberitahuan Email Perubahan Lokasi Login',
    lastUpdate: 'Pembaruan terakhir',
    modify: 'Ubah',
    verify: 'Memverifikasi',
    enable: 'Aktifkan',
    resend: 'Kirim ulang',
    enterVerificationCode: 'Masukkan kode verifikasi',
    verifyPreEmailNote: 'Kode akan dikirimkan ke {email}',
    verifyPostEmailNote: 'Kode telah dikirimkan ke {email}',
    preEmailCode: 'Kode akan dikirimkan ke alamat email baru Anda',
    authenticatorTooltip: 'Kode verifikasi aplikasi Autentikator akan diperbarui setiap 30 detik',
    contactSupport:
      'Tidak dapat mengakses metode autentikasi? Silakan hubungi {contact_email} atau Livechat',
    changeEmail: 'Ubah Alamat Email',
    emailTitle: 'Masukkan alamat email baru',
    editAuthenticator: 'Edit Perangkat Otentikasi',
    addAuthenticator: 'Tambah Perangkat Otentikasi',
    scanWithApp: 'Pindai dengan Aplikasi Authenticator',
    scanAppTooltip:
      'Anda dapat mengunduh Google Authenticator atau Microsoft Authenticator dari toko aplikasi di ponsel Anda. Lalu, gunakan kamera di dalam aplikasi untuk memindai kode QR yang disediakan.',
    scanAppTooltipForChina:
      'Anda dapat mengunduh Google Authenticator atau Microsoft Authenticator dari toko aplikasi ponsel Anda atau mencari Google Authenticator dalam Program Mini WeChat dan memilih hasil pertama. Kemudian, gunakan kamera dalam aplikasi untuk memindai kode QR yang disediakan.',
    scanAuthenticatorAppTips: {
      tips1:
        'Anda dapat mengunduh Google Authenticator atau Microsoft Authenticator dari toko aplikasi seluler',
      tips2:
        'Atau Anda juga dapat mencari Google Authenticator di WeChat Mini Program dan pilih hasil pertama.',
      tips3: 'Kemudian, gunakan kamera dalam aplikasi untuk memindai kode QR yang disediakan',
    },
    userGuide: 'Panduan Pengguna',
    setupKey: 'Setup Kunci',
    tnc: 'Dengan menghubungkan aplikasi otentikasi, Anda mengkonfirmasi bahwa Anda telah membaca dan menerima {link}.',
    disclaimer: 'disclaimer',
    changePasswordSuccess: 'Password Diperbarui',
    changeEmailSuccess: 'Alamat Email Diperbarui',
    enableSecuritySuccess: 'Pengaman Otentikasi Aktif',
    updateSecuritySuccess: 'Otentikator Keamanan Diperbarui',
    changePasswordSuccessDesc:
      'Kata sandi Anda telah diperbarui. <br/> Anda sekarang dapat menggunakan kata sandi baru untuk login.',
    changeEmailSuccessDesc:
      'Alamat email Anda telah diperbarui. <br/> Anda sekarang dapat menggunakan alamat email baru untuk login.',
    enableSecuritySuccessDesc: 'Aplikasi otentikasi keamanan Anda telah diaktifkan.',
    updateSecuritySuccessDesc: 'Aplikasi otentikasi keamanan Anda telah diperbarui.',
    passwordReq: 'Persyaratan Kata Sandi:',
    passwordCharacters: '8-16 karakter',
    passwordMix: 'Campuran huruf (a-z dan A-Z), angka (0-9)',
    passwordSpecial: 'Karakter khusus seperti !@#$%^&*().',
    confirmPwd: 'Konfirmasi Kata Sandi',
    enableNow: 'Aktifkan sekarang',
    changeDesc:
      'Bagi memastikan keselamatan dana dalam akaun anda, pengeluaran tidak akan disokong untuk T+ {numDays} hari selepas perubahan {method} anda.',
    verificationTitle: 'Verifikasi diperlukan',
    verificationDesc: 'Harap aktifkan Security Authenticator APP sebelum melanjutkan.',
    updateSuccess: 'Berhasil diperbarui',
    verifiedSuccess: 'Berhasil diverifikasi',
    verificationRequired: 'Silakan masukkan kode verifikasi',
    next: 'Berikutnya',
  },
  reserPwMobile: { header: 'SETEL ULANG PASSWORD' },
  downloads: {
    appDesc: 'Unduh Aplikasi {platform} di App Store dan Google Play Store',
    mt4: 'METATRADER 4',
    mt4Desc: 'Unduh MetaTrader 4 Untuk PC, browser web, smartphone, dan tablet',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone / iPad',
    android: 'Android',
    web: 'WebTrader',
    webTrader: 'Web Trader',
    smartTrader: 'Alat SmarTrader',
    mt5: 'METATRADER 5',
    mt5Desc: 'Unduh MetaTrader 5 Untuk PC, browser web, smartphone, dan tablet',
    button: { download: 'UNDUH', launch: 'MELUNCURKAN' },
  },
  forgetPwReq: {
    headerMobile: 'MASUKKAN NOMOR TELEPON LOGIN anda',
    labelEmail: 'Email',
    labelMobile: 'Nomor Telepon',
    loginMobile: 'Nomor Telepon Login',
    loginSMSCode: 'Kode SMS',
    loginSmsCodeExpires: 'Kode SMS kedaluwarsa dalam 5 menit',
    getCode: 'Kirim Kode SMS',
    failedCode: 'Gagal mengirim Kode SMS ke ponsel anda',
    sentAlready: 'Terkirim',
    expired: 'Kode SMS telah kedaluwarsa',
    tip: 'Masukkan alamat email akun anda untuk menyetel ulang password anda.',
    formValidation: {
      emailReq: 'Email login wajib diisi',
      mobileReq: 'Login seluler diperlukan',
      smsCodeReq: 'kode sms diperlukan',
    },
    succ: 'Kami baru saja mengirimi anda email berisi petunjuk untuk menyetel ulang password anda. <br> <br> Pastikan anda memeriksa folder spam pada email anda.',
  },
  loginClient: {
    header: 'PORTAL KLIEN',
    failedMasseage: 'Coba lagi',
    existingClientPortalAccess:
      'Kami melihat bahwa anda sudah memiliki akses ke Portal Klien. <br /> <br /> Silahkan masuk menggunakan email anda untuk membuka akun baru!',
    existingClientPortalAccessMobile:
      'Kami melihat bahwa anda sudah memiliki akses ke Portal Klien. <br /> <br /> Silahkan masuk menggunakan nomor telepon anda untuk membuka akun baru!',
    formValidation: {
      emailReq: 'Alamat email wajib diisi',
      mobileReq: 'Harap masukkan nilai numerik saja',
    },
  },
  myProfile: {
    changeDetails:
      'Jika anda ingin memperbarui rincia profil yang ada, jangan ragu untuk menghubungi {mailTo}',
  },
  openAdditAcc: {
    chooseTradePlat: 'PILIH PLATFORM TRADING',
    chooseAccType: 'PILIH JENIS AKUN',
    chooseCurrency: 'PILIH MATA UANG AKUN',
    chooseLeverage: 'PILIH LEVERAGE',
    chooseBalance: 'SALDO REKENING',
    notes: 'CATATAN TAMBAHAN',
    currError: 'anda harus memilih mata uang akun',
    platErr: 'anda harus memilih platform trading',
    accTypeErr: 'anda harus memilih jenis akun',
    leverageErr: 'anda harus memilih leverage',
    balanceErr: 'anda harus memilih saldo',
    succ: '<p> Akun live tambahan anda sedang disiapkan dan anda akan segera menerima email dengan detail login anda. </p> <p> anda dapat melihat status aplikasi anda saat ini dari halaman beranda. </p>',
    byTicking: 'Dengan mencentang kotak ini:',
    swapFreeNote:
      '*Mohon diperhatikan: Sebagai pengganti biaya swap, sejumlah kecil biaya administrasi mungkin dikenakan.',
    pammNote:
      '*Akun PAMM tersedia hanya untuk para investor yang menggunakan skema Money Manager. Akun PAMM tidak dapat diperdagangkan oleh siapapun kecuali Money Manager.',
  },
  openDemoAdditAcc: {
    header: 'BUKA AKUN DEMO TAMBAHAN',
    button: 'buka akun demo',
    liveButton: 'buka akun live',
    succ: 'Akun demo tambahan anda telah disiapkan dan anda akan segera menerima email dengan detail login anda.',
  },
  resetPassword: {
    header: 'SETEL ULANG PASSWORD',
    succ: 'Password anda telah diperbarui. anda sekarang dapat menggunakan pasword baru anda untuk mengakses akun trading MT4 / MT5 anda.',
    succ2: 'Password anda telah diperbarui.',
    failed: 'Token kedaluwarsa',
    emailNotFound: 'Mohon maaf, alamat email anda tidak dikenali',
  },
  fileUploader: {
    supportedFormat: 'Jenis file yang didukung: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: 'Jenis file yang didukung: {files}',
    maxSize: 'Ukuran file unggahan maksimum: {maxSize} MB',
    maxFileNum: 'Nomor file maksimum: {maxFileNum}',
    failedFileExtension: 'Unggah jenis file yang benar',
  },
  transcationHistory: {
    deposit: 'DEPOSIT',
    withdraw: 'PENARIKAN',
    desc: {
      incomplete:
        'Jika anda menghentikan atau gagal menyelesaikan proses penyetoran, status transaksi akan ditampilkan sebagai "belum selesai". anda akan diminta untuk membuat setoran baru karena transaksi ini tidak dapat dilanjutkan.',
      success: 'Transaksi anda berhasil. Dana anda akan segera muncul di akun anda',
      proc: 'Transaksi anda sedang diproses. Harap hubungi {mailTo}',
      failed: 'Transaksi anda gagal. Harap hubungi {mailTo} untuk informasi lebih lanjut',
      rejected: 'Transaksi anda telah ditolak. Harap hubungi {mailTo} untuk informasi lebih lanjut',
    },
    withdrawDesc: {
      sub: 'Transaksi anda telah dikirim. Mohon tunggu sampai selesai.',
      suc: 'Transaksi anda berhasil. Dana anda akan segera muncul di akun anda',
      proc: 'Transaksi anda sedang diproses. Harap hubungi {mailTo}',
      incomp:
        'Transaksi anda membutuhkan langkah tambahan untuk diselesaikan. Harap hubungi {mailTo} untuk informasi lebih lanjut',
      cancel:
        'Transaksi anda telah dibatalkan. Harap hubungi {mailTo} untuk informasi lebih lanjut',
      fail: 'Transaksi anda gagal. Harap hubungi {mailTo} untuk informasi lebih lanjut',
      rej: 'Transaksi anda telah ditolak. Harap hubungi {mailTo} untuk informasi lebih lanjut',
    },
  },
  transferFunds: {
    default: {
      equityStop:
        'Sayangnya transfer Anda tidak dapat diproses karena akan berakibat ketersediaan margin akun Anda akan jatuh di bawah 100% level stop out',
      equityMargin:
        'Sayangnya saat ini kami tidak dapat memproses transfer Anda karena akun Anda sepenuhnya dalam lindung nilai.',
      equityContinue:
        'Kami mendeteksi sekarang ini Anda memiliki posisi terbuka, mungkin Anda akan mengalami stop out setelah mengajukan permintaan transfer Anda. Harap konfirmasi jika Anda ingin melanjutkan.',
      blackList:
        'Akun ini telah dibatasi untuk melakukan transfer antar akun, harap hubungi {supportEmail}',
    },
    pleaseNote: 'Mohon dicatat:',
    suc: 'Dana anda telah berhasil ditransfer',
    warn1:
      'anda memiliki tanggung jawab menjaga saldo akun anda untuk tidak akan memicu margin call. Jika anda memiliki posisi terbuka saat ini, mohon pastikan anda mempertahankan margin yang diperlukan di akun anda. Dana yang ditransfer ke rekening yang disimpan dalam mata uang yang sama diproses secara instan.',
    warn2:
      'Jika transfer dana antar rekening disimpan dalam mata uang berbeda, kirim email ke {mailTo}.',
    warn3: 'Harap dicatat bahwa dana tidak dapat ditransfer antar akun read-only.',
  },
  transferHistory: {
    payStatus: {
      processing: 'Sedang diproses',
      paid: 'Dibayar',
      unpaid: 'Belum dibayar',
      rejected: 'Ditolak',
    },
  },
  identityProof: {
    home: {
      header: 'APLIKASI AKUN TIDAK LENGKAP',
      content:
        'Halo {name}, permohonan akun anda hampir selesai, namun anda masih perlu mengirimkan dokumen tambahan untuk menyelesaikan akun anda.',
    },
    shufti: {
      content:
        'Harap pastikan informasi yang diberikan sesuai dengan dokumen identitas Anda, dan dalam bahasa yang sama.',
      countryOfDocument: 'Negara sebagaimana tercantum pada dokumen',
    },
    popup: {
      header:
        'Halo {name}, sebelum akun live anda dapat diselesaikan, anda harus mengunggah dokumen berikut untuk verifikasi.',
      addressProofSpan:
        ' <strong>\n        <b>Konfirmasi Alamat</b>\n        </strong>\n        <br />\n        <I>\n          Kirim <b>satu</b> dari dokumen berikut\n          <br /><br />\n        </I>\n        - Salinan dari tagihan terkini </br>(tidak lebih dari 6 bulan)\n        <br />\n        <b class="or">atau</b><br />\n        - salinan dari rekening koran terbaru </br> (tidak lebih dari 6 bulan)\n        <br />\n        <br />\n        <b>PENTING !</b> Nama dalam dokumen HARUS dengan jelas menunjukan nama lengkap dan alamat anda saat ini.\n        <br />',
      idProofSpan:
        '<strong>\n        <b>Konfirmasi Tanda Pengenal</b>\n        </strong>\n        <br />\n        <I>Pilih\n          <b>Satu</b> dari pilihan berikut\n          <br />\n          <br />\n        </I>\n        - Salinan Paspor yang Sah<br />\n\n        - Salinan dari Surat Izin Mengemudi yang Sah\n        <br />\n        (Depan dan Belakang)\n        <br />\n        <b class=or">atau</b><br />\n        - Salinan dari Kartu Tanda Penduduk\n        <br />\n        <br />\n        <b>PENTING !</b> Nama dalam dokumen HARUS dengan jelas menunjukan nama lengkap dan alamat anda saat ini.<br />',
    },
    reason: 'Dokumentasi anda tidak dapat diverifikasi, <br> Alasan: {reason}. Silahkan coba lagi.',
  },
  header: { secPortal: 'Aman Area Klien' },
  menu: {
    home: 'BERanda',
    accManagement: 'AKUN',
    liveAcc: 'AKUN LIVE',
    openAdditAcc: 'BUKA AKUN TAMBAHAN',
    openAnAdditLiveAcc: 'BUKA AKUN LIVE TAMBAHAN',
    homeDemo: 'AKUN DEMO',
    depositFund: 'SETOR DANA',
    withdrawFunds: 'TARIK DANA',
    transferBetweenAccs: 'TRANSFER ANTAR AKUN',
    payDetails: 'RINCIAN PEMBAYARAN',
    funds: 'DANA',
    transactionHis: 'RIWAYAT TRANSAKSI',
    tranHis: 'TRANSFER',
    download: 'UNDUH',
    profile: 'PROFIL',
    myProfile: 'PROFIL SAYA',
    cpPassword: 'GANTI PASSWORD PORTAL KLIEN',
    contactUs: 'HUBUNGI KAMI',
    helpSupport: 'FORMULIR BANTUAN & DUKUNGAN',
    register: 'LANJUTKAN APLIKASI',
    tradingTools: 'ALATAN PROTRADER',
    MARKET_BUZZ: 'MARKET BUZZ ',
    ECON_CALENDAR: 'KALENDER EKONOMI',
    FEAT_FX: 'IDE-IDE PILIHAN',
    FX_IDEAS: 'Pandangan Teknikal',
    ALPHA_GENERATION: 'ALPHA GENERATION',
    MARKET_MASTERS: 'MARKET MASTERS',
    marketWidgets: 'Widget Pasar',
    technicalAnalysis: 'TEKNIKAL ANALISIS',
    techAnalysisTab: {
      forex: 'FOREX',
      indices: 'INDEKS',
      commodities: 'KOMODITI',
      sharedcfds: 'CFD SAHAM',
      crypto: 'KRIPTO',
    },
    marketData: 'data pasar',
    forexCrossRates: 'kurs lintas forex',
    forexHeatMap: 'forex heat map',
    forexScreener: 'forex screener',
    referAFriend: 'Undang Teman',
    wholesale: 'TINGKATKAN KE PRO',
    tradingView: 'BAGAN oleh TradingView',
    twoFactorAuthentication: 'AUTENTIKASI DUA FAKTOR',
    promotion: 'PROMOSI',
    depositBonus: 'Bonus Deposit 50%',
    hmcTrader: 'Ruang Trading Live',
    support: 'Layanan Dukungan',
    securityManagement: 'Manajemen Keamanan',
  },
  contact: {
    desc: 'Jika anda memiliki pertanyaan atau menginginkan informasi lebih lanjut, silakan hubungi kami melalui salah satu detail kontak di bawah ini. Kami berusaha untuk menanggapi semua pertanyaan dalam waktu 24 jam.',
    tip: 'Untuk klien yang bergabung melalui {email}',
    liveChat: 'Berbicara Langsung',
    helpSupportForm: {
      partner: 'Formulir Transfer Kemitraan',
      investigation: 'Formulir Investigasi Trading Moneta',
      promotion: 'Keluar dari Formulir Promosi Moneta',
      request: 'Formulir Permintaan Penutupan Akun',
    },
    accountManager: 'Manajer Akun - {userName}',
    monetaSupport: 'Layanan Dukungan Moneta',
  },
  depositResultDialog: { msg: 'Sudahkah anda berhasil menyelesaikan setoran anda' },
  dialog: {
    identityProof: {
      success:
        'Terima kasih. Kami telah berhasil menerima dokumen anda dan sedang memprosesnya untuk verifikasi.',
    },
  },
  notification: {
    pendingIdentityProof: {
      header: 'PENGOLAHAN APLIKASI AKUN',
      id: 'Tanda Pengenal adalah',
      poa: 'Alamatnya adalah',
      both: 'Tanda Pengenal dan Bukti alamat adalah',
      content:
        'Bukti {doc} anda saat ini sedang diproses dan akan segera diselesaikan. Untuk mempercepat pemrosesan akun live anda, anda dapat mendanai akun anda sekarang dan menerima prioritas pembukaan.',
    },
  },
  idPoa: {
    IdPoaNoPass: {
      header: 'Aplikasi akun belum tuntas ',
      content:
        'Untuk mulai trading, silakan unggah dokumen Bukti Identitas dan Bukti Alamat Anda. Setelah dikirimkan, Danai akun Anda untuk pembukaan akun prioritas.',
    },
    onlyIdPass: {
      header: 'Terima kasih!',
      content:
        'Aplikasi Anda sedang diproses. Danai akun Anda sekarang untuk pembukaan akun prioritas.',
    },
    onlyPoaPass: {
      header: 'Aplikasi akun belum tuntas ',
      content:
        'Untuk mulai trading, silakan unggah dokumen Bukti Identitas Anda. Setelah dikirimkan, Danai akun Anda untuk pembukaan akun prioritas.',
    },
    bothIdPoaPass: {
      header: 'Terima kasih!',
      content:
        'Aplikasi Anda sedang diproses. Danai akun Anda sekarang untuk pembukaan akun prioritas.',
    },
    uploadID: 'Upload ID',
    uploadPoa: 'Unggah Alamat',
    depositNow: 'Deposit Sekarang',
  },
  register: {
    progressStatus: {
      title: 'Kemajuan verifikasi',
      estimatedTimeTitle: 'Perkiraan Waktu untuk Menyelesaikan:',
      min: 'menit',
    },
    tabs: {
      personalDetails: 'Data pribadi',
      moreAboutYou: 'Lebih Banyak Tentang anda',
      accountConfig: 'Konfigurasi Akun',
      confirmYourId: 'Konfirmasikan Tanda Pengenal anda',
      fundYourAcc: 'Danai Akun anda',
    },
    btn: {
      next: 'LANJUT',
      back: 'KEMBALI',
      fundnow: 'DANAI SEKARANG',
      upload: 'Unggah',
      done: 'SELESAI',
      uploadNew: 'Unggah Sekarang',
    },
    authority: {
      dialog1: 'anda harus memiliki akun live untuk mengakses bagian portal klien ini.',
      dialog2:
        'Buka akun live sekarang untuk membuka akses penuh ke portal klien anda, yang mencakup berbagai alat perdagangan, promosi, dan banyak lagi.',
      openLiveAcc: 'buka akun live',
    },
    demo: {
      congrad: 'Selamat!',
      title: 'Akun DEMO MT{platform} 30 Hari anda sekarang sudah aktif',
      title2: 'Detail Akun DEMO anda',
      li: {
        login: 'Login:',
        password: 'Password:',
        srv: 'Server:',
        expDate: 'Tanggal kedaluwarsa:',
      },
      emailSent: '* Detail login juga telah dikirim ke email anda yang terdaftar.',
      dl: 'Unduh Platform Trading MT{platform} anda',
      proTraderPlatform: {
        content:
          'Login ke Platform PRO Trader generasi mutakhir kami menggunakan informasi akun MT{platform} Anda:',
      },
      appTrader: {
        content:
          'Masuk ke AppTrader Moneta Markets menggunakan login Portal Klien Anda. Dapatkan AppTrader untuk iOS atau Android sekarang!',
      },
      improveQ: 'Apakah anda ingin meningkatkan trading anda?',
      improveWay:
        'Cukup, buka akun live hanya dalam beberapa menit untuk membuka berbagai alat trading dan promosi eksklusif.',
      proTrade: 'Alat Pro Trader',
      depositBonus: 'Bonus Deposit 50%',
      smartTradeTool: 'Alat Smart Trader',
      openLiveAcc: 'buka akun live',
      poweredBy: 'Didukung oleh',
    },
    personalDetails: {
      page1: {
        pageTitle: 'Data pribadi',
        userTitle: 'Status',
        firstName: 'Nama depan',
        lastName: 'Nama keluarga',
        email: 'E-mail',
        dob: 'Tanggal Lahir',
        idType: 'Jenis Identifikasi',
        referral: 'Apakah anda pernah dirujuk oleh seseorang? (pilihan)',
        middleName: 'Nama tengah',
        nationality: 'Kewarganegaraan',
        phoneNum: 'Nomor Telepon',
        date: 'Hari',
        month: 'Bulan',
        year: 'Tahun',
        placeOfBirth: 'Tempat Lahir',
        idNum: 'Nomor identifikasi',
        passportNum: 'Nomor paspor',
        driverLicenseNum: 'Nomor Surat Izin Mengemudi',
        titleDropdown: { mr: 'Mr', mrs: 'Mrs', ms: 'Ms', miss: 'Miss', dr: 'Dr', prof: 'Prof' },
        idTypeDropdown: {
          passport: 'Paspor',
          idCard: 'Kartu Tanda Penduduk',
          driverLicense: 'SIM',
        },
      },
      page2: {
        pageTitle: 'ALAMAT RESIDENSIAL UTAMA',
        pageTitleForFCA: 'DETIL TAMBAHAN',
        mainResidency: 'Negara tempat tinggal ',
        streetNumber: 'Nomor jalan',
        streetName: 'Nama jalan',
        address: 'Alamat',
        provinceOrState: 'Provinsi / Negara Bagian',
        cityOrSuburb: 'Kota / Kabupaten',
        postcode: 'Kode Pos',
        previousCountry: 'Negara Sebelumnya',
        usQuestion:
          'Apakah anda Warga Negara / Penduduk AS untuk tujuan pajak atau apakah anda memiliki tautan / ikatan dengan AS (mis. Rekening Bank, dll.)',
        taxResidencyCountry: 'Negara domisili pajak',
        nationalInsuranceNumber: 'Nomor Asuransi Nasional (BPJS)',
        yearsAtAddress: 'Telah tinggal pada alamat tersebut selama berapa tahun',
        previousResidentialAddressTip:
          'Silakan masukkan alamat tempat tinggal anda sebelumnya di bawah ini',
      },
    },
    moreAboutYou: {
      pageTitle1: 'STATUS PEKERJAAN DAN RINCIAN KEUANGAN',
      pageTitle2: 'Pengetahuan dan Pengalaman Trading',
      complianceExplain:
        'Pertanyaan-pertanyaan berikut dikumpulkan untuk memenuhi persyaratan AML / CTF.',
      FCAQuestion10Tip:
        'Dalam 12 bulan terakhir, berapa banyak trading, rata-rata, yang telah anda lakukan dalam instrumen berikut per kuartal?',
    },
    accountConfig: {
      pageTitle: 'Konfigurasi Akun',
      chooseTradingPlatform: 'Pilih Platform Trading',
      chooseAccType: 'Pilih Jenis Akun',
      chooseAccCurrency: 'Pilih Mata Uang Akun',
    },
    confirmYourId: {
      pageTitle: 'Konfirmasikan Tanda Pengenal anda',
      description:
        'Sebagai broker forex teregulasi, kami diwajibkan untuk memverifikasi identitas anda. Harap unggah salah satu dokumen berikut untuk verifikasi Bukti KTP dan Bukti Alamat anda. Atau, anda dapat mengirimkan dokumen verifikasi anda melalui email ke {mailTo}',
      poidTitle: 'Bukti identitas',
      porTitle: 'Bukti Tempat Tinggal',
      uploadDescription: 'Pilih jenis dokumen dan unggah yang sesuai',
      note: 'PENTING: Nama di dokumen HARUS menunjukkan nama lengkap anda dengan jelas.',
      pending: { upload: 'Unggahan Tertunda', verification: 'Verifikasi Tertunda' },
      poidTypes: {
        photoId: 'foto ID',
        passport: 'Salinan paspor internasional anda (Halaman Tanda Tangan)',
        drivingLicence: 'Surat ijin Mengemudi',
      },
      porTypes: '<li>Tagihan Utilitas</li><li>Laporan Bank</li><li>Sertifikat Tempat Tinggal</li>',
    },
    finishPage: {
      title: '{individualUserName}, terima kasih telah mendaftar untuk akun live {platformName}!',
      contentDefault:
        'Kami telah menerima aplikasi anda dan menyiapkan akun live anda <b> {account} </b>. <br />\n      anda akan segera menerima email di <b> {email} </b> yang berisi detail login anda dan link untuk melihat status akun anda. <br />',
      contentWithoutId3WithAccount:
        'Akun perdagangan langsung anda saat ini sedang diproses dan anda akan segera menerima email yang berisi informasi lebih lanjut. <br />\n      Untuk mempercepat pemrosesan akun live anda, anda dapat mendanai akun anda sekarang dan menerima pembukaan prioritas. <br />',
      contentWithoutId3WithoutAccount:
        'Akun perdagangan langsung anda saat ini sedang diproses dan anda akan segera menerima email yang berisi informasi lebih lanjut. <br />',
    },
  },
  tradingTools: {
    MARKET_BUZZ: 'Akses liputan berita dan konten media yang mencakup 35.000+ aset trading.',
    FEAT_FX:
      'Lihat strategi trading teknikal yang dapat diimplementasikan dan ikuti perkembangannya secara real time.',
    ECON_CALENDAR: 'Nikmati data ekonomi secara real-time sepanjang waktu dari seluruh dunia.',
    FX_IDEAS: 'Lihat ulasan trading teknikal yang memikat dan edukatif pada 80.000 instrumen.',
    ALPHA_GENERATION:
      'Tingkatkan keunggulan trading Anda dan nikmati 3 indikator MT4 dan MT5 generasi lanjutan yang mutakhir.',
    MARKET_MASTERS:
      'Pelajari cara trading layaknya para profesional di lebih dari 100+ video tutorial trading tingkat lanjutan.',
    condition: {
      needDeposit:
        'Untuk menikmati akses ke perangkat paket PRO trader Anda dan video tutorial trading eksklusif, cukup danai akun live Anda dengan $500 atau lebih.',
      congratulations:
        'Selamat! Anda telah siap untuk membuka akses Perangkat Trader Premium Anda!',
      needTnc: 'Harap setujui{link}.',
      link: 'Syarat dan Ketentuan',
      unlock: 'BUKA AKSES PERANGKAT PRO TRADER',
      depositNow: 'DEPOSIT SEKARANG',
      tncAisc: 'Saya setuju untuk mendapatkan akses ke sarana ini.',
    },
    techAna: 'Analisa Teknikal untuk',
    byTradingView: 'oleh TradingView',
    alphaGeneration: {
      intro: 'ALPHA GENERATION UNTUK MT4 DAN MT5',
      introDesc:
        'Tingkatkan keunggulan trading Anda dengan tiga EA premium yang eksklusif untuk Moneta Markets.',
      analystViews: {
        title: 'Pandangan Teknikal',
        desc: 'Tren dan level harga teknikal langsung ditampilkan pada grafik harga Anda.',
      },
      adaptiveCandlesticks: {
        title: 'ADAPTIVE CANDLESTICKS',
        desc: 'Memindai secara real-time 16 pola grafik lilin yang populer.',
      },
      adc: {
        title: 'ADC',
        desc: 'Sinyal masuk dan keluar posisi trading secara jangka pendek untuk arah long maupun short.',
      },
      install: 'BAGAIMANA CARA MENGINSTAL ALPHA GENERATION?',
      installSteps: {
        one: 'Unduh Alpha Generation untuk PC dengan mengklik tombol "Unduh Alpha Generation" di bawah.',
        two: 'Klik dua kali berkas tersebut untuk menjalankannya dan ikuti wahana pandu penginstalan yang akan memandu Anda sepanjang prosesnya dalam bahasa pilihan Anda.',
        three: 'Pilih folder target MT4 atau MT5 Moneta Markets Anda.',
        four: 'Setelah terinstal, buka platform MT4 atau MT5 Anda, dan temukan indikator Trading Central bersama dengan indikator-indikator lain dalam daftar indikator MetaTrader Anda.',
        five: 'Seret indikator ke dalam grafik dan dapatkan analisis trading yang dapat langsung ditindaklanjuti.',
      },
      button: { guid: 'Panduan Pengguna', download: 'Unduh Alpha Generation' },
    },
  },
  tradingView: {
    launch: 'MELUNCURKAN',
    tnc: 'Masuk ke charting TradingView dan platform perdagangan dengan detail login MT {mtServer} Anda. Cari tahu lebih lanjut tentang BAGAN oleh TradingView <a href="https://{regulatorUrl}/platforms/trading-platforms/charts-by-tradingview/">sini</a>.',
    subTitle:
      'Bergabunglah dengan 15 juta investor lain dan mulai berdagang dengan grafik, indikator, dan alat menggambar TradingView.',
    intro:
      'Memperkenalkan BAGAN oleh TradingView. Klien Moneta sekarang dapat menemukan ide perdagangan, tren spot, dan menempatkan perdagangan langsung melalui platform TradingView bawaan kami, BAGAN.',
    descp1: '100+ indikator teknis',
    descp2: 'Pembaruan berita pasar langsung',
    descp3: '50+ alat menggambar cerdas',
    descp4: 'Analisis multi-kerangka waktu',
    descp5: '12 kondisi peringatan perdagangan yang berbeda',
    descp6: 'Beragam periode waktu',
    descp7: '50+ sarana menggambar cerdas',
    descp8: '20+ rancangan grafik',
    descp9: '12+ tipe grafik yang mudah disesuaikan',
    descp10: 'Liputan berita pasar terintegrasi',
    learnMore: 'Belajarlah lagi',
    tnc02:
      'Gunakan informasi login MT{mtServer} Anda dan nikmati platform web Protrader sekarang juga. {findMore}',
    tnc03: 'Cari Tahu Selengkapnya',
    subTitle02:
      'Para pengguna platform Protrader Moneta kini memiliki akses ke grafik harga live, indikator teknikal, dan sarana menggambar, semuanya didukung oleh TradingView; di lebih dari 300 pasangan FX, Indeks, Komoditas, CFD Saham, dan banyak lagi.',
    intro02: 'Dapatkan akses ke:',
    intro03: 'Didukung oleh',
  },
  TFAuth: {
    title: 'AUTENTIKASI DUA FAKTOR',
    enable: {
      enableTitle: 'AKTIFKAN AUTENTIKASI DUA FAKTOR',
      enableDesc:
        'Jaga keamanan akun Anda dengan mengaktifkan autentikasi mobile dua faktor. Autentikasi dua faktor memberikan ketenangan pikiran dan lapisan keamanan tambahan melalui verifikasi dua tahap setiap kali Anda log masuk. Setelah diaktifkan, Anda dapat memperoleh kode verifikasi dua faktor dari aplikasi di iOS atau Android Anda kapan saja, kirim kodenya dan log masuk dengan aman.',
      enableSubtitle: 'Cara mengaktifkan autentikasi dua faktor?',
      step1: 'Unduh dan instal Google Authenticator gratis dari App Store iOS atau Android.',
      step2: 'Dari dalam aplikasi ini, pilih "Scan Barcode" dan pindai kode batang di bawah ini.',
      step3: 'Masukkan kode numerik di aplikasi (diperbarui setiap 30 detik) di bawah ini.',
      placeHolder: 'kode',
    },
    disable: {
      disableTitle: 'MENONAKTIFKAN AUTENTIKASI DUA FAKTOR',
      disableDesc:
        'Untuk menonaktifkan autentikasi dua faktor, kirimkan kode numerik dari aplikasi Google Authenticator Anda di sini.',
    },
    dialog: {
      title: 'Silakan masukkan kode autentikasi dua faktor Anda',
      contact: 'Mengalami kesulitan saat masuk? {link}',
      link: 'Hubungi kami.',
    },
    popup: {
      popup1: 'Autentikasi dua faktor diaktifkan.',
      popup2: 'Autentikasi dua faktor dinonaktifkan.',
    },
  },
  promotion: {
    depositBonus: {
      depositBonus: 'Deposit dan klaim bonus trading Anda',
      title: 'Klaim bonusnya & tingkatkan deposit Anda!',
      subtitle1:
        'Ingin merasakan trading yang lebih dahsyat? Apa yang perlu dilakukan adalah mendanai akun live Anda. Setelah kami menerima deposit Anda, bonus deposit akan muncul di akun trading Anda.',
      fund: 'Danai akun Anda',
      tnc: '*{link}',
      link: 'Syarat dan ketentuan berlaku. ',
      bonusHistory: 'Riwayat Bonus',
      overBonus:
        '* Anda telah mencapai bonus kredit maksimum ${totalLimit} Deposit tambahan tidak akan memenuhi syarat untuk memperoleh bonus kredit.',
      opt: 'Apakah Anda ingin mengklaim bonus deposit?',
      tick: 'Harap centang kotak bila Anda ingin ikut serta dalam promosi bonus deposit.',
      tick2:
        'Moneta berhak mengecualikan dan/atau membatalkan keikutsertaan klien dalam program penawaran atau promosinya bila:<br><br> a) Churning (membuka dan menutup transaksi Margin FX atau CFD hanya untuk memanfaatkan program promosi semata);<br> b) klien ditemukan melanggar Perjanjian Klien atau telah melanggar syarat dan ketentuan promosi.<br><br> Dalam hal tindakan yang tidak pantas atau pelanggaran tersebut, Moneta berhak untuk mengurangi jumlah bonus dan keuntungan yang diperoleh dari akun trading.\n      <br><br>Bila perusahaan mencurigai atau memiliki cukup alasan untuk meyakini bahwa klien telah menyalahgunakan dan/atau memanipulasi syarat dan ketentuan skema bonus ini dengan melakukan lindung nilai posisinya secara internal (menggunakan akun trading lain yang dimilikinya dalam Perusahaan) atau eksternal (menggunakan akun trading lain yang dimiliki oleh broker lain) dan/atau bertindak dengan itikad tidak baik, perusahaan berhak, dengan kebijakan mutlak dan tanpa memperoleh persetujuan klien, untuk menghapus jumlah bonus dan keuntungan yang diperoleh dari akun trading dengan segera.\n      <br><br>Moneta setiap saat dapat membuat perubahan pada syarat dan ketentuan ini dan akan menginformasikan Anda tentang perubahan ini dengan mempublikasikan ketentuan yang diubah di situs web Moneta. Anda disarankan untuk meninjau Ketentuan ini secara berkala, dan dengan terus menggunakan situs web dan layanan Moneta, maka Anda telah menerima perubahan ketentuan tersebut. Moneta berhak untuk mengubah atau membatalkan penawaran apa pun atas kebijakannya sendiri kapan saja.',
      saveChange: 'Simpan Perubahan',
      creditReceived: 'Kredit Diterima',
      welcomeBonusTable: 'Bonus Selamat Datang',
      depositBonusTable: 'Bonus Deposit',
      depositDate: 'Tanggal Deposit',
      credit: 'Kredit',
      optIn: 'Berhasil Ikut',
      optOut: 'Berhasil Tidak Ikut',
      notification:
        '<div>Anda berhak ikut dalam program Bonus Deposit. Silakan pilih \'<a href="{depositBonusLink}">ikut serta</a>\' sebelum menyetorkan deposit untuk meraih bonus deposit. Temukan selengkapnya di <a href="{tncLink}" target="_blank">sini</a>.</div>',
      optInButton: 'ikut serta',
      dontShowAgain: 'Jangan tampilkan lagi pesan ini',
    },
    referAFriend: {
      congratulations:
        'Selamat! Anda berhasil bergabung dalam promosi Undang Teman sebagai pengundang.',
      redeem: 'Tebus',
      redeemed: 'Ditebus',
      dayRemain: 'hari lagi berakhir',
      voucherDesc: 'Voucher dapat ditebus setelah Anda trading ≥ {lots} lot',
      title:
        'Undang teman dan raih penghasilan hingga ${amount} dan ${secAmount} untuk teman Anda!',
      desc: 'Ketika undangan Anda menuntaskan langkah-langkah berikut ini dalam 30 hari, Anda akan memperoleh penghasilan hingga ${amount}! Dan, tanpa ada batasan berapa banyak teman yang boleh Anda undang dan referensikan! *<a href="{tncLink}">Syarat dan ketentuan berlaku.</a>',
      register: 'Daftar bersama kami',
      forYourFriend: 'untuk teman Anda',
      liveAcc: 'Buka akun live',
      makeDeposit: 'Lakukan Deposit',
      trade1Lot: 'Trading 1 Lot',
      trade5Lots: 'Trading 5 Lot',
      totalEarning: ' Total Penghasilan',
      referralAct: 'Aktivitas Referal',
      referralName: 'Nama Referal',
      registered: 'Terdaftar',
      accOpen: 'Akun Dibuka',
      madeDeposit: 'Deposit Disetor',
      redeemStatus: 'Status Penebusan',
      notApplicable: 'Tidak berlaku',
      country: 'Negara',
      acc: 'AKUN',
      share: 'BAGIKAN',
      copyLink: 'Salin tautan',
      redeemYourbonus: 'Tebus bonus Anda',
      tatolAmtConvert:
        'Jumlah total yang dikonversi ke mata uang dasar Anda adalah {convertAmt} {currency}',
      remark: '*Dengan klik tebus sekarang, Anda telah menyetujui syarat dan ketentuan',
      redeemNow: 'Tebus Sekarang',
      overlimit: 'Melewati Limit',
      newUser: 'Penggunaan Baru',
      tradeCash: 'Trading Uang Kontan',
    },
    cashback: {
      button: {
        applyForBonus: 'APPLY FOR BONUS',
        redeemCashback: 'TUKARKAN CASHBACK',
        howItWorks: 'BAGAIMANA CARANYA?',
        depositNow: 'DEPOSIT SEKARANG',
        stp: 'DIRECT STP',
        ecn: 'PRIME ECN',
        agree: 'SETUJU',
        cancel: 'CANCEL',
      },
      title: {
        monetaProgram: 'PROGRAM CASHBACK MONETA MARKETS',
        claim: 'CLAIM 50% CASHBACK BONUS ANDA SEKARANG',
        howItWorks: 'BERIKUT CARA KERJANYA',
        howMuch: 'BERAPA BANYAK YANG ANDA DAPATKAN DARI PERDAGANGAN?',
        treeSimpleSteps: 'KLAIM BONUS 50% ANDA SEKARANG - DALAM TIGA LANGKAH SEDERHANA',
        depositTradEarn: 'DEPOSI, TRADE,DAPATKAN',
        bonusSummary: 'Ringkasan Bonus Anda',
        historicalEarnings: 'PENDAPATAN SAAT INI & HISTORIS',
        program: 'PROGRAM CASHBACK',
        cashSummary: 'RINGKASAN CASHBACK',
        faqs: 'PERTANYAAN UMUM TENTANG BONUS CASHBACK',
        redeemCashback: 'TUKAR CASHBACK',
        cashbackBalance: 'Saldo Cashback',
      },
      subTitle: {
        lowerYourCost: 'Kurangi biaya Anda. Dapatkan uang tunai.',
        bonus: 'BONUS',
        cashback: 'CASHBACK',
        wallet: 'DOMPET',
        availableBonus: 'Bonus yang Tersedia',
        totalBalance: 'Total saldo',
        step1: 'Deposit dana, dan ajukan!',
        step2: 'Mulai berdagang!',
        step3: 'Klaim cashback Anda',
        FX: 'FOREX',
        XAU: 'EMAS',
        OIL: 'MINYAK',
        perLot: 'per lot',
        seetheBonusStatus: 'Lihat kolom STATUS BONUS untuk MENERAPKAN bonus yang dapat digunakan',
        tradeFxOilAndGold:
          'Dagang FX, Minyak, dan Emas untuk mengubah BONUS Anda menjadi CASHBACK!',
        totalCashback: 'Total cashback',
        FebruaryCashback: 'Cashback tertunda',
        cashbackRedeemable: 'Cashback dapat ditukar',
        account: 'Akun',
        amount: 'Jumlah',
      },
      information: {
        availableBonus1:
          'Available Bonus adalah jumlah Bonus Kredit yang Anda peroleh dari semua deposit yang memenuhi syarat. Untuk setiap deposit sebesar $500 atau lebih, Anda akan menerima 50% dari jumlah tersebut sebagai Bonus Kredit.',
        total:
          'Total adalah jumlah Saldo Keseluruhan, yaitu gabungan dari jumlah cashback yang dapat ditukar dan jumlah cashback bulan ini.',
        availableBonus2:
          'Bonus yang tersedia adalah jumlah Bonus Kredit dari semua deposit yang memenuhi syarat, yang belum diubah dan ditransfer ke Dompet Cashback Anda.',
        totalCashback:
          'Total Cashback adalah jumlah Bonus Kredit yang telah diubah menjadi uang tunai.',
        februaryCashback:
          'Cashback bulan Februari adalah Bonus Kredit bulan ini yang telah diubah menjadi uang tunai. Anda dapat mentransfernya ke akun trading Anda pada awal bulan depan.',
        cashbackRedeemable:
          'Cashback yang dapat ditukar adalah jumlah Kredit yang telah diubah menjadi uang tunai hingga akhir bulan lalu. Anda dapat mentransfer jumlah ini ke akun trading Anda kapan saja.',
        selectTheTradingAccount: 'Pilih akun trading yang ingin Anda transfer cashback Anda.',
        enterTheAmount: 'Jumlah (USD) yang ingin Anda transfer ke akun trading Anda.',
      },
      content: {
        claimContent1:
          'Sekarang, setiap kali Anda mendanai akun live Anda dengan $500 atau lebih, kami akan memberi Anda bonus 50%, yang akan muncul di Dompet Bonus di halaman utama Portal Klien Anda! Selanjutnya, ketika Anda melakukan perdagangan FX, Emas, atau Minyak, kredit bonus Anda akan diubah menjadi uang tunai sungguhan, yang ditampilkan di Dompet Cashback! Pada awal setiap bulan, dana di Dompet Cashback Anda dapat ditransfer ke akun live trading Anda untuk digunakan sebagai dana sungguhan!',
        claimContent2:
          'Yang terbaik dari semuanya, untuk setiap deposit yang Anda lakukan sebesar $500 atau lebih di masa depan, bonus Anda akan otomatis dikreditkan ke Dompet Bonus Anda, sehingga Anda dapat terus menghasilkan lebih banyak.',
        howItWorksContent1:
          'Mendanai akun live Anda dengan $500 atau lebih, kemudian klik "Apply" dan setujui syarat dan ketentuan untuk menerima bonus 50% Anda',
        howItWorksContent2:
          'Ketika Anda melakukan perdagangan FX, Emas, atau Minyak, kredit bonus Anda akan otomatis diubah menjadi uang tunai yang dapat diperdagangkan, yang dapat Anda lacak di "Dompet Cashback" Anda!',
        howItWorksContent3:
          'Pada awal setiap bulan baru, cukup klik "Redeem Cashback" di "Dompet Cashback" Anda dan tukarkan jumlah yang ditampilkan sebagai "Cashback yang Dapat Ditukar".',
        stepsContent1: 'Deposit $500 atau lebih dan ajukan bonus Anda di bawah ini',
        stepsContent2:
          'Mulailah berdagang FX, Emas, dan Minyak untuk mengubah bonus Anda menjadi uang tunai sungguhan!',
        stepsContent3: 'Klaim cashback Anda dari aktivitas perdagangan bulan sebelumnya!',
        depositTradEarnContent1: 'Semakin banyak Anda berdagang, semakin banyak Anda menghasilkan.',
        depositTradEarnContent2:
          'Ketika Anda melakukan deposit sebesar $500 atau lebih, Anda dapat mengklaim bonus cashback 50%!',
        optInContent1:
          'Untuk berpartisipasi dalam Promosi Cashback Moneta Markets, Anda harus menyetujui syarat dan ketentuan di bawah ini.',
        electTheDestinationAccount: 'Pilih akun tujuan untuk ditukar',
      },
      column: {
        date: 'TANGGAL',
        deposit: 'DEPOSIT',
        withdraws: 'PENARIKAN',
        bonus: 'BONUS YANG DAPAT DIKLAIM',
        status: 'STATUS BONUS',
        account: 'AKUN',
        redeem: 'CASHBACK YANG TELAH DITUKAR',
        reason: 'CATATAN',
      },
      tnc: {
        title: 'Syarat dan Ketentuan',
        content: {
          item1: {
            title: 'Syarat dan Ketentuan Cashback',
            li1: 'Moneta Markets adalah nama perdagangan dari Moneta Markets Ltd, terdaftar di bawah Saint Lucia Registry of International Business Companies dengan nomor registrasi 2023-00068.',
            li2: '"Syarat dan Ketentuan Bonus Cashback" adalah bagian integral dari "Syarat dan Ketentuan Moneta Markets Ltd" dan ketentuan yang ditetapkan di sini dianggap sebagai lampiran dari semua "Syarat dan Ketentuan Moneta Markets Ltd" dan harus dibaca secara bersamaan.',
          },
          item2: {
            title: 'Kondisi Kelayakan.',
            li1: 'Tunduk pada, dan tanpa mengabaikan, semua Syarat dan Ketentuan lainnya, penawaran ini tersedia bagi Klien Moneta Markets yang telah memenuhi Kriteria Kelayakan untuk penawaran ini ("Klien yang Memenuhi Syarat") sebagaimana diuraikan dalam bagian berikutnya.',
            li2: 'Moneta Markets menawarkan "Bonus Cashback" kepada klien yang membuka akun perdagangan live (bukan akun demo) dengan Moneta Markets.',
            li3: '"Bonus Cashback" hanya tersedia untuk Akun Direct STP dan Prime ECN. Akun PAMM, MAM, Ultra ECN dan "Cent" tidak memenuhi syarat untuk berpartisipasi dalam promosi "Bonus Cashback".',
            li4: 'Moneta Markets, atas kebijakannya sendiri, menawarkan "Bonus Cashback" kepada Klien dari negara/wilayah manapun, sesuai dengan pertimbangannya, dan selama Moneta Markets menilai perlu untuk memberikannya. Perusahaan berhak untuk menarik kembali dan/atau membatalkan kelayakan berdasarkan pembatasan dan/atau persyaratan lokal.',
            li5: 'Hanya orang-orang yang dapat membuat kontrak yang mengikat secara hukum di bawah hukum yang berlaku di negara tempat tinggal mereka yang boleh berpartisipasi dalam "Bonus Cashback". Tanpa mengurangi ketentuan sebelumnya, partisipasi dalam "Bonus Cashback" tidak diizinkan bagi orang di bawah usia 18 tahun ("Anak di Bawah Umur"), karena mereka tidak memenuhi syarat untuk membuka akun dengan Perusahaan.',
            li6: 'Partisipasi "Perantara/Pihak yang Berhubungan" dalam "Bonus Cashback" dilarang. Jika data pendaftaran dan/atau perdagangan seorang peserta dalam "Bonus Cashback" sesuai dengan informasi pendaftaran dan/atau perdagangan, termasuk namun tidak terbatas pada alamat IP, dari peserta lain dalam "Bonus Cashback", Moneta Markets berhak untuk menganggap kesesuaian ini sebagai alasan diskualifikasi segera. Untuk maksud dalam hal ini, istilah "Perantara/Pihak yang Berhubungan", ketika digunakan dalam Syarat & Ketentuan ini, kecuali konteks lainnya, mencakup setiap orang atau entitas yang memiliki hubungan dengan peserta dalam "Bonus Cashback", termasuk, tanpa batasan:',
            li6_sub_items: {
              li1: 'anggota keluarga, seperti saudara kandung, pasangan hidup, leluhur, keturunan garis lurus, dan keturunan kolateral;',
              li2: 'orang atau entitas yang, langsung atau tidak langsung melalui satu atau lebih perantara, dikendalikan oleh peserta dalam "Bonus Cashback", atau yang, langsung atau tidak langsung, melalui satu atau lebih perantara, mengendalikan peserta dalam "Bonus Cashback"; untuk maksud definisi ini, istilah "kontrol" (termasuk, dengan makna korelatif, istilah "dikendalikan oleh" dan "dalam pengendalian bersama dengan"), yang digunakan dalam kaitannya dengan peserta dalam "Bonus Cashback", berarti kepemilikan, langsung atau tidak langsung melalui satu atau lebih perantara, atas kekuatan untuk mengarahkan atau menyebabkan arah kebijakan manajemen dari orang atau entitas tersebut, baik melalui kepemilikan surat berharga yang memberikan hak suara atau sebaliknya.',
              li3: 'Demikian pula, orang yang terkait dengan cara apa pun dengan Moneta Markets dan/atau dengan situs web khusus dan/atau situs jejaring sosial tempat Moneta Markets dari waktu ke waktu mungkin menjalankan promosi, kontes, dan/atau survei tertentu tertentu, dalam konteks di mana akses ke "Bonus Cashback" ditawarkan, tidak diizinkan untuk berpartisipasi dalam penawaran.',
            },
          },
          item3: {
            title: 'Syarat Khusus "Bonus Cashback"',
            li1: 'Klien harus melakukan deposit pertama sebesar minimal $500, dan kemudian berhasil mengajukan 50% bonus kredit dalam portal klien mereka pertama kali untuk menerima kredit bonus. Kredit bonus dari deposit selanjutnya akan diterapkan secara otomatis.',
            li1_sub_desc:
              '*Hanya deposit yang dilakukan selama periode promosi yang valid dan akan dipertimbangkan untuk promosi ini. Ini tidak termasuk transfer internal, bonus referensi teman, dan penyesuaian tunai.',
            li2: '"Bonus Cashback" hanya tersedia untuk Akun Direct STP dan Prime ECN. Akun Ultra ECN dan "Cent" tidak memenuhi syarat untuk berpartisipasi dalam promosi "Bonus Cashback".',
            li3: 'Klien yang Memenuhi Syarat (klien baru dan klien yang sudah ada), yang memenuhi kriteria yang ditetapkan di sini, dapat menerima Cashback sebagai berikut:',
            li3_sub_content: {
              title1: '[Tabel rebate klien langsung STP dan ECN]',
              info1: 'Direct STP: $2 per lot FX, Oil, Gold yang diperdagangkan',
              info2: 'Prime ECN: $0.50 per lot FX, Oil, Gold yang diperdagangkan.',
              title2: '[Tabel rebate klien di bawah IB STP dan ECN]',
              info3: 'Direct STP: $0.50 per lot FX, Oil, Gold yang diperdagangkan',
              info4: 'Prime ECN: $0.25 per lot FX, Oil, Gold yang diperdagangkan.',
            },
            li3_sub_desc:
              '*Jumlah lot trading minimum yang harus terkumpul agar cashback dapat diaktifkan adalah 0,01 lot standar. Jika total lot trading yang valid pada hari itu tidak mencapai 0,1 lot standar, maka total lot trading akan diatur kembali menjadi nol. Jumlah lot kumulatif adalah kelipatan dari 0,01 lot standar, misalnya 0,02 lot standar, 0,03 lot standar (0,12 lot standar akan dihitung sebagai 0,12 lot standar).',
            li4: 'Produk-produk yang memenuhi syarat: Semua produk CFD Forex, Emas, dan Minyak',
            li5: 'Volume perdagangan yang efektif: Order yang baru dibuka dan ditutup selama periode promosi. Posisi yang terbuka selama kurang dari 10 menit, posisi terkunci, dan posisi lindung nilai tidak dihitung sebagai perdagangan yang valid untuk promosi ini.',
            li6: 'Penawaran ini dapat digunakan bersamaan dengan penawaran lain.',
            li7: 'Penarikan dana akan mengakibatkan penghapusan jumlah kredit yang sama dari Dompet Bonus pengguna. Jika jumlah penarikan melebihi jumlah Kredit Bonus, jumlah Kredit akan dikurangi menjadi nol.',
            li8: 'Moneta Markets tidak akan bertanggung jawab atas biaya, pengeluaran, atau kerusakan yang mungkin diderita akibat Moneta Markets mengatur ulang atau menyesuaikan akun klien sesuai dengan ketentuan ini, termasuk namun tidak terbatas pada kerugian yang dapat diatribusikan ke Posisi Terbuka yang Ditutup Secara Otomatis.',
            li9: 'Moneta Markets berhak, atas kebijaksanaannya sendiri:',
            li9_sub_items: {
              li1: 'menolak pendaftaran setiap peserta dalam "Bonus Cashback"; dan',
              li2: 'mendiskualifikasi peserta dalam "Bonus Cashback" yang melakukan manipulasi atau melanggar ketentuan ini dan/atau setiap "Ketentuan dan Syarat Moneta Markets" yang tercantum di situs web Moneta Markets.',
            },
            li10: ' Moneta Markets berhak, atas kebijaksanaannya yang wajar, untuk menghentikan penawaran "Bonus Cashback" kepada klien-klien nya, tanpa harus menjelaskan alasannya. Klien-klien tersebut akan diinformasikan melalui email.',
            li11: ' Moneta Markets berhak mengubah, mengamendemen, atau menghentikan "Bonus Cashback" atau aspeknya kapan saja tanpa harus memberi kompensasi kepada siapa pun atas hal itu. Pemberitahuan sebelumnya tentang perubahan tersebut akan diberikan kepada klien-klien. Harap perhatikan bahwa berpartisipasi dalam "Bonus Cashback" merupakan penerimaan dan persetujuan untuk mematuhi setiap perubahan, amandemen, dan/atau perubahan semacam itu.',
            li12: ' Indikasi atau kecurigaan dalam kebijaksanaan Moneta Markets, atas setiap bentuk arbitrase (termasuk namun tidak terbatas pada mendapatkan keuntungan bebas risiko), penyalahgunaan (termasuk namun tidak terbatas pada pola aktivitas perdagangan peserta yang menunjukkan bahwa peserta semata-mata bertujuan untuk mendapatkan keuntungan finansial dari cashback tanpa tertarik secara sungguh-sungguh dalam perdagangan di pasar dan/atau mengambil risiko pasar), penipuan, manipulasi, arbitrase cashback yang terkait dengan promosi cashback atau bentuk kegiatan curang atau penipuan lainnya, akan membatalkan semua cashback sebelumnya dari akun perdagangan nyata Klien yang Memenuhi Syarat dengan Moneta Markets dan/atau transaksi dan/atau laba atau kerugian yang dihasilkan darinya. Dalam keadaan seperti ini, Moneta Markets berhak, atas kebijaksanaannya sendiri untuk menutup/menangguhkan (baik sementara atau permanen) semua akun perdagangan nyata Klien yang Memenuhi Syarat tersebut dengan Moneta Markets, membatalkan semua pesanan dan menghapus semua laba peserta tersebut. Dalam keadaan seperti ini, Moneta Markets tidak bertanggung jawab atas konsekuensi pembatalan cashback, termasuk, namun tidak terbatas pada, penutupan pesanan oleh Stop Out.',
            li13: ' Berpartisipasi dalam "Bonus Cashback" merupakan penerimaan atas Syarat & Ketentuan ini dan Syarat dan Ketentuan Moneta Markets yang tercantum di situs web Moneta Markets.',
            li14: ' "Bonus Cashback" tersedia dalam periode terbatas, sebagaimana diindikasikan dalam peraturan permainan dari promosi khusus tersebut dan/atau survei yang menyebutkan "Bonus Cashback" sebagai bagian dari promosi tersebut.',
            li15: ' Jika Syarat dan Ketentuan "Bonus Cashback" ini diterjemahkan ke dalam bahasa selain bahasa Inggris, versi bahasa Inggris lebih diutamakan dalam kasus interpretasi yang tidak sesuai.',
            li16: 'Bonus Cashback tidak berlaku bagi klien yang tinggal di Turki.',
            li17: 'Hanya deposit sebesar $500 atau lebih sejak tanggal 19 Juli 2023 saja yang memenuhi syarat untuk memperoleh Bonus Cashback.',
          },
          item4: {
            title: 'Peringatan Risiko',
            li1: 'Perdagangan derivatif dan forex membawa risiko tinggi terhadap modal Anda dan hanya boleh diperdagangkan dengan uang yang mampu Anda rugi. Pastikan Anda membaca dokumen di halaman Dokumen Hukum kami dan mencari nasihat independen, untuk sepenuhnya memahami risiko-risiko tersebut sebelum memutuskan untuk berdagang. Promosi ini telah disiapkan tanpa mempertimbangkan tujuan, situasi keuangan, atau kebutuhan Anda.',
          },
        },
      },
      faqsList: {
        question_1: 'Apa itu Program Cashback Moneta Markets, dan bagaimana cara kerjanya?',
        question_2: 'Bagaimana cara mendaftar untuk Promosi Cashback?',
        question_3: 'Apa itu Kredit Bonus?',
        question_4: 'Apa itu Cashback?',
        question_5: 'Bagaimana cara mengecek saldo Bonus dan Cashback saya?',
        question_6: 'Apakah Kredit Bonus saya bisa kadaluarsa?',
        question_7: 'Apakah Saldo Cashback saya bisa kadaluarsa?',
        question_8: 'Apa manfaatnya?',
        question_9: 'Berapa banyak Kredit Bonus yang bisa saya dapatkan?',
        question_10: 'Berapa banyak Cashback yang bisa saya dapatkan?',
        question_11: 'Kapan saya bisa menarik Bonus Cashback saya?',
        question_12: 'Bagaimana cara mentransfer Cashback yang tersedia ke akun trading saya?',
        question_13: 'Berapa sering Cashback saya dihitung?',
        question_14: 'Apakah saya bisa mendapatkan Cashback pada semua produk?',
        question_15: 'Bagaimana cara menarik Bonus Cashback saya?',
        question_16: 'Apakah penarikan akan mempengaruhi Kredit Bonus saya?',
        question_17: 'Apakah penarikan akan mempengaruhi Bonus Cashback saya?',
        question_18:
          'Apakah ada durasi perdagangan minimum untuk mengonversi Bonus saya menjadi Cashback?',
        question_19: 'Bagaimana cara meningkatkan Kredit Bonus saya?',
        question_20: 'Bagaimana cara memaksimalkan Cashback bulanan saya?',
        question_21:
          'Apakah saya mendapatkan jumlah Cashback yang sama pada akun Direct STP dan Prime ECN?',
        question_22: 'Apakah saya bisa mendapatkan Cashback pada akun Ultra ECN atau Cent?',
        question_23: 'Apa itu status bonus dan apa artinya?',
        question_24: 'Bagaimana cara keluar dari Program Cashback?',
        answer_1:
          'Program Cashback Moneta Markets adalah program loyalitas, di mana klien melakukan setoran minimum sebesar $500 untuk menerima jumlah kredit bonus sebesar 50%. Kredit Bonus ini kemudian dikonversi menjadi uang tunai yang dapat ditarik/diperdagangkan berdasarkan aktivitas perdagangan pada produk FX, Emas, dan Minyak.',
        answer_2:
          'Klien Moneta Markets yang memiliki akun trading yang memenuhi syarat harus "mengajukan" jumlah Kredit Bonus pertama mereka dari dalam Portal Klien Moneta Markets. Cukup klik "Ajukan Bonus" di Bonus Wallet pada halaman utama Portal Klien, klik tombol "Ajukan" di tabel yang berjudul "Earnings Saat Ini & Historis Anda", lalu setujui syarat dan ketentuan. Kredit Bonus berikutnya yang diperoleh dari setoran berikutnya akan otomatis diterapkan.',
        answer_3:
          'Kredit Bonus diperoleh dari setoran yang memenuhi syarat sebesar $500 atau lebih, dengan tingkat 50% per setoran yang memenuhi syarat. Kredit Bonus disimpan di Bonus Wallet Anda, dan dikonversi menjadi uang tunai yang sesungguhnya berdasarkan aktivitas perdagangan Anda pada semua produk FX, Emas, dan Minyak.',
        answer_4:
          'Cashback adalah jumlah uang yang diperoleh berdasarkan konversi Kredit Bonus akibat aktivitas perdagangan Anda. Jumlah Cashback Anda dapat ditransfer ke akun trading Anda dan digunakan sebagai uang tunai yang sesungguhnya.',
        answer_5:
          'Anda dapat melihat saldo Anda dari layar utama Portal Klien Anda, di mana Bonus Wallet dan Cashback Wallet Anda ditampilkan. Untuk rincian dari Cashback Wallet Anda, cukup klik "Promosi" - "Cashback", atau "Tukar Cashback" dari "Cashback Wallet" di halaman utama Portal Klien Anda, di mana Anda dapat melihat jumlah total Cashback, Cashback yang dapat ditukar, dan jumlah Cashback bulan ini.',
        answer_6:
          'Tidak, kredit bonus tidak kedaluwarsa. Ketika Anda memilih untuk berpartisipasi dalam Bonus Cashback, semua setoran & penarikan akan dihitung dari 30 hari sebelumnya untuk menghitung kredit bonus yang tersedia Anda. Setiap Kredit Bonus berikutnya yang diperoleh dari setoran berikutnya akan diterapkan secara otomatis dan tidak kedaluwarsa. Anda dapat melihat status Kredit Bonus Saat Ini dan Historis Anda di tabel yang berjudul "Earnings Saat Ini & Historis Anda", yang akan menampilkan "Ajukan", "Dicatat", "Tidak Memenuhi Syarat", atau "Dikurangi".',
        answer_7:
          'Jumlah Bonus Cashback yang diperoleh tidak akan kedaluwarsa dan tetap milik Anda untuk ditransfer ke akun trading Anda untuk perdagangan langsung, atau ditarik pada awal setiap bulan.',
        answer_8:
          'Kami menghargai Anda sebagai klien dan ingin berterima kasih karena berdagang dengan Moneta Markets. Oleh karena itu, Anda akan menerima imbalan dalam bentuk Kredit Bonus pada semua setoran yang memenuhi syarat. Kemudian, saat Anda terus berdagang bersama kami, Kredit Anda dikonversi menjadi uang tunai yang sesungguhnya, sebagai ucapan terima kasih atas kesetiaan Anda.',
        answer_9:
          'Ketika Anda melakukan setoran sebesar $500 atau lebih, Anda akan menerima 50% dari jumlah tersebut dalam bentuk Kredit Bonus. Kemudian, untuk setiap setoran berikutnya yang memenuhi syarat, Anda akan terus mengumpulkan Kredit Bonus.',
        answer_10:
          'Jumlah Cashback yang dapat Anda peroleh tergantung pada jenis akun Anda dan produk yang Anda perdagangkan. Anda dapat memaksimalkan pendapatan Anda dengan berdagang FX, Minyak, dan Emas pada Akun Moneta Markets Direct STP.<br/><br/>Kredit Bonus Anda dikonversi menjadi Cashback dengan tingkat di bawah ini:<br/><br/>[Tabel rabat Direct STP dan Prime ECN]<br/>Direct STP: $2 per lot FX, Minyak, Emas diperdagangkan<br/>Prime ECN: $0,50 per lot FX, Minyak, Emas diperdagangkan',
        answer_11:
          'Anda dapat mentransfer saldo yang ditampilkan sebagai "Cashback yang Tersedia" ke akun trading Anda kapan saja. Sementara itu, Cashback yang diperoleh pada bulan berjalan dapat ditransfer ke akun trading Anda pada awal bulan berikutnya.',
        answer_12:
          'Yang perlu Anda lakukan hanyalah klik "Tukar Cashback" di Cashback Wallet Anda, pilih akun trading dan jumlah Cashback yang ingin Anda transfer, lalu klik "Tukar Cashback" lagi.',
        answer_13:
          'Cashback yang diperoleh dari aktivitas perdagangan pada semua produk FX, Emas, dan Minyak dihitung setiap hari pada GMT+2, dan akan muncul di Cashback Wallet Anda.',
        answer_14:
          'Bonus Cashback dapat diperoleh pada semua produk FX, Emas, dan Minyak pada kedua akun Direct STP dan Prime ECN seperti yang tercantum dalam tabel di bawah ini.<br/><br/>[Tabel rabat Direct STP dan Prime ECN]<br/>Direct STP: $0,50 per lot FX, Minyak, Emas diperdagangkan<br/>Prime ECN: $0,25 per lot FX, Minyak, Emas diperdagangkan.',
        answer_15:
          'Menarik Bonus Cashback yang tersedia sangat mudah. Yang perlu Anda lakukan hanyalah klik "Tukar Cashback" di Cashback Wallet Anda, pilih akun trading dan jumlah Cashback yang ingin Anda transfer, lalu klik "Tukar Cashback" lagi. Setelah dana ditransfer ke akun trading Anda, Anda dapat menggunakannya untuk berdagang atau mengajukan permintaan penarikan untuk diproses.',
        answer_16:
          'Kredit yang ada di Bonus Wallet Anda akan dikurangkan secara proporsional jika Anda menarik dana. Misalnya, jika Bonus Wallet Anda memiliki 500 kredit, dan Anda memilih untuk menarik $250 dari akun trading Anda, maka 250 Kredit Bonus juga akan dikurangkan. Perlu dicatat bahwa Kredit Bonus Anda tidak akan pernah berada dalam saldo negatif, terlepas dari seberapa banyak dana yang Anda pilih untuk ditarik.',
        answer_17:
          'Tidak. Bonus Cashback Anda milik Anda dan tidak akan terpengaruh oleh penarikan apa pun yang Anda lakukan.',
        answer_18:
          'Setiap perdagangan FX, Emas, atau Minyak harus dipertahankan selama minimal 10 menit agar Bonus Anda dikonversi menjadi Cashback.',
        answer_19:
          'Kredit Bonus Anda bertambah setiap kali Anda melakukan setoran yang memenuhi syarat. Untuk setiap setoran sebesar $500 atau lebih, Anda akan menerima 50% dari jumlah tersebut dalam bentuk Kredit Bonus.',
        answer_20:
          'Untuk memaksimalkan jumlah Cashback yang dapat Anda peroleh, tergantung pada dua faktor; jenis akun Anda dan aktivitas perdagangan Anda pada produk FX, Emas, dan Minyak, dengan akun Direct STP dikonversi menjadi Cashback dengan tingkat yang lebih tinggi daripada akun Prime ECN. Pastikan Anda memiliki jumlah kredit yang cukup di Bonus Wallet Anda, dan berdaganglah FX, Emas, dan Minyak pada akun Direct STP untuk memaksimalkan Cashback Anda. Selain itu, semakin banyak perdagangan yang Anda lakukan dan semakin tinggi volume perdagangan yang Anda lakukan, semakin cepat Kredit Bonus Anda akan dikonversi menjadi Cashback.',
        answer_21:
          'Jumlah Cashback yang diperoleh pada FX, Emas, dan Minyak berbeda antara jenis akun.<br/><br/>[Tabel rabat Direct STP dan Prime ECN]<br/>Direct STP: $0,50 per lot FX, Minyak, Emas diperdagangkan<br/>Prime ECN: $0,25 per lot FX, Minyak, Emas diperdagangkan.',
        answer_22:
          'Sayangnya, akun Ultra ECN atau Cent tidak mengakumulasi Cashback dari perdagangan FX, Emas, atau Minyak. Anda harus berdagang dengan akun Direct STP atau Prime ECN.',
        answer_23:
          'Ajukan: Saat pertama kali Anda melakukan setoran yang memenuhi syarat, Anda harus mengklik "Ajukan" untuk menerima bonus Anda. Semua setoran berikutnya akan otomatis dikreditkan.<br/>Dicatat: Bonus telah dikreditkan dan akan muncul di Bonus Wallet Anda<br/>Tidak Memenuhi Syarat: Bonus tidak memenuhi persyaratan setoran minimum sebesar $500<br/>Dikurangi: Bonus telah dikurangi karena penarikan dana dari akun trading Anda.',
        answer_24:
          'Jika Anda memutuskan Anda tidak ingin lagi menjadi bagian dari program ini, silakan kirim permintaan Anda untuk keluar dari program ke <a href="mailto:{email}">{email}</a>',
      },
      reason: {
        1: 'Sebuah bonus sebesar 50% telah ditambahkan ke Bonus Wallet Anda',
        2: 'Penyesuaian bonus manual - Dikreditkan',
        3: 'Jumlah penarikan telah dihapus dari Bonus Wallet Anda',
        4: 'Jumlah bonus ini telah dikonversi menjadi Cashback',
        5: 'Penyesuaian bonus manual - Dikurangi',
        6: 'Setoran ini tidak memenuhi jumlah minimum deposit yang diperlukan',
      },
      status: { 0: 'Ajukan', 1: 'Dikreditkan', 2: 'Dikurangi', 3: 'Tidak Memenuhi Syarat' },
      zeroMsg: 'Anda tidak memiliki Cashback yang tersisa untuk ditukar',
    },
  },
  hmcTraderPortal: {
    overview: 'Ikhtisar',
    interactiveAnalysis: 'Analisis Interaktif',
    learnFromTheBest: 'Belajar dari yang Terbaik',
    requestAResearch: 'Minta Riset',
    vipSupport: 'Dukungan VIP',
    eligibility: 'Kelayakan',
    login: 'Masuk ke HMC Ruang Trading Live',
    depositRequirement:
      'Penuhi persyaratan setoran untuk mendapatkan akses ke HMC Ruang Trading Live',
    basic: 'Dasar',
    vip: 'VIP',
    exclusiveAnalysis: 'Analisis Eksklusif',
    analysisDiscussions: 'Diskusi Analisis',
    tradingSignals: 'Sinyal Trading',
    tradingSignalRequest: 'Permintaan Sinyal Trading',
    privateAnalysisRequest: 'Permintaan Analisis Pribadi',
    prioritySupport: 'Dukungan Prioritas',
    minimumFundingRequirement:
      'Persyaratan Pendanaan Minimum dan trading $200,000 dari notional value pada saat trading ditutup setiap bulan*',
    tnc1: 'Harap diperhatikan bahwa informasi yang ditampilkan dalam <a href="{url}" target="_blank">portal.hmctrader.com</a> disediakan untuk Moneta (‘Moneta Global Limited’, ‘kami’) oleh penyedia pihak ketiga (‘HMC Trader’). Ini sepenuhnya dimaksudkan hanya untuk tujuan penelitian dan informasi serta bukan merupakan nasihat keuangan untuk membeli, menjual, atau menyimpan produk tertentu ataupun mengejar strategi investasi tertentu. Informasi ini tidak disesuaikan dengan kebutuhan investasi individu tertentu dan oleh karena itu, tidak mempertimbangkan tujuan investasi, situasi keuangan, atau kebutuhan siapa pun yang mungkin menerima informasinya. Harap diperhatikan juga bahwa kinerja masa lalu bukanlah indikator yang dapat diandalkan untuk kinerja dan/atau hasil pada masa mendatang. Hasil aktual dapat berbeda secara materi dari yang diharapkan dalam pernyataan pandangan ke depan atau kinerja sebelumnya. Kami tidak bertanggung jawab atas keakuratan atau kelengkapan segala informasi yang diberikan di sini, atau segala kerugian yang mungkin timbul dari informasi apa pun yang disediakan oleh HMC Trader.',
    tnc2: '*Agar memenuhi syarat untuk menggunakan layanan HMC Ruang Trading Live, Anda harus memenuhi persyaratan pendanaan minimum awal yang disebutkan di atas sejak tanggal peluncuran. Anda harus telah memenuhi setidaknya $200,000 notional value pada saat trading ditutup dari produk-produk yang ditawarkan oleh Moneta setiap bulan untuk mempertahankan izin akses di HMC Ruang Trading Live. Izin akses Anda akan dinonaktifkan secara otomatis jika Anda gagal memenuhi setidaknya $200,000 notional value pada saat trading ditutup dalam sebulan. Anda selanjutnya wajib menyetor jumlah pendanaan yang diperlukan untuk mengaktifkan kembali akses pada HMC Ruang Trading Live.',
  },
  supportTickets: {
    support: 'Layanan Dukungan',
    mySupportTickets: 'Tiket Dukungan Saya',
    createNewTicket: 'Buat Tiket Baru',
    ticketNo: 'No. Tiket',
    subject: 'Subyek',
    status: 'Status',
    lastUpdated: 'Pembaruan Terakhir',
    newTicketRequest: 'Buat Permintaan Tiket Baru',
    content: 'Konten',
    attachments: 'Lampiran',
    upload: 'Unggah',
    viewingTicket: 'Melihat Nomor Tiket',
    postReply: 'Kirim balasan',
    update: 'Perbarui',
    cancel: 'Batalkan',
    close: 'Tutup',
    number: 'No.',
    description: 'Keterangan',
    postReplyRequired: 'Kolom Balasan Posting wajib diisi',
    contentFieldRequired: 'Kolom Isi wajib diisi',
    subjectFieldRequired: 'Kolom Subjek wajib diisi',
    successMsg: 'Tiket dukungan Anda telah berhasil dibuat!',
  },
  responseMsg: {
    410: 'Validasi parameter gagal',
    411: 'Kata kunci Salah',
    420: 'Pengguna tidak ditemukan',
    421: 'Setel Ulang Kata Sandi ditolak',
    490: 'Harap jangan mengirimkan informasi kartu secara berganda',
    500: 'Terjadi kesalahan.',
    501: 'Permohonan gagal. Silakan coba lagi nanti.',
    505: 'Gagal mengunggah berkas',
    510: 'Kode SMS salah',
    511: 'Tidak dapat mengirim kode SMS ke ponsel anda',
    520: 'Gagal masuk. Silahkan coba lagi.',
    521: 'Seorang pengguna sudah masuk di perangkat ini!',
    522: 'Silakan login lagi',
    523: 'Silakan login lagi',
    524: 'Mohon maaf, alamat email anda tidak dikenali',
    525: 'Username atau password salah',
    526: 'Recaptcha tidak cocok',
    527: 'Pengguna bukan seorang IB',
    528: 'Pengguna tidak ditemukan',
    529: 'Username atau password salah',
    530: 'Sesi login tidak valid',
    531: 'Kode tidak valid',
    540: 'Akun trading anda tidak dapat ditemukan',
    541: 'Akun Rabat tidak dapat ditemukan',
    542: 'Anda tidak diizinkan untuk membuka akun tambahan',
    544: 'Anda tidak diperbolehkan mendaftarkan akun tambahan hingga Bukti Identitas Anda diverifikasi',
    550: 'Nomor rekening salah',
    551: 'Saldo anda tidak mencukupi',
    554: 'Jumlah yang diminta nol atau null',
    562: 'Kami tidak dapat memproses pembayaran anda, coba lagi. Jika tetap terjadi masalah, silakan hubungi kami melalui obrolan langsung, atau email {email}',
    564: 'Negara tidak sesuai dengan portal pembayaran',
    565: 'Kode Bank Salah',
    566: 'Metode pembayaran tidak ditemukan',
    567: 'Mohon maaf metode pembayaran ini saat ini tidak tersedia. Kami mohon maaf atas ketidaknyamanan yang terjadi',
    568: 'Akun ini telah dibatasi untuk melakukan deposit, harap menghubungi {email}',
    569: 'Nama depan/nama belakang/nomor telepon Anda wajib diisi. Bila Anda membutuhkan bantuan, harap hubungi {depositEmail}',
    581: 'anda telah berhasil mengikuti promosi. Harap dicatat bahwa anda harus melakukan deposit untuk menarik dana.',
    584: 'Anda tidak memiliki rabat yang berlaku',
    587: 'Jumlah penarikan tidak valid.',
    590: 'Akun transfer keluar tidak dapat ditemukan',
    591: 'Akun transfer masuk tidak dapat ditemukan',
    593: 'Kami tidak dapat memproses transfer dana Anda dari akun yang dipilih karena akun Anda mengandung kredit.',
    594: 'Transfer dana dari akun ini tidak diizinkan',
    624: 'Saldo negatif di akun ini tidak dapat dihapus, silakan hubungi support@monetamarkets.com untuk informasi lebih lanjut.',
    625: 'Permohonan Anda sedang dalam proses. Harap hubungi support@monetamarkets.com bila Anda memiliki pertanyaan.',
    626: 'Kami menemukan bahwa saldo Anda lebih besar dari nol, segarkan halaman dan periksa lagi',
    647: 'Kode verifikasi tidak benar',
    1001: 'Akun Anda tidak tersedia untuk sementara ini, harap hubungi manajer akun Anda',
    1102: 'Tidak dapat diaktifkan, akun telah dibuat sebagai akun live atau demo.',
    1103: 'Akun telah dinonaktifkan',
    1202: 'Verifikasi kedaluwarsa, silakan coba lagi',
    1204: 'Silakan coba dengan alamat email lainnya',
    1208: 'Kode verifikasi tidak dapat dikirim ulang',
    1209: 'Kesalahan pengiriman kode verifikasi',
    session_timeout: 'Sesi waktu habis, mengarahkan ke halaman login.',
    vload: {
      4019: 'Tipe atau nilai voucher tidak diterima',
      4071: 'Dicurigai penipuan',
      4072: 'Voucher telah digunakan',
      4073: 'Voucher tidak aktif',
      4074: 'Voucher kedaluwarsa',
      4075: 'Voucher tidak ditemukan',
      4080: 'Nilai voucher tidak mencukupi',
      4444: 'Voucher yang digunakan tidak valid. Mata uang tidak sesuai.',
      5000: 'Data toko tidak tersedia',
    },
  },
  lock: {
    premium: {
      trading: {
        tools: {
          description: 'Untuk membuka fitur-fitur premium, tuntaskan pendaftaran Anda sekarang',
        },
      },
    },
  },
};
