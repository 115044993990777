import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import registerState from './register/state'
import registerMutations from './register/mutations'
import registerActions from './register/actions'

import commonState from './common/state'
import commonMutations from './common/mutations'
import commonActions from './common/actions'

import regulatorState from './regulator/state'
import regulatorMutations from './regulator/mutations'
import regulatorActions from './regulator/actions'

import upgradeState from './upgrade/state'
import upgradeMutations from './upgrade/mutations'
import upgradeActions from './upgrade/actions'

import promotionState from './promotion/state'
import promotionMutations from './promotion/mutations'
import promotionActions from './promotion/actions'

import paymentState from './payment/state'
import paymentMutations from './payment/mutations'
import paymentActions from './payment/actions'

import cpsState from './cps/state'
import cpsMutations from './cps/mutations'
import cpsActions from './cps/actions'

Vue.use(Vuex)

const common = {
  namespaced: true,
  state: commonState,
  mutations: commonMutations,
  actions: commonActions,
}

const register = {
  namespaced: true,
  state: registerState,
  mutations: registerMutations,
  actions: registerActions,
}

const regulator = {
  namespaced: true,
  state: regulatorState,
  mutations: regulatorMutations,
  actions: regulatorActions,
}

const upgrade = {
  namespaced: true,
  state: upgradeState,
  mutations: upgradeMutations,
  actions: upgradeActions,
}

const promotion = {
  namespaced: true,
  state: promotionState,
  mutations: promotionMutations,
  actions: promotionActions,
}

const payment = {
  namespaced: true,
  state: paymentState,
  mutations: paymentMutations,
  actions: paymentActions,
}

const cps = {
  namespaced: true,
  state: cpsState,
  mutations: cpsMutations,
  actions: cpsActions,
}

export default new Vuex.Store({
  modules: {
    common,
    register,
    regulator,
    upgrade,
    promotion,
    payment,
    cps,
  },
  plugins: [
    createPersistedState({
      paths: ['common', 'regulator', 'upgrade', 'promotion', 'cps'],
    }),
  ],
})
