<template>
  <VideoTemplate></VideoTemplate>
</template>

<script>
import VideoTemplate from '@/components/register/VideoTemplate';

export default {
  name: 'vfscVideoRTemplate',
  components: { VideoTemplate }
};
</script>