<template>
  <el-form :model="form" :rules="rules" ref="ruleForm" label-position="left" label-width="25%">
    <ul class="clearfix">
      <li>
        <div class="label">{{ $t('register.accountConfig.chooseTradingPlatform') }}</div>
        <ul class="accountConfiguration">
          <el-form-item prop="tradingPlatform">
            <li
              v-for="item in $platform.openLiveAccount.choosePlatform(regulator)"
              :key="item.value"
              :class="{ active: form.tradingPlatform == item.value }"
              :data-testid="item.value"
            >
              <img class="popular_img" :src="item.popular_img" alt />
              <img :src="item.img" class="mt_img" @click="platformClick(item)" alt="" />
            </li>
          </el-form-item>
        </ul>
      </li>
      <li>
        <div class="label">{{ $t('register.accountConfig.chooseAccType') }}</div>

        <ul class="accountConfiguration">
          <div class="box box_type">
            <el-form-item prop="accountType">
              <li
                v-for="(item, index) in accountTypeList"
                :key="index"
                @click="typeClick(index, item)"
                :class="{ active: activeType == index }"
                :data-testid="item.value"
              >
                <img :src="item.img" class="mt_img_pamm" alt="" width="111px" v-if="index == 2" />
                <img :src="item.img" class="mt_img_others" alt="" width="111px" v-else />
              </li>
              <p v-if="swapFreeFlag" class="accTypeNote">
                <b>{{ $t('openAdditAcc.swapFreeNote') }}</b>
              </p>
            </el-form-item>
          </div>
        </ul>
      </li>
      <li>
        <div class="label">{{ $t('register.accountConfig.chooseAccCurrency') }}</div>
        <ul class="accountConfiguration currency_container">
          <el-form-item prop="currency">
            <li
              v-for="(item, index) in accountCurrencyList"
              :key="index"
              @click="currencyClick(index, item)"
              :class="{ active: activeCurrency == index }"
              class="currency"
              :data-testid="item.currency"
            >
              <div class="shadow_inner">
                <img :src="item.img" @click.prevent.stop="currencyClick(index, item)" alt="" />
                <p>{{ item.value }}</p>
              </div>
            </li></el-form-item
          >
        </ul>
      </li>
    </ul>
    <div class="ac_last_box">
      <div class="check">
        <div>
          <input type="checkbox" id="checkbox" v-model="checkStatus" :disabled="disableField" data-testid="checkbox" />
          <label for="checkbox">{{ $t('openAdditAcc.byTicking') }}</label>
        </div>
        <span class="error" v-if="!checkStatus">{{ $t('common.formValidation.tnc') }}</span>
      </div>
      <ul
        dir="ltr"
        v-html="
          $t($platform.openLiveAccount.getAgreeCondition(regulator), {
            platform: $platform.info.fullName,
            regulatorUrl: GLOBAL_DOMAIN_WEBSITE
          })
        "
      ></ul>
    </div>
  </el-form>
</template>

<script>
import accountConfiguration from '@/mixins/register/accountConfiguration';

export default {
  mixins: [accountConfiguration]
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/accountConfiguration.scss';
</style>
