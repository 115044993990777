import { instance, instanceExternal } from '@/axios/index.js'
import qs from 'qs'

const baseUrl = '/api/'
const cpUrl = '/cp/api/'

const loginUrl = `${baseUrl}login/`
const fileUrl = `${baseUrl}file/`
const smsUrl = `${baseUrl}request-sms-code`
const smsVerifyUrl = `${baseUrl}verify-sms-code`
const homeUrl = `${cpUrl}home/`
const withdrawalUrl = `${cpUrl}withdrawal/`
const paymentSelectionUrl = `${baseUrl}payment/`
const transferUrl = `${cpUrl}transfer/`
const depositUrl = `${cpUrl}deposit/`
const accountUrl = `${cpUrl}account/`
const cardRegisterUrl = `${cpUrl}card-register/`
const passwordUrl = `${cpUrl}password/`
const profileUrl = `${cpUrl}profile/`
const fundsUrl = `${cpUrl}funds/`
const transactionHistoryUrl = `${cpUrl}transactionHistory/`
const questionnaireUrl = `${cpUrl}questionnaire/`
const depositlResultPath = 'result'
const checkResetPasswordAuthorization = `${profileUrl}check_reset_password_authorization`
const submitResetProfilePassword = `${profileUrl}submit_reset_profile_password`
const submitResetChangeIpWarn = `${profileUrl}reset_change_ip_warn`
const submitResetMt4AccountPassword = `${cpUrl}password/submit_reset_mt4_password`
const submitResetForgetPassword = `${cpUrl}password/reset-mobile-password`
const identifyProofUrl = `${cpUrl}identityProof/`
const userProfileUrl = `${baseUrl}userProfile/`
const tcmktUrl = `${baseUrl}tcmkt/`
const tcVideoUrl = `${baseUrl}newsFactory/`
const tradingTips = `${baseUrl}tradingTips/`
const demoUrl = `${cpUrl}demo/`
const locationTrace = `https://secure.vantagemarkets.com/cdn-cgi/trace`
const googleTranslateBasic = `https://translation.googleapis.com/language/translate/v2?key=AIzaSyCKuPDmPYUTdOFUitGsQzH3rLgs58B4_I0`
const notificationUrl = `${baseUrl}notification/`
const sliderCheckerUrl = `${baseUrl}sliderChecker/`
const systemUrl = `${baseUrl}system/`
const proclientUrl = `${baseUrl}proclient/`
const cardAuditUrl = `${cpUrl}card-audit/`
const mfaUrl = `${baseUrl}mfa/`

export const personalDetailUrl = `${baseUrl}personalDetail/`
export const verificationUrl = `${baseUrl}verification/`
export const verificationV2Url = `${baseUrl}verificationV2/`
export const questionUrl = `${baseUrl}question/`
export const cpsWithdrawalUrl = `${withdrawalUrl}`
export const cpsUrl = `${depositUrl}cps/`

const securityManageUrl = `${cpUrl}multiFactorAuth/`
const authUrl = `${cpUrl}two-factor/`

/** login */
export const apiTo_login = data => instance.post(`${loginUrl}to_login`, qs.stringify(data))
export const apiTo_logout = data => instance.get(`${loginUrl}to_logout`, { headers: { Pragma: 'no-cache' }, params: data })
export const apiTo_login_by_token = headers => instance.post(`${loginUrl}to_login_by_token`, '', headers)
export const apiTo_login_register = data => instance.post(`${loginUrl}to_login_register`, qs.stringify(data))
export const apiTo_sms_code_login = data => instance.post(`${loginUrl}login-after-sms-code-check`, qs.stringify(data))

/** home */
export const apiQueryMetaTraderAccountDetails = data =>
  instance.post(`${homeUrl}query-metaTrader-account-details`, data)
export const apiQueryLeverageStatuses = data => instance.post(`${homeUrl}query-leverage-statuses`, data)
export const apiQueryAvailableLeverages = data => instance.post(`${homeUrl}query-available-leverages`, data)
export const apiUpdate_leverage = data => instance.post(`${homeUrl}update-leverage`, data, { timeout: 30000 })
export const apiUpdate_leverage_demo = data => instance.post(`${homeUrl}update-leverage-demo`, data, { timeout: 30000 })
export const apiReactivate_Account = data => instance.post(`${homeUrl}reactivate-account`, data)
export const apiHomeGet_demo_accs = data => instance.get(`${homeUrl}get-demo-accs`, data)
export const apiupdate_balance_demo = data => instance.post(`${homeUrl}update-balance-demo`, data)
export const apiQueryAllMetaTraderAccountDetails = data =>
  instance.post(`${homeUrl}query-all-metaTrader-account-details`, data)
export const apiUpdateMetaTraderAccountHide = data => instance.post(`${homeUrl}update-metaTrader-account-hide`, data)
export const apiRestAccountBalance = data => instance.post(`${homeUrl}restAccount/${data}`)

/** userProfile*/
export const apiSetlanguage = data => instance.post(`${userProfileUrl}setlanguage`, qs.stringify(data))

/** withdrawal */
export const apiIsCreditCardDeposit_cp = data => instance.post(`${withdrawalUrl}isCreditCardDeposit_cp`, data)
export const apiGetWithdrawalData_cp = () => instance.post(`${withdrawalUrl}getWithdrawalData_cp`)
export const apiGetNonCreditCardWithdrawTypeCP = data =>
  instance.post(`${withdrawalUrl}getNonCreditCardWithdrawTypeCP`, data)
export const apiApplyWithdrawal_cp = (data, token) =>
  instance.post(`${withdrawalUrl}applyWithdrawal_cp`, data, { headers: { token: token } })
export const apiRegisterUnionPayCard_cp = data => instance.post(`${withdrawalUrl}registerCard`, data)
export const apiCardInformation = data => instance.get(`${withdrawalUrl}queryUnionpayCard`, { param: data })
export const apiWithdrawalBlacklist = () => instance.get(`${withdrawalUrl}queryBlacklist`)
export const apiCreditCardBalances = data => instance.get(`${withdrawalUrl}creditCard/balances/${data.currency}`, data)
export const apiGetLBTWithdrawalData_cp = data =>
  instance.get(`${withdrawalUrl}getLBTWithdrawalData_cp`, { params: data })
export const apiApplyWithdrawal_cp_batch = (data, token) =>
  instance.post(`${withdrawalUrl}applyWithdrawal_cp_batch`, data, { headers: { token: token } })
export const apiWithdrawalQueryRateUSDByTargetCurrency = targetCurrency =>
  instance.get(`${withdrawalUrl}queryRateUSDByTargetCurrency/${targetCurrency}`)
export const apiGetWithdrawalBankCode = data => instance.get(`${withdrawalUrl}queryBankCode`, data)
export const apiGetWithdrawalBankBranchCode = bankCode => instance.get(`${withdrawalUrl}queryAllByBankCode/${bankCode}`)
export const apiCancelWithdraw = data =>
  instance.post(`${withdrawalUrl}cancelWithdrawalOrder?withdrawalId=${data.withdrawalId}`)
export const apiSendEmailVerificationCode = data => instance.post(`${mfaUrl}sendEmailVerificationCode`, data)

/** Payment */
export const apiPaymentSelection = data => instance.post(`${paymentSelectionUrl}info/getPaymentInfoList/`, data)

/** transfer */
export const apiGetTransferData_cp = () => instance.post(`${transferUrl}getTransferData_cp`)
export const apiApplyTransfer_cp = (data, token) =>
  instance.post(`${transferUrl}applyTransfer_cp`, qs.stringify(data), { headers: { token: token } })
export const apiTransferPermission = data => instance.post(`${transferUrl}transferPermission`, qs.stringify(data))
export const apiTransferBlacklist = () => instance.get(`${transferUrl}queryBlacklist`)

/** file */
export const apiDelete_file = data => instance.post(`${fileUrl}delete`, data)

/** account */
export const apiGetMetaTraderAccounts = () => instance.get(`${accountUrl}getMetaTraderAccounts`)
export const apiApplyAdditionalAccount = data =>
  instance.post(`${accountUrl}applyAdditionalAccount`, qs.stringify(data))
export const apiCheckEquity = data =>
  instance.get(`${accountUrl}check_equity?mt4Account=${data.mt4Account}&&amount=${data.amount}`)
export const apiCreateDemoAccount = data => instance.post(`${accountUrl}createDemoAccount`, data)

/** deposit */
export const apiDepositBlacklist = () => instance.get(`${depositUrl}queryBlacklist`)
export const apiRestrictedDeposit = () => instance.post(`${depositUrl}getRestrictedDepositData`)
export const apiCc_payment = data => instance.post(`${depositUrl}cc_payment`, data)
export const apiCc_payment_365 = data => instance.post(`${depositUrl}cc_payment_365`, data)
export const apiCc_payment_solid = (data, headers) =>
  instance.post(`${depositUrl}cc_payment_solid`, data, { headers: headers })
export const apiCc_payment_apg = data => instance.post(`${depositUrl}cc_payment_apg`, data)
export const apiCc_payment_sd = data => instance.post(`${depositUrl}cc_payment_sd`, data)
export const apiCc_checkout_payment = data => instance.post(`${depositUrl}cc_checkout_payment`, data)
export const apiFetch_card = data => instance.post(`${depositUrl}fetch_card`, data)
export const apiUpload_card = data => instance.post(`${depositUrl}upload_cc_img`, data)
export const apiQuery_mt_accounts = data => instance.post(`${depositUrl}query_mt_accounts`, qs.stringify(data))
export const apiQueryRate_USD_VND = () => instance.post(`${depositUrl}queryRate_USD_VND`)
export const apiRequire_token = () => instance.post(`${depositUrl}require_token`)
export const apiAnti_Reuse = () => instance.get(`${baseUrl}token/anti-reuse`)
export const apiQueryRate = () => instance.post(`${depositUrl}queryRate`)
export const apiQueryExchangeRate = () => instance.get(`${depositUrl}queryExchangeRate`)
export const apiExchangeRate = data =>
  instance.get(`${depositUrl}exchangeRate?fromCurrency=${data.fromCurrency}&toCurrency=${data.toCurrency}`)
export const apiQueryPagsmileExchangeRate = () => instance.get(`${depositUrl}pagsmile/queryExchangeRate`)
export const apiQuery_paymentChannel = () => instance.post(`${depositUrl}query_paymentChannel`)
export const apiQueryRate_USD_NGN = () => instance.post(`${depositUrl}queryRate_USD_NGN`)
export const apiQueryRate_USD_MYR = () => instance.post(`${depositUrl}queryRate_USD_MYR`)
export const apiQueryRate_USD_IDR = () => instance.post(`${depositUrl}queryRate_USD_IDR`)
export const apiQueryRate_USD_KRW = () => instance.post(`${depositUrl}queryRate_USD_KRW`)
export const apiQueryRate_USD_INR = () => instance.post(`${depositUrl}queryRate_USD_INR`)
export const apiQueryRate_USD_PHP = () => instance.post(`${depositUrl}queryRate_USD_PHP`)
export const apiQueryRate_USD_BRL = () => instance.post(`${depositUrl}queryRate_USD_BRL`)
export const apiQueryRate_USD_ZAR = () => instance.post(`${depositUrl}queryRate_USD_ZAR`)
export const apiQueryRate_USD_LAK = () => instance.post(`${depositUrl}queryRate_USD_LAK`)
export const apiEeziePay_banklist = data => instance.get(`${depositUrl}eeziepay/getBankList?context=eeziepay.inr`, data)
export const apiQueryRateUSDByTargetCurrency = data =>
  instance.post(`${depositUrl}queryRateUSDByTargetCurrency`, qs.stringify(data))
export const apiQuery_malaysia_paymentChannel = () => instance.post(`${depositUrl}query_malaysia_paymentChannel`)
export const apiGet_rate = data => instance.post(`${depositUrl}get_rate`, qs.stringify(data))
export const apiGet_holder_name = data => instance.post(`${depositUrl}get_holder_name`, qs.stringify(data))
export const apiGet_mobile_rate = data => instance.post(`${depositUrl}get_mobile_rate`, qs.stringify(data))
export const apiAssembly_auth_token = (data, token) =>
  instance.post(`${depositUrl}assembly_auth_token`, data, { headers: { token: token } })
export const apiGetCheckOutConf = () => instance.post(`${depositUrl}getCheckOutConf`)

export const apiVietnam_bankwire_payment = (data, token) =>
  instance.post(`${depositUrl}vietnam_bankwire_payment`, data, { headers: { token: token } })
// To be Confirm
export const apiEeziePay_payment = (data, token) =>
  instance.post(`${depositUrl}eeziepay`, data, { headers: { token: token } })
export const apiXpay_payment = (data, token) =>
  instance.post(`${depositUrl}xpay_payment`, data, { headers: { token: token } })
export const apiUnionpay_transfer = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer`, data, { headers: { token: token } })
export const apiUnionpay = (data, token) => instance.post(`${depositUrl}unionpay`, data, { headers: { token: token } })
export const apiZotapay_payment = (data, token) =>
  instance.post(`${depositUrl}zotapay_payment`, data, { headers: { token: token } })
export const apiPaytoday_payment = (data, token) =>
  instance.post(`${depositUrl}paytoday_payment`, data, { headers: { token: token } })
export const apiSkrill_payment = (data, token) =>
  instance.post(`${depositUrl}skrill/payment`, data, { headers: { token: token } })
export const apiStic_payment = (data, token) =>
  instance.post(`${depositUrl}stic/payment`, data, { headers: { token: token } })
export const apiWebMoney_payment = (data, token) =>
  instance.post(`${depositUrl}webMoney`, data, { headers: { token: token } })
export const apiPoli_payment = (data, token) =>
  instance.post(`${depositUrl}poli_payment`, data, { headers: { token: token } })
export const apiNigeria_bankwire_payment = (data, token) =>
  instance.post(`${depositUrl}nigeria_bankwire_payment`, data, { headers: { token: token } })
export const apiNeteller_payment = (data, token) =>
  instance.post(`${depositUrl}paysafe_neteller`, data, { headers: { token: token } })
export const apiPaywize_payment = (data, token) =>
  instance.post(`${depositUrl}paywize_payment`, data, { headers: { token: token } })
export const apiBridge_payment = (data, token) =>
  instance.post(`${depositUrl}bridgepay`, data, { headers: { token: token } })
export const apiPaypal_payment = (data, token) =>
  instance.post(`${depositUrl}paypal_payment`, data, { headers: { token: token } })
export const apiTrustly_payment = (data, token) =>
  instance.post(`${depositUrl}trustly_payment`, data, { headers: { token: token } })
export const apiCardpay_payment = (data, token) =>
  instance.post(`${depositUrl}cardpay_payment`, data, { headers: { token: token } })
export const apiTinkBit_payment = (data, token) =>
  instance.post(`${depositUrl}tinkbit_payment`, data, { headers: { token: token } })
export const apiMobile_payment = (data, token) =>
  instance.post(`${depositUrl}mobile_payment`, data, { headers: { token: token } })
export const apiMalaysia_bankwire_payment = (data, token) =>
  instance.post(`${depositUrl}malaysia_bankwire_payment`, data, { headers: { token: token } })
export const apiPhilipiness_bankwire_payment = (data, token) =>
  instance.post(`${depositUrl}philippines_bankwire_payment`, data, { headers: { token: token } })
export const apiIndonesia_bankwire_payment = (data, token) =>
  instance.post(`${depositUrl}indonesia_bankwire_payment`, data, { headers: { token: token } })
export const apiIndia_bankwire_payment = (data, token) =>
  instance.post(`${depositUrl}paygate`, data, { headers: { token: token } })
export const apiInternational_bank_payment = (data, token) =>
  instance.post(`${depositUrl}international_bank_payment`, data, { headers: { token: token } })
export const apiFasapay_payment = (data, token) =>
  instance.post(`${depositUrl}fasapay_payment`, data, { headers: { token: token } })
export const apiMijipay_payment = (data, token) =>
  instance.post(`${depositUrl}mijipay_payment`, data, { headers: { token: token } })
export const apiBroker_to_broker_payment = (data, token) =>
  instance.post(`${depositUrl}broker_to_broker_payment`, data, { headers: { token: token } })
export const apiBpay_payment = (data, token) =>
  instance.post(`${depositUrl}bpay_payment`, data, { headers: { token: token } })
export const apiAustralian_bank_payment = (data, token) =>
  instance.post(`${depositUrl}australian_bank_payment`, data, { headers: { token: token } })
export const apiCrypto_payment = (data, token) =>
  instance.post(`${depositUrl}crypto_payment`, data, { headers: { token: token } })
export const apiCryptousdt_payment = (data, token) =>
  instance.post(`${depositUrl}cryptousdt_payment`, data, { headers: { token: token } })
export const apiCryptoBtcCps_payment = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer_btc`, data, { headers: { token: token } })
export const apiCryptoOmniCps_payment = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer_omni`, data, { headers: { token: token } })
export const apiCryptousdtcps_payment = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer_usdt`, data, { headers: { token: token } })
export const apiCryptoTrcCps_payment = (data, token) =>
  instance.post(`${depositUrl}unionpay_transfer_trc`, data, { headers: { token: token } })
export const apiBitwallet_payment = (data, token) =>
  instance.post(`${depositUrl}bitwallet`, data, { headers: { token: token } })
export const apiDragonPhoenix_payment = (data, token) =>
  instance.post(`${depositUrl}kr_df`, data, { headers: { token: token } })
export const apiAssembly_payment = (data, token) =>
  instance.post(`${depositUrl}assembly_payment`, data, { headers: { token: token } })
export const apiPagsmile_payment = (data, token) =>
  instance.post(`${depositUrl}pagsmile`, data, { headers: { token: token } })
export const apiSafeCharge = (data, token) =>
  instance.post(`${depositUrl}safeCharge`, data, { headers: { token: token } })
export const apiIndiaBankTransferSubmitRecipt = (data, token) =>
  instance.post(`${depositUrl}submit-india-p2p`, data, { headers: { token: token } })
export const apiAstropay = (data, token) => instance.post(`${depositUrl}astropay`, data, { headers: { token: token } })
export const apiEverpay = (data, token) => instance.post(`${depositUrl}everpay`, data, { headers: { token: token } })
export const apiIndonesiaZotapay = (data, token) =>
  instance.post(`${depositUrl}indonesiaZotapay`, data, { headers: { token: token } })
export const apiIndiaZotapay = (data, token) =>
  instance.post(`${depositUrl}indiaZotapay`, data, { headers: { token: token } })
export const apiSouthAfricaZotapay = (data, token) =>
  instance.post(`${depositUrl}southAfricaZotapay`, data, { headers: { token: token } })
export const apiVload_payment = (data, token) =>
  instance.post(`${depositUrl}vload`, data, { headers: { token: token } })
export const apiPaymentAsia_payment = (data, token) =>
  instance.post(`${depositUrl}paymentasia_payment`, data, { headers: { token: token } })
export const apiCpsDepositH5_payment = (data, token) =>
  instance.post(`${depositUrl}cpsDepositH5`, data, { headers: { token: token } })
export const apiDasshpeUPI_payment = (data, token) =>
  instance.post(`${depositUrl}dasshpe_upi_payment`, data, { headers: { token: token } })
export const apiDasshpeNB_payment = (data, token) =>
  instance.post(`${depositUrl}dasshpe_netBanking_payment`, data, { headers: { token: token } })
export const apiDasshpeNB_banklist = (data, token) =>
  instance.get(`${depositUrl}dasshpe/getBankList?context=dasshpe_netbanking`, data)
export const apiDasshpeWallet_payment = (data, token) =>
  instance.post(`${depositUrl}dasshpeWallet/payment`, data, { headers: { token: token } })
export const apiDasshpeWallet_banklist = (data, token) =>
  instance.get(`${depositUrl}dasshpeWallet/getBankList?context=dasshpe_wallet`, data)
export const apiDollarSmart_payment = (data, token) =>
  instance.post(`${depositUrl}dollarsmart`, data, { headers: { token: token } })
//QR入金页面提交，获取二维码接口
export const apiDollarSmartQR_payment = (data, token) =>
  instance.post(`${depositUrl}dollarsmartQR`, data, { headers: { token: token } })
export const apiDollarSmartQRcode_confirmation = (data, token) =>
  instance.post(`${depositUrl}dollarsmartQR/update`, qs.stringify(data), { headers: { token: token } })
export const apiDollarSmart_confirmation = (data, token) =>
  instance.post(`${depositUrl}dollarsmart/update`, qs.stringify(data), { headers: { token: token } })
export const apiZotapay_bankwire_payment = (data, token) =>
  instance.post(`${depositUrl}zotapay_bankwire_payment`, data, { headers: { token: token } })

/** cardRegister */
export const apiCredit_card = data => instance.post(`${cardRegisterUrl}credit_card`, data)
export const apiHistoryList = data => instance.post(`${cardRegisterUrl}history/list`, data)
export const apiGetCardDetials = data =>
  instance.get(`${cardRegisterUrl}get-card-info?cardId=${data.cardId}&&cardType=${data.cardType}`)
export const apiUpdateCreditCard = data => instance.post(`${cardRegisterUrl}/credit_card/update`, data)
export const apiRemoveCard = data => instance.post(`${cardRegisterUrl}unionpay_card/update`, data)
export const apiUnionpay_banklist = () => instance.get(`${cardRegisterUrl}getBankName`)

/** password */
export const apiUpdate_mt4_account_password = data => instance.post(`${passwordUrl}update_mt4_account_password`, data)
export const apiReq_reset_mt4_password = data => instance.post(`${passwordUrl}req_reset_mt4_password`, data)
export const apiCheck_new_password = data => instance.post(`${passwordUrl}check_new_password`, data)
export const apiCheck_current_password = data => instance.post(`${passwordUrl}check_current_password`, data)

/** profile */
export const apiInfo = () => instance.post(`${profileUrl}info`)
export const apiCheckPassword = data => instance.post(`${profileUrl}checkPassword`, qs.stringify(data))
export const apiEncryptPublicKey = (token = null) => instance.get(`${cpUrl}keyPair/pk?token=${token}`)
export const apiUpdatePassword = (data, publicKey) => instance.post(`${profileUrl}updatePassword`, qs.stringify(data), { headers: { pk: publicKey } })
export const apiReq_reset_profile_password = data =>
  instance.post(`${profileUrl}req_reset_profile_password`, qs.stringify(data))
export const apiForceUpdatePassword = data => instance.post(`${profileUrl}force_update_password`, data)
export const apiReq_reset_profile_password_mobile = data => instance.post(`${smsVerifyUrl}`, data)
export const apiReq_reset_get_sms = data => instance.post(`${smsUrl}`, data)
export const apiSubmitResetChangeIpWarn = data => instance.post(`${submitResetChangeIpWarn}`, qs.stringify(data))
export const apiCheckMfaSwitchd = () => instance.get(`${profileUrl}checkMfaSwitch`)

/** funds */
export const apiTransferHistoryGet = data =>
  instance.get(`${fundsUrl}transfer-history/get`, {
    params: data,
  })

/** identityProof */
export const apiVerificationIdentityProofs = data => instance.post(`${identifyProofUrl}verification`, data)
export const apiInsertIdentityProofs = data => instance.post(`${identifyProofUrl}insert`, data)
export const apiCheckRecordExists = data => instance.post(`${identifyProofUrl}check_record_exists`, data)
export const apiGet_last_identifiy_proof = id =>
  instance.get(`${identifyProofUrl}get_last_identifiy_proof?documentType=${id}`)
export const apiAttach_new_document = data => instance.post(`${identifyProofUrl}attach_new_document`, data)

/**
 *Thirdparty Deposit Result
 * @param {*} url
 * @param {*} data
 */
export const apiThirdPartyDepositResult = (paymentType, data) =>
  instance.post(`${depositUrl}` + paymentType + `/${depositlResultPath}`, data, {
    timeout: 30000,
  })

/** transactionHistory */
export const apiTransactionHistory = (url, data) => instance.post(`${transactionHistoryUrl}${url}`, qs.stringify(data))
export const apiDownloadAccountTransactionHistoryPDF = data =>
  instance.post(`${transactionHistoryUrl}/downloadAccountTransactionHistoryPDF`, data)

/** questionnaireUrl */
export const apiCheck = data => instance.post(`${questionnaireUrl}check`, data)

/**
 * reset password
 */
export const apiCheckResetPasswordAuthorization = data => instance.post(`${checkResetPasswordAuthorization}`, data)
export const apiSubmitResetProfilePassword = (data, publicKey) => instance.post(`${submitResetProfilePassword}`, qs.stringify(data), { headers: { pk: publicKey } })
export const apiSubmitResetMt4AccountPassword = data => instance.post(`${submitResetMt4AccountPassword}`, data)
export const apiSubmitReseForgetPassword = data => instance.post(`${submitResetForgetPassword}`, data)

/** tcmktUrl */
export const apiTcmktCheckDeposit = () => instance.get(`${tradingTips}check/deposit`)
export const apiTcmktCheckTnc = () => instance.post(`${tcmktUrl}check/tnc`)
export const apiTcmktTncAccept = () => instance.post(`${tcmktUrl}tnc/accept`)
export const apiTcmktGetUrl = () => instance.post(`${tcmktUrl}getUrl`)

/** tcvideoUrl */
export const apiProCheckUnlocked = () => instance.get(`${tradingTips}/unlocked`)
export const apiVideoCheckDeposit = () => instance.get(`${tradingTips}check/deposit`)
export const apiVideoCheckTnc = () => instance.post(`${tcVideoUrl}check/tnc`)
export const apiVideoTncAccept = () => instance.post(`${tcVideoUrl}tnc/accept`)

/** demoUrl */
export const apiGet_demo_accs = () => instance.get(`${demoUrl}get_demo_accs`)

/** locationTrace */
//https://www.cloudflare.com/cdn-cgi/trace get location info by tracing cloudflare
export const apiGetUserLocation = () => instanceExternal.get(`${locationTrace}`)

/** google translation */
export const apiGetTranslation = data => instanceExternal.post(`${googleTranslateBasic}`, data)

/** notification */
export const apiGetNotifications = ({ country }) => instance.get(`${notificationUrl}enabled?country=${country}`)

/** Verification */
export const apiSecurityPassed = data =>
  instance.get(`${sliderCheckerUrl}passed`, {
    params: data,
  })

/** systemUrl */
export const apiInformation = () => instance.get(`${systemUrl}information`)

/** questionUrl */
export const apiEmploymentFinanceAnswers = () => instance.get(`${questionUrl}employmentFinanceAnswers`)
export const apiTradingAnswers = () => instance.get(`${questionUrl}tradingAnswers`)
export const apiWealthTests = lang => instance.get(`${questionUrl}wealthTest?lang=${lang}`)
export const apiWholesaleTests = lang => instance.get(`${questionUrl}wholesaleTest?lang=${lang}`)
export const apiSophisticatedInvestorQuizs = lang =>
  instance.get(`${questionUrl}sophisticatedInvestorQuiz?lang=${lang}`)
export const apiCategorisationTest = lang => instance.get(`${questionUrl}categorisationTest?lang=${lang}`)

/** proclientUrl */
export const apiProclientProcess = data => instance.post(`${proclientUrl}process`, data)
export const apiProclientGetCurrentStep = data => instance.get(`${proclientUrl}getCurrentStep`, data)
export const apiProclientClientNotice = () => instance.get(`${proclientUrl}clientNotice`)

/** 2FA */
export const apiAuthEnable = data => instance.post(`${authUrl}enable`, data)
export const apiAuthDisable = data => instance.post(`${authUrl}disable`, data)
export const apiAuthStatus = () => instance.get(`${authUrl}status`)


/** Security */
export const apiGetSecurityInfo = () => instance.get(`${securityManageUrl}info`)
export const apiGetHint = () => instance.get(`${securityManageUrl}hintInfo`)
export const apiGetMultiFactorAuth = functionCode => instance.get(`${securityManageUrl}${functionCode}/getMultiAuthMethod`)
export const apiPreValidateTotp = data => instance.post(`${securityManageUrl}totp/preValidate`, data)
export const apiPreValidateEmail = data => instance.post(`${securityManageUrl}email/preValidate`, data)
export const apiChangeEmail = data => instance.post(`${securityManageUrl}changeEmail`, data)
export const apiChangeSwitch = data => instance.post(`${securityManageUrl}switch`, data)
export const apiVerifyEmail = () => instance.post(`${securityManageUrl}verifyEmail`)
export const apiAddTwoFactor = data => instance.post(`${authUrl}enable`, data)
export const apiModifyTwoFactor = data => instance.post(`${securityManageUrl}totp/change/${data}`)


/**promotion */
const campaignUrl = `${baseUrl}campaign/`
export const apiEligibleCampaigns = () => instance.get(`${campaignUrl}eligible-campaigns`)
export const apiCampaignInfoAlt = id => instance.get(`${campaignUrl}info?campaignId=${id}`)
export const apiCampaignInfo = id => instance.get(`${campaignUrl}participants?campaignId=${id}`)
export const apiCampaignOpt = (id, optIn) => instance.post(`${campaignUrl}opt-in?campaignId=${id}&optIn=${optIn}`)
export const apiReferralLink = id => instance.get(`${campaignUrl}campaign-code?campaignId=${id}`)
export const apiRedeemableAcc = id => instance.get(`${campaignUrl}redeemable-accounts?campaignId=${id}`)
export const apiSubmitRedeem = data => instance.post(`${campaignUrl}redeem`, data)
export const apiCampaignOptInPopup = (id, enabled) =>
  instance.post(`${campaignUrl}toggle-opt-in-popup?campaignId=${id}&enabled=${enabled}`)

/**contact us */
const contactUsUrl = `${cpUrl}contact-us/`
export const apiContactUsInfo = () => instance.get(`${contactUsUrl}info`)

/**card audit */
export const cardAuditSubmit = data => instance.post(`${cardAuditUrl}submit`, data)

/**ticket */
const ticketUrl = `${cpUrl}ticket/`
export const apiTicketRequest = () => instance.get(`${ticketUrl}requests`)
export const apiTicketComment = id => instance.get(`${ticketUrl}comments?ticketId=${id}`)
export const apiTicketCommentUpdate = data =>
  instance.post(`${ticketUrl}update-ticket`, data, { headers: { 'Content-Type': 'multipart/form-data' } })
export const apiSubmitTicket = data =>
  instance.post(`${ticketUrl}submit-ticket`, data, { headers: { 'Content-Type': 'multipart/form-data' } })

/**security */
const securityUrl = `${baseUrl}security/`
export const apiGetSecurity = () => instance.get(`${securityUrl}getSecuritys`)
export const apiGetCurrentSecurity = functionCode => instance.get(`${securityUrl}current/${functionCode}/rule`) // functionCode = updatePassword / updateUserLogin
