<template>
  <el-form :model="form" ref="ruleForm" label-position="left" label-width="25%">
    <section>
      <i18n class="left" path="register.confirmYourId.description" tag="p">
        <template v-slot:mailTo>
          <a :href="'mailto:' + GLOBAL_ACCOUNT_EMAIL">
            <u>{{ GLOBAL_ACCOUNT_EMAIL }}</u>
          </a>
        </template>
      </i18n>
    </section>
    <ul>
      <li class="confirmId">
        <div class="title">
          {{ $t('register.confirmYourId.poidTitle') }}
          <I v-if="checkIdDocFile" class="i_success"> {{ $t('register.confirmYourId.pending.verification') }}</I
          ><I class="i_error" v-else> {{ $t('register.confirmYourId.pending.upload') }}</I>
        </div>
        <div class="tip">{{ $t('register.confirmYourId.uploadDescription') }}</div>
        <div class="confirmId_main">
          <div class="left">
            <img class="img" src="@/assets/images/register/identity.png" alt="" />
            <ul>
              <li>{{ $t('register.confirmYourId.poidTypes.photoId') }}</li>
              <li>{{ $t('register.confirmYourId.poidTypes.passport') }}</li>
              <li>{{ $t('register.confirmYourId.poidTypes.drivingLicence') }}</li>
            </ul>
            <br />
            <br />
            <span>{{ $t('register.confirmYourId.note') }}</span>
          </div>
          <div class="right">
            <vUpload
              :limit="6"
              v-on:updateFileInfo="updateIDInfo"
              :selfTip="true"
              :selfText="true"
              :class="{ finish: checkIdDocFile }"
              data-testid="updateIDInfo"
            >
              <div class="message" v-if="checkIdDocFile">
                <div class="done">{{ $t('register.btn.done') }}</div>
                <div class="uploadNew" :class="{ fr_button: lang === 'fr_FR' }">{{ $t('register.btn.uploadNew') }}</div>
              </div>
              <div class="message" v-else>
                <div class="top"><img src="@/assets/images/register/upload.png" alt="" /></div>
                <div class="bottom">{{ $t('register.btn.upload') }}</div>
              </div>
            </vUpload>
          </div>
        </div>
        <div>
          <idPoaForm ref="idPoaForm" v-if="form.idDocFilePathList.length > 0"></idPoaForm>
        </div>
      </li>
      <li class="confirmId">
        <div class="title">
          {{ $t('register.confirmYourId.porTitle') }}
          <I v-if="checkPocDocFile" class="i_success">{{ $t('register.confirmYourId.pending.verification') }}</I
          ><I class="i_error" v-else>{{ $t('register.confirmYourId.pending.upload') }}</I>
        </div>
        <div class="tip">{{ $t('register.confirmYourId.uploadDescription') }}</div>
        <div class="confirmId_main">
          <div class="left">
            <img class="img" src="@/assets/images/register/residence.png" alt="" />
            <ul v-html="$t('register.confirmYourId.porTypes')"></ul>
            <br />
            <br />
            <span>{{ $t('register.confirmYourId.note') }}</span>
          </div>
          <div class="right">
            <vUpload
              :limit="6"
              v-on:updateFileInfo="updatePOAInfo"
              :selfTip="true"
              :selfText="true"
              :class="{ finish: checkPocDocFile }"
              data-testid="updateIDInfo"
            >
              <div class="message" v-if="checkPocDocFile">
                <div class="done">{{ $t('register.btn.done') }}</div>
                <div class="uploadNew" :class="{ fr_button: lang === 'fr_FR' }">{{ $t('register.btn.uploadNew') }}</div>
              </div>
              <div class="message" v-else>
                <div class="top"><img src="@/assets/images/register/upload.png" alt="" /></div>
                <div class="bottom">{{ $t('register.btn.upload') }}</div>
              </div>
            </vUpload>

            <div v-if="showYoutube">
              <div class="video" @click="onPoaPlayerClick">
                Need help with your documentation? <br />
                <b>Check out our tutorial</b><img src="@/assets/images/register/youtube.png" />
              </div>
              <div class="dialog_wrapper" v-show="showPoaDialog">
                <div class="dialog">
                  <div class="el-dialog__header">
                    <i class="el-icon-close cursor-pointer" @click.stop.prevent="closePOA"></i>
                  </div>
                  <div id="poa-player-small"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </el-form>
</template>

<script>
import vUpload from '@/components/vUpload';
import idPoaForm from '@/components/home/UploadIdAddress/Form';
import confirmID from '@/mixins/register/confirmID';

export default {
  components: { vUpload, idPoaForm },
  mixins: [confirmID]
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/confirmID.scss';
</style>
