const bankInfoDetails = {
  beneAccName: {
    1: { show: true, value: 'Moneta Markets (PTY) LTD' },
    2: { show: true, value: 'Moneta Markets South Africa Proprietary Limited Client' },
  },

  // beneAccName: { show: true, value: 'Moneta Markets South Africa Proprietary Limited Client' },
  IBAN: { show: true, value: 'GB03SPPV23188494506592' },
  bankBeneAddress: { show: true, value: '1 Hood Avenue, Rosebank, Gauteng 2196, Johannesburg' },
  swift: { show: true, value: 'SPPVGB2L' },
  bankName: { show: true, value: 'Equals Money UK' },
  paymentRef: {
    show: true,
    value: 'Please include (Moneta trading account number) in the bank transfer reference field.',
  },
}
module.exports = {
  bank_infos(mode = 1) {
    let bank_infos = []
    bank_infos.push({
      title: 'common.field.beneAccName',
      info: bankInfoDetails.beneAccName[mode],
    })
    bank_infos.push({
      title: 'Beneficiary IBAN number',
      info: bankInfoDetails.IBAN,
    })
    bank_infos.push({
      title: 'Beneficiary Address',
      info: bankInfoDetails.bankBeneAddress,
    })
    bank_infos.push({
      title: 'Beneficiary Bank Swift code',
      info: bankInfoDetails.swift,
    })
    bank_infos.push({
      title: 'Beneficiary Bank Name',
      info: bankInfoDetails.bankName,
    })
    bank_infos.push({
      title: 'common.field.paymentRef',
      info: bankInfoDetails.paymentRef,
    })

    return bank_infos
  },
}
