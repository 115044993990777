export default {
  common: {
    liveChat: { desc: '<li><p>需要帮助?</p><span>在线客服</span></li>' },
    country: { international: '国际', australia: '澳大利亚', uk: '英国' },
    verification: { toSlide: '请拖动滑块完成拼图' },
    hover: {
      BankName: '接收资金的银行或金融机构的名称。',
      BankBeneficiaryName: '拥有接收资金的银行账户的个人或公司的名称。',
      AccountHoldersAddress: '接收资金的银行或金融机构的实际地址。',
      BankAddress: '拥有银行账户的个人或公司的实际地址。',
      BankAccountNumber: '银行账号或 IBAN（国际银行账号）是用于识别您身份的银行账户号码。',
      SwiftCode:
        'SWIFT 代码，也称为银行识别码 (BIC)，是一种用于识别国家、银行和分行的国际代码，在进行国际转账时需要使用该代码。',
    },
    field: {
      nameOnCard: '信用卡持有人姓名',
      first4Digits: '卡号前4位',
      last3Digits: '卡号前3位',
      first6Digits: '卡号前6位',
      last4Digits: '卡号後4位',
      bankName: '银行名称',
      bankAddress: '银行地址',
      bankBranch: '支行名称',
      branchAddress: '支行地址',
      bankCity: '银行所在城市',
      bankProvince: '银行所在省',
      bankCode: '银行代码',
      bankBranchCode: '分行代码',
      bankBeneficiaryName: '收款人姓名',
      bankBeneAccName: '收款帐户名称',
      bankBeneAccNum: '收款帐号',
      bankBeneAddress: '收款人地址',
      bankAccNum: '银行账号',
      bankAccName: '银行账户名',
      bankHolderAddress: '账号持有人地址',
      address: '地址',
      accName: '账户名',
      accNum: '账号',
      accNumTo: '账号',
      bsbNum: 'BSB号码',
      swift: 'SWIFT代码',
      region: '地区',
      sort: '银行代码(Sort Code)',
      ifscCode: 'IFSC 代码',
      swiftNonAUD: 'SWIFT代码 (非澳元账户)',
      amt: '数额',
      amtUSD: '数额(美元)',
      imptNotes: '备注',
      yes: '是',
      no: '否',
      payMethod: '支付方式:',
      expDate: '过期时间',
      bsb: 'BSB',
      abaSortCode: 'ABA/Sort 代码',
      country_region: '国家/地区',
      currLeverage: '目前杠杆',
      accEquity: '账户净值',
      newLeverage: '变更杠杆至',
      currPw: '现密码',
      newPw: '新密码',
      confirmPw: '确认新密码',
      loginEmail: '登录邮箱',
      email: '电子邮件',
      pw: '密码',
      accType: '账号类型',
      name: '姓名',
      emailAdd: '电子邮件地址',
      phone: '电话',
      countryCode: '国家/地区',
      dob: '生日',
      nat: '国籍',
      country: '居住国家',
      street: '街道名称 & 号码',
      suburbOrCity: '区/市',
      provinceOrState: '省/州',
      postcode: '邮编',
      empStat: '就业状况',
      occupation: '职业',
      annIncome: '年收入',
      save: '存款和投资',
      source: '资金来源',
      industry: '行业',
      statusDef: '状态注释',
      month: '月份',
      year: '年份',
      select: '请选择',
      completed: '已完成',
      incomplete: '未完成',
      submitted: '已提交',
      successful: '成功',
      processing: '处理中',
      cancelled: '取消',
      failed: '失败',
      rejected: '被拒绝',
      upload: '上传',
      ccNum: '信用卡号码',
      bankCardNumber: '银行卡号',
      cardHolderName: '持卡人姓名',
      reservedMobileNumber: '银行预留手机号码',
      nationalId: '身份证号',
      cardPhoto: '银行卡正面照',
      notes: '备注',
      bankAccOrIBAN: '银行账号/IBAN',
      selectWithdrawCard: '请选择出金卡',
      updateCardPhoto: '请上传银行卡正面照片',
      transitNumber: '转账号码',
      institutionNumber: '机构编号',
      wallet: '钱包',
      resetBalTo: '重置余额至',
      confirmation: '你确定吗?',
      removeNote: '您真的要删除此记录吗？此过程无法撤消。',
      depositCurrency: '入金货币',
      bankAccountCurrency: '银行账户货币',
      ipChangeWarning: '当有人从其他位置登录您的账户时收到提醒电子邮件',
      documentType: '文件类型',
      documentDetails: '文件详情',
      pixKeyType: 'PIX 密钥类型',
      pixKey: 'PIX 密钥',
      enterVerificationCode: '输入验证码',
      accDigit: '账号数位',
      docID: '文件 ID',
      paymentAccName: '{name} 账户名',
      paymentAccNum: '{name} 账号',
      beneficiaryIBANNumber: '受益人IBAN号',
      beneficiaryBankSwift: '受益人银行SWIFT代码',
      beneficiaryBankName: '受益人银行名称',
      beneficiaryBankAddress: '受益人银行地址',
      beneficiaryBankSortCode: '受益人银行分行代码',
      beneAccNum: '受益人账号',
      reversedfailed: '逆转失败',
      cardNumber: '卡号',
      walletNumber: '{name} 钱包号码',
      beneficiaryName: '受益人',
    },
    column: {
      date: '日期',
      type: '类型',
      server: '服务器',
      status: '状态',
      comment: '备注',
      accNum: '账号',
      currency: '货币类型',
      equity: '净值',
      credits: '积分',
      balance: '余额',
      leverage: '杠杆 ',
      resetMT4PW: '重置MT4登录密码',
      resetMT5PW: '重置MT5登录密码',
      tradingAcc: '交易账户',
      method: '方式',
      amt: '数额',
      procNote: '备注',
      number: '卡号',
      resetBal: '重置余额',
      remove: '删除',
    },
    key: { from: '从', to: '至' },
    fund: {
      must: '必须',
      infoHover: '持卡人姓名 {must} 与交易账户姓名一致',
      ccUploadReminder: '请上传您的信用卡正面照以便审核. (请遮挡中间6位数字)',
    },
    button: {
      bkToHm: '回到主页',
      smsLogin: '手机号登录',
      userLogin: '邮箱登录',
      goToIB: '前往IB后台',
      logout: '登出',
      submit: '提交',
      selectFile: '上传文件',
      depositFunds: '入金',
      withdrawFunds: '出金',
      ibPortal: 'IB后台',
      download: '下载',
      changeLeverage: '变更杠杆',
      cancel: '取消',
      changePw: '变更密码',
      forgetPw: '忘记密码',
      confirm: '确认',
      login: '登入',
      forgotPw: '忘记密码?',
      completeQuestionnaire: '完成调查问卷',
      identityProof: '完成帐户申请',
      upload: '上传',
      continue: '继续',
      enable: '启用',
      disable: '停用',
      export: '导出',
      ok: '确认',
      hide: '隐藏',
      unhide: '取消隐藏',
      showAll: '显示所有',
      beginner: '初级',
      intermediate: '中级',
      advanced: '高级',
      sendCode: '发送验证码',
      resendCode: '重发验证码',
    },
    formValidation: {
      common: '此栏必填。',
      accReq: '请填写帐号',
      amtReq: '请填写数额',
      ccReq: '请填写卡号',
      cardReq: '请填写卡号',
      cardOnNameReq: '请填写持卡人',
      digits16: '请填写16位卡号',
      digits3or4: '请填写3或4位卡号',
      digitsFirst6: '请填写卡号前6位',
      digitsLast4: '请填写卡号后4位',
      digitsLast3: '请填写卡号后3位',
      mid6Digits: '请填写卡号中间{number}位',
      expDateReq: '请填写过期时间',
      cvvReq: '请填写安全码 CVV',
      file: '请上传文件',
      filePhoto: '请上传卡照片',
      fileReceipt: '请上传收据',
      filePhotoReq: '请上传卡照片',
      amtLarger: '金额不能为空，同时必须大于或等于 ${minLimit} {currency}',
      withdrawAmtLarger: '最低取款金额为${minLimit} {currency}或等值金额。',
      amt0: '金额不能为0',
      amtLess: '金额不能大于 ${maxLimit} {currency}',
      note256: '字符串不能大于256个字',
      noteMoreThan: '字符串不能大于{limit}个字',
      noteLessThan: '字符串不能少于{limit}个字',
      emailFormat: '请填写正确的电邮地址',
      payMethodReq: '请填写付款方式',
      expReq: '请填写过期时间',
      bankNameReq: '请填写银行名称',
      bankCityReq: '请填写银行所在市',
      bankProvinceReq: '请填写银行所在省',
      bankAddressReq: '请填写银行所在地',
      bankBranchReq: '请填写银行支行',
      bankBranchCodeReq: '分行代码是必需的',
      bankAccNumReq: '请填写银行帐号',
      ifscCodeReq: '需要提供 IFSC 代码',
      bankBeneficiaryNameReq: '请填写收款人姓名',
      bankHolderAddressReq: '请填写持卡人住址',
      swiftReq: '请填写Swift号',
      accHolderAddress: '请填写帐号持有人住址',
      bsbReq: '请填写BSB',
      accNumIBANReq: '请填写银行账号/IBAN',
      accNameReq: '请填写帐号名称',
      withdrawMethodReq: '请选择出金方式',
      tnc: '请确认条款条件',
      currPwReq: '请填写密码',
      currPwWrong: '密码错误',
      newPwReq: '请填写新密码',
      newPwFormat:
        '您的密码必须在8-16个字符之间，并且是字母(a-z和A-Z)、数字(0-9)和特殊字符(如!@#$%^&*)的组合。',
      IdenficalPw: '新密码不能与旧密码相同',
      confirmPwReq: '请再次填写新密码',
      confirmNotMatch: '两次填写的新密码不一致',
      pwReq: '请填写密码',
      accCurrReq: '您必须选择一个货币类型',
      tradAccReq: '请选择一个交易帐号',
      answerReq: '请选择',
      bankCardReq: '您必须请选择一张卡',
      phoneNumberReq: '请填写银行卡预留手机号码',
      nationaIdReq: '请填写身份证',
      numberReq: '请输填写号码',
      alphanumericReq: '请填写字母数字',
      idTypeReq: '请选择证件类型',
      dobReq: '请选择出生日期',
      pobReq: '请选择出生地',
      nationalityReq: '请选择国籍',
      titleReq: '请选择头衔',
      firstNameReq: '请填写名字',
      lastNameReq: '请填写姓氏',
      emailReq: '请填写邮箱',
      addressReq: '请填写详细地址',
      suburbReq: '请填写城市或区',
      stateReq: '请填写省份或州',
      postcodeReq: '请填写邮政编码',
      countryReq: '请选择主要居住国家',
      NIReq: '请输入您的国民保险（NI）号码',
      taxResidencyReq: '请选择您的税务居住国家',
      yearsAtAddressReq: '请输入您在当前地址居住的年数',
      bankCodeReq: '请填写发卡行',
      dynamicReq: '请填写{dynamic}',
      mobReq: '请填写电话号码',
      confirmed: '请确认',
      resetBalAmt: '设置数值应在0到1,000,000之间',
      verCodeReq: '验证码为必填项',
      noEligibleAccounts: '无符合条件的帐户',
      AlphanumericOnlyNotSpace: '仅支持字母和数字。不允许使用空格。',
      AlphanumericOnly: '仅支持字母和数字。',
      NumbersSymbol: '仅支持数字和符号。',
      NumbersOnlyNotSpace: '仅支持数字。不允许使用空格。',
      NumbersOnly: '仅支持数字。',
      LettersSymbol: '仅支持字母和符号。',
      LettersSymbolNotSpace: '仅支持字母和符号。不允许使用空格。',
      LettersOnlyNotSpace: '仅支持字母。不允许使用空格。',
      NotSpace: '不允许使用空格。',
      beneficiaryNameReq: '需要受益人姓名',
    },
    withdrawChannel: {
      banktransferbpaypolipay: '银行转帐/BPay银行转帐/PoLi支付',
      banktransfer: '国际银行转账',
      skrill: 'Skrill',
      neteller: 'Neteller',
      unionpay: '中国银联',
      unionpaytransfer: '银联转帐',
      fasapay: 'FasaPay',
      creditcard: '信用卡',
      archiveThisCard: '如果此卡不再有效或不再使用，请归档此卡',
      update: '更新',
      archive: '归档',
      updateExpiryMsg: '请更新您的卡的有效期以使用您的卡取款',
      available: '可用的',
      pending: '待办的',
      unavailable: '不可用',
      fullyRefunded: '全额退款',
      requiredDocument: '归档卡所需的文件',
      officialLetter: '官方银行信函/电子邮件说明该卡不再可用',
      bankStatement: '使用此卡进行交易的银行对账单',
      expiredCard: '过期/不可用的卡',
      confirmArchive: '您确定要归档这​​张卡片吗？',
      confirmUpdate:
        '您确定要更新此卡的有效期吗？注：如因提供的到期日不正确导致提款失败，Moneta 概不负责',
      bpay: 'Bpay',
      creditdebitcard: '信用卡/借记卡',
      bankwiretransferinternational: '银行转帐 (国际)',
      banktransferaustralia: '银行转帐 (澳大利亚)',
      banktransferinternational: '国际银行转账',
      banktransferchina: '银行转帐 (国际)',
      banktransferuk: '银行转帐 (英国)',
      mobilepay: '移动支付',
      brokertobrokertransfer: '交易商到交易商',
      skrillmoneybookers: 'Skrill / Moneybookers',
      internetbankingthailand: '线上银行 (泰国)',
      internetbankingnigeria: '线上银行 (尼日利亚)',
      internetbankingvietnam: '线上银行 (越南)',
      internetbankingmalaysia: '线上银行 (马来西亚)',
      internetbankingindonesia: '线上银行 (印度尼西亚)',
      internetbankingkorea: '线上银行 (韩国)',
      internetbankingsouthkorea: '线上银行 (韩国)',
      internetbankingindia: '线上银行 (印度)',
      internetbankingphilippine: '线上银行 (菲律宾)',
      internetbankingsouthafrica: '线上银行 (南非)',
      internetbankinguk: '线上银行 (英国)',
      internetbankinglaos: '线上银行 (老挝)',
      internetbankinghk: '网上银行（香港）',
      internetbankinguganda: '线上银行 (乌干达)',
      internetbankingrwanda: '线上银行 (卢旺达)',
      internetbankingzambia: '线上银行 (赞比亚)',
      internetbankingcongo: '线上银行 (刚果)',
      internetbankingcameroon: '线上银行 (喀麦隆)',
      internetbankingburundi: '线上银行 (布隆迪)',
      internetbankingkenya: '线上银行 (肯尼亚)',
      internetbankingghana: '线上银行 (加纳)',
      internetbankingtanzania: '线上银行 (坦桑尼亚)',
      internetbankingbrazil: '线上银行 (巴西)',
      internetbankingmexico: '线上银行 (墨西哥)',
      internetbankingcolombia: '线上银行 (哥伦比亚)',
      internetbankingchile: '线上银行 (智利)',
      internetbankingperu: '线上银行 (秘鲁)',
      internetbankingusd: '网上银行（美元）',
      skrillneteller: 'Skrill / Neteller',
      vietnambanktransfer: '越南银行银行转帐',
      nigeriabanktransfer: '尼日利亚银行转帐',
      polipayment: 'Poli支付',
      bankwiretransferaustralia: '银行转帐 (澳大利亚)',
      thailandinstantbankwiretransfer: '泰国银行即时转账',
      nigeriainstantbankwiretransfer: '尼日利亚银行即时转账',
      vietnaminstantbankwiretransfer: '越南银行即时转账',
      laosinstantbankwiretransfer: '老挝即时银行电汇',
      malaysiainstantbankwiretransfer: '马来西亚银行即时转账',
      indonesiainstantbankwiretransfer: '印度尼西亚银行即时转账',
      indiainstantbanktransfer: '印度即时银行电汇',
      southafricainstantbankwiretransfer: '南非银行即时转账',
      philippinesinstantbankwiretransfer: '菲律宾银行即时转账',
      indialocalbankwiretransfer: '印度本地银行电汇',
      hongkongbanktransfer: '香港本地银行转账',
      ugandabanktransfer: '乌干达当地银行转账',
      rwandabanktransfer: '卢旺达当地银行转账',
      zambiabanktransfer: '赞比亚当地银行转账',
      congobanktransfer: '刚果当地银行转账',
      cameroonbanktransfer: '喀麦隆当地银行转账',
      burundibanktransfer: '布隆迪当地银行转账',
      kenyabanktransfer: '肯尼亚当地银行转账',
      ghanabanktransfer: '加纳当地银行转账',
      tanzaniabanktransfer: '坦桑尼亚当地银行转账',
      brazilbanktransfer: '巴西银行转账',
      mexicobanktransfer: '墨西哥本地银行转账',
      pagsmileBrazil: '巴西银行即时转账',
      pagsmileMexico: '墨西哥银行即时转账',
      pagsmileColombia: '哥伦比亚银行即时转账',
      pagsmileChile: '智利银行即时转账',
      pagsmilePeru: '秘鲁银行即时转账',
      banktransferbpaypolipayaustralia: '银行转帐/BPay银行转帐/PoLi支付 (澳大利亚)',
      banktransferbpaypolipayinternational: '银行转帐/BPay银行转帐/PoLi支付 (国际)',
      paypal: 'Paypal',
      canadianlocalbanktransfer: '加拿大本地银行转帐',
      cryptobitcoin: '加密货币-Bitcoin',
      cryptotetheromni: '加密货币-USDT(OMNI)',
      cryptotethererc20: '加密货币-USDT(ERC20)',
      cryptotethertrc20: '加密货币-USDT(TRC20)',
      cryptocurrencybitcoin: '加密货币-Bitcoin',
      cryptocurrencyusdt: '加密货币-USDT',
      bitwallet: 'Bitwallet',
      sticpay: 'SticPay',
      trustly: 'Trustly',
      astropay: 'Astropay',
      webmoney: 'WebMoney',
      vload: 'Vload',
      dasshpewallet: '印度钱包',
      cryptocurrencyeth: '加密货币-ETH',
      cryptocurrencyethcps: '加密货币-ETH',
      cryptocurrencyusdc: '加密货币-USDC(ERC-20)',
      cryptocurrencyusdcerc20: '加密货币-USDC(ERC-20)',
      cryptocurrencyusdccps: '加密货币-USDC(ERC-20)',
      chileBankTransfer: '智利银行转账',
      colombiaBankTransfer: '哥伦比亚银行转账',
      perubanktransfer: '秘鲁银行转账',
      alipay: '支付宝',
    },
  },
  home: {
    appNote: { desc02: '请点击这里了解更多。' },
    app: {
      title: '它是如何运作的',
      desc01: '下载{platform}',
      desc02: '使用您现有的电子邮件地址登录',
      desc03: '验证您的电话号码',
      desc04: '您已经准备好了。享受一流的交易体验',
    },
    pendingTooltip:
      '您的真实帐户获得批准后，您将收到一封包含MT{number}登录详细信息的电子邮件，以便您可以开始交易！',
    leverage: {
      tncTitle: '<span>条款条件</span>',
      tnc: '<p>通过提交此请求，我承认我已了解高杠杆会给我的资本带来高风险，且承受的损失可能大于但不限于我所存入的资本。<br><br>我同意产品发行方有权自行决定审查我的交易活动，并调整我的交易帐户的杠杆水平，且不会事先发出任何警告或通知。<br><br>我了解选择高杠杆可以使我获得更大的保证金，但也可能会造成重大损失。</p>',
      tncConfirm: '我已经阅读并同意条款和条件',
      changeLeverageConfirm: '您的请求已提交，我们会尽快为您处理',
      changeLeverageAutoComplete: '您的杠杆修改申请已完成',
      changeLeverageReject: '您的杠杆修改申请被拒绝',
      changeLeverageFail: '杠杆修改申请失败, 请重试',
      changeLeverageDefault: '发生错误, 请联系{supportEmail}关于您的杠杆修改申请',
      alreadyApplied:
        '您已经提交更改账户杠杆的请求, {platform} 的客服团队正在审查您的请求。在提交另一个请求之前，请等待我们对此次请求的审查。',
      reduceLeverageWarn: '请注意：如果您的账户保证金不足，减少杠杆会导致未结头寸被迫平仓。',
      reduceLeverageConfirm: '你想继续吗？',
      autoRestore:
        '<p>由于您的equity >= 20,000，您可使用的杠杆为500:1</p><br /><p>当您的equity再次小于20,000后，您可重新调整至1000:1</p>',
      oneThousandRatio:
        '<p>如果您想换成 1000:1 的杠杆，请参考以下条款：<br><br>1. 您无法申请任何与信用积分有关的促销活动<br><br>2. 在申请时，您的账户资产不能超过 15,000 美元或同等价值<br><br>3. 如果您的账户净值超过 20,000 美元或同等价值，账户杠杆将被转换为 500:1<br><br>4. 杠杆限制可能会根据适用的法规而变化<br><br>5. 杠杆的变化不适用于设有固定杠杆的产品<br><br>6. 根据客户协议，如果您违反违约事件和/或可疑交易活动的相关条款，将采取包括但不限于终止或扣除等措施<br><br></p>',
    },
    pw: {
      error: '重置密码失败。 请稍后再试一次',
      confirm: '您的密码已经更新<br>您现在可以使用您的新密码登录您的MT4/MT5账户',
      email:
        '<p class="first_info">重置密码的邮件已经发送至您的邮箱</p><p>请确保您已经检查了垃圾邮件信箱</p>',
    },
    activate: {
      question: '您希望重新激活您的交易账户么？',
      confirm: '我们已经收到您的请求<br>您的账户将会很快被激活',
    },
    accStatus: {
      active: '启用',
      rejected: '拒绝',
      inactive: '未启用',
      processing: '处理中',
      activate: '激活',
      expired: '失效',
    },
    reset: {
      success: '重置成功。',
      creditTip: '您的账户余额为负数。将账户余额重置为 0，这也会将信用重置为 0。是否确定要继续？',
      resetBtn: '重置',
      tableHeader: '负数余额',
    },
  },
  oneThousandRatioError: {
    error1: '该账号有赠金，无法调整至1000:1杠杆',
    error4: '该账号equity大于$15,000（或等值货币），无法调整至1000:1杠杆',
    error5: '该账号未通过ID审核，无法调整至1000:1杠杆',
    error6: '该账号已参加赠金活动，无法调整至1000:1杠杆',
  },
  paymentDetails: {
    subHeader: '添加支付方式',
    payMethod: '支付方式',
    tip: '<p>请从下面的菜单中选择您希望使用的出入金方式</p><br />\n    <p><b请注意：</b> 为了确保我们可以及时处理您的出入金请求，根据AML/CTF法规规定，您在选择的支付方式的中的姓名必须是<b> {individualUserName}</b></p>',
    result: {
      defaultSuccessMessage:
        '感谢您为交易帐户提交入金方式。我们正在处理您的请求，并会尽快与您联系。',
      unionPayCardSuccessMessage:
        '您的银联卡绑卡申请已提交, 在审核完成后, 您就可以使用此银行信息进行出金',
    },
    history: { header: '支付方式' },
    cc: {
      field: {
        ccNum: '信用卡号码',
        first6: '前六位',
        last4: '后四位',
        withrawlable: '此卡片的最大可用提款额为 {amount}。',
      },
      formValidation: { upload: '请上传卡照片' },
    },
  },
  thirdPartyDeposit: {
    result: {
      countDownMsg: '正在获取入金信息, 还有 {countDown} 秒',
      successMsg: '感谢您的入金, 您的入金将很快在您的交易账户中显示',
      rejectMsg: '拒绝',
      failureMsg: '您的支付失败了,请重试',
      message:
        '感谢您的入金, {platform} 团队需要手动进行审核这笔交易. 审核成功后, 资金将会立刻存入到您的交易账户中',
      defaultException: '如要使用{platform}确认您的存款，请联系支持{email}',
    },
  },
  deposit: {
    default: {
      deposit: '入金',
      reference: '备注',
      sendReceipt: '发送收据',
      sendReceiptTip:
        '当您完成转账后，请完成下面的表格并附上您转账收据的截图、照片或复印件，请确保转账收据上能够清楚地显示您的全名，账号和转账金额，以便我们处理您的转账。',
      reminder: '我们需要1-2个工作日将您的入金放到您的交易账户中',
      successMsg: '您的入金将会在我们收到并确认您的转账后显示在您的交易账户中',
      alt: '您也可以将您的转账收据发送到 {mailTo}',
      reminderFundAppear: '您的资金将会在我们从您之前的交易商处获取后立即出现在您的交易账户中',
      failed: '入金失败。 请稍后再试一次',
      fee: '费用',
      instant: '即时',
      hour: '小时',
      hours: '小时',
      businessDays: '工作天',
      disclaimer:
        '<li>1. 您在 {platform} 的所有付款都是保密的，并且仅用作在 {platform} 的交易账户资金出入使用。电子支付使用SSL(安全套接层)技术进行处理，并进行加密以确保安全性。</li>\n      <li> </br>2. {platform} 不接受来自第三方支付渠道的入金。请务必使用和您交易账户同名的银行账户/信用卡进行入金。如果交易账户持有人和银行账户/信用卡的付款人不是同一个名字，{platform} 将拒绝您的入金申请。</li>\n      <li> </br>3. 您应当理解，提交入金申请并不代表 {platform} 接受您的资金。如果您的交易账户正在接近或正被要求追加保证金，请确保您已经提前做好准备。</li>\n      <li> </br>4. {platform} 不会对任何由支付系统造成的延迟或错误负责。</li>\n      <li> </br>5. {platform} 不会对任何第三方支付方式提供者或您的银行对您收取的费用负责。</li>\n      <li> </br>6.请注意，Moneta Markets 在您的计费系统上的说明描述将显示为 <a href="https://www.monetamarkets.com/" target="_blank">www.monetamarkets.com</a> - Gladstonos & Filokyprou, 120, House Office E2<li>',
      channels: {
        creditOrDebit: '信用卡或借记卡入金',
        i12BankTransfer: '国际银行转账',
        auBankTransfer: '澳大利亚银行转账',
        bPay: 'BPAY银行转账',
        skrill: 'Skrill/Moneybookers',
        b2b: '交易商到交易商转账表单',
        unionPay: '银联',
        unionPayCN: '中国银联转账',
        vietInstant: '越南银行即时转账',
        thaiInstant: '泰国银行即时转账',
        nigeriaInstant: '尼日利亚银行即时转账',
        laosInstant: '老挝即时银行电汇',
        malayInstant: '马来西亚银行即时转账',
        indonesiaInstant: '印度尼西亚银行即时转账',
        philippineInstant: '菲律宾银行即时转账',
        mobilePay: '移动支付',
        poli: 'PoLi支付',
        paypal: 'PayPal支付',
        mijipay: 'Mijipay支付',
        crypto: '加密货币',
        tinkbit: 'Tinkbit支付',
        bitwallet: ' bitwallet支付',
        indiaInstant: '印度即时银行电汇',
        canada: 'Interac电子转帐',
        pagsmileBrazil: '巴西银行即时转账',
        pagsmileMexico: 'Mexico Instant Bank Transfer',
        pagsmileColombia: 'Colombia Instant Bank Transfer',
        pagsmileChile: 'Chile Instant Bank Transfer',
        pagsmilePeru: 'Peru Instant Bank Transfer',
        astropay: 'Astropay支付',
        dasshpeWallet: '印度钱包',
        southkoreabanktransfer: '韩国银行转账',
        alipay: '支付宝',
        indonesiainstantbanktransfer: '印度尼西亚银行即时转账',
        thailandinstantbanktransfer: '泰国即时银行转账',
      },
      rate: {
        rate: '汇率（{oldCurrency}/{newCurrency}）: ',
        result: { rateError: '获取汇率失败', channelError: '获取入金渠道失败' },
      },
      cn: { rate: '汇率（美元/人民币）: ', amt: '人民币: ' },
      viet: {
        result: { rateError: '获取汇率失败' },
        rate: '汇率（美元/越南盾）：',
        amt: '越南盾：',
      },
    },
    method: {
      desc: '如何通过{method}入金',
      inst1: '{number}.   完成下面的表单并点击“提交”',
      inst2: '{number}.   在银行选择页面选择您的银行，并登入您的{country}在线网银',
      inst3: '{number}.   按照提示，通过您的手机银行/在线网银完成入金',
      inst4: '{number}.   您将被重定向回客户后台，并且您的交易将完成',
      inst5: '{number}.   登入您的{method}账户',
      inst6: '{number}.   按照{method}帐户中的提示完成付款',
      inst7:
        '请注意：如果选择手动存款，您的资金可能会造成延迟，并需要很长的时间才能存入您的交易账户',
      inst8:
        '{number}.   请注意，如果您使用的是 {payment}，则每笔交易的存款金额仅限于 {currency} {amount}',
      form: { header: '{method}表单' },
      inst12: '{number}.   完成以下表格并选择“提交”',
      inst13: '{number}.   扫描二维码以打开您的手机银行应用',
      inst14: '{number}.   按照移动设备上的提示完成存款',
      inst15: '{number}.   完成下面的表格并按“提交”',
      inst16: '{number}.   输入卡片信息后，按“提交”',
    },
    cpsInfo: { thispaymentchannelistemporaryunavailable: '此支付通道暂时不可用。' },
    uk: { header: '英国银行即时转账', country: '英国' },
    southAfrica: { header: '南非银行即时转账', country: '南非' },
    idn: { header: '印度尼西亚银行即时转账', country: '印度尼西亚' },
    ida: { header: '印度银行即时转账', country: '印度' },
    brazil: { header: '巴西银行即时转账', country: '巴西' },
    nigeria: { header: '尼日利亚银行即时转账', country: '尼日利亚' },
    thailand: { header: '泰国银行即时转账', country: '泰国' },
    vietnam: { header: '越南银行即时转账', country: '越南' },
    laos: {
      header: '老挝即时银行电汇',
      country: '老挝',
      inst: '2.   使用 OnePay 应用程序扫描二维码',
    },
    hongkong: {
      country: '香港',
      inst1: '2.   按照说明进行付款',
      inst2: '3.   您的交易将完成',
      inst3: '请按照以下说明进行付款：',
      inst4: '1.   请登录您的银行账户。',
      inst5: '2.   转账至:',
      inst6: '3.   完成付款后，请点击「完成」按钮继续。',
      inst7: '账户仅适用于一次性存款',
      inst8: '注意：',
      inst9: '生成的此帐号仅对一次性存款有效，不得用于以后的交易，以免资金到账延迟。',
      inst10: '转账的付款金额必须与存款请求中提交的金额一致。',
      inst11: '请务必在提交存款申请后1小时内进行银行转账，以免造成存款失败。',
    },
    malay: { header: '马来西亚银行即时转账', country: '马来西亚' },
    php: { header: '菲律宾银行即时转账', country: '菲律宾' },
    uganda: { header: '乌干达当地银行转账', country: '乌干达' },
    rwanda: { header: '卢旺达当地银行转账', country: '卢旺达' },
    zambia: { header: '赞比亚当地银行转账', country: '赞比亚' },
    congo: { header: '刚果当地银行转账', country: '刚果' },
    cameroon: { header: '喀麦隆当地银行转账', country: '喀麦隆' },
    burundi: { header: '布隆迪当地银行转账', country: '布隆迪' },
    kenya: { header: '肯尼亚当地银行转账', country: '肯尼亚' },
    ghana: { header: '加纳当地银行转账', country: '加纳' },
    tanzania: { header: '坦桑尼亚当地银行转账', country: '坦桑尼亚' },
    bankWireAu: {
      header: '银行转账: 澳大利亚',
      instruction:
        '入金可以通过您的在线网银转至 {platform}，您也可以通过联系您的银行将入金转至 {platform}.',
      tip: '选择您想存入账户的货币类型。请确保您选择了与交易账户相同的货币类型',
      form: {
        bankDetails: '银行信息（仅限澳币入金）',
        refTip: '请注明您的账户号码',
        afp: '澳大利亚快速支付（仅限本地澳币入金）',
        payIdOrABNNum: 'PayID/ABN号码',
        payIdName: '{platformEntityName} Pty Ltd',
        bankTransferRefTip: '请在备注中注明您在 {platform} 的交易账户号码',
      },
    },
    bPay: {
      header: 'BPAY入金',
      option: '如何通过BPAY方式入金（仅限澳大利亚）',
      inst1: '1.   选择您的MT4/MT5账户查看您的BPay 开账人代码和BPay 参考编号',
      inst2:
        '2.   在您的网上银行支付界面中输入您的支付信息，BPay 开账人代码和BPay 参考编号后提交支付请求',
      inst3: '3.   返回到这个页面并完成下面的BPAY表单，以确保我们可以尽快处理您的入金',
      form: {
        header: 'BPAY表单',
        billerCode: 'BPay 开账人代码',
        refNum: 'BPay 参考编号',
        receipt: 'BPAY转账收据',
        reminderNotUploaded:
          '我们发现您还没有上传您的转账收据，为了尽快处理您的入金，请将您的转账收据尽快发送到 {mailTo}',
        reminderProc: '请注意，在没有收到您的转账收据之前，我们无法处理您的入金',
      },
    },
    b2b: {
      header: '交易商到交易商',
      option: '如何从其他交易商处将资金转至 {platform}',
      inst1: '1.   下载并完成 {download}',
      inst2: '2.   完成以下在线表单，并上传填写完成的交易商到交易商转账表单',
      inst3: '3.   将交易商到交易商转账表单发送到您之前的交易商处，以开始转账流程',
      b2bForm: '交易商到交易商转账表单',
      formValidation: { upload: '请上传交易商到交易商转账表单' },
    },
    cc: {
      header: '信用卡或借记卡入金',
      remind: '如果您在使用 "主要" 方法入金时遇到任何困难，请尝试使用 "次要" 贷记/借记卡入金方法.',
      field: {
        fundNow: '立刻使用信用卡/借记卡入金',
        newCard: '使用新卡',
        cardNum: '信用卡号码',
        nameOnCard: '信用卡持有人姓名',
        expDate: '过期时间',
        cvv: '安全码 CVV',
        bankCode: '发卡行',
      },
      placeholder: { bankCode: '请输入您的发卡行名称' },
      cvvTip: '卡背面显示的3位数字',
      threeDomainSecQ: '您的卡支持3D-Secure吗？',
      threeDomainSec:
        '<strong>3D安全验证（3D Secure）是一种Visa和万事达（Mastercard）信用卡认可的防欺诈方式。<br /><br />支持3D安全验证的信用卡入金将会即刻到账</strong>',
      cardUpload:
        '您正在使用的银行卡尚未链接到您的交易账户上，请上传您的信用卡正面照以便审核. (请遮挡中间6位数字)',
      result: {
        success:
          '感谢您的入金, 我们现正在核实您的卡信息, 一经核实, 您的入金将会在24小时内显示于您的帐户中',
        depositSuccess: '感谢您的入金, 您的入金将很快在您的交易账户中显示',
        error: '为了保护您帐户的安全，您已达到最大的尝试存款交易次数，请联系{email}',
      },
      mastercardonly: '请继续仅使用万事达卡通过此支付渠道进行支付。',
    },
    fasa: {
      header: 'FASAPAY入金',
      description: '如何使用Fasapay入金',
      inst1: '1.   完成下面的表单并点击“提交”',
      inst2: '2.   登入您的Fasapay账户',
      inst3: '3.   按照FasaPay帐户中的提示完成付款',
      inst4: '4.   Fasapay存款的每笔交易限额为25,000美元，每天最高限额为75,000美元',
      form: { header: 'FASAPAY表单' },
    },
    miji: {
      header: '泰国即时银行电汇',
      description: '如何通过泰国即时银行电汇为您的账户注资',
      inst1: '1.   填写下表并点击“提交”',
      inst2: '2.   提交后会跳转至支付页面',
      inst3: '3.   登陆您的手机银行APP并扫描支付页面上的二维码',
      inst4: '请注意：<b>系统会在您提交的金额上加收以分为单位的微小数额以作验证.</b>',
      form: { header: '泰国银行即时转账表单' },
    },
    intSwift: {
      header: '银行转账（国际SWIFT）',
      desc: '入金可以通过您的在线网银转至 {platform} ，您也可以通过联系您的银行将入金转至 {platform}.',
      desc2:
        '当您完成转账后，请完成下面的表格并附上您转账收据的截图、照片或复印件，请确保转账收据上能够清楚地显示您的全名，账号和转账金额，以便我们处理您的转账。',
      options: '请选择您的交易账户',
      refInfo: '请在银行转账是将您的 {platform} 交易账户填写在转账备注中',
      bussDayInfo: '我们需要2-5个工作日将您的入金放到您的交易账户中',
      tip: '请确保您的转帐收据清晰显示您的<b>全名</b>，<b>交易帐号</b>和<b>存款金额</b>',
    },
    mobile: {
      header: '移动支付',
      desc: '如何通过移动支付入金',
      inst1: '1.   完成下面的表单并点击“提交”',
      inst2: '2.   扫描二维码，打开您的手机银行应用程序',
      inst3: '3.   按照手机上的提示完成入金',
      form: { payMethod: '支付方式:', rate: '汇率（美元/人民币）:', amt: '人民币:' },
      formValidation: { cnyAmt: '人民币金额不能多于${limit}' },
    },
    net: {
      header: 'Neteller',
      inst2: '2.   登入您的Neteller账户',
      inst3: '3.   按照Neteller帐户中的提示完成付款',
      inst4: {
        fca: '4.   我们接受美元、欧元和英镑',
        default: '4.   我们接受澳元、美元、欧元、英镑和新加坡币',
      },
    },
    canada: {
      header: 'Interac电子转帐',
      desc: '如何通过Interac电子转帐为您的帐户入金',
      inst1: '1.   填写下面的表格，然后按“提交’',
      inst2: '2.   在银行选择页面中选择银行，然后登录到您的在线银行',
      inst3: '3.   请按照说明进行操作，并通过您的在线/移动银行付款',
      inst4: '4.   我们可以接受加元付款。每次存款最高$ 9,999 CAD',
      form: { header: 'Interac电子转帐表格' },
    },
    bitwallet: {
      header: 'BITWALLET入金',
      desc: '如何通过BITWALLET入金',
      inst1: '1.   完成下面的表单并点击“提交”',
      inst2: '2.   登入您的BITWALLET账户',
      inst3: '3.   按照BITWALLET帐户中的提示完成付款',
      inst4: '4.   我们接受美元、欧元、英镑和日币',
      form: { header: 'BITWALLET表单' },
    },
    astropay: {
      header: 'ASTROPAY入金',
      desc: '如何通过ASTROPAY入金',
      inst2: '2.   登入您的ASTROPAY账户',
      inst3: '3.   按照ASTROPAY帐户中的提示完成付款',
      inst4: '4.   我们可以接受所有货币的付款',
      form: { header: 'ASTROPAY表单' },
    },
    crypto: {
      choose: '请选择您想要入金的加密货币类型',
      proceedQuestion: '您是否要继续进行 <b>{method}</b> 入金?',
      remark:
        '请注意，我们无法通过 BUSDT 进行存款或取款，请确保地址和加密货币与我们接受的链和货币匹配，否则您可能会损失资金。',
      inst2: '2.   您将会被转到一个显示{method}钱包地址和数额的新窗口',
      inst3:
        '3.   复制{method}钱包地址，然后登录到您的个人{method}钱包，然后将所需金额转出到页面上的{method}地址',
      inst4: '请注意：比特币入金由B2BinPay以交易时提供的汇率处理',
    },
    poli: {
      header: 'POLI入金',
      desc: '如何通过POLI入金',
      reminder:
        '<li>请注意：</li><li>* 入金必须与您的 {platform} 账户姓名一致</li><li>* 仅限澳元入金</li>',
      form: { header: 'POLI表单' },
    },
    skrill: {
      header: 'SKRILL/MONEYBOOKERS入金',
      desc: '如何通过Skrill入金',
      inst1: '1.   完成下面的表单并点击“提交”',
      inst2: '2.   登入您的Skrill账户',
      inst3: '3.   按照Skrill帐户中的提示完成付款',
      inst4: '4.   我们接受美元、欧元、英镑和加币',
      inst4FCA: '4.   我们接受美元、欧元和英镑',
      form: { header: 'SKRILL表单', email: 'Skrill/Moneybookers电子邮件地址' },
      reminder:
        '<li>请注意：</li>\n      <li>* 入金的货币类型必须要与您MT4交易账户一致，如果您使用了不正确的电子邮件地址，Skrill会自动为您转换货币类型。可能会向您收取额外的费用</li>\n      <li>* 入金必须与您的 {platform} 账户姓名一致</li>',
    },
    sticPay: { inst2: '2.   登入您的SticPay账户', inst3: '3.   按照SticPay帐户中的提示完成付款' },
    webMoney: {
      header: 'WEBMONEY',
      inst4: '4.   我们接受美元和欧元',
      inst5: '5.   WebMoney存款的每笔交易限额为1300美元/1100欧元',
    },
    vload: {
      header: 'VLOAD',
      inst1: '2.   我们可以接受美元、欧元和日元的付款。',
      inst2:
        '<li>3.   购买Vload凭证，点击 <a href="https://pl.vload.com/buy" target="_blank" rel="noopener noreferrer">此处</a>.</li>',
      form: { voucherpin: '凭证PIN', voucherpinReq: '请填写凭证PIN' },
    },
    dasshpe: {
      headerWallet: '印度钱包',
      inst1: '2.   由您的 UPI 应用程序批准交易',
      inst2: '2.   请按照说明指示，通过钱包应用程序进行支付',
    },
    thaiPaytoday: {
      header: '泰国银行即时转账',
      desc: '如何通过泰国银行即时转账入金',
      inst1: '1.   完成下面的表单并点击“提交”',
      inst2: '2.   根据提示完成支付',
      form: { header: '泰国银行即时转账表单' },
      scan: '扫描以入金',
      exp: '{expireTime}秒后过期',
      reminder:
        '资金应在1小时内显示在您的帐户中。<br/><br/>\n      您可以在“交易记录”中查看存款的当前状态。<br/>\n      如果您在存款时遇到任何问题，请联系您的客户经理<br/>',
    },
    unionPay: {
      header: '银联入金',
      desc: '如何通过银联入金',
      inst1: '1.   完成下面的表单并点击“提交”',
      inst2: '2.   在银行选择页面选择您的银行，并登入您的在线网银',
      inst3: '3.   按照提示，通过您的手机银行/在线网银完成入金',
      form: { header: '银联入金表单' },
      tip: '尊敬的客户：<br/>由于中国工商银行的系统限制，目前暂不受理中国工商银行的出金申请。为了不影响您的出金进度，请您选择其他银行提交，感谢您的配合！',
    },
    unionPayCN: {
      header: '中国银联转账',
      desc: '如何通过中国银联转账入金',
      inst1: '1.   完成下面的表单并点击“提交”',
      inst2: '2.   支付信息页面将会弹出',
      inst3: '3.   按照提示，通过您的手机银行/在线网银完成入金',
      inst4: '请注意, 在入金期间你可能会被问到会员号码, 请输入你的交易帐号作为会员号码',
      form: {
        header: '中国银联入金表单',
        holderName: '银联卡持有人姓名:',
        holderNameInfo: '请确保您填写了与银行卡持有人相同的姓名',
      },
    },
    vietZota: {
      header: '越南银行即时转账',
      desc: '如何通过越南银行即时转账入金',
      inst: '<li>1.   完成下面的表单并点击“提交”</li>\n      <li>2.   在银行选择页面选择您的银行，并登入您的越南在线网银</li>\n      <li>3.   按照提示，通过您的手机银行/在线网银完成入金</li>\n      <li>4.   您将被重定向回客户后台，并且您的交易将完成</li>',
      form: { header: '越南银行即时转账表单' },
    },
    paypal: {
      header: 'PayPal',
      desc: '如何通过PayPal入金',
      inst1: '1.   完成下面的表单并点击“提交”',
      inst2: '2.   登入您的PayPal账户',
      inst3: '3.   按照提示，通过您的PayPal账户完成入金',
      inst4: '请注意: 我们不接受第三方渠道入金',
      form: { header: 'PAYPAL表单' },
    },
    cardpay: {
      desc: '如何通过Credit Card入金',
      inst1: '1.   填写下面的表格，然后按“提交”',
      inst2: '2.   然后，您将被跳转到到另一页面来输入您的卡详细信息',
      inst3: '3.   输入您的银行卡详细信息后，按“提交”',
      note: "请注意，出现在您的银行卡对帐单上的交易描述会因所使用的金融网关而有所区别。如果您对交易描述有任何疑问，请联系 <a href='mailto:{mail}'>{mail}</a>",
      form: { header: 'Credit Card表单' },
    },
    tinkbit: { inst5: '5.   每次存款最高 $ 1500 USD' },
    trustly: {
      desc: '如何通过Trustly入金',
      inst1: '1.   完成下面的表单并点击“提交”',
      inst2: '2.   登入您的Trustly账户',
      inst3: '3.   按照Trustly帐户中的提示完成付款',
      inst4: '4.   我们接受欧元和英镑',
      form: { header: 'TRUSTLY表单' },
      reminder:
        '<li>请注意：</li>\n      <li>* 入金的货币类型必须要与您MT4交易账户一致，如果您使用了不正确的电子邮件地址，Trustly会自动为您转换货币类型。可能会向您收取额外的费用</li>\n      <li>* 入金必须与您的 {platform} 账户姓名一致</li>',
    },
    fxir: {
      desc: '{number}.    请填写下面的表格并选择“提交”\n',
      desc2:
        '{number}.    为了成功和迅速存款，请以与您的交易账户相同的货币转账，并在上传转账收据后创建存款请求。',
      desc1: '{number}.    请填写下面的表格并选择“提交”',
      desc3:
        '一旦您完成转账，请填写下面的表格并附上一份清晰显示您的全名、FX-IR ID和存款金额的银行转账收据，以便我们处理您的交易。',
      desc4: '重要提示！请在转账备注栏中包含（您的交易账号）。',
      field: {
        id: '发送者 FX-IR ID',
        accName: 'FX-IR 账户名称',
        walletNumber: 'FX-IR 钱包号码',
        transactionID: '交易编号',
        senderFxirID: '发送者 FX-IR ID',
      },
    },
  },
  restrictDeposit: { header: '重要通知', info: '很遗憾，我们不再接受居住在加拿大的客户的存款。' },
  withdraw: {
    default: {
      selectCC: '选择信用卡',
      selectBA: '选择银行账户',
      anotherCC: '手动输入信用卡',
      anotherBA: '添加银行帐户',
      remember: '记住我的帐号',
      success: '您的出金请求已成功提交，我们会尽快为您处理。',
      ccFail:
        '为了保护您帐户的安全，您已达到最大尝试存款交易次数，我们暂时中止了您的出金。请联系{email}',
      fetchTradeAccFailed: '获取交易帐户失败',
      failed: '出金失败, 请稍后再尝试',
      notEnoughFunds: '输入的数量超过了本卡的限制',
      cardExpird: '此卡已过期. 请选择其他卡或其他付款方式.',
      minAmountWarn:
        '申请的提款金额小于申请的支付网关的最低金额。在提交提款请求之前，请将您的账户余额充值至/超过最低限额。更多信息可以在<a href="https://{regulatorUrl}/clients/accounts/withdraw/">这里</a>找到。',
      noCardWarn: '没有有效的卡, 请先绑卡',
      equityStop:
        '出金后您的预付款比例预计小于或等于平台的强制平仓比：100%，鉴于此风险您的出金申请无法提交',
      equityMargin: '由于账号处于完全锁仓状态，您的出金申请无法提交',
      equityContinue: '我们检测到您目前正在交易, 申请此次出金可能导致爆仓, 请确认',
      YourPaymentGreaterThanAvailableBalance: '您的出金金额大于可用余额',
      blackList: '很遗憾，您目前无法提交提款请求。请联系{supportEmail}取得更多信息',
      NetellerChargeAlert:
        'Neteller 出金限额为每笔交易 500,000 美元，并收取 2% 的手续费（上限为 30 美元）。',
      FasaPayChargeAlert: 'FasaPay 对每次出金收取 0.5% 的手续费。',
      SkrillChargeAlert: 'Skrill 对每次出金收取 1% 的手续费。',
      localBankTransfer: '本地銀行轉帳',
    },
    fasa: {
      field: { accName: 'FasaPay帐户名', accNum: 'FasaPay帐号' },
      formValidation: { accNameReq: '请填写FasaPay帐户名', accNumReq: '请填写FasaPay帐号' },
    },
    neteller: {
      field: { email: 'Neteller电邮' },
      formValidation: { emailReq: '请填写Neteller电邮' },
    },
    bitcoin: {
      field: { address: 'Bitcoin钱包地址' },
      formValidation: { addressReq: '请填写Bitcoin钱包地址' },
    },
    eth: { field: { address: 'ETH钱包地址' }, formValidation: { addressReq: '请填写ETH钱包地址' } },
    usdc: {
      field: { address: 'USDC钱包地址' },
      formValidation: { addressReq: '请填写USDC钱包地址' },
    },
    usdt: {
      field: { address: 'USDT钱包地址' },
      formValidation: { addressReq: '请填写USDT钱包地址' },
      note: '受ERC20的矿工费上涨影响，出金处理时间也相应延迟。为了保障您的出金处理速度，您可以选择使用TRC20，或支付较高的矿工费申请出金，以加快您的出金申请进度。',
    },
    paypal: { field: { email: 'Paypal电邮' }, formValidation: { emailReq: '请填写Paypal电邮' } },
    skrill: { field: { email: 'Skrill电邮' }, formValidation: { emailReq: '请填写Skrill电邮' } },
    webMoney: {
      field: { email: 'WebMoney电邮' },
      formValidation: { emailReq: '请填写WebMoney电邮' },
    },
    vload: {
      field: { email: '已注册Vload电邮' },
      formValidation: { emailReq: '请填写已注册Vload电邮' },
    },
    sticpay: { field: { email: 'Sticpay电邮' }, formValidation: { emailReq: '请填写Sticpay电邮' } },
    bitwallet: {
      field: { email: 'Bitwallet电邮' },
      formValidation: { emailReq: '请填写Bitwallet电邮' },
    },
    astropay: {
      field: { accountNumber: 'Astropay 账户' },
      formValidation: { accountNumberReq: '需要 Astropay 账户' },
    },
    transfer: {
      info: '{platform} 会垫付每个月第一次出金所产生的银行费用. 当月内的其他出金将会产生20单位出金货币的银行费用.',
      label: {
        upload: '请上传一张近3个月银行对账单的截图、照片或复印件',
        upload2: '请确保对账单日期或最后交易日期为最近3个月，若超出此时间范围，请重新上传对账单。',
      },
      formValidation: { upload: '请上传银行帐单' },
    },
    ccMethod: {
      desc1: '根据洗钱防制与打击资助恐怖主义规定，资金必须转入您首次进行存款的卡片',
      desc2: '如果注入账户资金的卡片目前不能使用的话，请联系{mailto}',
    },
    otherMethod: {
      otherType: '其他类型',
      withdrawalMethod: '提款方法',
      desc1: '请注意，银行转账可能会产生费用。请确保您的提款金额足以支付可能发生的任何费用。',
      desc2:
        '由于需要符合洗钱防制与打击资助恐怖主义规定，所有提款必须使用与您的账户资金存入转账一致的方式。',
      note: "由于最近的变化，我行可能无法为在某些国家注册的客户办理国际银行转账。<br/>\n      如果您必须通过国际银行转账取现，请与我们的客户支持联系：+ 44 2080 363 883 或<a href='mailto:{mail}'>{mail}</a>.<br/>\n      给您带来的不便我们深表歉意。",
      verificationCode: '验证码已发送至{email}。若您需要进一步协助，请联系{supportEmail}。',
      note2:
        '由于韩国的Travel Rule政策影响，如果使用韩国交易所地址进行提款，我们将不接受单笔金额超过735 USDT（约1,000,000 KRW）或该地址未通过KYC认证的客户。',
    },
  },
  transfer: {
    creditWarn: '由于您选择的账户具有信用值, 我们无法进行账户间转账操作。',
    blackListWarn:
      'MAM账户中移出资金可能会导致开仓交易被强制平仓。在从此账户移出资金前，请联系{mailTo}',
  },
  changePw: {
    header: '变更密码',
    field: { currPw: '现密码', newPw: '新密码', confirmPw: '确认新密码' },
    succ: '您的密码已经更新，您现在可以使用新密码访问账户管理中心',
  },
  security: {
    confirm: '确认',
    cancel: '取消',
    understand: '我明白了',
    passwordRenewalReminder: {
      header: '修改登录密码',
      desc: '为了您的账户资金安全，修改登录密码后，T+{days} 天内暂不支持出金。',
    },
    withdrawalFreeze: {
      header: '暂时不支持提现',
      userLoginDesc:
        '由于您修改了登录邮箱/手机号，为了您的账户资金安全，T+{days} 天内暂不支持提现。预计 {hours} 小时后解除限制。',
      passDesc:
        '由于您修改了登录密码，为了您的账户资金安全，T+{days} 天内暂不支持提现。预计 {hours} 小时后解除限制。',
      limitSecurityAuthenticator:
        '由于您的安全验证器应用程序的修改，为了确保您的帐户安全，暂时不支持 T+{days} 天的出金。此限制预计在 {hours} 小时内解除。',
    },
    securityManagement: '安全管理',
    authenticationMethod: '认证方法',
    isVerified: '已验证',
    isNotVerified: '未验证',
    password: '密码',
    changeAuthenticatorTitle: '更改安全验证器',
    securityAuthenticatorApp: '安全认证应用程序',
    securityAuthentication: '安全验证',
    enableLoginAuthentication: '启用登录验证',
    loginLocationChange: '登录地点变更邮件通知',
    lastUpdate: '最后更新',
    modify: '修改',
    verify: '验证',
    enable: '启用',
    resend: '重新发送',
    enterVerificationCode: '输入验证码',
    verifyPreEmailNote: '验证码将发送到 {email}',
    verifyPostEmailNote: '验证码已发送到 {email}',
    preEmailCode: '验证码将发送到您的新电子邮件地址',
    authenticatorTooltip: '身份验证器应用程序的验证码每30秒刷新一次',
    contactSupport: '无法访问身份验证方法？请联系{contact_email}或Livechat',
    changeEmail: '更改电子邮件地址',
    emailTitle: '输入新电子邮件地址',
    editAuthenticator: '编辑认证设备',
    addAuthenticator: '添加身份验证设备',
    scanWithApp: '使用身份验证器APP扫描',
    scanAppTooltip:
      '您可以从手机应用商店下载Google Authenticator或Microsoft Authenticator。然后，请使用应用内相机扫描提供的QR码。',
    scanAppTooltipForChina:
      '您可以从手机应用商店下载 Google Authenticator 或 Microsoft Authenticator，或在微信小程序中搜索 Google Authenticator 并选择第一个结果。然后，使用应用内相机扫描提供的 QR 码。',
    scanAuthenticatorAppTips: {
      tips1: '您可以从手机应用商店下载 Google Authenticator 或 Microsoft Authenticator',
      tips2: '或者您也可在微信小程序中搜索 Google Authenticator 并选择第一个结果。',
      tips3: '然后，使用应用内相机扫描提供的 QR 码',
    },
    userGuide: '用户指南',
    setupKey: '设置密钥',
    tnc: '通过链接身份验证器应用程序，您确认已阅读并接受 {link}。',
    disclaimer: '免责声明',
    changePasswordSuccess: '密码已更新',
    changeEmailSuccess: '电子邮件地址已更新',
    enableSecuritySuccess: '安全验证器已启用',
    updateSecuritySuccess: '安全验证器已更新',
    changePasswordSuccessDesc: '您的密码已更新。<br/> 您现在可以使用新密码登录。',
    changeEmailSuccessDesc: '您的电子邮件地址已更新。<br/> 您现在可以使用新电子邮件地址登录。',
    enableSecuritySuccessDesc: '您的安全认证应用程序已启用。',
    updateSecuritySuccessDesc: '您的安全认证应用程序已更新。',
    passwordReq: '密码要求:',
    passwordCharacters: '8-16个字符',
    passwordMix: '包含字母（a-z和A-Z）和数字（0-9）的组合',
    passwordSpecial: '特殊字符，如！@＃$％^＆*（）',
    confirmPwd: '确认密码',
    enableNow: '立即启用',
    changeDesc:
      '为了确保您账户资金的安全，在更改您的 {method} 后的 T+ {numDays} 天内，不支持提现。',
    verificationTitle: '需要验证',
    verificationDesc: '请在继续之前启用安全验证器应用程序。',
    updateSuccess: '更新成功',
    verifiedSuccess: '验证成功',
    verificationRequired: '请输入验证码',
    next: '下一步',
  },
  reserPwMobile: { header: '变更密码' },
  downloads: {
    appDesc: '在 App Store 和 Google Play Store 下载 {platform} 应用程序',
    mt4: 'METATRADER 4',
    mt4Desc: '下载适用于PC， 网页浏览器， 智能手机和平板电脑的MT4',
    windows: 'Windows',
    mac: 'Mac OS',
    ip: 'iPhone/iPad',
    android: 'Android',
    web: 'Web Trader',
    smartTrader: 'SmartTrader 工具',
    mt5: 'METATRADER 5',
    mt5Desc: '下载适用于PC， 网页浏览器， 智能手机和平板电脑的MT5',
    button: { download: '下载', launch: '启动' },
  },
  forgetPwReq: {
    headerMobile: '输入您的登录手机号',
    labelEmail: '邮箱',
    labelMobile: '手机号',
    loginMobile: '登录手机号',
    loginSMSCode: '验证码',
    loginSmsCodeExpires: '短信验证码有效期为5分钟',
    getCode: '发送验证码',
    failedCode: '发送验证码失败',
    sentAlready: '已发送',
    expired: '短信代码已过期',
    tip: '输入您帐户的电子邮件地址来重置您的密码。',
    formValidation: {
      emailReq: '请填写登录邮箱',
      mobileReq: '请填写完整的登录手机号',
      smsCodeReq: '请填写验证码',
    },
    succ: '重置密码的邮件已经发送至您的邮箱<br><br>请确保您已经检查了垃圾邮件信箱',
  },
  loginClient: {
    header: '账户管理中心',
    failedMasseage: '再试一次',
    existingClientPortalAccess:
      '查看到您已有账户管理中心登录账号。<br /><br />请登录您的账户管理中心进行开户申请。',
    existingClientPortalAccessMobile:
      '查看到您已有账户管理中心登录账号。<br /><br />请登录您的账户管理中心进行开户申请。',
    formValidation: { emailReq: '请填写邮箱', mobileReq: '请只输入数字' },
  },
  myProfile: { changeDetails: '如您想更改帐户资讯, 请联系: {mailTo}' },
  openAdditAcc: {
    chooseTradePlat: '选择交易平台',
    chooseAccType: '选择账户类型',
    chooseCurrency: '选择货币类型',
    chooseLeverage: '选择杠杆比例',
    chooseBalance: '选择账户余额',
    notes: '备注',
    currError: '您必须选择一个货币类型',
    platErr: '您必须选择一个交易平台',
    accTypeErr: '您必须选择一个账户类型',
    leverageErr: '您必须选择一个杠杆比例',
    balanceErr: '您必须选择一个账户余额',
    succ: '<p>您的新账户正在建立中，您将很快收到一封包含登录信息的电子邮件。</p><p>您可以在主页上查看申请的当前状态。</p>',
    byTicking: '如勾选, 你将接受以下条款',
    swapFreeNote: '*请注意：代处理掉期费用可能会产生少量行政费用。',
    pammNote:
      '*PAMM 账户仅适用于现有资金经理规划的投资者。除资金经理外，任何人都不能交易 PAMM 账户。',
  },
  openDemoAdditAcc: {
    header: '开设额外的模拟账户',
    button: '申请模拟账户',
    liveButton: '申请真实账户',
    succ: '<p>您的新账户正在建立中，您将很快收到一封包含登录信息的电子邮件。</p>',
  },
  resetPassword: {
    header: '重置密码',
    succ: '您的密码已经更新，您现在可以使用您的新密码登录您的MT4/MT5账户',
    succ2: '您的密码已经更新',
    failed: '超时, 请再试一次',
    emailNotFound: '您提供的邮箱尚未注册',
  },
  fileUploader: {
    supportedFormat: '文件支持类型: png, jpg, jpeg, bmp, pdf, doc, docx',
    supportedFormatCustom: '文件支持类型: {files}',
    maxSize: '最大文件大小: {maxSize}MB',
    maxFileNum: '最多文件数: {maxFileNum}',
    failedFileExtension: '请上传正确的文件类型',
  },
  transcationHistory: {
    deposit: '总入金',
    withdraw: '总出金',
    desc: {
      incomplete:
        '如果您终止或未能完成此次入金，则交易状态将显示为“未完成”。您将需要重新进行入金，因为此次交易无法被恢复。',
      success: '转账成功。您的资金会很快在您的账户中显示',
      proc: '我们正在处理您的转账，请联系: {mailTo}',
      failed: '转账失败，请联系: {mailTo} 获取更多信息',
      rejected: '您的转账申请已被拒绝，请联系: {mailTo} 获取更多信息',
    },
    withdrawDesc: {
      sub: '已提交，请等待',
      suc: '转账成功。您的资金会很快在您的账户中显示',
      proc: '我们正在处理您的转账，请联系: {mailTo}',
      incomp: '您的转账需要额外步骤才能完成，请联系: {mailTo} 获取更多信息',
      cancel: '您的转账已取消，请联系: {mailTo} 获取更多信息',
      fail: '转账失败，请联系: {mailTo} 获取更多信息',
      rej: '您的转账申请已被拒绝. 请联系: {mailTo} 获取更多信息',
    },
  },
  transferFunds: {
    default: {
      equityStop:
        '很遗憾，您的转账无法处理，因为转账将导致您的账户可用保证金下降到止损离场水平之100%以下',
      equityMargin: '很遗憾，我们目前无法处理您的转账，因为您的账户已完全对冲。',
      equityContinue:
        '我们发现您目前持有未平仓部位，在提交转账申请后，您可能会造成离场止损的问题。请确认是否要继续。',
      blackList: '此账户已被限制进行账户间转账，请联系 {supportEmail}',
    },
    pleaseNote: '请注意：',
    suc: '转账成功',
    warn1:
      '您有责任维持不会触发追加保证金通知的帐户余额。如果您目前有任何未结头寸，请确保您在帐户中保持所需的保证金。转入与账户货币类型相同的资金会立即得到处理',
    warn2: '如果您转入资金的货币类型与交易账户货币类型不同，请您发送电子邮件至: {mailTo}.',
    warn3: '请注意您不可以在只读账户间转移资金',
  },
  transferHistory: {
    payStatus: { processing: '处理中', paid: '已付款', unpaid: '未付款', rejected: '被拒绝' },
  },
  identityProof: {
    home: {
      header: '帐户信息不完整',
      content:
        '您好，{name}，您的帐户申请已接近完成，但是您仍然需要提交其他文件以验证通过您的帐户。',
    },
    shufti: {
      content: '请确保填写的详细信息和语言与您提供的ID文档一致。',
      countryOfDocument: '文档所在国家',
    },
    popup: {
      header: '您好 {name}, 我们需要更多的账户信息以处理您的账户申请. 请提供以下所需文件:',
      addressProofSpan:
        '<strong>\n      <b>确认您的地址</b>\n      </strong>\n      <br />\n      <I>请提交<b>以下文件之一</b>：\n        <br /><br />\n      </I>\n      - 近期的水电费账单</br>(最近6个月内)\n      <br />\n      <b class="or">或</b><br />\n      - 近期的银行对账单</br>(最近6个月内)\n      <br />\n      <br />\n      <b>请注意:</b> 您的全名及目前的地址必须在您提供的以上文件中清晰可见。<br />',
      idProofSpan:
        '<strong>\n      <b>确认您的身份</b>\n      </strong>\n      <br />\n      <I>请提交<b>以下文件之一</b>\n        <br /><br />\n      </I>\n      - 有效的护照复印件<br />\n      - 有效的驾照复印件\n      <br />\n      (正面和背面)\n      <br />\n      或</b><br />\n      - 身份证复印件\n      <br />(正面和背面)\n      <br />\n      <br />\n      <b>请注意: </b> 您的全名必须在您提供的以上文件中清晰可见。<br />',
    },
    reasonType: {
      4: '不完整的文件',
      5: '不清楚的文件',
      6: '需要提供彩色复印件',
      8: '开户名称不一致',
      9: '地址证明签发日期不合符规定',
    },
    reason: '我们无法审核您的文件，原因: {reason}，请重新上传',
  },
  questionnaire: {
    home: {
      header: '未完成{regulator}调查问卷',
      tip: '您必须完成{regulator}调查问卷后，我们才能完成您的开户申请，有关信息请参考我们的PDS',
      tipFCA: '您必须完成{regulator}调查问卷后，我们才能完成您的开户申请.',
    },
    header: '{regulator} 调查问卷',
    q1: '1. 与股票市场不同，外汇市场并没有交易中心或者是现实生活中的实际交易场所。鉴于外汇交易是交易双方通过电话或者网络完成的，所以又被称为是“银行间“或者是”场外交易（Over-The-Counter OTC）”市场。',
    q2: '2. PIP（percentage in point，以点表示的百分比）指的是在外汇交易中最小的价格变化。',
    q3: '3. ____指的是在买入价（Bid Price）和卖出价（Ask Price）之间的差价。这个是外汇交易券商在每笔外汇交易中所赚取的利润。',
    q4: '4. 当您的账户出现大量浮亏，并且账户资金不足支撑已开启仓位，我们将会正式发给您____。',
    q5: '5. 如果投资者没能满足追缴保证金的要求，差价合约场外交易券商有权利为客户的头寸平仓，并且无需提前通知投资者。',
    q6: '6. 外汇交易存在风险，您需要有效管理您的交易风险。由于杠杆的存在，外汇交易被认为是风险极大的投资之一。',
    q7: '7. 和交易券商进行差价合约场外交易，所有投资者的资金都将存放在独立的客户托管账户中，该账户是独立于券商日常经营账户的。',
    q8: '8. 在外汇交易中，所有的外汇产品都是以货币对的形式报价。例如，在比较欧元（EUR）和美元（USD）的价值时，我们的报价将以货币对EUR/USD给出。该产品的报价意味着1欧元等值于多少美元。例如EUR/USD报价1.29，那么1欧元等于______。',
    q9: '9. 投资者在差价合约交易中的损失不会超过其初始投入资金。',
    q10: '10. 如果您做了两个标准手的USD/JPY交易，这意味着您买入/卖出$200,000等值的日元（JPY）。假如您的交易账户中有$10,000资金，当您的账户中只有上述头寸（Position）时，并且您使用的杠杆是20:1，这意味着USD/JPY报价中1%的变化将导致您账户资金多大的变化？',
    a1: { opt1: '错误', opt2: '正确' },
    a2: { opt1: '错误', opt2: '正确' },
    a3: { opt1: '点差', opt2: '缓冲', opt3: '区间' },
    a4: { opt1: '追缴保证金通知', opt2: '更高的杠杆', opt3: '透支额度' },
    a5: { opt1: '错误', opt2: '正确' },
    a6: { opt1: '错误', opt2: '正确' },
    a7: { opt1: '错误', opt2: '正确' },
    a8: { opt1: '0.29 美金', opt2: '1.29 美金', opt3: '3.45 美金', opt4: '0.78 美金' },
    a9: { opt1: '错误', opt2: '正确' },
    a10: { opt1: '1%', opt2: '2%', opt3: '10%', opt4: '20%' },
    succ: '恭喜您通过了{regulator}调查问卷。',
    fail: '对不起，您没有通过{regulator}调查问卷。请再试。',
  },
  header: { secPortal: '安全客户专区' },
  menu: {
    home: '主页',
    accManagement: '账户管理',
    liveAcc: '真实账户',
    openAdditAcc: '开设额外账户',
    openAnAdditLiveAcc: '开设额外的真实账户',
    homeDemo: '模拟账户',
    depositFund: '入金',
    withdrawFunds: '出金',
    transferBetweenAccs: '账户间转账',
    payDetails: '支付信息',
    funds: '资金',
    transactionHis: '交易历史',
    tranHis: '转账历史',
    download: '下载',
    profile: '信息',
    myProfile: '个人信息',
    cpPassword: '账户管理中心密码',
    contactUs: '联系我们',
    helpSupport: '帮助和支持表单',
    register: '继续申请',
    tradingTools: 'PRO TRADER 工具',
    MARKET_BUZZ: '市场热点 ',
    ECON_CALENDAR: '财经日历',
    FEAT_FX: '精选观点',
    FX_IDEAS: '技术观点',
    ALPHA_GENERATION: '新一代功能',
    MARKET_MASTERS: '市场大师',
    marketWidgets: '市场工具',
    technicalAnalysis: '技术分析',
    techAnalysisTab: {
      forex: '外汇',
      indices: '指数',
      commodities: '大宗商品',
      sharedcfds: '股票价差合约',
      crypto: '加密货币',
    },
    marketData: '市场数据',
    forexCrossRates: '外汇交叉汇率',
    forexHeatMap: '外汇热区图',
    forexScreener: '外汇筛选器',
    referAFriend: '介绍给一位朋友',
    wholesale: '升级到专业版',
    tradingView: '图表 by TradingView',
    twoFactorAuthentication: '双重认证',
    promotion: '促销活动',
    depositBonus: '50% 入金奖励',
    hmcTrader: '真实交易房间',
    support: '支持',
    securityManagement: '安全管理',
  },
  contact: {
    desc: '{platform} 全球多语言团队随时为您提供帮助和服务, 让您拥有无与伦比的交易体验',
    tip: '由{email}登记的用户',
    liveChat: '在线客服',
    helpSupportForm: {
      partner: '合作伙伴转账表单',
      investigation: 'Moneta外汇交易调查表单',
      promotion: '选择退出 Moneta外汇推广表单',
      request: '账户关闭申请表单',
    },
    accountManager: '客户经理 - {userName}',
    monetaSupport: 'Moneta 外汇支持',
  },
  depositResultDialog: { msg: '请问您的入金是否成功' },
  dialog: { identityProof: { success: '感谢您上传的文件, 我们将会尽快对文件进行审核。' } },
  notification: {
    pendingIdentityProof: {
      header: '帐户信息不完整',
      id: '身份',
      poa: '地址',
      both: '身份/地址',
      content:
        '您的 {doc} 证明目前正在处理中，将很快完成。 与此同时, 您可以先行入金以获得优先开户的权利.',
    },
  },
  idPoa: {
    IdPoaNoPass: {
      header: '账户申请未完成 ',
      content:
        '如要开始交易，请上传您的身份证明文件和地址证明文件。一旦提交之后，请存入资金优先开立账户。',
    },
    onlyIdPass: {
      header: '谢谢！',
      content: '您的申请正在处理中。现在为您的账户存入资金，以进行优先开户。',
    },
    onlyPoaPass: {
      header: '账户申请未完成 ',
      content: '如要开始交易，请上传您的身份证明文件。一旦提交之后，请存入资金优先开立账户。',
    },
    bothIdPoaPass: {
      header: '谢谢！',
      content: '您的申请正在处理中。现在为您的账户存入资金，以进行优先开户。',
    },
    uploadID: '上传身份证明文件',
    uploadPoa: '上传地址',
    depositNow: '立即存款',
  },
  register: {
    progressStatus: { title: '注册进度', estimatedTimeTitle: '预计完成时间:', min: '分钟' },
    tabs: {
      personalDetails: '个人信息',
      moreAboutYou: '详细资料',
      accountConfig: '账户配置',
      confirmYourId: '文件上传',
      fundYourAcc: '即刻入金',
    },
    btn: {
      next: '下一步',
      back: '上一步',
      fundnow: '即刻入金',
      upload: '上传',
      done: '完成',
      uploadNew: '上传新的',
    },
    authority: {
      dialog1: '您必须具有真实帐户才能访问客户後台的此部分。',
      dialog2:
        '现在开设一个真实账户以解锁对您的客户後台的完全访问权限，其中包括一系列交易工具，促销活动以及更多其他内容',
      openLiveAcc: '开设真实账户',
    },
    demo: {
      congrad: '恭喜!',
      title: '您的30天测试 MT{platform} 帐户现已启用',
      title2: '您的测试帐户详细信息',
      li: { login: '帐号: ', password: '密码: ', srv: '服务器: ', expDate: '到期日: ' },
      emailSent: '* 登录详细信息也已发送到您的注册电子邮件',
      dl: '下载您的 MT{platform} 交易平台',
      proTraderPlatform: {
        content: '使用您的MT{platform}账户详细信息登录我们的新一代PRO交易者平台:',
      },
      appTrader: {
        content:
          '使用您的客户端门户登录Moneta Markets AppTrader。立即获得AppTrader的iOS或Android版本 !',
      },
      improveQ: '你想改善交易能力?',
      improveWay: '只需在几分钟内开设一个真实账户即可解锁一系列独家交易工具和促销活动',
      proTrade: '专业交易工具',
      depositBonus: '50％存款奖金',
      smartTradeTool: '智能交易工具',
      openLiveAcc: '开设真实账户',
      poweredBy: '提供者：',
    },
    personalDetails: {
      page1: {
        pageTitle: '个人信息',
        userTitle: '头衔',
        firstName: '名字',
        lastName: '姓氏',
        email: '电子邮箱',
        dob: '出生日期',
        idType: '证件类型',
        referral: '是否有推荐人?(选填)',
        middleName: '中间名',
        nationality: '国籍',
        phoneNum: '电话号码',
        date: '日',
        month: '月',
        year: '年',
        placeOfBirth: '出生地',
        idNum: '证件号码',
        passportNum: '护照号码',
        driverLicenseNum: '驾照号码',
        titleDropdown: {
          mr: '先生',
          mrs: '太太',
          ms: '女士',
          miss: '小姐',
          dr: '博士',
          prof: '教授',
        },
        idTypeDropdown: { passport: '护照', idCard: '身份证', driverLicense: '驾照' },
      },
      page2: {
        pageTitle: '主要居住地址',
        pageTitleForFCA: '额外细节',
        mainResidency: '主要居住国家',
        streetNumber: '街道号码',
        streetName: '街道名',
        address: '详细地址',
        provinceOrState: '省份/州',
        cityOrSuburb: '城市/区',
        postcode: '邮政编码',
        previousCountry: '以前的国家',
        usQuestion: '是否为美国公民或是在美国纳税?',
        taxResidencyCountry: '税务居民国家',
        nationalInsuranceNumber: '国民保险号',
        yearsAtAddress: '当前地址居住的年数',
        previousResidentialAddressTip: '请在下方填写您之前的居住地址',
      },
    },
    moreAboutYou: {
      pageTitle1: '工作与财务信息',
      pageTitle2: '投资经验',
      complianceExplain: '根据AMF/CTF的要求，我们需要收集您的以下信息.',
      FCAQuestion10Tip: '在过去的12个月中，您平均每季度执行以下交易多少笔交易？',
    },
    accountConfig: {
      pageTitle: '账户配置',
      chooseTradingPlatform: '选择交易平台',
      chooseAccType: '选择账户类型',
      chooseAccCurrency: '选择账户币种',
    },
    confirmYourId: {
      pageTitle: '文件上传',
      description:
        '作为正规监管的外汇券商, 我们需要验证您的身份. 请上传以下所需证件或者发送文件至我们邮箱{mailTo}',
      poidTitle: '身份证明',
      porTitle: '地址证明',
      uploadDescription: '请选择以下一种文件类型并上传',
      note: '请注意: 您的全名必须在您提供的以上文件中清晰可见。',
      pending: { upload: '待上传', verification: '待审核' },
      poidTypes: {
        photoId: '身份证 (正面和背面)',
        passport: '有效期内护照（含照片页及签名页）',
        drivingLicence: '驾照',
      },
      porTypes:
        '<li>水电费单 (最近6个月内)</li>\n      <li>银行流水单 (最近6个月内)</li>\n      <li>居住证明 (最近6个月内)</li>',
    },
    finishPage: {
      title: '{individualUserName}, 感谢您注册{platformName}真实账户!',
      contentDefault:
        '我们已经收到了您的申请并成功开通了您的真实账户.  不久之后，您会在<b> {email} </ b>收到一封电子邮件，其中包含您的登录详细信息.',
      contentWithoutId3WithAccount:
        '您的交易账户申请目前正在处理中, 我们将会尽快审核并通过邮件方式告知您处理结果.  您可以立即入金来获取优先开户服务.',
      contentWithoutId3WithoutAccount:
        '您的交易账户申请目前正在处理中, 我们将会尽快审核并通过邮件方式告知您处理结果.',
    },
  },
  tradingTools: {
    MARKET_BUZZ: '获取涵盖 35,000 种可交易资产的新闻报道和媒体内容。',
    FEAT_FX: '查看可操作的技术交易设置并实时追踪其进度。',
    ECON_CALENDAR: '全天候接收来自全球的实时经济数据。',
    FX_IDEAS: '查看 80,000 种工具精彩且具有教育意义的技术交易见解。',
    ALPHA_GENERATION: '提升您的交易优势，并获得 3 个新一代进阶的 MT4 和 MT5 指标。',
    MARKET_MASTERS: '通过 100 多个进阶交易教程视频，了解如何像专业人士一样进行交易。',
    condition: {
      needDeposit:
        '要访问您的 PRO trader 专业交易者工具套件和独家交易教程视频，只需为您的真实账户注入 $500 或以上的资金即可。',
      congratulations: '恭喜！您已准备好解锁您的高级交易者工具！',
      needTnc: '请同意{link}。',
      link: '条款和条件',
      unlock: '解锁 PRO TRADER 专业交易者工具',
      depositNow: '立即存款',
      tncAisc: '我同意使用以上工具。',
    },
    techAna: '技术分析：',
    byTradingView: '通过TradingView',
    alphaGeneration: {
      intro: '阿法世代（ALPHA GENERATION）的 MT4 和 MT5',
      introDesc: '使用 Moneta Markets 独有的三个高级智能专家功能提升您的交易优势。',
      analystViews: { title: '技术观点', desc: '价格图表上显示的即时技术价格水平和趋势。' },
      adaptiveCandlesticks: {
        title: '自适应烛台（ADAPTIVE CANDLESTICKS）',
        desc: '实时扫描 16 种热门的烛台形态。',
      },
      adc: { title: 'ADC', desc: '多头和空头方向的短期进场和退场交易信号。' },
      install: '如何安装 Alpha Generation？',
      installSteps: {
        one: '单击下面的「下载 Alpha Generation」按钮，下载个人电脑版 Alpha Generation。',
        two: '双击文件以执行它，然后按照安装指引操作，以您选择的语言引导您完成整个过程。',
        three: '选择您的 Moneta Markets MT4 或 MT5 目标文件夹。',
        four: '安装后，打开您的 MT4 或 MT5 平台，并在您的 MetaTrader 指标列表中找到 Trading Central 指标以及其他指标。',
        five: '将指标拖到图表上，即可获得能够操作的交易分析见解。',
      },
      button: { guid: '用户指南', download: '下载阿法世代（Alpha Generation）' },
    },
  },
  tradingView: {
    launch: '启动',
    tnc: '通过您的MT{mtServer}登录信息来登入TradingView的图表和交易平台。点击<a href="https://{regulatorUrl}/platforms/trading-platforms/charts-by-tradingview/">此处</a>获取更多关于图表 by TradingView的信息。',
    subTitle: '利用TradingView的图表、指标及绘图工具，与多达1.5亿的交易者一起开启交易之路。',
    intro:
      '通过全新上线的——图表 by TradingView, Moneta的客户即刻起可以在登陆后获取丰富的交易信息并通过TradingView的图表平台直接下单进行交易。',
    descp1: '100+ 技术分析指标',
    descp2: '实时的市场动态',
    descp3: '50+ 智能绘图工具',
    descp4: '多时间段分析',
    descp5: '12种交易提示场景',
    descp6: '多个时间范围',
    descp7: '50多种智能绘图工具',
    descp8: '20多种图表布局设计',
    descp9: '12种以上可定制图表类型',
    descp10: '内建的市场动态新闻',
    learnMore: '了解更多',
    tnc02: '立即使用您的MT{mtServer}登录信息，体验Protrader网络平台。 {findMore}',
    tnc03: '了解更多',
    subTitle02:
      'Moneta Protrader平台的用户现在可以访问实时价格图表、技术指标和绘图工具，所有功能均由TradingView协助提供；包括300多对外汇、指数、大宗商品、股票价差合约等。',
    intro02: '立即访问：',
    intro03: '协助提供：',
  },
  TFAuth: {
    title: '双重认证',
    enable: {
      enableTitle: '启用双重身份验证',
      enableDesc:
        '通过启用移动双重身份验证，确保您的账户安全。双重身份认证在每次登录时都会进行第二步验证程序，为您提供额外的安全性和安心。一旦启用此功能，您可以随时从iOS或Android安卓手机上的应用程序找到双重身份验证码，提交后即可安全登录。',
      enableSubtitle: '如何启用双重身份验证',
      step1: '在iOS或Android安卓应用商店免费下载并安装Google Authenticator。',
      step2: '进入应用程序后，选择「扫描条形码」，并扫描下面的条形码。',
      step3: '在下方的应用程序中输入数字代码(每30秒刷新一次)。',
      placeHolder: '代码',
    },
    disable: {
      disableTitle: '停用双重身份验证',
      disableDesc: '如要停用双重身份验证，请在这里通过Google Authenticator应用程序提交数字代码。',
    },
    dialog: {
      title: '请输入您的双重身份验证码',
      contact: '登录时出现困难？ {link}',
      link: '与我们联系。',
    },
    popup: { popup1: '双重身份验证已启用。', popup2: '双重身份验证已停用。' },
  },
  promotion: {
    depositBonus: {
      depositBonus: '入金并领取您的交易奖励',
      title: '申领您的奖金，提高您的存款！',
      subtitle1:
        '想要拥有更多的交易力量？您所要做的就是为您的账户注入资金。一旦我们收到您的存款，您的存款奖金就会出现在您的交易账户内。',
      fund: '注入资金到您的账户内',
      tnc: '*适用{link}。',
      link: '条款和条件',
      bonusHistory: '奖励历史',
      overBonus: '* 您已经达到${totalLimit}奖励的上限。其后的存款并不会再获得奖励。',
      opt: '您想选择领取存款奖励吗？',
      tick: '如欲选择参加存款奖励推广活动，请在方格内打勾。',
      tick2:
        'Moneta保留在以下情况下拒绝和/或取消客户参与其优惠或促销活动的权利：<br><br>甲) 炒单 (仅为赚取利润而进行外汇保证金或价差合约交易的开仓和平仓活动)；<br>乙) 客户被发现违反了客户协议或违反了促销活动条款和条件。<br><br>如有此类不当或违约行为，Moneta有权从交易账户中扣除所获得的任何奖金和利润。\n        <br><br>如果本公司怀疑或有理由相信客户滥用和/或操纵奖励计划的条款和条件，通过内部(使用在本公司持有的其他交易账户)或外部(使用在其他经纪商持有的其他交易账户)方式对冲其头寸和/或没有诚信行事，本公司保留在未经客户同意的情况下，立即从客户的交易账户中扣除奖金及利润的权利。\n        <br><br>Moneta可随时对这些条款和条件作出更改，并将在 Moneta 网站上发布修改后的条款通知您有关更改。建议您定期查看本条款，如果您继续使用 Moneta 的网站和服务，表示您接受任何该等更改的条款。Moneta 保留在任何时候自行决定修改或取消任何要约的权利。',
      saveChange: '保存更改',
      creditReceived: '已收到信用额度奖励',
      welcomeBonusTable: '欢迎奖励',
      depositBonusTable: '入金奖励',
      depositDate: '入金日期',
      credit: '信用额度奖励',
      optIn: '选择加入成功',
      optOut: '选择退出成功',
      notification:
        '<div>您有资格加入存款奖金活动。请在存款前「<a href="{depositBonusLink}">加入</a>」活动，以获得存款奖金。点击<a href="{tncLink}" target="_blank">这里</a>了解更多信息。</div>',
      optInButton: '加入',
      dontShowAgain: '不要再显示此信息',
    },
    referAFriend: {
      congratulations: '恭喜您！您刚刚成功加入「推荐朋友」推广活动，担任被推荐人。',
      redeem: '兑换',
      redeemed: '已兑换',
      dayRemain: '剩下天数',
      voucherDesc: '交易 ≥ {lots} 手即可兑换优惠券',
      title: '推荐一位朋友，赚取高达${amount}，您的朋友也能赚取${secAmount}！',
      desc: '当您的被推荐人在30天内完成以下步骤，您将赚到${amount}！而且，您可以推荐的朋友数量是没有限制的！*适用<a href="{tncLink}">条款与条件</a>。',
      register: '到我们这里注册',
      forYourFriend: '为您的朋友',
      liveAcc: '开立实时账户',
      makeDeposit: '进行存款',
      trade1Lot: '交易1手',
      trade5Lots: '交易5手',
      totalEarning: '总赚取收入',
      referralAct: '被推荐人活动',
      referralName: '被推荐人名字',
      registered: '已注册',
      accOpen: '已开立账户',
      madeDeposit: '已进行存款',
      redeemStatus: '兑换状态',
      notApplicable: '不适用',
      country: '国家',
      acc: '帐户',
      share: '分享',
      copyLink: '复制链接',
      redeemYourbonus: '兑换您的奖金',
      tatolAmtConvert: '转换为基础货币的总金额为 {convertAmt} {currency}',
      remark: '*点击立即兑换，即表示您同意条款和条件',
      redeemNow: '立即兑换',
      overlimit: '超限',
      newUser: '新用户',
      tradeCash: '交易现金',
    },
    cashback: {
      button: {
        applyForBonus: '申请赠金',
        redeemCashback: '兑换返现',
        howItWorks: '返现规则',
        depositNow: '马上入金',
        stp: '标准账户',
        ecn: 'ECN账户',
        agree: '同意',
        cancel: '取消',
      },
      title: {
        monetaProgram: 'Moneta Markets 返现奖金计划',
        claim: '马上参加50%返现奖金活动！',
        howItWorks: '了解活动规则',
        howMuch: '看看您的交易能生成多少奖金',
        treeSimpleSteps: '马上领取50%赠金 - 只需三步：',
        depositTradEarn: '入金. 交易. 领取赠金！',
        bonusSummary: '您的奖金一览',
        historicalEarnings: '您的奖金历史记录',
        program: '返现奖金计划',
        cashSummary: '返返记录',
        faqs: '返现奖金活动常见问题',
        redeemCashback: '兑换返现奖金',
        cashbackBalance: '返现奖金余额',
      },
      subTitle: {
        lowerYourCost: '降低成本。领取奖金。',
        bonus: '赠金',
        cashback: '返现奖金',
        wallet: '钱包',
        availableBonus: '可用赠金',
        totalBalance: '总余额',
        step1: '立刻入金，申请奖金！',
        step2: '立刻交易！',
        step3: '领取返现奖金',
        FX: '外汇',
        XAU: '黄金',
        OIL: '原油',
        perLot: '每手',
        seetheBonusStatus: '只需在奖金余额一栏中点击 申请 即可领取',
        tradeFxOilAndGold: '开始交易外汇，原油，和黄金把您的赠金转化为返现奖金！',
        totalCashback: '返现奖金总额',
        FebruaryCashback: '待兑换奖金',
        cashbackRedeemable: '可兑换奖金',
        account: '账户',
        amount: '金额',
      },
      information: {
        availableBonus1:
          '可用赠金是您通过所有符合条件的入金赚取的奖励积分金额。对于每笔 500 美金或以上的入金，您将获得该金额的 50% 作为奖励赠金。',
        total: '总余额是未来可兑换的返现奖金与当月已兑换的返现奖金之和。',
        availableBonus2:
          '您的可用赠金是所有符合条件的入金中尚未转换并转移到您的返现奖金钱包的奖励积分数量。',
        totalCashback: '返现奖金总额是已转换为现金的赠金总和。',
        februaryCashback: '已转换为佣金的当月入金奖励赠金。您可以在下月初将这些转入您的交易账户',
        cashbackRedeemable:
          '截至上月底已转换为现金的奖励赠金。您可以随时将此部分金额转入您的交易账户。',
        selectTheTradingAccount: '选择您想将返现奖金转入的交易账户。',
        enterTheAmount: '您希望转入交易账户的金额（美金）。',
      },
      content: {
        claimContent1:
          '现在，只要您的真实账户入金达到500 美金或更多，我们就会为您提供 50% 的奖励赠金，赠金会显示在您的客户后台主页上的赠金钱包中！然后，当您交易外汇、黄金或原油时，您的奖金赠金将转换为现金，在返现钱包中显示！每月初，返现钱包中的资金可转入您的真实交易账户，作为真实资金使用！',
        claimContent2:
          '最重要的是，今后您每入金达到 500 美金或更多，奖励赠金就会自动存入您的赠金钱包，这样您就可以不断赚取更多赠金。',
        howItWorksContent1:
          '您的真实账户入金达到 500 美金或更多，然后点击 "申请 "并同意相关条款和条件，即可获得 50%的奖励赠金。',
        howItWorksContent2:
          '当您交易外汇、黄金或原油时，您的奖励赠金将自动转换为可交易的真实现金，您可以在 "返现钱包 "中跟踪这些现金的使用情况！',
        howItWorksContent3:
          '在每个新的自然月开始时，只需点击 "返现钱包 "上的 "兑换返现"，然后兑换显示为 "可兑换返现 "的金额。',
        stepsContent1: '入金达到 500 美金或更多并申请您的奖励赠金',
        stepsContent2: '交易外汇、黄金或原油将您的奖励赠金将自动转换为真实现金！',
        stepsContent3: '申请前几个月交易活动的返现奖金！',
        depositTradEarnContent1: '交易越多，收益越多。',
        depositTradEarnContent2: '当您的真实账户入金达到500 美金或更多，您将获得50% 的奖励赠金！',
        optInContent1: '若选择参加Moneta Markets返现奖金促销活动，您必须同意以下条款与条件。',
        electTheDestinationAccount: '选择要进行返现的目标账户',
      },
      column: {
        date: '日期',
        deposit: '入金',
        withdraws: '出金',
        bonus: '可兑换奖金金额',
        status: '赠金状态',
        account: '账户',
        redeem: '已兑换返现金额',
        reason: '备注',
      },
      tnc: {
        title: '条款与条件',
        content: {
          item1: {
            title: '返现奖金活动相关条款与条件',
            li1: 'Moneta Markets是Moneta Markets有限公司的贸易名称，其在圣卢西亚国际商业公司注册处注册，注册号为2023-00068。',
            li2: '此"返现奖金"条款和条件是 "Moneta Markets有限公司条款和条件"的组成部分，其中的规定被视为 "Moneta Markets有限公司条款和条件"的附件，应一并阅读。',
          },
          item2: {
            title: '申请资格',
            li1: '在遵守且不影响所有其他条款和条件的前提下，该优惠适用于满足以下各节所述优惠资格标准的Moneta Markets客户（"合格客户"）。',
            li2: 'Moneta Markets为在Moneta Markets开设真实交易账户（非模拟账户）的客户提供 "返现奖金"。',
            li3: '"返现奖金"仅适用于 Direct STP 和 Prime ECN 账户。PAMM, MAM, Ultra ECN 与 "Cent "美分账户没有资格参加 "返现奖金"促销活动。',
            li4: 'Moneta Markets可自行决定向来自任何国家/地区的任何客户提供 "返现奖金"，只要Moneta Markets认为合适，将持续提供。公司保留根据当地限制和/或要求撤销和/或取消任何资格的权利。',
            li5: '只有根据其居住国的适用法律可以签订具有法律约束力的合同的人才可以参加 "返现奖金"。在不限制上述规定的前提下，未满 18 周岁的未成年人不得参与 "返现奖金"，因为他们未满足在本公司开设账户的要求。',
            li6: '禁止 "中介/相关方 "参与 "返现奖金"。如果 "返现奖金"参与者的注册和/或交易数据与其他 "返现奖金"参与者的注册和/或交易信息一致，包括但不限于IP地址，Moneta Markets保留立即取消其资格的权利。就本条款及细则而言，"中介人/关联方 "一词，除非上下文另有要求，应包括任何与 "返现奖励 "参与者有关系的个人或实体，包括但不限于： 1：',
            li6_sub_items: {
              li1: '家庭成员，如兄弟姐妹、配偶、祖先、直系后裔和旁系后裔；',
              li2: '任何 "返现奖金"参与者直接或间接通过一个或多个中间人控制的个人或实体，或直接或间接通过一个或多个中间人被任何 "返现奖金"参与者控制或与任何 "返现奖金"参与者共同控制的个人或实体； 就本定义而言，"控制 "一词（包括具有相关含义的 "受控制 "和 "共同控制"），在用于 "返现奖金"的任何参与者时，应指直接或通过一个或多个中间人间接拥有指导或导致指导该个人或实体的管理政策的权力，无论是通过拥有投票权证券还是其他方式。',
              li3: '同样，以任何方式与Moneta Markets和/或与Moneta Markets不定期开展某些特定促销、竞赛和/或调查活动的特定网站和/或社交网站有关联的人员，在提供 "返现奖金"的情况下，不得参与该优惠活动。',
            },
          },
          item3: {
            title: '具体的 "返现奖金"条款',
            li1: '客户需要入金达到 500 美金或以上，然后在其客户后台申请才能获得50% 奖励赠金。之后所有符合条件入金对应的赠金将被自动处理。',
            li1_sub_desc: '*只有在活动期间的入金才有效。这不包括内部转账、推荐朋友奖金和现金调整。',
            li2: '"返现奖金"仅适用于 Direct STP 和 Prime ECN 账户。Ultra ECN 与 "Cent "美分账户没有资格参加 "返现奖金"促销活动。',
            li3: '符合本文规定标准的合格客户（包括新客户和现有客户）可获得相当于以下金额的返现奖金：',
            li3_sub_content: {
              title1: '[STP和Prime ECN返佣明细］',
              info1: 'STP: 交易每手外汇, 原油, 黄金返2美金',
              info2: 'Prime ECN: 交易每手外汇, 原油, 黄金返0.50美金',
              title2: '[IB名下STP 和 Prime ECN 客户返佣明细］',
              info3: 'STP: 交易每手外汇, 原油, 黄金返0.50美金',
              info4: 'Prime ECN: 交易每手外汇, 原油, 黄金返0.25美金',
            },
            li3_sub_desc:
              '*可触发返现的最小累计交易手数为 0.01 标准手。如果客户当日的有效交易手数未能累积到 0.1 标准手，将被清零。累计手数为 0.01 标准手的倍数，如 0.02 标准手、0.03 标准手（0.12 标准手按 0.12 标准手计算）。',
            li4: '符合条件的产品： 所有外汇、黄金和原油差价合约产品',
            li5: '有效交易量：活动期间新开仓和平仓订单。开仓时间不足10分钟的仓位、锁仓、对冲仓位不计入本次活动的有效交易',
            li6: '此优惠可与其他优惠同时使用。',
            li7: '出金将从用户的赠金钱包中扣除同等金额的赠金。如果出金金额大于奖励赠金，增进额度将减为零。',
            li8: 'Moneta Markets 不承担因 Moneta Markets 根据本条款和条件重新设置或调整客户账户而可能遭受的任何成本、费用或损失，包括但不限于因自动关闭而造成的损失平仓。',
            li9: 'Moneta Markets 保留自行决定的权利',
            li9_sub_items: {
              li1: '拒绝任何“返现奖金”活动参与者的注册；和',
              li2: '取消任何篡改或违反这些条款和/或 Moneta Markets 网站上规定的任何“Moneta Markets 条款和条件”的“返现奖金”活动参与者的资格。',
            },
            li10: 'Moneta Markets保留在其合理的酌情权下停止向任何客户提供 "返现奖金"的权利，而无需解释原因。我们将通过电子邮件通知这些客户。',
            li11: 'Moneta Markets 保留随时更改、修改或终止“现金返还奖金”或其任何方面的权利，而无需为此向任何人进行补偿。此类更改将事先通知客户。请注意，参与“现金返还奖金”即表示接受并同意遵守任何此类变更、修订和/或变更。',
            li12: 'Moneta Markets 自行决定任何迹象或怀疑任何形式的套利（包括但不限于无风险获利）、滥用（包括但不限于参与者的交易活动模式表明参与者的唯一目的是获利）从现金返还中获得经济利益，但并非真正对市场交易感兴趣和/或承担市场风险）、欺诈、操纵、与返现奖金活动相关的现金返还套利或任何其他形式的欺骗或欺诈活动，将使之前的所有现金返还无效合格客户在 Moneta Markets 的真实交易账户和/或其中进行的任何及所有交易和/或其中获得的利润或损失。在这种情况下，Moneta Markets 保留自行决定关闭/暂停（暂时或永久）所有此类合格客户在 Moneta Markets 的真实交易账户、取消所有订单并取消此类参与者的所有利润的权利。在这些情况下，Moneta Markets 不对现金返还取消的任何后果负责，包括但不限于订单被强行平仓。',
            li13: '参与“返现奖金”即表示接受这些条款和条件以及 Moneta Markets 网站上规定的 Moneta Markets 条款和条件。',
            li14: '“返现奖金”的使用期限有限，具体期限见“返现奖金”所涉及的特定促销和/或调查的参与规则。',
            li15: '如果“返现奖金”的这些条款和条件被翻译成英语以外的语言，则在解释不兼容的情况下，以英语版本为准。',
            li16: '现金回馈奖励不适用于居住在土耳其的客户。',
            li17: '只有2023年7月19日起存入$500或以上的存款才有资格获得现金回馈奖励。',
          },
          item4: {
            title: '风险警告',
            li1: '衍生品和外汇交易会给您的资本带来很高的风险，因此只能用您可以承受损失的资金进行交易。在决定交易之前，请确保您阅读我们的法律文件页面上的文件并寻求独立建议，以充分了解风险。本次促销活动的准备并未考虑您的目标、财务状况或需求。',
          },
        },
      },
      faqsList: {
        question_1: 'Moneta Markets返现奖金活动是什么，如何运作？',
        question_2: '我如何注册返现奖金活动？',
        question_3: '什么是奖励赠金？',
        question_4: '什么是返现奖金？',
        question_5: '我如何查看我的奖励赠金和返现奖金？',
        question_6: '我的奖励赠金会过期吗？',
        question_7: '我的返现奖金余额会过期吗？',
        question_8: '有什么好处？',
        question_9: '我可以获得多少奖励赠金？',
        question_10: '我可以获得多少返现奖金？',
        question_11: '我什么时候可以提取我的返现奖金？',
        question_12: '如何将可用返现奖金转入我的交易账户？',
        question_13: '返现奖金多久计算一次？',
        question_14: '我可以在所有产品上赚取返现奖金吗？',
        question_15: '如何提取我的返现奖金？',
        question_16: '出金会影响我的奖励赠金吗？',
        question_17: '出金会影响我的返现奖金吗？',
        question_18: '将我的奖励赠金转换为返现奖金是否有最低交易时间限制？',
        question_19: '如何增加我的奖励赠金？',
        question_20: '如何最大限度地提高每月返现奖金？',
        question_21: 'STP 账户和 Prime ECN 账户获得的返现奖金金额一样吗？',
        question_22: 'Ultra ECN 或 "Cent" 美分账户可以获得现金返还吗？',
        question_23: '赠金状态有几种，分别代表什么？',
        question_24: '如何退出返现奖金活动？',
        answer_1:
          'Moneta Markets返现奖金活动是一项忠诚计划，客户至少入金500美金，即可获得50%的奖励赠金。这些奖励赠金将根据外汇、黄金和原油产品的交易活动转换为可提取/可交易的现金。',
        answer_2:
          '拥有合格交易账户的Moneta Markets客户必须在Moneta Markets客户后台 "申请 "他们的第一笔奖励赠金。只需在客户后台首页的赠金钱包中点击 "申请赠金"，在 "当前&历史收益"表格中点击 "申请 "按钮，然后点击同意条款和条件，随后符合条件的入金所获得的奖励赠金将被自动处理。',
        answer_3:
          '入金达到500美金或以上时将获得奖励赠金，每笔符合条件的入金的比率为50%。奖励赠金保存在您的赠金钱包中，并根据您在所有外汇、黄金和原油产品上的交易活动转换为返现奖金。',
        answer_4:
          '返现奖金是根据您的交易活动将奖励赠金转换成现金。您的奖金金额可以转入您的交易账户并作为真实现金使用。',
        answer_5:
          '您可以在您的客户后台的主屏幕上查看您的余额，您的赠金钱包和返现钱包都会显示在主屏幕上。如需查看返现钱包的明细，只需点击 "促销"-"返现奖金"，或在客户后台的 "返现钱包 "中点击 "兑换返现"，即可查看您的总返现、可兑换返现以及当月返现金额。',
        answer_6:
          '不会，奖励赠金不会过期。当您选择加入返现奖金活动时，将根据过去30天内的出入金计算您的奖金赠金。 之后所有入金所获得的奖励赠金将自动处理，不会过期。您可以在 "当前 & 历史收益" 表格中查看您当前和历史奖励赠金的状态，该表格将显示 "申请"、"已计入"、"不符合条件" 或 "已扣除"。',
        answer_7:
          '赚取的返现奖金不会过期，您可以将其转入您的交易账户进行实时交易，或者在每月月初提取。',
        answer_8:
          '作为我们的客户，我们非常重视您的价值，并感谢您选择Moneta Markets进行交易。因此，您将在所有符合条件的入金中获得奖励赠金。然后，随着您继续与我们交易，您的赠金将转换为实际现金，以感谢您的持续信任。',
        answer_9:
          '当您入金达到500美金或以上，您将获得50%的奖励赠金。然后，之后所有符合条件的入金将继续累积奖励赠金。',
        answer_10:
          '您赚取的返现奖金金额取决于您的账户类型和您喜欢交易的产品。您可以通过在Moneta Markets选择STP账户交易外汇、原油和黄金产品来最大化您的收益。',
        answer_11:
          '您可以随时将显示为 "可用返现奖金" 的余额转入您的交易账户。当月获得的返现奖金可在下个月月初转入您的交易账户。',
        answer_12:
          '您只需在您的返现钱包中点击 "Redeem Cashback"（兑换返现），选择您想转入的交易账户和返现金额，然后再次点击 "Redeem Cashback"（兑换返现）即可。',
        answer_13:
          '您在所有外汇、黄金和原油产品上的交易活动所赚取的返现奖金每天计算（GMT+2），并显示在您的返现钱包。',
        answer_14:
          'STP 和 Prime ECN 账户的所有外汇、黄金和原油产品均可获得返现奖金，具体如下所示。<br/><br/>[STP和Prime ECN返佣明细］<br/>STP: 交易每手外汇, 原油, 黄金返0.50美金<br/>Prime ECN: 交易每手外汇, 原油, 黄金返0.25美金',
        answer_15:
          '提取您的返现奖金非常简单。您只需在您的返现钱包中点击 "Redeem Cashback"（兑换返现），选择您想转入的交易账户和返现金额，然后再次点击 "Redeem Cashback"（兑换返现）。一旦资金转入您的交易账户，您就可以使用这笔资金进行交易或提交出金申请。',
        answer_16:
          '如果您提交出金，您的赠金钱包中的赠金将按比例扣除。例如，如果您的赠金钱包里有500美金的奖励赠金，而您选择从交易账户中出金250美金，那么250美金对应的奖励赠金也将被扣除。请注意，无论您的出金金额为多少，您的返现奖金余额都不会出现负数。',
        answer_17: '不会。您的返现奖金仍属于您自己，不会受到您出金的影响。',
        answer_18:
          '任何外汇、黄金或原油交易的持仓时间至少为10分钟，您的奖励赠金才能转换为返现奖金。',
        answer_19:
          '符合条件的入金会帮助累计您的奖励赠金。每存入 500 美金或以上，您将获得该金额 50%的奖励赠金。',
        answer_20:
          '最大限度地提高返现奖金取决于两个因素：您的账户类型，以及您在外汇、黄金和原油产品上的交易活动，STP 账户的返现高于 Prime ECN 账户。只需确保您的 "赠金钱包 "中有足够的信用额，并使用STP 账户进行外汇、黄金和原油交易，即可最大限度地获得返现奖金。而且，您的交易越多，交易量越大，您的奖励赠金转换为返现奖金的速度就越快。',
        answer_21:
          '不同账户类型在外汇、黄金和原油上获得的返现奖金金额不同。<br/><br/>[STP和Prime ECN返佣明细］<br/>STP: 交易每手外汇, 原油, 黄金返0.50美金<br/>Prime ECN: 交易每手外汇, 原油, 黄金返0.25美金',
        answer_22:
          '很遗憾，Ultra ECN 或"Cent“美分账户不能从外汇、黄金或原油交易中获得任何现金返还。您必须使用 STP 或 Prime ECN 账户。',
        answer_23:
          '申请： 首次完成符合条件的入金后，您需要点击 "申请" 才能获得赠金。之后所有入金对应的赠金都将自动处理。<br/>已存入： 赠金已经存入，并将在您的赠金钱包中显示。<br/>不合格： 奖金未达到最低入金 500 美金的要求。<br/>已扣除： 由于您的交易账户提交了出金请求，赠金已被扣除。',
        answer_24: '如果您决定不再参与该活动，请发送退出申请至<a href="mailto:{email}">{email}</a>',
      },
      reason: {
        1: '您的赠金钱包中已添加 50%的赠金',
        2: '手动调整奖金 - 已存入',
        3: '出金金额已从您的 "奖金钱包"中移除',
        4: '该赠金金额已转换为返现奖金',
        5: '手动调整奖金 - 已扣除',
        6: '此入金未达到最低入金额',
      },
      status: { 0: '申请', 1: '已存入', 2: '已扣除', 3: '不合格' },
      zeroMsg: '您没有剩余的返现奖金可以兑换',
    },
  },
  hmcTraderPortal: {
    overview: '平台介绍',
    interactiveAnalysis: '互动式分析',
    learnFromTheBest: '学自最佳交易员',
    requestAResearch: '申请分析支援',
    vipSupport: 'VIP专业支援',
    eligibility: '参加条件',
    login: '登入HMC真实交易房间',
    depositRequirement: '达成入金要求，解锁HMC真实交易房间权限',
    basic: '基础等级',
    vip: 'VIP等级',
    exclusiveAnalysis: '独家分析',
    analysisDiscussions: '分析研讨',
    tradingSignals: '交易信号',
    tradingSignalRequest: '一对一交易信号咨询',
    privateAnalysisRequest: '私人订制分析咨询',
    prioritySupport: '尊贵优先支援',
    minimumFundingRequirement: '最低入金要求以及每月需交易至少$200,000名义交易金额*',
    tnc1: '请注意，<a href="{url}" target="_blank">portal.hmctrader.com</a>上的信息是由第三方供应商（HMC Trader）提供给Moneta（"Moneta Global Limited"，"我们"）的。其目的仅限于研究和提供一般信息，不构成任何购买、出售或持有特定产品或追求任何特定投资策略的财务建议。这些信息不是为任何特定人士的投资需求而定制的，因此不包含对任何可能收到信息的观众的投资目标、财务状况或需求的考虑。请注意，过去的业绩并不是未来业绩和/或结果的可靠指标。实际结果可能与前瞻性声明或过去业绩声明中预期的结果有重大差异。我们对这里提供的任何信息的准确性或完整性不承担任何责任，也不承担由HMC Trader提供的任何信息可能引起的任何损失。',
    tnc2: '*自HMC真实交易房间正式上线之日开始计算，您需要满足上述的最低资金要求以解锁访问权限。您需要每月完成至少关仓价值为$200,000名义交易金额的交易以维持HMC真实交易房间的访问权限。如果您在一个月内没有完成至少$200,000名义交易金额的交易，您的访问权限将被自动取消。您需要重新达成入金要求以重新激活HMC真实交易房间的访问权限。',
  },
  supportTickets: {
    support: '支持',
    mySupportTickets: '我的支持请求',
    createNewTicket: '创建新的支持请求',
    ticketNo: '请求编号',
    subject: '主题',
    status: '状态',
    lastUpdated: '最后更新',
    newTicketRequest: '创建新的支持请求',
    content: '内容',
    attachments: '附件',
    upload: '上传',
    viewingTicket: '查看请求编号',
    postReply: '发表回复',
    update: '更新',
    cancel: '取消',
    close: '关闭',
    number: '编号',
    description: '描述',
    postReplyRequired: '帖子回复字段为必填项',
    contentFieldRequired: '内容字段为必填项',
    subjectFieldRequired: '主题字段为必填项',
    successMsg: '您的支持服务单已成功创建！',
  },
  responseMsg: {
    410: '参数验证失败',
    411: '密码错误',
    420: '用户不存在',
    421: '密码重置被拒',
    490: '请不要重复输入卡信息',
    500: '发生错误',
    501: '申请失败, 请稍后重试',
    505: '文件上传失败',
    510: '验证码错误',
    511: '发送短消息失败',
    520: '登录失败, 请重试',
    521: '您在此设备上已处于登入状态!',
    522: '请重新登录',
    523: '请重新登录',
    524: '您提供的邮箱尚未注册',
    525: '用户名或密码错误',
    526: 'Recaptcha 验证码不一致',
    527: '用户不是IB',
    528: '用户不存在',
    529: '用户名或密码错误',
    530: '登录会话无效',
    531: '代码无效',
    540: '系统找不到您的交易账号',
    541: '无法找到返佣账号',
    542: '抱歉，您不允许开同名账号',
    544: '您不允许申请一个额外的账户，直到您的身份证明核实为止',
    550: '账号错误',
    551: '您的余额不足',
    554: '请求的数额为0或者空',
    562: '无法处理您的支付请求，请重新尝试。如问题仍未解决，请通过Live Chat或者电子邮件{email}与我们取得联系',
    564: '国家未能配合该支付渠道',
    565: '银行代码错误',
    566: '无法找到支付方式',
    567: '很遗憾，此付款方式目前不可用。很抱歉为您造成不便',
    568: '此账户已被限制存款，请联系{email}',
    569: '必须提供您的名字/姓氏/电话号码。如果您需要帮助，请联系{depositEmail}',
    581: '您已成功选择加入促销活动。 请注意，您必须先入金才能出金。',
    584: '您没有可申请的佣金',
    587: '取款金额无效。',
    590: '无法找到转出账号',
    591: '无法找到转入账号',
    593: '由于您的账户包含信贷部分，我们无法对您所选择的账户进行资金转移处理。',
    594: '不允许从这个账户转移资金',
    624: '无法删除此账户中的负数余额，如需了解更多信息，请联系 support@monetamarkets.com。',
    625: '您的申请已收到并且正在处理中。如有任何疑问，请联系 support@monetamarkets.com。',
    626: '我们注意到，您的余额大于零。请刷新页面并再次检查。',
    647: '验证码错误',
    1001: '您的账号暂时无法登录，请联系您的客户经理',
    1102: '无法启用，该帐户已创建为真实帐户或模拟帐户。',
    1103: '账号已被停用',
    1202: '验证码已过期，请重试',
    1204: '请尝试使用另一个电子邮件地址',
    1208: '不能重复发送验证码',
    1209: '发送验证码错误',
    session_timeout: '登录时间超时, 正在回到登录页面',
    vload: {
      4019: '不接受代券类型或金额',
      4071: '怀疑有欺诈行为',
      4072: '已使用代券',
      4073: '代券未激活',
      4074: '代券已过期',
      4075: '没有找到代券',
      4080: '代券价值不足',
      4444: '使用代券无效。货币不匹配。',
      5000: '存储数据不可用',
    },
  },
  lock: { premium: { trading: { tools: { description: '要解锁高级功能，请立即完成您的申请' } } } },
};
