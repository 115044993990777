import { apiGetData } from '@/resource/register'
import { validateNumber } from '@/util/validation'

export default {
  data() {
    return {
      emailReadOnly: true,
      form: {
        title: null,
        firstName: '',
        middleName: null,
        lastName: '',
        nationalityId: null,
        email: '',
        phoneCode: '',
        mobile: '',
        dob: null,
        placeOfBirth: null,
        referredBy: null,
        idType: null,
        idNumber: '',
        step: 1,
      },
      rules: {
        title: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('common.formValidation.titleReq'),
          },
        ],
        firstName: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.firstNameReq'),
          },
          {
            max: 50,
            message: this.$t('common.formValidation.noteMoreThan', { limit: 50 }),
          },
          {
            min: 1,
            message: this.$t('common.formValidation.noteLessThan', { limit: 1 }),
          },
        ],
        middleName: [
          {
            required: false,
            trigger: 'blur',
          },
          {
            max: 50,
            message: this.$t('common.formValidation.noteMoreThan', { limit: 50 }),
          },
        ],
        lastName: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.lastNameReq'),
          },
          {
            min: 1,
            message: this.$t('common.formValidation.noteLessThan', { limit: 1 }),
          },
          {
            max: 50,
            message: this.$t('common.formValidation.noteMoreThan', { limit: 50 }),
          },
        ],
        nationalityId: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('common.formValidation.nationalityReq'),
          },
        ],
        email: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.emailReq'),
          },
        ],
        phoneCode: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.dobReq'),
          },
        ],
        mobile: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.mobReq'),
          },
          {
            min: 4,
            message: this.$t('common.formValidation.noteLessThan', { limit: 4 }),
          },
          {
            max: 20,
            message: this.$t('common.formValidation.noteMoreThan', { limit: 20 }),
          },
          {
            validator: validateNumber,
            trigger: 'blur',
          },
        ],
        dob: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('common.formValidation.dobReq'),
          },
        ],
        placeOfBirth: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('common.formValidation.pobReq'),
          },
        ],
        idType: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('common.formValidation.idTypeReq'),
          },
        ],
        idNumber: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.personalDetails.page1.idNum'),
            }),
          },
          {
            max: 45,
            message: this.$t('common.formValidation.noteMoreThan', { limit: 45 }),
          },
        ],
      },
      referredCheck: false,
      dateOfBirth: {
        year: null,
        month: null,
        date: null,
      },
      ndt: new Date(),
      monthPlaceholder: true,
      yearPlaceholder: true,
      dayPlaceholder: true,
    }
  },
  mounted() {
    this.getUserDate()
  },
  computed: {
    nationalities() {
      return this.$store.state.register.nationalities
    },
    phoneCodes() {
      return this.$store.state.register.phoneCodes
    },
    disableField() {
      return this.$store.state.register.disableField
    },
    years() {
      const year = this.ndt.getFullYear() - 18
      return Array.from({ length: 100 - 18 }, (value, index) => year - index)
    },
    months() {
      if (this.dateOfBirth.year == this.years[0]) {
        const month = this.ndt.getMonth() + 1
        return Array.from({ length: month }, (value, index) => this.$options.filters.appendzero(index + 1))
      }
      return Array.from({ length: 12 }, (value, index) => this.$options.filters.appendzero(index + 1))
    },
    dates() {
      const d =
        this.dateOfBirth.year == this.years[0] && this.dateOfBirth.month == this.ndt.getMonth() + 1
          ? this.ndt.getDate()
          : new Date(this.dateOfBirth.year, this.dateOfBirth.month, 0).getDate()
      return Array.from({ length: d }, (value, index) => this.$options.filters.appendzero(index + 1))
    },
  },
  watch: {
    dateOfBirth: {
      handler(dob) {
        const presMonth = this.months.find(f => f == dob.month)
        const presDate = this.dates.find(f => f == dob.date)
        if (!presMonth) dob.month = ''
        if (!presDate) dob.date = ''
        this.form.dob = dob.year && dob.month && dob.date ? `${dob.year}-${dob.month}-${dob.date}` : ''
      },
      deep: true,
    },
    referredCheck: {
      handler(state) {
        if (state === false) this.form.referredBy = ''
      },
    },
  },
  methods: {
    switchEmail() {
      if (!this.form.email) {
        this.emailReadOnly = false
      }
    },
    getUserDate() {
      apiGetData(1).then(resp => {
        const userData = resp.data.data
        this.form = { ...userData }

        if (userData.dob) {
          const splitDob = userData.dob.split('-')
          this.dateOfBirth = {
            year: parseInt(splitDob[0]),
            month: this.$options.filters.appendzero(parseInt(splitDob[1])),
            date: this.$options.filters.appendzero(parseInt(splitDob[2])),
          }
        }

        this.switchEmail()

        if (userData.referredBy) this.referredCheck = true
      })
    },
    monthFocus() {
      this.monthPlaceholder = true
    },
    dateFocus() {
      this.yearPlaceholder = true
    },
  },
}
