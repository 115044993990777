<template>
  <el-form-item :label="label" :prop="name" :class="{ active: visible }">
    <el-input
      v-on="listeners"
      v-bind="$attrs"
      :data-testid="name"
      auto-complete="new-password"
      :placeholder="placeholder"
      :disabled="disabled"
    />
    <slot></slot>
  </el-form-item>
</template>

<script>
export default {
  name: 'inputTemplate',
  props: { name: String, label: String, placeholder: String, disabled: Boolean },
  data() {
    return {
      focus: false
    };
  },
  computed: {
    visible() {
      return this.$attrs.value || this.focus || this.placeholder;
    },
    listeners() {
      return Object.assign({}, this.$listeners, {
        input: event => this.$emit('input', event),
        focus: event => (this.focus = true),
        blur: event => (this.focus = false)
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/form/input.scss';
</style>
