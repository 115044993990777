import Vue from 'vue'
import Router from 'vue-router'
import identity from './identity'

Vue.use(Router)
const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/views/shared/Layout'),
      children: [
        {
          path: '/',
          name: '/',
          meta: {
            noShowLoading: true,
          },
        },
        {
          path: '/register',
          name: 'register',
          component: () => import('@/views/register/Register'),
          meta: {
            restrictBanner: true,
          },
        },
        {
          path: '/demo',
          name: 'demo',
          component: () => import('@/views/register/Demo'),
          meta: {
            restrictBanner: true,
          },
        },
        {
          path: '/authority',
          name: 'authority',
          component: () => import('@/views/Authority'),
        },
        {
          path: '/registerFinish',
          name: 'registerFinish',
          component: () => import('@/views/register/RegisterFinish'),
        },
        {
          path: '/tradingTool/MARKET_BUZZ',
          name: 'MARKET_BUZZ',
          component: () => import('@/views/TradingTools'),
          meta: {
            noShowLoading: true,
            authority: true,
          },
        },
        {
          path: '/tradingTool/FEAT_FX',
          name: 'FEAT_FX',
          component: () => import('@/views/TradingTools'),
          meta: {
            noShowLoading: true,
            authority: true,
          },
        },
        {
          path: '/tradingTool/ECON_CALENDAR',
          name: 'ECON_CALENDAR',
          component: () => import('@/views/TradingTools'),
          meta: {
            noShowLoading: true,
            authority: true,
          },
        },

        {
          path: '/tradingTool/FX_IDEAS',
          name: 'FX_IDEAS',
          component: () => import('@/views/TradingTools'),
          meta: {
            noShowLoading: true,
            authority: true,
          },
        },
        {
          path: '/tradingTool/ALPHA_GENERATION',
          name: 'ALPHA_GENERATION',
          component: () => import('@/views/TradingTools'),
          meta: {
            noShowLoading: true,
            authority: true,
          },
        },
        {
          path: '/tradingTool/MARKET_MASTERS',
          name: 'MARKET_MASTERS',
          component: () => import('@/views/TradingTools'),
          meta: {
            noShowLoading: true,
            authority: true,
          },
        },
        {
          path: '/marketWidgets/technicalAnalysis',
          name: 'technicalAnalysis',
          component: () => import('@/views/marketWidgets/TechnicalAnalysis'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/TradingView',
          name: 'TradingView',
          component: () => import('@/views/TradingView'),
          meta: {
            noShowLoading: true,
            restrictBanner: true,
            authority: true,
          },
        },
        {
          path: '/ProtraderPlatform',
          name: 'ProtraderPlatform',
          component: () => import('@/views/ProtraderPlatform'),
          meta: { authority: true },
        },
        {
          path: '/home',
          name: 'home',
          component: () => import('@/views/Home'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/homeDemo',
          name: 'homeDemo',
          component: () => import('@/views/HomeDemo'),
        },
        {
          path: '/liveAccount',
          name: 'liveAccount',
          component: () => import('@/views/Home'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/openLiveAccount',
          name: 'openLiveAccount',
          component: () => import('@/views/OpenLiveAccount'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/openDemoAccount',
          name: 'openDemoAccount',
          component: () => import('@/views/OpenDemoAccount'),
        },
        {
          path: '/depositFunds',
          name: 'depositFunds',
          component: () => import(`@/views/deposit/DepositFunds`),
          meta: {
            authority: true,
          },
        },
        // temporary for testing
        {
          path: '/depositFundsCps',
          name: 'depositFundsCps',
          component: () => import(`@/views/deposit/DepositFundsCps`),
          meta: {
            authority: true,
          },
        },
        {
          path: '/restrictDeposit',
          name: 'restrictDeposit',
          component: () => import(`@/views/deposit/restrictDeposit`),
          meta: {
            noShowLoading: true,
            authority: true,
          },
        },
        {
          path: '/withdrawFunds',
          name: 'withdrawFunds',
          component: () => import('@/views/withdraw/WithdrawFunds'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/transferFunds',
          name: 'transferFunds',
          component: () => import('@/views/TransferFunds'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/transactionHistory',
          name: 'transactionHistory',
          component: () => import('@/views/TransactionHistory'),
          meta: {
            authority: true,
          },
        },
        // {
        //   path: '/hmcTraderPlatform',
        //   name: 'hmcTraderPlatform',
        //   component: () => import('@/views/HmcTraderPlatform'),
        // },
        {
          path: '/downloads',
          name: 'downloads',
          component: () => import('@/views/Downloads'),
        },
        {
          path: '/myProfile',
          name: 'myProfile',
          component: () => import('@/views/MyProfile'),
        },
        // {
        //   name: 'menu.referAFriend',
        //   path: '/referAFriend',
        //   component: () => import('@/views/promotion/ReferAFriend'),
        // },
        // {
        //   path: '/depositBonus',
        //   name: 'depositBonus',
        //   component: () => import('@/views/promotion/DepositBonus'),
        // },
        // {
        //   path: '/changePassword', // securityManagement页面替换changePassword页面
        //   name: 'changePassword',
        //   component: () => import('@/views/ChangePassword'),
        // },
        {
          path: '/securityManagement', // 安全中心综合了changePassword功能 替换changePassword页面
          name: 'securityManagement',
          component: () => import('@/views/SecurityManagement'),
        },
        // {
        //   path: '/twoFactorAuthentication',
        //   name: 'twoFactorAuthentication',
        //   component: () => import('@/views/TwoFactorAuthentication'),
        // },
        {
          path: '/upgradeToPro',
          name: 'upgradeToPro',
          component: () => import('@/views/wholesale/UpgradeToPro'),
        },
        {
          path: '/contactUs',
          name: 'contactUs',
          component: () => import(`@/views/ContactUs`),
        },
        {
          path: '/paymentDetails',
          name: 'paymentDetails',
          component: () => import('@/views/PaymentDetails'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/cashbackRundown',
          name: 'cashbackRundown',
          component: () => import('@/views/promotion/cashback/CashbackRundown'),
        },
        {
          path: '/cashback',
          name: 'cashback',
          component: () => import('@/views/promotion/cashback/Cashback'),
        },
        {
          path: '/applyBonus',
          name: 'applyBonus',
          component: () => import('@/views/promotion/cashback/ApplyBonus'),
        },
        // {
        //   path: '/myTickets',
        //   name: 'myTickets',
        //   component: () => import(`@/views/MyTickets`),
        // },
        // pages of thirdParty
        {
          path: '/fasapay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/neteller/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/neteller/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paysafe_neteller/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paytrust/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paywize/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/bitwallet/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/kr_df/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paytrust/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/zotapay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paytoday/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/unionpay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/cps/thirdPartyDepositResult',
          name: 'cpsThirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/tinkbit/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/creditcard/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/transact365/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/solid/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/apg/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/sd/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/bridgepay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/safeCharge/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/safeCharge/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/skrill/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/skrill/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paypal/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paypal/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/cardpay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/cardpay/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/crypto/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/crypto/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/cryptousdt/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/cryptousdt/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/mijipay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/stic/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/stic/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/xpay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          //india
          path: '/paygate/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/trustly/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
            restrictApi: true,
            noShowLoading: true,
          },
        },
        {
          path: '/trustly/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
            restrictApi: true,
            noShowLoading: true,
          },
        },
        {
          path: '/poli/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/poli/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/assembly/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/assembly/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/pagsmile/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/astropay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/astropay/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        // UK
        {
          path: '/everpay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/everpay/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/thirdPartyDepositResult/:id',
          name: 'thirdPartyResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/thirdPartyDepositFailed/:id',
          name: 'thirdPartyFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        // pages of deposit
        {
          path: '/deposit/cps/:name',
          name: 'cps',
          component: () => import('@/views/deposit/cps/Index'),
          props: true,
        },
        {
          path: '/deposit/cpsIndex/:name',
          name: 'cpsIndex',
          component: () => import('@/views/deposit/cps/IndexCps'),
          props: true,
        },
        {
          path: '/deposit/creditOrDebit',
          name: 'creditOrDebit',
          component: () => import('@/views/deposit/CreditOrDebit'),
        },
        {
          path: '/deposit/bitwalletDeposit',
          name: 'bitwalletDeposit',
          component: () => import('@/views/deposit/BitwalletDeposit'),
        },
        {
          path: '/deposit/indiaPayment',
          name: 'indiaPayment',
          component: () => import('@/views/deposit/IndiaPayment'),
        },
        {
          path: '/deposit/bridgePaymentDeposit',
          name: 'bridgePaymentDeposit',
          component: () => import('@/views/deposit/BridgepayDeposit'),
        },
        {
          path: '/deposit/brokerToBrokerTransfer',
          name: 'brokerToBrokerTransfer',
          component: () => import('@/views/deposit/BrokerToBrokerTransfer'),
        },
        {
          path: '/deposit/bPayDeposit',
          name: 'bPayDeposit',
          component: () => import('@/views/deposit/BPayDeposit'),
        },
        // {
        //   path: '/deposit/fasaPay',
        //   name: 'fasaPay',
        //   component: () => import('@/views/deposit/FasaPay'),
        // },
        {
          path: '/deposit/internationalSwift',
          name: 'internationalSwift',
          component: () => import('@/views/deposit/InternationalSwift'),
        },
        {
          path: '/deposit/neteller',
          name: 'neteller',
          component: () => import('@/views/deposit/NetellerDeposit'),
        },
        {
          path: '/deposit/poliPay',
          name: 'poliPay',
          component: () => import('@/views/deposit/PoliPay'),
        },
        {
          path: '/deposit/skrillPay',
          name: 'skrillPay',
          component: () => import('@/views/deposit/SkrillPay'),
        },
        {
          path: '/deposit/sticPay',
          name: 'sticPay',
          component: () => import('@/views/deposit/SticPay'),
        },
        {
          path: '/deposit/webMoney',
          name: 'webMoney',
          component: () => import('@/views/deposit/WebMoney'),
        },
        // {
        //   path: '/deposit/unionPay',
        //   name: 'unionPay',
        //   component: () => import('@/views/deposit/UnionPay'),
        // },
        {
          path: '/deposit/thailandZotaPay',
          name: 'thailandZotaPay',
          component: () => import('@/views/deposit/ThailandZotapay'),
        },
        {
          path: '/deposit/thailandPaytoday',
          name: 'thailandPaytoday',
          component: () => import('@/views/deposit/ThailandPaytoday'),
        },
        // {
        //   path: '/deposit/thailandEeziePay',
        //   name: 'thailandEeziePay',
        //   component: () => import('@/views/deposit/ThailandEeziePay'),
        // },
        {
          path: '/deposit/thailandXpay',
          name: 'thailandXpay',
          component: () => import('@/views/deposit/ThailandXpay'),
        },
        {
          path: '/deposit/nigeriaZotapay',
          name: 'nigeriaZotapay',
          component: () => import('@/views/deposit/NigeriaZotapay'),
        },
        {
          path: '/deposit/vietnamPayment',
          name: 'vietnamPayment',
          component: () => import(`@/views/deposit/VietnamPayment`),
        },
        // {
        //   path: '/deposit/vietnamEeziePay',
        //   name: 'vietnamEeziePay',
        //   component: () => import('@/views/deposit/VietnamEeziePay'),
        // },
        {
          path: '/deposit/malaysiaPayment',
          name: 'malaysiaPayment',
          component: () => import(`@/views/deposit/MalaysiaPayment`),
        },
        // {
        //   path: '/deposit/malaysiaEeziePay',
        //   name: 'malaysiaEeziePay',
        //   component: () => import('@/views/deposit/MalaysiaEeziePay'),
        // },
        // {
        //   path: '/deposit/philipinessPayment',
        //   name: 'philipinessPayment',
        //   component: () => import(`@/views/deposit/PhilipinessPayment`),
        // },
        // {
        //   path: '/deposit/indonesiaPayment',
        //   name: 'indonesiaPayment',
        //   component: () => import(`@/views/deposit/IndonesiaPayment`),
        // },
        // {
        //   path: '/deposit/indonesiaEeziePay',
        //   name: 'indonesiaEeziePay',
        //   component: () => import('@/views/deposit/IndonesiaEeziePay'),
        // },
        // {
        //   path: '/deposit/indiaEeziePay',
        //   name: 'indiaEeziePay',
        //   component: () => import('@/views/deposit/IndiaEeziePay'),
        // },
        // {
        //   path: '/deposit/crypto',
        //   name: 'crypto',
        //   component: () => import('@/views/deposit/Crypto'),
        // },
        {
          path: '/deposit/mijiPay',
          name: 'mijiPay',
          component: () => import('@/views/deposit/MijiPay'),
        },
        // {
        //   path: '/deposit/pagsmile',
        //   name: 'pagsmile',
        //   component: () => import('@/views/deposit/Pagsmile'),
        // },
        {
          path: '/deposit/indiaBankTransfer',
          name: 'indiaBankTransfer',
          component: () => import('@/views/deposit/IndiaBankTransfer'),
          meta: { limitLang: 'en_US' },
        },
        {
          path: '/deposit/astropayDeposit',
          name: 'astropayDeposit',
          component: () => import('@/views/deposit/AstropayDeposit'),
        },
        {
          path: '/deposit/uk',
          name: 'uk',
          component: () => import('@/views/deposit/UK'),
        },
        {
          path: '/deposit/southAfricaPayment',
          name: 'southAfricaPayment',
          component: () => import('@/views/deposit/SouthAfricaPayment'),
        },
        {
          path: '/deposit/Vload',
          name: 'vload',
          component: () => import('@/views/deposit/Vload'),
        },
        {
          path: '/deposit/laos',
          name: 'laos',
          component: () => import('@/views/deposit/Laos'),
        },
        {
          path: '/deposit/dasshpeUPI',
          name: 'dasshpeUPI',
          component: () => import('@/views/deposit/DasshpeUPI'),
        },
        {
          path: '/deposit/dasshpeWallet',
          name: 'dasshpeWallet',
          component: () => import('@/views/deposit/DasshpeWallet'),
        },
        // {
        //   path: '/deposit/hongkong',
        //   name: 'hongkong',
        //   component: () => import('@/views/deposit/HongKong'),
        // },
        // {
        //   path: '/deposit/hongkongQR',
        //   name: 'hongkongQR',
        //   component: () => import('@/views/deposit/HongKongQR'),
        // },
        {
          path: '/deposit/HongKongQR-scanPay',
          name: 'HongKongQR-scanPay',
          component: () => import('@/views/deposit/HongKongQR-scanPay'),
        },
        {
          path: '/deposit/africaZotapay',
          name: 'africaZotapay',
          component: () => import('@/views/deposit/AfricaZotapay'),
        },
        // Dev Purpose
        {
          path: '/demo-component',
          name: 'demo-component',
          component: () => import('@/views/Demo'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/shared/LoginLayout'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(`@/views/LoginClient`),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/login/to_login',
          name: 'to_login',
          component: () => import(`@/views/LoginClient`),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/addAuthentication',
          name: 'addAuthentication',
          component: () => import(`@/views/LoginClient`), // 组件复用 根据当前路由渲染对应表单
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/verifyAuthentication',
          name: 'verifyAuthentication',
          component: () => import(`@/views/LoginClient`),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/forgetPassword',
          name: 'forgetPassword',
          component: () => import(`@/views/ForgetPasswordReq`),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/resetPassword',
          name: 'resetPassword',
          component: () => import('@/views/ResetPassword'),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/resetProfilePassword',
          name: 'resetProfilePassword',
          component: () => import(`@/views/ResetProfilePassword`),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/loginByMobile',
          name: 'loginByMobile',
          component: () => import('@/views/LoginClientMobile'),
          props: true,
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/resetPasswordMobile',
          name: 'resetPasswordMobile',
          component: () => import('@/views/ResetPasswordMobile'),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/forgetPasswordMobile',
          name: 'forgetPasswordMobile',
          component: () => import('@/views/ForgetPasswordReqMobile'),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/logout',
          name: 'logout',
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/forceChangePassword',
          name: 'forceChangePassword',
          component: () => import('@/views/ForceChangePassword'),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/error',
          name: 'error',
          component: () => import('@/views/register/Error'),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
      ],
    },
    {
      path: '',
      component: () => import('@/views/shared/Layout'),
      children: [
        {
          name: '404',
          path: '*',
          component: () => import('@/views/404'),
        },
      ],
    },
  ],
})
export default router
identity.authorize()
