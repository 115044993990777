export default {
  setPayment(state, value) {
    state.payment = value
  },
  setCurrentPayment(state, value) {
    state.currentPayment = value
  },
  setWithdrawalPayment(state, value) {
    state.withdrawalPayment = value
  },
  reset(state) {
    ;(state.payment = {}), (state.currentPayment = {}), (state.withdrawalPayment = {})
  },
}
