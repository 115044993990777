import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementLocale from 'element-ui/lib/locale'
import elementEnLocale from 'element-ui/lib/locale/lang/en'
import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'
import elementFrLocale from 'element-ui/lib/locale/lang/fr'
import elementKrLocale from 'element-ui/lib/locale/lang/ko'
import elementThLocale from 'element-ui/lib/locale/lang/th'
import elementViLocale from 'element-ui/lib/locale/lang/vi'
import elementEsLocale from 'element-ui/lib/locale/lang/es'
import elementPtLocale from 'element-ui/lib/locale/lang/pt'
import elementDeLocale from '@/lang/element-ui/de'
import elementIdLocale from 'element-ui/lib/locale/lang/id'
import elementArLocale from 'element-ui/lib/locale/lang/ar'
import elementRuLocale from 'element-ui/lib/locale/lang/ru-RU'
import elementItLocale from 'element-ui/lib/locale/lang/it'
import elementJaLocale from 'element-ui/lib/locale/lang/ja'
import elementFaLocale from 'element-ui/lib/locale/lang/fa'

import enLocale from '@/lang/mo/en'
import zhLocale from '@/lang/mo/zh-CN'
import frLocale from '@/lang/mo/fr'
import krLocale from '@/lang/mo/kr'
import thLocale from '@/lang/mo/th'
import viLocale from '@/lang/mo/vi'
import esLocale from '@/lang/mo/es'
import ptLocale from '@/lang/mo/pt'
import deLocale from '@/lang/mo/de'
import idLocale from '@/lang/mo/id'
import arLocale from '@/lang/mo/ar'
import ruLocale from '@/lang/mo/ru'
import itLocale from '@/lang/mo/it'
import jaLocale from '@/lang/mo/ja'
import faLocale from '@/lang/mo/fa'

Vue.use(VueI18n)

const messages = {
  en_US: {
    ...enLocale,
    ...elementEnLocale,
  },
  zh_CN: {
    ...zhLocale,
    ...elementZhLocale,
  },
  fr_FR: {
    ...frLocale,
    ...elementFrLocale,
  },
  ko: {
    ...krLocale,
    ...elementKrLocale,
  },
  th: {
    ...thLocale,
    ...elementThLocale,
  },
  vi: {
    ...viLocale,
    ...elementViLocale,
  },
  es: {
    ...esLocale,
    ...elementEsLocale,
  },
  pt: {
    ...ptLocale,
    ...elementPtLocale,
  },
  de: {
    ...deLocale,
    ...elementDeLocale,
  },
  id: {
    ...idLocale,
    ...elementIdLocale,
  },
  ar: {
    ...arLocale,
    ...elementArLocale,
  },
  ru: {
    ...ruLocale,
    ...elementRuLocale,
  },
  ja: {
    ...jaLocale,
    ...elementJaLocale,
  },
  it: {
    ...itLocale,
    ...elementItLocale,
  },
  fa: {
    ...faLocale,
    ...elementFaLocale,
  },
}

const i18n = new VueI18n({
  locale: 'en_US', // set locale
  fallbackLocale: 'en_US',
  messages, // set locale messages
})

elementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n
