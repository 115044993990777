import router from '@/router'
import {
  apiSetlanguage,
  apiTo_logout,
  apiTo_login_by_token,
  apiTo_login_register,
  apiTo_login,
  apiTo_sms_code_login,
} from '@/resource'
import store from '@/store'
import redirect from '@/util/redirect'
import { getRegulator } from '@/util/regulator'
import { MessageBox } from 'element-ui'
import { setCookies } from '@/util/cookies'
import { mapVerifiedAuth, getToPath } from '@/components/security/Security'
import { sensorsBindLogin, sensorsLogout, sensorsRegister } from './sensorsdata'

function setUserProfile(resp) {
  const userInfo = resp.data.data
  store.commit('common/setLoginStatus', true)
  store.commit('common/setUserName', userInfo.userName)
  store.commit('common/setIndividualName', userInfo.userName.split('&')[0].trim())
  store.commit('common/setUserType', userInfo.userType)
  store.commit('common/setUserTypeEnum', userInfo.userTypeEnum)
  store.commit('common/setUserId', userInfo.userId)
  store.commit('common/setEventEmailId', userInfo.eventEmailId)
  store.commit('common/setEventMobileId', userInfo.eventMobileId)
  store.commit('common/setIbClient', userInfo.ibClient)
  store.commit('common/setQuestionnaire', userInfo.pendingQuestionnaireCompletion)
  store.commit('common/setOpenAccountAction', userInfo.openAccountActionEnabled)
  store.commit('common/setCountryCode', userInfo.countryCode)
  store.commit('common/setAccessToken', userInfo.accessToken)
  store.commit('common/setEmail', userInfo.email)
  setCookies('token', userInfo.accessToken, 30)
  store.commit('common/setRequireAddressProof', userInfo.identityProofStatus.requireAddressProof)
  store.commit('common/setRequireIDProof', userInfo.identityProofStatus.requireIDProof)
  store.commit('common/setPendingAddressProofApproval', userInfo.identityProofStatus.pendingAddressProofApproval)
  store.commit('common/setPendingIDProofApproval', userInfo.identityProofStatus.pendingIDProofApproval)
  store.commit('common/setRegulator', getRegulator(userInfo.regulator))
  store.commit('common/setGreenIDStatus', userInfo.verifyMethod === 'greenid' ? true : false)
  store.commit('common/setClientClassification', userInfo.clientClassification)
  store.commit('upgrade/setProClientType', userInfo.proClientType)
  store.commit('common/setEmailMasked', userInfo.emailMasked)
  store.commit('common/setBeforeLoginCountry', userInfo.countryCode || '')

  sensorsRegister({
    is_login: true
  })
  sensorsBindLogin(userInfo.userId, userInfo.eventEmailId, userInfo.eventMobileId)

  //if user didn't change language at login page, change the language by customer's preference
  if (!store.state.common.isChangedLangAtLandingPage) {
    store.commit('common/setLang', userInfo.language)
    //if changed, save the user's preference
  } else {
    apiSetlanguage({ language: store.state.common.lang })
  }
  store.commit('common/setIbUrl', userInfo.url)

  store.commit('common/setRegisterStep', userInfo.registerStep)
  if (userInfo.demo) store.commit('common/setDemo', true)
  if (userInfo.registerStep >= 5) store.commit('register/setDisableField', true)
}

function relogin(resp) {
  MessageBox.confirm(resp.data.errmsg + ' Would you like to log out this account and then log in again?', 'Info', {
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
    type: 'info',
  }).then(() => {
    signOut(521)
  })
}

function signOut(method,reason) {
  console.log('signout')
  store.commit('common/setAuthHeaders', {
    etxId: '',
    eCode: '',
    ptxId: '',
    pCode: '',
    tCode: '',
  })

  const requestData = {
    reason: reason,
  };

  if(store.state.common.extras.logoutStacktrace){
    requestData['stack'] = store.state.common.extras.logoutStacktrace;
  }

  if (store.state.common.loginStatus || method === 521) {
    apiTo_logout(requestData).then(resp => {
      store.commit('common/setLoginStatus', '')
      store.commit('register/reset')
      store.commit('regulator/reset')
      store.commit('upgrade/reset')
      store.commit('promotion/reset')
      store.commit('payment/reset')
      store.commit('cps/reset')
      store.commit('common/setAutoNotifyStatus')
      setCookies('token', '')
      sensorsRegister({
        is_login: false
      })
      sensorsLogout()
      redirect.redirectToLogoutUrl()
    })
  } else {
    sensorsRegister({
      is_login: false
    })
    sensorsLogout()
    redirect.redirectToLogoutUrl()
  } 
}

// 避免注册成功后未退出连续注册新用户后仍使用前者的状态
function reRegisterReset() {
  store.commit('common/setLoginStatus', '')
  store.commit('register/reset')
  store.commit('regulator/reset')
  store.commit('upgrade/reset')
  store.commit('promotion/reset')
  store.commit('payment/reset')
  store.commit('cps/reset')
  store.commit('common/setAutoNotifyStatus')
  setCookies('token', '')
}

export default {
  signIn(userName, password) {
    apiTo_login({
      userName_login: userName,
      password_login: password,
      utc: 39600000,
    }).then(resp => {
      if (resp.data.code === 427) {
        //Password reset every 90 days.
        const callbackData = resp.data.data
        router.push({
          name: 'forceChangePassword',
          params: {
            token: callbackData.token,
            maskedEmail: callbackData.email,
            callbackRegulator: callbackData.regulator,
          },
        })
      } else if (resp.data.code === 530) {
        router.push({ name: 'login' })
      } else if (resp.data.code === 0) {
        store.commit('common/setAuthHeaders', {
          etxId: '',
          eCode: '',
          ptxId: '',
          pCode: '',
          tCode: '',
        })
        if (!resp.data.data.isTwoFactor) {
          store.dispatch('regulator/actionGetInformation')
          setUserProfile(resp)
          if (resp.data.data.userTypeEnum == 'IB') redirect.redirectToIbPortal()
          else if (resp.data.data.demo) router.push('/homeDemo')
          else if (resp.data.data.registerStep < 6) router.push('/register')
          else if (!resp.data.data.passwordChanged) router.push('/securityManagement')
          else router.push('/home')
        } else {
          store.commit('common/setTwoFactorAuth', true)
        }
      } else if (resp.data.code === 521) {
        //A user already logged in on this device
        relogin(resp)
      } else if (resp.data.code === 529) {
        //input wrong password more than 2 times and invoke slide verification
        store.commit('common/setFailedSecondTime', true)
      } else if (resp.data.code === 1201) {
        const respBody = resp.data.data
          store.commit('common/setBeforeLoginCountry', respBody.c || '')
          store.commit('common/setLoginCredentials', {
            method: 'signIn',
            request: {
              userName_login: userName,
              password_login: password,
              utc: new Date().getTime(),
            },
          })
          store.commit('common/setEmailMasked', respBody.encodeEmail)
          store.commit('common/setRegulator', getRegulator(respBody.r))
          const lackAuthItems = respBody.lackAuthMethods.map(item => {
            const showVerified = mapVerifiedAuth(item, respBody.authStatus)
            return {
              type: item,
              authenticated: false,
              verified: showVerified,
              path: getToPath(item, showVerified),
            }
          })
          let usedRequiredAuths = store.state.common.requiredAuths
          usedRequiredAuths['login'] = respBody.lackAuthMethods?.concat(respBody.effectiveAuthMethods)
          store.commit('common/setRequiredAuths', usedRequiredAuths)
          store.commit('common/setCheckedAuthStatus', lackAuthItems)
          const pushObject = lackAuthItems?.find(item => !item.authenticated)
          if (pushObject?.path != router.currentRoute.path) {
            router.push(pushObject?.path)
          }
      } else if (resp.data.code === 1202) {
        store.commit('common/setAuthHeaders', {
          etxId: '',
          eCode: '',
          ptxId: '',
          pCode: '',
          tCode: '',
        })
        router.push('/login')
      }
    }).catch(error => {
      console.log('error', error)
    })
  },
  signInwithToken(userName, password, token) {
    apiTo_login({
      userName_login: userName,
      password_login: password,
      utc: 39600000,
      token: token,
    }).then(resp => {
      if (resp.data.code === 427) {
        //Password reset every 90 days.
        const callbackData = resp.data.data
        router.push({
          name: 'forceChangePassword',
          params: {
            token: callbackData.token,
            maskedEmail: callbackData.email,
            callbackRegulator: callbackData.regulator,
          },
        })
      } else if (resp.data.code === 530) {
        router.push({ name: 'login' })
      } else if (resp.data.code === 0) {
        store.dispatch('regulator/actionGetInformation')
        setUserProfile(resp)
        if (resp.data.data.userTypeEnum == 'IB') redirect.redirectToIbPortal()
        else if (resp.data.data.demo) router.push('/homeDemo')
        else if (resp.data.data.registerStep < 6) router.push('/register')
        else if (!resp.data.data.passwordChanged) router.push('/securityManagement')
        else router.push('/home')
      } else if (resp.data.code === 521) {
        //A user already logged in on this device
        relogin(resp)
      } else if (resp.data.code === 529) {
        //input wrong password more than 2 times and invoke slide verification
        store.commit('common/setFailedSecondTime', true)
      } else if (resp.data.code === 531) {
        store.commit('common/setTwoFactorAuth', false)
        store.commit('common/setFailedSecondTime', true)
      }
    })
  },
  signInMobile(countrycode, userName, password) {
    apiTo_login({
      country_code: countrycode,
      mobile_login: userName,
      password_login: password,
      utc: 39600000,
    }).then(resp => {
      if (resp.data.code === 427) {
        //Password reset every 90 days.
        const callbackData = resp.data.data
        router.push({
          name: 'forceChangePassword',
          params: {
            token: callbackData.token,
            maskedEmail: callbackData.email,
            callbackRegulator: callbackData.regulator,
          },
        })
      } else if (resp.data.code === 530) {
        router.push({ name: 'login' })
      } else if (resp.data.code === 0) {
        store.dispatch('regulator/actionGetInformation')
        setUserProfile(resp)
        if (resp.data.data.userTypeEnum == 'IB') redirect.redirectToIbPortal()
        else if (resp.data.data.demo) router.push('/homeDemo')
        else if (resp.data.data.registerStep < 6) router.push('/register')
        else if (!resp.data.data.passwordChanged) router.push('/securityManagement')
        else router.push('/home')
      } else if (resp.data.code === 521) {
        //A user already logged in on this device
        relogin(resp)
      }
    })
  },
  signOut(method,reason) {
    signOut(method,reason)
  },
  refreshToken(accessToken, pk, path) {
    apiTo_login_by_token({
      headers: {
        accessToken: accessToken,
        pk: pk
      },
    }).then(resp => {
      if (resp.data.code === 0) {
        store.dispatch('regulator/actionGetInformation')
        store.commit('common/setAutoNotifyStatus', false)
        setUserProfile(resp)
        if (path) router.push(path)
        else router.push('/home')
      } else if (resp.data.code === 1500) {
        router.push('/home')
      }
    })
  },
  register(registerToken) {
    reRegisterReset();
    apiTo_login_register({
      a: registerToken,
    }).then(resp => {
      if (resp.data.code === 0) {
        store.commit('common/setAuthHeaders', {
          etxId: '',
          eCode: '',
          ptxId: '',
          pCode: '',
          tCode: '',
        })
        store.commit('common/setLoginCredentials', { method: 'register', request: null })
        store.dispatch('regulator/actionGetInformation')
        setUserProfile(resp)
        if (resp.data.data.userTypeEnum == 'IB') redirect.redirectToIbPortal()
        else if (resp.data.data.demo) router.push('/demo')
        else if (resp.data.data.registerStep < 6) router.push('/register')
        else if (!resp.data.data.passwordChanged) router.push('/securityManagement')
        else {
          router.push('/home')
        }
      } else if (resp.data.code === 1201) {
        const respBody = resp.data.data
        store.commit('common/setBeforeLoginCountry', respBody.c || '')
        store.commit('common/setLoginCredentials', {
          method: 'register',
          request: {
            a: registerToken,
          },
        })
        store.commit('common/setEmailMasked', respBody.encodeEmail)
        store.commit('common/setRegulator', getRegulator(respBody.r))
        const lackAuthItems = respBody.lackAuthMethods?.map(item => {
          const showVerified = mapVerifiedAuth(item, respBody.authStatus)
          return {
            type: item,
            authenticated: false,
            verified: showVerified,
            path: getToPath(item, showVerified),
          }
        })
        let usedRequiredAuths = store.state.common.requiredAuths
        usedRequiredAuths['login'] = respBody.lackAuthMethods?.concat(respBody.effectiveAuthMethods)
        store.commit('common/setRequiredAuths', usedRequiredAuths)
        store.commit('common/setCheckedAuthStatus', lackAuthItems)
        const pushObject = lackAuthItems.find(item => !item.authenticated)
        if (pushObject?.path != router.currentRoute.path) {
          router.push(pushObject?.path)
        }
      } else if (resp.data.code === 1202) {
        store.commit('common/setAuthHeaders', {
          etxId: '',
          eCode: '',
          ptxId: '',
          pCode: '',
          tCode: '',
        })
        router.push('/login')
      } 
       else {
        router.push('/login')
      }
    })
  },
  smsCodeLogin(token) {
    apiTo_sms_code_login({
      b: token,
    }).then(resp => {
      console.log(resp)
      if (resp.data.code === 0) {
        store.dispatch('regulator/actionGetInformation')
        setUserProfile(resp)
        if (resp.data.data.resetMobilePassword) {
          router.push({ path: 'resetPasswordMobile', query: { resetToken: resp.data.data.accessToken } })
        }
      } else {
        router.push('/loginByMobile')
      }
    })
  },
}
